// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from 'assets/payrent_logo_inv.svg';
import logoIcon from 'assets/payrent_logo_icon.svg';
//import './style.scss';

const DrawerLogo = (props) => {
  const dispatch = useDispatch();

  const handleClick = (role) => {
    if (role === 'tenant') {
      dispatch({ type: 'SET_SELECTED_MENU', payload: 'tenant' });
    } else if (role === 'landlord') {
      dispatch({ type: 'SET_SELECTED_MENU', payload: 'landlord' });
    }
  };

  return (
    <Link
      to={props.userRole === 'tenant' ? '/tenant' : '/landlord'}
      onClick={() => handleClick(props.userRole)}
      data-testid="linkTest"
    >
      {props.compact ? (
        <img src={logoIcon} style={{ width: '100%' }} alt="Payrent logo" data-testid="imgIconTest" />
      ) : (
        <img src={logo} className="drawer-brand-logo" alt="Payrent logo" data-testid="imgTest" />
      )}
    </Link>
  );
};

export default DrawerLogo;
