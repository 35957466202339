import React from 'react';
import { Result } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OnboardingResult = (props) => <StyledResult {...props} />;

const StyledResult = styled(Result)`
  .ant-result-icon {
    margin-bottom: 24px;
  }
`;

OnboardingResult.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  extra: PropTypes.node,
};

OnboardingResult.defaultProps = {
  status: '500',
  title: '500',
  subTitle: 'Sorry, something went wrong.',
};

export default OnboardingResult;
