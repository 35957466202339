import axios from 'axios';
import Jsona from 'jsona';
import store from '../../../store';
import { refreshToken } from '../../aws/cognito';

const dataFormatter = new Jsona();

// Deprecated in favor of getSessionData
export const getUserData = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/session?page[size]=1&include=paymentSettings,notificationSettings,property,billingAccounts`;
    const res = await axios.get(url);
    console.log('ƒ getUserData res', res.data);

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

// Replaces getUserData with V3 session endpoint
export const getSessionDataV3 = async (cognitoToken, controller) => {
  const refreshed = await checkRefreshToken();

  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/session`,
    };
    const res = await axios(options);

    if (!res.data) throw new Error('200 response but no payload from server');
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    console.error(error);
    const response = error.response || error;
    throw response;
  }
};

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const getTourStatus = async (cognitoToken, id) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}/tour-status`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getRoutingNumberStatus = async (cognitoToken, routingNumber) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/check-routing-number?rn=${routingNumber}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getServicePlanData = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/service-plan`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const verifyEmail = async (email = 'admin@payrent.com') => {
  try {
    const results = await axios.get('https://apilayer.net/api/check', {
      params: {
        access_key: '0ed63c3f5acecf4733aab1fa5e7297e5',
        email,
        smtp: 1,
        format: 1,
      },
    });
    return results;
  } catch (err) {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      console.log(err.response);
    } else if (err.request) {
      // client never received a response, or request never left
      console.log(err.request);
    } else console.error(err);
  }
};

export const ipPhoneEmailValidation = async (email, phone, ip) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL_V3}/ipqualityscore?email=${email}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const ipQualityScore = async (ip) => {
  try {
    const results = await axios.get(
      `https://ipqualityscore.com/api/json/ip/${process.env.REACT_APP_IPQUALITY_KEY}/${ip}`
    );
    return results.data;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const emailQualityScore = async (email) => {
  try {
    const results = await axios.get(
      `https://ipqualityscore.com/api/json/email/${process.env.REACT_APP_IPQUALITY_KEY}/${email}`
    );
    return results.data;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};
export const phoneQualityScore = async (phone) => {
  try {
    const results = await axios.get(
      `https://ipqualityscore.com/api/json/phone/${process.env.REACT_APP_IPQUALITY_KEY}/${phone}`
    );
    return results.data;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPropertyFees = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/properties/${id}/fees`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getUserInsurancePolicy = async (cognitoToken, ba) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/documents/policy?id=${ba}`,
    };
    const res = await axios(options);
    const response = dataFormatter.deserialize(res.data);

    if (response.statusCode === 404) {
      throw response;
    } else {
      return response;
    }
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getBillingAccount = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/billing-accounts/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const fetchSystemMessages = async (cognitoToken, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/session/system-messages`,
    };
    const res = await axios(options);
    // console.log('ƒ fetchSystemMessages res', res);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getDocuments = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/documents`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getEsignDocumentData = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    // console.log('ƒ getEsignDocumentData id', id);
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/esign/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const downloadEsignDocument = async (cognitoToken, id, userEmail) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    // console.log('ƒ getEsignDocumentData id', id, userEmail);
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        email: userEmail,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/esign/${id}/download`,
    };

    const result = await axios(options);
    const blob = dataURItoBlob('data:application/pdf;base64,' + result.data);
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export function dataURItoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
