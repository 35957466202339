import React from 'react';
import { Menu, DatePicker, Button as AntButton } from 'antd';

const DateRangeFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleResetDateRangeFilter,
  setCurrentPage,
  currentPageRef,
  handleDateRangeFilter,
}) => {
  return (
    <Menu>
      <Menu.Item key="0">
        <DatePicker
          placeholder="Start date"
          value={startDate}
          onChange={(value, string) => {
            if (value) {
              let keys = [];
              if (selectedKeys.length > 1) {
                keys = selectedKeys.splice(0, 2, value.format('MM-DD-YYYY'));
              } else {
                keys = [value.format('MM-DD-YYYY')];
              }
              setSelectedKeys(keys);
            }
            if (!value) {
              const key = selectedKeys[1] ? [selectedKeys[1]] : [];
              setSelectedKeys(key);
            }
            setStartDate(value);
          }}
          format="MM/DD/YYYY"
        />
      </Menu.Item>
      <Menu.Item key="1">
        <DatePicker
          placeholder="End date"
          value={endDate}
          onChange={(value) => {
            if (value) {
              let keys = [];

              if (selectedKeys.length >= 1) {
                keys = selectedKeys.push(value.format('MM-DD-YYYY'));
              } else {
                keys = [value.format('MM-DD-YYYY')];
              }

              setSelectedKeys(keys);
            }
            if (!value) {
              const key = selectedKeys[0] ? [selectedKeys[0]] : [];
              setSelectedKeys(key);
            }
            setEndDate(value);
          }}
          format="MM/DD/YYYY"
        />
      </Menu.Item>

      <div className="ant-table-filter-dropdown-btns">
        <AntButton
          type="link"
          size="small"
          disabled={!startDate || !endDate}
          onClick={() => handleResetDateRangeFilter(clearFilters, confirm)}
        >
          Reset
        </AntButton>
        <AntButton
          type="primary"
          size="small"
          disabled={!startDate || !endDate}
          onClick={() => {
            setCurrentPage(1);
            currentPageRef.current = 1;
            handleDateRangeFilter(setSelectedKeys, selectedKeys, confirm, 1);
          }}
        >
          OK
        </AntButton>
      </div>
    </Menu>
  );
};

export default DateRangeFilter;
