import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Row, Col, Switch, Radio, Slider, Tooltip, Select, Form, Spin } from 'antd';

import { CheckCircleFilled, InfoCircleFilled, CalculatorFilled } from '@ant-design/icons';
import { ReactComponent as AssurantLogo } from 'assets/images/assurant-logo.svg';

// Tooltip texts
import Insurance from 'translations/en/tenant/insurance';

import PanelFooter from 'components/Panel/PanelFooter';
import Text from 'components/Text';
import Button from 'components/Button';

const marks = {
  0: '$5K',
  10: '$10K',
  20: '$15k',
  30: '$20K',
  40: '$25k',
  50: '$30K',
  60: '$35k',
  70: '$40k',
  80: '$45k',
  90: '$50k',
  100: '$55k',
};
const propertyValues = {
  0: 5000,
  10: 10000,
  20: 15000,
  30: 20000,
  40: 25000,
  50: 30000,
  60: 35000,
  70: 40000,
  80: 45000,
  90: 50000,
  100: 55000,
};

const { Option } = Select;

const Quote = (props) => {
  const {
    loading,
    setQuoteForm,
    quoteForm,
    quoteResponse,
    setShowQuote,
    setShowCheckout,
    quoteRequest,
    setPaymentCadence,
    paymentCadence,
    setLoading,
  } = props;
  const userData = useSelector((store) => store.session.userData);
  const session = useSelector((store) => store.session);
  const [form] = Form.useForm();

  const [isDesktop, setDesktop] = useState(window.innerWidth > 457);
  const [xs, setSx] = useState(window.innerWidth < 380);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 457);
    setSx(window.innerWidth < 380);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (quoteForm.dwellingType === '') {
      document.getElementById('dwellingSelect').scrollIntoView();
      form.validateFields(['dwellingType']);
      return;
    }
    setLoading(true);
    setTimeout(() => {
      quoteRequest();
    }, 1000);
  }, [quoteForm]);

  const onFinish = () => {
    if (quoteForm.dwellingType === '') {
      document.getElementById('dwellingSelect').scrollIntoView();
      form.validateFields(['dwellingType']);

      return;
    }
    // getQuotation();
    setShowQuote(false);
    setShowCheckout(true);
  };

  return (
    <>
      <StyledContainer className="content-container">
        <Row justify="center" gutter={[0, 15]}>
          <Form form={form}>
            <Col span={24} style={{ paddingTop: '7px' }}>
              <Row justify="center" id="dwellingSelect">
                <StyledItem
                  name="dwellingType"
                  rules={[{ required: true, message: 'Please choose your dwelling type' }]}
                >
                  <Select
                    style={{ width: 250 }}
                    placeholder="Select Dwelling Type"
                    name="dwellingSelect"
                    data-testid="dwellingSelectTest"
                    onChange={(v) => setQuoteForm({ ...quoteForm, dwellingType: v })}
                  >
                    <Option value="A">Apartment/Condominium</Option>
                    <Option value="T">Townhouse/Duplex/Triplex</Option>
                    <Option value="S">Single Family Home</Option>
                    <Option value="D">Dorm/Student Housing</Option>
                  </Select>
                </StyledItem>
              </Row>
            </Col>
          </Form>

          <Col span={isDesktop ? 20 : 22}>
            <Row justify="center">
              <Col span={22}>
                <Text color="black" size={16} strong>
                  Personal Property
                </Text>
              </Col>
              <Col span={2} style={{ textAlign: 'center' }}>
                <Tooltip placement="left" title={Insurance.tooltips.personalProperty} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>

            <StyledSlider
              marks={marks}
              step={50}
              defaultValue={30}
              data-testid="sliderTest"
              tooltip={{ open: false }}
              onChange={(v) => setQuoteForm({ ...quoteForm, personalPropertyCoverage: propertyValues[v] })}
            />
          </Col>
          <Col span={isDesktop ? 20 : 22}>
            <Row justify="center">
              <Col span={22}>
                <Text color="black" size={16} strong>
                  Deductible
                </Text>
              </Col>
              <Col span={2} style={{ textAlign: 'center' }}>
                <Tooltip placement="left" title={Insurance.tooltips.deductible} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Radio.Group
              defaultValue={100}
              buttonStyle="solid"
              onChange={(e) => setQuoteForm({ ...quoteForm, allPerilDeductible: e.target.value })}
            >
              <Radio.Button value={100}>$100</Radio.Button>
              <Radio.Button value={250} data-testid="250DeductibleRadio">
                $250
              </Radio.Button>
              <Radio.Button value={500}>$500</Radio.Button>
            </Radio.Group>
          </Col>
          {/*
          <Col span={isDesktop ? 20 : 22}>
            <Row justify="center">
              <Col span={22}>
                <Text color="black" size={16} strong>
                  Liability
                </Text>
              </Col>
              <Col span={2} style={{ textAlign: 'center' }}>
                <Tooltip placement="left" title={Insurance.tooltips.liability} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          
          <Col span={24} style={{ textAlign: 'center' }}>
            <Radio.Group
              defaultValue={100000}
              buttonStyle="solid"
              onChange={(e) => setQuoteForm({ ...quoteForm, liabilityLimit: e.target.value })}
            >
              <Radio.Button value={100000}>$100K</Radio.Button>
              <Radio.Button value={0} data-testid="liabilityNoneTest">
                NONE
              </Radio.Button>
            </Radio.Group>
          </Col>
          */}
          <Col span={isDesktop ? 20 : 22}>
            <Row justify="center">
              <Col span={24}>
                <Text color="black" size={16} strong>
                  Additional Coverage
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={isDesktop ? 20 : 22}>
            <Row gutter={10}>
              <Col span={4}>
                <Switch
                  data-testid="replacementSwitchTest"
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  onChange={(v) => setQuoteForm({ ...quoteForm, allowReplacementCost: v })}
                />
              </Col>
              <Col span={18}>Replacement Cost</Col>
              <Col span={2}>
                <Tooltip placement="left" title={Insurance.tooltips.replacementCost} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={isDesktop ? 20 : 22}>
            <Row gutter={10}>
              <Col span={4}>
                <Switch
                  data-testid="sewerDrainTest"
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  onChange={(v) => setQuoteForm({ ...quoteForm, allowWaterBackup: v })}
                />
              </Col>
              <Col span={18}>Sewer or Drain Backup</Col>
              <Col span={2}>
                <Tooltip placement="left" title={Insurance.tooltips.sewerDrainBackup} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>

          {/**
           
           <Col span={20}>
            <Row gutter={10}>
              <Col span={4}>
                <Switch checkedChildren="YES" unCheckedChildren="NO" />
              </Col>
              <Col span={18}>Earthquake Deductible</Col>
              <Col span={2}>
                <Tooltip placement="left" title={Insurance.tooltips.earthquakeDeductible} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
            <Col span={20}>
            <Row gutter={10}>
              <Col span={4}>
                <Switch checkedChildren="YES" unCheckedChildren="NO" />
              </Col>
              <Col span={18}>Hurricane Deductible</Col>
              <Col span={2}>
                <Tooltip placement="left" title={Insurance.tooltips.hurricaneDeductible} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
           */}
          {/**   earthquake options are for california only and hurricane option is for florida only */}
          {userData.property && userData?.property?.state.toLowerCase() === 'california' && (
            <Col span={isDesktop ? 20 : 22}>
              <Row gutter={10}>
                <Col span={4}>
                  <Switch
                    checkedChildren="YES"
                    unCheckedChildren="NO"
                    onChange={(v) => setQuoteForm({ ...quoteForm, allowEarthquake: v })}
                    data-testid="earthQuakeSwitch"
                  />
                </Col>
                <Col span={18}>Earthquake Coverage</Col>
                <Col span={2}>
                  <Tooltip placement="left" title={Insurance.tooltips.earthquakeCoverage} trigger={['click']}>
                    <InfoCircleFilled style={{ fontSize: '14px' }} />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          )}

          <Col span={isDesktop ? 20 : 22}>
            <Row gutter={10}>
              <Col span={4}>
                <Switch
                  defaultChecked
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  onChange={(v) => setQuoteForm({ ...quoteForm, allowIdentityFraud: v })}
                  data-testid="fraudSwitchTest"
                />
              </Col>
              <Col span={18}>Identity Fraud</Col>
              <Col span={2}>
                <Tooltip placement="left" title={Insurance.tooltips.identityFraud} trigger={['click']}>
                  <InfoCircleFilled style={{ fontSize: '14px' }} />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={isDesktop ? 20 : 22}>
            <Spin
              tip="Crunching the numbers..."
              size="large"
              indicator={<CalculatorFilled spin style={{ color: '#cb47b8' }} />}
              spinning={loading}
              style={{ color: '#541388' }}
            >
              <PaymentContainer>
                <Row justify="center" gutter={[0, 10]}>
                  <Col span={20} style={{ textAlign: 'center' }}>
                    <Text color="black" size={16}>
                      Choose Your Payment Options
                    </Text>
                  </Col>
                </Row>
                <Row justify="center" gutter={[0, 10]}>
                  <Col span={6} style={{ textAlign: 'center' }}>
                    <Row>
                      <Col span={12} style={{ maxWidth: '57%' }}>
                        <Text color="black" size={xs ? 21 : 25} strong>
                          ${quoteResponse ? quoteResponse.premiumMonthly.toString().split('.')[0] : '0'}
                        </Text>
                      </Col>
                      <Col span={10}>
                        <Row>
                          <Col span={12} style={{ lineHeight: '13px', marginTop: '5px', maxWidth: '100%' }}>
                            <Text color="black" size={xs ? 8 : 10} strong>
                              {quoteResponse
                                ? quoteResponse.premiumMonthly.toString().split('.').length > 1
                                  ? quoteResponse.premiumMonthly.toString().split('.')[1]
                                  : '00'
                                : '00'}
                            </Text>
                            <br />
                            <Text color="black" size={xs ? 8 : 10} strong>
                              /MO
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ marginLeft: '7px' }}>
                      <Text color="black" size={xs ? 8 : 10}>
                        $
                        {quoteResponse
                          ? `${(quoteResponse.downpayment + quoteResponse.premiumMonthly * 10).toFixed(2)}`
                          : '0.0'}
                        /YR
                      </Text>
                    </Row>
                  </Col>
                  <Col span={isDesktop ? 10 : 12} style={{ textAlign: 'left' }}>
                    <Text color="black" size={9}>
                      1 down payment today of ${quoteResponse ? quoteResponse.downpayment : '0.00'} <br />
                      10 Monthly payments of ${quoteResponse ? quoteResponse.premiumMonthly.toString() : '0.00'}
                    </Text>
                  </Col>
                  <Col span={isDesktop ? 4 : 4}>
                    {paymentCadence === 'monthly' ? (
                      <CheckCircleFilled style={{ fontSize: '40px', color: '#541388' }} />
                    ) : (
                      <Circle
                        data-testid="circleTest"
                        onClick={() => {
                          setPaymentCadence('monthly');
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row justify="center" gutter={[0, 0]}>
                  <Col span={7} style={{ textAlign: 'center' }}>
                    <Row>
                      <Col span={15} style={{ maxWidth: '62%' }}>
                        <Text color="black" size={xs ? 21 : 25} strong>
                          ${quoteResponse ? quoteResponse.premiumAnnual.toString().split('.')[0] : '0'}
                        </Text>
                      </Col>
                      <Col span={9}>
                        <Row>
                          <Col
                            span={11}
                            style={{ lineHeight: '13px', marginTop: '5px', marginLeft: '0px', maxWidth: '100%' }}
                          >
                            <Text color="black" size={xs ? 8 : 10} strong>
                              {quoteResponse
                                ? quoteResponse.premiumAnnual.toString().split('.').length > 1
                                  ? quoteResponse.premiumAnnual.toString().split('.')[1]
                                  : '00'
                                : '00'}
                            </Text>
                            <br />
                            <Text color="black" size={xs ? 8 : 10} strong>
                              /YR
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={isDesktop ? 9 : 11} style={{ textAlign: 'left' }}>
                    <Text color="black" size={9}>
                      Pay all at once and save ${quoteResponse ? quoteResponse.difference : '0.00'}
                    </Text>
                  </Col>
                  <Col span={5}>
                    {paymentCadence === 'annual' ? (
                      <CheckCircleFilled style={{ fontSize: '40px', color: '#541388' }} />
                    ) : (
                      <Circle
                        data-testid="annualCircleTest"
                        onClick={() => {
                          setPaymentCadence('annual');
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </PaymentContainer>
            </Spin>
          </Col>
          <Col span={isDesktop ? 20 : 22}>
            <Text color="grey" size={9}>
              Assurant is the holding company for various underwriting entities that provide Renters Insurance. In
              all states, except Minnesota and Texas, Renters Insurance is underwritten by American Bankers
              Insurance Company of Florida with its home office in Miami, Florida (ABIC NAIC# 10111). In Minnesota,
              the underwriter is American Security Insurance Company. In Texas, the renters property insurance is
              underwritten by Ranchers and Farmers Mutual Insurance Company. Each insurer has sole responsibility
              for its own products. In Utah, coverage is provided under policy form AJ8850PC-0307. Sold by Sure
              HIIS Insurance Services, LLC.
            </Text>
          </Col>
          <Col span={isDesktop ? 20 : 22} style={{ textAlign: 'center' }}>
            <AssurantLogo />
          </Col>
        </Row>
      </StyledContainer>
      <PanelFooter>
        <Row justify="center">
          <Col span={24}>
            <Button
              color="violet"
              alignment="center"
              onClick={onFinish}
              // loading={loading}
              data-testid="nextButtonTest"
              disabled={loading}
              bottom={0}
            >
              NEXT
            </Button>
          </Col>
        </Row>
      </PanelFooter>
    </>
  );
};

const StyledSlider = styled(Slider)`
  @media screen and (max-width: 400px) {
    .ant-slider-mark {
      font-size: 11px;
    }
  }
`;

const StyledContainer = styled.div`
  height: calc(100% - 306px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Circle = styled.span`
  height: 40px;
  width: 40px;
  border: 2px solid #541388;
  border-radius: 50%;
  display: inline-block;
`;

const PaymentContainer = styled.div`
  background-color: #f7f7f0;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px 5px;
  text-align: center;
  height: 166px;
  @media screen and (max-width: 457px) {
    padding: 10px 0px;
  }
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px;
  .ant-form-item-explain {
    text-align: center !important;
  }
`;

export default Quote;
