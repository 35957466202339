import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import Drawer from 'components/Drawer/Drawer';
import { CheckOutlined } from '@ant-design/icons';

import {
  getSessionDetails,
  getPropertyGroups,
  getBillingDetails,
  getServicePlans,
} from 'services/api/landlordApi/read';
import { getServicePlanData } from 'services/api/common/read';
import { updatePaymentGateway } from 'services/api/landlordApi/update';
import { upgradeDowngradeServicePlan } from 'services/api/landlordApi/create';

import { displayAlertAction } from 'store/actions/globalActions';
import { getServicePlanAction } from 'store/actions/sessionActions';

import CardWithTitle from 'components/CardWithTitle';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';

import AccountProfilePanel from '../components/AccountProfilePanel';
import AccountPreferencesPanel from '../components/AccountPreferencesPanel';
import PlansBillingsPanel from '../components/PlansBillingsPanel';
import BillingsDetailsPanel from '../components/BillingDetailsPanel';
import NewsPanel from '../components/NewsPanel';
import OnboardingOverlay from '../components/OnboardingOverlay';

import Onboarding from '../Onboarding';
import ServicePlansWepay from './ServicePlansWepay';
import ServicePlansFinix from './ServicePlansFinix';

//import './style.scss';

let timerId = '';

const ServicePlans = (props) => {
  // console.log('[ServicePlans.js]', props);
  const { showOnboardingOverlay, setShowOnboardingOverlay, setShowOnboarding, setDrawerVisible } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const { name: paymentGateway } = session.userData.servicePlan.processor;
  const [loading, setLoading] = useState({
    button1: false,
    button2: false,
    button3: false,
  });
  const [userServicePlan, setUserServicePlan] = useState({ id: 0 });
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'infor' });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading({
        button1: true,
        button2: true,
        button3: true,
      });

      const cognitoToken = session.cognitoTokens.idToken.jwtToken;
      const currentServicePlan = await getServicePlanData(cognitoToken);
      const propertyGroups = await getPropertyGroups(cognitoToken, 1, 20);
      // console.log(propertyGroups);
      if (propertyGroups.items.length > 1) {
        setDisabledButtons(true);
      }

      setUserServicePlan(currentServicePlan);

      setLoading({
        button1: false,
        button2: false,
        button3: false,
      });
    } catch (error) {
      setLoading({
        button1: false,
        button2: false,
        button3: false,
      });
    }
  };

  const checkLandlordStatus = (setLoading1, setDisableButton1, setDisableButton2, displayAlert) => {
    try {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        const result = await getSessionDetails();

        if (result.state === 'new2-step2') {
          setLoading1(false);
          setDisableButton1(true);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.state === 'action-required') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.state === 'error') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
          displayAlert('There was an error with your request', 'warning');
        }
      }, 2000);
    } catch (error) {
      if (timerId) clearInterval(timerId);
      setLoading1(false);
      displayAlert('There was an error with your request', 'warning');
    }
  };

  const handleUpgradeDowngrade = async (sp, button) => {
    try {
      setLoading({
        ...loading,
        [`button${button}`]: true,
      });
      // Nuvei users that wants to change to wepay
      if (session.userData.paymentGateway === 'Nuvei') {
      }
      if (session.userData.paymentGateway === 'Wepay' || session.userData.paymentGateway === 'SilaStripe') {
        // only valid for wepay landlords
        const cognitoToken = session.cognitoTokens.idToken.jwtToken;
        const oldSp = userServicePlan.id;

        await upgradeDowngradeServicePlan(
          cognitoToken,
          {
            processorId: session.userData.servicePlan.processor.id,
            servicePlanId: sp,
          },
          +session.userData.id
        );
        dispatch(displayAlertAction('', 'success', 'Your Service Plan Was Successfully Updated', true, 5000));
        const currentServicePlan = await getServicePlanData(cognitoToken);
        setUserServicePlan(currentServicePlan);

        if (+oldSp === 6 || +oldSp === 5 || +oldSp === 1) {
          const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);

          if (!plansBillingRes.paymentMethod.paymentMethod) {
            props.setDrawerVisible(true);
            props.setShowBillingDetailsPanel(true);
          }
        }
        setLoading({
          button1: false,
          button2: false,
          button3: false,
        });
        dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
      }
    } catch (error) {
      setLoading({
        button1: false,
        button2: false,
        button3: false,
      });
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
    }
  };

  const displayAlert = (text, type) => {
    setAlert({
      message: text,
      type,
    });
    setTimeout(() => {
      setAlert({
        message: '',
      });
    }, 3000);
  };

  const resetPanel = () => {
    props.setDrawerVisible(false);
    props.setShowOnboarding(false);
    props.setShowAccountProfile(false);
    props.setShowAccountPreferences(false);
    props.setShowPlansBillingPanel(false);
    props.setShowBillingDetailsPanel(false);
    dispatch({
      type: 'UNSET_UPGRADE_DOWNGRADE',
    });
  };

  return (
    <>
      <Drawer
        // closable={false}
        // closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
        placement="right"
        destroyOnClose
        onClose={() => resetPanel()}
        open={props.drawerVisible}
        // getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
        alertMessage={alert.message}
        alertType={alert.type}
      >
        {props.drawerVisible && props.showOnboarding && (
          <Onboarding
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            checkLandlordStatus={checkLandlordStatus}
            updatePaymentGateway={updatePaymentGateway}
          />
        )}
        {/* Moved to LandlordMenu.js */}
        {/* 
        {props.drawerVisible && props.showAccountProfile && (
          <AccountProfilePanel
            setShowAccountProfile={props.setShowAccountProfile}
            setDrawerVisible={props.setDrawerVisible}
          />
        )}
        {props.drawerVisible && props.showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowAccountPreferences={props.setShowAccountPreferences}
          />
        )}
        {props.drawerVisible && props.showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
          />
        )} */}

        {props.drawerVisible && props.showBillingDetailsPanel && (
          <BillingsDetailsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
            resetPanel={resetPanel}
            displayAlert={displayAlert}
          />
        )}
      </Drawer>
      {/* Removed from UI 4.0.0 */}
      {/* <NewsPanel
        title="What's New @PayRent"
        placement="right"
        closable={true}
        onClose={() => {
          props.setNewsDrawerVisible(false);
        }}
        open={props.newsDrawerVisible}
        posts={false}
        isOpen={props.newsDrawerVisible}
      /> */}
      {showOnboardingOverlay && (
        <OnboardingOverlay
          showOnboardingOverlay={showOnboardingOverlay}
          setShowOnboardingOverlay={setShowOnboardingOverlay}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
        />
      )}
      {paymentGateway === 'Wepay' ? (
        <ServicePlansWepay
          handleUpgradeDowngrade={handleUpgradeDowngrade}
          setDrawerVisible={setDrawerVisible}
          setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
        />
      ) : paymentGateway === 'Finix' ? (
        <ServicePlansFinix
          setDrawerVisible={props.setDrawerVisible}
          setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
        />
      ) : null}
    </>
  );
};

export default ServicePlans;

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 1500px) {
        width: 460px !important;
      }
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    input {
      color: #595959;
    }

    @media screen and (max-height: 750px) {
      .ant-steps-small .ant-steps-item-description {
        font-size: 12px !important;
      }
    }
    @media screen and (max-height: 750px) {
      .ant-drawer-body h1 {
        font-size: 35px !important;
      }
      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }
  `}
`;
