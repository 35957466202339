import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MenuOutlined, LogoutOutlined } from '@ant-design/icons';

// import freshChat from '../../../../assets/freshchat.png';

import Link from 'components/Link';

// import { openFreshChatWidget } from 'utils';

const LandlordMobileMenu = (props) => {
  const { showDrawer } = props;
  // const session = useSelector((state) => state.session);

  return (
    <>
      <MenuOutlined style={{ fontSize: '25px' }} onClick={showDrawer} data-testid="menuOutTest" />

      <TopRightMenu className="top-right-menu">
        {/* <div
          id="custom_fc_button"
          style={{ visibility: 'visible', marginRight: '24px', lineHeight: 0, cursor: 'pointer' }}
          data-testid="freshchatButton"
        >
          <a
            id="open_fc_widget"
            onClick={() =>
              openFreshChatWidget(
                session.userData.id,
                session.userData.firstName,
                session.userData.email,
                session.userData.cognito['custom:landlord'],
                session.userData.servicePlan.name,
                session.userData.servicePlan.version
              )
            }
            style={{ cursor: 'pointer' }}
            data-testid="fcLinkTest"
          >
            <img src={freshChat} style={{ width: '42px' }} alt="freshchat" />
            <span id="notify" />
          </a>
        </div> */}
        <Link to="/sign-out" data-testid="signOutTest">
          <LogoutOutlined style={{ color: 'white', fontSize: '28px' }} data-testid="logoOutTest" />
        </Link>
      </TopRightMenu>
    </>
  );
};

const TopRightMenu = styled.div`
  display: flex;
  align-content: center;
  justify-content: inherit;
`;

export default LandlordMobileMenu;
