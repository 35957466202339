import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';

import {
  identityVerification,
  SSNVerification,
  businessVerification,
  FEINVerification,
  kycKybMap,
} from 'resources/SilaDocs';

import { getPropertyGroupOnboardingStatus } from 'services/api/landlordApi/read';

import { displayAlert, truncateString } from 'resources/helpers';
import { uploadSilaDoc } from 'services/api/landlordApi/create';
import Text from 'components/Text';
import Space from 'components/Space';
import SpaceResponsive from 'components/SpaceResponsive';
import Button from 'components/Button';
import styledVariables from 'styles/styledComponents/variables';
import AlertFade from 'components/AlertFade/AlertFade';
import KycDocs from 'components/KycDocs';
import { boardingElement, boardingText } from '../../animation/variants';

const acceptableFileTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];

export default function VerificationDocs(props) {
  console.log('[VerificationDocs.js]', props);
  const {
    setSilaVerificationDocs,
    open: openPlaid,
    groupOnboardingStatus,
    defaultPropertyGroup,
    setShowOnboardingFullscreen,
    setShowDocsReview,
    defaultPropertyGroupId,
  } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const [uploadState, setUploadState] = useState({
    fileList: [],
    uploading: false,
  });

  const [uploading, setUploading] = useState(false);
  const [showUpload, setShowUpload] = useState({
    kycDocs1: false,
    kycDocs2: false,
    kybDocs1: false,
    kybDocs2: false,
  });

  const [uploadingState, setUploadingState] = useState({
    kycDocs1: false,
    kycDocs2: false,
    kybDocs1: false,
    kybDocs2: false,
  });

  const [uploadsFinished, setUploadsFinished] = useState(false);

  useEffect(() => {
    // console.log('ƒ useEffect on showUpload', showUpload);
    setUploadsFinished(!Object.values(showUpload).includes(true));
  }, [showUpload]);

  useEffect(() => {
    // console.log('ƒ useEffect on groupOnboardingStatus', groupOnboardingStatus);
    // map tags to upload components

    if (groupOnboardingStatus) {
      const { documents } = groupOnboardingStatus?.ach;
      if (documents) {
        const documentKeys = Object.entries(documents)
          .filter((entry) => entry[1].length !== 0)
          .map((e) => e[0]);
        console.log('documents required for', documentKeys);
        documentKeys.forEach((key) => {
          const uploadSetting = kycKybMap[key];
          setShowUpload((prev) => ({ ...prev, ...uploadSetting }));
        });
      }
    }

    return () => console.log('cancel');
  }, [groupOnboardingStatus]);

  const customRequest = async (file) => {
    // console.log('ƒ customRequest', file);
    try {
      const { info, source, documentType } = file;

      if (info.file.type === undefined) throw Error('Invalid file type');

      if (!acceptableFileTypes.includes(info.file.type)) {
        throw Error('Invalid File Type');
      }
      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }

      // setUploading(true);
      setUploadingState((prev) => {
        return { ...prev, [source]: true };
      });

      setUploadState({ ...uploadState, fileList: [...uploadState.fileList, file] });

      setShowUpload({ ...showUpload, [source]: false });

      setUploadingState((prev) => {
        return { ...prev, [source]: false };
      });

      setUploading(false);

      // const files = [];
      // if (uploadState.fileList.length) {
      //   for (let i = 0; i < uploadState.fileList.length; i++) {
      //     files.push();
      //   }
      //
      //   await Promise.all(files);
      //
      //   setUploadState({ ...uploadState, fileList: [] });
      // }
      // const ext = type.mime === 'image/jpeg' ? 'jpeg' : type.ext;
      // setSilaVerificationDocs(false);
      // openPlaid();
    } catch (error) {
      setUploadingState({
        kycDocs1: false,
        kycDocs2: false,
        kybDocs1: false,
        kybDocs2: false,
      });
      displayAlert(setAlert, error?.message || 'There was an error with your request', 'warning', 4000);
    }
  };

  const trashFile = (index) => {
    const keptFiles = uploadState.fileList.filter((f, i) => i !== index);
    const fileSource = uploadState.fileList[index].source;

    setUploadState({ fileList: keptFiles, uploading: false });
    setShowUpload((prev) => {
      console.log(prev);
      return { ...prev, [fileSource]: true };
    });
  };

  const onFinish = async () => {
    try {
      setUploading(true);
      await uploadSilaDoc(
        cognitoToken,
        uploadState.fileList,
        defaultPropertyGroup[0].id,
        groupOnboardingStatus.ach.silaHandle
      );

      setUploading(false);
      displayAlert(setAlert, 'successful upload', 'success', 4000);
      setTimeout(() => {
        setSilaVerificationDocs(false);
        setShowDocsReview(true);
        // setShowOnboardingFullscreen(false);
      }, 4000);
    } catch (error) {
      displayAlert(setAlert, 'Something went wrong', 'warning', 4000);
      setUploading(false);
    }
  };

  const contentVariants = {
    hidden: { opacity: 0 },
    enter: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
    leave: { opacity: 0, transition: { duration: 3 } },
  };

  return (
    <>
      <>
        <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
          <Text color="black" size={24} sizeXs={20} strong>
            Upload Your Documents
          </Text>
        </motion.div>
        <SpaceResponsive lg={10} xmd={10} md={10} sm={10} />
        <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave" id="docsContainer">
          <ContentContainer className="content-container">
            <Text color="black" size={14}>
              To help the government fight terrorism funding and money laundering, all financial institutions are
              required by federal law to obtain, verify, and record information about you, including your name,
              address, and date of birth. Please upload the following documents:
            </Text>
            <Space vertical={20} />
            <AlertFade
              isVisible
              type="info"
              display
              message="We can only accept clear color photos or pdfs. We must be able to see all four corners of the document with the information you are verifying clearly visible."
            />
            <Space vertical={20} />

            <Space vertical={10} />
            <motion.div variants={contentVariants} initial="hidden" animate="enter" exit="leave">
              <AnimatePresence>
                {showUpload.kycDocs1 && (
                  <KycDocs
                    id="kycDocs1"
                    key="kyc-docs-1"
                    title="Please provide valid a photo Id"
                    placeholder="Select Itendity Document"
                    files={identityVerification}
                    setUploadState={setUploadState}
                    uploadState={uploadState}
                    customRequest={customRequest}
                    accept={acceptableFileTypes}
                    showUpload={showUpload}
                    setShowUpload={setShowUpload}
                    uploadingState={uploadingState}
                  />
                )}

                {showUpload.kycDocs2 && (
                  <KycDocs
                    id="kycDocs2"
                    key="kyc-docs-2"
                    title="Please provide tax verification"
                    placeholder="Select Tax Document"
                    files={SSNVerification}
                    setUploadState={setUploadState}
                    uploadState={uploadState}
                    customRequest={customRequest}
                    accept={acceptableFileTypes}
                    showUpload={showUpload}
                    setShowUpload={setShowUpload}
                    uploadingState={uploadingState}
                  />
                )}
                {showUpload.kybDocs1 && (
                  <KycDocs
                    id="kybDocs1"
                    key="kyb-docs-1"
                    title="Please provide business verification"
                    placeholder="Select Business Document"
                    files={businessVerification}
                    setUploadState={setUploadState}
                    uploadState={uploadState}
                    customRequest={customRequest}
                    accept={acceptableFileTypes}
                    showUpload={showUpload}
                    setShowUpload={setShowUpload}
                    uploadingState={uploadingState}
                  />
                )}
                {showUpload.kybDocs2 && (
                  <KycDocs
                    id="kybDocs2"
                    key="kyb-docs-2"
                    title="Please provide business tax verification"
                    placeholder="Select Business Tax Document"
                    files={FEINVerification}
                    setUploadState={setUploadState}
                    uploadState={uploadState}
                    customRequest={customRequest}
                    accept={acceptableFileTypes}
                    showUpload={showUpload}
                    setShowUpload={setShowUpload}
                    uploadingState={uploadingState}
                  />
                )}
              </AnimatePresence>
            </motion.div>
            <Text color="black" size={14} key="text">
              Uploaded Documents
            </Text>
            <AnimatePresence>
              <UploadContainer
                key="upload-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 3 } }}
              >
                {uploadState.fileList.length ? (
                  uploadState.fileList.map((file, i) => {
                    const f = file.info.file;
                    return (
                      <div
                        style={{ display: 'flex', alignItems: 'center', marginTop: '4px', marginBottom: '4px' }}
                        key={f.uid}
                      >
                        <PaperClipOutlined style={{ fontSize: '14px', marginRight: '5px' }} />
                        <span style={{ flexGrow: 2 }}>{truncateString(f.name, 30, true)}</span>

                        <DeleteOutlined
                          style={{ fontSize: '14px', cursor: 'pointer' }}
                          onClick={() => trashFile(i)}
                        />
                      </div>
                    );
                  })
                ) : (
                  <Empty
                    style={{ margin: 0 }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{ height: 28 }}
                    description="No Documents"
                  />
                )}
              </UploadContainer>
            </AnimatePresence>
          </ContentContainer>
          <StyledFooter>
            <AlertFade
              type={alert.type}
              isVisible={!!alert.message}
              message={alert.message}
              // description={message.description}
              // alertPosition="absolute"
              position="absolute"
              width={80}
              bottom={100}
              minHeight="40"
            />
            <Button
              color="violet"
              bottom={0}
              alignment="center"
              onClick={onFinish}
              disabled={!uploadsFinished}
              loading={uploading}
            >
              FINISH
            </Button>
          </StyledFooter>
        </Container>
      </>
    </>
  );
}

const ContentContainer = styled.div`
  overflow: hidden;
  overflow-y: scroll !important;
  overflow-x: hidden;
`;

const Container = styled(motion.div)`
  width: 100%;
  background-color: ${styledVariables.colors.white2};
  height: 85%;
  border: 1px solid ${styledVariables.colors.grey5};
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 13px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 488px) {
    width: 95%;
  }
`;

const StyledFooter = styled.div`
  min-height: 110px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
  gap: 20px;
`;

const UploadContainer = styled(motion.div)`
  margin-top: 8px;
  text-overflow: ellipsis;
  border: 1px dashed #d9d9d9;
  padding: 12px;
`;
