import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Input, Row, Col, Select, Checkbox, Tooltip, Radio } from 'antd';
import parser from 'parse-address';
import { usStates } from 'resources/FormData';
import { v4 } from 'uuid';

import { createProperty, updateCreatePropertyFees } from 'services/api/landlordApi/create';
import { getPropertyFees } from 'services/api/common/read';
import { updatePropertyPreferences } from 'services/api/landlordApi/update';

import { getPropertiesListAction, getSummaryDataAction } from 'store/actions/landlordActions';
import { getServicePlanAction } from 'store/actions/sessionActions';

import { numberTOrdinalSuffix } from 'resources/helpers';

import { CloseSquareFilled, InfoCircleFilled } from '@ant-design/icons';

import PanelFooter from 'components/Panel/PanelFooter';
import SpaceResponsive from 'components/SpaceResponsive';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import Button from 'components/Button';
import Text from 'components/Text';
import Segmented from 'components/Segmented/Segmented';

import AdvancedFeaturesOverlay from '../AdvancedFeaturesOverlay/AdvancedFeaturesOverlay';
import AdditionalFeesFields from '../AdditionalFeesFields/AdditionalFeesFields';

import PropertyRentPolicy from '../PropertyRentPolicy/PropertyRentPolicy';

// import './styles.scss';

import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../../components/Panel/PanelHeader/PanelHeader';
import PremiumFeaturePopover from 'components/uielements/PremiumFeaturePopover/PremiumFeaturePopover';

const { Option } = Select;

const UnitPanel = (props) => {
  const history = useHistory();
  const location = useLocation();

  const {
    editProperty,
    cloneProperty,
    setEditProperty,
    selectedParentProperty,
    setShowUnitPanel,
    setDrawerVisible,
    handleRefreshUnits,
    propertyGroup,
    fetchProperties,
    page,
  } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const dispatch = useDispatch();

  const [message, setMessage] = useState({
    type: 'success',
    message: '',
    description: '',
    closable: false,
    marginBottom: 0,
  });

  const [form] = Form.useForm();
  const [isCommercial, setIsCommercial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [creditCardPayments, setCreditCardPayments] = useState(false);
  const [absorbFees, setAbsorbFees] = useState(false);
  const [fullPayment, setFullPayment] = useState(false);
  const [preventPayment, setPreventPayment] = useState(false);
  const [formDays, setFormDays] = useState([]);
  const [locationData, setLocationData] = useState(null);
  const [addressInput, setAddressInput] = useState('');
  const [addressInitValue, setAddressInitValue] = useState('');
  const [googleString, setGoogleString] = useState(null);
  const googleSelected = useRef(null);
  const customAddressInput = useRef(null);
  const amountInput = useRef(null);
  const renterFeesEnabled = session.userData.servicePlan.metaFeatures.rENTERPAYSFees;
  const additionalAssessmentsEnabled = session.userData.servicePlan.metaFeatures.aDDITIONALAssessments;

  useEffect(() => {
    datesArray();
    fetchAndSetData();
    dispatch(getServicePlanAction(cognitoToken));
  }, []);

  const fetchAndSetData = async () => {
    try {
      if (selectedParentProperty) {
        const parsedAddress = parser.parseLocation(selectedParentProperty.address);
        let streetNumber = '';
        let streetName = '';
        if (parsedAddress) {
          streetNumber = parsedAddress?.number;
          streetName = selectedParentProperty.address.replace(`${parsedAddress.number} `, '');
        } else {
          streetName = selectedParentProperty.address.split(',')[0];
        }

        setLocationData({
          address: streetName,
          city: selectedParentProperty.city,
          country: 'United States',
          google_place_id: selectedParentProperty.googlePlaceId,
          lat: selectedParentProperty
            ? selectedParentProperty.lat
            : editProperty
            ? editProperty.lat
            : cloneProperty.lat,
          long: selectedParentProperty
            ? selectedParentProperty.long
            : editProperty
            ? editProperty.long
            : cloneProperty.long,
          state: selectedParentProperty.state,
          streetNumber,
          zip: selectedParentProperty.zip,
        });
      }

      const propertyData = editProperty || cloneProperty;

      if (propertyData) {
        const feesRes = await getPropertyFees(cognitoToken, propertyData.id);
        const addressName =
          propertyData.city !== ''
            ? `${propertyData.address}, ${propertyData.city}, ${propertyData.state}, ${propertyData.zip}`
            : `${propertyData.address}, ${propertyData.state}, ${propertyData.zip}`;
        setAddressInitValue(addressName);
        const settings = propertyData.paymentSettings || propertyData.settings;
        setCreditCardPayments(settings.acceptCreditCard);
        setAbsorbFees(settings.absorbFees);
        setFullPayment(settings.requireFullPayment);
        setPreventPayment(settings.preventPayments);

        let defaultSettings = {
          address: propertyData.address,
          bedrooms: Math.ceil(propertyData.bedrooms),
          bathrooms: propertyData.bathrooms,
          unit: propertyData.address2,
          rentDay: propertyData.rentDueDay,
          rentAmount: propertyData.rentAmount.toFixed(2),
          percent: settings.absorbFeesPercent,
          propertyCategory: propertyData.commercial,
        };

        if (session.userData.servicePlan.metaFeatures.aDDITIONALAssessments) {
          defaultSettings.complianceLevel = settings.complianceLevel;

          defaultSettings = setDefaultSettings(defaultSettings, 'edit', feesRes);
        }

        form.setFieldsValue(defaultSettings);
        if (propertyData.commercial) {
          setIsCommercial(true);
        }
      }

      // Default settings

      if (!propertyData) {
        const { userData } = session;
        setCreditCardPayments(propertyGroup.settings.acceptCreditCard);
        setAbsorbFees(propertyGroup.settings.absorbFees);
        setPreventPayment(propertyGroup.settings.preventPayments);
        setFullPayment(propertyGroup.settings.requireFullPayment);

        let defaultSettings = {
          percent: propertyGroup.settings.absorbFeesPercent,
          complianceLevel: propertyGroup.settings.complianceLevel,
        };

        if (session.userData.servicePlan.metaFeatures.aDDITIONALAssessments) {
          if (propertyGroup.settings.hasOwnProperty('propertyFees')) {
            defaultSettings = setDefaultSettings(defaultSettings, 'default', null);
          }
        }
        form.setFieldsValue(defaultSettings);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setDefaultSettings = (settingsObj, mode, feesResponse) => {
    // const { userData } = session;
    // const { paymentSettings } = userData;
    // const { propertyFees } = paymentSettings;

    const propertyFees = propertyGroup ? propertyGroup.settings.propertyFees : null;

    settingsObj.securityDepositSwitch =
      mode === 'default' ? propertyFees.securityDeposit : feesResponse.securityDeposit;
    settingsObj.securityDepositAmount =
      mode === 'default' ? propertyFees.securityDepositAmount || '' : feesResponse.securityDepositAmount || '';
    settingsObj.discountAmountEarly =
      mode === 'default' ? propertyFees.discountAmount || '' : feesResponse.discountAmount || '';
    settingsObj.daysEarly = mode === 'default' ? propertyFees.discountDays || '' : feesResponse.discountDays || '';
    settingsObj.daysEarlySwitch = mode === 'default' ? propertyFees.discount : feesResponse.discount;
    settingsObj.lateFeeAmount =
      mode === 'default' ? propertyFees.lateFeeAmount || '' : feesResponse.lateFeeAmount || '';
    settingsObj.lateFeeFrequency =
      mode === 'default' ? propertyFees.lateFeeFrequency || '' : feesResponse.lateFeeFrequency || '';
    settingsObj.daysLateFee = mode === 'default' ? propertyFees.lateFeeDays || '' : feesResponse.lateFeeDays || '';
    settingsObj.daysLateSwitch = mode === 'default' ? propertyFees.lateFee : feesResponse.lateFee;
    settingsObj.parkingFeeAmount =
      mode === 'default' ? propertyFees.parkingAmount || '' : feesResponse.parkingFeeAmount || '';
    settingsObj.periodParkingFee =
      mode === 'default' ? propertyFees.parkingFrequency || '' : feesResponse.parkingFeeFrequency || '';
    settingsObj.parkingFeeSwitch = mode === 'default' ? propertyFees.parking : feesResponse.parkingFee;
    settingsObj.moveInFeeSwitch = mode === 'default' ? propertyFees.moveIn : feesResponse.moveInFee;
    settingsObj.moveInFee =
      mode === 'default' ? propertyFees.moveInAmount || '' : feesResponse.moveInFeeAmount || '';
    settingsObj.moveOutFeeSwitch = mode === 'default' ? propertyFees.moveOut : feesResponse.moveOutFee;
    settingsObj.moveOutFee =
      mode === 'default' ? propertyFees.moveOutAmount || '' : feesResponse.moveOutFeeAmount || '';
    return settingsObj;
  };

  const displayAlert = (text, type, description, time, close, marginBottom) => {
    if (message.message) {
      setMessage({
        message: '',
        description: '',
      });
    }
    setMessage({
      message: text,
      type,
      description,
      closable: !close,
      marginBottom,
    });
    if (close) {
      setTimeout(() => {
        setMessage({
          message: '',
          description: '',
          marginBottom: 0,
        });
      }, time);
    }
  };

  const onFinish = async (values) => {
    // console.log('ƒ values', values);
    try {
      let parentPropertyId = selectedParentProperty
        ? +selectedParentProperty.id
        : editProperty
        ? +editProperty.parentPropertyId
        : +cloneProperty.parentPropertyId;

      values.acceptCreditCard = creditCardPayments;
      values.absorbFees = absorbFees;
      values.preventPayment = preventPayment;
      values.fullPayment = fullPayment;
      values.propertyGroup = props?.editInvitationProperty ? +editProperty.propertyGroup.id : +propertyGroup.id;
      values.parentPropertyId = parentPropertyId;

      let successMessage = '';
      let messageType = 'success';
      let description = '';
      let time = 3000;
      let close = true;
      let marginBottom = 0;

      setIsLoading(true);
      let propertyRes = '';
      let complianceLevel = false;

      if (session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement) {
        complianceLevel = true;
      }

      if (!editProperty) {
        propertyRes = await createProperty(locationData, values, complianceLevel);
        // await updatePropertyPreferences(result.id, null, values);
        successMessage = 'Property successfully created.';
      }
      if (editProperty) {
        const property = {
          address2: values.unit,
          rentDueDay: values.rentDay,
          rentAmount: values.rentAmount,
          commercial: values.propertyCategory,
        };
        if (locationData) {
          property.address = !locationData.streetNumber
            ? locationData.address
            : `${locationData.streetNumber} ${locationData.address}`;
          property.city = locationData.city;
          property.googlePlaceId = locationData.google_place_id;
          property.lat = locationData.lat;
          property.long = locationData.long;
          property.state = locationData.state;
          property.zip = locationData.zip;
        }
        await updatePropertyPreferences(editProperty.id, property, values, complianceLevel);

        successMessage = 'Property successfully updated.';

        if (Number(values.rentAmount) !== editProperty.rentAmount) {
          successMessage = 'Rent Amount Updated';
          messageType = 'info';
          description =
            'The new rent amount will be applied to future charges only. It does not change any rent amounts already charged.';
          time = 5000;
          // close = false;
          marginBottom = 105;
          editProperty.rentAmount = Number(values.rentAmount);
        }
      }

      await updateCreatePropertyFees(cognitoToken, values, propertyRes.id || editProperty.id);
      // dispatch(getRentersListAction(1, 1000, '-lastName'));
      dispatch(getPropertiesListAction(cognitoToken, 1, 10000, '', ''));
      if (fetchProperties) await fetchProperties(+propertyGroup.id, page, false);

      setIsLoading(false);
      displayAlert(successMessage, messageType, description, time, close, marginBottom);

      if (handleRefreshUnits) {
        handleRefreshUnits(parentPropertyId);
      }

      setTimeout(() => {
        dispatch(getSummaryDataAction(cognitoToken, 'properties'));
        if (close) {
          setShowUnitPanel(false);
          if (!props.editInvitationProperty) setDrawerVisible(false);
          if (props.editInvitationProperty) props.setShowInvitationPanel(true);
        }
        if (props.setEditProperty && close) props.setEditProperty(null);
        if (props.setCloneProperty && close) props.setCloneProperty(null);
        if (location.pathname !== 'landlord/properties' && !props.editInvitationProperty)
          history.push('/landlord/properties');
      }, time);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.title;
      displayAlert(detail || 'Request failed.', 'warning', '', 2000, true);
    }
  };

  const onValuesChange = (values) => {
    if (values.securityDepositAmount) {
      if (isNaN(values.securityDepositAmount)) {
        form.setFieldsValue({ securityDepositAmount: '' });
      }
    }
    if (values.discountAmountEarly) {
      if (isNaN(values.discountAmountEarly)) {
        form.setFieldsValue({ discountAmountEarly: '' });
      }
    }
    if (values.daysEarly) {
      if (isNaN(values.daysEarly)) {
        form.setFieldsValue({ daysEarly: '' });
      }
    }
    if (values.lateFeeAmount) {
      if (isNaN(values.lateFeeAmount)) {
        form.setFieldsValue({ lateFeeAmount: '' });
      }
    }
    if (values.daysLateFee) {
      if (isNaN(values.daysLateFee)) {
        form.setFieldsValue({ daysLateFee: '' });
      }
    }
    if (values.parkingFeeAmount) {
      if (isNaN(values.parkingFeeAmount)) {
        form.setFieldsValue({ parkingFeeAmount: '' });
      }
    }
    if (values.moveInFee) {
      if (isNaN(values.moveInFee)) {
        form.setFieldsValue({ moveInFee: '' });
      }
    }
    if (values.moveOutFee) {
      if (isNaN(values.moveOutFee)) {
        form.setFieldsValue({ moveOutFee: '' });
      }
    }
    // Switchs
    if (values?.securityDepositSwitch === false) {
      form.resetFields(['securityDepositAmount']);
    }
    if (values?.daysEarlySwitch === false) {
      form.resetFields(['discountAmountEarly', 'daysEarly']);
    }

    if (values?.complianceLevel != undefined) {
      // if (form.getFieldValue('complianceLevel') === 0) {
      //   form.setFieldsValue({ daysLateSwitch: false });
      //   form.resetFields(['lateFeeAmount', 'daysLateFee', 'lateFeeFrequency']);
      // }

      if (form.getFieldValue('complianceLevel') === 1 || form.getFieldValue('complianceLevel') === 2) {
        form.setFieldsValue({ daysLateSwitch: true });
      }
    }

    if (values?.daysLateSwitch != undefined) {
      form.setFieldsValue({ daysLateSwitch: values?.daysLateSwitch });
    }
    if (values?.daysLateSwitch === false) {
      form.resetFields(['lateFeeAmount', 'daysLateFee']);
    }
    if (values?.parkingFeeSwitch === false) {
      form.resetFields(['parkingFeeAmount', 'periodParkingFee']);
    }
    if (values?.moveInFeeSwitch === false) {
      form.resetFields(['moveInFee']);
    }
    if (values?.moveOutFeeSwitch === false) {
      form.resetFields(['moveOutFee']);
    }
    if (values?.complianceLevel) {
      form.setFieldsValue({ daysLateSwitch: true });
    }
    // if (values?.hasOwnProperty('complianceLevel')) {
    //   if (!values.complianceLevel) {
    //     form.setFieldsValue({ daysLateSwitch: false });
    //     form.resetFields(['lateFeeAmount', 'daysLateFee', 'lateFeeFrequency']);
    //   }
    // }
    if (values?.hasOwnProperty('propertyCategory')) return setIsCommercial(values.propertyCategory);
  };

  const handleCreditCardPayments = (e) => {
    setCreditCardPayments(e.target.checked);
  };

  const handleAbsorbFees = (e) => {
    setAbsorbFees(e.target.checked);
  };

  const handleFullPayment = (e) => {
    setFullPayment(e.target.checked);
  };

  const handlePreventPayment = (e) => {
    setPreventPayment(e.target.checked);
  };

  const datesArray = () => {
    let days = [];
    for (let i = 1; i <= 31; i += 1) {
      days[i] = {
        label: `${numberTOrdinalSuffix(i)} of the month`,
        value: i,
      };
    }
    setFormDays(days);
  };

  const checkPrice = (rule, value) => {
    if (Number(value) >= 0) {
      return Promise.resolve();
    }
    return Promise.reject('Payment amount cannot be empty');
  };

  const checkPercentage = (rule, value) => {
    if (absorbFees) {
      if (Number(value) <= 100 && Number(value) > 0) {
        return Promise.resolve();
      }
      return Promise.reject('Please enter a valid percentage.');
    }
    return Promise.resolve();
  };

  const check = (obj, arr) => {
    return arr.every((e) => Object.keys(obj).includes(e));
  };

  useEffect(() => {
    if (!addressInput && (googleSelected.current || customAddressInput.current)) {
      setLocationData(null);
      googleSelected.current = false;
      customAddressInput.current = false;
      form.validateFields(['address']);
    }
    if (addressInput && addressInput !== googleString) {
      const addr = parser.parseLocation(addressInput);

      if (addr) {
        const validKeyNames = ['city', 'number', 'state', 'street', 'zip'];

        const validAddr = check(addr, validKeyNames);
        if (validAddr) {
          // console.log(addr);
          const usState = usStates.filter((s) => s.abbreviation === addr.state.toUpperCase());
          const customAddress = {
            streetNumber: addr.number,
            address: addr.type ? `${addr.street} ${addr.type}` : addr.street,
            city: addr.city,
            state: usState[0].name.charAt(0).toUpperCase() + usState[0].name.slice(1).toLowerCase(),
            google_place_id: v4(),
            lat: 0,
            long: 0,
            zip: addr.zip,
            country: 'United States',
          };
          setLocationData({ ...customAddress });
          customAddressInput.current = true;
        } else {
          customAddressInput.current = false;
          setLocationData(null);
          form.validateFields(['address']);
        }
      }
    }
  }, [addressInput]);

  useEffect(() => {
    if (customAddressInput.current || googleSelected.current) {
      form.validateFields(['address']);
    }
  }, [locationData]);

  const checkNumber = (rule, value) => {
    if (!value) {
      return Promise.reject('Required');
    }
    if (value) {
      if (Number(value) >= 0) {
        return Promise.resolve();
      }
      return Promise.reject('Value should be a number');
    }
  };

  const checkNumberInt = (rule, value) => {
    if (!value) {
      return Promise.reject('Required');
    }
    if (value) {
      const n = +value;
      if (n > 0) {
        if (Number.isInteger(n)) {
          return Promise.resolve();
        }
      }
      return Promise.reject('Value should be a number');
    }
  };

  const handleInputLeave = (e) => {
    const number = Number(e.target.value).toFixed(2);
    amountInput.current.blur();

    form.setFieldsValue({
      rentAmount: number,
    });
  };

  const handleDrawerReset = () => {
    if (!props.editInvitationProperty) props.setDrawerVisible(false);
    setShowUnitPanel(false);
    if (props.setEditProperty) props.setEditProperty(null);
    if (props.setCloneProperty) props.setCloneProperty(null);
    if (props.editInvitationProperty) props.setShowInvitationPanel(true);
  };

  return (
    <>
      <DrawerContainer data-testid="propertiesContainerTest">
        <StyledForm
          form={form}
          name="basic"
          initialValues={{
            propertyCategory: false,
            bedrooms: 0,
            bathrooms: 0.0,
            percent: 100,
            acceptCreditCard: true,
            absorbFees: session.userData.servicePlan.metaFeatures.rENTERPAYSFees,
            securityDepositSwitch: false,
            daysEarlySwitch: false,
            daysLateSwitch: false,
            parkingFeeSwitch: false,
            moveInFeeSwitch: false,
            moveOutFeeSwitch: false,
          }}
          onFinish={onFinish}
          onFinishFailed={() => {}}
          onValuesChange={onValuesChange}
        >
          <Header>
            <CloseSquareFilled
              style={{ fontSize: '25px', color: '#122C34' }}
              onClick={handleDrawerReset}
              data-testid="closePropertiesTest"
            />
          </Header>
          <Title>Unit</Title>

          <Subtitle>Add or edit your Unit details</Subtitle>
          <SpaceResponsive lg={10} md={10} sm={10} />
          <FormContentContainer className="content-container">
            <Row gutter={16}>
              <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item name="propertyCategory" label="Type">
                  <Segmented
                    options={[
                      { label: 'Residential', value: false },
                      { label: 'Commercial', value: true },
                    ]}
                    size="small"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="general-settings">
              <Col span={8}>
                Unit No.
                <Form.Item name="unit">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                Bedrooms
                <Form.Item
                  name="bedrooms"
                  rules={
                    !isCommercial && [
                      {
                        validator: checkNumberInt,
                      },
                    ]
                  }
                >
                  <Input disabled={isCommercial} />
                </Form.Item>
              </Col>
              <Col span={8}>
                Baths
                <Form.Item
                  name="bathrooms"
                  rules={
                    !isCommercial && [
                      {
                        validator: checkNumber,
                      },
                    ]
                  }
                >
                  <Input disabled={isCommercial} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15} className="general-settings">
              <Col span={16}>
                <br />
                <Form.Item
                  name="rentDay"
                  whitespace="true"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your rent date',
                    },
                  ]}
                >
                  <Select placeholder="Rent Due Date" onChange={props.handleSelect} data-testid="dueDayTest">
                    {formDays.map((d, i) => (
                      <Option value={d.value} key={i}>
                        {d.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                Amount
                <Form.Item
                  name="rentAmount"
                  data-testid="amountTest"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input
                    data-testid="amountInputTest"
                    ref={(input) => (amountInput.current = input)}
                    onMouseLeave={handleInputLeave}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Space vertical={20} />
            <Text color="black" strong>
              Property Settings
            </Text>
            <PaymentControlsContainer>
              <Form.Item
                name="acceptCreditCard"
                getValueProps={() => creditCardPayments}
                style={{ marginBottom: '10px !important' }}
              >
                <Checkbox
                  onClick={handleCreditCardPayments}
                  checked={creditCardPayments}
                  // disabled={!propertyGroup?.settings?.acceptCreditCard || false}
                >
                  <Text color="#595959">Accept credit card payments</Text>
                  <Tooltip
                    placement="top"
                    title="Choose to allow renters to pay rent with a credit card. Renters pay these fees."
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ccTooltip" />
                  </Tooltip>
                </Checkbox>
              </Form.Item>

              <Row>
                <Col span={6} className="absorbField">
                  <PremiumFeaturePopover
                    cta="Split or pass-through ACH transaction fees."
                    showPopover={!renterFeesEnabled}
                    handleDrawerReset={handleDrawerReset}
                  >
                    <Form.Item name="absorbFees" getValueProps={() => absorbFees}>
                      <Checkbox onClick={handleAbsorbFees} checked={absorbFees} disabled={!renterFeesEnabled}>
                        <Text color="#595959">Absorb</Text>
                      </Checkbox>
                    </Form.Item>
                  </PremiumFeaturePopover>
                </Col>
                <Col span={16}>
                  <Form.Item>
                    <Form.Item
                      name="percent"
                      noStyle
                      rules={[
                        {
                          validator: checkPercentage,
                        },
                      ]}
                    >
                      <Input
                        disabled={!renterFeesEnabled || !absorbFees}
                        style={{ width: '23%' }}
                        data-testid="absorbPercentTest"
                      />
                    </Form.Item>
                    <span>&nbsp;&nbsp;percent of ACH fees</span>
                    <Tooltip
                      placement="top"
                      title="Absorb, split or pass-through ACH transaction fees. If selected, you are paying some percentage of ACH fees."
                      trigger="click"
                    >
                      <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="absorbFeeTooltip" />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="fullPayment" getValueProps={() => fullPayment}>
                <Checkbox onClick={handleFullPayment} checked={fullPayment}>
                  <Text color="#595959">Require full payment</Text>
                  <Tooltip
                    placement="top"
                    title="If selected, renters must pay their full account balance. If their account balance is zero, they cannot make a payment"
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="fullPaymentTooltip" />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
              <Form.Item name="preventPayment" getValueProps={() => preventPayment}>
                <Checkbox onClick={handlePreventPayment} checked={preventPayment}>
                  <Text color="#595959">Prevent payment</Text>
                  <Tooltip
                    placement="top"
                    title="If selected, renters are prevented from making any payments."
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
            </PaymentControlsContainer>
            <Space vertical={20} />
            <PropertyRentPolicy
              servicePlan={session.userData.servicePlan}
              setShowAccountPreferences={props.setShowAccountPreferences}
              setShowUnitPanel={setShowUnitPanel}
              setDrawerVisible={props.setDrawerVisible}
            />

            <Text strong color="black">
              Additional Assessments
            </Text>
            <Tooltip
              placement="top"
              title="These are additional billing items automatically posted to a renters account based on the rules you set below."
              trigger="click"
            >
              <InfoCircleFilled style={{ marginLeft: '8px' }} />
            </Tooltip>
            <PaymentControlsContainer>
              {/* {!session.userData.servicePlan.metaFeatures.aDDITIONALAssessments ? (
                <AdvancedFeaturesOverlay
                  setDrawerVisible={props.setDrawerVisible}
                  setShowAccountPreferences={props.setShowAccountPreferences}
                  setShowUnitPanel={setShowUnitPanel}
                />
              ) : (
                ''
              )} */}
              <AdditionalFeesFields
                form={form}
                additionalAssessmentsEnabled={additionalAssessmentsEnabled}
                handleDrawerReset={handleDrawerReset}
              />
            </PaymentControlsContainer>
          </FormContentContainer>
          <PanelFooter>
            <AlertFade
              type={message.type}
              isVisible={!!message.message}
              message={message.message}
              description={message.description}
              alertPosition="absolute"
              position="absolute"
              minHeight="40"
              closable={message.closable}
              marginBottom={message.marginBottom}
              // width={90}
              bottom={82}
            />

            <SaveItem className="save-form-item">
              <Button
                type="primary"
                htmlType="submit"
                bottom={0}
                color="violet"
                loading={isLoading}
                alignment="center"
                data-testid="buttonTest"
                size="large"
              >
                SAVE
              </Button>
            </SaveItem>
          </PanelFooter>
        </StyledForm>
      </DrawerContainer>
    </>
  );
};

const DrawerContainer = styled.div`
  margin: 0px auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;

  border-radius: 24px;
  .general-settings.ant-row .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-select-selector {
    height: 31px !important;
    color: #595959;
  }
  .rent-policy-select .ant-select-selector {
    height: 50px !important;
  }

  @media screen and (max-height: 750px) {
    min-height: 96%;
    .ant-form-item.save-form-item {
      margin-bottom: 0px !important;
    }
    .content-container .ant-form-item {
      margin-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    .ant-col.ant-col-5.absorbField {
      flex: 0 0 27%;
      max-width: 30%;
    }
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  @media screen and (max-height: 750px) {
    margin-bottom: 0px !important;
  }
`;

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 25px 0px 25px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const PaymentControlsContainer = styled.div`
  margin-top: 10px;
  position: relative;
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

export default UnitPanel;
