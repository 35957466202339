import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Input, Row, Col, Select, DatePicker } from 'antd';

// import { getSubtitleSpace } from 'resources/helpers';
import { createTransaction } from 'services/api/landlordApi/create';
import {
  getSummaryDataAction,
  setSummaryPaymentType,
  unsetTransactionRenter,
} from 'store/actions/landlordActions';

import { getRentersV3 } from 'services/api/landlordApi/read';
import { CloseSquareFilled } from '@ant-design/icons';

import PanelFooter from 'components/Panel/PanelFooter';
import SpaceResponsive from 'components/SpaceResponsive';
import AlertFade from 'components/AlertFade';
import Button from 'components/Button';

import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../../components/Panel/PanelHeader/PanelHeader';

const { Option } = Select;

const TransactionPanel = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = useState({ type: 'success', message: '' });
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const landlord = useSelector((state) => state.landlord);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const [form] = Form.useForm();
  const [renters, setRenters] = useState({ items: [], totalCount: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRenter, setSelectedRenter] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [newBalance, setNewBalance] = useState(0);
  const [inheritedRenter, setInheritedRenter] = useState(null);
  const [loadingRenters, setLoadingRenters] = useState(false);

  useEffect(() => {
    // const paymentType = landlord.summaryCards.payments.type;
    // if (paymentType !== 'default' && paymentType !== '') {
    //   form.setFieldsValue({
    //     transactionType: paymentType === 'ADD_CREDIT' ? 'Credit' : 'Rent Charge',
    //   });
    // }
    const controller = new AbortController();
    fetchData(controller);
    return () => controller.abort();
  }, []);

  const fetchData = async (controller) => {
    try {
      setLoadingRenters(true);
      const rentersRes = await getRentersV3(cognitoToken, 1, 10000, '', '', controller);

      setRenters(rentersRes);
      setLoadingRenters(false);
    } catch (error) {
      console.log(error);
      displayAlert('Request failed.', 'warning');
    }
  };

  const displayAlert = (text, type) => {
    setMessage({
      message: text,
      type,
    });
    setTimeout(() => {
      setMessage({
        message: '',
      });
    }, 1500);
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);

      if (landlord.addTransaction.userName && inheritedRenter) {
        values.renterId = inheritedRenter;
      }
      // console.log(values);
      await createTransaction(values);

      setIsLoading(false);
      setIsDisabled(true);
      displayAlert('Transaction successfully created.', 'success');
      if (props.fetchProperties) props.fetchProperties();
      if (props.fetchTransactions) props.fetchTransactions();
      setTimeout(() => {
        dispatch(getSummaryDataAction(cognitoToken, 'payments'));
        dispatch(setSummaryPaymentType(''));
        if (props.fetchActivityFeed) props.fetchActivityFeed();
        props.setDrawerVisible(false);
        props.setShowTransactionPanel(false);
        setIsDisabled(false);
        if (location.pathname !== 'landlord/transactions') history.push('/landlord/transactions');
        if (landlord.addTransaction.userName) dispatch(unsetTransactionRenter());
      }, 1000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.detail;
      displayAlert(detail || 'Request failed.', 'warning');
    }
  };

  const checkPrice = (rule, value) => {
    if (Number(value) > 0) {
      return Promise.resolve();
    }
    if (isNaN(Number(value))) {
      return Promise.reject('Amount is not a valid number.');
    }
    return Promise.reject('Payment amount cannot be equal or less than $0.00');
  };
  const handleInputLeave = (e) => {
    const number = Number(e.target.value).toFixed(2);
    form.setFieldsValue({
      transactionAmount: number,
    });
  };
  const getBalance = (data) => {
    const account = data.billingAccounts.filter((bi) => bi.state === 'ACTIVE' || bi.state === 'PENDING');
    return account.length ? account[0].balance : 0;
  };

  const handleSelectOnChange = (value, autoset) => {
    if (autoset !== true) {
      setInheritedRenter(null);
    }

    const renter = JSON.parse(value);
    const renterDetail = renters.items.filter((r) => r.id === renter.id);

    const balance = getBalance(renterDetail[0]);
    setNewBalance(balance);
    setSelectedRenter(renterDetail);
  };

  const calculateNewBalance = (e) => {
    if (
      selectedRenter.length &&
      form.getFieldValue('transactionType') &&
      !isNaN(form.getFieldValue('transactionAmount'))
    ) {
      const transactionType = form.getFieldValue('transactionType');
      const balance = getBalance(selectedRenter[0]);
      const amount = form.getFieldValue('transactionAmount');

      let total = 0;
      switch (transactionType) {
        case 'Rent Charge':
          total = +balance + +amount;
          setNewBalance(total.toFixed(2));
          break;
        case 'Fee':
          total = +balance + +amount;
          setNewBalance(total.toFixed(2));
          break;
        case 'Utility':
          total = +balance + +amount;
          setNewBalance(total.toFixed(2));
          break;
        case 'Payment':
          total = +balance - +amount;
          setNewBalance(total.toFixed(2));
          break;
        case 'Credit':
          total = +balance - +amount;
          setNewBalance(total.toFixed(2));
          break;
        case 'Security Deposit':
          total = +balance + +amount;
          setNewBalance(total.toFixed(2));
          break;
        case 'Refund':
          total = +balance - +amount;
          setNewBalance(total.toFixed(2));
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (renters.items.length) {
      if (landlord.addTransaction.userName) {
        // it will be a problem with user with the same name - should we add the property name?
        form.setFieldsValue({
          renterId: landlord.addTransaction.userName,
        });

        const selectInstance = form.getFieldInstance('renterId');
        // console.log(selectInstance);
        const selected = selectInstance.props.children.filter(
          (s) => s.props.children === landlord.addTransaction.userName
        );
        setInheritedRenter(selected[0].props.value);
        handleSelectOnChange(selected[0].props.value, true);
      }
    }
  }, [renters]);

  useEffect(() => {
    if (selectedRenter.length) {
      calculateNewBalance();
    }
  }, [selectedRenter]);

  return (
    <>
      <DrawerContainer data-testid="transactionPanelContainerTest">
        <StyledForm
          form={form}
          name="basic"
          initialValues={{ transactionAmount: '0.00' }}
          onFinish={onFinish}
          onFinishFailed={() => {}}
          layout="vertical"
          requiredMark={false}
        >
          <Header>
            <CloseSquareFilled
              style={{ fontSize: '25px', color: '#122C34' }}
              onClick={() => {
                props.setDrawerVisible(false);
                props.setShowTransactionPanel(false);
                dispatch(setSummaryPaymentType(''));
                dispatch(unsetTransactionRenter());
              }}
              data-testid="closeTransactionTest"
            />
          </Header>
          <Title>Transaction</Title>

          <Subtitle>Manually add a charge, credit or payment to your renter´s account.</Subtitle>
          <SpaceResponsive lg={30} md={30} sm={30} />
          <FormContentContainer className="content-container">
            <Form.Item
              label="Tenant"
              name="renterId"
              data-testid="tenantTest"
              rules={[{ required: true, message: 'Please select a renter.' }]}
            >
              <Select
                placeholder="Select Tenant"
                showSearch
                optionFilterProp="children"
                onChange={handleSelectOnChange}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                data-testid="tenantSelectTest"
                loading={loadingRenters}
              >
                {renters.items.length &&
                  renters.items.map((renter, i) => (
                    <Option
                      value={JSON.stringify({
                        id: renter.id,
                        billingAccount: renter.billingAccounts.filter(
                          (b) => b.state === 'ACTIVE' || b.state === 'PENDING'
                        )[0].id,
                      })}
                      key={i}
                    >{`${renter.firstName} ${renter.lastName} - ${renter?.billingAccounts[0].property?.address} ${renter?.billingAccounts[0].property?.address2}`}</Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Transaction Type"
              name="transactionType"
              data-testid="transactionTest"
              rules={[
                {
                  required: true,
                  message: 'Please select one transaction type.',
                  whitespace: true,
                },
              ]}
            >
              <Select
                placeholder="Select Transaction Type"
                onChange={calculateNewBalance}
                data-testid="transactionTypeTest"
              >
                <Option value="Credit" key="1">
                  Credit
                </Option>
                <Option value="Payment" key="2">
                  Payment
                </Option>
                <Option value="Security Deposit" key="3">
                  Security Deposit
                </Option>
                <Option value="Rent Charge" key="5">
                  Rent Charge
                </Option>
                <Option value="Utility" key="7">
                  Utility
                </Option>
                <Option value="Fee" key="4">
                  Other Charge
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Memo"
              name="memo"
              rules={[
                {
                  required: true,
                  message: 'Please enter a memo.',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Enter a memo" data-testid="memoInputTest" />
            </Form.Item>
            <StyledRow gutter={16} justify="start">
              <Col span={12}>
                <Form.Item
                  label="Amount"
                  name="transactionAmount"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input
                    prefix="$"
                    // onMouseLeave={handleInputLeave}
                    onFocus={() => form.setFieldsValue({ transactionAmount: '' })}
                    onChange={calculateNewBalance}
                    data-testid="amountInputTest"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Transaction Date"
                  name="transactionDate"
                  data-testid="transactionDateTest"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a date.',
                    },
                  ]}
                  shouldUpdate
                >
                  <DatePicker format="MM/DD/YYYY" placeholder="mm/dd/yyyy" />
                </Form.Item>
              </Col>
            </StyledRow>
            <SpaceResponsive lg={20} md={10} sm={10} />
            <Subtitle>
              Transaction amount will be applied on the selected date. Future transactions will appear in the
              renter’s account as “upcoming”.
            </Subtitle>
            <SpaceResponsive lg={20} md={10} sm={10} />
            <StyledDiv>
              <AccountBalanceBox>
                New Account Balance
                <br />
                <AccountStatusNumber hasInFavor={newBalance < 0}>
                  ${newBalance}
                  <small>{newBalance >= 0 ? 'DUE' : 'CREDIT'}</small>
                </AccountStatusNumber>
              </AccountBalanceBox>
            </StyledDiv>
          </FormContentContainer>
          <PanelFooter>
            <AlertFade
              type={message.type}
              isVisible={!!message.message}
              message={message.message}
              alertPosition="absolute"
              position="absolute"
              minHeight="40"
              width={90}
              bottom={82}
            />
            <SaveItem className="save-form-item">
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  color="violet"
                  bottom={0}
                  loading={isLoading}
                  alignment="center"
                  data-testid="buttonTest"
                  disabled={isDisabled}
                  size="large"
                >
                  SAVE
                </Button>
              </div>
            </SaveItem>
          </PanelFooter>
        </StyledForm>
      </DrawerContainer>
    </>
  );
};

const DrawerContainer = styled.div`
  margin: 0px auto;
  margin-left: 0px;
  margin-right: 0px;
  max-width: 100%;
  height: 100%;

  .ant-select-selector {
    height: 31px !important;
    color: #595959;
  }

  @media screen and (max-height: 750px) {
    .ant-form-item.save-form-item {
      margin-bottom: 0px !important;
    }
  }

  @media screen and (max-width: 480px) {
    .ant-picker {
      width: 143px;
    }
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  @media screen and (max-height: 750px) {
    margin-bottom: 0px !important;
  }
`;

const FormContentContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 25px 0px 25px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const AccountStatusNumber = styled.span`
  color: ${(props) => (props.hasInFavor ? '#85BB65' : 'red')};
  font-size: 19px;
  font-weight: 500;
  align-items: baseline;
  justify-content: center;
  display: flex;

  small {
    font-size: 12px;
    margin-left: 10px;
  }
`;

const StyledDiv = styled.div`
  position: relative;
  height: 40px;
`;

const AccountBalanceBox = styled.div`
  font-size: 14px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  width: 313px;
  height: 61px;
  text-align: center;
  background-color: #f7f7f0;
`;

const StyledRow = styled(Row)`
  margin-bottom: -14px;
`;

export default TransactionPanel;
