import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Text from 'components/Text';
import Space from 'components/Space';
import Button from 'components/Button';

const AdvancedFeaturesOverlay = (props) => {
  const history = useHistory();

  return (
    <StyledOverlay className="upgrade-overlay">
      <Container>
        <Text color="violet2" size={14} strong>
          Interested In These Advanced Features?
        </Text>
        <Space vertical={20} />
        <Text>Get full access to late fees, security deposits and more when you upgrade your service plan.</Text>
        <Space vertical={20} />
        <Button
          color="yellow"
          onClick={() => {
            history.push('/landlord/service-plans');
            if (props.setDrawerVisible) props.setDrawerVisible(false);
            if (props.setShowAccountPreferences) props.setShowAccountPreferences(false);
            if (props.setShowPropertyPanel) props.setShowPropertyPanel(false);
          }}
          data-testid="upgradeButtonTest"
          alignment="center"
        >
          UPGRADE
        </Button>
        <Text
          color="violet"
          pointer
          underline
          size={14}
          onClick={() => {
            history.push('/landlord/service-plans');
            if (props.setDrawerVisible) props.setDrawerVisible(false);
            if (props.setShowAccountPreferences) props.setShowAccountPreferences(false);
            if (props.setShowPropertyPanel) props.setShowPropertyPanel(false);
          }}
        >
          Learn more
        </Text>
      </Container>
    </StyledOverlay>
  );
};

const Container = styled.div`
  width: 85%;
`;

const StyledOverlay = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  margin-left: -5px;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.86);
  @media screen and (max-width: 420px) {
    width: 100%;
    margin-left: -5%;
  }
`;

export default AdvancedFeaturesOverlay;
