import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { Card as AntCard, Drawer, Spin } from 'antd';

import * as tenantApi from 'services/api/tenantApi';

import { getPaymentMethods } from 'store/actions/tenantActions';
import { pollCheck } from 'resources/helpers';

import Title from 'components/Title';
import BottomCard from 'components/BottomCard';
import AlertFade from 'components/AlertFade';
import CardBlank from 'components/CardBlank';
import Space from 'components/Space';
import RentCredStatus from './components/RentCredStatus';
import LateFeeForgiveness from './components/LateFeeForgiveness';
import Benefits from './components/Benefits';
import CreditBoost from './components/CreditBoost';
import FinancialGoals from './components/FinancialGoals';
import RentCredProfile from '../components/RentCredProfile';
import InsuranceAlert from '../components/InsuranceAlert';
import AccountStatus from '../components/AccountStatus';
import ComplianceAlert from '../components/ComplianceAlert';
import PaymentMethods from '../components/PaymentMethods';
import PaymentSchedule from '../Payment/PaymentSchedule';
import PaymentMethodDetail from '../components/PaymentMethods/components/PaymentMethodDetail';
import AccountStatusTitle from '../components/AccountStatusTitle';
import MicrodepositAlert from '../components/MicrodepositAlert';

const RentCred = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const [payMethodsVisible, showPayMethods] = useState(false);
  const [showPaymentMethodDetail, setShowPaymentMethodDetail] = useState(false);
  const [defPaymentMethod, setDefPaymentMethod] = useState(false);
  const [showScheduledPayment, setShowScheduledPayment] = useState(false);
  const [showRentCredProfile, setShowRentCredProfile] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showRadioButtons, setShowRadioButtons] = useState(true);
  const [paymentMethodId, setPaymentMethodId] = useState(null);

  const [balanceDue, setBalanceDue] = useState(true);
  const [otherAmount, setOtherAmount] = useState(0);

  const paymentMethods = useSelector((store) => store.tenant.paymentMethods);
  // const session = useSelector((store) => store.session);
  const userData = useSelector((store) => store.session.userData);
  const rentCredStatus = useSelector((store) => store.tenant.rentCred);
  const globalLoading = useSelector((store) => store.global.loading);
  const alert = useSelector((store) => store.global.alert);
  const [drawerVisible, setDrawerVisible] = useState(false);

  let timerId = null;

  // const mountedRef = useRef(true);

  // useEffect(() => {
  //   fetchData();
  //   return () => {
  //     mountedRef.current = false;
  //   };
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     let tierId = null;
  //     let points = null;

  //     const params = new URLSearchParams(history.location.search);

  //     if (params.get('tier-id')) {
  //       tierId = params.get('tier-id');
  //     }
  //     if (params.get('points')) {
  //       points = params.get('points');
  //     }
  //     if (params.get('profile')) {
  //       if (params.get('profile') === 'true') {
  //         setDrawerVisible(true);
  //         setShowRentCredProfile(true);
  //       }
  //     }
  //     const res = await tenantApi.getRentCred(cognitoToken, session.userData.id);

  //     if (tierId) res.renterTierId = +tierId;
  //     if (points) res.points = +points;

  //     setRentCredStatus(res);
  //     if (!mountedRef.current) return null;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const togglePaymentMethods = () => {
    showPayMethods(!payMethodsVisible);
    setDefPaymentMethod(true);
  };

  const checkPaymentMethodStatus = async () => {
    const updated = dispatch(
      getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken)
    );
    const arr = updated?.payload ? updated.payload : [];
    const poll = pollCheck(arr);
    if (poll) {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        try {
          const methods = await tenantApi.getPaymentMethodsV3(
            userData.paymentGateway,
            false,
            session.cognitoTokens.idToken.jwtToken
          );

          const update = pollCheck(methods);
          if (!update) {
            dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
            clearInterval(timerId);
          }
        } catch (error) {
          console.log(error);
        }
      }, 3000);
    }
  };

  return (
    <>
      <StyledDrawer
        closable={false}
        placement="right"
        destroyOnClose
        onClose={() => {
          setDrawerVisible(false);
          setShowRentCredProfile(false);
          setShowPaymentMethodDetail(false);
          setShowScheduledPayment(false);
        }}
        open={drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {showRentCredProfile && <RentCredProfile setDrawerVisible={setDrawerVisible} />}

        {showScheduledPayment && (
          <PaymentSchedule
            showScheduledPayment={showScheduledPayment}
            setShowScheduledPayment={setShowScheduledPayment}
            setDrawerVisible={setDrawerVisible}
            balanceDue={balanceDue}
            otherAmount={otherAmount}
          />
        )}
        {showPaymentMethodDetail && (
          <PaymentMethodDetail
            setDrawerVisible={setDrawerVisible}
            setShowPaymentMethodDetail={setShowPaymentMethodDetail}
            payMethodsVisible={payMethodsVisible}
            checkPaymentMethodStatus={checkPaymentMethodStatus}
            defPaymentMethod={defPaymentMethod}
            setDefPaymentMethod={setDefPaymentMethod}
          />
        )}
      </StyledDrawer>
      <CardBlank title={<AccountStatusTitle userData={userData} />}>
        <AlertFade
          isVisible={alert.isVisible}
          message={alert.message}
          description={alert.description}
          type={alert.type}
          display
          closable={alert.closable}
          marginTop={10}
          onClose={() => {
            dispatch({
              type: 'UNSET_ALERT',
            });
          }}
        />
        {globalLoading && (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        )}
        <MicrodepositAlert />
        <InsuranceAlert />
        <ComplianceAlert
          setDrawerVisible={setDrawerVisible}
          togglePaymentMethods={togglePaymentMethods}
          setShowScheduledPayment={setShowScheduledPayment}
        />
      </CardBlank>
      <Space vertical={10} />
      <RentCredStatus rentCredStatus={rentCredStatus} />
      <Space vertical={10} />
      <Benefits rentCredStatus={rentCredStatus} />
      <Space vertical={10} />
      <AntCard style={{ body: { padding: '10px 24px' } }}>
        <LateFeeForgiveness rentCredStatus={rentCredStatus} />
      </AntCard>
      <Space vertical={10} />
      <CreditBoost rentCredStatus={rentCredStatus} />
      <Space vertical={10} />
      <FinancialGoals setDrawerVisible={setDrawerVisible} setShowRentCredProfile={setShowRentCredProfile} />
      <BottomCard
        isVisible={payMethodsVisible}
        setVisible={showPayMethods}
        /*
         * Do not use offsetHeight/clientHeight or similar to get the container height for the translation
         * It could lead to undesired behaviors due to the nature of Transition and onClickOutside components
         */
        translation={180 + paymentMethods.length * 41}
        outsideClickIgnoreClass={'ant-dropdown-menu'}
      >
        <Title level={4} color="black" bottom={20} centered>
          Payment Methods
        </Title>
        <PaymentMethods
          payMethodsVisible={payMethodsVisible}
          showPayMethods={showPayMethods}
          showPaymentMethodDetail={showPaymentMethodDetail}
          setShowPaymentMethodDetail={setShowPaymentMethodDetail}
          setDrawerVisible={setDrawerVisible}
          setShowPayment={setShowPayment}
          showRadioButtons={showRadioButtons}
          setPaymentMethodId={setPaymentMethodId}
          defPaymentMethod={defPaymentMethod}
          setDefPaymentMethod={setDefPaymentMethod}
        />
      </BottomCard>
    </>
  );
};

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
  `}
`;

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
`;
export default RentCred;
