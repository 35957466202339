import React from 'react';
import { Divider } from 'antd';
import MoreRenterActions from './MoreRenterActions';
import EditRenter from './EditRenter';
import DeleteRenter from './DeleteRenter';

const RenterMenu = (props) => {
  return (
    <div style={{ marginLeft: '16px' }} data-testid="renterMenuTest">
      <EditRenter {...props} />
      <Divider type="vertical" />
      <DeleteRenter {...props} />
      <Divider type="vertical" />
      <MoreRenterActions {...props} />
    </div>
  );
};

export default RenterMenu;
