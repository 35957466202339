import React, { useCallback, useEffect, useState, useRef } from 'react';
import { isDesktop } from 'react-device-detect';
import DesktopGetStarted from './components/DesktopGetStarted';
import MobileGetStarted from './components/MobileGetStarted';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';

function GetStarted() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const session = useSelector((store) => store.session);
  const userData = session.userData;
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const dispatch = useDispatch();
  const statusRef = useRef(false);

  const stepTitle = {
    0: 'Customize Your Portfolio',
    1: 'Add a Property',
    2: 'Add Unit Details',
    3: 'Accept Payments',
    4: 'Invite Your Tenant',
  };

  const commonProps = {
    stepTitle,
    currentStep,
    setCurrentStep,
    isDesktop,
    isLoading,
    setIsLoading,
  };

  const fetchAndSetBoardingStatus = useCallback(
    (quickStart) => {
      if (!quickStart) {
        dispatch(
          updateQuickStartStatusAction(userData.id, { visits: 1, lastCompletedStep: -1, finished: false }),
          cognitoToken
        );
        return;
      } else {
        const addVisit = quickStart.visits + 1;
        dispatch(updateQuickStartStatusAction(userData.id, { ...quickStart, visits: addVisit }, cognitoToken));
        setCurrentStep(quickStart.lastCompletedStep + 1);
      }
      statusRef.current = true;
    },
    [cognitoToken, dispatch, userData.id]
  );

  useEffect(() => {
    // console.log('[GetStarted.js] useEffect on landlordMeta', userData?.landlordMeta);
    if (!userData || statusRef.current) return;
    const quickStart = userData.landlordMeta.landlordProfile?.landlordProfile?.quickStart;
    fetchAndSetBoardingStatus(quickStart);
  }, [fetchAndSetBoardingStatus, userData]);

  return (
    <StyledContainer>
      {isDesktop ? <DesktopGetStarted {...commonProps} /> : <MobileGetStarted {...commonProps} />}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin: 48px auto;
`;

export default GetStarted;
