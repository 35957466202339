import React, { useState } from 'react';
import { Row, Col, Input, Radio, DatePicker, Form } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { ReactComponent as SureLogo } from 'assets/images/sure-logo.svg';
import Space from 'components/Space';

import Text from 'components/Text';
import Button from 'components/Button';
import PanelFooter from 'components/Panel/PanelFooter';

const History = (props) => {
  const {
    setShowHistory,
    setShowQuote,
    setHistoryForm,
    historyForm,
    setShowConfirmation,
    setAlert,
    alert,
  } = props;
  const [losses, setLosses] = useState('0');
  const [addName, setAddName] = useState(false);
  const [form] = Form.useForm();

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setLosses(e.target.value);
    setHistoryForm({ ...historyForm, losses: e.target.value });
  };

  const handleNextButton = () => {
    if (historyForm.losses === '2+') {
      setShowHistory(false);
      setShowQuote(false);
      setShowConfirmation(false);
      setAlert({ ...alert, visible: true });
      return;
    }
    if (historyForm.animalInjury) {
      setShowHistory(false);
      setShowQuote(false);
      setShowConfirmation(false);
      setAlert({ ...alert, visible: true });
      return;
    }
    if (losses === '1' && historyForm.lossDate === '') {
      form.validateFields(['lossDate']);
      return;
    }
    if (addName) {
      if (!historyForm.sniFirstName || !historyForm.sniLastName) {
        form.validateFields(['firstName', 'lastName']);
        return;
      }
    }
    setShowHistory(false);
    setShowQuote(true);
  };

  const formOnValuesChange = (changedValues) => {
    if (changedValues.lossDate) {
      setHistoryForm({ ...historyForm, lossDate: changedValues.lossDate.format('YYYY-MM-DD') });
    }
    if (changedValues.firstName) {
      setHistoryForm({ ...historyForm, sniFirstName: changedValues.firstName });
    }
    if (changedValues.lastName) {
      setHistoryForm({ ...historyForm, sniLastName: changedValues.lastName });
    }
  };

  return (
    <>
      <StyledContainer className="content-container">
        <Form form={form} name="basic" onValuesChange={formOnValuesChange}>
          <Col span={24} style={{ padding: '0px 14px 10px 14px' }}>
            <Text color="black" size="14" centered>
              Excluding storms, flood and other natural causes, how many losses have you had in the last 3 years?
            </Text>
          </Col>
          <Col span={24} style={{ textAlign: 'center', padding: '0px 0px 20px 0px' }}>
            <Radio.Group buttonStyle="solid" onChange={onChange} value={losses}>
              <Radio.Button value="0">NONE</Radio.Button>
              <Radio.Button value="1" data-testid="lossesTest">
                ONE
              </Radio.Button>
              <Radio.Button value="2+">TWO+</Radio.Button>
            </Radio.Group>
            {losses === '1' && (
              <Row justify="center">
                <Col span={24} style={{ textAlign: 'center' }}>
                  <StyledItem
                    name="lossDate"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a date',
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Date of last loss"
                      format="MM/DD/YYYY"
                      style={{ marginTop: '10px', width: '230px' }}
                      disabledDate={(current) => current && current < moment().subtract(3, 'years')}
                    />
                  </StyledItem>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24} style={{ padding: '0px 14px 10px 14px' }}>
            <Text color="black" size="14" centered>
              Do you own or care for an animal that has caused bodily injury or harm?
            </Text>
          </Col>
          <Col span={24} style={{ textAlign: 'center', padding: '0px 0px 20px 0px' }}>
            <Radio.Group
              defaultValue={false}
              buttonStyle="solid"
              onChange={(e) => setHistoryForm({ ...historyForm, animalInjury: e.target.value })}
            >
              <Radio.Button value={false} data-testid="radio1NoTest">
                NO
              </Radio.Button>
              <Radio.Button value={true} data-testid="radio1YesTest">
                YES
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24} style={{ padding: '0px 14px' }}>
            <Text color="black" size="14" centered>
              Would you like to add someone unrelated to you to your policy?
            </Text>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => {
                setAddName(e.target.value);
                setHistoryForm({ ...historyForm, sni: e.target.value });
              }}
              value={addName}
            >
              <Radio.Button value={false}>NO</Radio.Button>
              <Radio.Button value={true} data-testid="radio2YesTest">
                YES
              </Radio.Button>
            </Radio.Group>
            {addName && (
              <>
                <Space vertical={10} />
                <Row justify="center" gutter={4}>
                  <Col span={8}>
                    <StyledItem
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: 'Please add the first name',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder="First Name" data-testid="firstNameTest" />
                    </StyledItem>
                  </Col>
                  <Col span={8}>
                    <StyledItem
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Please add the last name',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" data-testid="lastNameTest" />
                    </StyledItem>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Form>
      </StyledContainer>
      <PanelFooter>
        <Col span={24}>
          <Space vertical={15} />
          <Button
            color="violet"
            alignment="center"
            onClick={handleNextButton}
            bottom={0}
            data-testid="nextButtonTest"
          >
            NEXT
          </Button>
        </Col>
        <Col span={10} style={{ textAlign: 'center' }} style={{ paddingTop: '10px' }}>
          <SureLogo />
        </Col>
        <Col span={20} style={{ paddingTop: '0px' }}>
          <Text color="grey" size="9">
            Content and associated insurance products are provided by Sure HIIS Insurance Services, LLC (“Sure”), a
            licensed seller of insurance. The above does not in any way constitute an endorsement or referral by
            PAYRENT LLC of Sure’s products or services.
          </Text>
        </Col>
      </PanelFooter>
    </>
  );
};

const StyledContainer = styled.div`
  height: 52%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px;
  .ant-form-item-explain {
    text-align: center !important;
  }
`;

export default History;
