import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Spin, List, Space as AntSpace, theme, Row, Col, Button } from 'antd';
import { CheckCircleOutlined, StepForwardFilled } from '@ant-design/icons';
import { getBillingDetails, getServicePlans } from 'services/api/landlordApi/read';
import { upgradeDowngradeServicePlan } from 'services/api/landlordApi/create';
import { displayAlertAction } from 'store/actions/globalActions';
import { getServicePlanAction } from 'store/actions/sessionActions';
import Text from 'components/Text';
import { motion } from 'framer-motion';
import CardWithMenu from 'components/CardWithMenu/CardWithMenu';
import { servicePlanMapper } from 'utils/servicePlans';
import ServicePlanPricing from './ServicePlanPricing';
import Result from 'components/uielements/Result/Result';
import Title from 'components/Title/Title';
import { featureDescriptions } from 'resources/featureDescriptions';
import { sendSibEvent } from 'utils/sendInBlue';

const MigrationServicePlans = (props) => {
  // console.log('[ServicePlansFinix.js]', props);
  const { hasAccepted, setCurrentStep, currentStep, setDrawerVisible, setVisiblePanel, allServicePlans } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const userServicePlan = useSelector((store) => store.session?.userData?.servicePlan);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [availableServicePlans, setAvailableServicePlans] = useState([]);

  const fetchAndFilterServicePlans = useCallback(async () => {
    setLoading(true);
    try {
      let availablePlans = [];
      const mappedServicePlans = servicePlanMapper[+userServicePlan.id]?.filter((sp) => !sp.default) || [];

      // console.log(
      //   '[MigrationServicePlans.js] ƒ filterAvailableServicePlans',
      //   allServicePlans,
      //   userServicePlan,
      //   mappedServicePlans
      // );

      mappedServicePlans.forEach((msp) => {
        const plan = allServicePlans.find((p) => p.id === msp.id);
        if (plan) {
          availablePlans.push({ ...plan, isSubmitting: false, default: msp.default });
        }
      });
      // console.log('availablePlans', mappedServicePlans, availablePlans);
      setAvailableServicePlans(() => availablePlans);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, [allServicePlans, userServicePlan.id]);

  const featureDescriptionMap = (features) => {
    const descriptions = featureDescriptions;
    const featureList = Object.keys(features).filter((f) => features[f] === true && f in descriptions);

    return (
      <ul>
        {featureList.map((f, i) => {
          return (
            <li key={i}>
              <AntSpace direction="horizontal">
                <CheckCircleOutlined style={{ color: 'green' }} /> {descriptions[f]}
              </AntSpace>
            </li>
          );
        })}
      </ul>
    );
  };

  const handleUpgradeDowngrade = async (sp) => {
    // console.log('ƒ handleUpgradeDowngrade', sp);
    try {
      setAvailableServicePlans((prev) => {
        return prev.map((p) => {
          if (p.id === sp.id) {
            return { ...p, isSubmitting: true };
          }
          return p;
        });
      });

      await upgradeDowngradeServicePlan(
        cognitoToken,
        {
          processorId: session.userData.servicePlan.processor.id,
          servicePlanId: sp.id,
        },
        +session.userData.id
      );
      const sibProperties = {
        EMAIL: session.userData.email,
        FIRSTNAME: session.userData.firstName,
        LASTNAME: session.userData.lastName,
      };

      const sibEventData = {
        data: { migration_type: 'upgrade' },
      };

      await sendSibEvent('migration_completed', sibProperties, sibEventData);

      // const currentServicePlan = await getServicePlanData(cognitoToken);
      // setUserServicePlan(currentServicePlan);
      dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));

      if (sp.subscriptionFee > 0) {
        const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);
        // console.log('plansBillingRes', plansBillingRes);
        if (!plansBillingRes.paymentMethod?.paymentMethod) {
          setDrawerVisible(true);
          setVisiblePanel('billing');
        }
      }
      setAvailableServicePlans([]);

      setResult({
        status: 'success',
        title: (
          <Title color="black" level={3}>
            Voila!
          </Title>
        ),
        subTitle: 'You have successfully upgraded your subscription. Click Next to continue with the migration.',
        extra: [
          <Button alignment="center" type="primary" size="large" onClick={() => setCurrentStep(currentStep + 1)}>
            Next Step
            <StepForwardFilled />
          </Button>,
        ],
      });

      setTimeout(() => {
        setCurrentStep(currentStep + 1);
      }, 3000);
    } catch (error) {
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
      setResult({
        status: 'error',
        title: <Title level={3}>Duh oh!</Title>,
        subTitle: 'There was an issue with migrating your account. Please contact customer support.',
      });
    }
    setAvailableServicePlans((prev) => {
      return prev.map((p) => {
        if (p.id === sp.id) {
          return { ...p, isSubmitting: false };
        }
        return p;
      });
    });
  };

  useEffect(() => {
    // console.log('ƒ useEffect on userServicePlan', userServicePlan);
    if (allServicePlans.length > 0 && userServicePlan && !result) {
      fetchAndFilterServicePlans();
    }
  }, [allServicePlans, fetchAndFilterServicePlans, result, userServicePlan]);

  return (
    <>
      {result ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} key="result">
          <Result {...result} />
        </motion.div>
      ) : availableServicePlans.length > 0 ? (
        <Spin
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          spinning={loading}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            key="plan-list"
          >
            <Text as="div" style={{ marginBottom: '24px' }} size={18} color="black" strong>
              Get More Features and Lower Fees:
            </Text>
            <StyledList
              itemLayout="vertical"
              size="large"
              dataSource={availableServicePlans}
              renderItem={(item, i) => (
                <List.Item key={item.id}>
                  <CardWithMenu
                    title={
                      <Text color="violet2" strong size={20}>
                        {item.name}
                      </Text>
                    }
                    extra={
                      <Button
                        disabled={!hasAccepted}
                        type={item.default ? 'primary' : 'default'}
                        onClick={() => handleUpgradeDowngrade(item)}
                        style={{ marginBottom: '0px' }}
                        loading={item.isSubmitting}
                      >
                        {item.default ? 'Keep' : 'Upgrade'}
                      </Button>
                    }
                  >
                    <FeatureWrapper token={token}>
                      <ServicePlanPricing item={item} showTransactionPricing />
                      <Row gutter={[24, 24]}>
                        <Col xs={24} className="feature-description">
                          {featureDescriptionMap(item.metaFeatures)}
                        </Col>
                      </Row>
                    </FeatureWrapper>
                  </CardWithMenu>
                </List.Item>
              )}
            />
          </motion.div>
        </Spin>
      ) : null}
    </>
  );
};

const StyledList = styled(List)`
  .ant-list-item {
    border-block-end: none;
    @media screen and (max-width: 768px) {
      padding: 16px 0px;
    }
  }
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }
  .feature-description {
    margin-top: 16px;
  }
`;

export default MigrationServicePlans;
