export const wepayServicePlans = [
  {
    name: 'pay as you go',
    subscriptionFee: 0.0,
    properties: -1,
    unitPrice: 0.0,
    achRate: 0.0,
    achFee: 5.0,
    creditCardRate: 0.035,
    creditCardFee: 0.3,
    id: 8,
  },
  {
    name: 'do it yourself',
    subscriptionFee: 19.0,
    properties: 25,
    unitPrice: 3.0,
    achRate: 0.0,
    achFee: 2.0,
    creditCardRate: 0.035,
    creditCardFee: 0.3,
    id: 2,
  },
  {
    name: 'go like a pro',
    subscriptionFee: 49.0,
    properties: 10,
    unitPrice: 3.0,
    achRate: 0.0,
    achFee: 0.0,
    creditCardRate: 0.035,
    creditCardFee: 0.3,
    id: 3,
  },
];

export const silaServicePlans = [
  {
    name: 'pay as you go',
    subscriptionFee: 0.0,
    properties: -1,
    unitPrice: 0.0,
    achRate: 0.0,
    achFee: 5.0,
    creditCardRate: 0.035,
    creditCardFee: 0.3,
    id: 11,
  },
  {
    name: 'do it yourself',
    subscriptionFee: 19.0,
    properties: 25,
    unitPrice: 3.0,
    achRate: 0.0,
    achFee: 2.0,
    creditCardRate: 0.035,
    creditCardFee: 0.3,
    id: 12,
  },
  {
    name: 'go like a pro',
    subscriptionFee: 49.0,
    properties: 10,
    unitPrice: 2.0,
    achRate: 0.0,
    achFee: 0.0,
    creditCardRate: 0.03,
    creditCardFee: 0.0,
    id: 13,
  },
];

export const finixServicePlans = [
  {
    id: '20',
    name: 'Pay As You Go',
    processor_id: 4,
    subscription_fee: 0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 5.0,
    debit_rate_fee: 0.01,
    debit_trx_fee: 5.0,
    credit_card_rate_fee: 0.035,
    credit_card_trx_fee: 4.95,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    onboarding_fee: 10.0,
    properties: -1,
    meta_features: JSON.stringify({
      version: '5.0',
      UPGRADABLE: true,
      PAY_BY_TEXT: false,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: false,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: false,
      ADVANCED_REPORTING: false,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: false,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      MULTIPLE_PROPERTY_GROUP: false,
      RENT_COLLECTION_POLICY_MANAGEMENT: false,
      DOCUMENT_STORAGE: false,
    }),
    state: 'ACTIVE',
    access: 'PUBLIC',
    cap: 6,
    prop_unit_price: 0.0,
    trial: 0,
    stripe_price_id: 'price_1HaU0qDhmipTjlkeNgiFd27w',
    stripe_product_id: 'prod_IApgkz1TEYTVXB',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: '21',
    name: 'Do It Yourself',
    processor_id: 4,
    subscription_fee: 19.0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 2.0,
    debit_rate_fee: 0.01,
    debit_trx_fee: 5.0,
    credit_card_rate_fee: 0.035,
    credit_card_trx_fee: 4.95,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    properties: 25,
    onboarding_fee: 0.0,
    meta_features: JSON.stringify({
      version: '5.0',
      UPGRADABLE: true,
      PAY_BY_TEXT: false,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: false,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: true,
      ADVANCED_REPORTING: true,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: true,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      MULTIPLE_PROPERTY_GROUP: false,
      RENT_COLLECTION_POLICY_MANAGEMENT: true,
      DOCUMENT_STORAGE: false,
    }),
    state: 'ACTIVE',
    access: 'PUBLIC',
    cap: 6,
    prop_unit_price: 3.0,
    trial: 7,
    stripe_price_id: 'price_1HaU4yDhmipTjlkew6p3Cwb2',
    stripe_product_id: 'prod_IApkJkGTwnheIR',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: '22',
    name: 'Go Like A Pro',
    processor_id: 4,
    subscription_fee: 49.0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 0.0,
    debit_rate_fee: 0.0,
    debit_trx_fee: 10.0,
    credit_card_rate_fee: 0.035,
    credit_card_trx_fee: 4.95,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    properties: 10,
    onboarding_fee: 0.0,
    meta_features: JSON.stringify({
      version: '5.0',
      UPGRADABLE: true,
      PAY_BY_TEXT: false,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: true,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: true,
      ADVANCED_REPORTING: true,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: true,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      MULTIPLE_PROPERTY_GROUP: true,
      RENT_COLLECTION_POLICY_MANAGEMENT: true,
      DOCUMENT_STORAGE: false,
    }),
    state: 'ACTIVE',
    access: 'PUBLIC',
    cap: 6,
    prop_unit_price: 3.0,
    stripe_price_id: 'price_1HaU9sDhmipTjlkeekq7TU3t',
    stripe_product_id: 'prod_IAppLFkFOpLysi',
    trial: 14,
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: '23',
    name: 'High Riser Landlord',
    processor_id: 4,
    subscription_fee: 0.0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 0.0,
    debit_rate_fee: 0.0,
    debit_trx_fee: 10.0,
    credit_card_rate_fee: 0.025,
    credit_card_trx_fee: 4.95,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    onboarding_fee: 0.0,
    properties: 10000,
    meta_features: JSON.stringify({
      version: '5.0',
      UPGRADABLE: true,
      PAY_BY_TEXT: false,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: true,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: true,
      ADVANCED_REPORTING: true,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: true,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      MULTIPLE_PROPERTY_GROUP: true,
      RENT_COLLECTION_POLICY_MANAGEMENT: true,
      DOCUMENT_STORAGE: false,
    }),
    state: 'ACTIVE',
    access: 'PRIVATE',
    cap: 6,
    prop_unit_price: 0,
    trial: 0,
    stripe_price_id: 'price_1K9AECDhmipTjlkewmcYCA1M',
    stripe_product_id: 'prod_Konpe9HMvI45Xm',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: '24',
    name: 'High Riser Tenant 395',
    processor_id: 4,
    subscription_fee: 0.0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 3.95,
    debit_rate_fee: 0.0,
    debit_trx_fee: 10.0,
    credit_card_rate_fee: 0.025,
    credit_card_trx_fee: 4.95,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    onboarding_fee: 0.0,
    properties: 10000,
    meta_features: JSON.stringify({
      version: '5.0',
      UPGRADABLE: true,
      PAY_BY_TEXT: false,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: true,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: true,
      ADVANCED_REPORTING: true,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: true,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      MULTIPLE_PROPERTY_GROUP: true,
      RENT_COLLECTION_POLICY_MANAGEMENT: true,
      DOCUMENT_STORAGE: false,
    }),
    state: 'ACTIVE',
    access: 'PRIVATE',
    cap: 6,
    prop_unit_price: 0,
    trial: 0,
    stripe_price_id: 'price_1Mf4qjDhmipTjlkeGpZELAJH',
    stripe_product_id: 'prod_KonqhnVLYiJmca',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: '25',
    name: 'High Riser Post Bill 195',
    processor_id: 4,
    subscription_fee: 0.0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 1.0,
    debit_rate_fee: 0.0,
    debit_trx_fee: 10.0,
    credit_card_rate_fee: 0.025,
    credit_card_trx_fee: 4.95,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    onboarding_fee: 0.0,
    properties: 10000,
    meta_features: JSON.stringify({
      version: '5.0',
      UPGRADABLE: true,
      PAY_BY_TEXT: false,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: true,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: true,
      ADVANCED_REPORTING: true,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: true,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      MULTIPLE_PROPERTY_GROUP: true,
      RENT_COLLECTION_POLICY_MANAGEMENT: true,
      DOCUMENT_STORAGE: false,
    }),
    state: 'ACTIVE',
    access: 'PRIVATE',
    cap: 6,
    prop_unit_price: 0,
    trial: 0,
    stripe_price_id: 'price_1MCyI0DhmipTjlkeTeU9lEvD',
    stripe_product_id: 'prod_MgB0Sg2reluPRc',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: '26',
    name: 'High Riser Partner 495',
    processor_id: 4,
    subscription_fee: 0.0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 4.95,
    debit_rate_fee: 0.0,
    debit_trx_fee: 10.0,
    credit_card_rate_fee: 0.025,
    credit_card_trx_fee: 4.95,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    onboarding_fee: 0.0,
    properties: 10000,
    meta_features: JSON.stringify({
      version: '5.0',
      UPGRADABLE: true,
      PAY_BY_TEXT: false,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: true,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: true,
      ADVANCED_REPORTING: true,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: true,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      MULTIPLE_PROPERTY_GROUP: true,
      RENT_COLLECTION_POLICY_MANAGEMENT: true,
      DOCUMENT_STORAGE: false,
    }),
    state: 'ACTIVE',
    access: 'PRIVATE',
    cap: '6',
    prop_unit_price: 0,
    trial: 0,
    stripe_price_id: 'price_1N1AeGDhmipTjlkeerVGo0t9',
    stripe_product_id: 'prod_Nmk8BXJMN204BM',
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    id: '27',
    name: 'Forever Free',
    processor_id: 4,
    subscription_fee: 0.0,
    ach_rate_fee: 0.0,
    ach_trx_fee: 6.0,
    debit_rate_fee: null,
    debit_trx_fee: null,
    credit_card_rate_fee: 0.035,
    credit_card_trx_fee: 0.3,
    express_fee_rate: 0.015,
    express_fee_cap: 15.0,
    onboarding_fee: 0.0,
    properties: 10000,
    meta_features: JSON.stringify({
      version: '1.0a',
      UPGRADABLE: true,
      PAY_BY_TEXT: true,
      SUB_ACCOUNT: false,
      FLEX_FUNDING: false,
      BALANCE_CHECK: false,
      RENTAL_LISTING: false,
      RENT_REMINDERS: true,
      ACH_CC_PAYMENTS: true,
      EVICTION_ASSIST: false,
      EXPRESS_FUNDING: false,
      PAYMENT_CONTROLS: true,
      RENTER_PAYS_FEES: true,
      ADVANCED_REPORTING: false,
      RENTER_ABSORB_FEES: false,
      SCHEDULED_PAYMENTS: true,
      RENTER_TIER_UPGRADE: false,
      ACCOUNTING_INTEGRATION: false,
      ADDITIONAL_ASSESSMENTS: true,
      ON_TIME_RENT_GUARANTEE: false,
      TENANT_ISSUE_MGT_COMMS: false,
      RENT_COLLECTION_POLICY_MANAGEMENT: false,
    }),
    state: 'ACTIVE',
    access: 'PRIVATE',
    created_at: '2023-12-24 00:00:00',
    updated_at: '2023-12-24 00:00:00',
    cap: 5.0,
    stripe_price_id: 'price_1HlbbeDhmipTjlkeCMTrjD0p',
    stripe_product_id: 'prod_IMKGf3K7PQyYlm',
    agent: null,
    office: null,
    prop_unit_price: 0.0,
    trial: 0,
  },
];

// mapping wepay service plans to finix service plans
export const servicePlanMapper = {
  2: [
    { id: '21', default: 1 },
    { id: '22', default: 0 },
  ],
  3: [{ id: '22', default: 1 }],
  5: [
    { id: '27', default: 1 },
    { id: '21', default: 0 },
    { id: '22', default: 0 },
  ],
  6: [
    { id: '27', default: 1 },
    { id: '21', default: 0 },
    { id: '22', default: 0 },
  ],
  8: [
    { id: '20', default: 1 },
    { id: '21', default: 0 },
    { id: '22', default: 0 },
  ],
  9: [{ id: '23', default: 1 }],
  10: [{ id: '24', default: 1 }],
  16: [{ id: '25', default: 1 }],
  17: [{ id: '25', default: 1 }],
  19: [{ id: '24', default: 1 }],
};
