import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Spin, List, Space as AntSpace, theme, Layout } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { getPropertyGroups, getBillingDetails, getServicePlans } from 'services/api/landlordApi/read';
import { getServicePlanData } from 'services/api/common/read';
import { upgradeDowngradeServicePlan } from 'services/api/landlordApi/create';
import { displayAlertAction } from 'store/actions/globalActions';
import { getServicePlanAction } from 'store/actions/sessionActions';
import Button from 'components/Button';
import Text from 'components/Text';
import { motion } from 'framer-motion';
import CardWithMenu from 'components/CardWithMenu/CardWithMenu';
import Space from 'components/Space/Space';
import { featureDescriptions } from 'resources/featureDescriptions';
import AccountSettingsDrawer from 'layouts/AppLayout/components/DrawerMenu/components/LandLordMenu/AccountSettingsDrawer/AccountSettingsDrawer';

const ServicePlansFinix = (props) => {
  // console.log('[ServicePlansFinix.js]', props);
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [userServicePlan, setUserServicePlan] = useState({ id: 0 });
  const [availableServicePlans, setAvailableServicePlans] = useState([]);
  const [propertyGroupCount, setPropertyGroupCount] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const [visiblePanel, setVisiblePanel] = useState('');
  const [alert, setAlert] = useState({ message: '', type: 'success' });

  const fetchData = async () => {
    try {
      setLoading(true);

      const cognitoToken = session.cognitoTokens.idToken.jwtToken;
      const currentServicePlan = await getServicePlanData(cognitoToken);
      await getServicePlans().then((res) => {
        setAvailableServicePlans(() =>
          res
            .filter(
              (plan) => plan.processor.id === session.userData.servicePlan.processor.id && plan.access === 'PUBLIC'
            )
            .map((p) => {
              return { ...p, isSubmitting: false };
            })
        );
        return;
      });
      const propertyGroups = await getPropertyGroups(cognitoToken, 1, 20);
      setPropertyGroupCount(propertyGroups.items.length);

      setUserServicePlan(currentServicePlan);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const isEligible = (sp) => {
    if (sp.id === userServicePlan.id) {
      return false;
    }
    if (!sp.metaFeatures.mULTIPLEPROPERTYGroup && propertyGroupCount > 1) {
      return false;
    }
    return true;
  };

  const featureDescriptionMap = (features) => {
    const descriptions = featureDescriptions;
    const featureList = Object.keys(features).filter((f) => features[f] === true && f in descriptions);

    return (
      <ul>
        {featureList.map((f, i) => {
          return (
            <li key={i}>
              <AntSpace direction="horizontal">
                <CheckCircleOutlined style={{ color: 'green' }} /> {descriptions[f]}
              </AntSpace>
            </li>
          );
        })}
      </ul>
    );
  };
  const transactionPricingMap = (sp) => {
    return (
      <ul>
        <li>
          <Text strong color="black">
            {(sp.achRateFee * 100).toFixed(1)}% + ${sp.achTrxFee} per ACH
          </Text>
        </li>
        <li>
          {(sp.debitRateFee * 100).toFixed(1)}% + ${sp.debitTrxFee} Debit Card
        </li>
        <li>
          {(sp.creditCardRateFee * 100).toFixed(1)}% + ${sp.creditCardTrxFee} Credit Card
        </li>
      </ul>
    );
  };

  const handleUpgradeDowngrade = async (sp) => {
    // console.log('ƒ handleUpgradeDowngrade', sp);
    try {
      setAvailableServicePlans((prev) => {
        return prev.map((p) => {
          if (p.id === sp.id) {
            return { ...p, isSubmitting: true };
          }
          return p;
        });
      });

      const cognitoToken = session.cognitoTokens.idToken.jwtToken;

      await upgradeDowngradeServicePlan(
        cognitoToken,
        {
          processorId: session.userData.servicePlan.processor.id,
          servicePlanId: sp.id,
        },
        +session.userData.id
      );

      dispatch(displayAlertAction('', 'success', 'Your Service Plan Was Successfully Updated', true, 5000));

      const currentServicePlan = await getServicePlanData(cognitoToken);
      setUserServicePlan(currentServicePlan);

      if (sp.subscriptionFee > 0) {
        const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);
        // console.log('plansBillingRes', plansBillingRes);
        if (!plansBillingRes.paymentMethod?.paymentMethod) {
          setDrawerVisible(true);
          setVisiblePanel('billing');
        }
      }

      dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    } catch (error) {
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
    }
    setAvailableServicePlans((prev) => {
      return prev.map((p) => {
        if (p.id === sp.id) {
          return { ...p, isSubmitting: false };
        }
        return p;
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const displayAlert = (text, type) => {
    setAlert({
      message: text,
      type,
    });
    setTimeout(() => {
      setAlert({
        message: '',
      });
    }, 3000);
  };

  const resetPanel = () => {
    setDrawerVisible(false);
    setDrawerExpanded(false);
    setVisiblePanel('');
  };

  return (
    <>
      <AccountSettingsDrawer
        visiblePanel={visiblePanel}
        setVisiblePanel={setVisiblePanel}
        drawerVisible={drawerVisible}
        resetPanel={resetPanel}
        displayAlert={displayAlert}
        alert={alert}
        drawerExpanded={drawerExpanded}
      />
      <Text as="div" style={{ marginLeft: '24px', marginTop: '24px' }} size={22} color="violet2" strong>
        Service Plans
      </Text>
      {loading ? (
        <Spin
          style={{
            width: '100%',
            height: 'calc(100vh - 125px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          spinning={loading}
        />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          key="plan-list"
        >
          <StyledList
            itemLayout="vertical"
            size="large"
            dataSource={availableServicePlans}
            renderItem={(item, i) => (
              <List.Item key={i}>
                <CardWithMenu
                  title={
                    <Text color="violet2" strong size={20}>
                      {item.name}
                    </Text>
                  }
                  extra={
                    <Button
                      disabled={!isEligible(item)}
                      type="primary"
                      onClick={() => handleUpgradeDowngrade(item)}
                      style={{ marginBottom: '0px' }}
                      loading={item.isSubmitting}
                    >
                      {userServicePlan.id === item.id ? 'Current Plan' : 'Select Plan'}
                    </Button>
                  }
                >
                  <FeatureWrapper token={token}>
                    <AntSpace direction="vertical" size={24}>
                      <div className="subscription-summary">
                        <Text as="div" strong size={48} color="black">
                          ${item.subscriptionFee}{' '}
                          <Text strong color="black">
                            per month
                          </Text>
                        </Text>
                        <Text as="div" color="black">
                          Includes {item.properties < 0 ? 'unlimited' : item.properties} units <br /> ($
                          {item.propUnitPrice.toFixed(2)} per additional unit)
                        </Text>
                        <Space vertical={6} />
                        <Text color="black">Account Setup Fee: ${item.onboardingFee?.toFixed(2) || '0.00'}</Text>
                      </div>

                      <div className="transaction-pricing">
                        <Text color="black">Transaction Fees:</Text>
                        <Space vertical={8} />
                        {transactionPricingMap(item)}
                      </div>
                    </AntSpace>
                    <div className="feature-description">{featureDescriptionMap(item.metaFeatures)}</div>
                  </FeatureWrapper>
                </CardWithMenu>
              </List.Item>
            )}
          />
        </motion.div>
      )}
    </>
  );
};

const StyledList = styled(List)`
  .ant-list-item {
    border-block-end: none;
    @media screen and (max-width: 768px) {
      padding: 16px 0px;
    }
  }
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }
  .feature-description {
    margin-top: 16px;
  }
  .transaction-pricing {
    border: 1px solid ${(props) => props.token.colorBorder};
    border-radius: ${(props) => props.token.borderRadius}px;
    box-shadow: ${(props) => props.token.boxShadowTertiary};
    padding: 12px 16px;
    width: 100%;
  }
`;

export default ServicePlansFinix;
