import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Button';
import OnboardingStatus from 'screens/Landlord/components/OnboardingStatus';

const Finished = (props) => {
  const session = useSelector((store) => store.session);

  const { boardingStatus, setDrawerVisible, setShowAcceptPayments } = props;

  return (
    <Container>
      <ContentContainer className="content-container">
        <OnboardingStatus
          boardingStatus={boardingStatus}
          servicePlan={session.userData.servicePlan}
          paymentGateway={session.userData.paymentGateway}
        />
      </ContentContainer>
      <StyledFooter>
        <Button
          color="violet"
          bottom={0}
          onClick={() => {
            setDrawerVisible(false);
            setShowAcceptPayments(false);
          }}
        >
          CLOSE
        </Button>
      </StyledFooter>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  min-height: calc(100% - 25px);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 22px 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledFooter = styled.div`
  min-height: 80px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
  gap: 20px;
`;

export default Finished;
