import React from 'react';
import { SyncOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Button, Space as AntSpace, Tooltip, Skeleton } from 'antd';
import Space from 'components/Space';
import { useDispatch, useSelector } from 'react-redux';
import { setDashboard } from 'store/actions/dashboardActions';
import StyledKpiToggle from './KpiToggle';
import { StyledCardWithTitle, StyledKpiTitle, StyledKpiValue, StyledWarning } from './KpiDashboard.styles';

export default function KpiCard(props) {
  const {
    title,
    data,
    prefix,
    suffix,
    handleRefresh,
    showToggle,
    id,
    loading,
    isDesktop,
    reloading,
    toolTip,
  } = props;
  const { values, hasNull } = data;
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.dashboard.settings);

  const onChange = (value) => {
    dispatch(setDashboard({ [id]: { defaultPeriod: value } }));
  };

  const valueElements = () => {
    return Object.entries(values).map(([key, value]) => {
      return (
        <Skeleton
          loading={loading}
          paragraph={{ rows: 1, width: '240px' }}
          title={false}
          key={`${id}-${key}`}
          active
        >
          <StyledKpiValue value={value} key={`${id}-${key}`} display={settings[id].defaultPeriod === key ? 1 : 0}>
            {prefix}
            {value ? value.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}
            {suffix}
          </StyledKpiValue>
        </Skeleton>
      );
    });
  };

  return (
    <StyledCardWithTitle
      className="kpi-card"
      key={id}
      title={
        handleRefresh ? (
          <Tooltip title="Refresh data">
            <Button
              type="text"
              className="kpi-refresh-button"
              onClick={() => handleRefresh(id)}
              icon={
                <SyncOutlined
                  style={reloading ? { color: '#cb47b8' } : { color: '#C0C0C0' }}
                  spin={reloading}
                  data-testid="kpiRefreshIcon"
                />
              }
              data-testid="kpiRefreshTest"
            />
          </Tooltip>
        ) : (
          ' '
        )
      }
      data-testid="kpiCardTest"
      extra={showToggle && <StyledKpiToggle defaultPeriod={settings[id].defaultPeriod} onChange={onChange} />}
    >
      <AntSpace direction="vertical" size={0}>
        {hasNull ? (
          <Tooltip
            title="You must add cost and expense details for all of your properties in order to calculate accurately."
            trigger={isDesktop ? ['hover'] : ['click']}
          >
            <StyledWarning>
              <span>Incomplete property data!</span>
            </StyledWarning>
          </Tooltip>
        ) : (
          <div style={{ height: 19 }}> </div>
        )}
        <AntSpace>
          <StyledKpiTitle>{title}</StyledKpiTitle>
          {toolTip && (
            <Tooltip title={toolTip} trigger={isDesktop ? ['hover'] : ['click']}>
              <InfoCircleFilled color="#122c34" />
            </Tooltip>
          )}
        </AntSpace>
        {valueElements()}
      </AntSpace>
    </StyledCardWithTitle>
  );
}
