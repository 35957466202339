import { createStore, legacy_createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { batchedSubscribe } from 'redux-batched-subscribe';
import storageSession from 'redux-persist/lib/storage/session';
import debounce from 'lodash.debounce';

import rootReducer from './rootReducer';

const persistConfig = {
  key: 'store',
  storage: storageSession,
  timeout: 2,
  blacklist: ['dashboard'],
};

const debounceNotify = debounce((notify) => notify(), 3000);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })
  : compose;

const store = legacy_createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunk), batchedSubscribe(debounceNotify))
);

const persistedStore = () => {
  const persistor = persistStore(store);
  return { store, persistor };
};

export default persistedStore;
