import React from 'react';
import { Col, Row, Form, Input, Select, DatePicker } from 'antd';

import MaskedInput from 'antd-mask-input';

import { checkZipCode, checkCity, checkAddressValidator } from 'resources/FormValidators';

import { usStates } from 'resources/FormData';

const { Option } = Select;

const BusinessFields = (props) => {
  const { form } = props;

  const EinValidator = (rule, value) => {
    if (!value) {
      return Promise.reject('Please input your business EIN');
    }
    const justNumbers = value.replace(/-|_/g, '');
    if (justNumbers.length === 9) {
      return Promise.resolve();
    }
    if (justNumbers.length < 9) {
      return Promise.reject('Not a valid EIN');
    }

    return Promise.reject('Please input your business EIN');
  };

  return (
    <>
      <Row gutter={15}>
        <Col span={12}>
          Business Legal Name
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="legalBusinessName"
                  rules={[
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please input your business name',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={12}>
          DBA (if applicable)
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="dbaName"
                  // rules={[
                  //   {
                  //     required: false,
                  //     message: 'Please input your dba name ',
                  //     whitespace: true,
                  //   },
                  // ]}
                >
                  <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          Business Street Address
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  rules={[
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please input your address',
                      whitespace: true,
                    },
                    {
                      pattern: new RegExp(/(?!^\d+$)^.+$/),
                      message: 'Address must be numbers and letters',
                    },
                    {
                      max: 100,
                      message: 'Address cant be more than 100 characters',
                    },
                    {
                      validator: checkAddressValidator,
                    },
                  ]}
                  name="businessAddress"
                >
                  <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={10}>
          Zip Code
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="bZipcode"
                  rules={[
                    {
                      validator:
                        form.getFieldValue('businessType') !== 'Sole Proprietorship' ? checkZipCode : false,
                    },
                  ]}
                >
                  <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          City
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="bCity"
                  rules={[
                    {
                      validator: form.getFieldValue('businessType') !== 'Sole Proprietorship' ? checkCity : false,
                    },
                  ]}
                >
                  <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={10}>
          State
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="bState"
                  rules={[
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please select a state',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select State"
                    showSearch
                    onChange={() => {}}
                    disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'}
                  >
                    {usStates.map((s, i) => (
                      <Option value={s.abbreviation} key={i}>
                        {s.abbreviation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          Business Email Address
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="businessEmail"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not a valid email',
                    },
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please enter your email',
                    },
                  ]}
                >
                  <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={10}>
          Business Phone
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="businessPhone"
                  rules={[
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please enter your phone number',
                    },
                    {
                      // pattern: new RegExp('^[0-9]{6,}$'),
                      // supports ten characters without + sign
                      // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                      pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                      message: 'Please enter only numbers - min length 10',
                    },
                    {
                      message: 'Please input a valid phone number.',
                      max: 12,
                    },
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111 111 1111"
                    mask="000 000 0000"
                    minLength={6}
                    placeholderChar=" "
                    data-testid="phoneTest"
                    prefix="+1"
                    disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          Business Start Date
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="businessStartDate"
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a date',
                    },
                  ]}
                >
                  <DatePicker format="MM/DD/YYYY" placeholder="mm/dd/yyyy" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={12}>
          Business EIN
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="businessEin"
                  rules={[
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please input your business Ein',
                      whitespace: true,
                    },
                    {
                      validator: EinValidator,
                    },
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="11-1111111"
                    mask="00-0000000"
                    minLength={6}
                    placeholderChar=" "
                    disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BusinessFields;
