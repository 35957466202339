import * as React from 'react';
import { Card as AntCard } from 'antd';
import styled from 'styled-components';

type Props = {
  children: React.Node,
  title: string,
};

const CardWithTitle = (props) => {
  const {
    children,
    headFontSize,
    headAlign,
    title,
    bodyPadding,
    bodyMinHeight,
    hoverable,
    type,
    extra,
    key,
    ...rest
  } = props;

  return (
    <StyledCard
      type={type}
      title={title}
      bordered={false}
      hoverable={hoverable}
      styles={{
        header: {
          fontSize: headFontSize ? `${headFontSize}px` : '20px',
          fontWeight: 600,
          padding: '0 24px 0 24px',
          color: '#541388',
          marginBottom: '0px',
          height: type === 'landlord' ? '56px' : '',
          textAlign: headAlign || '',
        },
        body: {
          paddingTop: type === 'landlord' ? '5px' : 'inherit',
          padding: bodyPadding, // '4px 6px 15px 6px'
          fontSize: '12px',
          minHeight: bodyMinHeight ? `${bodyMinHeight}px` : '',
        },
      }}
      extra={extra}
      {...rest}
    >
      {children}
    </StyledCard>
  );
};

const StyledCard = styled(AntCard)`
  border: ${(props) => (props.type === 'landlord' || props.type === 'pricing' ? '1px solid #d9d9d9' : 'inherit')};
  .ant-card-head {
    border-bottom: ${(props) => {
      switch (props.type) {
        case 'landlord':
          return '0px';
        case 'pricing':
          return '1px solid #d9d9d9';
        default:
          return '1px solid #f0f0f0';
      }
    }};
  }
  @media screen and (max-width: 560px) {
    .ant-card-head-wrapper {
      display: flex;
      flex-direction: column;
    }
    .ant-card-extra {
      margin-left: unset;
    }
    .ant-card-head-title {
      padding: 16px 0px 5px 0px;
    }
    .ant-card-extra {
      padding: 5px 0px 16px 0px;
    }
  }
`;

export default CardWithTitle;
