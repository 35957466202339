import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import parser from 'parse-address';
import { usStates } from 'resources/FormData';
import { v4 } from 'uuid';

import { Form, Row, Col, Input, Upload, DatePicker, Tooltip, Alert, Space as AntSpace, Spin } from 'antd';

import { createParentProperty, createProperty, updateCreatePropertyFees } from 'services/api/landlordApi/create';
import { updateParentProperty } from 'services/api/landlordApi/update';
import { getProperties, getPropertiesV3, getPropertyGroups } from 'services/api/landlordApi/read';
import PlacesAutocompleteInput from 'components/PlacesAutocompleteInput';
import Space from 'components/Space';
import Text from 'components/Text';
import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';
import Button from 'components/Button';
import { useQuery } from '@tanstack/react-query';
import OnboardingResult from '../OnboardingResult';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';

const PropertyForm = (props) => {
  const { currentStep, setCurrentStep, setIsLoading, isLoading, isError, propertyGroup } = props;
  const session = useSelector((state) => state.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const quickStart = session.userData.landlordMeta.landlordProfile.landlordProfile?.quickStart;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [addressInput, setAddressInput] = useState('');
  const [addressInitValue, setAddressInitValue] = useState('');
  const [locationData, setLocationData] = useState(null);
  const [googleString, setGoogleString] = useState(null);
  const googleSelected = useRef(null);
  const customAddressInput = useRef(null);

  const check = (obj, arr) => {
    return arr.every((e) => Object.keys(obj).includes(e));
  };

  useEffect(() => {
    if (!addressInput && (googleSelected.current || customAddressInput.current)) {
      setLocationData(null);
      googleSelected.current = false;
      customAddressInput.current = false;
      form.validateFields(['address']);
    }
    if (addressInput && addressInput !== googleString) {
      const addr = parser.parseLocation(addressInput);

      if (addr) {
        const validKeyNames = ['city', 'number', 'state', 'street', 'zip'];

        const validAddr = check(addr, validKeyNames);
        if (validAddr) {
          const usState = usStates.filter((s) => s.abbreviation === addr.state.toUpperCase());
          const customAddress = {
            streetNumber: addr.number,
            address: addr.type ? `${addr.street} ${addr.type}` : addr.street,
            city: addr.city,
            state: usState[0].name.charAt(0).toUpperCase() + usState[0].name.slice(1).toLowerCase(),
            google_place_id: v4(),
            lat: 0,
            long: 0,
            zip: addr.zip,
            country: 'United States',
          };
          setLocationData({ ...customAddress });
          customAddressInput.current = true;
        } else {
          customAddressInput.current = false;
          setLocationData(null);
          form.validateFields(['address']);
        }
      }
    }
  }, [addressInput]);

  const addressValidator = () => {
    if (!addressInitValue) {
      if (!locationData) {
        return Promise.reject('Please select the street address.');
      }

      if (addressInput.trim().length === 0) {
        return Promise.reject('Please select the street address.');
      }
      return Promise.resolve();
    }
    return Promise.resolve();
  };

  const handlePlacesAutocomplete = (data) => {
    googleSelected.current = true;
    setLocationData(data);
    form.validateFields();
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      let newUnit = false;
      const parentProp = await createParentProperty(
        session.cognitoTokens.idToken.jwtToken,
        propertyGroup.id,
        locationData,
        values
      );
      const units = await getProperties(false, 1, 5, false, false, `parentPropertyId:${parentProp.id}`);

      if (!units.length) {
        const defaultUnitValues = {
          unit: 1,
          rentAmount: 0,
          rentDay: 1,
          commercial: false,
          bedrooms: 1,
          bathrooms: 1,
          propertyGroup: propertyGroup.id,
          parentPropertyId: +parentProp.id,
          acceptCreditCard: propertyGroup.settings.acceptCreditCard,
          preventPayment: propertyGroup.settings.preventPayments,
          fullPayment: propertyGroup.settings.requireFullPayment,
          absorbFees: propertyGroup.settings.absorbFees,
          percent: propertyGroup.settings.absorbFeesPercent,
          complianceLevel: propertyGroup.settings.complianceLevel,
        };

        const unitRes = await createProperty(locationData, defaultUnitValues, 0);

        const defaultFees = {
          securityDepositSwitch: propertyGroup.settings.propertyFees.securityDeposit,
          securityDepositAmount: propertyGroup.settings.propertyFees.securityDepositAmount,
          daysEarlySwitch: propertyGroup.settings.propertyFees.discount,
          daysEarly: propertyGroup.settings.propertyFees.discountDays,
          discountAmountEarly: propertyGroup.settings.propertyFees.discountAmount,
          daysLateSwitch: propertyGroup.settings.propertyFees.lateFee,
          lateFeeFrequency: 'ONE-TIME',
          daysLateFee: propertyGroup.settings.propertyFees.lateFeeDays,
          lateFeeAmount: propertyGroup.settings.propertyFees.lateFeeAmount,
          parkingFeeSwitch: propertyGroup.settings.propertyFees.parking,
          periodParkingFee: propertyGroup.settings.propertyFees.parkingFrequency,
          parkingFeeAmount: propertyGroup.settings.propertyFees.parkingAmount,
          moveInFeeSwitch: propertyGroup.settings.propertyFees.moveIn,
          moveInFee: propertyGroup.settings.propertyFees.moveInAmount,
          moveOutFeeSwitch: propertyGroup.settings.propertyFees.moveOut,
          moveOutFee: propertyGroup.settings.propertyFees.moveOutAmount,
        };
        await updateCreatePropertyFees(session.cognitoTokens.idToken.jwtToken, defaultFees, unitRes.id);
        newUnit = true;
      }
      dispatch(
        updateQuickStartStatusAction(
          session.userData.id,
          { ...quickStart, lastCompletedStep: currentStep },
          cognitoToken
        )
      );
      setCurrentStep(currentStep + 1);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const checkPrice = (rule, value) => {
    if (Number(value) >= 0) {
      return Promise.resolve();
    }
    if (isNaN(Number(value))) {
      return Promise.reject('Amount is not a valid number.');
    }
    return Promise.reject('Not a valid amount');
  };

  if (isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        className="spin-container"
      >
        <Spin spinning={isLoading} />
      </div>
    );
  } else if (isError) {
    return <OnboardingResult />;
  } else {
    return (
      <StyledForm
        form={form}
        name="parent-property-onboarding-form"
        data-testid="parentPropertyOnboardingFormTest"
        onFinish={onFinish}
        initialValues={{
          acquisitionPrice: null,
          currentValue: null,
          annualDebtService: null,
          annualTaxes: null,
          annualInsurance: null,
          annualMaintCosts: null,
          annualMgmtFees: null,
        }}
      >
        <Row gutter={16} className="general-settings">
          <Col span={24}>
            Address
            <Form.Item
              name="address"
              data-testid="addressTest"
              rules={[
                {
                  validator: addressValidator,
                },
              ]}
            >
              <PlacesAutocompleteInput
                callback={handlePlacesAutocomplete}
                setGoogleString={setGoogleString}
                // groupId={groupId}
                setAddressInput={setAddressInput}
                addressValidator={addressValidator}
                addressInitValue={addressInitValue}
                setAddressInitValue={setAddressInitValue}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space vertical={20} />
        <Text size={16} color="black" strong>
          Property Details (optional)
        </Text>
        <Space vertical={20} />
        <Text color="black">
          These optional details make your dashboard metrics accurate. We don&apos;t sell or share them.
        </Text>
        <Space vertical={20} />
        <Row gutter={15}>
          <Col span={12}>
            Acquisition Date
            <Form.Item name="acquisitionDate">
              <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={12}>
            Acquisition Price
            <Form.Item
              name="acquisitionPrice"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input prefix="$" data-testid="amountTest" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            Current Value
            <Form.Item
              name="currentValue"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input prefix="$" />
            </Form.Item>
          </Col>
          <Col span={12}>
            Ann. Debt Service
            {/* <Tooltip
                  placement="left"
                  title="Total mortgage and other loan payments made on the property."
                  trigger="click"
                >
                  <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ssnTooltip" />
                </Tooltip> */}
            <Form.Item
              name="annualDebtService"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input prefix="$" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            Ann. Taxes
            <Form.Item
              name="annualTaxes"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input prefix="$" />
            </Form.Item>
          </Col>
          <Col span={12}>
            Ann. Insurance
            <Form.Item
              name="annualInsurance"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input prefix="$" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            Ann. Maintenance
            <Form.Item
              name="annualMaintCosts"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input prefix="$" />
            </Form.Item>
          </Col>
          <Col span={12}>
            Ann. Mgmt. Fees
            <Form.Item
              name="annualMgmtFees"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input prefix="$" />
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    );
  }
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export default PropertyForm;
