import React from 'react';
import { Result as AntResult } from 'antd';
import styled from 'styled-components';

export default function Result(props) {
  const { children, ...rest } = props;
  return <StyledResult {...rest}>{children}</StyledResult>;
}

const StyledResult = styled(AntResult)`
  padding: 48px 18px;
  .button-container {
    justify-content: center;
  }
`;
