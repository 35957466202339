/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import Jsona from 'jsona';
import * as actions from 'store/actions';
import * as tenantApi from 'services/api/tenantApi';

const dataFormatter = new Jsona();

const getBillingsUrl = (filter, filterType, sort, include, pageSize, pageNumber) => {
  let apiUrl = `${process.env.REACT_APP_API_URL}/billing-items?`;
  if (filter !== null) apiUrl += `filter[gql]=(${encodeURIComponent(filter)})`;
  if (filterType !== null) apiUrl += encodeURIComponent(`+billingItemType:${filterType}`);
  if (sort) apiUrl += `&sort=-${sort}`;
  if (include) apiUrl += `&include=${include}`;
  if (pageSize) apiUrl += `&page[size]=${pageSize}`;
  if (pageNumber) apiUrl += `&page[number]=${pageNumber}`;
  return apiUrl;
};

export const getBillingItems = () => async (dispatch) => {
  try {
    const processedUrl = getBillingsUrl('state:PROCESSED', null, 'orderDate', 'payment,billingCharge', 10);
    const processingUrl = getBillingsUrl('state:PROCESSING', null, 'orderDate', 'payment,billingCharge', null);
    const delayedUrl = getBillingsUrl('state:DELAYED_PROCESSED', null, 'orderDate', 'payment,billingCharge', 10);

    const processedRes = await axios.get(processedUrl);
    const processingRes = await axios.get(processingUrl);
    const delayedRes = await axios.get(delayedUrl);

    const processed = dataFormatter.deserialize(processedRes.data);
    const processing = dataFormatter.deserialize(processingRes.data);
    const delayed = dataFormatter.deserialize(delayedRes.data);

    const billingItems = {
      processed,
      processing,
      delayed,
    };

    dispatch({
      type: actions.GET_BILLING_ITEMS,
      payload: billingItems,
    });
  } catch (error) {
    // console.log('Billing action error ', error);
    dispatch({
      type: actions.GET_BILLING_ITEMS_ERROR,
      payload: error.message,
    });
  }
};

export const billingItemsFilter =
  (auth, filterState, filterType, sort, include, pageSize, pageNumber) => async (dispatch) => {
    const itemUrl = getBillingsUrl(filterState, filterType, sort, include, pageSize, pageNumber);
    const res = await axios.get(itemUrl);
    const items = dataFormatter.deserialize(res.data);
    let action = '';
    switch (filterState) {
      case 'state:PROCESSED':
        action = actions.GET_PROCESSED_BILLING_ITEMS;
        break;
      default:
        action = '';
    }
    dispatch({
      type: action,
      payload: items,
    });
  };

export const checkFirstAchPayment = () => async (dispatch) => {
  try {
    const payments = await tenantApi.getBillingItems(
      'state:PROCESSING,state:PROCESSED,state:DELAYED',
      '+billingItemType:CREDIT+refType:Payment',
      'orderDate',
      'payment,billingCharge',
      '10',
      '1'
    );
    let check = false;
    if (!payments.length) {
      check = false;
    }
    // if (payments.length) {
    //   const achPm = payments.filter((p) => p.payment.paymentMethodType === 'ACH');
    //   if (achPm.length === 0) {
    //     check = true;
    //   } else {
    //     check = false;
    //   }
    // }

    dispatch({
      type: actions.SET_FIRST_ACH_PAYMENT,
      payload: check,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getBillingItemsFiltered =
  (auth, filterState, filterType, sort, include, pageSize, pageNumber, lazyLoad) => async (dispatch) => {
    try {
      // dispatch(setTransactionHistoryLoading());

      const transactionsUrl = getBillingsUrl(filterState, filterType, sort, include, pageSize, pageNumber);
      const res = await axios.get(transactionsUrl);
      // console.log('Respuesta original', res);
      const totalCount = res.data.meta.totalCount;
      const transactions = dataFormatter.deserialize(res.data);
      // console.log(transactions);
      const object = { transactions, lazyLoad, totalCount };
      // console.log(object);
      dispatch({
        type: actions.GET_TRANSACTION_HISTORY,
        payload: object,
      });
      return true;
    } catch (error) {
      dispatch({
        type: actions.TRANSACTION_HISTORY_ERROR,
        payload: error.message,
      });
    }
  };

export const clearTransactionsHistory = () => (dispatch) => {
  dispatch({
    type: actions.CLEAR_TRANSACTION_HISTORY,
  });
};

export const setTransactionHistoryLoading = () => (dispatch) => {
  dispatch({
    type: actions.SET_LOADING_TRANSACTION_HISTORY,
  });
};

export const getPaymentMethods = (gateway, history, token) => async (dispatch) => {
  try {
    const paymentMethods = await tenantApi.getPaymentMethodsV3(gateway, history, token);
    return dispatch({
      type: actions.GET_PAYMENT_METHODS,
      payload: paymentMethods,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getPaymentMethodsHistory =
  (gateway, history = true, token) =>
  async (dispatch) => {
    try {
      const paymentMethods = await tenantApi.getPaymentMethodsV3(gateway, history, token);

      return dispatch({
        type: actions.GET_PAYMENT_METHODS_HISTORY,
        payload: paymentMethods,
      });
    } catch (err) {
      console.error(err);
    }
  };

export const getPaymentSettings = (id) => async (dispatch) => {
  try {
    const paymentSettings = await tenantApi.getPaymentSettings(id);

    dispatch({
      type: actions.GET_PAYMENT_SETTINGS,
      payload: paymentSettings,
    });
    return Promise.resolve(paymentSettings);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const updateNotificationSettings = (userId, payload, cognitoToken) => async (dispatch) => {
  try {
    await tenantApi.updateUserPreferencesV3(userId, payload, cognitoToken);
    return dispatch({
      type: actions.UPDATE_NOTIFICATIONS_SETTINGS,
      payload,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// Scheduled payments

export const getScheduledPayments = (cognitoToken) => async (dispatch) => {
  try {
    const scheduledPayments = await tenantApi.getScheduledPayments(cognitoToken);
    dispatch({
      type: actions.GET_SCHEDULED_PAYMENTS,
      payload: scheduledPayments,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: actions.SCHEDULED_PAYMENTS_ERROR,
      payload: err,
    });
  }
};

export const createScheduledPayment = (cognitoToken, values, userId) => async (dispatch) => {
  try {
    const result = await tenantApi.schedulePayment(cognitoToken, values, userId);

    dispatch({
      type: actions.CREATE_SCHEDULED_PAYMENT,
      payload: result,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: actions.SCHEDULED_PAYMENTS_ERROR,
      payload: error,
    });
    throw new Error(error.message);
  }
};

export const updateScheduledPayment = (cognitoToken, id, userId, values, balanceDue) => async (dispatch) => {
  try {
    const result = await tenantApi.updateScheduledPayment(cognitoToken, id, userId, values, balanceDue);
    dispatch({
      type: actions.UPDATE_SCHEDULED_PAYMENT,
      payload: result,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: actions.SCHEDULED_PAYMENTS_ERROR,
      payload: error,
    });
    throw new Error(error.message);
  }
};

export const deleteScheduledPayment = (cognitoToken, id) => async (dispatch) => {
  try {
    const result = await tenantApi.deleteScheduledPayment(cognitoToken, id);
    if (result) {
      await dispatch({
        type: actions.DELETE_SCHEDULED_PAYMENT,
        payload: id,
      });
      return result;
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: actions.SCHEDULED_PAYMENTS_ERROR,
      payload: error,
    });
    throw new Error(error.message);
  }
};

export const getRentCredData = (cognitoToken, id) => async (dispatch) => {
  try {
    const rentCred = await tenantApi.getRentCred(cognitoToken, id);
    // console.log('ƒ tenantActions/getRentCredData', rentCred);
    if (rentCred) {
      const getTier = (id) => {
        switch (id) {
          case 2:
            return { color: '', text: 'Silver' };
          case 3:
            return { color: 'gold', text: 'Gold' };
          case 4:
            return { color: '#8C8C8C', text: 'Platinum' };
          case 5:
            return { color: 'orange', text: 'Bronze' };
          default:
            return { color: 'grey', text: 'Unk' };
        }
      };
      delete rentCred.type;
      rentCred.label = getTier(rentCred.renterTierId).text;
      dispatch({
        type: actions.SET_RENTCRED_DATA,
        payload: rentCred,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const initializeLvbleExperience = (cognitoToken, data, state) => async (dispatch) => {
  // console.log('ƒ tenantActions/initializeLvbleExperience', data);
  try {
    const account = await tenantApi.createUpdateLvbleAccount(cognitoToken, data);
    // console.log('ƒ tenantActions/createUpdateLvbleAccount', account);
    if (account) {
      dispatch(getLvbleToken(cognitoToken, data.id, state));
      dispatch({
        type: actions.SET_LVBLE_ACCOUNT,
        payload: account,
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: actions.SET_LVBLE_ACCOUNT_ERROR,
    });
    throw error;
  }
};

export const updateLvbleAccount = (cognitoToken, id, data) => async (dispatch) => {
  try {
    const account = await tenantApi.updateLvbleAccount(cognitoToken, id, data);
    if (account) {
      dispatch({
        type: actions.SET_LVBLE_ACCOUNT,
        payload: account,
      });
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: actions.SET_LVBLE_ACCOUNT_ERROR,
    });
    throw error;
  }
};

export const getLvbleAccount = (cognitoToken, id) => async (dispatch) => {
  try {
    const account = await tenantApi.getLvbleAccount(cognitoToken, id);
    // console.log('ƒ tenantActions/getLvbleAccount', account);
    if (account) {
      dispatch({
        type: actions.SET_LVBLE_ACCOUNT,
        payload: account || null,
      });
    }
  } catch (error) {
    console.error('ƒ tenantActions/getLvbleAccount', error);
    dispatch({
      type: actions.SET_LVBLE_ACCOUNT_ERROR,
      payload: error.status !== 404,
    });
    throw error;
  }
};

export const getLvbleToken =
  (cognitoToken, id, state = 'EXPERIENCE_CLOSED') =>
  async (dispatch) => {
    try {
      const lvbleToken = await tenantApi.getLvbleToken(cognitoToken, id);
      // console.log('ƒ tenantActions/getLvbleToken result', lvbleToken);
      if (lvbleToken) {
        dispatch({
          type: actions.SET_LVBLE_TOKEN,
          payload: lvbleToken,
        });
        dispatch({ type: actions.SET_LVBLE_EXPERIENCE, payload: state });
      }
    } catch (error) {
      dispatch({
        type: actions.SET_LVBLE_TOKEN_ERROR,
      });
      throw error;
    }
  };

export const processLvblePayment = (cognitoToken, legacyToken, userData, data) => async (dispatch) => {
  try {
    data.billing_account_id = userData.billingAccounts.find((ba) => ba.state === 'ACTIVE').id;
    data.token = legacyToken;
    data.tenant_id = userData.id;

    const result = tenantApi.settleLvblePayment(cognitoToken, data);
    // console.log('ƒ tenantActions/processLvblePayment', result);
    if (result) {
      dispatch({
        type: actions.SET_LVBLE_ACCOUNT,
        payload: result,
      });
    }
  } catch (error) {
    console.error('ƒ tenantActions/processLvblePayment', error);
    dispatch({
      type: actions.SET_LVBLE_TOKEN_ERROR,
    });
    throw error;
  }
};
