import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDocuments } from 'services/api/common/read';
import { useSelector } from 'react-redux';
import { Empty, Skeleton } from 'antd';
import Text from 'components/Text/Text';
import DownloadDocument from 'screens/Landlord/Documents/components/DownloadDocument';
import Space from 'components/Space/Space';

function SharedDocuments() {
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const {
    data: docs,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['documents'],
    queryFn: async () => await getDocuments(cognitoToken, 1, 10000),
    select: (data) => data.items.map((d) => ({ ...d, key: d.id })),
  });

  // console.log('docs', docs);

  return (
    <>
      <Space verical={24} />
      <Text color="black" size={16} strong>
        Shared Documents
      </Text>
      <Space verical={12} />
      <Skeleton active loading={isLoading} paragraph={{ rows: 0 }}>
        {docs && docs.length ? (
          docs.map((doc) => (
            <div key={doc.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text size={16} color="black">
                {doc.label}
              </Text>
              <DownloadDocument record={doc} />
            </div>
          ))
        ) : isError ? (
          <Text color="black">Error fetching documents</Text>
        ) : (
          <Empty description="No Documents" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Skeleton>
    </>
  );
}

export default SharedDocuments;
