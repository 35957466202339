import React, { useState, useEffect, useCallback, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';
import { Title, Subtitle } from 'components/Panel/PanelTitle/PanelTitle';
import { Form, Switch, Select, Input, TreeSelect } from 'antd';
import styled from 'styled-components';
import UploadButton from 'components/UploadButton/UploadButton';
import { motion } from 'framer-motion';
import { formVariants } from 'animations/variants';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertiesV3, getRentersV3 } from 'services/api/landlordApi/read';
import FileType from 'file-type/browser';
import useFileManager from 'hooks/useFileManager';
import { globalDisplayAlert } from 'resources/helpers';

const { Item, useForm } = Form;

function DocumentPanel(props) {
  // console.log('[DocumentPanel.js]', props);
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const cognitoToken = session.cognitoTokens?.idToken.jwtToken;
  const { setSubmitting } = props;
  const { uploadFile, deleteFile } = useFileManager();
  const [form] = useForm();
  const [selectedTags, setSelectedTags] = useState([]);
  const [tenantSelectOptions, setTenantSelectOptions] = useState([]);
  const [unitSelectOptions, setUnitSelectOptions] = useState([]);
  const [loading, setLoading] = useState({ unit: false, tenant: false });
  const [uploadState, setUploadState] = useState({
    fileList: [],
    uploading: false,
  });

  const handleFinish = useCallback(
    async (values) => {
      try {
        setSubmitting(true);
        const docId = props.editDocument?.id || uuidv4();
        let newFile;
        let fileMetadata = props.editDocument?.metadata || {};

        if (!!uploadState.fileList.length) {
          // url encode file name
          const name = uploadState.fileList[0].name.toLowerCase().replace(/\s/g, '_');
          newFile = { file: uploadState.fileList[0], name };
          // if new file is a pdf then get metadata
          if (newFile.file.type === 'application/pdf') {
            // check if file is encrypted

            const pdf = await PDFDocument.load(await newFile.file.arrayBuffer());
            if (pdf.isEncrypted)
              throw new Error('Encrypted PDFs are not supported. Please upload a different file.');

            const size = pdf.getPage(0).getSize();

            fileMetadata = {
              pageCount: pdf.getPageCount(),
              author: pdf.getAuthor(),
              creationDate: pdf.getCreationDate(),
              modificationDate: pdf.getModificationDate(),
              subject: pdf.getSubject(),
              title: pdf.getTitle(),
              dimensions: { width: size.width, height: size.height },
            };
            // console.log('ƒ handleFinish/pdfMetadata', fileMetadata);
          }
          await uploadFile(docId, newFile, 'upload', 'document', ['pdf', 'jpg', 'jpeg', 'png', 'zip']);
        }
        // console.log('ƒ handleFinish uploadRes', uploadRes);

        const associations = {
          tenants: values.tenant || [],
          properties: values.property?.filter((p) => p.includes('propertyId-')).map((p) => p.split('-')[1]) || [],
          parentProperties:
            values.property?.filter((p) => p.includes('parentPropertyId-')).map((p) => p.split('-')[1]) || [],
        };

        const eventList = (editDoc) => {
          let eventList = [];
          if (!editDoc) {
            eventList.push({
              eventType: 'created',
              entity: 'document',
              occurredAt: new Date().toISOString(),
              message: `Created the document ${values.label} `,
              metadata: {
                eventCreatedBy: session.userData.id,
              },
            });
          } else {
            eventList = [...editDoc.events];
            eventList.push({
              eventType: 'updated',
              entity: 'document',
              message: `Updated the document ${values.label}`,
              occurredAt: new Date().toISOString(),
              metadata: {
                eventCreatedBy: session.userData.id,
              },
            });
          }
          return eventList;
        };

        const documentData = {
          id: docId,
          landlordId: +session.userData.id,
          label: values.label,
          fileName: newFile?.name || props.editDocument?.fileName,
          mimeType: newFile?.file.type || props.editDocument?.mimeType,
          isPublic: values.isPublic,
          isTemplate: values.isTemplate,
          tags: { user: values.tags || [] },
          associations,
          events: eventList(props.editDocument),
          metadata: fileMetadata,
        };

        // console.log('[DocumentPanel.js] ƒ handleFinish/documentData', documentData);

        await props.handleMutation.mutateAsync(documentData);

        setUploadState({ fileList: [], uploading: false });

        setSubmitting(false);
        setTimeout(() => {
          props.setDrawerVisible(false);
          props.setEditDocument(null);
          globalDisplayAlert(
            dispatch,
            '',
            'success',
            `Document sucessfully ${!!props.editDocument ? 'updated' : 'created'}`
          );
        }, 100);
      } catch (err) {
        setSubmitting(false);
        props.displayAlert(err.message || 'Failed to save document', 'error');
        console.error('[DocumentPanel.js] ƒ handleFinish', err);
      }
    },
    [dispatch, props, session.userData.id, setSubmitting, uploadFile, uploadState.fileList]
  );

  const fetchAndSetData = useCallback(
    async (controller) => {
      try {
        setLoading({ unit: true, tenant: true });
        if (!!props.editDocument) {
          const propertyTreeMap = (editDoc) => {
            const { associations } = editDoc;
            const unitValues = associations.properties.map((p) => `propertyId-${p}`);
            const parentValues = associations.parentProperties.map((p) => `parentPropertyId-${p}`);
            return [...unitValues, ...parentValues];
          };

          form.setFieldsValue({
            label: props.editDocument?.label,
            isTemplate: props.editDocument?.isTemplate,
            isPublic: props.editDocument?.isPublic,
            tags: props.editDocument.tags?.user || [],
            tenant: props.editDocument.associations?.tenants || [],
            property: propertyTreeMap(props.editDocument) || [],
          });
        } else {
          setUploadState({ fileList: [], uploading: false });
          form.setFieldsValue({
            label: '',
            isTemplate: false,
            isPublic: false,
            tags: [],
            tenant: [],
            property: [],
          });
        }

        const fetchUnits = getPropertiesV3(cognitoToken, '', controller);
        const fetchTenants = getRentersV3(cognitoToken, 1, 10000, '', controller);
        const [units, tenants] = await Promise.all([fetchUnits, fetchTenants]);

        // console.log('ƒ fetchAndSetData/tenants', tenants);

        if (units?.length) {
          const parentGroups = Object.groupBy(units, ({ parentPropertyId }) => parentPropertyId);
          const propertyTree = [];

          for (const key in parentGroups) {
            const pChildren = parentGroups[key]
              .map((p) => ({ title: `Unit ${p.address2}`, value: `propertyId-${p.id}` }))
              .sort((a, b) => a.title.localeCompare(b.title));
            // console.log(pChildren)
            propertyTree.push({
              value: `parentPropertyId-${key}`,
              title: parentGroups[key][0].address,
              children: pChildren,
            });
          }

          setUnitSelectOptions(() => propertyTree.sort((a, b) => a.title.localeCompare(b.title)));
        }

        if (tenants?.items.length) {
          setTenantSelectOptions(() =>
            tenants.items.map((t) => ({ label: `${t.firstName} ${t.lastName}`, value: t.id }))
          );
        }
      } catch (error) {
        console.error('ƒ fetchAndSetData ERR', error);
      }
      setLoading({ unit: false, tenant: false });
    },
    [cognitoToken, form, props.editDocument]
  );

  const handleDeleteFile = async (filename) => {
    try {
      await deleteFile(null, filename);
    } catch (error) {
      console.error('ƒ deleteFile', error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    console.log('ƒ useEffect');
    if (props.drawerVisible) {
      fetchAndSetData(controller);
    }
    return () => {
      console.log('ƒ cleanup');
      controller.abort();
    };
  }, [fetchAndSetData, props.drawerVisible, form]);

  return (
    <DrawerContainer variants={formVariants} initial="hide" animate="show">
      <Title>{props.editDocument ? 'Edit' : 'Add'} Document</Title>
      <Subtitle>Upload and Organize Your Documents</Subtitle>
      <FormContentContainer>
        <Form
          form={form}
          name="document-form"
          layout="vertical"
          requiredMark="optional"
          onFinish={handleFinish}
          initialValues={{
            isTemplate: false,
            isPublic: false,
            property: [],
            tenant: [],
            label: '',
            tags: [],
            file: '',
          }}
        >
          <Item
            name="file"
            style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: '24px' }}
            extra="Max file size 10Mb"
            rules={[{ required: !props.editDocument, message: 'Please upload a document' }]}
          >
            <UploadButton
              text="Upload Document"
              size="large"
              disabled={uploadState.uploading || !!uploadState.fileList.length || !!props.editDocument}
              config={{
                accept: '.pdf,.jpg,.jpeg,.png,.zip',
                showUploadList: true,
                multiple: false,
                fileList: uploadState.fileList,
                onRemove: async (file) => {
                  try {
                    await handleDeleteFile(file);
                    const index = uploadState.fileList.indexOf(file);
                    const newFileList = uploadState.fileList.slice();
                    newFileList.splice(index, 1);
                    form.setFieldValue('file', '');
                    setUploadState((prev) => ({ ...prev, fileList: newFileList }));
                  } catch (err) {
                    console.error(err);
                  }
                },
                beforeUpload: (file) => {
                  FileType.fromBlob(file).then((type) => {
                    let valid = true;
                    if (type === undefined) {
                      valid = false;
                      props.displayAlert('Invalid file type', 'warning');
                    }

                    if (
                      type?.ext !== 'pdf' &&
                      type?.ext !== 'jpg' &&
                      type?.ext !== 'jpeg' &&
                      type?.ext !== 'png' &&
                      type?.ext !== 'zip'
                    ) {
                      valid = false;
                      props.displayAlert('Invalid file type', 'warning');
                    }
                    if (file.size / 1024 / 1020 > 10) {
                      valid = false;
                      props.displayAlert('File Size Exceeds 10 MB', 'warning');
                    }
                    if (valid) setUploadState({ ...uploadState, fileList: [...uploadState.fileList, file] });
                    return false;
                  });
                  form.setFieldValue('file', file.name);
                  return false;
                },
              }}
            />
          </Item>
          <Item name="label" label="Label" rules={[{ required: true }]}>
            <Input placeholder="Lease Addendum ver. 2b" />
          </Item>
          <Item name="tags" label="Tags">
            <Select
              mode="tags"
              placeholder="Tag your document"
              value={selectedTags}
              onChange={setSelectedTags}
              maxCount={5}
              style={{
                width: '100%',
              }}
              options={props.tagOptions?.filter((o) => !selectedTags.includes(o.value))}
            />
          </Item>
          <Item
            name="isTemplate"
            label="Save as Template"
            valuePropName="checked"
            tooltip="Make this document a template for other documents."
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Item>
          <Text as="div" color="black" strong>
            Associate With:
          </Text>
          <Space vertical={18} />
          <Item name="property" label="Properties">
            <TreeSelect
              treeData={unitSelectOptions}
              placeholder="Select a Unit"
              loading={loading.unit}
              disabled={loading.unit}
              showSearch
              multiple
            />
          </Item>
          <Item name="tenant" label="Tenants">
            <Select
              options={tenantSelectOptions}
              placeholder="Select a Tenant"
              loading={loading.tenant}
              disabled={loading.tenant}
              showSearch
              mode="multiple"
            />
          </Item>
          <Item
            name="isPublic"
            label="Share with Tenant(s)"
            valuePropName="checked"
            extra="Document can be viewed and downloaded by associated tenants."
          >
            <Switch checkedChildren="Public" unCheckedChildren="Private" />
          </Item>
        </Form>
      </FormContentContainer>
    </DrawerContainer>
  );
}

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 25px 0;
  @media screen and (max-width: 400px) {
    padding: 20px 0;
  }
`;

const DrawerContainer = styled(motion.div)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export default DocumentPanel;
