// @flow
import * as React from 'react';
import { Button as AntButton, theme } from 'antd';
import styled, { css } from 'styled-components';

const Button = (props) => {
  const { token } = theme.useToken();
  const {
    children,
    color,
    outlined,
    alignment,
    bottom,
    top,
    right,
    left,
    fontSize,
    inline,
    relativeMarginLeft,
    padding1,
    padding2,
    style,
    ...rest
  } = props;

  return (
    <ButtonContainer
      bottom={bottom}
      alignment={alignment}
      fontSize={fontSize}
      top={top}
      right={right}
      left={left}
      inline={inline}
      relativeMarginLeft={relativeMarginLeft}
      style={style}
      className="button-container"
    >
      <StyledButton
        {...rest}
        color={color}
        outlined={outlined}
        padding1={padding1}
        padding2={padding2}
        fontSize={fontSize}
        theme={token}
      >
        {children}
      </StyledButton>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  margin-bottom: ${(props) => (props.bottom !== undefined ? `${props.bottom}px` : '15px')};
  margin-top: ${(props) => (props.top > -50 ? `${props.top}px` : '0px')};
  margin-right: ${(props) => (props.right ? `${props.right}px` : '0px')};
  margin-left: ${(props) => (props.left ? `${props.left}px` : '0px')};
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: ${(props) => {
    let alignment;
    if (props.alignment) {
      switch (props.alignment) {
        case 'left':
          alignment = 'flex-start';
          break;
        case 'center':
          alignment = 'center';
          break;
        case 'right':
          alignment = 'flex-end';
          break;
        default:
          break;
      }
    }
    return alignment;
  }};

  ${(props) => {
    if (props.relativeMarginLeft) {
      return css`
        margin-left: ${props.relativeMarginLeft}%;
      `;
    }
  }};
  @media screen and (max-width: 400px) {
    .ant-btn-link {
      font-size: ${(props) => (props.fontSize ? `${props.fontSize}px !important` : '14px !important')};
      span {
        text-decoration: underline;
        font-weight: 100 !important;

        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
`;

// Optional button colors
const violet = css`
  background-color: #cb48b7;
  border-color: #cb48b7;
  color: white;
  border-radius: 4px;
  &:hover {
    background-color: #7530ac;
    border-color: #7530ac;
    color: white;
  }
  &:active {
    background-color: #7530ac;
    border-color: #7530ac;
    color: white;
  }

  &:focus {
    background-color: #7530ac;
    border-color: #7530ac;
    color: white;
  }
`;
const yellow = css`
  background-color: #ffcc00;
  border-color: #ffcc00;
  color: #122c34;
  &:hover {
    background-color: #ffffff;
    border-color: 5#41388;
    color: #122c34;
  }
  &:active {
    background-color: #ffffff;
    border-color: 5#41388;
    color: #122c34;
  }

  &:focus {
    background-color: #ffcc00;
    border-color: #ffcc00;
    color: #122c34;
  }
`;

const red = css`
  background-color: #ff4a54;
  border-color: #ff4a54;
  color: white;
  &:hover {
    background-color: #ff4a54;
    border-color: #ff4a54;
    color: white;
  }

  &:active {
    background-color: #ff4a54;
    border-color: #ff4a54;
    color: white;
  }

  &:focus {
    background-color: #ff4a54;
    border-color: #ff4a54;
    color: white;
  }
`;

const green = css`
  background-color: #3db44b;
  border-color: #3db44b;
  color: white;
  &:hover {
    background-color: #3db44b;
    border-color: #3db44b;
    color: white;
  }

  &:active {
    background-color: #3db44b;
    border-color: #3db44b;
    color: white;
  }

  &:focus {
    background-color: #3db44b;
    border-color: #3db44b;
    color: white;
  }
`;

const darkGreen = css`
  background-color: #006633;
  border-color: #006633;
  color: white;
  &:hover {
    background-color: #006633;
    border-color: #006633;
    color: white;
  }

  &:active {
    background-color: #006633;
    border-color: #006633;
    color: white;
  }

  &:focus {
    background-color: #006633;
    border-color: #006633;
    color: white;
  }
`;

const lvbleGreen = css`
  background-color: #09d1a2;
  border-color: #09d1a2;
  color: white;
  &:hover {
    background-color: #09d1a2;
    border-color: #09d1a2;
    color: white;
  }

  &:active {
    background-color: #09d1a2;
    border-color: #09d1a2;
    color: white;
  }

  &:focus {
    background-color: #09d1a2;
    border-color: #09d1a2;
    color: white;
  }
`;

const lightBlue = css`
  background-color: #624df7;
  border-color: #624df7;
  color: white;
  &:hover {
    background-color: #624df7;
    border-color: #624df7;
    color: white;
  }

  &:active {
    background-color: #624df7;
    border-color: #624df7;
    color: white;
  }

  &:focus {
    background-color: #624df7;
    border-color: #624df7;
    color: white;
  }
`;

const darkGrey = css`
  background-color: #122c34;
  border-color: #122c34;
  color: white;
  &:hover {
    background-color: #122c34;
    border-color: #122c34;
    color: white;
  }

  &:active {
    background-color: #122c34;
    border-color: #122c34;
    color: white;
  }

  &:focus {
    background-color: #122c34;
    border-color: #122c34;
    color: white;
  }
`;

const blue = css`
  background-color: #624df7;
  border-color: #624df7;
  color: white;
  &:hover {
    background-color: #ffffff;
    border-color: #000000;
    color: #000000;
  }

  &:active {
    background-color: #ffffff;
    border-color: #000000;
    color: #0000000;
  }

  &:focus {
    background-color: #ffffff;
    border-color: #000000;
    color: #000000;
  }
`;

const outlined = css`
  border-color: #cb48b7;
  color: #cb48b7;
  &:hover {
    border-color: #a1208c;
    color: #a1208c;
  }
  &:focus {
    border-color: #cb48b7;
    color: #cb48b7;
  }
`;

const StyledButton = styled(({ fontSize, color, ...rest }) => <AntButton {...rest} />)`
  font-weight: 500;
  font-family: Poppins, sans-serif;
  border-radius: 6px !important;
  ${(props) => {
    if (!!props.outlined) {
      return outlined;
    }
  }};
  ${(props) => {
    if (props.fontSize) {
      return css`
        font-size: ${props.fontSize}px !important;
      `;
    }
  }};
  ${(props) => {
    // eslint-disable-next-line default-case
    switch (props.color) {
      case 'violet':
        return violet;
      case 'yellow':
        return yellow;
      case 'red':
        return red;
      case 'darkGrey':
        return darkGrey;
      case 'blue':
        return blue;
      case 'green':
        return green;
      case 'lightBlue':
        return lightBlue;
      case 'darkGreen':
        return darkGreen;
      case 'lvbleGreen':
        return lvbleGreen;
    }
  }};
  ${(props) => {
    if (props.height) {
      return css`
        height: ${props.height}px;
      `;
    }
  }};
  ${(props) => {
    if (props.lineheight) {
      return css`
        line-height: ${props.lineheight}px;
      `;
    }
  }};
  ${(props) => {
    if (props.padding1 && props.padding2) {
      return css`
        padding: ${props.padding1}px ${props.padding2}px;
      `;
    }
  }};
  ${(props) => {
    if (props.paddingtop) {
      return css`
        padding-top: ${props.paddingtop}px !important;
      `;
    }
  }};
  ${(props) => {
    if (props.paddingbottom) {
      return css`
        padding-bottom: ${props.paddingbottom}px !important;
      `;
    }
  }};
`;

export default Button;
