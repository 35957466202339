import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

import Text from 'components/Text';
import Space from 'components/Space';

const TermsConditions = (props) => {
  const { setTermsConditions } = props;
  const [out, setOut] = useState(false);

  useEffect(() => {}, []);

  return (
    <Container out={out}>
      <CloseOutlined
        style={{
          fontSize: '14px',
          color: '#686868',
          position: 'absolute',
          right: '11px',
          top: '11px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setOut(true);
          setTimeout(() => {
            setTermsConditions(false);
          }, 280);
        }}
      />
      <Text size={16} color="black" weight={500}>
        Terms and Conditions
      </Text>
      <Space vertical={10} />
      <BodyContainer>
        <Text size={12} color="black">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu mi, ultrices ut lobortis eget, auctor
          vitae tellus. Phasellus finibus velit vel cursus pellentesque. Nullam luctus sodales accumsan. Nulla
          sagittis et nisi eu consectetur. Etiam bibendum, mauris a pellentesque tristique, ex ipsum scelerisque
          arcu, sed gravida dui libero et libero. Proin sit amet leo a ipsum accumsan ultrices. Integer accumsan
          commodo ex ac suscipit. Maecenas sollicitudin, felis vel elementum feugiat, massa elit tincidunt mauris,
          eget fermentum turpis leo vitae turpis. Pellentesque habitant morbi tristique senectus et netus et
          malesuada fames ac turpis egestas. Proin pellentesque tempus urna, a blandit ligula varius nec. Praesent
          ornare nunc risus, eget bibendum justo posuere tempus. Aliquam fringilla tempus pharetra. Duis dapibus
          sem ac aliquet condimentum. Maecenas odio nibh, vehicula et quam sed, sodales pretium magna. Aenean
          imperdiet nibh id hendrerit tristique. Maecenas ultricies mi massa, eu imperdiet nibh interdum eu. In id
          augue eu ligula iaculis mollis. Sed et ipsum leo. Proin malesuada arcu nibh. Nam et ante vitae ante
          pellentesque suscipit. Morbi auctor nisl vel turpis condimentum aliquam. In ac rutrum lorem, lacinia
          imperdiet tellus. Suspendisse lacus leo, venenatis quis dui nec, sollicitudin volutpat sapien. Quisque
          vehicula aliquet placerat. Mauris malesuada luctus sodales. In suscipit neque vel lectus eleifend,
          vulputate gravida ex tristique. Maecenas mollis blandit erat, nec eleifend quam sodales ac. Donec auctor
          nibh nec metus mattis consectetur. Vestibulum accumsan ligula odio, a sagittis nisi ullamcorper semper.
          Morbi euismod accumsan viverra. Vestibulum consequat ex ac neque iaculis tristique. Duis dignissim
          maximus sem, non tempor mi gravida quis. Morbi et tortor nibh. Aliquam porta, dui in tincidunt ornare,
          sapien mi auctor velit, quis condimentum felis odio eget ligula. Pellentesque lectus leo, tincidunt sit
          amet pulvinar rhoncus, convallis id dolor. Nullam eu dui tempor, lacinia nunc non, luctus quam. Aliquam
          erat volutpat. Fusce velit nisi, hendrerit vel pretium non, efficitur nec purus. Mauris id ex nibh. Etiam
          placerat turpis lectus. Donec in quam orci. Pellentesque nec vulputate mi. Mauris nec urna elit.
          Curabitur tempus felis turpis, vel viverra lacus lobortis ut. Curabitur finibus rutrum lectus, in commodo
          augue. Duis non ante augue. Curabitur sed velit turpis. Maecenas efficitur fringilla purus sed blandit.
          Aenean pretium risus orci, vel tristique odio venenatis id. Nam et mattis quam. Praesent lobortis
          scelerisque ipsum ut egestas. Donec sed dictum nulla. Etiam ante lorem, cursus ac quam sed, ultricies
          dignissim turpis. Nullam vel nisl tincidunt, luctus eros a, efficitur ligula. Donec dignissim metus a
          nisl sodales, a commodo sapien suscipit. Nulla tristique lorem purus, ut aliquam augue laoreet eu.
        </Text>
      </BodyContainer>
    </Container>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  } 
`;

const Container = styled.div`
  position: absolute;
  width: 350px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: white;
  border: 1px solid #d9d9d9;
  z-index: 5;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 20px;
  animation: 0.3s ${(props) => (props.out ? fadeOut : fadeIn)}; linear;
`;

const BodyContainer = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default TermsConditions;
