import * as actions from 'store/actions';

const initalState = {
  header: {
    title: '',
    leftButtonVisible: false,
    leftButtonPath: '',
    leftButtonType: '',
  },
  menu: {
    currentSelectedMenu: '',
  },
  bottomCard: {
    forceVisible: false,
  },
  alert: {
    isVisible: false,
    message: '',
    type: 'success',
    description: '',
    closable: false,
  },
  loading: false,
  loadingOverlay: false,
  tour: {
    showMainTour: false,
    showPaymentTour: false,
  },
  redirectPathName: null,
  panel: null,
};

export default (state = initalState, action) => {
  switch (action.type) {
    case actions.HEADER_BUTTON_VISIBLE:
      return {
        ...state,
        header: {
          leftButtonVisible: action.visible,
          title: action.screenTitle,
          leftButtonPath: action.leftButtonPath,
          leftButtonType: action.leftButtonType,
        },
      };
    case actions.BOTTOM_CARD_FORCE_VISIBLE:
      return {
        ...state,
        bottomCard: {
          forceVisible: action.forceVisible,
        },
      };
    case actions.SET_SELECTED_MENU:
      return {
        ...state,
        menu: {
          currentSelectedMenu: action.payload,
        },
      };
    case actions.SET_ALERT:
      return {
        ...state,
        alert: {
          ...action.payload,
        },
      };
    case actions.UNSET_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          isVisible: false,
        },
      };
    case actions.SET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.SET_LOADING_OVERLAY:
      return {
        ...state,
        loadingOverlay: action.payload,
      };
    case actions.SET_SHOW_TOUR:
      return {
        ...state,
        tour: {
          ...state.tour,
          ...action.payload,
        },
      };
    case actions.SET_REDIRECT_PATHNAME:
      return {
        ...state,
        redirectPathName: action.payload,
      };
    case actions.SET_PANEL:
      return {
        ...state,
        panel: action.payload,
      };
    default:
      return state;
  }
};
