import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Card as AntCard, Spin, Drawer } from 'antd';

import * as Iot from 'services/aws/iot/aws-iot';

import {
  getBillingItemsFiltered,
  getBillingItems,
  setTransactionHistoryLoading,
  clearTransactionsHistory,
  getPaymentMethods,
} from 'store/actions/tenantActions';
import * as tenantApi from 'services/api/tenantApi';

import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

import { pollCheck } from 'resources/helpers';

// Components
import Title from 'components/Title';
import Space from 'components/Space';
import BottomCard from 'components/BottomCard';
import CardWithMenu from 'components/CardWithMenu';
import AlertFade from 'components/AlertFade';
import CardBlank from 'components/CardBlank';

import UpcomingCharges from '../components/UpcomingCharges';
import TransactionsHistory from './components/TransactionsHistory';
import ComplianceAlert from '../components/ComplianceAlert';
import PaymentSchedule from '../Payment/PaymentSchedule';
import PaymentMethods from '../components/PaymentMethods';
import PaymentMethodDetail from '../components/PaymentMethods/components/PaymentMethodDetail';
import InsuranceAlert from '../components/InsuranceAlert';
import AccountStatusTitle from '../components/AccountStatusTitle';
import MicrodepositAlert from '../components/MicrodepositAlert';

const Transactions = () => {
  const [hasMore, setHasMore] = useState(false);
  const [filterType, setFilterType] = useState(null);
  const [filterState, setFilterState] = useState(
    'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED'
  );

  const [payMethodsVisible, showPayMethods] = useState(false);
  const [showPaymentMethodDetail, setShowPaymentMethodDetail] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showRadioButtons, setShowRadioButtons] = useState(true);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [defPaymentMethod, setDefPaymentMethod] = useState(false);
  const [showScheduledPayment, setShowScheduledPayment] = useState(false);
  const [balanceDue, setBalanceDue] = useState(true);
  const [otherAmount, setOtherAmount] = useState(0);

  const paymentMethods = useSelector((store) => store.tenant.paymentMethods);
  const totalCount = useSelector((store) => store.tenant.transactionHistory.totalCount);
  const userData = useSelector((store) => store.session.userData);
  const billingItems = useSelector((store) => store.tenant.billingItems);
  const globalLoading = useSelector((store) => store.global.loading);
  const alert = useSelector((store) => store.global.alert);
  const transactionHistory = useSelector((store) => store.tenant.transactionHistory.items);
  const session = useSelector((store) => store.session);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const AuthStr = session.cognitoTokens.idToken.jwtToken;

  const filterStateRef = useRef();
  const filterRef = useRef();
  const pagesRef = useRef([]);

  let timerId = null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (transactionHistory.length < totalCount) setHasMore(true);
  }, [transactionHistory, totalCount]);

  useEffect(() => {
    const controller = new AbortController();
    filterStateRef.current = 'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED';
    filterRef.current = null;
    dispatch({ type: 'GET_BILLING_ITEMS_LOADING' });
    dispatch(getServicePlanAction(AuthStr));
    dispatch(getBillingItems());
    dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
    dispatch(setTransactionHistoryLoading());
    dispatch(
      getBillingItemsFiltered(AuthStr, filterState, null, 'orderDate', 'payment,billingCharge', 10, 1, false)
    );
  }, []);

  useEffect(() => {
    Iot.attachMessageHandler((topic, message) => {
      const string = new TextDecoder().decode(message);
      const ms = JSON.parse(string);
      if (ms.event === 'TRANSACTION_UPDATE') {
        dispatch(
          getBillingItemsFiltered(
            AuthStr,
            filterStateRef.current,
            filterRef.current,
            'orderDate',
            'payment,billingCharge',
            10,
            1,
            false
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    if (transactionHistory.length < totalCount) setHasMore(true);
  }, [transactionHistory]);

  const handleLoadMore = () => {
    if (transactionHistory.length < totalCount) {
      if (transactionHistory.length >= 10) {
        const calculate = Math.ceil(transactionHistory.length / 5);
        const pageNum = calculate + 1;
        if (!pagesRef.current.includes(pageNum)) {
          pagesRef.current.push(pageNum);

          dispatch(
            getBillingItemsFiltered(
              '',
              filterState,
              filterType,
              'orderDate',
              'payment,billingCharge',
              5,
              pageNum,
              true
            )
          );
        }
      }
    } else if (transactionHistory.length >= totalCount && totalCount !== null) {
      setHasMore(false);
    }
  };

  const handleChangeFilter = (filterType) => {
    switch (filterType) {
      case 'All':
        filterRef.current = null;
        filterStateRef.current = 'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED';
        setFilterType(null);
        setFilterState('state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED');
        dispatch(clearTransactionsHistory());
        dispatch(
          getBillingItemsFiltered(
            '',
            'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED',
            null,
            'orderDate',
            'payment,billingCharge',
            10,
            1,
            false
          )
        );
        dispatch({
          type: 'SET_ACTIVE_BOX_MENU',
          activeBoxMenu: {},
        });
        break;
      case 'Credits':
        filterRef.current = 'CREDIT';
        setFilterType('CREDIT');
        dispatch(clearTransactionsHistory());
        dispatch(
          getBillingItemsFiltered(
            '',
            'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED',
            'CREDIT',
            'orderDate',
            'payment,billingCharge',
            10,
            1,
            false
          )
        );
        dispatch({
          type: 'SET_ACTIVE_BOX_MENU',
          activeBoxMenu: {},
        });
        break;
      case 'Charges':
        filterRef.current = 'CHARGE';
        setFilterType('CHARGE');
        dispatch(clearTransactionsHistory());
        dispatch(
          getBillingItemsFiltered(
            AuthStr,
            'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED',
            'CHARGE',
            'orderDate',
            'payment,billingCharge',
            10,
            1,
            false
          )
        );
        dispatch({
          type: 'SET_ACTIVE_BOX_MENU',
          activeBoxMenu: {},
        });
        break;
      default:
        setFilterType(null);
    }
  };

  const checkPaymentMethodStatus = async () => {
    const updated = dispatch(
      getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken)
    );
    const arr = updated?.payload ? updated.payload : [];
    const poll = pollCheck(arr);
    if (poll) {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        try {
          const methods = await tenantApi.getPaymentMethodsV3(
            userData.paymentGateway,
            false,
            session.cognitoTokens.idToken.jwtToken
          );

          const update = pollCheck(methods);
          if (!update) {
            dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
            clearInterval(timerId);
          }
        } catch (error) {
          console.log(error);
        }
      }, 3000);
    }
  };

  const togglePaymentMethods = () => {
    showPayMethods(!payMethodsVisible);
    setDefPaymentMethod(true);
  };

  return (
    <>
      <StyledDrawer
        closable={false}
        placement="right"
        destroyOnClose
        onClose={() => {
          setDrawerVisible(false);
          setShowPaymentMethodDetail(false);
          setShowScheduledPayment(false);
        }}
        open={drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {showScheduledPayment && (
          <PaymentSchedule
            showScheduledPayment={showScheduledPayment}
            setShowScheduledPayment={setShowScheduledPayment}
            setDrawerVisible={setDrawerVisible}
            balanceDue={balanceDue}
            otherAmount={otherAmount}
          />
        )}
        {showPaymentMethodDetail && (
          <PaymentMethodDetail
            setDrawerVisible={setDrawerVisible}
            setShowPaymentMethodDetail={setShowPaymentMethodDetail}
            payMethodsVisible={payMethodsVisible}
            // AddBankAccount={AddBankAccount}

            //payClicked={payClicked}
            //paymentMethodId={paymentMethodId}
            checkPaymentMethodStatus={checkPaymentMethodStatus}
            defPaymentMethod={defPaymentMethod}
            setDefPaymentMethod={setDefPaymentMethod}
          />
        )}
      </StyledDrawer>
      <CardBlank title={<AccountStatusTitle userData={userData} />}>
        <AlertFade
          isVisible={alert.isVisible}
          message={alert.message}
          description={alert.description}
          type={alert.type}
          display
          closable={alert.closable}
          marginTop={10}
          onClose={() => {
            dispatch({
              type: 'UNSET_ALERT',
            });
          }}
        />
        {globalLoading && (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        )}
        <MicrodepositAlert />
        <InsuranceAlert />
        <ComplianceAlert
          setDrawerVisible={setDrawerVisible}
          togglePaymentMethods={togglePaymentMethods}
          setShowScheduledPayment={setShowScheduledPayment}
        />
      </CardBlank>

      <Space vertical={10} />

      <AntCard
        title="Upcoming Charges and Credits"
        styles={{
          header: {
            fontSize: '16px',
            color: '#541388',
            fontWeight: 600,
          },
          body: {
            padding: '5px 10px 10px 10px',
          },
        }}
      >
        <UpcomingCharges upcomingBillingItems={billingItems.delayed} loading={billingItems.loading} />
      </AntCard>

      <Space vertical={10} />

      <CardWithMenu
        id="transactionHistory"
        title="Transaction History"
        handleChangeFilter={handleChangeFilter}
        items={[
          {
            text: 'All',
            link: '#',
          },
          {
            text: 'Credits',
            link: '#',
          },
          {
            text: 'Charges',
            link: '#',
          },
        ]}
        styles={{
          body: {
            padding: '0',
          },
        }}
      >
        <TransactionsHistory
          items={transactionHistory}
          handleLoadMore={handleLoadMore}
          hasMore={hasMore}
          filterType={filterType}
        />
      </CardWithMenu>
      <BottomCard
        isVisible={payMethodsVisible}
        setVisible={showPayMethods}
        /*
         * Do not use offsetHeight/clientHeight or similar to get the container height for the translation
         * It could lead to undesired behaviors due to the nature of Transition and onClickOutside components
         */
        translation={180 + paymentMethods.length * 41}
        outsideClickIgnoreClass={'ant-dropdown-menu'}
      >
        <Title level={4} color="black" bottom={20} centered>
          Payment Methods
        </Title>
        <PaymentMethods
          payMethodsVisible={payMethodsVisible}
          showPayMethods={showPayMethods}
          showPaymentMethodDetail={showPaymentMethodDetail}
          setShowPaymentMethodDetail={setShowPaymentMethodDetail}
          setDrawerVisible={setDrawerVisible}
          setShowPayment={setShowPayment}
          showRadioButtons={showRadioButtons}
          setPaymentMethodId={setPaymentMethodId}
          defPaymentMethod={defPaymentMethod}
          setDefPaymentMethod={setDefPaymentMethod}
        />
      </BottomCard>
    </>
  );
};

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
`;

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
  `}
`;

export default Transactions;
