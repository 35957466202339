import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import AlertFade from 'components/AlertFade';
import Text from 'components/Text';

const ComplianceAlert = (props) => {
  const userData = useSelector((store) => store.session.userData);
  const tenant = useSelector((store) => store.tenant);
  const [showWarning, setShowWarning] = useState(false);

  const isCompliant = useMemo(() => {
    if (
      userData?.property?.paymentSettings?.complianceLevel === 1 &&
      tenant.paymentMethods.length > 0 &&
      tenant.scheduledPayments.items.length > 0
    ) {
      return true;
    } else if (
      userData?.property?.paymentSettings?.complianceLevel === 2 &&
      tenant.paymentMethods.length > 1 &&
      tenant.scheduledPayments.items.length > 0
    ) {
      return true;
    }
    return false;
  }, [tenant.paymentMethods, tenant.scheduledPayments.items, userData.property.paymentSettings.complianceLevel]);

  const rcpEnabled = useMemo(
    () => userData?.servicePlan?.metaFeatures.rENTCOLLECTIONPOLICYManagement,
    [userData.servicePlan]
  );
  const complianceLevel = useMemo(() => userData?.property?.paymentSettings?.complianceLevel, [userData.property]);

  useEffect(() => {
    // console.log('ƒ useEffect on compliance alert', tenant, userData);

    if (rcpEnabled) {
      if (complianceLevel === 1 || complianceLevel === 2) setShowWarning(!isCompliant);
    } else {
      setShowWarning(false);
    }
  }, [showWarning, isCompliant, userData, rcpEnabled, complianceLevel]);

  const handleScheduledClick = () => {
    if (props.setDrawerVisible) {
      props.setDrawerVisible(true);
    }
    if (props.setShowScheduledPayment) {
      props.setShowScheduledPayment(true);
    }
    if (props.setShowPayment) {
      props.setShowPayment(false);
    }
    if (props.setFromCompliance !== undefined) {
      props.setFromCompliance(true);
    }
  };

  const handlePaymentClick = () => {
    if (props.togglePaymentMethods) {
      props.togglePaymentMethods(true);
    }
  };

  return (
    <AlertFade
      message="Rent Collection Policy Warning"
      description={
        <>
          Your Landlord has a rent collection policy which requires: <br />
          <StyledUl>
            {complianceLevel === 2 ? (
              <li>
                <Text color="violet" size={14} underline pointer onClick={handlePaymentClick}>
                  1 Primary and 1 Backup Payment Method
                </Text>
                <br />
                <Text color="black" size={14}>
                  Your backup payment method will be used automatically if your primary payment method fails.
                </Text>
              </li>
            ) : complianceLevel === 1 ? (
              <li>
                <Text color="violet" size={14} underline pointer onClick={handlePaymentClick}>
                  1 Primary Payment Method
                </Text>
              </li>
            ) : null}
            {complianceLevel > 0 && (
              <li>
                <Text color="violet" size={14} underline pointer onClick={handleScheduledClick}>
                  One Recurring Payment
                </Text>
                <br />
                <Text color="black" size={14}>
                  Recurring payment must be scheduled on or before rent is due for full rent amount.
                </Text>
              </li>
            )}
          </StyledUl>
          Please update your account to comply.
        </>
      }
      display
      isVisible={showWarning}
      type="warning"
    />
  );
};

const StyledUl = styled.ul`
  padding-inline-start: 20px;
`;

export default ComplianceAlert;
