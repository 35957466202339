import React from 'react';
import { Avatar, Card, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Text from 'components/Text/Text';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Meta } = Card;

function BillingAccountDetails(props) {
  const { record, baDetails, complianceStatus } = props;
  const session = useSelector((state) => state.session);
  const history = useHistory;
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <div>
              <Meta
                avatar={
                  baDetails?.photo ? (
                    <img alt="" src={baDetails?.photo} width={96} style={{ borderRadius: '4px' }} />
                  ) : (
                    <Avatar shape="square" size={64} className="metaStyles">
                      {record?.details?.billingAccount.user.firstName.charAt(0)}
                      {record?.details?.billingAccount.user.lastName.charAt(0)}
                    </Avatar>
                  )
                }
                title={
                  <>
                    {record?.details?.billingAccount.state === 'active' && (
                      <>
                        <Tag color="purple" icon={<CheckCircleOutlined />}>
                          Enrolled
                        </Tag>
                        <br />
                      </>
                    )}{' '}
                    {`${record?.details?.billingAccount.user.firstName} ${record?.details?.billingAccount.user.lastName}`}
                  </>
                }
                description={
                  <>
                    Email:{' '}
                    <Text color="violet" underline pointer size={14}>
                      <a href={`mailto:${record?.details?.billingAccount.user.email}`}>
                        {record?.details?.billingAccount.user.email}
                      </a>
                    </Text>
                    <br /> Mobile:{' '}
                    <Text color="violet" underline pointer size={14}>
                      {record?.details?.billingAccount.user.cellPhone}
                    </Text>
                  </>
                }
              />
            </div>
          </td>
          <td>
            <div>
              <Meta
                description={
                  <>
                    <strong>Last Payment:</strong>{' '}
                    {baDetails
                      ? baDetails?.latestPaymentDate && dayjs(baDetails.latestPaymentDate).format('ll')
                      : ''}
                    <br /> <strong>Payments Scheduled:</strong> {baDetails && baDetails.paymentScheduled}
                    <br /> <strong>Payment Methods:</strong> {baDetails && baDetails.paymentMethods}
                    <br /> <strong>Rent Collection Policy Status:</strong>{' '}
                    {!session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement && (
                      <Text
                        color="violet"
                        pointer
                        underline
                        size={12}
                        onClick={() => {
                          history.push('/landlord/service-plans');
                        }}
                      >
                        UPGRADE
                      </Text>
                    )}
                    {session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement && (
                      <Text color="black" size={12}>
                        {complianceStatus}
                      </Text>
                    )}
                  </>
                }
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default BillingAccountDetails;
