/* eslint-disable import/prefer-default-export */
import * as actions from 'store/actions';
import axios from 'axios';
import Jsona from 'jsona';

import { getServicePlanData, getSessionDataV3 } from 'services/api/common/read';
const dataFormatter = new Jsona();

// deprecated - use getUserDataV3
export const getUserData = () => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/session?page[size]=1&include=paymentSettings,notificationSettings,property,billingAccounts`;
    const res = await axios.get(url);

    const formattedRes = dataFormatter.deserialize(res.data);

    dispatch({
      type: actions.GET_USER_SESSION,
      payload: formattedRes,
    });
    return formattedRes;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getUserDataV3 = (cognitoToken, controller) => async (dispatch) => {
  try {
    const formattedRes = await getSessionDataV3(cognitoToken, controller);

    dispatch({
      type: actions.GET_USER_SESSION,
      payload: formattedRes,
    });
    return formattedRes;
  } catch (error) {
    console.error(error);
  }
};

export const getServicePlanAction = (cognitoToken) => async (dispatch) => {
  try {
    const result = await getServicePlanData(cognitoToken);
    dispatch({
      type: actions.GET_SERVICE_PLAN_DATA,
      payload: result,
    });
  } catch (error) {
    console.log(error);
  }
};
