import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Text from 'components/Text';
import AlertFade from 'components/AlertFade';

const MicrodepositAlert = () => {
  const tenant = useSelector((store) => store.tenant);
  const history = useHistory();
  const [alert, setAlert] = useState(true);

  useEffect(() => {}, []);

  const checkPm = (t) => {
    if (t.paymentMethods.length) {
      const verifyPm = t.paymentMethods.filter((pm) => {
        if (pm.paymentGatewayMeta?.state) {
          if (pm.paymentGatewayMeta?.state === 'pending verification' && pm.state === 'SCHEDULED') {
            return true;
          }
          return false;
        }
      });
      if (verifyPm.length) return true;
      return false;
    }
    return false;
  };

  return (
    <AlertFade
      message="Payment Method Requires Verification"
      description={
        <>
          You have a bank account that still requires verification. Please{' '}
          <Text
            color="violet"
            underline
            size={14}
            pointer
            onClick={() => {
              history.push('/tenant/wallet?panel=insurance');
            }}
          >
            go to your wallet
          </Text>
          , click verify and enter micro deposit amounts.
        </>
      }
      display
      isVisible={checkPm(tenant)}
      type="warning"
    />
  );
};

export default MicrodepositAlert;
