import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Drawer } from 'antd';

import AccountProfilePanel from '../components/AccountProfilePanel';
import AccountPreferencesPanel from '../components/AccountPreferencesPanel';
import PlansBillingsPanel from '../components/PlansBillingsPanel';
import BillingDetailsPanel from '../components/BillingDetailsPanel';

import PayrentCapitalLogo from 'assets/images/payrent-logo-capital.png';
import PayrentSmall from 'assets/images/financing/logo-payrent-small.png';
import SecurityBadges from 'assets/images/financing/img-security-badges.png';
import LogoCompany1 from 'assets/images/financing/logo-company1.png';
import LogoCompany2 from 'assets/images/financing/logo-company2.png';
import LogoCompany3 from 'assets/images/financing/logo-company3.png';
import LogoCompany4 from 'assets/images/financing/logo-company4.png';
import LogoCompany5 from 'assets/images/financing/logo-company5.png';
import { ReactComponent as LogoSource1 } from 'assets/images/financing/logo-source1.svg';
import { ReactComponent as LogoSource2 } from 'assets/images/financing/logo-source2.svg';
import { ReactComponent as LogoSource4 } from 'assets/images/financing/logo-source4.svg';
import { ReactComponent as LogoSource5 } from 'assets/images/financing/logo-source5.svg';
import { ReactComponent as LogoSource6 } from 'assets/images/financing/logo-source6.svg';
import { ReactComponent as LogoSource7 } from 'assets/images/financing/logo-source7.svg';
import LogoSource8 from 'assets/images/financing/logo-source8.png';
import LogoSource9 from 'assets/images/financing/logo-source9.png';

// How it works
import { ReactComponent as IconApply } from 'assets/images/financing/icon-apply.svg';
import { ReactComponent as IconEvaluate } from 'assets/images/financing/icon-evaluate.svg';
import { ReactComponent as IconFund } from 'assets/images/financing/icon-fund.svg';
import { ReactComponent as IconGrow } from 'assets/images/financing/icon-grow.svg';

import OnboardingFullscreen from '../components/OnboardingFullScreen';

import './styles.scss';

const Financing = (props) => {
  const {
    setShowOnboarding,
    setDrawerVisible,
    drawerVisible,
    showOnboardingFullscreen,
    setShowOnboardingFullscreen,
    setShowAccountProfile,
    showAccountProfile,
    showAccountPreferences,
    setShowAccountPreferences,
    setShowPlansBillingPanel,
    showPlansBillingPanel,
    setShowBillingDetailsPanel,
    showBillingDetailsPanel,
  } = props;

  const stripeTimer = useRef(false);

  useEffect(() => {
    const handler = window.lendflow.create({
      env: process.env.REACT_APP_LENDFLOW_ENV,
      product: 'leadfunding',
      viewProduct: '1',
    });
    //
    handler.initalisebutton('.start-funding');
    handler.initalisebutton('.start-funding-2');
  }, []);
  return (
    <>
      {showOnboardingFullscreen && (
        <OnboardingFullscreen
          showOnboardingFullscreen={showOnboardingFullscreen}
          setShowOnboardingFullscreen={setShowOnboardingFullscreen}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
          stripeTimer={stripeTimer}
        />
      )}
      <StyledDrawer
        closable={false}
        // closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
        placement="right"
        destroyOnClose
        onClose={() => {
          setDrawerVisible(false);
          setShowOnboarding(false);
          setShowAccountProfile(false);
          setShowAccountPreferences(false);
          setShowPlansBillingPanel(false);
          setShowBillingDetailsPanel(false);
        }}
        open={props.drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {drawerVisible && showAccountProfile && (
          <AccountProfilePanel setShowAccountProfile={setShowAccountProfile} setDrawerVisible={setDrawerVisible} />
        )}
        {drawerVisible && showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={setDrawerVisible}
            setShowAccountPreferences={setShowAccountPreferences}
          />
        )}
        {drawerVisible && showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={setDrawerVisible}
            setShowPlansBillingPanel={setShowPlansBillingPanel}
            setShowBillingDetailsPanel={setShowBillingDetailsPanel}
            showBillingDetailsPanel={showBillingDetailsPanel}
          />
        )}
        {drawerVisible && showBillingDetailsPanel && (
          <BillingDetailsPanel
            setDrawerVisible={setDrawerVisible}
            setShowBillingDetailsPanel={setShowBillingDetailsPanel}
            setShowPlansBillingPanel={setShowPlansBillingPanel}
            showBillingDetailsPanel={showBillingDetailsPanel}
          />
        )}
      </StyledDrawer>

      <div id="page-homepage">
        <section id="banner" className="banner-medium">
          <div className="text">
            <a className="logo">
              <img src={PayrentCapitalLogo} alt="Logo" />
            </a>
            <h1 className="main-heading">Multiple financing solutions to help you grow your business</h1>
            <p className="banner-text">
              With one simple and secure application you can explore funding options from 75+ different lenders and
              use the money to grow your business!
            </p>
            <p className="banner-text banner-text-bottom">
              Your business is eligible if you have been in business for at least 6 months and have at least $10k
              in monthly rental income.
            </p>
            <button className="start-funding btn-options blue">Explore My Options</button>
            <p className="note">*there is no obligation and applying will NOT impact your credit!</p>
          </div>
          <div className="offer-container">
            <div className="offer-header blue">
              <div className="logo">
                <img src={PayrentSmall} alt="Logo PayRent" />
              </div>
              <p style={{ margin: 0 }}>You have two available offers!</p>
            </div>

            <div className="offer-content">
              <div className="offer-info-box">
                <p style={{ margin: 0 }}>
                  <span>Term Loan</span>
                  <span>$225,000</span>
                </p>
              </div>
              <div className="offer-info-box">
                <p style={{ margin: 0 }}>
                  <span>Line of Credit</span>
                  <span>$850,000</span>
                </p>
              </div>

              <div className="privacy-text">
                <p>
                  We take your privacy very seriously. We use bank level security to protect your personal
                  information. Applying will not impact your credit score. We only use a soft pull to check your
                  credit.
                </p>

                <img className="security-badges" src={SecurityBadges} alt="Security Badges" />
              </div>
            </div>
          </div>
        </section>
        <div id="fund-options" className="blue">
          <div className="text">
            <h2 className="heading" style={{ color: 'white' }}>
              What you can use the funds for
            </h2>
            <p className="sub-heading">
              The funds can be used for just about anything you need to help support your growth!
            </p>
          </div>

          <ul className="fund-options-list">
            <li>Help with cash flow issues</li>
            <li>Purchase a rental</li>
            <li>Fix and flip a property</li>
            <li>Finance a capital expense</li>
            <li>Refinance your portfolio</li>
            <li>And much more!</li>
          </ul>
        </div>
        <div id="flowchart">
          <h6 className="heading">
            <span className="yellow"></span> PayRent works with 75+ lenders to generate capital offers that match
            your specific business needs
          </h6>

          <div className="boxes-container">
            <div className="box box-left">
              <div className="list-container metrics-list">
                <h6 className="list-heading blue">evaluation metrics</h6>
                <ul>
                  <li>Industry</li>
                  <li>Risk Tier</li>
                  <li>Revenue Metrics</li>
                  <li>Use of Funds</li>
                  <li>PayRent Platform Data</li>
                </ul>
              </div>
              <div className="list-container sources-list">
                <h6 className="list-heading blue">Data sources</h6>
                <ul>
                  <li className="span-half">
                    <LogoSource1 />
                  </li>
                  <li className="span-half">
                    <LogoSource2 />
                  </li>
                  <li className="span-quarter">
                    <LogoSource4 />
                  </li>
                  <li className="span-quarter">
                    <LogoSource5 />
                  </li>
                  <li className="span-quarter">
                    <LogoSource6 />
                  </li>
                  <li className="span-quarter">
                    <LogoSource7 />
                  </li>
                  <li className="span-half">
                    <img src={LogoSource8} alt="Data Source Logo" />
                  </li>
                  <li className="span-half">
                    <img src={LogoSource9} alt="Data Source Logo" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="box box-middle">
              <ul>
                <li>
                  <img src={LogoCompany1} alt="Company Logo" />
                </li>
                <li>
                  <img src={LogoCompany2} alt="Company Logo" />
                </li>
                <li>
                  <img src={LogoCompany3} alt="Company Logo" />
                </li>
                <li>
                  <img src={LogoCompany4} alt="Company Logo" />
                </li>
                <li>
                  <img src={LogoCompany5} alt="Company Logo" />
                </li>
              </ul>
            </div>
            <div className="box box-right">
              <div className="list-container products-list">
                <h6 className="list-heading green">CAPITAL Products</h6>
                <ul>
                  <li>Recievables Purchase</li>
                  <li>Line of Credit</li>
                  <li>Term Loan</li>
                  <li>Invoice Factoring</li>
                  <li>Instant Payout</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="how-it-works">
          <h3 className="section-heading">How it works</h3>

          <div className="how-boxes-container">
            <div className="how-box">
              <IconApply />
              <h4 className="heading">Apply</h4>
              <p className="text">
                Fill out one simple application. There's no fee or obligation, and applying will not impact your
                credit.&nbsp;
              </p>
            </div>
            <div className="how-box">
              <IconEvaluate />
              <h4 className="heading">Evaluate</h4>
              <p className="text">
                Review your options and offers with your dedicated funding advisor and pick the solution that works
                best for you.
              </p>
            </div>
            <div className="how-box">
              <IconFund />
              <h4 className="heading">Fund</h4>
              <p className="text">
                Get the capital you need in as little as 24 hours - wired directly into your business bank account.
              </p>
            </div>
            <div className="how-box">
              <IconGrow />
              <h4 className="heading">Grow</h4>
              <p className="text">
                Put the money to work and get back to what you do best - growing your business!
              </p>
            </div>
          </div>
        </div>
        <div id="how-it-helps">
          <h3 className="section-heading">How we help you</h3>

          <div className="how-boxes-container">
            <div className="how-box">
              <span className="number">1</span>
              <p className="text">
                Once you’ve completed the application, we’ll review your file and match you with multiple lenders
                and show you offers so that you can pick the terms that work best for you.
              </p>
            </div>
            <div className="how-box">
              <span className="number">2</span>
              <p className="text">
                You’ll get 1-on-1 guidance from an experienced funding advisor that will help you understand your
                options and even negotiate with lenders on your behalf.{' '}
              </p>
            </div>
            <div className="how-box">
              <span className="number">3</span>
              <p className="text">
                We handle all the leg work to ensure you’re getting the best deal and can often get the money into
                your account the same day it closes.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="cta">
          <div className="text">
            <h3 className="heading" style={{ color: 'white' }}>
              Get funding for your business
            </h3>
            <p className="sub-heading">
              Quick and easy access to flexible financing, so you can better manage your cash flow and invest in
              growth
            </p>
            <button className="start-funding-2 btn-cta">Explore My Options</button>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 1500px) {
        width: 460px !important;
      }
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    input {
      color: #595959;
    }
    @media screen and (max-height: 750px) {
      .ant-steps-small .ant-steps-item-description {
        font-size: 12px !important;
      }
    }
    @media screen and (max-height: 750px) {
      .ant-drawer-body h1 {
        font-size: 35px !important;
      }
      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }
  `}
`;

export default Financing;
