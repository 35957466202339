// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { wepayOnboarding } from 'services/wepay/onboarding';
import { createPaymentGateway } from 'services/api/landlordApi/create';
import { getSessionDetails, getPropertyGroupOnboardingStatus } from 'services/api/landlordApi/read';

// Components

import Button from 'components/Button';
import Space from 'components/Space';
import Text from 'components/Text';
import AlertFade from 'components/AlertFade/AlertFade';
import { isIOS } from 'react-device-detect';

// TODO set alert for possible errors
// Logic to enable buttons

// - new2-step1 - new2-step2

const WepayPaymentAccountForm = (props) => {
  const { checkLandlordStatus, propertyGroup } = props;
  const dispatch = useDispatch();

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [disableButton1, setDisableButton1] = useState(false);
  const [disableButton2, setDisableButton2] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  useEffect(() => {
    checkStep();
  }, [propertyGroup]);

  const checkStep = async () => {
    setLoading1(true);
    setLoading2(true);
    console.log('Check step');
    const result = await getPropertyGroupOnboardingStatus(
      session.cognitoTokens.idToken.jwtToken,
      propertyGroup[0].id
    );
    switch (result.wepayRegistrationState) {
      case 'pending-step':
        displayAlert('Pending step', 'warning');
        setDisableButton1(false);
        setDisableButton2(true);
        setLoading1(false);
        setLoading2(false);
        break;
      case 'new2-step1':
        setDisableButton1(false);
        setDisableButton2(true);
        setLoading1(false);
        setLoading2(false);
        break;
      case 'new2-step2':
        setDisableButton1(true);
        setDisableButton2(false);
        setLoading1(false);
        setLoading2(false);
        break;
      case 'action-required':
        displayAlert('Action required: verify your identity', 'warning');
        setDisableButton1(true);
        setDisableButton2(false);
        setLoading1(false);
        setLoading2(false);
        break;
      case 'error':
        displayAlert('There is an error in your account', 'warning');
        setDisableButton1(false);
        setDisableButton2(true);
        setLoading1(false);
        setLoading2(false);
        break;
      case 'active':
        displayAlert('Wepay account configured', 'success');
        setDisableButton1(true);
        setDisableButton2(true);
        setLoading1(false);
        setLoading2(false);
        break;
      default:
        setDisableButton1(true);
        setDisableButton2(true);
        setLoading1(false);
        setLoading2(false);
    }
  };

  const displayAlert = (message, type) => {
    setAlert({
      isVisible: true,
      message,
      type,
    });

    setTimeout(() => {
      setAlert({
        isVisible: false,
      });
    }, 2000);
  };

  const configPaymentAccount = () => {
    props.setDrawerVisible(false);
  };

  const handleWepay1 = async () => {
    try {
      setLoading1(true);
      const session = await getSessionDetails();
      const fullName = `${session.firstName} ${session.lastName}`;
      const result = await wepayOnboarding(fullName, session.email);
      result.propertyGroupId = +propertyGroup[0].id;
      await createPaymentGateway(result);

      checkLandlordStatus(setLoading1, setDisableButton1, setDisableButton2, displayAlert);
    } catch (error) {
      // show error alert
      setLoading1(false);
      // console.log(error);
      displayAlert('There was an error with your request', 'warning');
    }
  };

  const handleWepay2 = async () => {
    setLoading2(true);
    dispatch({
      type: 'SET_LOADING_OVERLAY',
      payload: true,
    });
    try {
      const result = await getSessionDetails();
      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, +propertyGroup[0].id);

      if (groupStatus?.updateUri) {
        const url = groupStatus?.updateUri;
        setTimeout(() => {
          window.location.href = url;
          // window.open(url, '_blank'); //to open new page

          dispatch({
            type: 'SET_LOADING_OVERLAY',
            payload: false,
          });
        }, 300);
      }
    } catch (error) {
      // console.log(error);
      setLoading2(false);
      dispatch({
        type: 'SET_LOADING_OVERLAY',
        payload: false,
      });
    }
  };

  return (
    <>
      <Container className="content-container">
        {/**<Steps onboardingData={onboardingData} stepTitle={stepTitle} stepIcon={stepIcon} stepStatus={stepStatus} /> */}

        {isIOS && (
          <AlertFade
            isVisible
            type="error"
            display
            marginTop={10}
            marginBottom={10}
            message={
              <span>
                You must disable Pop-up Blocking in your browser to complete the steps below.{' '}
                <a href="https://www.howtogeek.com/770127/how-to-disable-pop-up-blocker-in-safari-on-iphone-and-ipad/">
                  Learn How
                </a>
              </span>
            }
          />
        )}

        <Text size={16} color="black" centered margin="0 auto">
          To accept payments you must first register with Chase WePay, our payment processor. Click the button
          below to open a new, secure registration window.
        </Text>
        <Space vertical={10} />
        <Button
          color="yellow"
          alignment="center"
          fontSize={14}
          onClick={handleWepay1}
          disabled={disableButton1}
          loading={loading1}
          data-testid="step1Test"
          bottom={0}
        >
          STEP 1: CREATE YOUR MERCHANT ACCOUNT
        </Button>
        <Space vertical={10} />
        <Text size={16} color="black" centered margin="0 auto">
          Once your account is created, click on STEP 2 to verify your identity and add business details. You
          cannot accept rent payments until this step is completed.
        </Text>
        <Space vertical={10} />
        <Button
          color="yellow"
          alignment="center"
          fontSize={14}
          onClick={handleWepay2}
          disabled={disableButton2}
          loading={loading2}
          data-testid="step2Test"
        >
          STEP 2: VERIFY YOUR IDENTITY AND BUSINESS
        </Button>
      </Container>
      {/**
       <PanelFooter>
        <AlertFade
          isVisible={alert.isVisible}
          message={alert.message}
          type={alert.type}
          alertPosition="absolute"
          position="absolute"
          width={80}
          minHeight="80"
        />
        <Row justify="center" style={{ marginBottom: '0px', width: '100%' }}>
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <StyledText
              inline
              start
              onClick={() => props.setCurrentStep(3)}
              size={12}
              underline
              pointer
              margin="0px 0px 0px 13px"
            >
              Previous
            </StyledText>
          </Col>
          <Col span={10}>
            <Button
              htmlType="button"
              onClick={configPaymentAccount}
              type="primary"
              color="violet"
              alignment="center"
              // left={15}
              data-testid="doneTest"
              bottom={0}
            >
              DONE
            </Button>
          </Col>
          <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <StyledText end onClick={() => props.setDrawerVisible(false)} size={12} underline pointer>
              Skip this for now
            </StyledText>
          </Col>
        </Row>
      </PanelFooter>
       
       */}
    </>
  );
};

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const Container = styled.div`
  justify-content: center;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  padding: 0px 30px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media screen and (max-width: 480px) {
    padding: 0px 30px;
  }
`;

const StyledText = styled(Text)`
  padding-top: 22px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default WepayPaymentAccountForm;
