import React from 'react';
import Lottie from 'lottie-react';
import data from './superstarData.json';
import PropTypes from 'prop-types';

export default function Superstar(props) {
  return <Lottie animationData={data} {...props} />;
}

Lottie.propTypes = {
  loop: PropTypes.bool,
  style: PropTypes.object,
};
Lottie.defaultProps = {
  loop: true,
  style: {
    height: '120px',
    width: '120px',
    display: 'block',
    margin: '0 auto',
  },
};
