import React from 'react';
import { Space } from 'antd';
import { useHistory } from 'react-router';
import { Benefits, Headline, Subheading } from '../SalesCard.styles';

export default function SalesCopy() {
  const { location } = useHistory();

  if (location.pathname.indexOf('landlord') !== -1) {
    return (
      <Space direction="vertical" size={26} style={{ marginBottom: '6px' }}>
        <Headline id="landlord-headline">Ready to Simplify Rent Collection?</Headline>
        <Subheading id="landlord-subheadline">
          Enjoy faster, easier rent billing and collection that increases
          <br />
          <strong>on-time payments</strong>
        </Subheading>
        <Benefits id="landlord-benefits" data-testid="landlord-benefits-test">
          <ul>
            <li>Automatic monthly billing and rent reminders to simplify your life</li>
            <li>Easy to use software with no monthly fees lets you start now</li>
            <li>Deposit directly to your bank while maintaining your privacy</li>
            <li>
              Reward <strong>on-time payments</strong> with free credit boosting and other RentCred&trade; rewards
            </li>
          </ul>
        </Benefits>
      </Space>
    );
  }

  return (
    <Space direction="vertical" size={44} style={{ marginBottom: '28px' }}>
      <Headline id="tenant-headline" data-testid="rewards-text">
        Ready for Exclusive Rent Rewards?
      </Headline>
      <Subheading id="tenant-subheadline">
        Boost your credit score, get emergency cash and improve your <strong>on-time payment</strong> history
      </Subheading>
      <Benefits id="tenant-benefits">
        <ul>
          <li>Earn on-time payment rewards that improve your financial health</li>
          <li>Pay conveniently with a bank account, credit or debit card 24/7</li>
          <li>Schedule recurring payments in advance so you can focus on more important things</li>
        </ul>
      </Benefits>
    </Space>
  );
}
