import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { CheckCircleFilled } from '@ant-design/icons';
import { ReactComponent as StripeCc } from 'assets/images/onboarding-fullscreen/stripe-cc.svg';
import Text from 'components/Text';
import Space from 'components/Space';

const StripeDetails = () => {
  return (
    <ContentContainer className="content-container">
      <Row align="middle" justify="center">
        <StripeCc />
      </Row>
      <Space vertical={25} />
      <Text size={21} color="black" strong centered>
        PayRent partners with Stripe for credit card payments
      </Text>
      <Space vertical={30} />
      <Row gutter={10}>
        <Col span={3}>
          <CheckCircleFilled style={{ fontSize: '24px', color: '#635BFF' }} />
        </Col>
        <Col span={12}>
          <Text color="black" size={18} strong>
            Trusted
          </Text>
        </Col>
      </Row>
      <Row gutter={[10, 15]}>
        <Col span={3}></Col>
        <Col span={18}>
          <Text color="black" size={14}>
            Used by millions of companies of all sizes—from startups to Fortune 500s.
          </Text>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={3}>
          <CheckCircleFilled style={{ fontSize: '24px', color: '#635BFF' }} />
        </Col>
        <Col span={12}>
          <Text color="black" size={18} strong>
            Secure
          </Text>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={3}></Col>
        <Col span={18}>
          <Text color="black" size={14}>
            Your business and personal information is kept private and safe.
          </Text>
        </Col>
      </Row>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 80%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default StripeDetails;
