import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Button as AntButton, Spin } from 'antd';

import { displayAlertAction } from 'store/actions/globalActions';

const DeleteMenu = (props) => {
  const { record, deleteText, handleClickDelete, handleRefund, setDropdownVisible, resetDropdown } = props;

  const session = useSelector((store) => store.session);
  const transactions = useSelector((store) => store.landlord.transactions);
  const [loading, setLoading] = useState(false);
  const [refundButton, setRefundButton] = useState({
    text: 'Refund',
    color: 'inherit',
    action: false,
  });
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log(record);
  // }, []);

  const enableSilaRefund = (r) => {
    if (r.refType === 'Payment') {
      if (r.billingItem.payment) {
        if (r.status === 'paid' && r.billingItem.payment.status !== 'REFUNDED') {
          return false;
        }
      }
    }
    return true;
  };

  const handleSilaRefund = async () => {
    try {
      if (!refundButton.action) {
        setRefundButton({
          text: 'Really Refund?',
          color: '#CB48B7',
          action: true,
        });
        return;
      }
      setLoading(true);
      await handleRefund(record.billingItem.payment.id, +record.renterData[0].id);

      setLoading(false);
      dispatch(displayAlertAction('', 'success', 'Transaction refund initiated', true, 5000));
    } catch (error) {
      setLoading(false);
      console.log(error);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].detail, true, 5000));
      setDropdownVisible(resetDropdown(transactions.items));
    }
  };
  const items = [
    {
      key: record.transactionId,
      label: deleteText.text,
      style: { color: deleteText.color },
      disabled: record.refType === 'Payment' || record.status === 'refund',
      onClick: (e) => handleClickDelete(e, record.transactionId),
    },
  ];
  return items;
};

export default DeleteMenu;
