import React from 'react';
import Space from 'components/Space';

import { getQuadpayFee } from 'services/api/tenantApi';
import { getServicePlans } from 'services/api/landlordApi/read';

export const getSubtitleSpace = (height) => {
  switch (height) {
    case height === 720:
      return <Space vertical={20} />;
    case height >= 800:
      return <Space vertical={40} />;
    case height >= 900:
      return <Space vertical={60} />;
    default:
      return <Space vertical={100} />;
  }
};

export const displayAlert = (setAlert, message, type, time = 3000) => {
  setAlert({
    isVisible: true,
    message,
    type,
  });
  setTimeout(() => {
    setAlert({
      isVisible: false,
      message: '',
      type,
    });
  }, time);
};

export const globalDisplayAlert = (dispatch, message, type, description) => {
  dispatch({
    type: 'SET_ALERT',
    payload: {
      isVisible: true,
      message,
      type,
      description,
      closable: true,
    },
  });

  setTimeout(() => {
    dispatch({
      type: 'UNSET_ALERT',
    });
  }, 5000);
};

export const calculateLandlordFee = (refType, fee, landlordAbsorbPercent) => {
  let feeAmount = 0;
  let landlordFee = 0;
  if (refType === 'Payment') {
    feeAmount = Number(fee);
    landlordFee = (feeAmount / 100) * landlordAbsorbPercent;

    return landlordFee.toFixed(2);
  }
};

export const calculateRenterFee = (fee, landlordAbsorbPercent) => {
  let feeAmount = 0;
  let customerFee = 0;

  feeAmount = Number(fee);

  customerFee = feeAmount - (feeAmount / 100) * landlordAbsorbPercent;
  return customerFee.toFixed(2);
};

export const getServicePlanName = async (sp) => {
  // switch (sp) {
  //   case '6':
  //   case '7':
  //   case '8':
  //   case '1':
  //     return 'Pay As You Go';
  //   case '2':
  //     return 'Do It Yourself';
  //   case '3':
  //     return 'Go Like a Pro';
  //   case '4':
  //     return 'High Riser';
  //   case '5':
  //     return 'Forever Free';
  //   default:
  //     return 'No service plan';
  // }

  const servicePlan = await getServicePlans().then((plans) => {
    return plans.find((p) => p.id === sp);
  });

  return servicePlan?.name || 'Unknown';
};

export const itemType = (chargeType) => {
  switch (chargeType) {
    case 'SECURITY_DEPOSIT':
      return 'Security Deposit';
    case 'DEPOSIT':
      return 'Deposit';
    case 'RENT':
      return 'Rent charge';
    case 'CHARGEBACK':
      return 'Chargeback';
    case 'UTILITY':
      return 'Utility';
    case 'PAYMENT':
      return 'Payment';
    case 'REFUND':
      return 'Refund';
    case 'CREDIT':
      return 'Credit';
    case 'MISC_FEE':
      return 'Fee';
    case 'PARKING':
      return 'Parking';
    case 'MOVE_IN':
      return 'Move In';
    case 'MOVE_OUT':
      return 'Move Out';
    case 'DISCOUNT':
      return 'Discount';
    case 'LATE_FEE':
      return 'Late fee';
    default:
      return;
  }
};

export const itemTypeColor = (chargeType) => {
  switch (chargeType) {
    case 'SECURITY_DEPOSIT':
      return 'color-red weight-500';
    case 'DEPOSIT':
      return 'color-red weight-500';
    case 'RENT':
      return 'color-red weight-500';
    case 'CHARGEBACK':
      return 'color-green weight-500';
    case 'UTILITY':
      return 'color-red weight-500';
    case 'PAYMENT':
      return 'color-green weight-500';
    case 'REFUND':
      return 'color-green weight-500';
    case 'CREDIT':
      return 'color-green weight-500';
    case 'MISC_FEE':
      return 'color-red weight-500';
    case 'PARKING':
      return 'color-red weight-500';
    case 'MOVE_IN':
      return 'color-red weight-500';
    case 'MOVE_OUT':
      return 'color-red weight-500';
    case 'DISCOUNT':
      return 'color-green weight-500';
    case 'LATE_FEE':
      return 'color-red weight-500';
  }
};

export const calculateFee = async (
  amount,
  propertySettings,
  selectedPm,
  servicePlan,
  paymentMethod,
  firstPayment,
  absorbFees,
  absorbFeesPercent,
  achAlert,
  quadpay,
  cognitoToken,
  state
) => {
  const cap = Number(servicePlan?.cap);
  const ccRateFee = +(Number(servicePlan?.creditCardRateFee) * 100).toFixed(2);
  const ccTrxFee = Number(servicePlan?.creditCardTrxFee);
  const dbRateFee = +(Number(servicePlan?.debitRateFee) * 100).toFixed(2);
  const dbTrxFee = Number(servicePlan?.debitTrxFee);
  const achRateFee = +(Number(servicePlan?.achRateFee) * 100).toFixed(2);
  const achTrxFee = +Number(servicePlan?.achTrxFee).toFixed(2);
  const pm = selectedPm !== undefined ? selectedPm : 0;

  if (propertySettings.paymentSettings && pm > -1 && !quadpay) {
    // const { paymentSettings } = propertySettings;
    // const { absorbFees } = paymentSettings;
    if (paymentMethod[pm]?.paymentMethodType === 'ACH' || achAlert === 'ACH') {
      // if (firstPayment) {
      //   return 0;
      // }
      /** Variable rate
         const calc = (amount / 100) * achRateFee;
      let calculatedfee = calc > cap ? cap : calc;
        
       */

      let calculatedfee = achTrxFee + achRateFee;
      if (absorbFees) {
        const absorb = (calculatedfee / 100) * +absorbFeesPercent;
        calculatedfee = calculatedfee - absorb;
      }
      return calculatedfee;
    } else if (
      paymentMethod[pm].paymentMethodType === 'Card' ||
      paymentMethod[pm].paymentMethodType === 'CREDIT'
    ) {
      const calc = (amount / 100) * ccRateFee + ccTrxFee;
      return calc;
    } else if (paymentMethod[pm].paymentMethodType === 'DEBIT') {
      const calc = (amount / 100) * dbRateFee + dbTrxFee;
      return calc;
    }
  }
  if (quadpay) {
    const cappedAmount = +amount >= 500 ? 500 : +amount;
    const result = await getQuadpayFee(cognitoToken, state, cappedAmount);
    return result.merchantFeeForPaymentPlan;
  }
  return 0;
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const truncateString = (str, num, ellipsis) => {
  if (str) {
    if (str.length > num) {
      if (ellipsis) {
        return str.slice(0, num) + '...';
      }
      return str.slice(0, num);
    }
    return str;
  }
};

// Speed up calls to hasOwnProperty
const hasOwnProperty = Object.prototype.hasOwnProperty;

export const isEmpty = (obj) => {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

export const numberTOrdinalSuffix = (num) => {
  const suffix = ['st', 'nd', 'rd'][((((num + 90) % 100) - 10) % 10) - 1] || 'th';
  return `${num}${suffix}`;
};

export const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

export const scrollTo = (ele) => {
  ele.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  });
};

export const pollCheck = (pMethods) => {
  const filtered =
    pMethods.filter(
      (p) =>
        p &&
        p.hasOwnProperty('state') &&
        p.hasOwnProperty('microDepositsFlow') &&
        ((!p.microDepositsFlow && p.state === 'PENDING') || p.state === 'SCHEDULED')
    ).length > 0;
  return filtered;
};

export const getBalanceDueWithPending = (balance, pending) => {
  let totalPending = 0;
  if (pending.length) {
    for (let i = 0; i < pending.length; i++) {
      if (pending[i].payment) {
        const amount = +pending[i].payment.amount;
        totalPending -= amount;
      } else if (pending[i].billingCharge) {
        const amount = +pending[i].billingCharge.amount;
        totalPending += amount;
      }
    }
  }

  const newBalance = Number(balance) + Number(totalPending);
  return newBalance.toFixed(2);
};

export const getTotalPaymentsWithPending = (payments, pending) => {
  let totalPending = 0;
  for (let i = 0; i < pending.length; i++) {
    const amount = +pending[i].payment.amount;
    totalPending += amount;
  }

  const newBalance = payments + totalPending;
  return newBalance.toFixed(2);
};

export const isSafari = () => {
  const safari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  return safari;
};

export const settlementDetailsMap = (tfr, settlementId) => {
  const payments = tfr
    .filter((i) => i.type === 'DEBIT' && i.subtype === 'API')
    .map((p) => {
      // console.log('p', p);
      const fees = tfr
        .filter((f) => f.type === 'FEE' && f.parentTransfer === p.id)
        .reduce((acc, curr) => acc - curr.amount, 0);

      return {
        key: p.id,
        ...p,
        convenienceFee: Number(p.tags.convenienceAmount) || 0,
        fee: fees,
        netAmount: p.amount + fees,
      };
    });

  const reversals = tfr
    .filter((i) => i.type === 'REVERSAL')
    .map((r) => {
      const fees = tfr
        .filter((f) => f.type === 'FEE' && f.parent_transfer === r.id)
        .reduce((acc, curr) => acc - curr.amount, 0);
      return {
        key: r.id,
        ...r,
        amount: -r.amount,
        fee: fees,
        convenienceFee: 0,
        netAmount: -r.amount + fees,
      };
    });

  const additionalFees = tfr
    .filter((i) => i.type === 'FEE' && !i.parentTransfer)
    .map((f) => ({
      key: f.id,
      ...f,
      fee: -f.fee,
      amount: 0,
      convenienceFee: 0,
    }));

  const disputes = tfr
    .filter((i) => i.type === 'DISPUTE' && i.subtype === 'MERCHANT_DEBIT')
    .map((d) => ({
      key: d.id,
      ...d,
      amount: -d.amount,
      fee: 0,
      convenienceFee: 0,
      tags: { ...d.tags, renterFullname: `ref: contact PayRent ` },
    }));

  const disputeFees = tfr
    .filter((i) => i.type === 'FEE' && i.subtype === 'PLATFORM_FEE' && i.feeType === 'DISPUTE_FIXED_FEE')
    .map((r) => ({
      key: r.id,
      ...r,
      fee: -r.amount,
      amount: 0,
      convenienceFee: 0,
      tags: { ...r.tags, renterFullname: `ref: ${r.parentTransfer}` },
    }));

  // console.log(
  //   'ƒ settlementDetailsMap',
  //   payments,
  //   'reversals',
  //   reversals,
  //   'additionalFees',
  //   additionalFees,
  //   'disputes',
  //   disputes,
  //   'disputeFees',
  //   disputeFees
  // );
  const transactions = [...payments, ...reversals, ...additionalFees, ...disputes, ...disputeFees];

  const dataMap = transactions
    .map((t, i) => ({
      key: i,
      id: t.id,
      createdAt: t.createdAt,
      settledAt: t.readyToSettleAt,
      settlementId: settlementId,
      type: t.type,
      amount: (t.amount - t.convenienceFee) / 100,
      fee: (t.fee + t.convenienceFee) / 100,
      netAmount: (t.amount + t.fee) / 100,
      renterFullname: t.tags.renterFullname,
      renterFulladdress: t.tags.renterFulladdress,
    }))
    .sort((a, b) => b.createdAt - a.createdAt);
  // console.log('dataMap', dataMap);
  return dataMap;
};

export const propsAreDeepEqual = (prevProps, nextProps) => {
  let isEqual = true;
  const prevPropsKeys = Object.keys(prevProps);
  const nextPropsKeys = Object.keys(nextProps);

  if (prevPropsKeys.length !== nextPropsKeys.length) {
    // console.log('prop keys length not equal');
    return false;
  }

  // console.log('prevPropsKeys', prevPropsKeys, 'nextPropsKeys', nextPropsKeys);

  for (const key of prevPropsKeys) {
    if (typeof prevProps[key] !== 'object' && prevProps[key] !== nextProps[key]) {
      // console.log(`prop ${key} is not equal`);
      isEqual = false;
      return isEqual;
    } else if (typeof prevProps[key] === 'object') {
      const prev = JSON.stringify(prevProps[key]);
      const next = JSON.stringify(nextProps[key]);
      if (prev !== next) {
        // console.log(`prop ${key} is not equal`);
      }
      isEqual = false;
      return isEqual;
    }
  }
  return isEqual;
};

export const camelToTitleCase = (str) => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
};

export const snakeToTitleCase = (str) => {
  return str
    .replace(/_/g, ' ')
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (s) => s.toUpperCase());
};
