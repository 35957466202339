import React, { useEffect, useCallback } from 'react';
import { LvbleWidget as LvbleComponent } from '@lvble/react';
import { useDispatch, useSelector } from 'react-redux';
import { getLvbleToken } from 'store/actions/tenantActions';
import FloatLoader from 'assets/lotties/FloatLoader/FloatLoader';
import { StyledLoadingContainer, StyledLvbleContainer } from '../Lvble.styles';
import Text from 'components/Text/Text';

const ENVIRONMENT = process.env.REACT_APP_ENV === 'production' ? 'production' : 'sandbox';

export default function LvbleWidget(props) {
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const lvble = useSelector((store) => store.tenant.lvbleAccount);

  const fetchToken = useCallback(({ userData, cognitoTokens }) => {
    dispatch({ type: 'SET_LVBLE_TOKEN_LOADING' });
    dispatch(getLvbleToken(cognitoTokens.idToken.jwtToken, userData.id));
  }, []);

  useEffect(() => {
    if (!lvble.token && !lvble.error) {
      fetchToken(session);
    }
  }, []);

  return (
    <StyledLvbleContainer id="lvble-widget-container" data-testid="lvbleWidgetTest">
      {lvble.loading ? (
        <StyledLoadingContainer data-testid="lvbleLoaderTest">
          <FloatLoader data-testid="floatLoaderTest" />
          <Text size={14}>Checking Your Float Account...</Text>
        </StyledLoadingContainer>
      ) : (
        <LvbleComponent
          id="lvble-widget"
          style={{ flexGrow: 1 }}
          environment={ENVIRONMENT}
          token={lvble.token}
          onEvent={(eventType, data) => {
            switch (eventType) {
              case 'EXPERIENCE_OPENED':
                dispatch({ type: 'SET_LVBLE_EXPERIENCE', payload: eventType });
                break;
              case 'EXPERIENCE_CLOSED':
                dispatch({ type: 'SET_LVBLE_EXPERIENCE', payload: eventType });
                break;
              case 'REPAY':
              case 'ERROR':
                console.log(eventType, data);
                break;
              default:
                console.log('no case for', eventType);
                break;
            }
          }}
        />
      )}
    </StyledLvbleContainer>
  );
}
