// @flow
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import TenantMenu from './components/TenantMenu';
import LandLordMenu from './components/LandLordMenu';

// Style
//import './style.scss';

const DrawerMenu = (props) => {
  const userRole = useSelector((state) => state.session.userRole);
  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
      {userRole === 'tenant' ? (
        <TenantMenu closeDrawer={props.closeDrawer} />
      ) : (
        <LandLordMenu
          closeDrawer={props.closeDrawer}
          setDrawerVisible={props.setDrawerVisible}
          setShowOnboarding={props.setShowOnboarding}
          setShowAccountProfile={props.setShowAccountProfile}
          setShowAccountPreferences={props.setShowAccountPreferences}
          setShowPlansBillingPanel={props.setShowPlansBillingPanel}
          setShowOnboardingOverlay={props.setShowOnboardingOverlay}
          setShowOnboardingFullscreen={props.setShowOnboardingFullscreen}
          isCollapsed={props.isCollapsed}
        />
      )}
    </div>
  );
};

export default DrawerMenu;
