import React from 'react';
import { ReactComponent as RentCredLogo } from 'assets/images/rentcred/rentcred-logo.svg';
import Text from 'components/Text/Text';

const RentCredTitle = (props) => {
  const { subtitle } = props;

  return (
    <>
      <RentCredLogo width={120} height={52} data-testid="logoTest" />
      <Text strong>{subtitle}</Text>
    </>
  );
};

export default RentCredTitle;
