import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UploadButton = (props) => {
  const { config, text, uploading, size, disabled, height } = props;
  return (
    <Upload {...config}>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        loading={uploading}
        size={size}
        height={height}
        disabled={disabled}
        data-testid="buttonTest"
      >
        {text}
      </Button>
    </Upload>
  );
};

// Optional button styles

export default UploadButton;
