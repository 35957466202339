import React, { useEffect, useRef, useContext, useState } from 'react';
import { animate } from 'framer-motion';
import styled from 'styled-components';

import AppContext from '../Context/Context';

const BigNumber = styled.span`
  font-size: 28px;
  font-weight: 600;
`;

const Counter = (props) => {
  const { from, to } = props;
  const nodeRef = useRef();
  const context = useContext(AppContext);
  const milestones = [3, 6, 9];

  useEffect(() => {
    const node = nodeRef.current;
    const controls = animate(from, to, {
      duration: 1,
      ease: 'easeInOut',
      onUpdate(value) {
        node.textContent = Number(value.toFixed(0)).toLocaleString();
      },
      onComplete() {
        if (milestones.includes(to)) {
          context.setComplete();
        }
      },
    });
    return () => controls.stop();
  }, [to]);

  return <BigNumber ref={nodeRef} />;
};

export default function PaymentCounter(props) {
  const { paymentCount } = props;

  return <Counter from={0} to={paymentCount} />;
}
