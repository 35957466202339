import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Input, Row, Select, Spin } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import styled from 'styled-components';

import { Montserrat } from 'resources/vgsFonts';
import { usStates } from 'resources/FormData';

import { vgsValidator } from 'utils';

// styles for VGS Collect fields
const css = {
  border: '1px solid #D9D9D9',
  'background-clip': 'padding-box',
  // transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  boxSizing: 'border-box',
  width: '100%',
  borderRadius: '2px',
  padding: '4px 11px',
  color: 'rgba(0, 0, 0, 0.85)',
  '@font-face': {
    'font-family': 'Montserrat',
    'font-style': 'normal',
    'font-weight': '400',
    src: Montserrat,
  },
  // color: '#1b1d1f',
  fontFamily: '"Montserrat"',
  fontSize: '16px',
  fontVariant: 'tabular-nums',
  fontFeatureSettings: 'tnum, "tnum"',
  '&:focus': {
    border: '1px solid #ffdb29',
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f5f5f5',
    cursor: 'not-allowed',
    opacity: '1',
  },
  '@media screen and (max-width: 400px)': {
    fontSize: '14px',
    border: '1px solid #D9D9D9',
  },
};
// import moment from 'moment';
// ejemplo para los estilos de vgs
// https://jsfiddle.net/VeryGoodFiddle/of1ty57u/

const NuveiCreditCardDetail = (props) => {
  const servicePlanData = useSelector((store) => store.session.userData.servicePlan);

  const { loading, checkZipCode, setLoading, editPaymentMethod } = props;
  const [isLoaded, scriptLoaded] = useState(false);
  const { Option } = Select;
  // const [form] = Form.useForm();
  const [vgsForm, setVgsForm] = useState({});
  const [inputsLoading, setInputsLoading] = useState(true);
  // const monthFormat = 'MM/YY';
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.verygoodvault.com/vgs-collect/2.0/vgs-collect.js';
    script.async = true;
    script.onload = () => scriptLoaded(true);
    document.body.appendChild(script);
  });

  useEffect(() => {
    if (isLoaded) {
      const createVgsForm = window.VGSCollect.create(
        process.env.REACT_APP_VGS_VAULT,
        process.env.REACT_APP_VGS_ENV,
        (s) => {
          if (s) {
            if (s.card_name?.isDirty) props.form.validateFields(['userName']);
            if (s.card_number?.isDirty) props.form.validateFields(['creditCardNumber']);
            if (s.card_exp?.isDirty) props.form.validateFields(['expirationDate']);
            if (s.card_cvc?.isDirty) props.form.validateFields(['creditCardCvc']);
            if (inputsLoading) setInputsLoading(false);
          }
        }
      );
      setVgsForm(createVgsForm);
      const card_name = {
        type: 'text',
        name: 'card_name',
        validations: ['required'],
        css,
      };
      if (editPaymentMethod?.length) {
        card_name.disabled = 'disabled';
        card_name.value = editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.user_name;
      }
      createVgsForm.field('#cc-name .fake-input', card_name);

      const ccNumber = {
        type: 'card-number',
        name: 'card_number',
        successColor: '#4F8A10',
        // errorColor: '#D8000C',
        showCardIcon: true,
        // placeholder: '4111 1111 1111 1111',
        validations: ['required', 'validCardNumber'],
        css,
      };

      if (editPaymentMethod?.length) {
        ccNumber.disabled = 'disabled';
        ccNumber.value = editPaymentMethod[0].label;
      }

      createVgsForm.field('#cc-number .fake-input', ccNumber);

      const ccCvc = {
        type: 'card-security-code',
        name: 'card_cvc',
        // placeholder: '344',
        validations: ['required', 'validCardSecurityCode'],
        css,
      };

      if (editPaymentMethod?.length) {
        ccCvc.disabled = 'disabled';
        ccCvc.value = '***';
      }

      createVgsForm.field('#cc-cvc .fake-input', ccCvc);

      const expirationDate = {
        type: 'card-expiration-date',
        name: 'card_exp',
        placeholder: 'MM/YY',
        validations: ['required', 'validCardExpirationDate'],
        yearLength: '2',
        css,
      };

      if (editPaymentMethod?.length) {
        expirationDate.disabled = 'disabled';
        expirationDate.value = editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.card_exp;
      }

      createVgsForm.field('#cc-expiration-date .fake-input', expirationDate);
      // setInputsLoading(false);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (editPaymentMethod?.length) {
      props.form.setFieldsValue({
        paymentMethodName: editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.payment_method_name,
        userName: editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.user_name,
        billingAddress: editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.billing_address,
        billingCity: editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.billing_city,
        postalCode: editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.zip_code,
        state: editPaymentMethod[0].paymentGatewayMeta?.ACTIVE?.state,
        // routingNumber: maskedRouting,
      });
    }
  }, []);

  const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmit = (values) => {
    // e.preventDefault();
    // console.log(vgsForm.state);
    let formState = vgsForm.state;
    setLoading(true);
    vgsForm.submit(
      '/post',
      {},
      (status, response) => {
        try {
          const label = `${jsUcfirst(formState.card_number.cardType)} xxxxxx${formState.card_number.last4}`;
          const cardData = JSON.parse(response.data);

          vgsValidator([
            [cardData.card_name, 'card_name'],
            [cardData.card_number, 'card_number'],
            [cardData.card_cvc, 'card_cvc'],
            [cardData.card_exp, 'card_exp'],
          ]);

          const data = {
            defaultMethod: false,
            label,
            metaData: {
              payment_method_name: values.paymentMethodName,
              user_name: cardData.card_name,
              card_number: cardData.card_number,
              card_cvc: cardData.card_cvc,
              card_type: formState.card_number.cardType.toUpperCase(),
              card_exp: cardData.card_exp,
              billing_address: values.billingAddress,
              billing_city: values.billingCity,
              zip_code: values.postalCode,
              state: values.state,
            },
            paymentGateway: 'Nuvei',
            paymentMethodType: 'Card',
          };
          props.onFinish(data, 'nuvei');
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const validateFullName = () => {
    if (!vgsForm.state.card_name.isValid) {
      return Promise.reject('Please enter the name on credit card.');
    }
    return Promise.resolve();
  };

  const validateCreditCard = () => {
    if (!vgsForm.state.card_number.isValid) {
      return Promise.reject('Please enter a valid credit card number.');
    }
    return Promise.resolve();
  };

  const validateCvv = () => {
    if (!vgsForm.state.card_cvc.isValid) {
      return Promise.reject('Please enter the cvv number.');
    }
    return Promise.resolve();
  };

  const validateExpDate = () => {
    if (!vgsForm.state.card_exp.isValid) {
      return Promise.reject('Please enter a valid expiration date.');
    }
    return Promise.resolve();
  };

  return (
    <>
      <Text size={12} color={'black'} strong centered>
        {`Fees: ${(Number(servicePlanData?.achRateFee) * 100).toFixed(2)}% + $${Number(
          servicePlanData?.creditCardTrxFee
        ).toFixed(2)} per transaction`}
      </Text>
      <Space vertical={10} />
      {inputsLoading && (
        <LoadingContainer>
          <Spin />
        </LoadingContainer>
      )}
      <Form form={props.form} onFinish={handleSubmit} style={{ visibility: inputsLoading ? 'hidden' : 'visible' }}>
        <span className="inputTitle">Payment Method Name</span>
        <Form.Item
          name="paymentMethodName"
          whitespace="true"
          rules={[{ required: true, message: 'Please enter your payment method´s name.', whitespace: true }]}
        >
          <Input disabled={editPaymentMethod?.length} />
        </Form.Item>

        <Form.Item
          name="userName"
          whitespace="true"
          rules={[
            {
              validator: validateFullName,
            },
          ]}
          // hasFeedback
        >
          <span className="inputTitle">Name on Credit Card</span>
          <div id="cc-name" className="form-group" key="1">
            <div className="form-control-static">
              <span className="fake-input"></span>
            </div>
          </div>
        </Form.Item>

        <Form.Item
          name="creditCardNumber"
          whitespace="true"
          rules={[
            {
              validator: validateCreditCard,
            },
          ]}
        >
          <span className="inputTitle">Credit Card Number</span>
          <div id="cc-number" className="form-group" key="2">
            <div className="form-control-static">
              <span className="fake-input"></span>
            </div>
          </div>
        </Form.Item>
        <Row gutter={15}>
          <Col span={12}>
            <span className="inputTitle">Expiration date</span>
            <Form.Item
              name="expirationDate"
              whitespace="true"
              rules={[{ validator: validateExpDate }]}
              // hasFeedback
            >
              <div id="cc-expiration-date" className="form-group" key="3">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <span className="inputTitle">CVV</span>
            <Form.Item
              name="creditCardCvc"
              whitespace="true"
              rules={[
                {
                  validator: validateCvv,
                },
              ]}
            >
              <div id="cc-cvc" className="form-group" style={{ width: '99.9%' }} key="3">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <span className="inputTitle">Billing address</span>
        <Form.Item
          name="billingAddress"
          whitespace="true"
          rules={[{ required: true, message: 'Please enter your billing address.', whitespace: true }]}
        >
          <Input disabled={editPaymentMethod?.length} />
        </Form.Item>

        <Row gutter={15}>
          <Col span={10}>
            <span className="inputTitle">Billing City</span>
            <Form.Item
              name="billingCity"
              whitespace="true"
              rules={[{ required: true, message: 'Please enter your billing city.', whitespace: true }]}
            >
              <Input disabled={editPaymentMethod?.length} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <span className="inputTitle">State</span>
            <Form.Item
              name="state"
              whitespace="true"
              rules={[{ required: true, message: 'Please select your state.' }]}
            >
              <Select showSearch onChange={() => {}} disabled={editPaymentMethod?.length}>
                {usStates.map((s, i) => (
                  <Option value={s.abbreviation} key={i}>
                    {s.abbreviation}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <span className="inputTitle">Zip Code</span>
            <Form.Item
              name="postalCode"
              whitespace="true"
              rules={[
                {
                  validator: checkZipCode,
                },
              ]}
            >
              <Input data-testid="zipCodeTest" disabled={editPaymentMethod?.length} />
            </Form.Item>
          </Col>
        </Row>

        <Button
          htmlType="submit"
          color="violet"
          alignment="center"
          size="medium"
          loading={loading}
          data-testid="saveTest"
          disabled={editPaymentMethod?.length}
        >
          Save
        </Button>
      </Form>
    </>
  );
};

const LoadingContainer = styled.div`
  position: absolute;
  padding-top: 200px;
  align-items: center;
  width: 85%;
  justify-content: center;
  display: flex;
`;

export default NuveiCreditCardDetail;
