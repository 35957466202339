import React, { useState } from 'react';

import { Menu, Space as AntSpace, Dropdown } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Text from 'components/Text';
import DeleteMenuItem from '../DeleteMenuItem';
import { useEffect } from 'react';

const DeleteMenu = (props) => {
  const { paymentMethods, setDropdownVisible, dropdownVisible, getPaymentMethods, setAlert, groupId } = props;

  const getItem = (label, key, icon, children, type, disabled) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      disabled,
    };
  };
  //

  const generateMenuItems = (pms) => {
    const i = pms.map((pm) => {
      return getItem(
        <DeleteMenuItem
          pm={pm}
          setDropdownVisible={setDropdownVisible}
          getPaymentMethods={getPaymentMethods}
          setAlert={setAlert}
        />,
        pm.id,
        null,
        null,
        null,
        (pm.microDepositsFlow && pm.paymentMethodState === 'SCHEDULED' ? true : false) || pm.default
      );
    });
    return i;
  };

  const [aMenu, setAmenu] = useState(
    <Menu items={generateMenuItems(paymentMethods)} className="payment-details-menu" />
  );

  let menu = <Menu />;

  useEffect(() => {
    setAmenu(<Menu items={generateMenuItems(paymentMethods)} className="payment-details-menu" />);
  }, [paymentMethods]);

  const handleDropdownClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <AntSpace align="center" style={{ padding: '0 8px 4px', display: 'flex', justifyContent: 'center' }}>
      <DeleteOutlined style={{ fontSize: '14px' }} />
      <Dropdown
        overlay={aMenu}
        open={dropdownVisible}
        onClick={handleDropdownClick}
        overlayClassName="payment-details-menu"
      >
        <Text size={16} color="black" pointer>
          Delete a bank Account
        </Text>
      </Dropdown>
    </AntSpace>
  );
};

export default DeleteMenu;
