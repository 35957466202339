import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { globalDisplayAlert } from 'resources/helpers';
import useFileManager from 'hooks/useFileManager';

function DownloadDocument(props) {
  const session = useSelector((store) => store.session);
  const { downloadFile } = useFileManager();
  const { record } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const actionDisabled = !session.userData?.servicePlan?.metaFeatures.dOCUMENTStorage;

  function documentOwnerId(doc) {
    if (session.userData?.cognito['custom:landlord'] !== 'true') {
      return doc.landlordId;
    }
    return '';
  }

  const handleDownloadDocument = async () => {
    // console.log('ƒ handleDownloadDocument', props.record);
    if (!record) return;
    try {
      setLoading(true);
      await downloadFile(
        record.id,
        'download',
        encodeURIComponent(record.fileName),
        'document',
        '',
        documentOwnerId(record)
      );

      setLoading(false);
    } catch (err) {
      console.error('ƒ handleDownloadDocument error', err);
      setLoading(false);
      globalDisplayAlert(dispatch, 'Document deleted successfully', 'error');
    }
  };

  return (
    <IconButton
      toolTitle="Download Document"
      icon={<DownloadOutlined />}
      handleClick={handleDownloadDocument}
      disabled={actionDisabled}
      loading={loading}
    />
  );
}

export default DownloadDocument;
