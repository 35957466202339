import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';
import Tag from 'components/Tag';

import { SettingOutlined, DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';

import { displayAlertAction } from 'store/actions/globalActions';
import { deletePropertyGroup } from 'services/api/landlordApi/delete';

import theme from 'styles/styledComponents/variables';
import styled from 'styled-components';
import { Divider } from 'antd';
import IconButton from 'components/uielements/IconButton/IconButton';

const CardButtons = (props) => {
  const {
    setShowPropertyPanel,
    setDrawerVisible,
    setShowPortfolioSettings,
    screenWidth,
    propertyGroup,
    fetchPropertyGroups,
    setLoadingPropertyGroups,
    getPaymentMethodStatus,
    wepayStatus,
    onboardingState,
  } = props;

  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);

  const handleDeletePropertyGroup = async (id) => {
    try {
      setLoadingPropertyGroups(true);
      await deletePropertyGroup(session.cognitoTokens.idToken.jwtToken, id);
      fetchPropertyGroups(false, true);
    } catch (error) {
      setLoadingPropertyGroups(false);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].detail || 'Request failed.', true, 5000));
    }
  };

  return (
    <StyledExtraWrapper className="property-group-extra">
      <Button
        type="primary"
        height={screenWidth > 560 ? 36 : 27}
        fontSize={screenWidth > 560 ? 16 : 12}
        padding1={1}
        padding2={15}
        bottom={0}
        onClick={() => {
          setShowPropertyPanel(true);
          setDrawerVisible(true);
        }}
        icon={
          <PlusSquareOutlined
            style={{ fontSize: screenWidth > 560 ? '14px' : '13px', color: theme.colors.white }}
          />
        }
        data-testid="addPropertyTest"
      >
        ADD A PROPERTY
      </Button>
      {session.userData.paymentGateway === 'Wepay' &&
        (wepayStatus ? (
          <Tag backgroundcolor={theme.colors.grey5}>Payments Not Enabled</Tag>
        ) : (
          <Tag color="green">Payments Enabled</Tag>
        ))}
      {(session.userData.paymentGateway === 'SilaStripe' || session.userData.paymentGateway === 'Finix') &&
        getPaymentMethodStatus(onboardingState)}

      <IconButton
        toolTitle="Property Group Settings"
        icon={<SettingOutlined />}
        handleClick={() => {
          setShowPortfolioSettings(true);
          setDrawerVisible(true);
        }}
        data-testid="settingsIconTest"
      />
      {!propertyGroup?.default ? (
        <>
          <Divider type="vertical" />
          <IconButton
            showPopconfirm
            popTitle="Really Delete Property Group?"
            toolTitle="Delete Property Group"
            icon={<DeleteOutlined />}
            handleClick={() => handleDeletePropertyGroup(propertyGroup.id)}
            data-testid="deleteTest"
          />
        </>
      ) : null}
    </StyledExtraWrapper>
  );
};

const StyledExtraWrapper = styled.div`
  display: inline-flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export default CardButtons;
