import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { StarFilled, CheckOutlined } from '@ant-design/icons';

import { Drawer, Card, Row, Col, Form } from 'antd';

import { openInNewTab, scrollTo } from 'resources/helpers';

import { setSummaryPaymentType, unsetTransactionRenter } from 'store/actions/landlordActions';

import Space from 'components/Space';
import Text from 'components/Text';
import Button from 'components/Button';

import RenterDetails from '../components/RenterDetails';
import Onboarding from '../Onboarding';
import TransactionPanel from '../components/TransactionPanel';
import PropertyPanel from '../components/PropertyPanel';
import InvitationPanel from '../components/InvitationPanel';
import AccountProfilePanel from '../components/AccountProfilePanel';
import AccountPreferencesPanel from '../components/AccountPreferencesPanel';
import PlansBillingsPanel from '../components/PlansBillingsPanel';
import BillingDetailsPanel from '../components/BillingDetailsPanel';
import NewsPanel from '../components/NewsPanel';

import RentPrepTitle from './components/RentPrepTitle';
import ScreeningTitle from './components/ScreeningTitle';
import ApplyConnect from './components/ApplyConnect';

// import OnboardingOverlay from '../components/OnboardingOverlay';
import OnboardingFullscreen from '../components/OnboardingFullScreen';

const Screening = (props) => {
  const { setShowOnboarding, setDrawerVisible, showOnboardingFullscreen, setShowOnboardingFullscreen } = props;
  const dispatch = useDispatch();
  const [showTransactionPanel, setShowTransactionPanel] = useState(false);
  const [showPropertyPanel, setShowPropertyPanel] = useState(false);
  const [showInvitationPanel, setShowInvitationPanel] = useState(false);
  const [showEditRenterPanel, setShowEditRenterPanel] = useState(false);
  const [editRenter, setEditRenter] = useState(null);
  const [editProperty, setEditProperty] = useState(null);
  const screeningRef = useRef(null);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const stripeTimer = useRef(null);

  // Invitation panel form
  const [form] = Form.useForm();
  const [editInvitationProperty, setEditInvitationProperty] = useState(false);

  let timerId = '';

  const updateMedia = () => {
    setDesktop(window.innerWidth > 576);
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (window.innerWidth < 576) {
      scrollTo(screeningRef.current);
    }
  }, []);

  return (
    <StyledScreenContainer>
      <StyledDrawer
        closable={false}
        // closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
        placement="right"
        destroyOnClose
        onClose={() => {
          props.setDrawerVisible(false);
          props.setShowOnboarding(false);
          props.setShowAccountProfile(false);
          props.setShowAccountPreferences(false);
          props.setShowPlansBillingPanel(false);
          props.setShowBillingDetailsPanel(false);
          form.resetFields();
          setShowTransactionPanel(false);
          setShowPropertyPanel(false);
          setShowInvitationPanel(false);
          setShowEditRenterPanel(false);
          setEditInvitationProperty(false);
          dispatch(setSummaryPaymentType(''));
          dispatch(unsetTransactionRenter());
        }}
        open={props.drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {props.drawerVisible && showEditRenterPanel && editRenter && (
          <RenterDetails
            editRenter={editRenter}
            setDrawerVisible={props.setDrawerVisible}
            setEditRenter={setEditRenter}
            setShowEditRenterPanel={setShowEditRenterPanel}
          />
        )}
        {/* props.drawerVisible && props.showOnboarding && (
          <Onboarding
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            checkLandlordStatus={checkLandlordStatus}
            updatePaymentGateway={updatePaymentGateway}
          />
        ) */}
        {props.drawerVisible && showTransactionPanel && (
          <TransactionPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
          />
        )}
        {props.drawerVisible && showPropertyPanel && (
          <PropertyPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPropertyPanel={setShowPropertyPanel}
            editProperty={editProperty}
            editInvitationProperty={editInvitationProperty}
            setShowInvitationPanel={setShowInvitationPanel}
          />
        )}
        {props.drawerVisible && showInvitationPanel && (
          <InvitationPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowInvitationPanel={setShowInvitationPanel}
            setEditProperty={setEditProperty}
            setShowPropertyPanel={setShowPropertyPanel}
            form={form}
            editInvitationProperty={editInvitationProperty}
            setEditInvitationProperty={setEditInvitationProperty}
            setShowTransactionPanel={setShowTransactionPanel}
          />
        )}
        {/* Moved to LandlordMenu.js */}
        {/* {props.drawerVisible && props.showAccountProfile && (
          <AccountProfilePanel
            setShowAccountProfile={props.setShowAccountProfile}
            setDrawerVisible={props.setDrawerVisible}
          />
        )}
        {props.drawerVisible && props.showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowAccountPreferences={props.setShowAccountPreferences}
          />
        )}
        {props.drawerVisible && props.showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )}
        {props.drawerVisible && props.showBillingDetailsPanel && (
          <BillingDetailsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )} */}
      </StyledDrawer>
      {/* Removed from UI 4.0.0 */}
      {/* <NewsPanel
        title="What's New @PayRent"
        placement="right"
        closable={true}
        onClose={() => {
          props.setNewsDrawerVisible(false);
        }}
        open={props.newsDrawerVisible}
        posts={false}
        isOpen={props.newsDrawerVisible}
      /> */}
      {/* showOnboardingOverlay && (
        <OnboardingOverlay
          showOnboardingOverlay={showOnboardingOverlay}
          setShowOnboardingOverlay={setShowOnboardingOverlay}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
        />
      ) */}

      {showOnboardingFullscreen && (
        <OnboardingFullscreen
          showOnboardingFullscreen={showOnboardingFullscreen}
          setShowOnboardingFullscreen={setShowOnboardingFullscreen}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
          stripeTimer={stripeTimer}
          // checkLandlordStatus={checkLandlordStatus}
        />
      )}
      {/* <Cards
        setDrawerVisible={props.setDrawerVisible}
        setShowTransactionPanel={setShowTransactionPanel}
        setShowPropertyPanel={setShowPropertyPanel}
        setShowInvitationPanel={setShowInvitationPanel}
      /> */}
      <Space vertical={20} />
      <StyledColumnsContainer ref={screeningRef} id="screen-anchor">
        <Row
          gutter={[
            { xs: 0, sm: 0, md: 0, lg: 10, xl: 12, xxl: 40 },
            { xs: 15, sm: 20, md: 20, lg: 15, xl: 0, xxl: 0 },
          ]}
          justify="center"
        >
          <Col xs={24} sm={22} md={18} lg={12} xl={8} xxl={8}>
            <StyledCard title={<RentPrepTitle />}>
              <StyledCardContainer>
                <Text size={16} strong centered color="grey3">
                  Tenant Screening for Under
                </Text>
                <Text size={46} centered color="grey4">
                  $19
                </Text>
                <Text size={16} strong centered color="grey3">
                  no tenant involvement
                </Text>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '18px 0px' }}>
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  &nbsp;
                  <Text size={16} color="black">
                    Customer Rating
                  </Text>
                </div>
                <Text size={16} color="grey3" centered>
                  Tenant screening that protects landlords and identifies great tenants.
                </Text>
                <Text size={20} strong centered color="grey3" style={{ padding: '18px 0px' }}>
                  Features
                </Text>
                <StyledFeaturesContainer>
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Full Credit Report with Score
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    SSN Verification/Address History
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Nationwide Eviction Report
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Judgements, Leins and Bankruptcies
                  </Text>
                </StyledFeaturesContainer>
                <Button
                  color="green"
                  alignment="center"
                  bottom={0}
                  onClick={() => openInNewTab('https://www.payrent.com/affiliate/rentprep')}
                >
                  START SCREENING TENANTS
                </Button>
              </StyledCardContainer>
            </StyledCard>
          </Col>
          <Col xs={24} sm={22} md={18} lg={12} xl={8} xxl={8}>
            <StyledMiddleCard title={<ScreeningTitle />}>
              <StyledCardContainer>
                <Text size={16} strong centered color="grey3">
                  No Cost for Landlords
                </Text>
                <Text size={46} centered color="grey4">
                  $39
                </Text>
                <Text size={16} strong centered color="grey3">
                  paid by applicant
                </Text>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '18px 0px' }}>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                    <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                    <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                    <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                    <StarFilled style={{ fontSize: '20px', color: '#D9D9D9' }} />
                    <StyledSpan style={{ width: '10%' }}>
                      <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                    </StyledSpan>
                  </div>
                  &nbsp;
                  <Text size={16} color="black">
                    Customer Rating
                  </Text>
                </div>
                <Text size={16} color="grey3" centered>
                  Apply, screen and accept new renters faster and easier.
                </Text>
                <Text size={20} strong centered color="grey3" style={{ padding: '18px 0px' }}>
                  Features
                </Text>
                <StyledFeaturesContainer>
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Credit Score by TransUnion
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Nationwide Eviction Check
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Nationwide Criminal/ Sex Offender
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Automated Decline Notifications
                  </Text>
                </StyledFeaturesContainer>
                <Button
                  color="lightBlue"
                  alignment="center"
                  bottom={0}
                  data-testid="signupButtonTest"
                  onClick={() => openInNewTab('https://www.payrent.com/affiliate/screenrenters')}
                >
                  CREATE YOUR FREE ACCOUNT
                </Button>
              </StyledCardContainer>
            </StyledMiddleCard>
          </Col>
          <Col xs={24} sm={22} md={18} lg={12} xl={8} xxl={8}>
            <StyledCard title={<ApplyConnect />}>
              <StyledCardContainer>
                <Text size={16} strong centered color="grey3">
                  No Cost for Landlords
                </Text>
                <Text size={46} centered color="grey4">
                  $0
                </Text>
                <Text size={16} strong centered color="grey3">
                  $29.95 paid by applicant
                </Text>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '18px 0px' }}>
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  <StarFilled style={{ fontSize: '20px', color: '#FADB14' }} />
                  &nbsp;
                  <Text size={16} color="black">
                    Customer Rating
                  </Text>
                </div>
                <Text size={16} color="grey3" centered>
                  Create a free account then invite applicants to apply and screen.
                </Text>
                <Text size={20} strong centered color="grey3" style={{ padding: '18px 0px' }}>
                  Features
                </Text>
                <StyledFeaturesContainer>
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Experian Credit w/ Score
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Criminal & Eviction History
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Online Rental Application
                  </Text>
                  <br />
                  <CheckOutlined style={{ color: '#541388' }} />
                  <Text size={16} sizeXlg={12} color="grey3" strong>
                    Document Uploads
                  </Text>
                </StyledFeaturesContainer>
                <Button
                  color="darkGreen"
                  alignment="center"
                  bottom={0}
                  onClick={() => openInNewTab('https://www.payrent.com/affiliate/apply-connect')}
                >
                  GET CONNECTED
                </Button>
              </StyledCardContainer>
            </StyledCard>
          </Col>
        </Row>
      </StyledColumnsContainer>
    </StyledScreenContainer>
  );
};

const StyledScreenContainer = styled.div`
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const StyledColumnsContainer = styled.div`
  @media screen and (max-width: 575px) {
    margin-top: -31px !important;
  }
`;
const StyledCardContainer = styled.div`
  max-width: 350px;
`;

const StyledCard = styled(Card)`
  .ant-card-head-title {
    padding: 12.5px 0px;
  }
  .ant-card-body {
    display: flex;
    justify-content: center;
  }
`;

const StyledMiddleCard = styled(Card)`
  .ant-card-head-title {
    padding: 9px 0px;
  }
  .ant-card-body {
    display: flex;
    justify-content: center;
  }
`;

const StyledFeaturesContainer = styled.div`
  span {
    padding: 5px;
  }
  margin-bottom: 18px;
`;
const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 1500px) {
        width: 460px !important;
      }
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    input {
      color: #595959;
    }
    @media screen and (max-height: 750px) {
      .ant-steps-small .ant-steps-item-description {
        font-size: 12px !important;
      }
    }
    @media screen and (max-height: 750px) {
      .ant-drawer-body h1 {
        font-size: 35px !important;
      }
      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }
  `}
`;
const StyledSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 80px;
  z-index: 2;
`;

export default Screening;
