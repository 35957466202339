export function createMerchantIdentityRequest(data) {
  // console.log('data', data);
  const identityRequestMap = {
    tags: data.tags || {},
    additional_underwriting_data: {
      annual_ach_volume: data.additional_underwriting_data_monthly_rental_income * 12 * 100,
      average_ach_transfer_amount: 300000,
      average_card_transfer_amount: 300000,
      business_description: data.additional_underwriting_data_business_description,
      card_volume_distribution: {
        card_present_percentage: 0,
        mail_order_telephone_order_percentage: 0,
        ecommerce_percentage: 100,
      },
      credit_check_allowed: false,
      merchant_agreement_accepted: true,
      merchant_agreement_ip_address: data.additional_underwriting_data_merchant_agreement_ip_address,
      merchant_agreement_timestamp: data.additional_underwriting_data_merchant_agreement_timestamp,
      merchant_agreement_user_agent: data.additional_underwriting_data_merchant_agreement_user_agent,
      refund_policy: 'OTHER',
      volume_distribution_by_business_type: {
        other_volume_percentage: 0,
        consumer_to_consumer_volume_percentage: 0,
        business_to_consumer_volume_percentage: 100,
        business_to_business_volume_percentage: 0,
        person_to_person_volume_percentage: 0,
      },
    },
    entity: {
      annual_card_volume: data.additional_underwriting_data_monthly_rental_income * 12 * 100,
      business_address: {
        city: data.entity_business_address_city,
        country: data.entity_business_address_country,
        region: data.entity_business_address_region,
        line2: data.entity_business_address_line2,
        line1: data.entity_business_address_line1,
        postal_code: data.entity_business_address_postal_code,
      },
      business_name: data.entity_business_name,
      business_phone: cleanPhoneNumber('1' + data.entity_business_phone),
      business_tax_id: data.entity_business_tax_id,
      business_type: data.entity_business_type,
      default_statement_descriptor: `PayRent-${data.entity_business_name}`.slice(0, 20),
      dob: {
        year: data.entity_dob.year(),
        day: data.entity_dob.date(),
        month: data.entity_dob.month() + 1,
      },
      doing_business_as: data.entity_doing_business_as ? data.entity_doing_business_as : data.entity_business_name,
      email: data.entity_email,
      first_name: data.entity_first_name,
      has_accepted_credit_cards_previously: true,
      incorporation_date: {
        year: data.entity_incorporation_date.year(),
        day: data.entity_incorporation_date.date(),
        month: data.entity_incorporation_date.month() + 1,
      },
      last_name: data.entity_last_name,
      max_transaction_amount: 500000,
      ach_max_transaction_amount: 500000,
      mcc: '6513',
      ownership_type: data.entity_ownership_type,
      personal_address: {
        city: data.entity_personal_address_city,
        country: data.entity_personal_address_country,
        region: data.entity_personal_address_region,
        line2: data.entity_personal_address_line2,
        line1: data.entity_personal_address_line1,
        postal_code: data.entity_personal_address_postal_code,
      },
      phone: cleanPhoneNumber('1' + data.entity_phone),
      principal_percentage_ownership: data.entity_principal_percentage_ownership,
      tax_id: data.entity_tax_id,
      title: data.entity_title,
      url: data.entity_url || 'no website',
    },
  };
  // console.log('identityRequestMap', identityRequestMap);
  return identityRequestMap;
}

export function createMerchantIdentityAssociateRequest(data) {
  const associateRequestMap = {
    entity: {
      first_name: data.entity_first_name,
      last_name: data.entity_last_name,
      title: data.entity_title,
      email: data.entity_email,
      principal_percentage_ownership: data.entity_principal_percentage_ownership,
      personal_address: {
        city: data.entity_personal_address_city,
        country: data.entity_personal_address_country,
        region: data.entity_personal_address_region,
        line2: data.entity_personal_address_line2,
        line1: data.entity_personal_address_line1,
        postal_code: data.entity_personal_address_postal_code,
      },
      phone: cleanPhoneNumber('1' + data.entity_phone),
      dob: {
        year: data.entity_dob.year(),
        day: data.entity_dob.date(),
        month: data.entity_dob.month() + 1,
      },
      tax_id: data.entity_tax_id,
    },
  };

  return associateRequestMap;
}

function cleanPhoneNumber(phoneStr) {
  return phoneStr.replace(/[^\d+]+/g, '');
}
