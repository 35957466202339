// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card as AntCard, Spin } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { checkFirstAchPayment } from 'store/actions/tenantActions';
import { getUserDataV3 } from 'store/actions/sessionActions';

import * as Iot from 'services/aws/iot/aws-iot';

import { cancelSilaTransaction, getBillingItem } from 'services/api/tenantApi';

import { getBalanceDueWithPending, displayAlert } from 'resources/helpers';

// icons
import { HistoryOutlined, LogoutOutlined, ArrowLeftOutlined, ScheduleOutlined } from '@ant-design/icons';

// Components
import Title from 'components/Title';
import Link from 'components/Link';
import Space from 'components/Space';
import Text from 'components/Text';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import AddPaymentMethod from '../../components/AddPaymentMethod';

const PaymentStatus = (props) => {
  // console.log('[PaymentStatus.js]', props);
  const { paymentData, paymentMethodSelection, paymentError, quadpayResult } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const billingItems = useSelector((store) => store.tenant.billingItems);
  const paymentMethods = useSelector((store) => store.tenant.paymentMethods);
  const billingAccounts = useSelector((store) => store.session.userData.billingAccounts);
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const [due, setDue] = useState(0);
  const [fee, setFee] = useState('0.00');
  const [totalAmount, setTotalAmount] = useState(0);
  const [scheduledDate, setScheduledDate] = useState('');
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processedPayment, setProcessedPayment] = useState(false);

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
    if (paymentData) {
      if (paymentError.length) {
        setError(paymentError);
        // const newBalanceDue = getBalanceDueWithPending(paymentData.due, billingItems.processing);
        setDue(paymentData.due);
        setFee(paymentData.fee.amount);
        setTotalAmount(+paymentData.totalAmount);
        setPaymentMethod([paymentMethods[paymentMethodSelection]]);
        // setPaymentMethod(location.paymentMethod);
      } else {
        if (paymentData.landlordDisabled) {
          const tAmount = paymentData.amount + Number(paymentData.fee.amount);
          setTotalAmount(tAmount);
          setPaymentMethod([paymentMethods[paymentMethodSelection]]);
          setFee(paymentData.fee.amount);

          const newBalanceDue = getBalanceDueWithPending(paymentData.due, billingItems.processing);
          setDue(newBalanceDue);
        } else {
          let calcFee = 0;

          if (session.userData.paymentGateway === 'SilaStripe') {
            if (paymentData.transactionType === 'ACH') {
              calcFee =
                +paymentData.applicationFee -
                (+paymentData.applicationFee / 100) * paymentData.landlordAbsorbPercent;
            } else {
              calcFee =
                +paymentData.processingFee -
                (+paymentData.processingFee / 100) * paymentData.landlordAbsorbPercent;
            }
          } else {
            calcFee =
              +paymentData?.fee?.amount -
              (paymentData?.fee?.amount / 100) * paymentData?.fee?.landlordAbsorbPercent;
          }

          const billingAccount = paymentData.customerRenter
            ? paymentData.customerRenter.billingAccounts.filter((b) => b.state === 'ACTIVE')
            : [];

          setPaymentMethod([paymentMethods[paymentMethodSelection]]);
          setScheduledDate(moment(paymentData.createdAt).format('MM/DD/YYYY'));

          if (paymentData.due) {
            const addedPending = paymentData.due - paymentData.amount;
            setDue(addedPending);
          }
          if (paymentData.customerRenter) {
            const newBalanceDue = getBalanceDueWithPending(billingAccount[0].balance, billingItems.processing);
            setDue(newBalanceDue);
          }

          if (paymentMethods[paymentMethodSelection].paymentMethodType === 'ACH') {
            if (paymentData.fee.firstPayment) {
              setTotalAmount(paymentData.amount);
            } else {
              const tAmount = paymentData.amount + Number(calcFee.toFixed(2));
              setTotalAmount(tAmount);
              setFee(calcFee.toFixed(2));
            }
          }
          if (
            paymentMethods[paymentMethodSelection].paymentMethodType === 'Card' ||
            paymentMethods[paymentMethodSelection].paymentMethodType === 'CREDIT'
          ) {
            const tAmount = paymentData.amount + Number(calcFee.toFixed(2));
            setTotalAmount(tAmount);
            setFee(calcFee.toFixed(2));
          }
        }
        // const calcFee =
        //   Number(paymentData.fee.amount) - (paymentData.fee.amount / 100) * paymentData.fee.landlordAbsorbPercent;
        // const billingAccount = paymentData.customerRenter
        //   ? paymentData.customerRenter.billingAccounts.filter((b) => b.state === 'ACTIVE')
        //   : [];
        // setPaymentMethod([paymentMethods[paymentMethodSelection]]);
        // const tAmount = paymentData.amount + Number(calcFee.toFixed(2));
        // setTotalAmount(tAmount);
        // setScheduledDate(moment(paymentData.createdAt).format('MM/DD/YYYY'));
        //
        // setFee(calcFee.toFixed(2));
        // if (paymentData.customerRenter) setDue(billingAccount[0].balance);
      }
    }
    if (quadpayResult) {
      setTotalAmount(+quadpayResult.amount);
      setScheduledDate('Today');
      const ba = billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (ba.length) {
        const newBalanceDue = getBalanceDueWithPending(+ba[0].balance, billingItems.processing);
        setDue(newBalanceDue);
        const formattedFee = +quadpayResult?.fee;
        setFee(formattedFee.toFixed(2));
      }
    }

    dispatch({
      type: 'HEADER_BUTTON_VISIBLE',
      visible: true,
      screenTitle: 'Payment',
      leftButtonPath: '/tenant',
      leftButtonType: 'close',
    });
    return () => {
      controller.abort();
      dispatch({
        type: 'HEADER_BUTTON_VISIBLE',
        visible: false,
        screenTitle: '',
        leftButtonPath: '',
        leftButtonType: '',
      });
    };
  }, [billingItems]);

  const checkSilaAchPayment = async () => {
    try {
      const bi = await getBillingItem(paymentData.billingItem.id);
      console.log(bi);
      if (bi.state === 'PROCESSED') {
        setProcessedPayment(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (paymentData && session.userData.paymentGateway === 'SilaStripe') checkSilaAchPayment();
    Iot.attachMessageHandler(async (topic, message) => {
      const string = new TextDecoder().decode(message);
      const ms = JSON.parse(string);
      if (ms.event === 'TRANSACTION_UPDATE') {
        try {
          const bi = await getBillingItem(paymentData.billingItem.id);
          console.log(bi);
          if (bi.state === 'PROCESSED') {
            setProcessedPayment(true);
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
  }, []);

  const handleCancelTransaction = async () => {
    try {
      if (paymentData.billingItem.state !== 'PROCESSING' || processedPayment) {
        return;
      }
      setLoading(true);
      await cancelSilaTransaction(cognitoToken, paymentData.billingItem.paymentId);
      setProcessedPayment(true);
      displayAlert(setAlert, 'Transaction cancelled', 'success', 5000);
      setLoading(false);
    } catch (e) {
      console.log('Error', e);
      setLoading(false);
      displayAlert(
        setAlert,
        e?.data?.error || e.data?.errors[0]?.detail || 'There was an error with you request.',
        'warning',
        5000
      );
    }
  };

  return (
    <Container data-testid="paymentStatusTest">
      <Header>
        <ArrowLeftOutlined
          onClick={() => {
            dispatch(checkFirstAchPayment());
            props.setPaymentError([]);
            props.setShowPayment(true);
            props.setPayClicked(false);
            props.setDrawerVisible(false);
            props.setShowPaymentStatus(false);
            props.setQuadpayResult(null);
          }}
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowTest"
        />
        Payment
      </Header>
      <FormContainer>
        <Title level={3} color="violet2">
          Account Balance
        </Title>

        <Text size={32} color={due >= 0 ? 'red' : 'green'} centered>
          ${due} <small style={{ fontSize: '16px' }}>{due >= 0 ? 'Due' : 'Credit'}</small>
        </Text>
        {paymentData?.landlordDisabled ? (
          <AlertFade
            isVisible
            type="warning"
            message="Payment Pending"
            description="Your landlord is not yet enabled to take this payment. We have notified them of your request to pay and will let you know when you can complete the payment process."
          />
        ) : (
          <Title level={3} color={error.length ? 'red' : 'green'} centered>
            {error.length ? 'Payment Error' : 'Payment Accepted'}
          </Title>
        )}
        <Spin spinning={loading}>
          <AntCard
            style={{
              borderColor: '#C4C4C4',
              borderRadius: '4px',
              marginTop: '15px',
              marginBottom: '15px',
            }}
            bordered
            styles={{
              body: {
                borderRadius: '4px',
                textAlign: 'center',
                backgroundColor: '#F7F7F0',
                padding: '10px',
                color: 'black',
              },
            }}
          >
            {error.length ? (
              <>
                <Text size={12} color="black">
                  There was a problem with your payment using
                  <br />
                  {paymentMethod.length > 0 && paymentMethod[0].label}
                </Text>
                <Space vertical={30} />
                <Text size={12} color="black">
                  {error[0].status || error[0]?.title === 'Error' ? (
                    error[0].detail
                  ) : (
                    <>
                      Please contact your financial institution for
                      <br />
                      further details or check payment method details
                    </>
                  )}
                </Text>
              </>
            ) : (
              <>
                <Text color="black" size={12} strong>
                  Billed to:
                </Text>
                <br />
                {quadpayResult?.success ? (
                  <Text size={12} color="black">
                    Quadpay order id: {quadpayResult.orderId}
                  </Text>
                ) : (
                  <Text size={12} color="black">
                    {paymentMethod.length > 0 && paymentMethod[0].label}
                  </Text>
                )}

                <Space vertical={30} />

                <Text color="black" size={12} strong>
                  Scheduled for:
                </Text>
                <br />
                <Text size={12} color="black">
                  {scheduledDate}
                </Text>
              </>
            )}

            <Space vertical={30} />

            <Text color="black" size={16} strong>
              Total Payment Amount
            </Text>
            <br />
            <Text size={25} color="black" strong>
              ${totalAmount.toFixed(2)}
            </Text>
            <br />
            <Text size={12} color="black">
              Includes a ${fee}
              &nbsp;processing fee
            </Text>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <AlertFade
                type={alert.type}
                isVisible={!!alert.message}
                message={alert.message}
                // description={message.description}
                alertPosition="absolute"
                position="absolute"
                minHeight="40"
                // closable={alert.closable}
                // marginBottom={alert.marginBottom}
                // width={90}
                bottom={10}
              />
            </div>
          </AntCard>
        </Spin>

        <Space vertical={10} />
        {error.length ? (
          paymentMethods.length < 5 && (
            <AddPaymentMethod
              setShowPayment={props.setShowPayment}
              setShowPaymentStatus={props.setShowPaymentStatus}
              setShowPaymentMethodDetail={props.setShowPaymentMethodDetail}
              AddBankAccount={props.AddBankAccount}
              setDrawerVisible={props.setDrawerVisible}
            />
          )
        ) : (
          <>
            {' '}
            {!paymentData?.landlordDisabled && (
              <Text color="black" size={12} tag="span" noMargin centered>
                You will receive an email confirmation of this payment if enabled in your{' '}
                <Link to="/tenant/settings" inline>
                  account settings
                </Link>
              </Text>
            )}
            <Space vertical={20} />
            <Button
              alignment="center"
              outlined
              onClick={() => {
                history.push('/sign-out');
              }}
              data-testid="logOutTest"
            >
              LOGOUT <LogoutOutlined />
            </Button>
            {paymentData?.landlordDisabled ? (
              <Link to="/tenant/wallet" centered data-testid="scheduledButtonTest">
                View scheduled payments
                <ScheduleOutlined
                  style={{
                    color: 'black',
                    fontSize: '22px',
                    marginLeft: '10px',
                  }}
                />
              </Link>
            ) : (
              <Link to="/tenant/transactions" centered data-testid="transactionButtonTest">
                See transaction history
                <HistoryOutlined
                  style={{
                    color: 'black',
                    fontSize: '22px',
                    marginLeft: '10px',
                  }}
                />
              </Link>
            )}
            <Space vertical={20} />
            {session.userData.paymentGateway === 'SilaStripe' &&
              paymentMethods[paymentMethodSelection].paymentMethodType === 'ACH' && (
                <>
                  <Text size={12} color="black" centered>
                    Make a mistake and need cancel this payment?
                  </Text>
                  <Text
                    color={
                      paymentData?.billingItem?.state === 'PROCESSING' || !processedPayment ? 'violet' : 'grey'
                    }
                    size={12}
                    underline
                    centered
                    pointer={paymentData?.billingItem?.state === 'PROCESSING' || !processedPayment}
                    onClick={handleCancelTransaction}
                  >
                    Click here to cancel
                  </Text>
                </>
              )}
          </>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const FormContainer = styled.div`
  padding: 25px;
`;

// width: 100vw;
const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

export default PaymentStatus;
