import React from 'react';
import { useDispatch } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import './styles.scss';

function Menu(props) {
  const dispatch = useDispatch();

  Menu.handleClickOutside = (e) => {
    if (!(e.target.tagName === 'svg' || e.target.tagName === 'path')) {
      dispatch({
        type: 'SET_ACTIVE_BOX_MENU',
        activeBoxMenu: {},
      });
    }
  };

  return (
    <ul className="basic-menu" style={props.style}>
      {props.items?.map((item, i) => (
        <li
          className="basic-menu__item"
          onClick={() => {
            props.handleChangeFilter(item.text);
            dispatch({
              type: 'SET_ACTIVE_BOX_MENU',
              activeBoxMenu: {},
            });
          }}
          key={i}
        >
          {item.text}
        </li>
      ))}
    </ul>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Menu.handleClickOutside,
};

export default onClickOutside(Menu, clickOutsideConfig);
