import { Space } from 'antd';
import Button from 'components/Button/Button';
import { isDesktop, isMobile } from 'react-device-detect';
import React from 'react';
import styled from 'styled-components';
import { DeliveredProcedureOutlined, StepForwardOutlined } from '@ant-design/icons';
import GetStartedHelp from './OnboardingHelp/GetStartedHelpText';

function OnboardingActions(props) {
  const { currentStep, isLoading } = props;

  return (
    <StyledActionDiv data-testid="onboardingActionsTest">
      {isDesktop && <GetStartedHelp currentStep={currentStep} />}
      <Space className="action-div-space" direction="horizontal">
        {currentStep === 0 && (
          <Button
            size="large"
            loading={isLoading}
            disabled={isLoading}
            type="primary"
            htmlType="submit"
            form="portfolio-settings-onboarding"
            data-testid="nextButtonTest"
          >
            Next
            <StepForwardOutlined />
          </Button>
        )}
        {currentStep === 1 && (
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            form="parent-property-onboarding-form"
            loading={isLoading}
            disabled={isLoading}
            data-testid="nextButtonTest"
          >
            Next
            <StepForwardOutlined />
          </Button>
        )}
        {currentStep === 2 && (
          <Button
            size="large"
            type="primary"
            form="unit-onboarding-form"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
            data-testid="nextButtonTest"
          >
            Next
            <StepForwardOutlined />
          </Button>
        )}
        {currentStep === 3 && null}
        {currentStep === 4 && (
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            form="invite-renter-onboarding-form"
            loading={isLoading}
            disabled={isLoading}
            data-testid="finishButtonTest"
          >
            Finish
            <DeliveredProcedureOutlined />
          </Button>
        )}
      </Space>
      {isMobile && <GetStartedHelp currentStep={currentStep} />}
    </StyledActionDiv>
  );
}

const StyledActionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  .action-div-space {
    width: 100%;
    margin-top: 24px;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      justify-content: flex-end;
    }
  }
`;

const StyledHelpDiv = styled.div`
  width: 100%;
  border-left: ${(props) => `1px solid ${props.token.colorSplit} `};
  padding-left: 8px;
`;

export default OnboardingActions;
