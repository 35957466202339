import * as React from 'react';
import { Drawer } from 'antd';

function PaymentMethodsDrawer({ children, ...props }) {
  // console.log('[PaymentMethodsDrawer.js]', props, children);

  return (
    <Drawer
      placement="bottom"
      data-testid="paymentMethodsDrawer"
      closable={false}
      height={'fit-content'}
      getContainer={false}
      styles={{
        header: { borderBottom: 'none' },
        mask: { backgroundColor: 'rgba(0, 0, 0, 0.0)' },
        content: { padding: '12px 0' },
      }}
      {...props}
    >
      {children}
    </Drawer>
  );
}

export default PaymentMethodsDrawer;
