import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Jsona from 'jsona';

const dataFormatter = new Jsona();

const wePayCheckout = async (paymentData, propertyGroupId) => {
  console.log('[wepay/payment.js]', paymentData);
  let payload = {};

  if (paymentData.type === 'payment_bank') {
    payload = {
      unique_id: uuidv4(),
      amount: paymentData.amount,
      fee: {
        app_fee: paymentData.fee.appFee,
        fee_payer: 'payer',
      },
      currency: 'USD',
      type: 'service',
      short_description: paymentData.shortDescription || 'rent related',
      payment_method: {
        type: paymentData.type,
        payment_bank: {
          id: paymentData.id,
        },
      },
    };
  } else {
    payload = {
      unique_id: uuidv4(),
      amount: paymentData.amount,
      fee: {
        app_fee: paymentData.fee.appFee,
        fee_payer: 'payer',
      },
      currency: 'USD',
      type: 'service',
      short_description: paymentData.shortDescription || 'rent related',
      payment_method: {
        type: paymentData.type,
        credit_card: {
          id: paymentData.id,
        },
      },
    };
  }
  // console.log(JSON.stringify(payload));

  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_ADMIN_KEY,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/wepay/checkout/${propertyGroupId}`,
      data: payload,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export default wePayCheckout;
