// @flow
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import Drawer from 'antd/lib/drawer';
import { Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Joyride from 'react-joyride';
// import { Drawer, AutoComplete as AntAutoComplete } from 'antd';
// import axios from 'axios';

import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';
import {
  getPropertiesListAction,
  getV3RentersListAction,
  setSummaryPaymentType,
  unsetTransactionRenter,
} from 'store/actions/landlordActions';

import {
  getActivityFeed,
  getPropertyGroups,
  getPropertyGroupOnboardingStatus,
} from 'services/api/landlordApi/read';
import { updatePaymentGateway } from 'services/api/landlordApi/update';
import { displayAlertAction } from 'store/actions/globalActions';
import { dashboardSteps } from 'resources/landlordTourSteps';
import { scriptExists } from 'utils';
import * as Iot from 'services/aws/iot/aws-iot';

// Components
import AutoComplete from 'components/AutoComplete';
import Space from 'components/Space';
// import Button from 'components/Button/Button';
// import OnboardingOverlay from '../components/OnboardingOverlay';
import Onboarding from '../Onboarding';
import OnboardingFullscreen from '../components/OnboardingFullScreen';
import TransactionPanel from '../components/TransactionPanel';
import PropertyPanel from '../components/PropertyPanel';
import InvitationPanel from '../components/InvitationPanel';
import ActivityFeed from './ActivityFeed';
import KpiDashboard from '../components/KpiDashboard/KpiDashboard';
import UnitPanel from '../components/UnitPanel';
import QuickAddMenu from './Buttons/QuickAddMenu';

// import Onboarding from '../Onboarding';

let timerId = '';

const Dashboard = (props) => {
  // console.log('[Dashboard.js] props', props);
  // const [modalVisible, setIsVisible] = useState(true);
  const { setShowOnboarding, setDrawerVisible, setShowOnboardingFullscreen, showOnboardingFullscreen } = props;
  const session = useSelector((store) => store.session);
  const processor = session.userData.paymentGateway;
  const global = useSelector((store) => store.global);
  const landlord = useSelector((store) => store.landlord);
  const [showTransactionPanel, setShowTransactionPanel] = useState(false);
  const [showPropertyPanel, setShowPropertyPanel] = useState(false);
  const [showInvitationPanel, setShowInvitationPanel] = useState(false);
  const [firstActivityRequest, setFirstActivityResults] = useState({
    count: 0,
    pinned: [],
    feed: [],
  });
  const [activityFeed, setActivityFeed] = useState({
    count: 0,
    pinned: [],
    feed: [],
  });
  const [feedLength, setFeedLength] = useState(20);
  const [editProperty, setEditProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const stripeTimer = useRef(null);

  // Invitation panel form
  const [form] = Form.useForm();
  const [editInvitationProperty, setEditInvitationProperty] = useState(false);

  const dashboardCardRef = useRef(null);

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';
  const googleUrl = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;

  const dispatch = useDispatch();
  const history = useHistory();

  let sessionId = useRef(null);
  let onboardingTriggered = useRef(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 576);
  };

  // useEffect(() => {
  //   window.addEventListener('resize', updateMedia);
  //   return () => window.removeEventListener('resize', updateMedia);
  // }, []);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    if (props.location?.pathname === '/landlord/s/wepaykyc') {
      // dispatch(displayAlertAction('', 'success', 'User successfully boarded.', true, 5000));
      history.push('/landlord');
      setShowOnboardingFullscreen(true);
      // setShowOnboardingOverlay(true);
    }
    const params = new URLSearchParams(props.history?.location?.search);
    if (params.get('showOnboarding')) {
      if (params.get('showOnboarding') === 'true') {
        setShowOnboardingFullscreen(true);
      }
    }
    batch(() => {
      dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
      dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    });

    fetchActivityFeed();
    const stripeTimerRef = stripeTimer.current;
    return () => {
      clearTimeout(stripeTimerRef);
      window.removeEventListener('resize', updateMedia);
    };
  }, []);

  const fetchDefaultPropertyGroup = async (controller) => {
    try {
      const defPg = await getPropertyGroups(
        session.cognitoTokens.idToken.jwtToken,
        1,
        10,
        '&default=1',
        controller
      );

      const defaultPropGroupId = +defPg.items[0].id;

      dispatch({ type: 'SET_DEFAULT_PROPERTY_GROUP', payload: defaultPropGroupId });
      return defaultPropGroupId;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBoardingStatus = async (defPgId, controller) => {
    // console.log('ƒ fetchBoardingStatus', defPgId, processor);
    try {
      const cognitoToken = session.cognitoTokens.idToken.jwtToken;
      // const processor = session.userData.servicePlan.processor.name;
      const boardingStatus = await getPropertyGroupOnboardingStatus(cognitoToken, defPgId, controller);
      dispatch(getPropertiesListAction(cognitoToken, 1, 10000, '', '', controller));
      dispatch(getV3RentersListAction(cognitoToken, 1, 10000, '', '', controller));

      const status =
        boardingStatus.wepayState || boardingStatus.ach?.state || boardingStatus.finixOnboardingState || null;

      dispatch({
        type: 'SET_BOARDING_STATUS',
        payload: { status: status?.toLowerCase(), loading: false },
      });

      if (processor === 'Finix') {
        const unboarded = status === 'NOT_STARTED' || status === null;

        if (unboarded && landlord.units.totalCount === 0) {
          history.push('/landlord/onboarding');
          dispatch({ type: 'SET_SELECTED_MENU', payload: 'onboarding' });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const triggerOnboarding = async (controller) => {
    try {
      let defaultPropGroupId = landlord.defaultPropertyGroupId;
      if (!landlord.defaultPropertyGroupId) {
        defaultPropGroupId = await fetchDefaultPropertyGroup(controller);
      }
      if (!onboardingTriggered.current) {
        await fetchBoardingStatus(defaultPropGroupId, controller);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (session.userData.paymentGateway) {
      triggerOnboarding(controller);
    }

    //if (session.userData.servicePlan.id !== '0') {
    //  if (session.userData.servicePlan.id !== servicePlanId.current) {
    //    fetchBoardingStatus();
    //    servicePlanId.current = session.userData.servicePlan.id;
    //  }
    //}

    if (session.userData.id !== sessionId.current && session.userData.id) {
      Iot.attachMessageHandler((topic, message) => {
        const string = new TextDecoder().decode(message);
        // console.log(string);
        const ms = JSON.parse(string);
        if (ms.event === 'FEED_UPDATE') {
          fetchActivityFeed();
        }
      });

      sessionId.current = session.userData.id;
    }
    return () => controller.abort();
  }, [session.userData.id, session.userData.paymentGateway]);

  // useEffect(() => {
  //   console.log('ƒ useEffect on loading', loading);
  //   if (!loading && window.innerWidth < 576) {
  //     scrollTo(dashboardCardRef.current);
  //   }
  // }, [loading]);

  useEffect(() => {
    if (!scriptExists(googleUrl)) {
      const script = document.createElement('script');
      script.src = googleUrl;
      script.async = true;

      document.body.appendChild(script);
    }
  }, [googleUrl]);

  /*

  const fetchTourStatus = async () => {
    try {
      const tourRes = await getTourStatus(session.cognitoTokens.idToken.jwtToken, session.userData.id);
      setTourStatus(tourRes);
    } catch (error) {
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
    }
  };
  */

  const filterActivityFeed = (filters, updatedRes) => {
    let filtered = [];
    if (filters.includes('archived')) {
      if (updatedRes) {
        filtered = updatedRes.feed.filter((f) => f.archived === true);
      } else {
        filtered = firstActivityRequest.feed.filter((f) => f.archived === true);
      }
    }
    if (filters.includes('flagged')) {
      if (filtered.length) {
        filtered = filtered.filter((f) => f.flagged === true);
      } else {
        if (updatedRes) {
          filtered = updatedRes.feed.filter((f) => f.flagged === true);
        } else {
          filtered = firstActivityRequest.feed.filter((f) => f.flagged === true);
        }
      }
    }
    if (
      filters.includes('billing') ||
      filters.includes('chargeback') ||
      filters.includes('payment') ||
      filters.includes('schedule') ||
      filters.includes('verification') ||
      filters.includes('document')
    ) {
      let items = [];
      if (updatedRes) {
        items = filtered.length ? filtered : updatedRes.feed;
      } else {
        items = filtered.length ? filtered : firstActivityRequest.feed;
      }
      const typeFilters = filters.filter((f) => f !== 'archived' && f !== 'flagged');

      filtered = items.filter((f) => {
        return typeFilters.some((item2) => {
          return f.type === item2;
        });
      });
    }

    setActivityFeed({
      count: filters.length ? filtered.length : firstActivityRequest.count,
      pinned: filters.length ? activityFeed.pinned : firstActivityRequest.pinned,
      feed: filters.length ? filtered : firstActivityRequest.feed,
    });
  };

  const fetchActivityFeed = async (filters) => {
    try {
      setLoading(true);
      const filter = null;
      const filter2 = '?f=archived';

      const [resActivity, archivedItems] = await Promise.all([
        getActivityFeed(session.cognitoTokens.idToken.jwtToken, filter),
        getActivityFeed(session.cognitoTokens.idToken.jwtToken, filter2),
      ]);

      // console.log('ƒ fetchActivityFeed', resActivity, archivedItems);

      const totalFeedItems = resActivity.feed.concat(archivedItems.feed);
      const totalPinnedItems = resActivity.pinned.concat(archivedItems.pinned);

      const updatedActivityRes = {
        count: totalFeedItems.length,
        pinned: totalPinnedItems,
        feed: totalFeedItems.sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt)),
      };

      if (resActivity) {
        setActivityFeed({
          count: totalFeedItems.length,
          pinned: totalPinnedItems,
          feed: totalFeedItems.sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt)),
        });
        setFirstActivityResults(updatedActivityRes);
      }

      if (filters) {
        if (filters.length) {
          filterActivityFeed(filters, updatedActivityRes);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
    }
  };

  const checkLandlordStatus = (setLoading1, setDisableButton1, setDisableButton2, displayAlert) => {
    try {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        const result = await getPropertyGroupOnboardingStatus(
          session.cognitoTokens.idToken.jwtToken,
          landlord.defaultPropertyGroupId
        );

        if (result.wepayRegistrationState === 'new2-step2') {
          setLoading1(false);
          setDisableButton1(true);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.wepayRegistrationState === 'action-required') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.wepayRegistrationState === 'error') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
          displayAlert('There was an error with your request', 'warning');
        }
      }, 2000);
    } catch (error) {
      if (timerId) clearInterval(timerId);
      setLoading1(false);
      displayAlert('There was an error with your request', 'warning');
    }
  };

  //   const throttleFunc = throttle((val) => {
  //     let nameRes = [];
  //     let messageRes = [];
  //     if (val) {
  //       // Filter Pinned and archived
  //       const filtered = firstActivityRequest.feed.filter((f) => f.pinned === false && f.archived === false);
  //       nameRes = filtered.filter((f) => f.renterName.toUpperCase().includes(val.toUpperCase()));
  //       messageRes = filtered.filter((f) => f.message.toUpperCase().includes(val.toUpperCase()));
  //     }
  //     if (nameRes.length) nameRes = [...new Set(nameRes.map((item) => item.renterName))];
  //     setSuggestedResults({ nameRes, messageRes });
  //   }, 100);
  //
  //   const handleSuggestedSearch = (value) => {
  //     throttleFunc(value);
  //   };

  const handleClearSearch = async (e) => {
    const value = e.target.value;
    if (e.key === 'Enter' && value.length > 1) {
      const filtered = firstActivityRequest.feed.filter((f) => f.pinned === false && f.archived === false);
      const searchResult = filtered.filter(
        (f) =>
          f.renterName.toUpperCase().includes(value.toUpperCase()) ||
          f.message.toUpperCase().includes(value.toUpperCase())
      );
      setActivityFeed({
        count: searchResult.length,
        pinned: activityFeed.pinned,
        feed: searchResult,
      });
    }
    if (e.key === 'Backspace' && !value) {
      fetchActivityFeed();
    }
  };
  /*
  const handleOnSelect = (value, option) => {
    const filtered = firstActivityRequest.feed.filter((f) => f.pinned === false && f.archived === false);
    const searchResult = filtered.filter(
      (f) =>
        f.renterName.toUpperCase().includes(option.children[0].toUpperCase()) ||
        f.message.toUpperCase().includes(option.children[4].toUpperCase())
    );

    setActivityFeed({
      count: searchResult.length,
      pinned: firstActivityRequest.pinned,
      feed: searchResult,
    });
  };
  */

  const handleJoyrideCallback = async (data) => {
    const { action } = data;

    if (action === 'close' || action === 'skip') {
      dispatch({
        type: 'SET_SHOW_TOUR',
        payload: {
          showMainTour: false,
        },
      });
    }
  };

  // useEffect(() => {
  //   console.log('ƒ useEffect on landlord', landlord);
  //   if (landlord.merchantAccount.loading || landlord.units.loading) return;
  //   if (processor === 'Finix') {
  //     const unboarded =
  //       landlord.merchantAccount.status === 'not_started' || landlord.merchantAccount.status === null;

  //     if (unboarded && landlord.units.totalCount === 0) {
  //       history.push('/landlord/onboarding');
  //       dispatch({ type: 'SET_SELECTED_MENU', payload: 'onboarding' });
  //     }
  //   }
  // }, [
  //   landlord,
  //   dispatch,
  //   history,
  //   landlord.merchantAccount.loading,
  //   landlord.merchantAccount.status,
  //   landlord.units.loading,
  //   landlord.units.totalCount,
  //   processor,
  // ]);

  return (
    <>
      <StyledDrawer
        closable={false}
        // closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
        placement="right"
        destroyOnClose
        onClose={() => {
          props.setDrawerVisible(false);
          props.setShowOnboarding(false);
          props.setShowAccountProfile(false);
          props.setShowAccountPreferences(false);
          props.setShowPlansBillingPanel(false);
          props.setShowBillingDetailsPanel(false);
          setEditInvitationProperty(false);
          setShowTransactionPanel(false);
          setShowPropertyPanel(false);
          setShowInvitationPanel(false);
          form.resetFields();
          dispatch(setSummaryPaymentType(''));
          dispatch(unsetTransactionRenter());
        }}
        open={props.drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {props.drawerVisible && props.showOnboarding && (
          <Onboarding
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            checkLandlordStatus={checkLandlordStatus}
            updatePaymentGateway={updatePaymentGateway}
          />
        )}
        {props.drawerVisible && showTransactionPanel && (
          <TransactionPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
            fetchActivityFeed={fetchActivityFeed}
          />
        )}
        {props.drawerVisible && showPropertyPanel && (
          <PropertyPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPropertyPanel={setShowPropertyPanel}
            editProperty={editProperty}
            setEditProperty={setEditProperty}
            editInvitationProperty={editInvitationProperty}
            setShowInvitationPanel={setShowInvitationPanel}
          />
        )}
        {props.drawerVisible && showInvitationPanel && (
          <InvitationPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowInvitationPanel={setShowInvitationPanel}
            setEditProperty={setEditProperty}
            setShowPropertyPanel={setShowPropertyPanel}
            form={form}
            editInvitationProperty={editInvitationProperty}
            setEditInvitationProperty={setEditInvitationProperty}
            setShowTransactionPanel={setShowTransactionPanel}
          />
        )}
        {/*  Moved to LandlordMenu.js */}
        {/* {props.drawerVisible && props.showAccountProfile && (
          <AccountProfilePanel
            setShowAccountProfile={props.setShowAccountProfile}
            setDrawerVisible={props.setDrawerVisible}
          />
        )}
        {props.drawerVisible && props.showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowAccountPreferences={props.setShowAccountPreferences}
          />
        )}
        {props.drawerVisible && props.showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )}
        {props.drawerVisible && props.showBillingDetailsPanel && (
          <BillingDetailsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )} */}
        {props.drawerVisible && props.showUnitPanel && <UnitPanel />}
      </StyledDrawer>
      {/* Removed from UI 4.0.0 */}
      {/* <NewsPanel
        title="What's New @PayRent"
        placement="right"
        closable={true}
        onClose={() => {
          props.setNewsDrawerVisible(false);
        }}
        open={props.newsDrawerVisible}
        posts={false}
        isOpen={props.newsDrawerVisible}
      /> */}

      {showOnboardingFullscreen && (
        <OnboardingFullscreen
          showOnboardingFullscreen={showOnboardingFullscreen}
          setShowOnboardingFullscreen={setShowOnboardingFullscreen}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
          isDesktop={isDesktop}
          checkLandlordStatus={checkLandlordStatus}
          stripeTimer={stripeTimer}
        />
      )}
      <section className="screen screen--activity-feed" data-testid="dashboardTest">
        <Joyride
          callback={handleJoyrideCallback}
          // continuous
          // getHelpers={getHelpers}
          run={global.tour.showMainTour && !props.drawerVisible && !props.showOnboarding}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={dashboardSteps}
          styles={{
            options: {
              zIndex: 19,
            },
          }}
        />
        <KpiDashboard isDesktop={isDesktop} />

        <Space vertical={20} />
        {/* <Row
          gutter={[
            {
              xs: 8,
              sm: 6,
              md: 8,
              lg: 12,
              xl: 7,
              xxl: 10,
            },
            {
              xs: 10,
              sm: 6,
              md: 8,
              lg: 12,
              xl: 7,
              xxl: 10,
            },
          ]}
          justify="left"
        >
          <Col span={18} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 18 }} xl={{ span: 18 }}> */}
        <StyledDiv>
          <QuickAddMenu
            isDesktop={isDesktop}
            setDrawerVisible={setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
            setShowInvitationPanel={setShowInvitationPanel}
          />
          <AutoComplete
            className="auto-complete"
            // onChange={handleSuggestedSearch}
            // onSelect={handleOnSelect}
            onKeyUp={handleClearSearch}
            // onSearch={handleOnSearch}
            backfill
            defaultActiveFirstOption={false}
            placeholder={
              <>
                <SearchOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                <span className="ant-select-selection-placeholder">Search Activity</span>
              </>
            }
          >
            {/*suggestedResults.nameRes.map((name) => (
              <Option key={`${name}${Math.random()}`} value={`${name}`}>
                {name}
              </Option>
            ))*/}
            {/*suggestedResults.messageRes.map((item) => (
              <Option key={item.id} value={`${truncateString(item.message, 35, false)}`}>
                {truncateString(item.message, 35, true)}
              </Option>
            ))*/}
          </AutoComplete>
        </StyledDiv>

        <Space vertical={24} />
        <div ref={dashboardCardRef} id="anchor">
          <ActivityFeed
            activityFeed={activityFeed}
            filterActivityFeed={filterActivityFeed}
            feedLength={feedLength}
            setFeedLength={setFeedLength}
            fetchActivityFeed={fetchActivityFeed}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </section>
    </>
  );
};

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      height: 100%;
      width: 480px !important;
      @media screen and (max-width: 1500px) {
        width: 460px !important;
      }
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    input {
      color: #595959;
    }
    @media screen and (max-height: 750px) {
      .ant-steps-small .ant-steps-item-description {
        font-size: 12px !important;
      }
    }
    @media screen and (max-height: 750px) {
      .ant-drawer-body h1 {
        font-size: 35px !important;
      }
      .ant-form-item {
        margin-bottom: 15px !important;
      }import { PlusSquareOutlined } from '@ant-design/icons';

    }
  `}
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export default Dashboard;
