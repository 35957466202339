import React, { useEffect } from 'react';
import { Button, Card, Form, Input } from 'antd';
import styled from 'styled-components';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import { Link } from 'react-router-dom';
import AlertFade from 'components/AlertFade';

import Text from 'components/Text';
import Space from 'components/Space';

const ConfirmationForm = (props) => {
  const { updatePhoneNumber, setUpdatePhoneNumber, phone, email, setEmail } = props;

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log(values);
    values.phone = phone;
    values.oldEmail = email;
    if (!updatePhoneNumber) props.handleSubmit(values.code, values.email);
    if (updatePhoneNumber) {
      props.handleUpdatePhoneNumber(values);
      setEmail(values.email);
    }
  };

  useEffect(() => {
    const formValue = form.getFieldValue('email');
    if (formValue === '') {
      form.setFieldsValue({
        email: email,
      });
    }
  }, [email, form]);

  return (
    <>
      <Card style={{ maxWidth: '380px' }}>
        <h2 className="form-container__form-title" data-testid="h4Test" style={{ textAlign: 'center' }}>
          Confirm Sign Up
        </h2>
        {/*
      <Text size={16} color="black" centered>
        A confirmation code has been sent to your mobile device
      </Text>
      */}
        <Text size={16} color="black" centered>
          A confirmation code has been sent to your email
        </Text>
        <Space vertical={10} />
        <AlertFade
          type={props.alertData.type}
          isVisible={props.alertData.visible}
          message={props.alertData.message}
        />

        <Form
          onFinish={onFinish}
          className="auth-form"
          size="large"
          form={form}
          initialValues={{ email, code: '' }}
        >
          {!updatePhoneNumber && (
            <Form.Item
              name="email"
              // rules={[
              //   { required: true, message: 'This field is empty' },
              // ]}
              rules={[{ required: updatePhoneNumber, message: 'This field is required' }]}
            >
              <Input
                disabled={!updatePhoneNumber}
                // defaultValue={phone || 'No phone provided'}
                placeholder={email || 'No email provided'}
                data-testid="emailInputTest"
              />
            </Form.Item>
          )}

          {/*updatePhoneNumber && (
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please enter your phone number',
              },
              {
                // pattern: new RegExp('^[0-9]{6,}$'),
                // supports ten characters without + sign
                // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                message: 'Please enter only numbers - min length 10',
              },
              {
                message: 'Please input a valid phone number.',
                max: 16,
              },
            ]}
          >
            <MaskedInput
              className="masked-input"
              placeholder="111 111 1111"
              mask="000 000 0000"
              minLength={6}
              placeholderChar=" "
              data-testid="phoneTest"
              prefix="+1"
            />
          </Form.Item>
          )*/}
          {updatePhoneNumber && (
            <Form.Item
              name="email"
              whitespace="true"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not a valid email',
                },
                {
                  required: true,
                  message: 'Please enter your email',
                },
              ]}
            >
              <Input placeholder="Email (username)" defaultValue={email} data-testid="testEmail" />
            </Form.Item>
          )}

          <StyledFormItem
            name="code"
            whitespace="true"
            rules={[{ required: !updatePhoneNumber, message: 'This field is required' }]}
          >
            <Input
              placeholder="Confirmation code"
              data-testid="codeTest"
              autoComplete="new-password"
              disabled={updatePhoneNumber}
            />
          </StyledFormItem>

          <div className="auth-form__forgot-password">
            No code? &nbsp;
            <Text
              style={{ cursor: 'pointer' }}
              onClick={props.resendConfirmationCode}
              // type="link"
              // size="small"
              // className="button-link--small"
              data-testid="resendCodeTest"
            >
              Resend code
            </Text>
            &nbsp; or &nbsp;
            <Text
              style={{ cursor: 'pointer' }}
              onClick={() => setUpdatePhoneNumber(true)}
              // type="link"
              // size="small"
              // className="button-link--small"
              data-testid="resendCodeTest"
            >
              Change Email
            </Text>
          </div>
          <Space vertical={18} />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              className="form-container__button"
              icon={<CheckCircleOutlined />}
              loading={props.isLoading}
              disabled={props.isLoading}
              data-testid="confirmTest"
            >
              {updatePhoneNumber ? 'SAVE AND CONFIRM' : 'CONFIRM'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Space vertical={12} />
      <div className="form-container__botton-text">
        <Link to="/" data-testid="backSignTest">
          Back to Sign In
        </Link>
      </div>
    </>
  );
};

const StyledFormItem = styled(Form.Item)`
  @media screen and (max-width: 435px) {
    margin-bottom: 10px;
  }
`;

export default ConfirmationForm;
