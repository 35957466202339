import moment from 'moment';

const getCardType = (number) => {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) {
    return 'Visa';
  }
  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  ) {
    return 'Mastercard';
  }
  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) {
    return 'AmericanExpress';
  }
  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) != null) {
    return 'Discover';
  }
  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null) {
    return 'DinersClub';
  }
  re = new RegExp('^38');
  if (number.match(re) != null) {
    return 'DinersClub';
  }
  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) != null) {
    return 'DinersClub';
  }
  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) != null) return 'JCB';

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) != null) return 'Visa Electron';

  return '';
};

const getLastFour = (number) => {
  const foo = number;
  const limit = 4;
  const tail = foo.slice(-limit);
  return tail;
};

export const addACHMethod = (email, disableMicrodeposits) => {
  return new Promise((resolve, reject) => {
    const response = window.WePay.bank_account.create(
      {
        email,
        client_id: process.env.REACT_APP_WEPAY_CLIENT,
        options: {
          avoidMicrodeposits: disableMicrodeposits || false,
        },
      },
      (data) => {
        console.log('wepay response: ', data);
        let dataObj;
        try {
          dataObj = JSON.parse(data);
        } catch (err) {
          console.log('%cerr', 'background: purple; color: #fff; font-size: 18px');
          console.log(err);
          // const error = new ClientException(
          //   'Something went wrong with adding your payment method. ERROR_CODE: 108',
          // );
          // reject(error);
          reject();
          return;
        }

        // Prevents wepay bug when left open and not closed during
        // WePay.credit_card.create still trigger bank_account callback for some reason
        if (dataObj.response && dataObj.response.credit_card_id) {
          resolve(null);
          return;
        }

        if (dataObj.error) {
          console.log('%cdataObj.error', 'background: purple; color: #fff; font-size: 18px');
          console.log(dataObj.error);

          // Window closed don't propagate as error
          if (dataObj.error_code === 1003) {
            resolve(null);
            return;
          }

          // const err = new ClientException(
          //   dataObj.error_description,
          // );
          // reject(err);
          reject();
        } else {
          const paymentMethod = {
            label: `${dataObj.bank_account_name} (${dataObj.bank_account_last_four})`,
            defaultMethod: false,
            paymentGateway: 'Wepay',
            paymentMethodType: 'ACH',
            flow: dataObj.flow,
            metaData: {
              gatewayId: `${dataObj.bank_account_id}`,
              TOKENIZATION: dataObj,
            },
          };

          resolve(paymentMethod);
        }
      }
    );

    if (response.error) {
      console.log('%cresponse', 'background: purple; color: #fff; font-size: 18px');
      console.log(response);
      // const err = new ClientException(response.error_description);
      // reject(err);
      reject();
    }
  });
};

export const addCardMethod = (userEmail, values) => {
  return new Promise((resolve, reject) => {
    const { userName, cardNumber, cvv, postalCode, expirationDate } = values;
    const cardType = getCardType(cardNumber);
    const lastFour = getLastFour(cardNumber);
    const params = {
      email: userEmail,
      client_id: process.env.REACT_APP_WEPAY_CLIENT,
      user_name: userName,
      cc_number: cardNumber,
      cvv: cvv,
      expiration_month: moment(expirationDate).format('MM'),
      expiration_year: moment(expirationDate).format('YYYY'),
      address: {
        postal_code: postalCode,
      },
    };

    const response = window.WePay.credit_card.create(params, (data) => {
      if (data.error) {
        reject(data.error_description);
      } else {
        const paymentMethod = {
          label: `${cardType} xxxxxx${lastFour}`,
          defaultMethod: false,
          paymentGateway: 'Wepay',
          paymentMethodType: 'Card',
          metaData: {
            gatewayId: data.credit_card_id, // must be integer or will fail
            TOKENIZATION: data,
            // user_name: userName,
          },
        };

        resolve(paymentMethod);
      }
    });
    if (response.error) {
      reject(response.error_description);
    }
  });
};
