import { Result } from 'antd';
import React from 'react';

export default function Error403(props) {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result
        status="403"
        title="Access Denied"
        subTitle={props.message || 'Your access to this page is forbidden. Please contact customer support.'}
      />
    </div>
  );
}
