import React, { useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import * as LandlordApi from 'services/api/landlordApi/create';
import { globalDisplayAlert } from 'resources/helpers';

function CloneDocument(props) {
  // console.log('CloneDocument props', props);
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const cognitoToken = session.cognitoTokens?.idToken.jwtToken;
  const actionDisabled = !session.userData?.servicePlan?.metaFeatures.dOCUMENTStorage;
  const qc = useQueryClient();

  const handleCloneTemplate = async () => {
    // console.log('ƒ handleCloneDocument', props.record);

    try {
      setLoading(true);
      const { key, ...rest } = props.record;

      await LandlordApi.cloneDocument(
        cognitoToken,
        { ...rest, id: uuidv4(), label: `${rest.label} Copy`, isTemplate: false },
        key
      );
      qc.invalidateQueries(['documents']);
      setLoading(false);
      globalDisplayAlert(dispatch, '', 'success', 'Template successfully cloned');
    } catch (err) {
      console.error('ƒ handleCloneDocument error', err.data.errors);
      setLoading(false);
      globalDisplayAlert(dispatch, '', 'error', 'Error cloning template');
    }
  };

  return (
    <IconButton
      toolTitle="Clone Template"
      icon={<CopyOutlined />}
      handleClick={handleCloneTemplate}
      disabled={actionDisabled}
      loading={loading}
    />
  );
}

export default CloneDocument;
