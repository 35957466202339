import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Input, Row, Col, Select, Checkbox, Tooltip, Spin } from 'antd';
import parser from 'parse-address';
import { usStates } from 'resources/FormData';
import { v4 } from 'uuid';

import { updatePropertyGroup } from 'services/api/landlordApi/update';
import { resetPropertiesPreferences } from 'services/api/landlordApi/create';

import { getServicePlanAction } from 'store/actions/sessionActions';

import { numberTOrdinalSuffix } from 'resources/helpers';

import { CloseSquareFilled, InfoCircleFilled } from '@ant-design/icons';

import PanelFooter from 'components/Panel/PanelFooter';
import SpaceResponsive from 'components/SpaceResponsive';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import Button from 'components/Button';
import Text from 'components/Text';
import AdditionalFeesFields from '../AdditionalFeesFields/AdditionalFeesFields';

import PropertyRentPolicy from '../PropertyRentPolicy/PropertyRentPolicy';
import MerchantAccountDetails from './components/MerchantAccountDetails';

// import './styles.scss';

import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../../components/Panel/PanelHeader/PanelHeader';
import PremiumFeaturePopover from 'components/uielements/PremiumFeaturePopover/PremiumFeaturePopover';

const PortfolioSettingsPanel = (props) => {
  // console.log('[PortfolioSettingsPanel.js]', props);
  const {
    setShowPortfolioSettings,
    setDrawerVisible,
    setDrawerExpanded,
    propertyGroup,
    fetchPropertyGroups,
    setShowAcceptPayments,
    setShowPaymentDetails,
    setOnboardingState,
    onboardingState,
    onboardingStatusParser,
  } = props;

  // console.log(onboardingState);

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const dispatch = useDispatch();

  const [message, setMessage] = useState({
    type: 'success',
    message: '',
    description: '',
    closable: false,
    marginBottom: 0,
  });

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [formDays, setFormDays] = useState([]);
  const [locationData, setLocationData] = useState(null);
  const [addressInput, setAddressInput] = useState('');
  const [googleString, setGoogleString] = useState(null);
  const googleSelected = useRef(null);
  const customAddressInput = useRef(null);
  const [resetting, setResetting] = useState(false);
  const additionalAssessmentsEnabled = session.userData.servicePlan.metaFeatures.aDDITIONALAssessments;
  const renterFeesEnabled = session.userData.servicePlan.metaFeatures.rENTERPAYSFees;

  useEffect(() => {
    datesArray();
    fetchAndSetData();
    dispatch(getServicePlanAction(cognitoToken));
  }, []);

  const fetchAndSetData = async () => {
    try {
      if (propertyGroup.length) {
        const fields = {
          portfolioName: propertyGroup[0].name,
          acceptCreditCard: propertyGroup[0].settings.acceptCreditCard,
          absorbFees: propertyGroup[0].settings.absorbFees,
          fullPayment: propertyGroup[0].settings.requireFullPayment,
          preventPayment: propertyGroup[0].settings.preventPayments,
          complianceLevel: propertyGroup[0].settings.complianceLevel,
          securityDepositAmount: propertyGroup[0].settings.propertyFees.securityDepositAmount,
          securityDepositSwitch: propertyGroup[0].settings.propertyFees.securityDeposit,
          discountAmountEarly: propertyGroup[0].settings.propertyFees.discountAmount,
          daysEarly: propertyGroup[0].settings.propertyFees.discountDays,
          daysEarlySwitch: propertyGroup[0].settings.propertyFees.discount,
          lateFeeAmount: propertyGroup[0].settings.propertyFees.lateFeeAmount,
          daysLateFee: propertyGroup[0].settings.propertyFees.lateFeeDays,
          daysLateSwitch: propertyGroup[0].settings.propertyFees.lateFee,
          parkingFeeAmount: propertyGroup[0].settings.propertyFees.parkingAmount,
          percent: propertyGroup[0].settings.absorbFeesPercent,
          periodParkingFee: propertyGroup[0].settings.propertyFees.parkingFrequency,
          parkingFeeSwitch: propertyGroup[0].settings.propertyFees.parking,
          moveInFee: propertyGroup[0].settings.propertyFees.moveInAmount,
          moveInFeeSwitch: propertyGroup[0].settings.propertyFees.moveIn,
          moveOutFee: propertyGroup[0].settings.propertyFees.moveOutAmount,
          moveOutFeeSwitch: propertyGroup[0].settings.propertyFees.moveOut,
        };
        form.setFieldsValue(fields);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayAlert = (text, type, description, time, close, marginBottom) => {
    if (message.message) {
      setMessage({
        message: '',
        description: '',
      });
    }
    setMessage({
      message: text,
      type,
      description,
      closable: !close,
      marginBottom,
    });
    if (close) {
      setTimeout(() => {
        setMessage({
          message: '',
          description: '',
          marginBottom: 0,
        });
      }, time);
    }
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      await updatePropertyGroup(cognitoToken, propertyGroup[0].id, values);
      await fetchPropertyGroups(true);
      setIsLoading(false);
      displayAlert('Portfolio successfully edited', 'success', '', 3000, true);
      setTimeout(() => {
        setDrawerVisible(false);
        setShowPortfolioSettings(false);
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.title;
      displayAlert(detail || 'Request failed.', 'warning', '', 3000, true);
    }
  };

  const onValuesChange = (values) => {
    if (values.securityDepositAmount) {
      if (isNaN(values.securityDepositAmount)) {
        form.setFieldsValue({ securityDepositAmount: '' });
      }
    }
    if (values.discountAmountEarly) {
      if (isNaN(values.discountAmountEarly)) {
        form.setFieldsValue({ discountAmountEarly: '' });
      }
    }
    if (values.daysEarly) {
      if (isNaN(values.daysEarly)) {
        form.setFieldsValue({ daysEarly: '' });
      }
    }
    if (values.lateFeeAmount) {
      if (isNaN(values.lateFeeAmount)) {
        form.setFieldsValue({ lateFeeAmount: '' });
      }
    }
    if (values.daysLateFee) {
      if (isNaN(values.daysLateFee)) {
        form.setFieldsValue({ daysLateFee: '' });
      }
    }
    if (values.parkingFeeAmount) {
      if (isNaN(values.parkingFeeAmount)) {
        form.setFieldsValue({ parkingFeeAmount: '' });
      }
    }
    if (values.moveInFee) {
      if (isNaN(values.moveInFee)) {
        form.setFieldsValue({ moveInFee: '' });
      }
    }
    if (values.moveOutFee) {
      if (isNaN(values.moveOutFee)) {
        form.setFieldsValue({ moveOutFee: '' });
      }
    }
    // Switchs
    if (values?.securityDepositSwitch === false) {
      form.resetFields(['securityDepositAmount']);
    }
    if (values?.daysEarlySwitch === false) {
      form.resetFields(['discountAmountEarly', 'daysEarly']);
    }

    if (values?.daysLateSwitch === false) {
      if (form.getFieldValue('complianceLevel') === 0) {
        form.setFieldsValue({ daysLateSwitch: false });
        form.resetFields(['lateFeeAmount', 'daysLateFee', 'lateFeeFrequency']);
      } else {
        form.setFieldsValue({ daysLateSwitch: true });
      }
    }

    if (values?.parkingFeeSwitch === false) {
      form.resetFields(['parkingFeeAmount', 'periodParkingFee']);
    }
    if (values?.moveInFeeSwitch === false) {
      form.resetFields(['moveInFee']);
    }
    if (values?.moveOutFeeSwitch === false) {
      form.resetFields(['moveOutFee']);
    }
    if (values?.complianceLevel) {
      form.setFieldsValue({ daysLateSwitch: true });
    }
    if (values?.hasOwnProperty('complianceLevel')) {
      if (!values.complianceLevel) {
        form.setFieldsValue({ daysLateSwitch: false });
        form.resetFields(['lateFeeAmount', 'daysLateFee', 'lateFeeFrequency']);
      }
    }
  };

  const datesArray = () => {
    let days = [];
    for (let i = 1; i <= 31; i += 1) {
      days[i] = {
        label: `${numberTOrdinalSuffix(i)} of the month`,
        value: i,
      };
    }
    setFormDays(days);
  };

  // const checkPrice = (rule, value) => {
  //   if (Number(value) > 0) {
  //     return Promise.resolve();
  //   }
  //   return Promise.reject('Payment amount must be more than $0.00');
  // };

  const checkPercentage = (rule, value) => {
    if (form.getFieldValue('absorbFees')) {
      if (Number(value) <= 100 && Number(value) > 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Please enter a valid percentage.'));
    }
    return Promise.resolve();
  };

  const check = (obj, arr) => {
    return arr.every((e) => Object.keys(obj).includes(e));
  };

  useEffect(() => {
    if (!addressInput && (googleSelected.current || customAddressInput.current)) {
      setLocationData(null);
      googleSelected.current = false;
      customAddressInput.current = false;
      form.validateFields(['address']);
    }
    if (addressInput && addressInput !== googleString) {
      const addr = parser.parseLocation(addressInput);

      if (addr) {
        const validKeyNames = ['city', 'number', 'state', 'street', 'zip'];

        const validAddr = check(addr, validKeyNames);
        if (validAddr) {
          console.log(addr);
          const usState = usStates.filter((s) => s.abbreviation === addr.state.toUpperCase());
          const customAddress = {
            streetNumber: addr.number,
            address: addr.type ? `${addr.street} ${addr.type}` : addr.street,
            city: addr.city,
            state: usState[0].name.charAt(0).toUpperCase() + usState[0].name.slice(1).toLowerCase(),
            google_place_id: v4(),
            lat: 0,
            long: 0,
            zip: addr.zip,
            country: 'United States',
          };
          setLocationData({ ...customAddress });
          customAddressInput.current = true;
        } else {
          customAddressInput.current = false;
          setLocationData(null);
          form.validateFields(['address']);
        }
      }
    }
  }, [addressInput]);

  useEffect(() => {
    if (customAddressInput.current || googleSelected.current) {
      form.validateFields(['address']);
    }
  }, [locationData]);

  const handleResetProperties = async () => {
    try {
      setResetting(true);
      await resetPropertiesPreferences(cognitoToken, propertyGroup[0].id);
      setResetting(false);
      displayAlert('Properties successfully reset', 'success', '', 3000, true);
    } catch (error) {
      console.log(error);
      setResetting(false);
      const detail = error?.data?.errors[0]?.title;
      displayAlert(detail || 'Request failed.', 'warning', '', 3000, true);
    }
  };

  const handleDrawerReset = () => {
    setShowPortfolioSettings(false);
    setDrawerVisible(false);
    setDrawerExpanded(false);
  };

  return (
    <>
      <DrawerContainer data-testid="propertiesContainerTest">
        <StyledForm
          form={form}
          name="basic"
          initialValues={{
            percent: 100,
            acceptCreditCard: true,
            absorbFees: true,
            securityDepositSwitch: false,
            daysEarlySwitch: false,
            daysLateSwitch: false,
            parkingFeeSwitch: false,
            moveInFeeSwitch: false,
            moveOutFeeSwitch: false,
          }}
          onFinish={onFinish}
          onFinishFailed={() => {}}
          onValuesChange={onValuesChange}
        >
          <Header>
            <CloseSquareFilled
              style={{ fontSize: '25px', color: '#122C34' }}
              onClick={handleDrawerReset}
              data-testid="closePropertiesTest"
            />
          </Header>
          <Title>Portfolio Settings</Title>

          <Subtitle>Edit your portfolio settings</Subtitle>
          <SpaceResponsive lg={10} md={10} sm={10} />
          <FormContentContainer className="content-container">
            Portfolio Name
            <Form.Item name="portfolioName">
              <Input placeholder="My Portfolio" />
            </Form.Item>
            <Text color="black" strong>
              Merchant Account Details
            </Text>
            <SpaceResponsive lg={10} md={10} sm={10} />
            <MerchantAccountDetails
              propertyGroup={propertyGroup}
              setShowPortfolioSettings={setShowPortfolioSettings}
              setDrawerExpanded={setDrawerExpanded}
              setShowAcceptPayments={setShowAcceptPayments}
              setShowPaymentDetails={setShowPaymentDetails}
              setOnboardingState={setOnboardingState}
              onboardingState={onboardingState}
              onboardingStatusParser={onboardingStatusParser}
            />
            <Space vertical={20} />
            <Text color="black" strong>
              Default Property Settings
            </Text>
            <PaymentControlsContainer>
              <Form.Item
                name="acceptCreditCard"
                valuePropName="checked"
                // getValueProps={() => creditCardPayments}
                style={{ marginBottom: '10px !important' }}
              >
                <Checkbox>
                  <Text color="#595959">Accept credit card payments</Text>
                  <Tooltip
                    placement="top"
                    title="Choose to allow renters to pay rent with a credit card. Renters pay these fees."
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ccTooltip" />
                  </Tooltip>
                </Checkbox>
              </Form.Item>

              <Row>
                <Col span={6} className="absorbField">
                  <PremiumFeaturePopover
                    cta="Split or pass-through ACH transaction fees."
                    showPopover={!renterFeesEnabled}
                    handleDrawerReset={handleDrawerReset}
                  >
                    <Form.Item name="absorbFees" valuePropName="checked">
                      <Checkbox disabled={!renterFeesEnabled}>
                        <Text color="#595959">Absorb</Text>
                      </Checkbox>
                    </Form.Item>
                  </PremiumFeaturePopover>
                </Col>
                <Col span={16}>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) => prevValues.absorbFees !== curValues.absorbFees}
                    noStyle
                  >
                    {() => {
                      return (
                        <Form.Item>
                          <Form.Item
                            name="percent"
                            noStyle
                            rules={[
                              {
                                validator: checkPercentage,
                              },
                            ]}
                          >
                            <Input
                              disabled={!renterFeesEnabled}
                              style={{ width: '23%' }}
                              data-testid="absorbPercentTest"
                            />
                          </Form.Item>
                          <span>&nbsp;&nbsp;percent of ACH fees</span>
                          <Tooltip
                            placement="top"
                            title="Absorb, split or pass-through ACH transaction fees. If selected, you are paying some percentage of ACH fees."
                            trigger="click"
                          >
                            <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="absorbFeeTooltip" />
                          </Tooltip>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="fullPayment" valuePropName="checked">
                <Checkbox>
                  <Text color="#595959">Require full payment</Text>
                  <Tooltip
                    placement="top"
                    title="If selected, renters must pay their full account balance. If their account balance is zero, they cannot make a payment"
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="fullPaymentTooltip" />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
              <Form.Item name="preventPayment" valuePropName="checked">
                <Checkbox>
                  <Text color="#595959">Prevent payment</Text>
                  <Tooltip
                    placement="top"
                    title="If selected, renters are prevented from making any payments."
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
            </PaymentControlsContainer>
            <Space vertical={20} />
            <PropertyRentPolicy
              servicePlan={session.userData.servicePlan}
              setShowAccountPreferences={props.setShowPropertyPanel}
              setDrawerVisible={props.setDrawerVisible}
            />
            <Text strong color="black">
              Additional Assessments
            </Text>
            <Tooltip
              placement="top"
              title="These are additional billing items automatically posted to a renters account based on the rules you set below."
              trigger="click"
            >
              <InfoCircleFilled style={{ marginLeft: '8px' }} />
            </Tooltip>
            <PaymentControlsContainer>
              {/* {!session.userData.servicePlan.metaFeatures.aDDITIONALAssessments ? (
                <AdvancedFeaturesOverlay
                  setDrawerVisible={props.setDrawerVisible}
                  setShowAccountPreferences={props.setShowPropertyPanel}
                />
              ) : (
                ''
              )} */}
              <AdditionalFeesFields
                form={form}
                additionalAssessmentsEnabled={additionalAssessmentsEnabled}
                handleDrawerReset={handleDrawerReset}
              />
            </PaymentControlsContainer>
            <Space vertical={24} />
            <Spin spinning={resetting}>
              <Button type="default" onClick={handleResetProperties}>
                Reset all portfolio properties to these settings
              </Button>
            </Spin>
          </FormContentContainer>
          <PanelFooter>
            <AlertFade
              type={message.type}
              isVisible={!!message.message}
              message={message.message}
              description={message.description}
              alertPosition="absolute"
              position="absolute"
              minHeight="40"
              closable={message.closable}
              marginBottom={message.marginBottom}
              // width={90}
              bottom={82}
            />

            <SaveItem className="save-form-item">
              <Button
                type="primary"
                htmlType="submit"
                bottom={0}
                color="violet"
                loading={isLoading}
                alignment="center"
                data-testid="buttonTest"
              >
                SAVE
              </Button>
            </SaveItem>
          </PanelFooter>
        </StyledForm>
      </DrawerContainer>
    </>
  );
};

const DrawerContainer = styled.div`
  margin: 0px auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  border-radius: 24px;
  .general-settings.ant-row .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-input,
  .ant-select-selection-item,
  input {
    font-size: 16px;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-select-selector {
    height: 31px !important;
    color: #595959;
  }
  .rent-policy-select .ant-select-selector {
    height: 50px !important;
  }

  @media screen and (max-height: 750px) {
    min-height: 96%;
    .ant-form-item.save-form-item {
      margin-bottom: 0px !important;
    }
    .content-container .ant-form-item {
      margin-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    .ant-picker {
      width: 150px;
    }
    .ant-col.ant-col-5.absorbField {
      flex: 0 0 27%;
      max-width: 30%;
    }
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  @media screen and (max-height: 750px) {
    margin-bottom: 0px !important;
  }
`;

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 25px 0px 25px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const StyledDiv = styled.div`
  position: relative;
  height: 40px;
`;

const PaymentControlsContainer = styled.div`
  margin-top: 10px;
  position: relative;
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

export default PortfolioSettingsPanel;
