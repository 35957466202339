import React from 'react';
import { Card } from 'antd';
import Avatar from 'components/Avatar';
import Tag from 'components/Tag';
import Text from 'components/Text';

const { Meta } = Card;

const TenantDetails = (props) => {
  const { invitedRenter, loading } = props;

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <Card loading={loading} bordered={false}>
                <Meta
                  avatar={
                    <Avatar size={96} className="metaStyles">
                      {invitedRenter[0].name.split(' ')[0].charAt(0)}
                      {invitedRenter[0].name.split(' ').length > 1 &&
                        invitedRenter[0].name.split(' ')[1].charAt(0)}
                    </Avatar>
                  }
                  title={
                    <>
                      <Tag color="" style={{ fontWeight: 400 }}>
                        Invited
                      </Tag>
                      <br />

                      {`${invitedRenter[0].name}`}
                    </>
                  }
                  description={
                    <>
                      Email:{' '}
                      <Text color="violet" underline pointer size={14}>
                        <a href={`mailto:${invitedRenter[0].email}`}>{invitedRenter[0].email}</a>
                      </Text>
                      <br /> Mobile:{' '}
                      <Text color="violet" underline pointer size={14}>
                        {invitedRenter[0].phone}
                      </Text>
                    </>
                  }
                />
              </Card>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TenantDetails;
