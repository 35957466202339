import React from 'react';
import styled from 'styled-components';

import { InfoCircleFilled } from '@ant-design/icons';
import { Form, Row, Col, Select, Input, DatePicker, Tooltip } from 'antd';
import Text from 'components/Text';
import Space from 'components/Space';

import moment from 'moment';

import MaskedInput from 'antd-mask-input';
import { usStates } from 'resources/FormData';

import { checkZipCode, checkCity, checkAddressValidator } from 'resources/FormValidators';

const { Option } = Select;

const GeneralFields = (props) => {
  const { businessRoles, loadingRoles, showBusinessFields } = props;
  return (
    <div style={{ display: showBusinessFields ? 'none' : '' }}>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text size={16} color="black">
            All financial institutions are required by federal law to obtain, verify, and record information about
            you, including your name, address, and date of birth. We may also ask to see your driver&apos;s license
            or other identifying documents.
          </Text>
        </Col>
      </Row>
      <Space vertical={25} />
      <Row gutter={15}>
        <Col span={12}>
          Business Type
          <Form.Item
            name="ownership"
            data-testid="firstNameTest"
            rules={[{ required: true, message: 'Please select your ownership type', whitespace: true }]}
          >
            <Select placeholder="Select Type">
              <Option value="Sole Proprietorship">Sole Proprietorship</Option>
              <Option value="LLC">LLC</Option>
              <Option value="Corporation">Corporation</Option>
              <Option value="Non-Profit">Non Profit</Option>
              <Option value="Government">Goverment Organization</Option>
              <Option value="Partnership">Partnership</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          Business Role
          <Form.Item
            name="businessRole"
            rules={[{ required: true, message: 'Please select the business role', whitespace: true }]}
          >
            <Select placeholder="Select Role" loading={loadingRoles}>
              {businessRoles.map((br) => (
                <Option value={br.name} key={br.uuid}>
                  {br.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          First Name
          <Form.Item
            name="firstName"
            data-testid="firstNameTest"
            rules={[{ required: true, message: 'Please input your first name', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          Last Name
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Please input your last name', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          Home Address
          <Form.Item
            rules={[
              { required: true, message: 'Please input your address', whitespace: true },
              {
                pattern: new RegExp(/(?!^\d+$)^.+$/),
                message: 'Address must be numbers and letters',
              },
              {
                max: 100,
                message: 'Address cant be more than 100 characters',
              },
              {
                validator: checkAddressValidator,
              },
            ]}
            name="homeAddress"
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={10}>
          Zip Code
          <Form.Item
            name="zipCode"
            rules={[
              {
                validator: checkZipCode,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          City
          <Form.Item
            name="city"
            data-testid="cityTest"
            rules={[
              {
                validator: checkCity,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={10}>
          State
          <Form.Item
            name="state"
            data-testid="stateTest"
            rules={[{ required: true, message: 'Please select a state' }]}
          >
            <Select placeholder="Select State" showSearch onChange={() => {}}>
              {usStates.map((s, i) => (
                <Option value={s.abbreviation} key={i}>
                  {s.abbreviation}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={14}>
          Email Address
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not a valid email',
              },
              {
                required: true,
                message: 'Please enter your email',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          Mobile Phone
          <Form.Item
            name="mobilePhone"
            rules={[
              {
                required: true,
                message: 'Please enter your phone number',
              },
              {
                // pattern: new RegExp('^[0-9]{6,}$'),
                // supports ten characters without + sign
                // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                message: 'Please enter only numbers - min length 10',
              },
              {
                message: 'Please input a valid phone number.',
                max: 12,
              },
            ]}
          >
            <MaskedInput
              className="masked-input"
              placeholder="111 111 1111"
              mask="000 000 0000"
              minLength={6}
              placeholderChar=" "
              data-testid="phoneTest"
              prefix="+1"
            />
          </Form.Item>
        </Col>
      </Row>
      Date of Birth
      <Form.Item
        name="dateOfBirth"
        data-testid="dateOfBirthTest"
        rules={[{ required: true, message: 'Please select your date of birth.' }]}
      >
        <DatePicker
          format="MM/DD/YYYY"
          placeholder="mm/dd/yyyy"
          disabledDate={(current) => current && current > moment().subtract(18, 'years')}
          defaultPickerValue={moment().subtract(18, 'years')}
          style={{ width: '100%' }}
          data-testid="datePickerTest"
        />
      </Form.Item>
      <Row gutter={10}>
        <Col span={12}>
          <SsnContainer>
            SSN or ITIN{' '}
            <Tooltip
              placement="top"
              title="US Federal regulations require us to obtain your Social Security Number to confirm your identity. This will not impact your credit."
              trigger="click"
            >
              <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ssnTooltip" />
            </Tooltip>
          </SsnContainer>
          <Form.Item
            name="ssn"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (value) {
                    const number = value.replace(/-|_/g, '').trim();
                    if (number.length === 9) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Ssn must be nine digits');
                  }
                  return Promise.reject('Please input your SSN');
                },
              }),
            ]}
          >
            <MaskedInput
              className="masked-input"
              placeholder="111-11-1111"
              mask="000-00-0000"
              minLength={11}
              placeholderChar=" "
              data-testid="maskedInputSsn"
            />
          </Form.Item>
          <div id="emptyssn" style={{ position: 'absolute' }} key="3">
            <div className="form-control-static">
              <StyledSpan className="fake-input"></StyledSpan>
            </div>
          </div>
        </Col>
        <Col span={12}>
          Re-enter SSN
          <Form.Item
            name="ssnItinConfirm"
            dependencies={['ssn']}
            rules={[
              {
                required: true,
                message: 'Please input your SSN',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('ssn') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two SSN that you entered do not match');
                },
              }),
            ]}
          >
            <MaskedInput
              className="masked-input"
              placeholder="111-11-1111"
              mask="000-00-0000"
              minLength={6}
              placeholderChar=" "
              data-testid="maskedInputTest"
            />
          </Form.Item>
        </Col>
      </Row>
      {/**
       
       <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership} noStyle>
        {() => {
          return form.getFieldValue('ownership') && form.getFieldValue('ownership') !== 'Sole Proprietorship' ? (
            <Button
              alignment="center"
              color="violet"
              height={35}
              bottom={0}
              padding1={5}
              padding2={20}
              onClick={() => setShowBusinessFields(true)}
              disabled={enableContinue}
            >
              CONTINUE
            </Button>
          ) : (
            ''
          );
        }}
      </Form.Item>
       
       */}
    </div>
  );
};

const StyledSpan = styled.span`
  iframe {
    height: 32px;
    width: 98%;
  }
`;

const SsnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export default GeneralFields;
