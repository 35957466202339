import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import moment from 'moment';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import { usStates } from 'resources/FormData';

import { purchaseInsurance } from 'services/api/tenantApi';

import { Col, Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import PanelFooter from 'components/Panel/PanelFooter';
import { sendSibEvent } from 'utils/sendInBlue';

// import './styles.scss';

const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '14px',
          color: '#000000',
          fontFamily: 'Montserrat, Poppins, sans-serif',
          '::placeholder': {
            color: '#979696',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};

const Checkout = (props) => {
  const {
    quoteResponse,
    quoteForm,
    historyForm,
    setShowCheckout,
    setShowConfirmation,
    fetchInsurancePolicy,
    paymentCadence,
    setInsuranceDrawer,
    setInsuranceStepsVisible,
    processingPayment,
    setProcessingPayment,
  } = props;
  const session = useSelector((store) => store.session);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [message, setMessage] = useState({ message: '', type: 'success' });

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setProcessingPayment(true);
      const cognitoToken = session.cognitoTokens.idToken.jwtToken;

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        setProcessingPayment(false);
        return;
      }

      const card = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(card);

      if (result.error) {
        // Show error to your customer.
        console.log(result.error.message);
        throw Error(result.error.message);
      } else {
        // Send the token to your server.
        // This function does not exist yet; we will define it in the next step.
        const stateShort = usStates.filter((s) => s.name === session.userData.property.state.toUpperCase());
        const values = {
          quoteId: quoteResponse.quoteId,
          planId: quoteResponse.planId,
          paymentCadence,
          email: session.userData.email,
          address: session.userData.property.address,
          unit: session.userData.property.address2,
          city: session.userData.property.city,
          state: stateShort[0].abbreviation,
          zip: session.userData.property.zip,
          tenantName: session.userData.firstName,
          tenantLastName: session.userData.lastName,
          tenantPhone: session.userData.phone,
          ...historyForm,
          ...quoteForm,
        };

        await purchaseInsurance(cognitoToken, result.token.id, values);
        await sendSibEvent('insurance_purchased', { EMAIL: session.userData.email });
        setMessage({ message: 'Successful payment', type: 'success' });

        setTimeout(async () => {
          await fetchInsurancePolicy();
          setProcessingPayment(false);
          setShowCheckout(false);
          setShowConfirmation(true);
        }, 4000);
      }
    } catch (error) {
      if (typeof error === 'string') {
        if (error.includes('timeout')) {
          await fetchInsurancePolicy();
          setProcessingPayment(false);
          setMessage({
            message: 'Request timed out. Please check your connection and try again.',
            type: 'warning',
          });
          setTimeout(() => {
            setShowCheckout(false);
            setInsuranceDrawer(false);
            setInsuranceStepsVisible(false);
            setMessage({ ...message, message: '' });
          }, 4000);
        }
      } else {
        setMessage({
          message: error?.data?.errors[0]?.detail || 'There was a problem with your request',
          type: 'warning',
        });
        setProcessingPayment(false);
        setTimeout(() => {
          setMessage({ ...message, message: '' });
        }, 4000);
      }
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', height: 'calc(100% - 200px)', display: 'flex', flexDirection: 'column' }}
        className="stripe-form"
      >
        <StyledContainer className="content-container">
          <Row justify="center">
            <Col span={20}>
              <SummaryContainer>
                <Text color="black" size={14} strong>
                  Your Custom Plan Summary:
                </Text>
                <Space vertical={15} />
                <Text color="black" size="12" strong>
                  Property Coverage:
                </Text>
                <Text color="black" size="12">
                  {' '}
                  ${quoteResponse?.settings?.personalPropertyCoverage}
                </Text>
                <br />
                <Text color="black" size="12" strong>
                  Deductible:
                </Text>
                <Text color="black" size="12">
                  {' '}
                  ${quoteResponse?.settings?.allPerilDeductible}
                </Text>
                <br />
                <Text color="black" size="12" strong>
                  Liability Amount:
                </Text>
                <Text color="black" size="12">
                  {' '}
                  ${quoteResponse?.settings?.liabilityLimit}
                </Text>
                <br />
                <Text color="black" size="12" strong>
                  Additional Coverage:
                </Text>
                <br />
                {quoteResponse?.settings?.includeIdentityFraud && (
                  <>
                    <Text color="black" size="12" margin="0px 0px 0px 10px">
                      {' '}
                      Identify Theft
                    </Text>
                    <br />
                  </>
                )}
                {quoteResponse?.settings?.includeReplacementCost && (
                  <>
                    <Text color="black" size="12" margin="0px 0px 0px 10px">
                      {' '}
                      Replacement Cost
                    </Text>
                    <br />
                  </>
                )}
                {quoteResponse?.settings?.includeEarthquake && (
                  <>
                    <Text color="black" size="12" margin="0px 0px 0px 10px">
                      {' '}
                      Earthquake coverage
                    </Text>
                    <br />
                  </>
                )}

                {quoteResponse?.settings?.includeWaterBackup && (
                  <>
                    <Text color="black" size="12" margin="0px 0px 0px 10px">
                      {' '}
                      Sewer or Drain Backup
                    </Text>
                    <br />
                  </>
                )}

                <Text color="black" size="12" strong>
                  Start Date:
                </Text>
                <Text color="black" size="12">
                  {' '}
                  {moment().format('ll')}
                </Text>
                <br />
                <Text color="black" size="12" strong>
                  Billing Cycle:
                </Text>
                <Text color="black" size="12">
                  {' '}
                  {paymentCadence === 'monthly' ? 'Monthly (11 cycles)' : 'Annual'}
                </Text>
              </SummaryContainer>
            </Col>

            <Col span={20}>
              <label>
                <Space vertical={10} />
                <Text size={16} color="black">
                  Credit Card Number
                </Text>
                <CardNumberElement options={options} />
              </label>
              <label>
                <Text size={16} color="black">
                  Expiration
                </Text>
                <CardExpiryElement options={options} />
              </label>
              <label>
                <Text size={16} color="black">
                  CVV
                </Text>
                <CardCvcElement options={options} />
              </label>
            </Col>
          </Row>
        </StyledContainer>
        <PanelFooter>
          <AlertFade
            type={message.type}
            isVisible={!!message.message}
            message={message.message}
            alertPosition="absolute"
            position="absolute"
            minHeight="80"
            width={70}
            bottom={82}
          />

          <Button
            htmlType="submit"
            color="violet"
            alignment="center"
            disabled={!stripe || !elements}
            loading={processingPayment}
            data-testid="paytest"
            bottom={0}
          >
            FINISH AND PAY{' '}
            {paymentCadence === 'monthly' ? `$${quoteResponse?.downpayment}` : `$${quoteResponse?.premiumAnnual}`}
          </Button>
        </PanelFooter>
      </form>
    </>
  );
};

const SummaryContainer = styled.div`
  background-color: #f7f7f0;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px 10px;
  textalign: center;
`;

const StyledContainer = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export default Checkout;
