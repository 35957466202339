import React from 'react';
import { Spin as AntSpin } from 'antd';
import styled from 'styled-components';

function Spin(props) {
  return (
    <StyledSpinContainer className="spin-container" data-testid="spinTest">
      <AntSpin {...props} />
    </StyledSpinContainer>
  );
}
const StyledSpinContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default Spin;
