import { Flex, Select, theme, Space } from 'antd';
import Text from 'components/Text/Text';
import Progress, { strokeColors } from 'components/uielements/Progress/Progress';
import React, { useState, useEffect, useCallback } from 'react';
import { getPropertyGroups } from 'services/api/landlordApi/read';
import CreateMerchantStepper from 'screens/Landlord/FinixOnboarding/CreateMerchantWizard';
import { useSelector } from 'react-redux';
import { displayAlert } from 'resources/helpers';
import { formVariants } from 'animations/variants';
import { MotionMainContent } from '../WepayOffboarding.styles';
import { motion } from 'framer-motion';
import Result from 'components/uielements/Result/Result';
import { ReactComponent as MultiFamily } from 'assets/images/onboarding-fullscreen/multi-family.svg';
import Button from 'components/Button/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function MigrationOnboarding(props) {
  const { setAlert } = props;
  // console.log('[MigrationOnboarding.js] props', props);
  const session = useSelector((store) => store.session);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(null);
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [progress, setProgress] = useState({ percent: 0, steps: 0, success: 0, status: [] });
  const [selectOptions, setSelectOptions] = useState([]);
  const { token } = theme.useToken();
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const handlePropertyGroupSelect = (value) => {
    console.log('[MigrationOnboarding.js] ƒ handlePropertyGroupSelect', value);
    setSelectedPropertyGroup(() => propertyGroups.find((pg) => pg.id === value));
  };

  const handleResetMigrationOnboarding = useCallback(() => {
    console.log('[MigrationOnboarding.js] ƒ handleResetMerchantOnboarding');
    setSelectedPropertyGroup(null);
    setPropertyGroups([]);
    setProgress({ percent: 0, steps: 0, success: 0, status: [] });
    fetchAndSetData();
  }, []);

  const fetchAndSetData = async (controller) => {
    try {
      setIsFetching(true);
      const { items: propGroups } = await getPropertyGroups(cognitoToken, 1, 1000, '', controller);

      setPropertyGroups(propGroups);
      setSelectOptions(() =>
        propGroups.map((pg) => ({ label: pg.name, value: pg.id, disabled: !!pg.finixAccount }))
      );
      setSelectedPropertyGroup(propGroups.find((pg) => (pg.default && !pg.finixAccount ? pg : null)));
      updateProgress(propGroups);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log('[MigrationOnboarding.js] ƒ fetchandSetPropertyGroups ERROR', err);
      displayAlert(setAlert, 'There was an error fetching your property groups.', 'error');
    }
  };

  const updateProgress = (propertyGroups) => {
    const total = propertyGroups.length;
    const success = propertyGroups.filter((pg) => !!pg.finixAccount).length;
    const percent = Math.round((success / total) * 100);
    const status = propertyGroups
      .filter((group) => !!group.finixAccount)
      .map((pg) => {
        const state = pg.finixAccount?.onboardingState;
        let status;
        switch (state) {
          case 'APPROVED':
            status = strokeColors.success;
            break;
          case 'PROVISIONING':
            status = strokeColors.warning;
            break;
          case 'REJECTED':
            status = strokeColors.error;
            break;
          case 'IDENTITY_CREATED':
          case 'INSTRUMENT_CREATED':
            status = strokeColors.info;
            break;
          default:
            break;
        }
        return status;
      });

    setProgress(() => ({ percent, status, success, steps: total }));
  };

  useEffect(() => {
    console.log('ƒ useEffect propertyGroups on CDM');
    const controller = new AbortController();
    fetchAndSetData(controller);
    return () => controller.abort();
  }, []);

  return (
    <MotionMainContent
      bordercolor={token.colorBorderSecondary}
      key="onboarding"
      variants={formVariants}
      initial="hide"
      animate="show"
      exit="leave"
    >
      <Flex justify="space-between" style={{ marginBottom: '24px' }} wrap="wrap" gap={24}>
        <Select
          loading={isFetching}
          placeholder="Select Property Group"
          options={selectOptions}
          onChange={handlePropertyGroupSelect}
          value={selectedPropertyGroup?.id}
          style={{ flex: '0 1 240px' }}
          disabled={false} // disable when completing onboarding
        />

        <Space direction="vertical" style={{ alignItems: 'flex-start' }}>
          <Progress
            steps={!progress?.steps ? 1 : progress.steps}
            percent={progress.percent}
            strokeColor={progress.status}
          />
          <Text color="black" size={12} as="div">
            {progress.success} of {progress.steps} property groups onboarded
          </Text>
        </Space>
      </Flex>
      {selectedPropertyGroup ? (
        <motion.div key="onboarding-stepper" variants={formVariants} initial="hide" animate="show" exit="leave">
          <CreateMerchantStepper
            propertyGroup={selectedPropertyGroup}
            handleResetMigrationOnboarding={handleResetMigrationOnboarding}
          />
        </motion.div>
      ) : (
        <Result
          status="info"
          title={
            progress.success === progress.steps ? (
              <Space direction="vertical" size={24}>
                <Text as="div" color="black" strong>
                  Onboarding complete!
                </Text>
                <Link to="/landlord">
                  <Button type="primary">Go To My Dashboard</Button>
                </Link>
              </Space>
            ) : (
              <Text color="black">Select a property group</Text>
            )
          }
          icon={<MultiFamily />}
        />
      )}
    </MotionMainContent>
  );
}

export default MigrationOnboarding;
