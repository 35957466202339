import React from 'react';
import Tag from 'components/Tag';

export const transferStatusMap = (value) => {
  switch (value) {
    case 'SUCCEEDED':
      return <Tag color="green">Succeeded</Tag>;
    default:
      return <Tag color="red">Unknown</Tag>;
  }
};

export const transferTypeMap = (value) => {
  // convert upper case text to title case
  const text = value
    .toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

  switch (value) {
    case 'DEBIT':
      return <Tag color="green">Payment</Tag>;
    case 'REVERSAL':
    case 'FEE':
    case 'ADJUSTMENT':
    case 'DISPUTE':
    case 'RESERVE':
      return <Tag color="red">{text}</Tag>;
    case 'CREDIT':
      return <Tag color="blue">{text}</Tag>;
    default:
      return <Tag>Unknown</Tag>;
  }
};
