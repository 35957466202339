import React from 'react';
import Segmented from 'components/Segmented/Segmented';

export default function StyledKpiToggle(props) {
  const { defaultPeriod, onChange } = props;

  return (
    <Segmented
      className="kpi-toggle"
      options={[
        { label: 'This Month', value: 'mtd' },
        { label: 'This Year', value: 'ytd' },
      ]}
      defaultValue={defaultPeriod}
      onChange={onChange}
      size="small"
    />
  );
}
