import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import MaskedInput from 'antd-mask-input';

import { Card as AntCard, Row, Col, DatePicker, Form, Spin } from 'antd';

import { getUserDataV3 } from 'store/actions/sessionActions';

import { updateTenantMetaV3 } from 'services/api/tenantApi';

import { Montserrat } from 'resources/vgsFonts';

// Components

import Text from 'components/Text';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import CreditBoostTitle from './CreditBoostTitle';
import dayjs from 'dayjs';
import SsnInput from 'components/SsnInput/SsnInput';

const css = {
  fontSize: '14px',
  border: '1px solid #D9D9D9',
  'background-clip': 'padding-box',
  boxSizing: 'border-box',
  width: '153px',
  height: '36px',
  borderRadius: '2px',
  padding: '4px 11px',
  color: '#595959',
  overFlowX: 'hidden',
  '@font-face': {
    'font-family': 'Montserrat',
    'font-style': 'normal',
    'font-weight': '400',
    src: Montserrat,
  },
  fontFamily: '"Montserrat"',
  fontVariant: 'tabular-nums',
  fontFeatureSettings: 'tnum, "tnum"',
  '&:focus': {
    border: '1px solid #ffdb29',
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f5f5f5',
    cursor: 'not-allowed',
    opacity: '1',
  },
};

const CreditBoost = (props) => {
  const { rentCredStatus } = props;
  console.log('rentCredStatus', props);
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const dispatch = useDispatch();
  const [isLoaded, scriptLoaded] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'warning', isVisible: false });
  const [optOutAlert, setOptOutAlert] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [vgsForm, setVgsForm] = useState(null);
  const [form] = Form.useForm();
  const ssnFieldRef = useRef(null);
  const loadedForm = useRef(false);
  const [disabledEnroll, setDisabledEnroll] = useState(true);
  const [showEnrollButton, setShowEnrollButton] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  const updateMedia = () => {
    setWindowWidth(window.innerWidth);
    if (ssnFieldRef.current) {
      if (window.innerWidth < 400) {
        css.width = '100%';
        ssnFieldRef.current.update({
          css,
        });
      }
      if (window.innerWidth > 400) {
        css.width = '153px';
        ssnFieldRef.current.update({
          css,
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (
      !session.userData.tenantMeta.creditBoost?.hasOwnProperty('optOutDate') ||
      !session.userData.tenantMeta.creditBoost?.optOutDate
    ) {
      if (session.userData.tenantMeta.creditBoost?.birthDate) {
        setDisabledInputs(true);
        setShowEnrollButton(false);
        form.setFieldsValue({
          birthDate: dayjs(session.userData.tenantMeta.creditBoost?.birthDate),
          ssn: '***-**-****',
        });
      }
      if (!session.userData.tenantMeta.creditBoost?.birthDate) {
        setDisabledInputs(false);
        form.setFieldsValue({
          birthDate: '',
          ssn: '',
        });
      }

      setDisabledEnroll(rentCredStatus.renterTierId < 2);
    }

    if (session.userData.tenantMeta.creditBoost?.optOutDate) {
      const diff = dayjs().diff(session.userData.tenantMeta.creditBoost?.optOutDate, 'months');

      if (diff >= 6) {
        setDisabledEnroll(false);
        setDisabledInputs(rentCredStatus.renterTierId < 2 || session.userData.tenantMeta.creditBoost.birthDate);
      } else {
        setDisabledEnroll(true);
        setDisabledInputs(true);
      }
    }
  }, [session, rentCredStatus]);

  useEffect(() => {
    if (rentCredStatus.renterTierId > 1) {
      if (ssnFieldRef.current && !session.userData.tenantMeta.creditBoost.lastFour) {
        ssnFieldRef.current.update({
          disabled: '',
        });
      }
    }
  }, [rentCredStatus]);

  // deprecated code
  useEffect(() => {
    loadedForm.current = true;
    // initForm();
  }, []);

  // const initForm = (vgsCollect) => {
  //   const createVgsForm = window.VGSCollect.create(
  //     process.env.REACT_APP_VGS_VAULT,
  //     process.env.REACT_APP_VGS_ENV,
  //     (s) => {
  //       if (s) {
  //         if (s.ssn?.isDirty) {
  //           form.validateFields(['ssn']);
  //         }
  //       }
  //     }
  //   );
  //   if (window.innerWidth < 400) css.width = '100%';
  //   ssnFieldRef.current = createVgsForm.field('#hiddenSsn .fake-input', {
  //     type: 'ssn',
  //     name: 'ssn',
  //     placeholder: session.userData.tenantMeta?.creditBoost?.lastFour
  //       ? `XXX-XXX-${session.userData.tenantMeta.creditBoost.lastFour}`
  //       : '222-222-2222',
  //     disabled:
  //       disabledInputs || rentCredStatus.renterTierId < 2 || session.userData.tenantMeta.creditBoost?.birthDate
  //         ? 'disabled'
  //         : '',
  //     css,
  //   });

  //   setVgsForm(createVgsForm);
  // };

  const displayAlert = (message, type) => {
    setAlert({ message, isVisible: true, type });

    setTimeout(() => {
      setAlert({ message: '', isVisible: false, type: 'warning' });
    }, 5000);
  };

  const onFinish = async (values) => {
    try {
      if (disabledInputs) {
        if (optOutAlert) {
          setOptOutAlert(false);
          return displayAlert(
            'You will not be able to re-enroll for six months. Click to confirm cancellation',
            'warning'
          );
        }
        setLoading(true);
        const creditBoost = {
          birthDate: '',
          ssn: '',
          lastFour: '',
          optIn: false,
          optOutDate: dayjs().utc().format('YYYY-MM-DD'),
        };
        await updateTenantMetaV3(
          session.userData.id,
          creditBoost,
          session.userData.tenantMeta.renterProfile,
          session.userData.tenantMeta.financialGoals,
          cognitoToken
        );
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        setLoading(false);
        setOptOutAlert(true);
        setShowEnrollButton(true);
        displayAlert('Success', 'success');
      } else {
        setLoading(true);
        const ssnWSpaces = values.ssn.replace(/[-\s]/g, '');

        const creditBoost = {
          birthDate: dayjs(values.birthDate).format('YYYY-MM-DD'),
          ssn: ssnWSpaces,
          lastFour: ssnWSpaces.slice(-4),
          optIn: true,
        };

        await updateTenantMetaV3(
          session.userData.id,
          creditBoost,
          session.userData.tenantMeta.renterProfile,
          session.userData.tenantMeta.financialGoals,
          cognitoToken
        );

        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        setLoading(false);
        setShowEnrollButton(false);
        displayAlert('Success', 'success');

        // const extraVgsFields = {
        //   data: {
        //     customSsn: ssnWSpaces,
        //   },
        // };

        // vgsForm.submit('/post', extraVgsFields, async (status, data) => {
        //   try {
        //     const vgsRes = JSON.parse(data.data);
        //     vgsValidator([[vgsRes.customSsn, 'customSsn']]);

        //     const creditBoost = {
        //       birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
        //       ssn: vgsRes.customSsn,
        //       optIn: true,
        //     };
        //     await updateTenantMeta(
        //       session.userData.id,
        //       creditBoost,
        //       session.userData.tenantMeta.renterProfile,
        //       session.userData.tenantMeta.financialGoals
        //     );
        //     dispatch(getUserData());
        //     setLoading(false);
        //     setShowEnrollButton(false);
        //     displayAlert('Success', 'success');
        //   } catch (error) {
        //     console.log(error);
        //     setLoading(false);
        //     displayAlert('There was an error with your request', 'warning');
        //   }
        // });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      displayAlert('There was an error with your request', 'warning');
    }
  };

  // const ssnValidation = (rule, value) => {
  //   if (!vgsForm?.state?.ssn?.isValid) {
  //     ssnFieldRef.current.update({
  //       css: {
  //         ...css,
  //         border: '0px',
  //       },
  //     });
  //     return Promise.reject('Not a valid ssn');
  //   }
  //   ssnFieldRef.current.update({
  //     css: {
  //       ...css,
  //     },
  //   });
  //   return Promise.resolve();
  // };

  const dateFormat = 'MM/DD/YYYY';

  const checkSsn = (rule, value) => {
    if (!disabledInputs) {
      const wSpaces = value.replace(/[-\s]/g, '');
      if (!value || !wSpaces) return Promise.reject('Required');
      if (wSpaces.length < 9) {
        return Promise.reject('Invalid SSN');
      }
    }

    return Promise.resolve();
  };

  return (
    <StyledCard title={<CreditBoostTitle locked={disabledInputs} />}>
      <Row justify="center">
        <Col
          span={23}
          style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', textAlign: 'center' }}
        >
          <Text color="black">
            Enroll in Credit Reporting and boost your credit score by reporting your monthly rent payments to all 3
            credit bureaus for FREE.
          </Text>
        </Col>
      </Row>
      <Spin spinning={!loadedForm.current}>
        <Form name="creditBoostForm" onFinish={onFinish} form={form} initialValues={{ ssn: '' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
              flexDirection: windowWidth < 400 ? 'column' : 'row',
            }}
          >
            <BirthDateContainer>
              <StyledText color="black" size={14}>
                Birth Date
              </StyledText>
              <br />
              <StyledFormItem name="birthDate" rules={[{ required: disabledInputs, message: 'Required' }]}>
                <StyledAntPicker
                  disabled={disabledInputs}
                  format={dateFormat}
                  disabledDate={(current) => current && current > dayjs().subtract(18, 'years')}
                />
              </StyledFormItem>
            </BirthDateContainer>
            <SsnContainer>
              <StyledText color="black" size={14}>
                Social Security Number
              </StyledText>
              <br />
              <StyledSsnFormItem
                name="ssn"
                rules={[
                  {
                    validator: checkSsn,
                  },
                ]}
              >
                <SsnInput
                  disabled={disabledInputs}
                  placeholder={session.userData.tenantMeta.creditBoost?.ssn ? '***-**-****' : '987-65-4321'}
                />
              </StyledSsnFormItem>
              <StyledDiv id="hiddenSsn" className="form-group" key="3" style={{ display: 'none' }}>
                <div style={{ width: `${windowWidth - 70}px` }}>
                  <span className="fake-input"></span>
                </div>
              </StyledDiv>
            </SsnContainer>
            <AlertFade
              type={alert.type}
              isVisible={alert.isVisible}
              message={alert.message}
              position="absolute"
              bottom={40}
            />
          </div>
          <Row justify="center">
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', textAlign: 'center' }}
            >
              <Text color="black" size={10}>
                By clicking ENROLL you agree to the{' '}
                <a href="https://www.payrent.com/terms-conditions" target="_blank">
                  terms and conditions
                </a>{' '}
                of PayRent's rent reporting service.
              </Text>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col span={5} style={{ display: 'flex', justifyContent: 'center' }}>
              {showEnrollButton && (
                <Button color="violet" bottom={0} htmlType="submit" loading={loading} disabled={disabledEnroll}>
                  ENROLL
                </Button>
              )}
              {!showEnrollButton && (
                <Button
                  color={optOutAlert && 'violet'}
                  bottom={0}
                  htmlType="submit"
                  loading={loading}
                  outlined={!optOutAlert}
                  disabled={!disabledInputs}
                >
                  {optOutAlert ? 'CANCEL' : 'CONFIRM CANCEL'}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Spin>
    </StyledCard>
  );
};

const BirthDateContainer = styled.div`
  margin-right: 10px;

  @media screen and (max-width: 400px) {
    margin-left: 0px;
    margin-right: 0px;
    height: 36px:
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    text-align: left;
  }
`;

const StyledSsnFormItem = styled(Form.Item)`
  max-width: 151px;
  .ant-form-item-explain {
    text-align: left;
  }
  input {
    font-size: 16px !important;
  }
  @media screen and (max-width: 400px) {
    max-width: 100%;
  }
`;

const StyledDiv = styled.div`
  width: 153px;
  @media screen and (max-width: 374px) {
    max-width: 400px;
  }
`;

const StyledText = styled(Text)`
  position: absolute;
  width: 200px;
  @media screen and (max-width: 375px) {
    font-size: 12px !important;
  }
`;

const StyledAntPicker = styled(DatePicker)`
  border-radius: 4px;
  input {
    font-size: 16px !important;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

const SsnContainer = styled.div`
  margin-left: 10px;
  .ant-form-item-has-error .fake-input iframe {
    height: 40px;
    max-width: 155px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 400px) {
    margin-left: 0px;
    .ant-form-item-has-error .fake-input iframe {
      width: 100%;
    }
  }
`;

const StyledCard = styled(AntCard)`
  .fake-input iframe {
    height: 38px !important;
  }
  .ant-card-body {
    padding: 24px 24px;
  }
  .fake-input {
    height: 37px !important;
  }
  @media screen and (max-width: 768px) {
    .fake-input iframe {
      height: 36px;
    }
  }
  @media screen and (max-width: 400px) {
    .fake-input iframe {
      width: 100%;
    }
  }
`;

export default CreditBoost;
