import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import { displayAlert } from 'resources/helpers';
import { unsubscribe } from 'services/api/common/create';

import Text from 'components/Text';
import Button from 'components/Button';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';

// import './styles.scss';

const RemoveRenter = (props) => {
  const [alert, setAlert] = useState({ isVisible: false, message: '', type: 'warning' });
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [address, setAddress] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const bodyclass = document.createAttribute('class');
    bodyclass.value = 'remove-renter-body';
    document.getElementsByTagName('body')[0].setAttributeNode(bodyclass);

    const params = new URLSearchParams(props.history?.location?.search);
    const urlAddress = `${decodeURI(params.get('address'))}${props.history?.location?.hash}`;
    const urlToken = params.get('token') || '';
    setAddress(urlAddress.trim());
    setToken(urlToken);
  }, []);

  const handleClick = async () => {
    try {
      setLoading(true);
      await unsubscribe(token);
      setLoading(false);
      setDisableButton(true);
      displayAlert(setAlert, 'Request Successfully Created', 'success', 5000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      displayAlert(
        setAlert,
        error?.data?.errors[0]?.detail || 'There was an error, please try again.',
        'warning',
        5000
      );
    }
  };
  return (
    <StyledContainer>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col span={20}>
          <Text color="black" size={16} centered>
            Click CONFIRM to request removal from the rental property at {address} and discontinue rent reminders
          </Text>
          <Space vertical={15} />
          <Button
            color="violet"
            alignment="center"
            onClick={handleClick}
            loading={loading}
            disabled={disableButton || !token || !address}
            data-testid="buttonTest"
          >
            CONFIRM
          </Button>
          <Row justify="center">
            <Col span={15}>
              <AlertFade
                message={alert.message}
                isVisible={alert.isVisible}
                type={alert.type}
                position="absolute"
                alertPosition="absolute"
                //width={100}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export default RemoveRenter;
