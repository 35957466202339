import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { Form, DatePicker, Select, Radio, Row, Col, Checkbox, Input } from 'antd';
import { CalendarOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getScheduledPayments, updateScheduledPayment, deleteScheduledPayment } from 'store/actions/tenantActions';
import { getUserDataV3 } from 'store/actions/sessionActions';
import { ordinalize } from 'utils';
import { disableScheduledPaymentEndDate } from 'resources/FormValidators';
import { calculateFee } from 'resources/helpers';
import * as tenantApi from 'services/api/tenantApi';

// Components
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import PanelFooter from 'components/Panel/PanelFooter';
import TotalPaymentAmount from '../../components/TotalPaymentAmount';
import Segmented from 'components/Segmented/Segmented';
import Text from 'components/Text/Text';

dayjs.extend(utc);

const SchedulePayment = (props) => {
  // console.log('[PaymentSchedule.js]', props);
  const id = props.scheduledPaymentId;
  const history = useHistory();
  const dispatch = useDispatch();
  // const { id } = params;
  const paymentMethods = useSelector((store) => store.tenant.paymentMethods);
  const scheduledPayments = useSelector((store) => store.tenant.scheduledPayments);
  const paymentMethod = useSelector((store) => store.tenant.paymentMethods);
  const servicePlan = useSelector((store) => store.session.userData.servicePlan);
  const firstPayment = useSelector((store) => store.tenant.firstPayment);
  const userData = useSelector((store) => store.session.userData);

  let absorbFeesPercent = 100;
  let absorbFees = false;

  if (userData.property) {
    absorbFeesPercent = userData.property.paymentSettings.absorbFeesPercent;
    absorbFees = userData.property.paymentSettings.absorbFees;
  }

  const session = useSelector((store) => store.session);
  const [form] = Form.useForm();

  const [balanceDue, setBalanceDue] = useState(false);
  const [frequency, setFrequency] = useState('');
  const [duration, setDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [unsubmitted, setUnsubmitted] = useState(true);
  const [editView, setEditView] = useState(false);
  const [removeScheduledPayment, setRemoveScheduledPayment] = useState(false);
  const [removeText, setRemoveText] = useState('Remove scheduled payment');
  const [scheduledPaymentRemoved, setScheduledPaymentRemoved] = useState(false);
  const [alert, setAlert] = useState({ visible: false, message: '', type: 'warning', description: '' });
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountValidation, setTotalAmountValidation] = useState(false);
  const [fee, setFee] = useState(false);
  const [chosenPm, setChosenPm] = useState(-1);
  const [firstPayDate, setFirstPayDate] = useState(false);
  const [formData, setFormData] = useState({});

  const paymentInput = useRef(null);

  // const onSuccess = useCallback((token, metadata) => {
  //   const plaid = {
  //     token,
  //     metadata,
  //   };
  //   verifyPaymentMethod(plaid);
  // }, []);

  // const onExit = () => {
  //   displayAlert('Payment Method Error', 'warning', 'Something went wrong while adding your payment method!');
  // };

  // customizacion del css https://support.plaid.com/hc/en-us/articles/360008420353-Plaid-Link-customization

  // const config = {
  //   clientName: 'Payrent',
  //   env: process.env.REACT_APP_PLAID_ENV,
  //   product: ['auth', 'transactions'],
  //   publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
  //   onSuccess,
  //   onExit,
  // };
  // const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (
      props.paymentMethodSelection !== undefined &&
      typeof props.balanceDue === 'boolean' &&
      props.otherAmount !== undefined
    ) {
      if (paymentMethods.length) {
        setBalanceDue(props.balanceDue);
        const values = {
          balanceDue: props.balanceDue,
        };
        if (props.paymentMethodSelection > -1) values.payFrom = paymentMethods[props.paymentMethodSelection]?.id;
        if (props.balanceDue === false) values.paymentAmount = Number(props.otherAmount).toFixed(2);
        form.setFieldsValue(values);
      }
    }
    if (id) {
      const due =
        !isEmpty(userData) && userData.hasOwnProperty('billingAccounts')
          ? userData.billingAccounts.filter((b) => b.state === 'ACTIVE')[0].balance
          : 0;

      let payment = scheduledPayments.items.filter((sp) => sp.id === id);
      if (payment.length > 0) {
        let formData = {
          payFrom: payment[0].paymentMethod.id,
          frequency: payment[0].frequency,
          paymentAmount: payment[0].balanceDue ? (0).toFixed(2) : Number(payment[0].fixAmount).toFixed(2),
        };
        if (payment[0].firstPaymentDate) {
          formData.firstPaymentDate = dayjs.utc(payment[0].firstPaymentDate);
          setFirstPayDate(true);
        }
        if (payment[0].hasOwnProperty('schedule')) formData.deliverBy = payment[0].schedule;

        if (payment[0].endDate && !dayjs.utc(payment[0].endDate).isSame(dayjs.utc('1970-01-01'))) {
          formData.endPaymentDate = dayjs.utc(payment[0].endDate);
          formData.duration = 2;
        }

        form.setFieldsValue(formData);
        if (payment[0].balanceDue) setBalanceDue(true);
        handleSelectChange(payment[0].paymentMethod.id);
        setEditView(true);
      }
    }
    setFormData(form.getFieldsValue());

    dispatch({
      type: 'HEADER_BUTTON_VISIBLE',
      visible: true,
      screenTitle: 'Schedule Payment',
      leftButtonPath: '/tenant/wallet',
      leftButtonType: 'back',
    });

    return () => {
      dispatch({
        type: 'HEADER_BUTTON_VISIBLE',
        visible: false,
        screenTitle: '',
        leftButtonPath: '',
        leftButtonType: '',
      });
    };
  }, [session, id]);

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const displayAlert = (message, type, description) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    setTimeout(() => {
      dispatch({
        type: 'UNSET_ALERT',
      });
    }, 5000);
  };

  const verifyPaymentMethod = async (plaid) => {
    setAlert({
      visible: false,
    });
    const pm = paymentMethods.map((e) => e.id).indexOf(form.getFieldValue('payFrom'));
    const filter = plaid.metadata.accounts.filter(
      (a) => a.mask === paymentMethods[pm].paymentGatewayMeta.account_last_four
    );
    if (!filter.length) {
      setLoading(false);
      setAlert({
        visible: true,
        message: 'Authentication Error',
        description: 'The account authorized does not match the one selected.',
        type: 'error',
      });
      return setTimeout(() => {
        setAlert({
          visible: false,
          message: '',
          type: 'error',
        });
      }, 5000);
    }
    setLoading(true);
    try {
      await tenantApi.savePlaidToken(session.cognitoTokens.idToken.jwtToken, paymentMethods[pm], plaid);
      onFinish(form.getFieldsValue());
    } catch (error) {
      displayAlert('There was an error', 'warning', 'Your payment method was not verified.');
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);

      if (editView) {
        dispatch(
          updateScheduledPayment(session.cognitoTokens.idToken.jwtToken, id, userData.id, values, balanceDue)
        );
      } else {
        // Check balance first
        /*
        const pm = paymentMethods.map((e) => e.id).indexOf(values.payFrom);

        if (paymentMethods[pm].paymentMethodType === 'ACH' && userData.paymentGateway === 'Wepay') {
          const account = await tenantApi.getAccountBalance(
            session.cognitoTokens.idToken.jwtToken,
            values.payFrom
          );

          if (!account.plaidAccessToken && !account.balance) {
            setLoading(false);

            return setAlert({
              visible: true,
              message: (
                <>
                  We need to verify your account balance. <Link to="#">Click here to begin.</Link>
                </>
              ),
              type: 'warning',
              clickFunction: open,
            });
          }

          if (Number(values.paymentAmount) > account.balance) {
            displayAlert('Insufficient funds to schedule a payment.', 'warning', '');
            setLoading(false);
            props.setDrawerVisible(false);
            return;
          }
        }
        */
        // await dispatch(createScheduledPayment(session.cognitoTokens.idToken.jwtToken, values, userData.id));
        await tenantApi.schedulePayment(session.cognitoTokens.idToken.jwtToken, values, userData.id);
        dispatch(getScheduledPayments(session.cognitoTokens.idToken.jwtToken));
      }

      setLoading(false);
      setUnsubmitted(false);
      displayAlert(editView ? 'Scheduled payment updated' : 'Scheduled payment created', 'success', '');
      if (props.notification) {
        if (props.setShowScheduledPayment) props.setShowScheduledPayment(false);
        props.setNotification(false);
        props.setDrawerVisible(false);
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        return;
      }
      if (props.setShowScheduledPayment) {
        // props.setShowPayment(true);
        props.setShowScheduledPayment(false);
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        history.push('/tenant/wallet');
        return;
      }
      props.setDrawerVisible(false);
      dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
    } catch (err) {
      props.setDrawerVisible(false);
      setLoading(false);
      displayAlert(
        'There was an error',
        'warning',
        editView
          ? 'Your scheduled payment was not updated.'
          : err?.data?.errors[0].detail || 'Your scheduled payment was not created.'
      );
    }
  };

  const onFinishFailed = (errorInfo) => console.error(errorInfo);

  const onValuesChange = (changedValues) => {
    const [key] = Object.keys(changedValues);
    setFormData(form.getFieldsValue());
    if (key === 'firstPaymentDate') {
      let { firstPaymentDate } = changedValues;
      if (changedValues.firstPaymentDate === null) {
        firstPaymentDate = '';
        setFirstPayDate(false);
      } else {
        if (form.getFieldValue('endPaymentDate')) {
          const daysDiff = firstPaymentDate.diff(form.getFieldValue('endPaymentDate'), 'days');
          const days = form.getFieldValue('frequency') === 'WEEKLY' ? -8 : -30;
          if (daysDiff > days) {
            form.setFieldsValue({
              endPaymentDate: '',
            });
          }
        }

        firstPaymentDate = firstPaymentDate.format('MM-DD-YYYY');
        setFirstPayDate(true);
      }
    }
    //  Not used in form
    // if (changedValues.hasOwnProperty('rangePaymentDate')) {
    //   if (changedValues.rangePaymentDate === null) {
    //     changedValues.rangePaymentDate = [];
    //   } else {
    //     changedValues.rangePaymentDate = [
    //       changedValues.rangePaymentDate[0].format('MM-DD-YYYY'),
    //       changedValues.rangePaymentDate[1].format('MM-DD-YYYY'),
    //     ];
    //   }
    // }

    if (key === 'frequency') {
      setFrequency(changedValues.frequency);
      const updateFields = {
        deliverBy: 'Send subsequent payments on',
      };
      if (changedValues.frequency === 'ONE-TIME') {
        updateFields.duration = 1;
        setDuration(1);
      }

      if (changedValues.frequency === 'MONTHLY' || changedValues.frequency === 'WEEKLY') {
        if (form.getFieldValue('firstPaymentDate') && form.getFieldValue('endPaymentDate')) {
          const daysDiff = form
            .getFieldValue('firstPaymentDate')
            .diff(form.getFieldValue('endPaymentDate'), 'days');
          const days = form.getFieldValue('frequency') === 'WEEKLY' ? -8 : -30;
          if (daysDiff > days) {
            updateFields.endPaymentDate = '';
          }
        }
      }

      form.setFieldsValue(updateFields);
    }
    if (key === 'duration') {
      setDuration(changedValues.duration);
    }
  };

  const handleClickBalanceDue = (e) => {
    const checked = e.target.checked;
    // const due = !isEmpty(userData) && userData.hasOwnProperty('billingAccounts')
    //     ? userData.billingAccounts.filter((b) => b.state === 'ACTIVE')[0].balance
    //     : 0;

    if (checked) {
      form.setFieldsValue({
        paymentAmount: Number(0).toFixed(2),
        balanceDue: true,
      });
      setBalanceDue(true);
      setTotalAmount(0);
      setFee(0);
    } else {
      form.setFieldsValue({
        paymentAmount: Number(0).toFixed(2),
      });
      setBalanceDue(false);
    }
  };

  const handleClickDeleteScheduledPayment = async () => {
    if (removeScheduledPayment) {
      try {
        setLoading(true);
        dispatch(deleteScheduledPayment(session.cognitoTokens.idToken.jwtToken, id));
        setLoading(false);
        setScheduledPaymentRemoved(true);
        props.setDrawerVisible(false);
        displayAlert('Scheduled Payment Removed', 'success', 'You can add a new scheduled payment at any time.');
      } catch (error) {
        setLoading(false);
        props.setDrawerVisible(false);
        displayAlert('Scheduled Payment Error', 'warning', 'Your scheduled payment was not removed.');
      }
    }
    setRemoveScheduledPayment(true);
    setRemoveText('Really remove scheduled payments?');
  };

  const { Option } = Select;

  const daysOfMonth = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31,
  ];

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const checkPrice = (rule, value) => {
    if (balanceDue) {
      if (Number(value) >= 0) {
        return Promise.resolve();
      }
      return Promise.reject('Payment amount cannot be less than $0.00');
    }
    if (Number(value) >= 1) {
      return Promise.resolve();
    }
    return Promise.reject('Payment amount cannot be less than $1.00');
  };

  const calculateTotalAmount = async (value, propertySettings, selectedPm) => {
    const totalFee = await calculateFee(
      value,
      propertySettings,
      selectedPm,
      servicePlan,
      paymentMethod,
      firstPayment,
      absorbFees,
      absorbFeesPercent,
      '',
      false,
      '',
      ''
    );

    const total = parseFloat(value) + parseFloat(totalFee);
    // setOtherAmount(0);
    setFee(totalFee.toFixed(2));
    setTotalAmount(Number(total).toFixed(2));
  };

  const handleSelectChange = (value) => {
    const pmIndex = paymentMethod.findIndex((p) => p.id === value);
    setChosenPm(pmIndex);
    const payAmount = balanceDue ? 0 : form.getFieldValue('paymentAmount');
    if (payAmount > 0) calculateTotalAmount(payAmount, userData.property, pmIndex);
  };

  const handleOtherAmountChange = async (e) => {
    if (e.target.value.indexOf(',') > -1) {
      const newValue = e.target.value.replace(',', '.');
      form.setFieldsValue({ paymentAmount: newValue });
      return;
    }
    if (isNaN(e.target.value)) {
      form.setFieldsValue({ paymentAmount: '' });
    }

    const number = +e.target.value;
    if (typeof number === 'number' && number >= 0) {
      const pmIndex = paymentMethod.findIndex((p) => p.id === form.getFieldValue('payFrom'));
      const totalFee =
        +number > 0
          ? await calculateFee(
              number,
              userData.property,
              pmIndex,
              servicePlan,
              paymentMethod,
              firstPayment,
              absorbFees,
              absorbFeesPercent,
              false,
              false,
              '',
              ''
            )
          : 0;

      const total = number + Number(totalFee.toFixed(2));
      setFee(totalFee.toFixed(2));

      setTotalAmount(Number(total.toFixed(2)));
      setTotalAmountValidation(false);
    }
  };

  const handleInputLeave = (e) => {
    const number = Number(e.target.value).toFixed(2);
    paymentInput.current.blur();
    form.setFieldsValue({
      paymentAmount: number,
    });
  };

  return (
    <Container data-testid="paymentScheduleTest">
      <Prompt
        when={form.isFieldsTouched() && unsubmitted && removeScheduledPayment === false}
        message="Are you sure you want to leave?"
      />
      <Header>
        <ArrowLeftOutlined
          onClick={() => {
            if (props.notification) {
              if (props.setShowScheduledPayment) props.setShowScheduledPayment(false);
              props.setNotification(false);
              props.setDrawerVisible(false);
              return;
            }
            if (props.fromCompliance) {
              if (props.setShowScheduledPayment) props.setShowScheduledPayment(false);
              props.setFromCompliance(false);
              props.setDrawerVisible(false);
              return;
            }
            if (props.setShowScheduledPayment && props.setShowPayment) {
              props.setShowPayment(true);
              props.setShowScheduledPayment(false);
              return;
            }
            if (props.setShowScheduledPayment) props.setShowScheduledPayment(false);
            props.setDrawerVisible(false);
          }}
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowTest"
        />
        Schedule Payment
      </Header>
      <FormContainer>
        <StyledForm
          name="basic"
          form={form}
          requiredMark={false}
          initialValues={{
            paymentAmount: '0.00',
            duration: 1,
            balanceDue: false,
            frequency: 'ONE-TIME',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          className="scheduledPayments"
        >
          <ContentContainer className="content-container">
            <div>
              <Form.Item
                name="payFrom"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'Please select the payment method.',
                  },
                ]}
              >
                <Select
                  placeholder="Pay from"
                  style={{ width: '100%' }}
                  onChange={handleSelectChange}
                  data-testid="paymentMethodTest"
                >
                  {paymentMethods.length > 0 &&
                    paymentMethods
                      .filter((p) => p.state === 'ACTIVE')
                      .map((p, i) => (
                        <Option key={i} value={p.id}>
                          {p.label}
                        </Option>
                      ))}
                </Select>
              </Form.Item>

              <Row gutter={15}>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Form.Item name="balanceDue" getValueProps={() => balanceDue}>
                    <Checkbox onClick={handleClickBalanceDue} checked={balanceDue} data-testid="balanceDueTest">
                      Balance Due
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="inputTitle">Other Amount</span>
                  <Form.Item
                    name="paymentAmount"
                    rules={[
                      {
                        validator: checkPrice,
                      },
                    ]}
                  >
                    <Input
                      prefix="$"
                      //type="number"
                      onChange={(e) => {
                        e.persist();
                        handleOtherAmountChange(e);
                      }}
                      disabled={balanceDue}
                      onMouseLeave={handleInputLeave}
                      onFocus={() => {
                        form.setFieldsValue({
                          paymentAmount: '',
                        });
                      }}
                      data-testid="amountTest"
                      ref={(input) => (paymentInput.current = input)}
                    />
                    {/**
               * <InputNumber
                  formatter={(value) => `$ ${value}`}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  precision={2}
                  disabled={balanceDue}
                />
               */}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="frequency"
                    label="Frequency"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Segmented
                      testId="frequencyTest"
                      options={[
                        { label: 'One-time', value: 'ONE-TIME' },
                        { label: 'Weekly', value: 'WEEKLY' },
                        { label: 'Monthly', value: 'MONTHLY' },
                      ]}
                      size="default"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="firstPaymentDate"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Please select date.',
                  },
                ]}
                // dependencies={['duration', 'endPaymentDate']}
              >
                <DatePicker
                  disabledDate={(current) => current && current < dayjs().local().endOf('day')}
                  placeholder="First Payment Date"
                  format="MM/DD/YYYY"
                  style={{ width: '100%' }}
                  inputReadOnly
                  data-testid="startDatePickerTest"
                />
              </Form.Item>
              <Form.Item
                name="deliverBy"
                rules={[
                  {
                    required:
                      form.getFieldValue('frequency') === 'WEEKLY' ||
                      form.getFieldValue('frequency') === 'MONTHLY',
                    message: 'Please select a day.',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        form.getFieldValue('frequency') === 'ONE-TIME' ||
                        form.getFieldValue('frequency') === undefined
                      ) {
                        return Promise.resolve();
                      }
                      if (!value || value !== 'Send subsequent payments on') {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please select a day.');
                    },
                  }),
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  disabled={
                    form.getFieldValue('frequency') === 'ONE-TIME' || form.getFieldValue('frequency') === undefined
                  }
                  placeholder="Send subsequent payments on"
                  data-testid="deliverByTest"
                >
                  {form.getFieldValue('frequency') === 'WEEKLY' &&
                    daysOfWeek.map((d, i) => (
                      <Option value={d} key={i}>
                        {d}
                      </Option>
                    ))}
                  {form.getFieldValue('frequency') === 'MONTHLY' &&
                    daysOfMonth.map((d, i) => (
                      <Option value={d} key={i}>
                        {ordinalize(d)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Row>
                <Col xs={{ span: 18, offset: 4 }} sm={{ span: 21, offset: 3 }}>
                  <Form.Item name="duration" label="Duration:">
                    <Radio.Group>
                      <Radio
                        value={1}
                        disabled={
                          form.getFieldValue('frequency') === 'ONE-TIME' ||
                          form.getFieldValue('frequency') === undefined
                            ? true
                            : false
                        }
                      >
                        No End Date
                      </Radio>
                      <Radio
                        value={2}
                        disabled={
                          form.getFieldValue('frequency') === 'ONE-TIME' ||
                          form.getFieldValue('frequency') === undefined
                            ? true
                            : false
                        }
                        data-testid="endDateTest"
                      >
                        End Date&nbsp;
                        <CalendarOutlined />
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {(form.getFieldValue('duration') === 2 || duration === 2) && (
                <Form.Item
                  name="endPaymentDate"
                  rules={[
                    {
                      type: 'object',
                      required: true,
                      message: 'Please select date.',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => disableScheduledPaymentEndDate(current, form)}
                    placeholder="End Date"
                    format="MM/DD/YYYY"
                    style={{ width: '100%' }}
                    // inputReadOnly
                    disabled={!form.getFieldValue('firstPaymentDate') || !firstPayDate}
                    data-testid="endDatePickerTest"
                  />
                </Form.Item>
              )}

              <TotalPaymentAmount
                totalAmount={totalAmount}
                totalAmountValidation={totalAmountValidation}
                absorbFees={absorbFees}
                absorbFeesPercent={absorbFeesPercent}
                paymentMethod={paymentMethod}
                chosenPm={chosenPm}
                firstPayment={firstPayment}
                fee={fee}
                formData={formData}
                balanceDue={balanceDue}
              />
            </div>
            <div style={{ margin: '12px 0' }}>
              <Text size={11} color="grey4" centered>
                By clicking Schedule, you authorize PayRent Inc. to initiate debits in your name using the payment
                method selected and on the specified date(s). Payment will be presented to your financial
                institution by the next business day. You further agree that once you click submit you may not
                revoke this authorization or cancel this payment.
              </Text>
            </div>
          </ContentContainer>
          <PanelFooter>
            <AlertFade
              isVisible={alert.visible}
              message={alert.message}
              description={alert.description}
              type={alert.type}
              clickFunction={alert.clickFunction}
            />

            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
              alignment="center"
              data-testid="buttonTest"
              bottom={0}
            >
              SCHEDULE
            </Button>
            {editView && (
              <>
                {!scheduledPaymentRemoved && (
                  <ItemContainer>
                    <RemoveLink onClick={handleClickDeleteScheduledPayment}>{removeText}</RemoveLink>
                  </ItemContainer>
                )}
              </>
            )}
          </PanelFooter>
        </StyledForm>
      </FormContainer>
    </Container>
  );
};

const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  .ant-form-item {
    margin-bottom: 26px;
  }
`;

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const ContentContainer = styled.div`
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const FormContainer = styled.div`
  height: calc(100% - 65px);
`;
//  width: 100vw;
const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

const RemoveLink = styled.div`
  text-decoration: underline;
  color: #cb48b7 !important;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ItemContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

export default SchedulePayment;
