import React from 'react';
import styled from 'styled-components';

import { calculateLandlordFee } from 'resources/helpers';

const DetailsTable = (props) => {
  const { items, truncateString } = props;
  return (
    <StyledDetailsTable>
      <thead>
        <tr>
          {/**
        <th>Transaction ID</th>
       */}
          {items.refType === 'Payment' && (
            <>
              <th>Method</th>
              <th>Your fee</th>
              <th>Settlement Date</th>
              <th>Net Settlement</th>
            </>
          )}

          {items.refType === 'BillingCharge' && <th>Memo:</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          {/**
       <td data-testid="paymentIdTest" className="transactionId">
        {data.items.transactionId}
      </td>
       */}
          {items.refType === 'Payment' && (
            <>
              <td>{items.method}</td>
              <td>$ {calculateLandlordFee(items.refType, items.fee, items.landlordAbsorbPercent)}</td>
              <td>{items.settlementDate}</td>
              <td>{items.netSettlement}</td>
            </>
          )}

          {items.refType === 'BillingCharge' && (
            <td className="transactionMemo">{truncateString(items.memo, 50)}</td>
          )}
        </tr>
      </tbody>
    </StyledDetailsTable>
  );
};

const StyledDetailsTable = styled.table`
  font-size: calc(10px + 0.2vw);
  .transactionId {
    width: 22%;
  }
  .transactionMemo {
    max-width: 20%;
  }
`;

export default DetailsTable;
