import React from 'react';
import Tag from 'components/Tag';
import { useSelector } from 'react-redux';

const RentCredBadge = (props) => {
  const rentCred = useSelector((store) => store.tenant.rentCred);

  const getTier = (id) => {
    switch (id) {
      case 2:
        return { color: '', text: 'Silver' };
      case 3:
        return { color: 'gold', text: 'Gold' };
      case 4:
        return { color: '#8C8C8C', text: 'Platinum' };
      case 5:
        return { color: 'orange', text: 'Bronze' };
    }
  };

  return (
    <>
      {rentCred?.renterTierId > 1 && (
        <Tag
          color={getTier(rentCred.renterTierId).color}
          style={{ marginLeft: '10px', marginRight: '10px', width: '70px', textAlign: 'center' }}
        >
          {getTier(rentCred.renterTierId).text}
        </Tag>
      )}
    </>
  );
};

export default RentCredBadge;
