import { isMobile } from 'react-device-detect';

export const theme = {
  token: {
    colorPrimary: '#541388',
    colorLink: '#cb48b7',
    fontFamily: "'Montserrat', 'Poppins', sans-serif",
    fontSize: isMobile ? 12 : 15,
    colorError: '#f43535',
    colorSuccess: '#85bb65',
    colorDark: '#122c34',
    colorBgSpotlight: 'rgba(18, 44, 52, 0.9)',
  },
  components: {
    Button: {
      colorPrimary: '#cb47b8',
      primaryShadow: '0 2px 3px rgba(36,3,48, 0.27) ',
    },
    FloatButton: {
      colorPrimary: '#cb47b8',
    },
    Layout: {
      bodyBg: '#f7f7f4',
    },
    Menu: {
      darkItemSelectedBg: '#ffcc00',
      darkItemSelectedColor: '#122c34',
      darkItemBg: '#122c34',
      itemBorderRadius: 8,
    },
    Table: {
      colorBgContainer: '#fff',
      headerBorderRadius: 0,
    },
    Dropdown: {
      colorPrimary: '#cb47b8',
    },
    Steps: {
      colorText: '#cb47b8',
    },
    Tabs: {
      itemActiveColor: '#cb47b8',
      // itemSelectedColor: '#cb47b8',
    },
    Segmented: {
      itemActiveBg: '#cb47b8',
      // itemHoverBg: '#cb47b8',
      itemSelectedBg: '#cb47b8',
      // itemHoverColor: '#fff',
      itemSelectedColor: '#fff',
    },
  },
};
