import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { List, Skeleton, Drawer, Form, ConfigProvider, Space as AntSpace, theme as antTheme } from 'antd';
import styled, { css } from 'styled-components';
import theme from 'styles/styledComponents/variables';
import Joyride from 'react-joyride';
import dayjs from 'dayjs';
import { isEmpty, debounce } from 'lodash';
import { getRentersV3 } from 'services/api/landlordApi/read';
import { resendInviteV3, rentReminder, createTransaction } from 'services/api/landlordApi/create';
import { deleteInviteV3 } from 'services/api/landlordApi/delete';
import { removeRenterV3 } from 'services/api/landlordApi/update';
import { rentersSteps } from 'resources/landlordTourSteps';
import { scrollTo } from 'resources/helpers';
import Text from 'components/Text';
import CardWithTitle from 'components/CardWithTitle';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';
import {
  getV3RentersListAction,
  setSummaryPaymentType,
  unsetTransactionRenter,
} from 'store/actions/landlordActions';
import { displayAlertAction } from 'store/actions/globalActions';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  DollarCircleFilled,
  FrownOutlined,
  MehOutlined,
  PlusSquareOutlined,
  SafetyCertificateFilled,
  SafetyCertificateOutlined,
  SmileFilled,
} from '@ant-design/icons';
import InputSearch from 'components/InputSearch';
import Space from 'components/Space';
import Button from 'components/Button/Button';
import TransactionPanel from '../components/TransactionPanel';
import PropertyPanel from '../components/PropertyPanel';
import InvitationPanel from '../components/InvitationPanel';

import RenterMenu from './components/RenterMenu/RenterMenu';
import RenterDetails from '../components/RenterDetails';
import AccountProfilePanel from '../components/AccountProfilePanel';
import AccountPreferencesPanel from '../components/AccountPreferencesPanel';
import PlansBillingsPanel from '../components/PlansBillingsPanel';
import BillingDetailsPanel from '../components/BillingDetailsPanel';
import NewsPanel from '../components/NewsPanel';

import { ReactComponent as RentSvg } from 'assets/empty-table-images/Rent.svg';
import OnboardingFullscreen from '../components/OnboardingFullScreen';
import UnitPanel from '../components/UnitPanel';
import EmptyLarge from 'components/EmptyLarge/EmptyLarge';

//import './style.scss';
import ResetButton from '../PropertyGroups/components/GroupTable/ResetButton/ResetButton';
import IconBadge from 'components/uielements/IconBadge/IconBadge';

const Renters = (props) => {
  // console.log('[Renters.js]', props);
  const {
    setShowOnboarding,
    setDrawerVisible,
    showOnboardingFullscreen,
    setShowOnboardingFullscreen,
    setShowUnitPanel,
    showUnitPanel,
  } = props;

  const { token } = antTheme.useToken();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const global = useSelector((store) => store.global);
  const rentersList = useSelector((store) => store.landlord.renters);
  const history = useHistory();
  // const [drawerVisible, setDrawerVisible] = useState(false);
  const [editRenter, setEditRenter] = useState(null);
  const [searchRenter, setSearchRenter] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [showTransactionPanel, setShowTransactionPanel] = useState(false);
  const [showPropertyPanel, setShowPropertyPanel] = useState(false);
  const [showInvitationPanel, setShowInvitationPanel] = useState(false);
  const [showEditRenterPanel, setShowEditRenterPanel] = useState(false);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [deleteInvitationText, setDeleteInvitationText] = useState({
    text: 'Delete Invitation',
    color: 'inherit',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: false,
  });
  const [deleteRenterText, setDeleteRenterText] = useState({
    text: 'Remove Renter',
    color: 'inherit',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: false,
  });
  const [renters, setRenters] = useState({ items: [], total: 0 });
  const [editProperty, setEditProperty] = useState(null);
  const [userTypeFilter, setUserTypeFIlter] = useState(null);
  const [selectedParentProperty, setSelectedParentProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');

  const rentersRef = useRef();
  const cancelToken = useRef(null);
  const cPage = useRef(1);
  const cSize = useRef(10);
  const stripeTimer = useRef(false);

  // Invitation panel form
  const [form] = Form.useForm();
  const [editInvitationProperty, setEditInvitationProperty] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 576);
  };

  const handleResetBalance = React.useCallback(async (item) => {
    console.log('[Renters.js] handleResetBalance', item);
    try {
      setRenters((prev) => ({
        ...prev,
        items: prev.items.map((r) => {
          if (r.id === item.id) r.resetLoading = true;
          return r;
        }),
      }));
      let chargeType = '';
      const values = {};

      if (+item.billingAccount.balance > 0) chargeType = 'Credit';
      if (+item.billingAccount.balance < 0) chargeType = 'Fee';

      values.transactionType = chargeType;
      values.memo = 'Reset Account';
      values.transactionDate = dayjs();
      values.transactionAmount = Math.abs(Number(item.billingAccount.balance));
      values.renterId = JSON.stringify({
        id: item.id,
        billingAccount: item.billingAccount.id,
      });

      await createTransaction(values);
      fetchRenters(cPage.current, cSize.current, searchValue);
      // dispatch(displayAlertAction('', 'success', 'Account Balance reset.', true, 5000));
    } catch (error) {
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
    fetchRenters(cPage.current, cSize.current, searchValue);
    setRenters((prev) => ({
      ...prev,
      items: prev.items.map((r) => {
        if (r.id === item.id) r.resetLoading = false;
        return r;
      }),
    }));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (!rentersList.loading && window.innerWidth < 576) {
      scrollTo(rentersRef.current);
    }
  }, [rentersList.loading]);

  const resetDropdown = (items) => {
    const object = {};
    for (let i = 0; i < items.length; i++) {
      object[i] = false;
    }
    return object;
  };

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
    dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    fetchData();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (userId !== session?.userData?.id && session?.userData?.id !== '') {
      setUserId(session?.userData?.id);
    }
  }, [session]);

  useEffect(() => {
    dispatch({
      type: 'SET_RENTERS_LIST_LOADING',
    });
    const params = new URLSearchParams(props.history?.location?.search);
    let typeFilter = null;

    if (params.get('type')) {
      typeFilter = params.get('type');
      setUserTypeFIlter(typeFilter);
    }
    setDropdownVisible(resetDropdown(renters.items));
  }, [props.history?.location?.search]);

  const fetchData = async () => {
    fetchRenters(1, 10, '');
  };

  const startRenterAction = (id, action) => {
    console.log('ƒ beginRenterAction', id, action);
    setRenters((prev) => ({
      ...prev,
      items: prev.items.map((r) => {
        if (r.id === id) r[action] = true;
        return r;
      }),
    }));
  };

  const endRenterAction = (id, action) => {
    console.log('ƒ endRenterAction', id, action);
    setRenters((prev) => ({
      ...prev,
      items: prev.items.map((r) => {
        if (r.id === id) r[action] = false;
        return r;
      }),
    }));
  };

  const fetchRenters = async (p, s, search) => {
    try {
      setLoading(true);
      if (cancelToken.current) {
        // Cancel the previous request before making a new request
        cancelToken.current.abort();
      }
      cancelToken.current = new AbortController();
      const rentersRes = await getRentersV3(
        session.cognitoTokens.idToken.jwtToken,
        p,
        s,
        search,
        cancelToken.current
      );
      // console.log('[Renters.js] fetchRenters', rentersRes);
      setLoading(false);
      setRenters(() => ({
        items:
          rentersRes?.items.map((r) => ({ ...r, resetLoading: false, isDeleting: false, isSending: false })) || [],
        total: rentersRes?.total || 0,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const debounceFn = useCallback(debounce(fetchRenters, 500), []);

  const handleSearch = (e) => {
    const value = e.target.value;

    setSearchValue(value);
    debounceFn(1, cSize.current, value);

    if (e.target.value === '') {
      fetchRenters(1, cSize.current, '');
    }
  };

  const getDataSource = () => {
    if (renters) {
      if (searchValue.length) {
        return renters.items;
      }
      if (!searchValue.length) {
        return renters.items;
      }
      if (!searchRenter.length && !searchValue.length) {
        return renters.items;
      }
    }
  };

  const handleSendRentReminder = async (id) => {
    try {
      startRenterAction(id, 'isSending');
      setDropdownVisible(resetDropdown(renters.items));
      await rentReminder(id);
      endRenterAction(id, 'isSending');
      dispatch(displayAlertAction('', 'success', 'Rent Reminder Sent', true, 5000));
    } catch (error) {
      endRenterAction(id, 'isSending');
      dispatch(
        displayAlertAction(
          'Rent Reminder Error',
          'warning',
          'The rent reminder was not sent. Please try again.',
          true,
          5000
        )
      );
    }
  };

  const handleResendInvite = async (id) => {
    console.log('handleResendInvite', id);
    try {
      startRenterAction(id, 'isSending');
      setDropdownVisible(resetDropdown(renters.items));
      await resendInviteV3(cognitoToken, id);
      endRenterAction(id, 'isSending');
      dispatch(displayAlertAction('', 'success', 'Rent Invitation Sent', true, 5000));
    } catch (error) {
      endRenterAction(id, 'isSending');
      dispatch(
        displayAlertAction(
          'Invite Renter Error',
          'warning',
          'The renter invitation was not sent. Please try again.',
          true,
          5000
        )
      );
    }
  };

  const handleDeleteInvitation = async (id) => {
    try {
      startRenterAction(id, 'isDeleting');
      setDropdownVisible(resetDropdown(renters.items));
      setDeleteInvitationText({
        text: 'Delete Invitation',
        color: 'inherit',
        style: { color: 'inherit', fontWeight: 'inherit' },
        action: false,
      });

      await deleteInviteV3(cognitoToken, id);
      dispatch(getV3RentersListAction(cognitoToken, 1, 10000, '', ''));
      const updatedList = searchRenter.filter((sr) => +sr.id !== +id);
      setSearchRenter(updatedList);
      await fetchRenters(cPage.current, cSize.current, searchValue);
      dispatch(displayAlertAction('', 'success', 'Renter Invitation Deleted', true, 5000));

      endRenterAction(id, 'isDeleting');
    } catch (error) {
      console.log(error);
      endRenterAction(id, 'isDeleting');
      dispatch(
        displayAlertAction(
          'Delete Invite Error',
          'warning',
          'The renter invitation was not deleted. Please try again.',
          true,
          5000
        )
      );
    }
  };

  const handleRemoveRenter = async (propertyId, customerId, parentPropertyId, propertyGroupId) => {
    try {
      startRenterAction(customerId, 'isDeleting');
      setDropdownVisible(resetDropdown(renters.items));
      setDeleteRenterText({
        text: 'Remove Renter',
        color: 'inherit',
        style: { color: 'inherit', fontWeight: 'inherit' },
        action: false,
      });
      await removeRenterV3(propertyId, customerId, parentPropertyId, propertyGroupId, cognitoToken);
      dispatch(getV3RentersListAction(cognitoToken, 1, 10000, '', ''));
      const updatedList = searchRenter.filter((sr) => +sr.id !== +customerId);
      setSearchRenter(updatedList);
      await fetchRenters(cPage.current, cSize.current, searchValue);
      dispatch(displayAlertAction('', 'success', 'Renter Successfully Removed From Property', true, 5000));

      endRenterAction(customerId, 'isDeleting');
    } catch (error) {
      endRenterAction(customerId, 'isDeleting');
      dispatch(
        displayAlertAction(
          'Remove Renter Error',
          'warning',
          'Renter was not removed from the property. Please try again.',
          true,
          5000
        )
      );
    }
  };

  const handleViewTransactionHistory = (item) => {
    const activeBillingAccount = item.billingAccounts.filter((ba) => ba.state === 'ACTIVE');
    if (activeBillingAccount.length) {
      dispatch({ type: 'SET_SELECTED_MENU', payload: 'transactions' });
      history.push(`/landlord/transactions?billing_account_id=${activeBillingAccount[0].id}`);
    } else {
      dispatch(
        displayAlertAction('There was an error', 'warning', 'Renter has no active billing account.', true, 5000)
      );
    }
  };
  /*
  const avatarLetters = (name) => {
    console.log(name);

    const nameArr = name.split(' ');
    if (nameArr.length) {
      const initials = [];
      nameArr.forEach((n) => initials.push(n.charAt(0)));
      return initials.join('');
    }
    return name.charAt(0);
  };
  */

  const handleJoyrideCallback = async (data) => {
    const { action } = data;

    if (action === 'close' || action === 'skip') {
      dispatch({
        type: 'SET_SHOW_TOUR',
        payload: {
          showMainTour: false,
        },
      });
    }
  };

  const checkPolicyStatus = (customer) => {
    const ba = customer.billingAccounts.filter((b) => b.state === 'ACTIVE');
    if (ba.length && ba[0].hasPolicy) {
      return true;
    }
    return false;
  };

  const checkRequireInsurance = (customer) => {
    const ba = customer.billingAccounts.filter((b) => b.state === 'ACTIVE');
    if (ba.length) {
      if (ba[0].requireInsurance) return true;
      return false;
    }
    return false;
  };

  const handlePagination = async (page, pageSize) => {
    if (page !== cPage.current || pageSize !== cSize.current) {
      cPage.current = page;
      cSize.current = pageSize;
      await fetchRenters(page, pageSize, searchValue);
    }
  };

  const autoPayProps = (item) => {
    console.log('autoPayProps', item);
    const activeBa = checkActiveBa(item);
    const rcpEnabled = session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement;
    const rcpSet = !isEmpty(item.accountSettings?.compliance);
    const hasScheduledPayments = !!item.accountSettings?.schedulePayments?.has;

    console.log('autoPayProps conditions', activeBa, rcpEnabled, rcpSet, hasScheduledPayments);

    switch (true) {
      case activeBa && rcpEnabled && rcpSet && hasScheduledPayments:
        return {
          color: token.colorSuccess,
          title: 'Auto-pay Active',
          icon: SmileFilled,
          'data-testid': 'autoPayActiveTest',
        };
      case activeBa && rcpEnabled && rcpSet && !hasScheduledPayments:
        return {
          color: token.colorError,
          title: 'Auto-pay Inactive',
          icon: FrownOutlined,
          'data-testid': 'autoPayInactiveTest',
        };
      default:
        return {
          color: token.colorTextDescription,
          title: 'Auto-pay Not Required',
          icon: MehOutlined,
          'data-testid': 'autoPayNotRequiredTest',
        };
    }
  };

  const checkPendingBa = (item) => {
    if (item.billingAccounts) {
      const ba = item.billingAccounts.filter((b) => b.state === 'PENDING');
      if (ba.length) {
        return true;
      }
      return false;
    }
    return true;
  };

  const checkActiveBa = (item) => {
    if (item.billingAccounts) {
      const ba = item.billingAccounts.filter((b) => b.state === 'ACTIVE');
      console.log('checkActiveBa', ba);
      if (ba.length) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <StyledContainer>
      <StyledDrawer
        closable={false}
        // closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
        placement="right"
        width={isDesktop ? 414 : '100vw'}
        destroyOnClose
        onClose={() => {
          props.setDrawerVisible(false);
          props.setShowOnboarding(false);
          props.setShowAccountProfile(false);
          props.setShowAccountPreferences(false);
          props.setShowPlansBillingPanel(false);
          props.setShowBillingDetailsPanel(false);
          form.resetFields();
          setShowTransactionPanel(false);
          setShowPropertyPanel(false);
          setShowInvitationPanel(false);
          setEditInvitationProperty(false);
          setShowEditRenterPanel(false);
          dispatch(setSummaryPaymentType(''));
          dispatch(unsetTransactionRenter());
        }}
        open={props.drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {props.drawerVisible && showEditRenterPanel && editRenter && (
          <RenterDetails
            editRenter={editRenter}
            setDrawerVisible={props.setDrawerVisible}
            setEditRenter={setEditRenter}
            setShowEditRenterPanel={setShowEditRenterPanel}
            userTypeFilter={userTypeFilter}
            fetchRenters={fetchRenters}
            currentPage={cPage.current}
            currentSize={cSize.current}
            sender="renters"
          />
        )}
        {/* props.drawerVisible && props.showOnboarding && (
          <Onboarding
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            checkLandlordStatus={checkLandlordStatus}
            updatePaymentGateway={updatePaymentGateway}
          />
        ) */}
        {props.drawerVisible && showTransactionPanel && (
          <TransactionPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
          />
        )}
        {props.drawerVisible && showPropertyPanel && (
          <PropertyPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPropertyPanel={setShowPropertyPanel}
            editProperty={editProperty}
            editInvitationProperty={editInvitationProperty}
            setShowInvitationPanel={setShowInvitationPanel}
          />
        )}
        {props.drawerVisible && showInvitationPanel && (
          <InvitationPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowInvitationPanel={setShowInvitationPanel}
            setEditProperty={setEditProperty}
            setShowPropertyPanel={setShowPropertyPanel}
            form={form}
            editInvitationProperty={editInvitationProperty}
            setEditInvitationProperty={setEditInvitationProperty}
            setShowTransactionPanel={setShowTransactionPanel}
            fetchRenters={fetchRenters}
            setShowUnitPanel={setShowUnitPanel}
            setSelectedParentProperty={setSelectedParentProperty}
            cPage={cPage}
            cSize={cSize}
          />
        )}
        {/* Moved to LandlordMenu.js */}
        {/* {props.drawerVisible && props.showAccountProfile && (
          <AccountProfilePanel
            setShowAccountProfile={props.setShowAccountProfile}
            setDrawerVisible={props.setDrawerVisible}
          />
        )}
        {props.drawerVisible && props.showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowAccountPreferences={props.setShowAccountPreferences}
          />
        )}
        {props.drawerVisible && props.showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )}
        {props.drawerVisible && props.showBillingDetailsPanel && (
          <BillingDetailsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )} */}
        {props.drawerVisible && showUnitPanel && (
          <UnitPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPropertyPanel={setShowPropertyPanel}
            editProperty={editProperty}
            editInvitationProperty={editInvitationProperty}
            setShowInvitationPanel={setShowInvitationPanel}
            setShowUnitPanel={setShowUnitPanel}
            selectedParentProperty={selectedParentProperty}
          />
        )}
      </StyledDrawer>
      {/* Removed from UI 4.0.0 */}
      {/* <NewsPanel
        title="What's New @PayRent"
        placement="right"
        closable={true}
        onClose={() => {
          props.setNewsDrawerVisible(false);
        }}
        open={props.newsDrawerVisible}
        posts={false}
        isOpen={props.newsDrawerVisible}
      /> */}
      {/* showOnboardingOverlay && (
        <OnboardingOverlay
          showOnboardingOverlay={showOnboardingOverlay}
          setShowOnboardingOverlay={setShowOnboardingOverlay}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
        />
      ) */}

      {showOnboardingFullscreen && (
        <OnboardingFullscreen
          showOnboardingFullscreen={showOnboardingFullscreen}
          setShowOnboardingFullscreen={setShowOnboardingFullscreen}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
          stripeTimer={stripeTimer}
          // checkLandlordStatus={checkLandlordStatus}
        />
      )}
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        // getHelpers={getHelpers}
        run={global.tour.showMainTour && !props.drawerVisible && !props.showOnboarding}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={rentersSteps}
        styles={{
          options: {
            zIndex: 19,
          },
        }}
      />
      {/* <Cards
        setDrawerVisible={props.setDrawerVisible}
        setShowTransactionPanel={setShowTransactionPanel}
        setShowPropertyPanel={setShowPropertyPanel}
        setShowInvitationPanel={setShowInvitationPanel}
      /> */}
      <Space vertical={20} />
      <StyledDiv>
        <InputSearch placeholder="Search" mobilewidth={100} handleSearch={handleSearch} />
      </StyledDiv>
      <Space vertical={25} />
      <div ref={rentersRef} id="anchor">
        <CardWithTitle
          title="Renters"
          className="rentersTour"
          bodyPadding="0px 0px 15px 0px"
          extra={
            <Button
              icon={
                <PlusSquareOutlined style={{ fontSize: isDesktop ? '14px' : '13px', color: theme.colors.white }} />
              }
              type="primary"
              height={isDesktop ? 36 : 27}
              fontSize={isDesktop ? 16 : 12}
              padding1={1}
              padding2={15}
              bottom={0}
              data-modal="invitation"
              onClick={() => {
                setDrawerVisible(true);
                setShowInvitationPanel(true);
              }}
              data-testid="invitePanelTest"
            >
              INVITE A RENTER
            </Button>
          }
        >
          <ConfigProvider
            renderEmpty={() => (
              <EmptyLarge
                description="No renters yet but renter details and status will appear here."
                image={<RentSvg />}
              />
            )}
          >
            <List
              size="small"
              loading={loading}
              itemLayout={isDesktop ? 'horizontal' : 'vertical'}
              // loadMore={loadMore}
              dataSource={getDataSource()}
              data-testid="listTest"
              pagination={{
                onChange: handlePagination,
                showSizeChanger: true,
                pageSize: cSize.current,
                current: cPage.current,
                total: renters ? renters.total : 0,
                pageSizeOptions: [10, 20, 50, 100],
              }}
              renderItem={(item, i) => (
                <List.Item
                  key={i}
                  actions={[
                    <div className="list-tags">
                      <StyledTags className="renterTags">
                        {!!item?.accountVerified ? (
                          <IconBadge
                            color={token.colorPrimary}
                            icon={CheckCircleFilled}
                            title="Active"
                            data-testid="activeIconTest"
                          />
                        ) : (
                          <IconBadge
                            color={token.colorTextDescription}
                            icon={CheckCircleOutlined}
                            title="Not Active"
                            data-testid="notActiveIconTest"
                          />
                        )}{' '}
                        {+item.billingAccounts[0].balance <= 0 ? (
                          <IconBadge
                            color={token.colorSuccess}
                            icon={DollarCircleFilled}
                            title="Paid Up"
                            data-testid="currentBalanceTest"
                          />
                        ) : (
                          <IconBadge
                            color={token.colorError}
                            icon={DollarCircleFilled}
                            title="Balance Due"
                            data-testid="balaceDueTest"
                          />
                        )}
                        {checkRequireInsurance(item) && checkPolicyStatus(item) ? (
                          <IconBadge
                            color={token.colorSuccess}
                            title="Insured"
                            icon={SafetyCertificateFilled}
                            data-testid="insuranceProvidedTest"
                          />
                        ) : checkRequireInsurance(item) && !checkPolicyStatus(item) ? (
                          <IconBadge
                            color={token.colorError}
                            title="Uninsured"
                            icon={SafetyCertificateOutlined}
                            responsive
                            data-testid="insuranceNotProvidedTest"
                          />
                        ) : (
                          <IconBadge
                            color={token.colorTextDescription}
                            icon={SafetyCertificateOutlined}
                            title="Insurance Not Required!"
                            data-testid="insuranceNotRequiredTest"
                          />
                        )}
                        <IconBadge {...autoPayProps(item)} />
                        {/* {session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement &&
                          (item.billingAccounts[0].property?.settings?.complianceLevel === 1 ||
                            item.billingAccounts[0].property?.settings?.complianceLevel === 2) &&
                          !isEmpty(item.billingAccounts[0].property?.settings?.compliance) &&
                          (item.billingAccounts[0].property.settings.compliance.paymentMethods.isCompliant &&
                          item.billingAccounts[0].property.settings.compliance.scheduledPayments.isCompliant ? (
                            <IconBadge color="green" title="Auto-pay Active" />
                          ) : (
                            <IconBadge color="grey" title="Auto-pay Not Required" />
                          ))}
                        {session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement &&
                          (item.billingAccounts[0].property?.settings?.complianceLevel === 1 ||
                            item.billingAccounts[0].property?.settings?.complianceLevel === 2) &&
                          !isEmpty(item.billingAccounts[0].property?.settings?.compliance) &&
                          (!item.billingAccounts[0].property.settings.compliance.paymentMethods.isCompliant ||
                          !item.billingAccounts[0].property.settings.compliance.scheduledPayments.isCompliant ? (
                            <IconBadge color="red" title="Auto-pay Inactive" />
                          ) : (
                            <IconBadge color="grey" title="Auto-pay Not Required" />
                          ))} */}
                      </StyledTags>
                    </div>,
                  ]}
                  extra={
                    <RenterMenu
                      item={item}
                      i={i}
                      setEditRenter={setEditRenter}
                      setDrawerVisible={props.setDrawerVisible}
                      setShowEditRenterPanel={setShowEditRenterPanel}
                      handleSendRentReminder={handleSendRentReminder}
                      handleResendInvite={handleResendInvite}
                      handleDeleteInvitation={handleDeleteInvitation}
                      handleRemoveRenter={handleRemoveRenter}
                      deleteInvitationText={deleteInvitationText}
                      setDeleteInvitationText={setDeleteInvitationText}
                      deleteRenterText={deleteRenterText}
                      setDeleteRenterText={setDeleteRenterText}
                      dropdownVisible={dropdownVisible}
                      setDropdownVisible={setDropdownVisible}
                      handleViewTransactionHistory={handleViewTransactionHistory}
                      resetDropdown={resetDropdown}
                      rentersList={renters}
                      isDesktop={isDesktop}
                      checkPendingBa={checkPendingBa}
                      loading={loading}
                    />
                  }
                >
                  <Skeleton avatar title={false} loading={item?.loading} active>
                    <List.Item.Meta
                      title={
                        <>
                          <StyledTitle color="black" size={16} strong>
                            {item?.firstName} {item?.lastName} {item?.name}
                          </StyledTitle>
                          {!isDesktop && <br />}
                          <StyledTitle color="violet" size={16}>
                            <a href={`mailto:${item?.email}`}>{item?.email}</a>
                          </StyledTitle>
                        </>
                      }
                      description={
                        <>
                          <StyledDescription
                            role="button"
                            onClick={() =>
                              history.push(
                                `/landlord/properties?address=${item?.billingAccounts[0].property?.address}&id=${item?.billingAccounts[0].property?.id}`
                              )
                            }
                            color="black"
                            size={16}
                            data-testid="descriptionTest"
                          >
                            <StyledTitle>
                              {item?.billingAccounts[0].property?.address}, #
                              {item?.billingAccounts[0].property?.address2
                                ? item?.billingAccounts[0].property?.address2
                                : '1'}
                            </StyledTitle>
                          </StyledDescription>
                          <AntSpace>
                            <StyledTitle color="black">
                              Current Balance: ${item?.billingAccounts[0].balance}{' '}
                            </StyledTitle>
                            <ResetButton
                              record={{
                                details: { ...item, billingAccount: item.billingAccounts[0] },
                                resetLoading: item.resetLoading,
                              }}
                              handleResetBalance={handleResetBalance}
                            />
                          </AntSpace>
                        </>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </ConfigProvider>
        </CardWithTitle>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .ant-list-pagination {
    margin-top: 16px !important;
    margin-right: 15px !important;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .renterTags {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    max-width: 200px;
    gap: 8px 0;
  }

  .ant-list-item-meta-title {
    line-height: 0.75 !important;
  }

  @media screen and (max-width: 575px) {
    .listContent {
      display: none;
    }
    .ant-list-item-action {
      display: inline;
      float: right;
      margin-top: -106px;
    }
    .ant-list-vertical .ant-list-item-action > li:first-child {
      padding-right: 5px;
    }

    .renterTags .customTag:nth-child(4) .ant-tag {
      margin-top: 5px;
      margin-bottom: 12px;
    }

    .ant-list-vertical .ant-list-item-meta-title {
      margin-bottom: 0px !important;
      line-height: 21px;
    }
  }
  @media screen and (max-width: 475px) {
    .renterTags .customTag:nth-child(4) .ant-tag {
      margin-top: 12px;
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 392px) {
    .ant-list-item-meta-description,
    .ant-list-item-meta-title {
      max-width: 230px;
    }
  }
`;

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 1500px) {
        width: 460px !important;
      }
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    input {
      color: #595959;
    }
    @media screen and (max-height: 750px) {
      .ant-steps-small .ant-steps-item-description {
        font-size: 12px !important;
      }
    }
    @media screen and (max-height: 750px) {
      .ant-drawer-body h1 {
        font-size: 35px !important;
      }
      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }
  `}
`;

const StyledTags = styled.div``;

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

const StyledTitle = styled(Text)`
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
  @media screen and (max-width: 392px) {
    font-size: 12px;
  }
`;

const StyledDescription = styled.div`
  cursor: pointer;
  width: fit-content;
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
  @media screen and (max-width: 392px) {
    font-size: 12px;
  }
`;

export default Renters;
