import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';

const EditRenter = (props) => {
  const { item } = props;

  const handleEditRenterDetails = (item) => {
    props.setEditRenter(item);
    props.setShowEditRenterPanel(true);
    props.setDrawerVisible(true);
  };

  return (
    <IconButton
      toolTitle="Edit Renter Details"
      icon={<EditOutlined />}
      handleClick={() => handleEditRenterDetails(item)}
      data-testid="editRenterTest"
    />
  );
};

export default EditRenter;
