import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Select, Form, theme } from 'antd';

import './styles.scss';

import Button from 'components/Button';

const { Option } = Select;

const PropertyRentPolicy = (props) => {
  const history = useHistory();
  const { token } = theme.useToken();

  return (
    <Form.Item style={{ position: 'relative' }}>
      {!props.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement && (
        <DisabledOverlay>
          <Button
            color="yellow"
            onClick={() => {
              history.push('/landlord/service-plans');
              if (props.setDrawerVisible) props.setDrawerVisible(false);
              if (props.setShowAccountPreferences) props.setShowAccountPreferences(false);
              if (props.setShowUnitPanel) props.setShowUnitPanel(false);
            }}
          >
            UPGRADE
          </Button>
        </DisabledOverlay>
      )}
      <Form.Item noStyle name="complianceLevel">
        <StyledSelect
          token={token}
          placeholder="Rent Collection Policy"
          className="rent-policy-select"
          disabled={!props.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement}
          data-testid="rentCollectionTest"
        >
          <Option value={0}>Rent Collection Policy: None</Option>
          <Option value={1}>
            Rent Collection Policy: Relaxed
            <ul className="policy-option">
              <li>1 Recurring Payment</li>
              <li>Automatic late fee</li>
            </ul>
          </Option>
          <Option value={2}>
            Rent Collection Policy: Strict
            <ul className="policy-option">
              <li>2 payment methods</li>
              <li>1 Recurring Payment</li>
              <li>Automatic late fee</li>
              <li>Failover to backup payment method</li>
            </ul>
          </Option>
        </StyledSelect>
      </Form.Item>
    </Form.Item>
  );
};

const StyledSelect = styled(Select)`
  height: 50px !important;
  font-size: ${(props) => props.token.fontSize}px !important;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const DisabledOverlay = styled.div`
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 5px;
`;
export default PropertyRentPolicy;
