import { theme } from 'antd';
import AlertFade from 'components/AlertFade/AlertFade';
import React from 'react';
import { StyledAlertWrapper, StyledSideBar, StyledVideoWrapper } from '../WepayOffboarding.styles';
import MigrationSteps from './MigrationSteps';
import { AnimatePresence, motion } from 'framer-motion';
import Text from 'components/Text/Text';

function MigrationSidebar(props) {
  const { alert } = props;

  return (
    <StyledSideBar vertical gap="small" className="sidebar-wrapper" data-testid="migrationSidebarTest">
      <MigrationSteps {...props} />
      <Text as="div" color="black">
        Learn More
      </Text>
      <StyledVideoWrapper className="video-wrapper" data-testid="videoboxTest">
        <iframe
          style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', border: 0 }}
          src="https://pymt.fyi/5Ckzrot7pJG2"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </StyledVideoWrapper>
      <AnimatePresence>
        <StyledAlertWrapper>
          <AlertFade type={alert.type} isVisible={!!alert.message} message={alert.message} />
        </StyledAlertWrapper>
      </AnimatePresence>
    </StyledSideBar>
  );
}

export default MigrationSidebar;
