import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import { documentsPresignedUrl } from 'services/api/common/create';
import { openInNewTab } from 'resources/helpers';

import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';

const PolicyDetails = (props) => {
  const { userPolicyDetail } = props;
  const session = useSelector((store) => store.session);
  const uploadedInsuranceFile = useSelector((store) => store.tenant.uploadedInsuranceFile);
  const [message, setMessage] = useState({ message: '', type: 'success' });

  const downloadPolicy = async () => {
    try {
      if (!uploadedInsuranceFile) {
        openInNewTab('https://insurance.sureapp.com/sign-in');
        return;
      }
      const ba = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      const res = await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        ba[0].id,
        'download',
        '',
        'insurance'
      );
      window.open(res.presignedUrl);
    } catch (error) {
      setMessage({ message: 'There was a problem with your request', type: 'warning' });
    }
  };

  return (
    <>
      <StyledContainer className="content-container">
        <Row justify="center" gutter={[0, 20]}>
          <Col span={10}>
            <Space vertical={20} />
            <Text color="black" size={16} strong>
              {userPolicyDetail?.tenant?.name}
            </Text>
            <br />
            <Text color="black" size={10}>
              Applied to: {userPolicyDetail?.tenant?.address}
            </Text>
          </Col>
          <Col span={10}>
            <Space vertical={20} />
            <Text color="black" size={16} strong>
              Landlord Contact Info:
            </Text>
            <br />
            <Text color="black" size={10}>
              {userPolicyDetail?.landlord?.name}
            </Text>
            <br />
            <Text color="violet" size={10}>
              {userPolicyDetail?.landlord?.email}
            </Text>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 20]}>
          <Col span={20}>
            <InsuranceContainer>
              <Text color="black" size={14} strong centered>
                Renters Insurance
              </Text>
              <Space vertical={20} />
              <Text color="black" size={12} centered>
                View your complete insurance policy by visiting Sure, the insurance brokerage. If you have any
                questions contact Sure directly at (844) 335-5441
              </Text>
              <Space vertical={20} />

              <Button color="violet" alignment="center" onClick={downloadPolicy} data-testid="viewPolicyTest">
                VIEW POLICY
              </Button>

              <Text color="black" size={12} strong>
                Policy Number:
              </Text>
              <Text color="black" size={12}>
                {' '}
                {userPolicyDetail?.policy?.policyNumber}
              </Text>
              <br />
              <Text color="black" size={12} strong>
                Status:
              </Text>
              <Text color="black" size={12}>
                {' '}
                {userPolicyDetail?.policy?.status}
              </Text>
              <br />
              <Text color="black" size={12} strong>
                Carrier:
              </Text>
              <Text color="black" size={12}>
                {' '}
                {userPolicyDetail?.policy?.carrier}
              </Text>
              <br />
              <Text color="black" size={12} strong>
                Liability Amount:
              </Text>
              <Text color="black" size={12}>
                {' '}
                {userPolicyDetail?.policy?.liability && `$${userPolicyDetail?.policy?.liability}`}
              </Text>
              <br />
              <Text color="black" size={12} strong>
                Start Date:
              </Text>
              <Text color="black" size={12}>
                {' '}
                {userPolicyDetail?.policy?.startDate}
              </Text>
              <br />
              <Text color="black" size={12} strong>
                End Date:
              </Text>
              <Text color="black" size={12}>
                {' '}
                {userPolicyDetail?.policy?.endDate}
              </Text>
              <br />
              <Text color="black" size={12} strong>
                Billing Cycle:
              </Text>
              <Text color="black" size={12}>
                {' '}
                {userPolicyDetail?.policy?.billingCycle === 'monthly' ? 'Monthly (11 cycles)' : 'Annualy'}
              </Text>
            </InsuranceContainer>
          </Col>
        </Row>
      </StyledContainer>
      <PanelFooter>
        <AlertFade
          type={message.type}
          isVisible={!!message.message}
          message={message.message}
          alertPosition="absolute"
          position="absolute"
          minHeight="80"
          width={70}
          bottom={82}
        />

        <Button
          color="violet"
          alignment="center"
          onClick={() => {
            props.setInsuranceDrawer(false);
            props.setShowPolicyDetails(false);
          }}
          data-testid="clodeButtonTest"
        >
          CLOSE
        </Button>
      </PanelFooter>
    </>
  );
};

const StyledContainer = styled.div`
  height: calc(100% - 180px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const InsuranceContainer = styled.div`
  background-color: #f7f7f0;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px 10px;
`;

export default PolicyDetails;
