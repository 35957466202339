import styled from 'styled-components';

export const StyledPageHeader = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin-top: 25px;
  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;
