import React from 'react';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';

import Text from 'components/Text';
import Space from 'components/Space';

const GoLikeAPro = () => {
  return (
    <>
      <Text size={24} strong color="black" centered>
        Go-Like-A-Pro
      </Text>
      <Space vertical={20} />
      <div style={{ paddingRight: '50px', paddingLeft: '50px', lineHeight: '35px' }}>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          $49 per month for 10 units
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          $0 bank transfers
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          Faster funding
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          More rent payment controls
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          Rent Collection Enforcement
        </Text>
      </div>
    </>
  );
};

const StyledCheck = styled(CheckOutlined)`
  color: #541388;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export default GoLikeAPro;
