import { Steps, Row, Flex } from 'antd';
import CardBlank from 'components/CardBlank/CardBlank';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledCard = styled(CardBlank)`
  margin: 8px auto;
  max-width: 960px;
  .ant-card-body {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledSteps = styled(Steps)`
  .ant-steps-item-description {
    font-size: 13px !important;
    color: ${(props) => props.token.colorTextSecondary} !important;
  }

  .ant-steps-item-wait .ant-steps-item-description {
    color: ${(props) => props.token.colorTextQuaternary} !important;
  }
`;

export const StyledRow = styled(Row)`
  width: 100%;
`;

export const StyledSideBar = styled(Flex)`
  margin-right: 8px;
`;

export const MotionMainContent = styled(motion.div)`
  @media screen and (min-width: 768px) {
    padding-left: 18px;
    border-left: 1px solid ${(props) => props.bordercolor};
  }
`;

export const StyledVideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
`;

export const StyledAlertWrapper = styled.div`
  min-height: 74px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const StyledTransactionWrapper = styled.div`
  border: 1px solid ${(props) => props.token?.colorBorder};
  border-radius: ${(props) => props.token?.borderRadius}px;
  box-shadow: ${(props) => props.token?.boxShadowTertiary};
  padding: 12px 16px;
`;
