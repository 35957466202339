import React from 'react';
import { Card } from 'antd';
import Text from 'components/Text/Text';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DeleteOutlined } from '@ant-design/icons';
import AlertFade from 'components/AlertFade/AlertFade';
import UploadButton from 'components/UploadButton/UploadButton';
import { numberTOrdinalSuffix } from 'resources/helpers';
import styled from 'styled-components';

const { Meta } = Card;

dayjs.extend(utc);

function LeaseDetails(props) {
  const {
    record,
    baDetails,
    loading,
    uploading,
    uploadAlert,
    handleEditRenter,
    handleEditProperty,
    handleDownloadFile,
    customRequest,
    deleteFile,
  } = props;

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <div style={{ height: '96px', paddingTop: '20px' }}>
              <Meta
                description={
                  <>
                    <strong>Start Date:</strong>{' '}
                    <Text
                      color="violet"
                      underline
                      pointer
                      size={14}
                      onClick={() => {
                        handleEditRenter(record.details);
                      }}
                    >
                      {baDetails && dayjs(baDetails.startDate).utc().format('MM-DD-YYYY')}
                    </Text>
                    <br /> <strong>End Date:</strong>{' '}
                    <Text
                      color="violet"
                      underline
                      pointer
                      size={14}
                      onClick={() => {
                        handleEditRenter(record.details);
                      }}
                    >
                      {baDetails && dayjs(baDetails.endDate).utc().format('MM-DD-YYYY')}
                    </Text>
                    <br /> <strong>Extend Month-to-Month:</strong>{' '}
                    <Text
                      color="violet"
                      underline
                      pointer
                      size={14}
                      onClick={() => {
                        handleEditRenter(record.details);
                      }}
                    >
                      {record?.details?.billingAccount?.extendMonthToMonthFlag ? 'Yes' : 'No'}
                    </Text>
                  </>
                }
              />
            </div>
          </td>
          <td>
            <div style={{ height: '96px', paddingTop: '20px' }}>
              <Meta
                description={
                  <>
                    <strong>Rent Amount:</strong>{' '}
                    <Text
                      color="violet"
                      underline
                      pointer
                      size={14}
                      onClick={() => handleEditProperty(record.details)}
                    >
                      ${record?.details?.rentAmount.toFixed(2)}
                    </Text>
                    <br /> <strong>Rent Due Date:</strong>{' '}
                    <Text
                      color="violet"
                      underline
                      pointer
                      size={14}
                      onClick={() => handleEditProperty(record.details)}
                    >
                      {numberTOrdinalSuffix(record.details.rentDueDay)} of the month
                    </Text>
                    <br /> <strong>Lease Document:</strong>{' '}
                    {baDetails?.leaseDocument && (
                      <>
                        <Text
                          color="violet"
                          underline
                          pointer
                          size={14}
                          onClick={() => handleDownloadFile(record, baDetails?.leaseDocument)}
                        >
                          {baDetails?.leaseDocument}
                        </Text>
                        &nbsp; &nbsp;
                        <DeleteOutlined
                          className="delete-file-icon"
                          onClick={() => deleteFile(baDetails?.leaseDocument)}
                          data-testid="deleteLeaseTest"
                        />
                      </>
                    )}
                  </>
                }
              />
            </div>
          </td>
          <td style={{ verticalAlign: 'bottom', textAlign: 'center', minWidth: '150px' }}>
            {!loading && (
              <>
                <AlertContainer className="fileAlert">
                  <AlertFade
                    type={uploadAlert.type}
                    message={uploadAlert.message}
                    isVisible={uploadAlert.visible}
                    position="absolute"
                  />
                </AlertContainer>

                <UploadButton
                  config={{
                    accept: '.pdf,.jpg,.jpeg,.png',
                    showUploadList: false,
                    customRequest: (info) => customRequest(info),
                  }}
                  text="Upload Lease"
                  uploading={uploading}
                  disabled={baDetails?.leaseDocument}
                />
              </>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const AlertContainer = styled.div`
  position: relative;
  height: 43px;
  display: flex;
  justify-content: center;
`;

export default LeaseDetails;
