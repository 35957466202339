import { Montserrat } from 'resources/vgsFonts';

export const vgsCss = {
  visibility: 'hidden',
  opacity: 0,
  fontSize: '14px',
  border: '1px solid #D9D9D9',
  'background-clip': 'padding-box',
  boxSizing: 'border-box',
  width: '100%',
  borderRadius: '2px',
  padding: '4px 11px',
  color: '#595959',
  '@font-face': {
    'font-family': 'Montserrat',
    'font-style': 'normal',
    'font-weight': '400',
    src: Montserrat,
  },
  fontFamily: '"Montserrat"',
  fontVariant: 'tabular-nums',
  fontFeatureSettings: 'tnum, "tnum"',
  '&:focus': {
    border: '1px solid #ffdb29',
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f5f5f5',
    cursor: 'not-allowed',
    opacity: '1',
  },
  '@media screen and (max-width: 400px)': {
    'font-size': '14px !important',
  },
};
