import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Spin } from 'antd';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getBillingDetails } from 'services/api/landlordApi/read';

import SubscriptionForm from './components/SubscriptionForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscriptionContainer = (props) => {
  const {
    setDrawerVisible,
    checkLandlordStatus,
    setBoardingStatus,
    getOnboardingStatus,
    setShowOnboarding,
    boardingStatus,
    stepTitle,
    stepIcon,
    stepStatus,
    globalLoading,
  } = props;
  const session = useSelector((store) => store.session);

  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'warning' });

  const [options, setOptions] = useState({
    clientSecret: null,
    appearance: {
      theme: 'none',
      rules: {
        '.Input::placeholder': {
          color: '#595959',
        },
        '.Input': {
          border: '1px solid #D9D9D9',
          color: '#595959',
          fontFamily: `'Montserrat', 'Poppins', sans-serif`,
          fontSize: '14px',
          padding: '8px',
        },
        '.Input:focus-visible': {
          outline: '0',
        },
        '.Input:focus': {
          borderColor: '#FFCC00',
          borderRightWidth: '1px !important',
        },
        '.Tab:hover': {
          color: '#595959',
        },
        '.Tab--selected': {
          borderColor: '#D9D9D9',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px yellow',
        },
        '.Input--invalid': {
          boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
        },
      },
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);

      setOptions({ ...options, clientSecret: plansBillingRes.clientSecret });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {!options.clientSecret && (
        <LoadingContainer>
          <Spin
            spinning={!options.clientSecret}
            style={{ alignSelf: 'center', justifySelf: 'center', width: '100%' }}
          />
        </LoadingContainer>
      )}

      {!!options.clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <SubscriptionForm
            setDrawerVisible={setDrawerVisible}
            checkLandlordStatus={checkLandlordStatus}
            setBoardingStatus={setBoardingStatus}
            getOnboardingStatus={getOnboardingStatus}
            setShowOnboarding={setShowOnboarding}
            boardingStatus={boardingStatus}
            stepTitle={stepTitle}
            stepIcon={stepIcon}
            stepStatus={stepStatus}
            globalLoading={globalLoading}
          />
        </Elements>
      )}
    </>
  );
};

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
export default SubscriptionContainer;
