import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { capSentence } from 'utils';

import { itemType, itemTypeColor } from 'resources/helpers';
import * as tenantApi from 'services/api/tenantApi';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (text) => (
      <span className="weight-500" data-testid="upcomingDate">
        {text}
      </span>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text) => {
      return (
        <span className={itemTypeColor(text)} data-testid="upcomingType">
          {capSentence(itemType(text))}
        </span>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (pbi) => {
      let style = 'color-green weight-500';
      if (pbi.billingItemType === 'CHARGE') {
        style = 'color-red weight-500';
      }
      return (
        <span className={style} data-testid="upcomingAmount">
          {pbi.balanceDue && pbi.billingCharge && pbi.billingCharge.amount}
          {!pbi.balanceDue &&
            pbi.billingCharge &&
            `$${
              typeof pbi.billingCharge.amount === 'number'
                ? pbi.billingCharge.amount.toFixed(2)
                : pbi.billingCharge.amount
            }`}
        </span>
      );
    },
  },
];

const UpcomingCharges = (props) => {
  const session = useSelector((store) => store.session);
  const AuthStr = session.cognitoTokens.idToken.jwtToken;
  const [loading, setLoading] = useState(false);
  const [upcomingItems, setUpcomingItems] = useState([]);

  const mountedRef = useRef(true);

  useEffect(() => {
    getSoonestScheduledPayment();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const compare = (a, b) => {
    if (a.billingCharge.chargeDate < b.billingCharge.chargeDate) {
      return -1;
    }
    if (a.billingCharge.chargeDate > b.billingCharge.chargeDate) {
      return 1;
    }
    return 0;
  };

  const getSoonestScheduledPayment = async () => {
    try {
      setLoading(true);
      const upcomingRes = await tenantApi.getBillingItems(
        'state:DELAYED_PROCESSED',
        null,
        'orderDate',
        'payment,billingCharge',
        10,
        1
      );
      const result = await tenantApi.getSoonestScheduledPayment(AuthStr);

      const charges = upcomingRes;
      let items = [];
      if (result.length) {
        for (let i = 0; i < result.length; i++) {
          result[i].billingCharge = {
            chargeDate: result[i].nextScheduledDate,
            amount: result[i].balanceDue ? 'Balance due' : result[i].fixAmount,
            chargeType: 'CREDIT',
          };
          result[i].billingItemType = 'CREDIT';
          result[i].scheduledPayment = true;
        }

        items = [...charges, ...result];
      } else {
        items = [...charges];
      }

      const sorted = items.sort(compare).slice(0, 10);
      setUpcomingItems(sorted);
      setLoading(false);
      if (!mountedRef.current) return null;
    } catch (error) {
      const charges = props.upcomingBillingItems;
      const sorted = charges.sort(compare);
      setUpcomingItems(sorted);
      setLoading(false);
    }
  };

  const parseBillingData = (uc) => {
    const parsed = uc.map((pbi) => {
      return {
        key: pbi.id,
        date: pbi?.scheduledPayment
          ? moment.utc(pbi.billingCharge?.chargeDate).format('MM-DD-YYYY')
          : moment.utc(pbi.billingCharge?.chargeDate).format('MM-DD-YYYY'),
        type: pbi.billingCharge?.chargeType,
        amount: pbi,
      };
    });
    return parsed;
  };

  return (
    <StyledTable
      size="small"
      bordered={false}
      showHeader={false}
      columns={columns}
      dataSource={parseBillingData(upcomingItems)}
      pagination={false}
      loading={loading}
    />
  );
};

const StyledTable = styled((props) => <Table {...props} data-testid="upcomingTable" />)`
  td {
    border: none !important;
    padding: 4px 8px !important;
    font-size: 14px;
  }
`;

export default UpcomingCharges;
