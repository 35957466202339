import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { CloseSquareFilled } from '@ant-design/icons';
import parser from 'parse-address';
import { usStates } from 'resources/FormData';
import { v4 } from 'uuid';
import { Form, Row, Col, Input, Upload, DatePicker } from 'antd';
import { createParentProperty, createProperty, updateCreatePropertyFees } from 'services/api/landlordApi/create';
import { updateParentProperty } from 'services/api/landlordApi/update';
import { getProperties, getPropertiesV3 } from 'services/api/landlordApi/read';
import SpaceResponsive from 'components/SpaceResponsive';
import PlacesAutocompleteInput from 'components/PlacesAutocompleteInput';
import Space from 'components/Space';
import Text from 'components/Text';
import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';
import Button from 'components/Button';

// import './styles.scss';

import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../../components/Panel/PanelHeader/PanelHeader';

const PropertyPanel = (props) => {
  const {
    setDrawerVisible,
    setShowPropertyPanel,
    propertyGroup,
    fetchProperties,
    currentPage,
    editProperty,
    properties,
    setExpandedRowKeys,
    expandedRowKeys,
    handleExpand,
    setEditProperty,
    setShowUnitPanel,
    setSelectedParentProperty,
  } = props;
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [addressInput, setAddressInput] = useState('');
  const [addressInitValue, setAddressInitValue] = useState('');
  const [formDays, setFormDays] = useState([]);
  const [locationData, setLocationData] = useState(null);
  const [googleString, setGoogleString] = useState(null);
  const googleSelected = useRef(null);
  const customAddressInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState({
    type: 'success',
    message: '',
    description: '',
    closable: false,
    marginBottom: 0,
  });

  const check = (obj, arr) => {
    return arr.every((e) => Object.keys(obj).includes(e));
  };

  useEffect(() => {
    if (editProperty) {
      // console.log(editProperty);
      // console.log(properties);
      const property = properties.items.filter((p) => p.id === editProperty);

      const parsedAddress = parser.parseLocation(property[0].address);
      let streetNumber = '';
      let streetName = '';
      if (parsedAddress) {
        streetNumber = parsedAddress?.number;
        streetName = property[0].address.replace(`${parsedAddress.number} `, '');
      } else {
        streetName = property[0].address.split(',')[0];
      }

      setLocationData({
        address: streetName,
        streetNumber,
        city: property[0].city,
        country: 'United States',
        google_place_id: property[0].googlePlaceId,
        lat: property[0].lat,
        long: property[0].long,
        state: property[0].state,
        zip: property[0].zip,
      });

      const addressName =
        property[0].city !== ''
          ? `${property[0].address}, ${property[0].city}, ${property[0].state}, ${property[0].zip}`
          : `${property[0].address}, ${property[0].state}, ${property[0].zip}`;
      setAddressInitValue(addressName);
      form.setFieldsValue({
        acquisitionDate: property[0].acquisitionDate ? moment(property[0].acquisitionDate) : '',
        acquisitionPrice: property[0].acquisitionPrice,
        currentValue: property[0].currentValue,
        annualDebtService: property[0].annualDebtService,
        annualTaxes: property[0].annualTaxes,
        annualInsurance: property[0].annualInsurance,
        annualMaintCosts: property[0].annualMaintCosts,
        annualMgmtFees: property[0].annualMgmtFees,
      });
    }
  }, []);

  useEffect(() => {
    if (!addressInput && (googleSelected.current || customAddressInput.current)) {
      setLocationData(null);
      googleSelected.current = false;
      customAddressInput.current = false;
      form.validateFields(['address']);
    }
    if (addressInput && addressInput !== googleString) {
      const addr = parser.parseLocation(addressInput);

      if (addr) {
        const validKeyNames = ['city', 'number', 'state', 'street', 'zip'];

        const validAddr = check(addr, validKeyNames);
        if (validAddr) {
          const usState = usStates.filter((s) => s.abbreviation === addr.state.toUpperCase());
          const customAddress = {
            streetNumber: addr.number,
            address: addr.type ? `${addr.street} ${addr.type}` : addr.street,
            city: addr.city,
            state: usState[0].name.charAt(0).toUpperCase() + usState[0].name.slice(1).toLowerCase(),
            google_place_id: v4(),
            lat: 0,
            long: 0,
            zip: addr.zip,
            country: 'United States',
          };
          setLocationData({ ...customAddress });
          customAddressInput.current = true;
        } else {
          customAddressInput.current = false;
          setLocationData(null);
          form.validateFields(['address']);
        }
      }
    }
  }, [addressInput]);

  const addressValidator = () => {
    if (!addressInitValue) {
      if (!locationData) {
        return Promise.reject('Please select the street address.');
      }

      if (addressInput.trim().length === 0) {
        return Promise.reject('Please select the street address.');
      }
      return Promise.resolve();
    }
    return Promise.resolve();
  };

  const handlePlacesAutocomplete = (data) => {
    googleSelected.current = true;
    setLocationData(data);
    form.validateFields();
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      let newUnit = false;
      if (editProperty) {
        await updateParentProperty(
          session.cognitoTokens.idToken.jwtToken,
          editProperty,
          propertyGroup[0].id,
          locationData,
          values
        );
      } else {
        const parentProp = await createParentProperty(
          session.cognitoTokens.idToken.jwtToken,
          propertyGroup[0].id,
          locationData,
          values
        );
        const units = await getProperties(false, 1, 5, false, false, `parentPropertyId:${parentProp.id}`);

        if (!units.length) {
          const defaultUnitValues = {
            unit: 1,
            rentAmount: 0,
            rentDay: 1,
            commercial: false,
            bedrooms: 1,
            bathrooms: 1,
            propertyGroup: propertyGroup[0].id,
            parentPropertyId: +parentProp.id,
            acceptCreditCard: propertyGroup[0].settings.acceptCreditCard,
            preventPayment: propertyGroup[0].settings.preventPayments,
            fullPayment: propertyGroup[0].settings.requireFullPayment,
            absorbFees: propertyGroup[0].settings.absorbFees,
            percent: propertyGroup[0].settings.absorbFeesPercent,
            complianceLevel: propertyGroup[0].settings.complianceLevel,
          };
          const unitRes = await createProperty(locationData, defaultUnitValues, 0);

          const defaultFees = {
            securityDepositSwitch: propertyGroup[0].settings.propertyFees.securityDeposit,
            securityDepositAmount: propertyGroup[0].settings.propertyFees.securityDepositAmount,
            daysEarlySwitch: propertyGroup[0].settings.propertyFees.discount,
            daysEarly: propertyGroup[0].settings.propertyFees.discountDays,
            discountAmountEarly: propertyGroup[0].settings.propertyFees.discountAmount,
            daysLateSwitch: propertyGroup[0].settings.propertyFees.lateFee,
            lateFeeFrequency: 'ONE-TIME',
            daysLateFee: propertyGroup[0].settings.propertyFees.lateFeeDays,
            lateFeeAmount: propertyGroup[0].settings.propertyFees.lateFeeAmount,
            parkingFeeSwitch: propertyGroup[0].settings.propertyFees.parking,
            periodParkingFee: propertyGroup[0].settings.propertyFees.parkingFrequency,
            parkingFeeAmount: propertyGroup[0].settings.propertyFees.parkingAmount,
            moveInFeeSwitch: propertyGroup[0].settings.propertyFees.moveIn,
            moveInFee: propertyGroup[0].settings.propertyFees.moveInAmount,
            moveOutFeeSwitch: propertyGroup[0].settings.propertyFees.moveOut,
            moveOutFee: propertyGroup[0].settings.propertyFees.moveOutAmount,
          };
          await updateCreatePropertyFees(session.cognitoTokens.idToken.jwtToken, defaultFees, unitRes.id);

          // dispatch(getPropertiesListAction(session.cognitoTokens.idToken.jwtToken, 1, 10000, '', ''));
          const unitListRes = await getPropertiesV3(session.cognitoTokens.idToken.jwtToken, parentProp.id);
          parentProp.propertyGroupId = propertyGroup[0].id;
          setEditProperty(unitListRes[0]);

          console.log('Parent prop', parentProp);
          setSelectedParentProperty(parentProp);
          newUnit = true;
          const record = { id: parentProp.id };
          handleExpand(null, record);
        }
      }

      setIsLoading(false);
      setMessage({
        type: 'success',
        message: editProperty ? 'Property updated' : 'Property created',
        description: '',
        closable: false,
        marginBottom: 0,
      });
      await fetchProperties(propertyGroup[0].id, currentPage, false);
      setTimeout(() => {
        setMessage({
          ...message,
          message: '',
        });
        if (!newUnit) {
          setShowPropertyPanel(false);
          setDrawerVisible(false);
        } else {
          setShowPropertyPanel(false);
          setShowUnitPanel(true);
        }
      }, 3000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMessage({
        type: 'warning',
        message: error.data?.errors[0].detail || 'There was an error with your request',
        description: '',
        closable: false,
        marginBottom: 0,
      });
    }
  };

  const checkPrice = (rule, value) => {
    if (Number(value) >= 0) {
      return Promise.resolve();
    }
    if (isNaN(Number(value))) {
      return Promise.reject('Amount is not a valid number.');
    }
    return Promise.reject('Not a valid amount');
  };

  return (
    <>
      <div data-testid="propertiesContainerTest" className="property-panel">
        <StyledForm
          form={form}
          name="parentProperty"
          onFinish={onFinish}
          // onFinishFailed={() => {}}
          // onValuesChange={onValuesChange}
          initialValues={{
            acquisitionPrice: null,
            currentValue: null,
            annualDebtService: null,
            annualTaxes: null,
            annualInsurance: null,
            annualMaintCosts: null,
            annualMgmtFees: null,
          }}
        >
          <Header>
            <CloseSquareFilled
              style={{ fontSize: '25px', color: '#122C34' }}
              onClick={() => {
                setShowPropertyPanel(false);
                setDrawerVisible(false);
              }}
              data-testid="closePropertiesTest"
            />
          </Header>
          <Title className="panel-title">Property</Title>

          <Subtitle>Add or edit your property details</Subtitle>
          <SpaceResponsive lg={10} md={10} sm={10} />
          <FormContentContainer className="content-container">
            <Row gutter={16} className="general-settings">
              <Col span={24}>
                Address
                <Form.Item
                  name="address"
                  data-testid="addressTest"
                  rules={[
                    {
                      validator: addressValidator,
                    },
                  ]}
                  help="Begin typing address then select from the list."
                >
                  <PlacesAutocompleteInput
                    callback={handlePlacesAutocomplete}
                    setGoogleString={setGoogleString}
                    // groupId={groupId}
                    setAddressInput={setAddressInput}
                    addressValidator={addressValidator}
                    addressInitValue={addressInitValue}
                    setAddressInitValue={setAddressInitValue}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Space vertical={20} />
            <Text size={16} color="black" strong>
              Property Details (optional)
            </Text>
            <Space vertical={20} />
            <Subtitle>
              These optional details make your dashboard metrics accurate. We don&apos;t sell or share them.
            </Subtitle>
            <Space vertical={20} />
            <Row gutter={15}>
              <Col span={12}>
                Acquisition Date
                <Form.Item name="acquisitionDate">
                  <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" placeholder="mm/dd/yyyy" />
                </Form.Item>
              </Col>
              <Col span={12}>
                Acquisition Price
                <Form.Item
                  name="acquisitionPrice"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input prefix="$" data-testid="amountTest" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                Current Value
                <Form.Item
                  name="currentValue"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input prefix="$" />
                </Form.Item>
              </Col>
              <Col span={12}>
                Ann. Debt Service
                {/* <Tooltip
                  placement="left"
                  title="Total mortgage and other loan payments made on the property."
                  trigger="click"
                >
                  <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ssnTooltip" />
                </Tooltip> */}
                <Form.Item
                  name="annualDebtService"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input prefix="$" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                Ann. Taxes
                <Form.Item
                  name="annualTaxes"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input prefix="$" />
                </Form.Item>
              </Col>
              <Col span={12}>
                Ann. Insurance
                <Form.Item
                  name="annualInsurance"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input prefix="$" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                Ann. Maintenance
                <Form.Item
                  name="annualMaintCosts"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input prefix="$" />
                </Form.Item>
              </Col>
              <Col span={12}>
                Ann. Mgmt. Fees
                <Form.Item
                  name="annualMgmtFees"
                  rules={[
                    {
                      validator: checkPrice,
                    },
                  ]}
                >
                  <Input prefix="$" />
                </Form.Item>
              </Col>
            </Row>
          </FormContentContainer>
          <PanelFooter>
            <AlertFade
              type={message.type}
              isVisible={!!message.message}
              message={message.message}
              description={message.description}
              alertPosition="absolute"
              position="absolute"
              minHeight="40"
              closable={message.closable}
              marginBottom={message.marginBottom}
              // width={90}
              bottom={82}
            />

            <SaveItem className="save-form-item">
              <Button
                type="primary"
                htmlType="submit"
                bottom={0}
                color="violet"
                loading={isLoading}
                alignment="center"
                data-testid="buttonTest"
                size="large"
              >
                {editProperty ? 'SAVE' : 'NEXT'}
              </Button>
            </SaveItem>
          </PanelFooter>
        </StyledForm>
      </div>
    </>
  );
};

// const DrawerContainer = styled.div`
//   max-width: 100%;
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;

//   border-radius: 24px;
//   .general-settings.ant-row .ant-form-item {
//     margin-bottom: 5px;
//   }
//   .ant-input,
//   .ant-select-selection-item,
//   input {
//     font-size: 16px;
//   }
//   .ant-select-selection-item {
//     font-size: 16px;
//   }
//   .ant-select-selector {
//     height: 31px !important;
//     color: #595959;
//   }
//   .rent-policy-select .ant-select-selector {
//     height: 50px !important;
//   }

//   @media screen and (max-height: 750px) {
//     min-height: 96%;
//     .ant-form-item.save-form-item {
//       margin-bottom: 0px !important;
//     }
//     .content-container .ant-form-item {
//       margin-bottom: 10px !important;
//     }
//   }

//   @media screen and (max-width: 480px) {
//     max-width: 100%;
//     .ant-picker {
//       width: 150px;
//     }
//     .ant-col.ant-col-5.absorbField {
//       flex: 0 0 27%;
//       max-width: 30%;
//     }
//   }
// `;

// const StyledUpload = styled(Upload)`
//   .ant-upload.ant-upload-select-picture-card {
//     width: 100% !important;
//     height: 150px !important;
//   }
// `;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  @media screen and (max-height: 750px) {
    margin-bottom: 0px !important;
  }
`;

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 25px 0px 25px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

export default PropertyPanel;
