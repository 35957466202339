import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Space, Tag, theme, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const DynamicTags = (props) => {
  // console.log('[DynamicTags.js] props:', props);
  const { item, updateDocumentTags, maxCount } = props;
  const { token } = theme.useToken();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [addTagVisible, setAddTagVisible] = useState(item.tags.user.length < maxCount);
  const [localTags, setLocalTags] = useState({ system: [], user: [] });
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      return inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    // console.log('[DynamicTags.js] useEffect on item:', item);
    setLocalTags(item?.tags || {});
  }, [item]);

  const handleClose = (removedTag) => {
    const newTags = localTags.user.filter((tag) => tag !== removedTag);
    setLocalTags((prev) => ({ ...prev, user: newTags }));
    setAddTagVisible(newTags.length < maxCount);
    updateDocumentTags(newTags, item);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !localTags.user.includes(inputValue)) {
      const newTags = [...localTags.user, inputValue];
      setLocalTags((prev) => ({ ...prev, user: newTags }));
      setAddTagVisible(newTags.length < maxCount);
      updateDocumentTags(newTags, item);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const tagInputStyle = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  };

  const tagPlusStyle = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  return (
    <Space size={[0, 8]} wrap>
      {localTags?.system?.length
        ? localTags?.system?.map((tag, i) => (
            <Tag key={`system-${i}`} closable={false} color="blue">
              {tag}
            </Tag>
          ))
        : null}
      {localTags?.user?.length
        ? localTags?.user?.map((tag, index) => {
            const isLongTag = tag.length > 20;

            const tagElem = (
              <Tag
                key={`user-${index}`}
                closable
                style={{
                  userSelect: 'none',
                }}
                onClose={() => handleClose(tag)}
                color="purple"
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={index}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })
        : null}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : addTagVisible ? (
        <Tag key="new-tag" style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
          New Tag
        </Tag>
      ) : null}
    </Space>
  );
};

DynamicTags.propTypes = {
  item: PropTypes.object.isRequired,
  updateDocumentTags: PropTypes.func.isRequired,
  maxCount: PropTypes.number,
};

DynamicTags.defaultProps = {
  maxCount: 5,
};

export default DynamicTags;
