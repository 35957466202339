import { Button, Card, Space, theme } from 'antd';
import React from 'react';
import AlertFade from 'components/AlertFade';
import TenantSignupForm from './TenantSignupForm';
import Text from 'components/Text/Text';
import { useHistory } from 'react-router';
import Title from 'components/Title';

export default function TenantSignup(props) {
  const { token } = theme.useToken();

  const cardStyle = {
    maxWidth: '380px',
    padding: '0',
  };
  return (
    <div style={{ margin: '0 12px' }}>
      <Card style={cardStyle}>
        <div style={{ height: 90, textAlign: 'center' }}>
          <Title data-testid="tenantFormTitle" level={3} color={token.colorTextBase}>
            Tenant Sign-Up
          </Title>
          <AlertFade type="error" isVisible={props.authError.error} message={props.authError.message} />
        </div>
        <Space vertical={20} />
        <TenantSignupForm {...props} />
      </Card>
      <div data-testid="bottomText">
        <Text color={'white'}>Have an account? </Text>
        <Button onClick={() => props.history.push('/sign-in')} type="link" data-testid="signinButtonTest">
          Sign In
        </Button>
      </div>
    </div>
  );
}
