import React from 'react';
import Text from 'components/Text/Text';
import { Space } from 'antd';

function PropertyFaqs() {
  const faqs = [
    {
      q: 'What is a property?',
      a: 'A property represents a single address or location. If the property is multi-unit, it is the primary address',
    },
    {
      q: 'Can I add multiple units to a property?',
      a: 'Yes properties can be multi-unit. You can enter as many units as you like later on the Properties screen.',
    },
    {
      q: 'Do I need to complete the property details?',
      a: 'Property details are optional but they help us to provide you with summary data about your portfolio.',
    },
  ];
  return faqs.map((faq, index) => (
    <Space direction="vertical" key={index}>
      <Text color="black" strong>
        {faq.q}
      </Text>
      <Text color="black">{faq.a}</Text>
    </Space>
  ));
}

export default PropertyFaqs;
