import React, { useState } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as tenantApi from 'services/api/tenantApi';
import { displayAlert } from 'resources/helpers';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Button from 'components/Button';

import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';

const StripeCreditCard = (props) => {
  const { setDrawerVisible, options } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'success', message: '' });

  const elements = useElements();
  const stripe = useStripe();

  const saveCreditCard = async () => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setLoading(true);
      const { error, setupIntent } = await stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'https://my-site.com/account/payments/setup-complete',
        },
      });

      console.log(setupIntent);

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (e.g., payment
        // details incomplete)

        const errorObject = {
          message: error.message,
        };

        throw errorObject;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

      setupIntent.stripeCustomerId = options.stripe_id;

      const result = await tenantApi.createACHPaymentMethod(
        cognitoToken,
        null,
        session.userData.paymentGateway,
        'creditCard',
        setupIntent,
        +session.userData.id
      );

      console.log(result);

      displayAlert(setMessage, 'Credit Card successfully saved.', 'success', 5000);
      setLoading(false);
      setTimeout(() => {
        setDrawerVisible(false);
      }, 4500);
    } catch (error) {
      console.log(error);

      setLoading(false);
      displayAlert(
        setMessage,
        error.data?.errors[0].detail || error?.message || 'There was an error with you request.',
        'warning',
        5000
      );
    }
  };
  return (
    <>
      <StyledContainer>
        <PaymentElement />
      </StyledContainer>
      <PanelFooter>
        <AlertFade
          type={message.type}
          isVisible={!!message.message}
          message={message.message}
          alertPosition="absolute"
          position="absolute"
          minHeight="100"
          width={90}
          bottom={82}
        />
        <Button
          loading={loading}
          onClick={saveCreditCard}
          disabled={!stripe || !elements || message.message}
          alignment="center"
          color="violet"
          bottom={0}
        >
          Save
        </Button>
      </PanelFooter>
    </>
  );
};

const StyledContainer = styled.div`
  margin: 0 30px 0 30px;
`;

export default StripeCreditCard;
