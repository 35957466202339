import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileType from 'file-type/browser';
import axios from 'axios';
import { documentsPresignedUrl } from 'services/api/common/create';
import { displayAlertAction } from 'store/actions/globalActions';

const useFileManager = (props) => {
  // console.log('ƒ useFileLoader:', props);
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // useEffect(() => {
  //   console.log('ƒ useEffect on useFileLoader:', props);
  // }, []);

  const uploadFile = async (id, info, action, type, allowedFileTypes = ['pdf']) => {
    // console.log('ƒ uploadFile:', id, info, action, type, allowedFileTypes);
    setLoading(true);
    try {
      const { file, name } = info;
      const fileType = await FileType.fromBlob(file);

      if (fileType === undefined) throw Error('Invalid file type');

      if (!allowedFileTypes.includes(fileType.ext)) {
        throw Error('Invalid File Type');
      }

      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }

      const res = await documentsPresignedUrl(session.cognitoTokens.idToken.jwtToken, id, action, name, type);

      const instance = axios.create({
        headers: { 'Content-Type': file.type },
      });

      delete instance.defaults.headers.common['Authorization'];
      delete instance.defaults.headers.common['Accept'];

      const uploadRes = await instance.put(res.presignedUrl, file);

      setSuccess(true);
      return uploadRes;
    } catch (err) {
      const { response } = err;
      setError(response || err);
      setSuccess(false);
    }
    setLoading(false);
  };

  const downloadFile = async (id, action, filename, type, ext, path) => {
    try {
      const res = await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        id,
        action,
        filename,
        type,
        ext,
        path
      );
      window.open(res.presignedUrl);
    } catch (error) {
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const getFileUrl = async (id, action, filename, type, ext, path) => {
    // console.log('ƒ getFileUrl:', id, action, filename, type, ext, path);
    try {
      const res = await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        id,
        action,
        filename,
        type,
        ext,
        path
      );
      return res.presignedUrl;
    } catch (error) {
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const deleteFile = async (record, filename) => {
    try {
      await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        record.details.billingAccount.id,
        'delete',
        filename,
        'lease'
      );
    } catch (error) {
      const { response } = error;
      throw response;
    }
  };
  return { loading, error, success, uploadFile, downloadFile, deleteFile, getFileUrl };
};

export default useFileManager;
