import React from 'react';
import MaskedInput from 'antd-mask-input';
import styled from 'styled-components';

function SsnInput(props) {
  return (
    <StyledMaskedInput
      className="masked-input ant-input ant-input-success ant-input-outlined"
      placeholder="987 65 4321"
      mask="000-00-0000"
      data-testid="ssnTest"
      {...props}
    />
  );
}

const StyledMaskedInput = styled(MaskedInput)`
  :hover {
    border-color: #541388;
  }
  :focus-within {
    border-color: #541388;
    box-shadow: 0 0 0 2px rgba(36, 3, 48, 0.27) !important;
  }
  border-radius: 4px;
`;

export default SsnInput;
