import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Montserrat } from 'resources/vgsFonts';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { getBillingDetails } from 'services/api/landlordApi/read';
import Space from 'components/Space';
import Text from 'components/Text';
import SpaceResponsive from 'components/SpaceResponsive';
import BillingForm from './components/BillingForm';

import { boardingElement, boardingText } from '../../animation/variants';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscriptionPayment = (props) => {
  const { setSilaAccountSetup, setSubscriptionPayment } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const [options, setOptions] = useState({
    clientSecret: null,
    appearance: {
      theme: 'none',
      rules: {
        '.Input::placeholder': {
          color: '#595959',
        },
        '.Input': {
          border: '1px solid #D9D9D9',
          color: '#595959',
          fontFamily: `'Montserrat', 'Poppins', sans-serif`,
          fontSize: '14px',
          padding: '8px',
          marginBottom: '13px',
        },
        '.Input:focus-visible': {
          outline: '0',
        },
        '.Input:focus': {
          borderColor: '#FFCC00',
          borderRightWidth: '1px !important',
        },

        '.Label': {
          fontFamily: `'Montserrat', 'Poppins', sans-serif`,
        },
        '.Tab:hover': {
          color: '#595959',
        },
        '.Tab--selected': {
          borderColor: '#D9D9D9',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px yellow',
        },
        '.Input--invalid': {
          boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 1px var(--colorDanger)',
          marginBottom: '7px',
        },
      },
    },
    CustomFontSource: {
      family: 'Avenir',
      src: Montserrat,
      weight: '500',
    },
  });

  const fetchData = async () => {
    try {
      const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);
      if (!plansBillingRes?.clientSecret && plansBillingRes.paymentMethod) {
        setSubscriptionPayment(false);
        setSilaAccountSetup(true);
      } else {
        setOptions({ ...options, clientSecret: plansBillingRes.clientSecret });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong centered>
          Add a Payment Method
        </Text>
      </motion.div>
      <SpaceResponsive lg={15} xmd={10} md={10} sm={10} />
      <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave">
        <Text size={16} color="black">
          You have selected a paid subscription which requires a credit card on file to keep your account active
          after the trial period ends. If you cancel your subscription before your trial ends you will not be
          charged.
        </Text>
        <Space vertical={25} />
        <Spin
          spinning={!options.clientSecret}
          style={{ alignSelf: 'center', justifySelf: 'center', width: '100%' }}
        />
        {!!options.clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <BillingForm
              setSubscriptionPayment={setSubscriptionPayment}
              setSilaAccountSetup={setSilaAccountSetup}
            />
          </Elements>
        )}
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: #ffffff;
  min-height: 80%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
  @media screen and (max-width: 400px) {
    padding: 22px 10px 15px 10px;
  }
`;

export default SubscriptionPayment;
