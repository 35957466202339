// @flow
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import styled, { css } from 'styled-components';

const AlertFade = (props) => {
  // There are 4 types of Alert: success, info, warning, error

  const {
    isVisible,
    message,
    type,
    description,
    marginTop,
    display,
    closable,
    clickFunction,
    position,
    width,
    minHeight,
    alertPosition,
    top,
    addClass,
    onClose,
    bottom,
    marginBottom,
    section,
    isCollapsed,
  } = props;

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 576);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const style = {
    // overflow: 'hidden',
    height: 0,
    opacity: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: marginBottom ? `${marginBottom}px` : '10px',
    zIndex: 50,
    width: '100%',
  };
  if (minHeight) style.minHeight = `${minHeight}px`;
  if (position) style.position = position;
  if (top) style.top = `${top}px`;
  if (bottom) style.bottom = `${bottom}px`;
  if (width) style.width = `${width}%`;
  if (isDesktop) {
    if (section === 'mainAlert') {
      style.width = `calc(100% - ${isCollapsed ? '80' : '200'}px)`;
      style.position = isDesktop ? 'absolute' : 'fixed';
    }
  }

  if (display) delete style.display;
  if (marginTop) style.marginTop = marginTop;

  useEffect(() => {
    if (isDesktop) {
      if (section === 'mainAlert') {
        style.width = `calc(100% - ${isCollapsed ? '80' : '200'}px)`;
        style.position = isDesktop ? 'absolute' : 'fixed';
      }
    }
  }, [isDesktop, isCollapsed]);

  return (
    /**
     <Transition
      items={isVisible}
      from={style}
      enter={{
        height: 'auto',
        opacity: 1,
      }}
      leave={{
        height: 0,
        opacity: 0,
      }}
    >
      {(esVisible) =>
        esVisible &&
        ((transitionProps) => (
          <div style={transitionProps}>
            <StyledAlert
              width={width}
              alertPosition={alertPosition}
              message={message}
              description={description}
              type={type}
              showIcon
              closable={closable}
              data-testid="alertTest"
              className={addClass}
              onClick={clickFunction}
              onClose={onClose}
            />
          </div>
        ))
      }
    </Transition>
     
     */
    isVisible && (
      <StyledAlert
        width={width}
        alertPosition={alertPosition}
        message={message}
        description={description}
        type={type}
        showIcon
        closable={closable}
        data-testid="alertTest"
        className={addClass}
        onClick={clickFunction}
        onClose={onClose}
        position={position}
        bottom={bottom}
        banner
      />
    )
  );
};

const StyledAlert = styled(Alert)`
  ${(props) => {
    if (props.width) {
      return css`
        width: ${props.width}%;
      `;
    }
  }};
  ${(props) => {
    if (props.alertPosition) {
      return css`
        position: ${props.alertPosition};
      `;
    }
  }};
  ${(props) => {
    if (props.position) {
      return css`
        position: ${props.position};
      `;
    }
  }};
  ${(props) => {
    if (props.bottom) {
      return css`
        bottom: ${props.bottom}px;
      `;
    }
  }};
  margin-bottom: 10px;
  z-index: 19;
  animation: fadeIn linear 0.2s;
  -webkit-animation: fadeIn linear 0.2s;
  -moz-animation: fadeIn linear 0.2s;
  -o-animation: fadeIn linear 0.2s;
  -ms-animation: fadeIn linear 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
`;

AlertFade.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
};

AlertFade.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
};

export default AlertFade;
