import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import Text from 'components/Text';
import { Table, Tag, Spin } from 'antd';
import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import TransactionDescription from './TransactionDescription';

const columns = [
  {
    title: 'Transaction Details',
    dataIndex: 'date',
    key: 'date',
    colSpan: 2,
    align: 'left',
    width: '30%',
  },
  {
    title: '',
    dataIndex: 'amount',
    key: 'amount',
    colSpan: 0,
    align: 'center',
    width: 110,
  },
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    colSpan: 0,
    render: (status) => {
      const StatusColor = {
        Pending: 'gold',
        Billed: 'green',
        Credited: 'green',
        Paid: 'green',
        Failed: 'red',
        Posted: 'green',
      };

      return <Tag color={StatusColor[status]}>{status}</Tag>;
    },
  },
  {
    title: '',
    dataIndex: '',
    key: '',
    colSpan: 2,
    align: 'left',
  },
];

const getLabel = (item) => {
  if (item.billingCharge) {
    const billingCharge = item.billingCharge;

    switch (billingCharge.chargeType) {
      case 'LATE_FEE':
        return 'Late fee';
      case 'MISC_FEE':
        return 'Fee';
      case 'CHARGEBACK':
        return 'Chargeback';
      case 'RENT':
        return 'Rent charge';
      case 'DEPOSIT':
        return 'Deposit';
      case 'PAYMENT':
        return 'Payment';
      case 'CREDIT':
        return 'Credit';
      case 'REFUND':
        return 'Refund';
      case 'SECURITY_DEPOSIT':
        return 'Security Deposit';
      case 'UTILITY':
        return 'Utility';
      case 'PARKING':
        return 'Parking';
      case 'MOVE_IN':
        return 'Move In';
      case 'MOVE_OUT':
        return 'Move Out';
      case 'DISCOUNT':
        return 'Discount';
      default:
        return 'Unknown charge type';
    }
  } else if (item.payment) {
    switch (item.payment.type) {
      case 'payments':
        return 'Payment';
      default:
        return 'Unknown charge type';
    }
  }
};

const TransactionsHistory = (props) => {
  const transactionHistory = useSelector((store) => store.tenant.transactionHistory.items) || [];
  const totalCount = useSelector((store) => store.tenant.transactionHistory.totalCount);
  const loading = useSelector((store) => store.tenant.transactionHistory.loading);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [prevFilter, setPrevFilter] = useState();

  if (prevFilter !== props.filterType) {
    setPrevFilter(props.filterType);
    if (expandedKeys.length) {
      setExpandedKeys([]);
    }
  }

  useEffect(() => {
    setPrevFilter(props.filterType);
  }, []);

  const data = props.items
    ? props.items.map((item, i) => {
        let status = '';

        switch (true) {
          case item.refType === 'BillingCharge' && item.state === 'POSTED':
            status = item.billingItemType === 'CHARGE' ? 'Billed' : 'Credited';
            break;
          case item.refType === 'BillingCharge' && item.state === 'PROCESSING':
            status = 'Pending';
            break;
          case item.refType === 'BillingCharge' && item.state === 'DELAYED_PROCESSED':
            status = 'Scheduled';
            break;
          case item.refType === 'Payment' && item.state === 'PROCESSED':
            status = 'Paid';
            break;
          case item.refType === 'Payment' && item.state !== 'PROCESSED':
            status = item.state === 'PROCESSING' ? 'Pending' : 'Failed';
            break;
          default:
            break;
        }

        const object = {
          key: i,
          date: `${moment(item.orderDate).format('MM-DD-YYYY')} ${getLabel(item)}`,
          amount: (
            <Text color={item.billingItemType === 'CHARGE' ? 'red' : 'green'}>
              {item.billingItemType === 'CREDIT' && '-'}$
              {item.billingCharge ? item.billingCharge.amount.toFixed(2) : item.payment.amount}
            </Text>
          ),
          status,
          detail: item,
        };
        return object;
      })
    : [];

  const onRow = ({ key }) => expandedKeys.includes(key) && { className: 'expand-parent' };

  const onExpand = (expanded, { key }) => {
    const keys = expandedKeys;
    const expandedK = expanded ? keys.concat(key) : keys.filter((k) => k !== key);
    setExpandedKeys(expandedK);
  };

  // const handleTableChange = (pagination, filters, sorter) => {
  //   console.log('Table change', pagination, filters, sorter);
  // };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={props.handleLoadMore}
      hasMore={transactionHistory.length < totalCount}
      loader={
        totalCount !== null && (
          <div style={{ textAlign: 'center', margin: '20px 0' }} key={0}>
            {' '}
            <Spin size="middle" />{' '}
          </div>
        )
      }
      threshold={500}
    >
      <StyledTable
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        loading={loading}
        onRow={onRow}
        onExpand={onExpand}
        expandedRowKeys={expandedKeys}
        expandable={{
          expandedRowRender: (record) => <TransactionDescription record={record} />,
          expandIconColumnIndex: 3,
          rowExpandable: (record) => record.name !== 'Not Expandable',
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <ShrinkOutlined
                style={{ fontSize: '20px', color: '#CB47B8' }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <ExpandAltOutlined
                style={{ fontSize: '20px', color: '#CB47B8' }}
                onClick={(e) => onExpand(record, e)}
                data-testid="expandTest"
              />
            ),
        }}
      />
    </InfiniteScroll>
  );
};

const StyledTable = styled((props) => <Table {...props} />)`
  th.ant-table-row-expand-icon-cell {
    display: none;
  }

  .expand-parent td {
    border-bottom: 0px;
  }

  th.ant-table-cell {
    font-weight: 600;
    font-size: 14px;
  }

  @media screen and (max-width: 400px) {
    .ant-table-row-expand-icon-cell {
      span {
        font-size: 18px !important;
      }
    }
    tr.ant-table-expanded-row {
      td.ant-table-cell {
        border-left: 2px solid #efefef;
        border-right: 2px solid #efefef;
        border-bottom: 2px solid #efefef;
      }
    }

    td.ant-table-cell {
      font-size: 12px !important;
      span {
        font-size: 13px !important;
      }
    }
    td.ant-table-row-expand-icon-cell {
      span {
        font-size: 18px !important;
      }
    }
  }
`;

export default TransactionsHistory;
