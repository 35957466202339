// @flow
import React from 'react';
import Drawer from 'components/Drawer/Drawer';
import AccountProfilePanel from 'screens/Landlord/components/AccountProfilePanel/AccountProfilePanel';
import AccountPreferencesPanel from 'screens/Landlord/components/AccountPreferencesPanel/AccountPreferencesPanel';
import PlansBillingsPanel from 'screens/Landlord/components/PlansBillingsPanel/PlansBillingsPanel';
import BillingDetailsPanel from 'screens/Landlord/components/BillingDetailsPanel/BillingDetailsPanel';

const AccountSettingsDrawer = (props) => {
  const { visiblePanel, setVisiblePanel, drawerVisible, resetPanel, displayAlert, alert, drawerExpanded } = props;
  return (
    <Drawer
      data-testid="accountSettingsDrawerTest"
      placement="right"
      width={drawerExpanded ? '100%' : 414}
      alertMessage={alert.message}
      alertType={alert.type}
      onClose={() => resetPanel()}
      open={drawerVisible}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      {visiblePanel === 'profile' ? (
        <AccountProfilePanel resetPanel={resetPanel} displayAlert={displayAlert} />
      ) : visiblePanel === 'preferences' ? (
        <AccountPreferencesPanel resetPanel={resetPanel} displayAlert={displayAlert} />
      ) : visiblePanel === 'plans' ? (
        <PlansBillingsPanel
          resetPanel={resetPanel}
          displayAlert={displayAlert}
          setVisiblePanel={setVisiblePanel}
        />
      ) : visiblePanel === 'billing' ? (
        <BillingDetailsPanel resetPanel={resetPanel} displayAlert={displayAlert} />
      ) : null}
    </Drawer>
  );
};

export default AccountSettingsDrawer;
