import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { Empty } from 'antd';

import {
  identityVerification,
  SSNVerification,
  businessVerification,
  FEINVerification,
  kycKybMap,
} from 'resources/SilaDocs';

import { displayAlert, truncateString } from 'resources/helpers';
import Text from 'components/Text';
import Space from 'components/Space';
import KycDocs from 'components/KycDocs';

const acceptableFileTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];

const KycDocuments = (props) => {
  const { onboardingStatus, setAlert, fetchData, setUploadState, uploadState, setShowUpload, showUpload } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const [uploadingState, setUploadingState] = useState({
    kycDocs1: false,
    kycDocs2: false,
    kybDocs1: false,
    kybDocs2: false,
  });

  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const trashFile = (index) => {
    const keptFiles = uploadState.fileList.filter((f, i) => i !== index);
    const fileSource = uploadState.fileList[index].source;

    setUploadState({ fileList: keptFiles, uploading: false });
    setShowUpload((prev) => {
      console.log(prev);
      return { ...prev, [fileSource]: true };
    });
  };

  useEffect(() => {
    // console.log('ƒ useEffect on groupOnboardingStatus', groupOnboardingStatus);
    // map tags to upload components
    const { documents } = onboardingStatus;
    if (documents) {
      const documentKeys = Object.entries(documents)
        .filter((entry) => entry[1].length !== 0)
        .map((e) => e[0]);
      console.log('documents required for', documentKeys);
      documentKeys.forEach((key) => {
        const uploadSetting = kycKybMap[key];
        setShowUpload((prev) => ({ ...prev, ...uploadSetting }));
      });
    }
    return () => console.log('cancel');
  }, [onboardingStatus]);

  const customRequest = async (file) => {
    // console.log('ƒ customRequest', file);
    try {
      const { info, source, documentType } = file;

      if (info.file.type === undefined) throw Error('Invalid file type');

      if (!acceptableFileTypes.includes(info.file.type)) {
        throw Error('Invalid File Type');
      }
      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }

      // setUploading(true);
      setUploadingState((prev) => {
        return { ...prev, [source]: true };
      });

      setUploadState({ ...uploadState, fileList: [...uploadState.fileList, file] });
      setShowUpload({ ...showUpload, [source]: false });

      setUploadingState((prev) => {
        return { ...prev, [source]: false };
      });

      // const files = [];
      // if (uploadState.fileList.length) {
      //   for (let i = 0; i < uploadState.fileList.length; i++) {
      //     files.push();
      //   }
      //
      //   await Promise.all(files);
      //
      //   setUploadState({ ...uploadState, fileList: [] });
      // }
      // const ext = type.mime === 'image/jpeg' ? 'jpeg' : type.ext;
      // setSilaVerificationDocs(false);
      // openPlaid();
    } catch (error) {
      setUploadingState({
        kycDocs1: false,
        kycDocs2: false,
        kybDocs1: false,
        kybDocs2: false,
      });
      displayAlert(setAlert, error?.message || 'There was an error with your request', 'warning', 4000);
    }
  };

  return (
    <>
      <Space vertical={20} />
      <Text size={16} centered color="black">
        Please upload the following documents to confirm your identity:
      </Text>
      <Space vertical={10} />
      {/*uploadState.fileList.map((f, i) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PaperClipOutlined style={{ fontSize: '14px', marginRight: '5px' }} />
          <span style={{ flexGrow: 2 }}>{f.name}</span>

          <DeleteOutlined style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => trashFile(i)} />
        </div>
      ))*/}
      <Space vertical={10} />
      {showUpload.kycDocs1 && (
        <KycDocs
          id="kycDocs1"
          key="kyc-docs-1"
          title="Please provide valid a photo Id"
          placeholder="Select Itendity Document"
          files={identityVerification}
          setUploadState={setUploadState}
          uploadState={uploadState}
          customRequest={customRequest}
          accept={acceptableFileTypes}
          showUpload={showUpload}
          setShowUpload={setShowUpload}
          uploadingState={uploadingState}
        />
      )}

      {showUpload.kycDocs2 && (
        <KycDocs
          id="kycDocs2"
          key="kyc-docs-2"
          title="Please provide tax verification"
          placeholder="Select Tax Document"
          files={SSNVerification}
          setUploadState={setUploadState}
          uploadState={uploadState}
          customRequest={customRequest}
          accept={acceptableFileTypes}
          showUpload={showUpload}
          setShowUpload={setShowUpload}
          uploadingState={uploadingState}
        />
      )}
      {showUpload.kybDocs1 && (
        <KycDocs
          id="kybDocs1"
          key="kyb-docs-1"
          title="Please provide business verification"
          placeholder="Select Business Document"
          files={businessVerification}
          setUploadState={setUploadState}
          uploadState={uploadState}
          customRequest={customRequest}
          accept={acceptableFileTypes}
          showUpload={showUpload}
          setShowUpload={setShowUpload}
          uploadingState={uploadingState}
        />
      )}
      {showUpload.kybDocs2 && (
        <KycDocs
          id="kybDocs2"
          key="kyb-docs-2"
          title="Please provide business tax verification"
          placeholder="Select Business Tax Document"
          files={FEINVerification}
          setUploadState={setUploadState}
          uploadState={uploadState}
          customRequest={customRequest}
          accept={acceptableFileTypes}
          showUpload={showUpload}
          setShowUpload={setShowUpload}
          uploadingState={uploadingState}
        />
      )}

      <Text color="black" size={14} key="text">
        Uploaded Documents
      </Text>
      <AnimatePresence>
        <UploadContainer
          key="upload-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 3 } }}
        >
          {uploadState.fileList.length ? (
            uploadState.fileList.map((file, i) => {
              const f = file.info.file;
              return (
                <div
                  style={{ display: 'flex', alignItems: 'center', marginTop: '4px', marginBottom: '4px' }}
                  key={f.uid}
                >
                  <PaperClipOutlined style={{ fontSize: '14px', marginRight: '5px' }} />
                  <span style={{ flexGrow: 2 }}>{truncateString(f.name, 30, true)}</span>

                  <DeleteOutlined style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => trashFile(i)} />
                </div>
              );
            })
          ) : (
            <Empty
              style={{ margin: 0 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              imageStyle={{ height: 28 }}
              description="No Documents"
            />
          )}
        </UploadContainer>
      </AnimatePresence>
    </>
  );
};

const UploadContainer = styled(motion.div)`
  margin-top: 8px;
  text-overflow: ellipsis;
  border: 1px dashed #d9d9d9;
  padding: 12px;
`;

export default KycDocuments;
