import React from 'react';
import { theme, Flex } from 'antd';
import Text from 'components/Text';
import Space from 'components/Space/Space';
import { StyledTransactionWrapper } from '../WepayOffboarding.styles';

const ServicePlanPricing = (props) => {
  // console.log('[ServicePlanPricing.js]', props);
  const { item, showTransactionPricing } = props;
  const { token } = theme.useToken();

  const transactionPricingMap = (sp) => {
    return (
      <ul>
        <li>
          <Text strong color="black">
            {(sp.achRateFee * 100).toFixed(1)}% + ${sp.achTrxFee} per ACH
          </Text>
        </li>
        <li>
          {(sp.debitRateFee * 100).toFixed(1)}% + ${sp.debitTrxFee} Debit
        </li>
        <li>
          {(sp.creditCardRateFee * 100).toFixed(1)}% + ${sp.creditCardTrxFee} Credit
        </li>
      </ul>
    );
  };

  return (
    <Flex wrap="wrap" gap={24}>
      <div className="subscription-summary">
        <Text as="div" strong size={48} color="black">
          ${item.subscriptionFee || 0}{' '}
          <Text strong color="black">
            per month
          </Text>
        </Text>
        <Text as="div" color="black">
          Includes {item.properties < 0 ? 'unlimited' : item.properties} units <br /> ($
          {item.propUnitPrice?.toFixed(2) || 0} per additional unit)
        </Text>
      </div>
      {showTransactionPricing ? (
        <StyledTransactionWrapper token={token} className="transaction-pricing">
          <Text color="black">Transaction Fees:</Text>
          <Space vertical={8} />
          {transactionPricingMap(item)}
        </StyledTransactionWrapper>
      ) : null}
    </Flex>
  );
};

export default ServicePlanPricing;
