import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Form, Checkbox } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { vgsCss } from 'utils/vgs';

import { updateTenantKyc, getTenantBoardingStatus } from 'services/api/tenantApi';
import { submitAppForOnboarding, uploadSilaDoc } from 'services/api/landlordApi/create';

import { displayAlert } from 'resources/helpers';
import { getUserDataV3 } from 'store/actions/sessionActions';

import DocsReceived from 'components/DocsReceived';

import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import PanelFooter from 'components/Panel/PanelFooter';

import IdentityVerification from './components/IdentityVerification';
import KycDocuments from './components/KycDocuments';

const SilaKyc = (props) => {
  const { setDrawerVisible, setShowSilaKyc, setShowPayment, silaOnboardingStatus, setSilaOnboardingStatus } =
    props;
  const dispatch = useDispatch();

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [identityVerification, setIdentityVerification] = useState(true);
  const [uploadDocs, setUploadDocs] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });
  const [vgsForm, setVgsForm] = useState({});
  const [isLoaded, scriptLoaded] = useState(false);
  // const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [docsReceived, setDocsReceived] = useState(false);
  const [termsConditions, setTermsConditions] = useState(false);

  const [uploadState, setUploadState] = useState({
    fileList: [],
    uploading: false,
  });

  const [showUpload, setShowUpload] = useState({
    kycDocs1: false,
    kycDocs2: false,
    kybDocs1: false,
    kybDocs2: false,
  });

  const [uploadsFinished, setUploadsFinished] = useState(false);

  useEffect(() => {
    // console.log('ƒ useEffect on showUpload', showUpload);
    setUploadsFinished(!Object.values(showUpload).includes(true));
  }, [showUpload]);

  // check if boarding is required
  // what happens if the landlord didnt board yet?
  // check if kyc lite or docs are required - it will depend on the type of property
  // if rentamount is greater than 7000 which docs are required?

  useEffect(() => {
    const createVgsForm = window.VGSCollect.create(
      process.env.REACT_APP_VGS_VAULT,
      process.env.REACT_APP_VGS_ENV,
      (s) => {
        if (s) {
          // if (s.businessEin?.isDirty) form.validateFields(['businessEin']);
          // if (s.routingNumber?.isDirty) form.validateFields(['routingNumber']);
          // if (s.bankAccountNumber?.isDirty) form.validateFields(['bankAccountNumber']);
        }
      }
    );

    const ssn_number = {
      type: 'text',
      name: 'emptyssn',
      // placeholder: 'AAA-GG-SSSS',
      fontFamily: 'Montserrat',
      css: vgsCss,
    };
    if (identityVerification) {
      createVgsForm.field('#emptyssn .fake-input', ssn_number);
      setVgsForm(createVgsForm);
      scriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (silaOnboardingStatus.state === 'DOCUMENTS_REQUIRED' || silaOnboardingStatus.state === 'REVIEW') {
      setIdentityVerification(false);
      setUploadDocs(true);
    }
    if (
      silaOnboardingStatus.state === 'DOCUMENTS_RECEIVED' ||
      (silaOnboardingStatus.state === 'SUCCESS' && silaOnboardingStatus.flow === 'DOC_KYC')
    ) {
      setUploadDocs(false);
      setIdentityVerification(false);
      setDocsReceived(true);
    }
  }, [silaOnboardingStatus]);

  const fetchData = async () => {
    try {
      const st = await getTenantBoardingStatus(cognitoToken, session.userData.id);
      setSilaOnboardingStatus(st);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    try {
      if (uploadDocs) {
        setLoading(true);
        await uploadSilaDoc(cognitoToken, uploadState.fileList, null, silaOnboardingStatus.silaHandle);
        setLoading(false);
        displayAlert(setAlert, 'successful upload', 'success', 4000);

        setTimeout(() => {
          setUploadDocs(false);
          setDocsReceived(true);
        }, 4000);
        return;
      }

      if (docsReceived) {
        setDrawerVisible(false);
        setShowSilaKyc(false);
        return;
      }
      setLoading(true);
      const formData = values;
      const cleanSsn = formData.ssn.replace(/-/g, '').trim();

      console.log(cleanSsn);

      vgsForm.submit(
        '/post',
        {
          data: {
            ssn: cleanSsn,
          },
        },
        async (status, response) => {
          try {
            console.log(response);
            formData.ssn = JSON.parse(response.data).ssn;

            await updateTenantKyc(cognitoToken, +session.userData.id, formData.ssn);
            dispatch(getUserDataV3(cognitoToken));
            await submitAppForOnboarding(cognitoToken, +session.userData.id);
            const st = await getTenantBoardingStatus(cognitoToken, session.userData.id);
            setSilaOnboardingStatus(st);
            displayAlert(setAlert, 'Ssn successfully saved', 'success', 5000);
            setLoading(false);

            setTimeout(() => {
              if (st.state === 'DOCUMENTS_REQUIRED' || st.state === 'REVIEW') {
                setIdentityVerification(false);
                setUploadDocs(true);
              } else if (st.state === 'DOCUMENTS_RECEIVED') {
                setIdentityVerification(false);
                setDocsReceived(true);
              } else {
                setShowSilaKyc(false);
                setShowPayment(true);
              }
            }, 4000);
          } catch (error) {
            setLoading(false);
            displayAlert(setAlert, 'There was an error with your request', 'warning', 5000);
          }
        },
        (errors) => {
          console.log(errors);

          setLoading(false);
          displayAlert(setAlert, 'There was an error with your request', 'warning', 5000);
        }
      );
    } catch (error) {
      setLoading(false);
      displayAlert(setAlert, 'Something went wrong', 'warning', 4000);
    }
  };

  const validateTermsAndConditions = (rule, value, form) => {
    if (!value) {
      return Promise.reject('Please accept the terms and conditions.');
    }
    return Promise.resolve();
  };

  return (
    <Container>
      <Header>
        <ArrowLeftOutlined
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowTest"
          onClick={() => {
            setDrawerVisible(false);
            setShowSilaKyc(false);
          }}
        />
        Identity Verification
      </Header>
      <FormContainer>
        <StyledForm name="silaKyc" form={form} onFinish={onFinish}>
          <ContentContainer className="content-container">
            {identityVerification && <IdentityVerification />}
            {uploadDocs && (
              <KycDocuments
                setAlert={setAlert}
                onboardingStatus={silaOnboardingStatus}
                fetchData={fetchData}
                setUploadState={setUploadState}
                uploadState={uploadState}
                setUploadsFinished={setUploadsFinished}
                uploadsFinished={uploadsFinished}
                showUpload={showUpload}
                setShowUpload={setShowUpload}
              />
            )}
            {docsReceived && <DocsReceived />}
          </ContentContainer>
          <PanelFooter>
            {identityVerification && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <StyledItem>
                  <Form.Item
                    name="termsConditions"
                    valuePropName="checked"
                    rules={[{ validator: validateTermsAndConditions }]}
                    noStyle
                  >
                    <Checkbox />
                  </Form.Item>
                  <StyledText style={{ color: 'black', marginLeft: '5px' }}>I agree to the </StyledText>
                  <StyledText
                    style={{
                      color: '#cb48b7',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      margin: '0px 5px 0px 5px',
                    }}
                    onClick={() => setTermsConditions(true)}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <a href="https://payrent.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                      terms and conditions
                    </a>
                  </StyledText>
                  and{' '}
                  <StyledText
                    style={{
                      color: '#cb48b7',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      margin: '0px 5px 0px 5px',
                    }}
                    onClick={() => setTermsConditions(true)}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <a href="https://payrent.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                      privacy policy
                    </a>
                  </StyledText>
                  <StyledText style={{ color: 'black' }}> of this account</StyledText>
                </StyledItem>
              </div>
            )}

            <AlertFade
              isVisible={!!alert.message}
              message={alert.message}
              // description={alert.description}
              type={alert.type}
              // clickFunction={alert.clickFunction}
              alertPosition="absolute"
              position="absolute"
              minHeight="80"
              bottom={82}
            />

            <SaveItem className="save-form-item">
              <Form.Item
                shouldUpdate={(prevValues, curValues) => prevValues.termsConditions !== curValues.termsConditions}
                noStyle
              >
                {() => {
                  return (
                    <>
                      <Button
                        color="violet"
                        htmlType={identityVerification ? 'SUBMIT' : ''}
                        loading={loading}
                        size="medium"
                        alignment="center"
                        data-testid="buttonTest"
                        bottom={0}
                        disabled={
                          (identityVerification && session.userData.ssnToken) ||
                          (identityVerification && !form.getFieldValue('termsConditions')) ||
                          (uploadDocs && !uploadsFinished)
                        }
                      >
                        {identityVerification && 'SUBMIT'}
                        {uploadDocs && 'FINISH'}
                        {docsReceived && 'CLOSE'}
                      </Button>
                    </>
                  );
                }}
              </Form.Item>
            </SaveItem>
          </PanelFooter>
        </StyledForm>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;

  @media screen and (max-height: 750px) {
    margin-bottom: 0px !important;
  }
`;

const ContentContainer = styled.div`
  padding: 25px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  height: calc(100% - 65px);
`;

const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 10px !important;
  padding: 0 18px;
`;

const StyledText = styled.span`
  font-size: 14px;

  @media screen and (max-width: 445px) {
    font-size: 12px;
  }
  @media screen and (max-width: 390px) {
    font-size: 11px;
  }
`;

export default SilaKyc;
