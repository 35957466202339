import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import FinixPayments from 'screens/Landlord/FinixOnboarding/CreateMerchantWizard';
import { CloseSquareFilled } from '@ant-design/icons';
import SpaceResponsive from 'components/SpaceResponsive';
import WepayPaymentAccountForm from 'screens/Landlord/Onboarding/components/PaymentAccount/WepayPaymentAccountForm';
import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../../components/Panel/PanelHeader/PanelHeader';
import SilaPayments from './SilaPayments/SilaPayments';

const AcceptPayments = (props) => {
  const {
    checkFinixBoardingStatus,
    propertyGroup,
    fetchPropertyGroups,
    setDrawerVisible,
    setShowAcceptPayments,
    setDrawerExpanded,
    checkLandlordStatus,
    setOnboardingState,
    onboardingState,
    stripeTimer,
    fetchPropertyGroupStatus,
  } = props;
  const session = useSelector((store) => store.session);
  const [processor, setProcessor] = useState(null);

  useEffect(() => {
    // console.log('ƒ useEffect on paymentGateway', session.userData.paymentGateway);
    setProcessor(session.userData.paymentGateway);
  }, [session]);

  return (
    <DrawerContainer className="accept-payments-panel">
      <Header>
        <CloseSquareFilled
          style={{ fontSize: '25px', color: '#122C34' }}
          onClick={() => {
            setShowAcceptPayments(false);
            setDrawerVisible(false);
            setDrawerExpanded(false);
          }}
          data-testid="closePropertiesTest"
        />
      </Header>
      <Title>Accept Payments</Title>
      <Subtitle>In just a few simple steps you’ll be collecting rent faster, eaiser and more securely</Subtitle>
      <SpaceResponsive lg={10} md={10} sm={10} />
      {processor === 'Finix' && (
        <FinixPayments
          propertyGroup={propertyGroup}
          fetchPropertyGroups={fetchPropertyGroups}
          setDrawerVisible={setDrawerVisible}
          setDrawerExpanded={setDrawerExpanded}
          setShowAcceptPayments={setShowAcceptPayments}
          checkFinixBoardingStatus={checkFinixBoardingStatus}
        />
      )}
      {/* processors deprecated 1/2024 */}
      {processor === 'Wepay' && (
        <WepayPaymentAccountForm propertyGroup={propertyGroup} checkLandlordStatus={checkLandlordStatus} />
      )}
      {processor === 'SilaStripe' && (
        <SilaPayments
          setOnboardingState={setOnboardingState}
          onboardingState={onboardingState}
          stripeTimer={stripeTimer}
          fetchPropertyGroupStatus={fetchPropertyGroupStatus}
          propertyGroup={propertyGroup}
          setDrawerVisible={setDrawerVisible}
          setShowAcceptPayments={setShowAcceptPayments}
          setDrawerExpanded={setDrawerExpanded}
        />
      )}
    </DrawerContainer>
  );
};

const DrawerContainer = styled.div`
  margin: 0px auto;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  border-radius: 24px;
  .general-settings.ant-row .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-input,
  .ant-select-selection-item,
  input {
    font-size: 16px;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-select-selector {
    height: 32px !important;
    color: #595959;
  }
  .rent-policy-select .ant-select-selector {
    height: 50px !important;
  }

  @media screen and (max-height: 750px) {
    min-height: 96%;
    .ant-form-item.save-form-item {
      margin-bottom: 0px !important;
    }
    .content-container .ant-form-item {
      margin-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    .ant-picker {
      width: 150px;
    }
    .ant-col.ant-col-5.absorbField {
      flex: 0 0 27%;
      max-width: 30%;
    }
  }
`;

export default AcceptPayments;
