import React from 'react';
import styled from 'styled-components';

const PanelFooter = (props) => {
  const { children } = props;
  return <Container>{children}</Container>;
};

const Container = styled.div`
  border-top: 1px solid lightgrey;
  min-height: 120px;
  padding: 5px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
`;

export default PanelFooter;
