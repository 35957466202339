import React from 'react';
import { Descriptions } from 'antd';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import moment from 'moment';
import PaymentMethodIcon from '../../../../../assets/PaymentMethodIcon';

const { Item } = Descriptions;

const container = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { delayChildren: 0.5, staggerChildren: 0.3 } },
};

const child = { hidden: { scale: 0 }, show: { scale: 1 } };

function PaymentThankYou(props) {
  // console.log('[PaymentThankYou.js]', props);
  const { transaction } = props;

  return (
    <motion.div style={{ textAlign: 'center' }} variants={container} initial="hidden" animate="show">
      <PaymentMethodIcon />
      <motion.h1 key="heading" variants={child}>
        Thank You!
      </motion.h1>
      <motion.h2 key="subheading" variants={child}>
        Your payment was successful
      </motion.h2>
      <motion.div key="description" variants={child}>
        <StyledDescriptions title="Transaction Details" column={1}>
          <Item label="Payment Method">{transaction.paymentMethodLabel}</Item>
          <Item label="Transaction ID">{transaction.checkoutId}</Item>
          <Item label="Transaction Time">{moment.unix(transaction.createTime).format('MM-DD-YYYY HH:mm:ss')}</Item>
          <Item label="Transaction Amount">${transaction.amount}</Item>
          <Item label="Payment will appear as">{transaction.softDescriptor}</Item>
          <Item label="Description">{transaction.shortDescription}</Item>
        </StyledDescriptions>
      </motion.div>
    </motion.div>
  );
}

const StyledDescriptions = styled(Descriptions)`
  max-width: 414px;
  margin: 0 auto;
  border: 1px solid #f0f0f0;
  padding: 12px 24px;
  margin-top: 24px;
`;
export default PaymentThankYou;
