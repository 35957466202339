import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';

const BuyUploadPolicy = (props) => {
  // console.log('BuyUploadPolicy props', props);
  const handleUploadClick = () => {
    props.setInsuranceDrawer(true);
    props.setGetUploadVisible(true);
  };

  return (
    <IconButton
      toolTitle="Buy or Upload Policy"
      icon={<UploadOutlined />}
      handleClick={() => handleUploadClick()}
      disabled={props.disabled}
      data-testid="editRenterTest"
    />
  );
};

export default BuyUploadPolicy;
