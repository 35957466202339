import React from 'react';
import { Divider } from 'antd';
import DownloadDocument from './DownloadDocument';
import EsignDocument from './EsignDocument';
import MoreDocumentActions from './MoreDocumentActions';
import CloneDocument from './CloneDocument';

function DocumentActions(props) {
  const { record } = props;
  return (
    <>
      <DownloadDocument {...props} />
      <Divider type="vertical" />
      {record.isTemplate ? <CloneDocument {...props} /> : <EsignDocument {...props} />}
      <Divider type="vertical" />
      <MoreDocumentActions {...props} />
    </>
  );
}

export default DocumentActions;
