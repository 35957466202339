// @flow
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-spring/renderprops';
import { Card } from 'antd';
import styled from 'styled-components';

// Icons
import { MoreOutlined } from '@ant-design/icons';

// Components
import Menu from './components/Menu';

const CardWithMenu = (props) => {
  const { title, id, items, children, handleChangeFilter, type, ...rest } = props;
  const dispatch = useDispatch();
  const activeBoxMenu = useSelector((state) => state.dashboard.activeBoxMenu[id]);

  const toggleMenu = () => {
    dispatch({
      type: 'SET_ACTIVE_BOX_MENU',
      activeBoxMenu: { [id]: !activeBoxMenu },
    });
  };

  const extraAction = () => {
    return (
      <MoreOutlined
        style={{
          fontSize: '25px',
          fontWeight: 600,
          position: 'relative',
          color: '#cb47b8',
          left: '10px',
        }}
        onClick={toggleMenu}
        data-testid="moreMenuTest"
      />
    );
  };

  return (
    <StyledCard
      type={type}
      title={title}
      extra={extraAction()}
      styles={{
        header: {
          fontSize: '20px',
          fontWeight: 600,
          color: '#541388',
          height: type === 'landlord' ? '56px' : 'inherit',
        },
        body: {
          minHeight: '170px',
          paddingTop: type === 'landlord' ? '5px' : 'inherit',
        },
      }}
      {...rest}
    >
      <Transition
        items={activeBoxMenu}
        from={{ transform: 'translate3d(20px, 0px, 0)', opacity: 0 }}
        enter={{ transform: 'translate3d(0, -0px, 0)', opacity: 1 }}
        leave={{ transform: 'translate3d(20px, 0px, 0)', opacity: 0 }}
      >
        {(isVisible) =>
          isVisible &&
          ((transitionProps) => (
            <Menu
              items={items}
              style={{ ...transitionProps, zIndex: 100 }}
              handleChangeFilter={handleChangeFilter}
            />
          ))
        }
      </Transition>

      {children}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  .ant-card-head {
    border-bottom: ${(props) => (props.type === 'landlord' ? '0px' : '1px solid #f0f0f0')};
  }
  .ant-card-body {
    padding: 'inherit';
  }
`;

export default CardWithMenu;
