import styled from 'styled-components';

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 42px;
  text-align: center;
  color: #122c34;
  margin-bottom: 10px !important;
`;

export const Subtitle = styled.div`
  width: 320px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  color: #122c34;
`;
