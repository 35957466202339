import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Icon, { UserOutlined, FileTextOutlined } from '@ant-design/icons';
import { documentsPresignedUrl } from 'services/api/common/create';
import { ReactComponent as UmbrellaIcon } from 'assets/images/umbrella.svg';
import { Tabs, Empty } from 'antd';
import { isEmpty } from 'lodash';
import { displayAlertAction } from 'store/actions/globalActions';
import { getBillingAccount, getInvitedRenters } from 'services/api/landlordApi/read';
import { getUserInsurancePolicy } from 'services/api/common/read';
import TenantTab from './TenantDetails';
import BillingAccountDetails from './BillingAccountDetails';
import LeaseDetails from './LeaseDetails';
import InsuranceDetails from './InsuranceDetails';

const { TabPane } = Tabs;

const ExpandedRow = (props) => {
  const { record, handleUploadFile, handleDownloadFile, handleDeleteFile } = props;
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);
  const [loading, setLoading] = useState(false);
  const [baDetails, setBaDetails] = useState(null);
  const prevILeaseStartDateRef = useRef();
  const prevILeaseEndDateRef = useRef();
  const [uploading, setUploading] = useState(false);
  const [uploadAlert, setUploadAlert] = useState({
    visible: false,
    message: 'Upload Successful',
    type: 'success',
  });
  const [complianceStatus, setComplianceStatus] = useState('');
  const [invitedRenter, setInvitedRenter] = useState([]);
  const [insurancePolicy, setInsurancePolicy] = useState(null);
  const [insuranceFileAvailable, setInsuranceFileAvailable] = useState(false);

  useEffect(() => {
    // console.log(record);
    fetchData();
  }, []);

  useEffect(() => {
    if (record?.details?.billingAccount) {
      if (prevILeaseStartDateRef.current || prevILeaseEndDateRef.current) {
        if (
          record.details.billingAccount.leaseStartDate !== prevILeaseStartDateRef.current ||
          record.details.billingAccount.leaseEndDate !== prevILeaseEndDateRef.current
        ) {
          fetchData();
        }
      }
    }
  }, [record]);

  const displayUploadAlert = (visible, message, type) => {
    setUploadAlert({
      visible,
      message,
      type,
    });
    setTimeout(() => {
      setUploadAlert({
        visible: false,
        message,
        type,
      });
    }, 3500);
  };

  const fetchData = async () => {
    if (record.details.billingAccount) {
      try {
        setLoading(true);
        const result = await getBillingAccount(
          session.cognitoTokens.idToken.jwtToken,
          record.details.billingAccount.id
        );

        prevILeaseStartDateRef.current = record.details.billingAccount.leaseStartDate;
        prevILeaseEndDateRef.current = record.details.billingAccount.leaseEndDate;

        setBaDetails(result);

        if (session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement) {
          if (record.details.settings?.compliance.complianceLevel !== undefined) {
            if (record.details.settings?.compliance.complianceLevel === 0) {
              setComplianceStatus('');
            }
            if (
              record.details.settings?.compliance.complianceLevel === 1 ||
              record.details.settings?.compliance.complianceLevel === 2
            ) {
              if (!isEmpty(record.details.settings.compliance)) {
                if (
                  record.details.settings.compliance.acceptanceDate &&
                  record.details.settings.compliance.payment_methods.isCompliant &&
                  record.details.settings.compliance.scheduled_payments.isCompliant
                ) {
                  setComplianceStatus('Compliant');
                } else {
                  setComplianceStatus('Non-Compliant');
                }
              } else {
                setComplianceStatus('Non-Compliant');
              }
            }
          }
        }
      } catch (error) {
        setLoading(false);
      }
      try {
        const res = await getUserInsurancePolicy(
          session.cognitoTokens.idToken.jwtToken,
          record.details.billingAccount.id
        );
        await handleDownloadInsurancePolicy(record, '');
        // check if file is available

        setInsurancePolicy(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    if (!record.details.billingAccount) {
      try {
        setLoading(true);
        const invitedRes = await getInvitedRenters(10000);
        const invited = invitedRes.items.filter((i) => i.property.id === record.details.id);
        setInvitedRenter(invited);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleDownloadInsurancePolicy = async (r, functionAction) => {
    try {
      const res = await documentsPresignedUrl(
        session.cognitoTokens.idToken.jwtToken,
        r.details.billingAccount.id,
        'download',
        '',
        'insurance'
      );
      if (res.presignedUrl) {
        if (functionAction === 'download') {
          window.open(res.presignedUrl);
        } else {
          setInsuranceFileAvailable(true);
        }
      }
    } catch (error) {
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const customRequest = async (info) => {
    try {
      setUploading(true);
      await handleUploadFile(info, record);
      const res = await getBillingAccount(
        session.cognitoTokens.idToken.jwtToken,
        record.details.billingAccount.id
      );
      setBaDetails(res);
      setUploading(false);
      displayUploadAlert(true, 'Upload Successful', 'success');
    } catch (error) {
      displayUploadAlert(true, error.data?.errors[0]?.detail || error.message, 'warning');
      setUploading(false);
    }
  };

  const deleteFile = async (filename) => {
    try {
      await handleDeleteFile(record, filename);
      const res = await getBillingAccount(
        session.cognitoTokens.idToken.jwtToken,
        record.details.billingAccount.id
      );
      setBaDetails(res);
    } catch (error) {
      displayUploadAlert(true, 'Request failed, please try again.', 'warning');
    }
  };

  const items = [
    {
      label: 'Tenant',
      icon: <UserOutlined />,
      key: '1',
      children: (
        <>
          {invitedRenter.length > 0 && <TenantTab invitedRenter={invitedRenter} loading={loading} />}
          {record?.details?.billingAccount ? (
            <BillingAccountDetails record={record} baDetails={baDetails} complianceStatus={complianceStatus} />
          ) : (
            !invitedRenter.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </>
      ),
    },
    {
      label: 'Lease',
      icon: <FileTextOutlined />,
      key: '2',
      children: (
        <>
          {record?.details?.billingAccount ? (
            <LeaseDetails
              record={record}
              baDetails={baDetails}
              loading={loading}
              uploading={uploading}
              uploadAlert={uploadAlert}
              handleEditRenter={props.handleEditRenter}
              handleEditProperty={props.handleEditProperty}
              handleDownloadFile={handleDownloadFile}
              customRequest={customRequest}
              deleteFile={deleteFile}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </>
      ),
    },
    {
      label: 'Insurance',
      icon: <Icon component={UmbrellaIcon} />,
      key: '3',
      children: (
        <>
          {record?.details?.billingAccount && insurancePolicy && Object.keys(insurancePolicy.policy).length ? (
            <InsuranceDetails
              record={record}
              insurancePolicy={insurancePolicy}
              loading={loading}
              insuranceFileAvailable={insuranceFileAvailable}
              handleDownloadInsurancePolicy={handleDownloadInsurancePolicy}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </>
      ),
    },
  ];

  return <StyledTabs defaultActiveKey="1" animated={{ inkBar: true, tabPane: false }} items={items} />;
};

const StyledTabs = styled(Tabs)`
  .ant-card-body {
    padding: 0px 15px 2px 15px;
  }
  .ant-tabs-tabpane {
    height: 109px;
  }
  .ant-card-meta-title {
    font-size: 14px;
    font-weight: 600;
  }
  .ant-card-meta-description {
    color: black;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0px !important;
  }
  .ant-avatar-square {
    margin-top: 0px !important;
  }
  .ant-btn {
    height: 36px;
    padding-top: 6px !important;
  }
`;

export default ExpandedRow;
