import React, { useEffect, useState } from 'react';
import { displayAlert } from 'resources/helpers';
import { useSelector } from 'react-redux';
import { deletePaymentMethodV3 } from 'services/api/common/delete';

const DeleteMenuItem = (props) => {
  const { pm, setDropdownVisible, getPaymentMethods, setAlert } = props;
  const session = useSelector((state) => state.session);

  const [itemLabel, setItemLabel] = useState({ color: '#000000', text: '', action: false });

  useEffect(() => {
    const label = `${pm.label}${
      !!pm.microDepositsFlow && pm.paymentMethodState === 'SCHEDULED' ? ' - verifying...' : ''
    }`;
    setItemLabel({ ...itemLabel, text: label });
  }, []);

  const labelAction = async () => {
    try {
      if (!itemLabel.action) {
        if (pm.paymentMethodState !== 'SCHEDULED' && !pm.default) {
          setItemLabel({ color: '#CB48B7', text: 'Really delete?', action: true });
          return;
        }
      } else {
        await deletePaymentMethodV3(pm.id, session.cognitoTokens.idToken.jwtToken);
        await getPaymentMethods();
        displayAlert(setAlert, 'Payment method deleted', 'success', 5000);
        setDropdownVisible(false);
      }
    } catch (error) {
      console.log(error);
      displayAlert(setAlert, error.data?.errors[0].detail || 'Your request failed', 'warning', 5000);
    }
  };

  return (
    <div
      onClick={labelAction}
      style={{ color: pm.paymentMethodState !== 'SCHEDULED' && !pm.default ? itemLabel.color : '' }}
    >
      {itemLabel.text}
    </div>
  );
};

export default DeleteMenuItem;
