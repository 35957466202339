import { PlusSquareOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';
import React from 'react';

function AddUnit(props) {
  const {
    setShowUnitPanel,
    setDrawerVisible,
    setDropdownVisible,
    resetDropdown,
    properties,
    parentProperty,
    setSelectedParentProperty,
  } = props;

  const handleAddUnit = () => {
    setShowUnitPanel(true);
    setDrawerVisible(true);
    setDropdownVisible(resetDropdown(properties.items));
    setSelectedParentProperty(parentProperty);
  };

  return <IconButton toolTitle="Add Unit" icon={<PlusSquareOutlined />} handleClick={handleAddUnit} />;
}

export default AddUnit;
