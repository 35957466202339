// @flow

// node_modules
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox, Form, Input, Card, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { omit } from 'lodash';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { updateTenantProfile } from 'services/api/tenantApi';

// Local services
import { changePassword } from 'services/aws/cognito';
import { updateNotificationSettings } from 'store/actions/tenantActions';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

// Local components
import Space from 'components/Space';
import Button from 'components/Button';
import Text from 'components/Text';
import AlertFade from 'components/AlertFade';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const Settings = () => {
  const session = useSelector((store) => store.session);

  const id = useSelector((store) => store.session.userData.id);
  const alert = useSelector((store) => store.global.alert);
  const notificationSettings = useSelector((store) => store.session.userData.notificationSettings);
  const { paymentConfirmations, rentReminders } = notificationSettings;
  const [form] = Form.useForm();
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const [isLoadingPrefs, setIsLoadingPrefs] = useState(false);
  const [isLoadingNotif, setIsLoadingNotif] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getServicePlanAction(cognitoToken));
    dispatch(getUserDataV3(cognitoToken, controller));
    return () => controller.abort();
  }, []);

  const displayAlert = (message, type) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description: '',
        closable: true,
      },
    });

    setTimeout(() => {
      dispatch({
        type: 'UNSET_ALERT',
      });
    }, 5000);
  };

  const updateProfile = async (values) => {
    console.log(values, 'values');
    try {
      setIsLoadingPrefs(true);
      const normalizePhone = values.phone_number ? `${values.phone_number.replace(/\s/g, '')}` : '';
      values.phone_number = normalizePhone;
      const birthdate = values.birthdate ? values.birthdate.format('YYYY-MM-DD') : '';

      values.birthdate = birthdate;

      if (values.password || values.oldPassword) {
        if (!values.oldPassword) {
          displayAlert('Old password is required', 'warning');
          setIsLoadingPrefs(false);
          return;
        }
        if (values.password !== values.confirm) {
          displayAlert('Passwords do not match!', 'warning');
          setIsLoadingPrefs(false);
          return;
        }
        await changePassword(values.oldPassword, values.password);
      }

      //
      await updateTenantProfile(
        session.cognitoTokens.idToken.jwtToken,
        id,
        omit(values, ['oldPassword', 'password', 'confirm'])
      );
      // await updateUserAttributes(omit(values, ['oldPassword', 'password', 'confirm']));

      // dispatch({
      //   type: 'UPDATE_USER_SESSION_DATA',
      //   userData: pickBy(values, identity), // Remove unfilled values
      // });
      dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
    } catch (err) {
      setIsLoadingPrefs(false);
      let title = 'Update user profile error!';
      if (err !== '') title = err;
      if (title === 'Incorrect username or password.') {
        title = 'Incorrect Old Password';
      }
      displayAlert(err?.data?.errors[0].detail || title, 'warning');
      return;
    }
    displayAlert('Account Preferences Updated', 'success');
    setIsLoadingPrefs(false);
  };

  const updateUserPreferences = async (values) => {
    try {
      setIsLoadingNotif(true);
      dispatch(updateNotificationSettings(session.userData.id, values, cognitoToken));
    } catch (err) {
      console.error(err);
      setIsLoadingNotif(false);
      displayAlert('Update user preferences error!', 'warning');
      return;
    }
    displayAlert('Account Preferences Updated', 'success');
    setIsLoadingNotif(false);
  };

  const preferencesValueChanges = (values) => {
    if (session.userData.property.paymentSettings?.complianceLevel) {
      if (session.userData.property.paymentSettings.complianceLevel > 0) {
        form.setFieldsValue({
          notificationsGroup: [...values.notificationsGroup, 'rentReminders'],
        });
      }
    }
  };

  return (
    <>
      <Card
        title="Profile"
        styles={{
          header: {
            fontSize: '14px',
            color: '#541388',
            fontWeight: 600,
          },
        }}
      >
        <AlertFade
          isVisible={alert.isVisible}
          message={alert.message}
          type={alert.type}
          description={alert.description}
          display
          closable={alert.closable}
        />
        <Form
          onFinish={updateProfile}
          initialValues={{
            given_name: session.userData.firstName,
            family_name: session.userData.lastName,
            phone_number: session.userData.phone?.replace('+1', ''),
            email: session.userData.email,
            birthdate: session.userData.dateOfBirth ? dayjs(session.userData.dateOfBirth).utc() : '',
          }}
        >
          <span className="inputTitle">First name</span>
          <Form.Item
            name="given_name"
            whitespace="true"
            rules={[
              {
                type: 'string',
                required: true,
                message: 'Please enter your first name.',
              },
            ]}
          >
            <Input data-testid="firstNameTest" />
          </Form.Item>
          <span className="inputTitle">Last name</span>
          <Form.Item
            name="family_name"
            whitespace="true"
            rules={[
              {
                type: 'string',
                required: true,
                message: 'Please enter your last name.',
              },
            ]}
            data-testid="lastNameItemTest"
          >
            <Input data-testid="lastNameInputTest" />
          </Form.Item>
          <span className="inputTitle">Mobile phone</span>
          <Form.Item
            name="phone_number"
            whitespace="true"
            rules={[
              {
                required: true,
                message: 'Please enter your phone number',
              },
              {
                // pattern: new RegExp('^[0-9]{6,}$'),
                // supports ten characters without + sign
                // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                message: 'Please enter only numbers - min length 10',
              },
              {
                message: 'Please input a valid phone number.',
                max: 12,
              },
            ]}
            data-testid="mobileItemTest"
          >
            {/* <MaskedInput
              className="masked-input"
              placeholder="111 111 1111"
              mask="000 000 0000"
              minLength={6}
              placeholderChar=" "
              data-testid="mobileInputTest"
              prefix="+1"
            /> */}
            <PhoneInput data-testid="mobileInputTest" />
          </Form.Item>
          Birthdate
          <Form.Item
            name="birthdate"
            whitespace="true"
            rules={[
              {
                required: true,
                message: 'Please enter your birthdate',
              },
            ]}
          >
            <DatePicker
              placeholder="Birthdate (mm/dd/yyyy)"
              disabledDate={(current) => current && current > dayjs().subtract(18, 'years')}
              defaultPickerValue={dayjs().subtract(18, 'years')}
              style={{ width: '100%' }}
              format="MM/DD/YYYY"
              autoComplete="none"
            />
          </Form.Item>
          <span className="inputTitle">Email for notifications</span>
          <Form.Item name="email" whitespace="true">
            <Input disabled />
          </Form.Item>
          <span className="inputTitle">Old password</span>
          <Form.Item name="oldPassword" whitespace="true">
            <Input.Password autoComplete="off" />
          </Form.Item>
          <span className="inputTitle">New password</span>
          <Form.Item
            name="password"
            dependencies={['confirm']}
            whitespace="true"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('confirm') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passwords do not match!');
                },
              }),
              {
                pattern: new RegExp('^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@~#$%^&+*!=]).*$'),
                message: 'Min 8 characters, at least 1 capital, 1 lowercase, 1 number and 1 special character',
              },
            ]}
          >
            <Input.Password autoComplete="off" data-testid="inputPasswordTest" />
          </Form.Item>
          <span className="inputTitle">Confirm Password</span>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            // hasFeedback
            whitespace="true"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passwords do not match!');
                },
              }),
              {
                pattern: new RegExp('^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@~#$%^&+*!=]).*$'),
                message: 'Min 8 characters, at least 1 capital, 1 lowercase, 1 number and 1 special character',
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              data-testid="confirmPasswordTest"
            />
          </Form.Item>
          <Button
            htmlType="submit"
            color="violet"
            loading={isLoadingPrefs}
            size="medium"
            alignment="right"
            data-testid="updateButtonTest"
          >
            UPDATE
          </Button>
        </Form>
      </Card>

      <Space vertical={10} />

      <Card
        title="Preferences"
        styles={{
          header: {
            fontSize: '14px',
            color: '#541388',
            fontWeight: 600,
          },
        }}
      >
        <Text strong color="black" size={14}>
          Notifications
        </Text>
        <Space vertical={15} />

        <Form onFinish={updateUserPreferences} onValuesChange={preferencesValueChanges} form={form}>
          <Form.Item
            name="notificationsGroup"
            initialValue={(() => {
              const initialValues = [];
              if (paymentConfirmations) initialValues.push('paymentConfirmations');
              if (rentReminders) initialValues.push('rentReminders');
              return initialValues;
            })()}
          >
            <StyledCheckBoxGroup>
              <StyledCheckBox value="paymentConfirmations" data-testid="paymentConfirmTest">
                Email me payment confirmations
              </StyledCheckBox>
              <StyledCheckBox value="rentReminders" data-testid="rentReminders">
                Send me rent reminders
              </StyledCheckBox>
              <StyledCheckBox value="notifications" disabled>
                Text me notifications
              </StyledCheckBox>
            </StyledCheckBoxGroup>
          </Form.Item>
          <div>
            <Text color="black" size={14}>
              View our{' '}
              <a href="https://payrent.com/terms-conditions" rel="noopener noreferrer" target="_blank">
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a href="https://payrent.com/privacy-policy" rel="noopener noreferrer" target="_blank">
                Privacy Policy
              </a>
            </Text>
            <Button
              htmlType="submit"
              color="violet"
              loading={isLoadingNotif}
              size="medium"
              alignment="right"
              data-testid="updateNotificationTest"
            >
              UPDATE
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

const StyledCheckBoxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCheckBox = styled(Checkbox)`
  margin-left: 0 !important;
  margin-bottom: 20px;
`;

export default Settings;
