import React, { useEffect, useState } from 'react';
import { Col, Space as AntSpace, Button } from 'antd';
import { StyledCard, StyledRow } from './WepayOffboarding.styles';
import MigrationSidebar from './components/MigrationSidebar';
import SubscriptionSwitcher from './components/SubscriptionSwitcher';
import Drawer from 'components/Drawer/Drawer';
import MigrationOnboarding from './components/MigrationOnboarding';
import { useDispatch, useSelector } from 'react-redux';
import BillingDetailsPanel from 'screens/Landlord/components/BillingDetailsPanel/BillingDetailsPanel';
import Text from 'components/Text/Text';
import { upgradeDowngradeServicePlan } from 'services/api/landlordApi/create';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

const MigrationStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [visiblePanel, setVisiblePanel] = useState('');
  const [isResetting, setIsResetting] = useState(false);
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const paymentGateway = session.userData.paymentGateway;
  const [alert, setAlert] = useState({
    message: '',
    type: 'info',
  });

  const commonProps = {
    currentStep,
    setCurrentStep,
    setAlert,
    setDrawerVisible,
    setVisiblePanel,
  };

  const handleUndo = async (id) => {
    setIsResetting(true);
    try {
      console.log('[MigrationStepper.js] ƒ handleReset Wepay');
      const token = session.cognitoTokens.idToken.jwtToken;
      await upgradeDowngradeServicePlan(
        token,
        {
          processorId: session.userData.servicePlan.processor.id,
          servicePlanId: id,
        },
        +session.userData.id
      ).then(() => {
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
        setCurrentStep(0);
      });
    } catch (err) {
      console.error(err);
    }

    setIsResetting(false);
  };

  function resetPanel() {
    setVisiblePanel('');
    setDrawerVisible(false);
  }

  const stepTitle = {
    0: { title: 'Update Your Subscription', description: 'Select similar subscription or choose a new one' },
    1: {
      title: 'Complete Payment Account Setup',
      description: 'Create a new payment account for each property group',
    },
  };

  useEffect(() => {
    if (paymentGateway === 'Finix') {
      setCurrentStep(1);
    }
  }, [paymentGateway]);

  return (
    <>
      <Drawer
        open={drawerVisible}
        alertMessage={alert.message}
        alertType={alert.type}
        onClose={() => setDrawerVisible(false)}
      >
        {visiblePanel === 'billing' && (
          <BillingDetailsPanel
            resetPanel={resetPanel}
            displayAlert={(message, type) => setAlert({ message, type })}
          />
        )}
      </Drawer>
      <StyledCard data-testid="migrationCardTest">
        <StyledRow gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <MigrationSidebar
              alert={alert}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              stepTitle={stepTitle}
              data-testid="migrationSidebarTest"
            />
          </Col>
          <Col xs={24} sm={16}>
            {currentStep === 0 && <SubscriptionSwitcher {...commonProps} />}
            {currentStep === 1 && <MigrationOnboarding {...commonProps} />}
            {process.env.NODE_ENV === 'development' && (
              <AntSpace>
                <Text>Reset service plan (development use only):</Text>
                <Button loading={isResetting} type="link" size="small" onClick={() => handleUndo(8)}>
                  PAYG
                </Button>
                <Button loading={isResetting} type="link" size="small" onClick={() => handleUndo(2)}>
                  DIY
                </Button>
                <Button loading={isResetting} type="link" size="small" onClick={() => handleUndo(3)}>
                  GLAP
                </Button>
              </AntSpace>
            )}
          </Col>
        </StyledRow>
      </StyledCard>
    </>
  );
};
export default MigrationStepper;
