import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { Dropdown, Space, Spin } from 'antd';
import { DownOutlined, MoreOutlined } from '@ant-design/icons';
import { displayAlertAction } from 'store/actions/globalActions';
import { deleteProperty } from 'services/api/landlordApi/delete';

import styledTheme from 'styles/styledComponents/variables';
import { getPropertiesListAction } from 'store/actions/landlordActions';
import ActionMenuButton from 'components/uielements/ActionMenuButton/ActionMenuButton';
import { removeRenterV3 } from 'services/api/landlordApi/update';

const UnitMenu = (props) => {
  const {
    setUnitDropdownVisible,
    unitDropdownVisible,
    resetDropdown,
    i,
    unit,
    handleExpand,
    setShowInvitationPanel,
    setDrawerVisible,
    setInviteProperty,
    setSelectedParentProperty,
    parentProperties,
    setEditProperty,
    setShowUnitPanel,
    setCloneProperty,
    fetchProperties,
    screenWidth,
    page,
    propertyGroupId,
  } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);

  const history = useHistory();
  const [currentParentProperty, setCurrentParentProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteText, setDeleteText] = useState({
    text: 'Delete Unit',
    color: 'inherit',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: false,
  });
  const [removeRenterText, setRemoveRenterText] = useState({
    text: 'Remove Renter',
    color: 'inherit',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: false,
  });
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  useEffect(() => {
    const p = parentProperties.filter((p) => +p.id === +unit.parentPropertyId);

    setCurrentParentProperty(p[0]);
  }, [props]);

  const handleDeleteProperty = async (id) => {
    try {
      if (Object.keys(unit.billingAccount).length) {
        dispatch(
          displayAlertAction('', 'warning', 'Cannot delete property with renters attached to it.', true, 5000)
        );
        setUnitDropdownVisible(
          resetDropdown({
            ...unitDropdownVisible,
            [unit.parentPropertyId]: { ...unitDropdownVisible[unit.parentPropertyId], [i]: false },
          })
        );
        return;
      }
      if (deleteText.text === 'Delete Unit') {
        return setDeleteText({
          text: 'Really Delete?',
          color: '#CB48B7',
          style: { color: '#CB47B8', fontWeight: 500 },
          action: true,
        });
      }
      setLoading(true);
      await deleteProperty(id);
      await fetchProperties(propertyGroupId, page, false);
      await handleExpand(true, { id: unit.parentPropertyId });
      dispatch(getPropertiesListAction(cognitoToken, 1, 10000, '', ''));
      displayAlertAction('', 'success', 'Unit successfully deleted.', true, 5000);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const handleViewTransactionHistory = () => {
    const id = unit.id;
    history.push(`/landlord/transactions?fields=${id}`);
  };

  const handleRemoveRenter = async (account) => {
    console.log('ƒ handleRemoveRenter', account, unit, parentProperties);
    try {
      if (!removeRenterText.action) {
        return setRemoveRenterText({
          text: 'Really Remove Renter?',
          color: '#CB48B7',
          style: { color: '#CB47B8', fontWeight: 500 },
          action: true,
        });
      }
      setLoading(true);
      setUnitDropdownVisible(
        resetDropdown({
          ...unitDropdownVisible,
          [unit.parentPropertyId]: { ...unitDropdownVisible[unit.parentPropertyId], [i]: false },
        })
      );
      await removeRenterV3(
        unit.propertyId,
        account.user?.userId,
        unit.parentPropertyId,
        propertyGroupId,
        cognitoToken
      );
      await fetchProperties(propertyGroupId, page, false);
      await handleExpand(true, { id: unit.parentPropertyId });
      dispatch(getPropertiesListAction(cognitoToken, 1, 10000, '', ''));
      setLoading(false);
      setRemoveRenterText({
        text: 'Remove Renter',
        color: 'inherit',
        style: { color: 'inherit', fontWeight: 'inherit' },
        action: false,
      });
      dispatch(displayAlertAction('', 'success', 'Renter successfully removed.', true, 5000));
    } catch (error) {
      setLoading(false);
      setUnitDropdownVisible(
        resetDropdown({
          ...unitDropdownVisible,
          [unit.parentPropertyId]: { ...unitDropdownVisible[unit.parentPropertyId], [i]: false },
        })
      );
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const handleButtons = (type) => {
    switch (type) {
      case 'clone':
        setCloneProperty(unit);
        setShowUnitPanel(true);
        break;
      case 'edit':
        setEditProperty(unit);
        setShowUnitPanel(true);
        break;
      case 'invite':
        setInviteProperty(unit);
        setShowInvitationPanel(true);
        break;
      default:
        break;
    }

    setDrawerVisible(true);
    setSelectedParentProperty(currentParentProperty);
    setUnitDropdownVisible(
      resetDropdown({
        ...unitDropdownVisible,
        [unit.parentPropertyId]: { ...unitDropdownVisible[unit.parentPropertyId], [i]: false },
      })
    );
  };

  const items = [
    {
      label: 'Invite Renter',
      onClick: () => handleButtons('invite'),
      'data-testid': 'inviteTest',
      disabled: Object.keys(unit.billingAccount).length || unit.billingAccount.state === 'PENDING',
      key: '1',
      style: {
        display:
          !Object.keys(unit.billingAccount).length || unit.billingAccount.state === 'PENDING' ? 'flex' : 'none',
      },
    },
    {
      label: <Spin spinning={loading}>{removeRenterText.text}</Spin>,
      onClick: () => handleRemoveRenter(unit.billingAccount),
      'data-testid': 'removeTest',
      key: '2',
      style: {
        ...removeRenterText.style,
        display:
          Object.keys(unit.billingAccount).length && unit.billingAccount.state === 'ACTIVE' ? 'flex' : 'none',
      },
    },
    {
      label: 'Edit Unit',
      onClick: () => handleButtons('edit'),
      'data-testid': 'editTest',
      key: '3',
    },
    {
      label: 'Clone Unit',
      onClick: () => handleButtons('clone'),
      'data-testid': 'cloneTest',
      key: '4',
    },
    {
      label: <Spin spinning={loading}>{deleteText.text}</Spin>,
      onClick: () => handleDeleteProperty(unit.id),
      style: deleteText.style,
      'data-testid': 'deleteTest',
      key: '5',
    },
    {
      label: 'View Transaction History',
      onClick: handleViewTransactionHistory,
      'data-testid': 'historyTest',
      key: '6',
    },
  ];

  return (
    <Space size="middle" style={{ textAlign: 'left' }}>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        placement="bottomRight"
        open={unitDropdownVisible[unit.parentPropertyId] ? unitDropdownVisible[unit.parentPropertyId][i] : false}
        onOpenChange={(visible, { source }) => {
          if (visible) {
            const recordState = {
              [i]: true,
            };
            setUnitDropdownVisible({
              ...unitDropdownVisible,
              [unit.parentPropertyId]: { ...recordState },
            });
          }
          if (!visible && source === 'trigger') {
            const recordState = {
              [i]: false,
            };
            setUnitDropdownVisible({
              ...unitDropdownVisible,
              [unit.parentPropertyId]: { ...recordState },
            });
            setDeleteText({
              text: 'Delete Property',
              color: 'inherit',
              style: { color: 'inherit', fontWeight: 'inherit' },
              action: false,
            });

            setRemoveRenterText({
              text: 'Remove Renter',
              color: 'inherit',
              style: { color: 'inherit', fontWeight: 'inherit' },
              action: false,
            });
          }
        }}
      >
        {screenWidth < 550 ? (
          <MoreOutlined style={{ color: styledTheme.colors.violet, marginLeft: '8px', fontSize: '20px' }} />
        ) : (
          <ActionMenuButton>
            Unit Menu
            <DownOutlined style={{ color: styledTheme.colors.violet, marginLeft: '8px' }} />
          </ActionMenuButton>
        )}
      </Dropdown>
    </Space>
  );
};

export default UnitMenu;
