import React from 'react';
import styled from 'styled-components';

import { CheckOutlined } from '@ant-design/icons';

import Text from 'components/Text';
import Space from 'components/Space';

const DoItYourSelf = () => {
  return (
    <>
      <Text size={24} strong color="black" centered>
        Do-It-Yourself
      </Text>
      <Space vertical={20} />
      <div style={{ paddingRight: '50px', paddingLeft: '50px', lineHeight: '35px' }}>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          Flat $20 per month for up to 25 units
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          $2 bank transfers (60% savings)
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          Automated late fee assessment
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          Pass-through transaction fees to renters
        </Text>
        <Text size={16} color="grey3" strong style={{ display: 'flex' }}>
          <StyledCheck />
          Rent Collection Enforcement
        </Text>
      </div>
    </>
  );
};

const StyledCheck = styled(CheckOutlined)`
  color: #541388;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export default DoItYourSelf;
