import React from 'react';
import { Card, Typography, Space } from 'antd';
import styled from 'styled-components';
import { useTrail, animated } from 'react-spring';

import moment from 'moment';

import NewsTags from './components/NewsTags';

const { Text } = Typography;

const StyledCard = styled(Card)`
  margin: 10px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const StyledArticle = styled('div')`
  font-size: 14px;
  img {
    height: auto;
    max-width: 80%;
    display: block;
    margin: 18px auto;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* Gray / gray-9 */
    color: #262626;
  }
`;

const StyledDateline = styled(Text)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

const StyledTitle = styled(Text)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #262626;
`;

const NewsFeed = (props) => {
  const { posts, isOpen } = props;
  const items = posts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const trail = useTrail(items.length, {
    config: { mass: 4, tension: 2000, friction: 300 },
    delay: 300,
    opacity: isOpen ? 1 : 0,
    x: isOpen ? 0 : 20,
    from: { opacity: 0, x: 180, height: 0 },
  });

  return (
    <>
      {trail.map(({ x, height, ...rest }, index) => (
        <animated.div style={{ ...rest, transform: x.interpolate((y) => `translate3d(0,${y}px,0)`) }} key={index}>
          <StyledCard
            style={{}}
            title={
              <Space direction="vertical" size={12}>
                <Space>
                  <NewsTags tag={items[index].tags[0]} />
                  <StyledDateline>{moment(items[index].created_at).format('MMM DD, YYYY')}</StyledDateline>
                </Space>
                <StyledTitle>{items[index].title}</StyledTitle>
              </Space>
            }
          >
            <StyledArticle
              dangerouslySetInnerHTML={{
                __html: items[index].description,
              }}
            />
          </StyledCard>
        </animated.div>
      ))}
    </>
  );
};
export default NewsFeed;
