import axios from 'axios';
import Jsona from 'jsona';
import store from '../../../store';
import { refreshToken } from '../../aws/cognito';

const dataFormatter = new Jsona();

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const updateTourStatus = async (cognitoToken, id, values) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    const data = {
      data: {
        type: 'users',
        id,
        attributes: {
          ...values,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}/tour-status`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateCognitoPhoneNumber = async (phone, email) => {
  try {
    const data = {
      data: {
        attributes: {
          phone,
          email,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/users/unconfirmed`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};
