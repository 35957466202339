import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Spin } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getBillingDetails } from 'services/api/landlordApi/read';
import BillingPanelForm from './components/BillingPanelForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PlansBillingDetailsPanel = (props) => {
  const { resetPanel, displayAlert } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  // const [plansBilling, setPlansBilling] = useState(null);
  // const [fieldsObject, setFieldsObject] = useState(null);

  const [options, setOptions] = useState({
    clientSecret: null,
    appearance: {
      theme: 'none',
      rules: {
        '.Input::placeholder': {
          color: '#595959',
        },
        '.Input': {
          border: '1px solid #D9D9D9',
          color: '#595959',
          fontFamily: `'Montserrat', 'Poppins', sans-serif`,
          fontSize: '14px',
          padding: '8px',
        },
        '.Input:focus-visible': {
          outline: '0',
        },
        '.Input:focus': {
          borderColor: '#FFCC00',
          borderRightWidth: '1px !important',
        },
        '.Tab:hover': {
          color: '#595959',
        },
        '.Tab--selected': {
          borderColor: '#D9D9D9',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px yellow',
        },
        '.Input--invalid': {
          boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 1px var(--colorDanger)',
          marginBottom: '7px',
        },
      },
    },
  });

  useEffect(() => {
    fetchAndSetData();
  }, []);

  const fetchAndSetData = async () => {
    try {
      const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);
      setOptions({ ...options, clientSecret: plansBillingRes.clientSecret });
      // setPlansBilling(plansBillingRes);
      // const fieldsData = {
      //   firstName: plansBillingRes.billingDetails.firstName,
      //   lastName: plansBillingRes.billingDetails.lastName,
      //   billingEmailAddress: plansBillingRes.billingDetails.email,
      //   bussinessName: plansBillingRes.billingDetails.businessName || '',
      //   billingStreetAddress: plansBillingRes.billingDetails?.streetAddress || '',
      //   billingZipCode: plansBillingRes.billingDetails?.zipCode || '',
      //   billingCity: plansBillingRes.billingDetails?.city || '',
      //   billingState: plansBillingRes.billingDetails?.state || '',
      // };
      // setFieldsObject(fieldsData);
      // form.setFieldsValue(fieldsObject);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DrawerContainer>
        <Spin
          spinning={!options.clientSecret}
          style={{ alignSelf: 'center', justifySelf: 'center', width: '100%' }}
        />
        {!!options.clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <BillingPanelForm resetPanel={resetPanel} displayAlert={displayAlert} />
          </Elements>
        )}
      </DrawerContainer>
    </>
  );
};

const DrawerContainer = styled.div`
  margin: 0px auto;
  max-width: 100%;
  height: 100%;
  display: flex;
  border-radius: 24px;
  .ant-input,
  .ant-select-selection-item,
  input {
    font-size: 16px;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-select-selector {
    height: 31px !important;
    color: #595959;
  }

  @media screen and (max-width: 480px) {
    max-width: 330px;
    .ant-picker {
      width: 150px;
    }
  }
`;

export default PlansBillingDetailsPanel;
