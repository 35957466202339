import { CrownFilled, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { theme, Button as AntButton } from 'antd';
import Button from 'components/Button/Button';
import CardWithTitle from 'components/CardWithTitle/CardWithTitle';
import Table from 'components/Table/Table';
import React, { useCallback, useEffect, useState } from 'react';
import DocumentActions from './components/DocumentActions';
import DynamicTags from 'components/DynamicTags/DynamicTags';
import { motion } from 'framer-motion';
import { formVariants } from 'animations/variants';
import Result from 'components/uielements/Result/Result';
import { useSelector } from 'react-redux';
import Space from 'components/Space/Space';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { getDocuments } from 'services/api/common/read';
import { createOrUpdateDocumentReference } from 'services/api/landlordApi/create';
import styledTheme from 'styles/styledComponents/variables';
import AutoComplete from 'components/AutoComplete/AutoComplete';
import { StyledPageHeader } from './Documents.styles';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import DocumentDescription from './components/DocumentDescription';
import DocumentDrawer from './components/DocumentDrawer';
import useWindowSize from 'hooks/useWindowSize';
import Text from 'components/Text/Text';
import { StyledVideoWrapper } from '../WepayOffboarding/WepayOffboarding.styles';

dayjs.extend(relativeTime);

function Documents() {
  const { token } = theme.useToken();
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken.jwtToken;
  const featureDisabled = !session.userData?.servicePlan?.metaFeatures.dOCUMENTStorage;
  const [tagOptions, setTagOptions] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({ columnKey: 'label', order: 'ascend' });
  const [submitting, setSubmitting] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { width: screenWidth } = useWindowSize();
  const [editDocument, setEditDocument] = useState(null);
  const [showPanel, setShowPanel] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filterValues, setFilterValues] = useState({ tags: [], label: [], action: [] });
  const [searchOptions, setSearchOptions] = useState([]);
  // react-query
  const qc = useQueryClient();
  const {
    data: docs,
    isFetched,
    fetchStatus,
    isLoading,
  } = useQuery({
    queryKey: ['documents'],
    queryFn: async () => await getDocuments(cognitoToken, 1, 10000),
    select: (data) => data.items.map((d) => ({ ...d, key: d.id })),
  });
  // console.log('useQuery', fetchStatus);

  const mutation = useMutation({
    mutationFn: (newDoc) => createOrUpdateDocumentReference(cognitoToken, newDoc),
    onSuccess: () => qc.invalidateQueries(['documents'], { refetchActive: true }),
  });

  function mergeTags(doc) {
    return doc.tags.system ? [...doc.tags.user, ...doc.tags.system] : doc.tags.user;
  }

  const setTagFilter = useCallback(() => {
    // console.log('ƒ setTagFilter');
    if (!docs || !docs.length) return setTagOptions([]);

    const tagList = docs
      .map((item) => mergeTags(item))
      .flat()
      .filter((item, index, self) => self.indexOf(item) === index)
      .sort((a, b) => a.localeCompare(b));

    setTagOptions(() => tagList.map((item) => ({ text: item, value: item })));
  }, [docs]);

  const handleChangeTable = (pagination, filters, sorter) => {
    // console.log('ƒ handleChangeTable', pagination, filters, sorter);
    setSortedInfo(sorter);
    setFilterValues((prev) => ({ ...prev, tags: filters.tags }));
  };

  const handleSearch = (value) => {
    // console.log('ƒ handleSearch', value);
    if (!value) {
      setFilterValues((prev) => ({ ...prev, label: [] })); // clear filter
      setSearchValue('');
      return setSearchOptions(() => docs?.map((item) => ({ value: item.label })) || []);
    }
    setFilterValues((prev) => ({ ...prev, label: [value] }));
    setSearchValue(value);
    setSearchOptions(() => [
      ...(docs
        ?.map((doc) => ({ value: doc.label }))
        .filter((d) => d.value.toLowerCase().includes(value.toLowerCase())) || []),
    ]);
  };

  const handleSearchSelect = (value) => setFilterValues((prev) => ({ ...prev, label: [value] }));

  const updateDocumentTags = useCallback(
    debounce((tags, item) => {
      // console.log('ƒ updateDocumentTags start', tags, item);

      const mergedTags = { ...item.tags, user: tags };

      const newDoc = {
        id: item.id,
        landlordId: item.landlordId,
        label: item.label,
        fileName: item.fileName,
        mimeType: item.mimeType,
        isPublic: item.isPublic,
        isTemplate: item.isTemplate,
        tags: mergedTags,
        associations: item.associations,
        events: item.events,
      };

      mutation.mutateAsync(newDoc);
    }, 900),
    []
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'label',
      key: 'label',

      render: (text, record) => <DocumentDescription record={record} />,
      sorter: (a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      },
      sortOrder: sortedInfo.columnKey === 'label' ? sortedInfo.order : null,
      filteredValue: filterValues.label || null,
      onFilter: (value, record) => record.label.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      filters: tagOptions,
      responsive: ['md'],
      width: 320,
      render: (tags, record) =>
        tags ? <DynamicTags updateDocumentTags={updateDocumentTags} maxCount={4} item={record} /> : null,
      onFilter: (value, record) => mergeTags(record).includes(value),
      filteredValue: filterValues.tags || null,
      filterSearch: true,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 180,
      filteredValue: null,
      render: (text, record) => (
        <DocumentActions
          record={record}
          loading={submitting}
          setDrawerVisible={setDrawerVisible}
          setShowPanel={setShowPanel}
          setEditDocument={setEditDocument}
        />
      ),
    },
  ];

  useEffect(() => {
    // console.log('ƒ useEffect on docs:', isFetched, fetchStatus, docs);
    let mounted = true;
    if (isFetched && fetchStatus === 'idle' && mounted) {
      setTagFilter();
      setSearchOptions(
        () => docs?.map((item) => ({ value: item.label })).sort((a, b) => a.value.localeCompare(b.value)) || []
      );
    }
    return () => (mounted = false);
  }, [isFetched, fetchStatus, setTagFilter, docs]);

  return (
    <>
      <Space vertical={25} />
      <DocumentDrawer
        showPanel={showPanel}
        tagOptions={tagOptions}
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        setEditDocument={setEditDocument}
        setSubmitting={setSubmitting}
        submitting={submitting}
        handleMutation={mutation}
        editDocument={editDocument}
      />
      <motion.div variants={formVariants} initial="hide" animate="show" className="content-container">
        <StyledPageHeader className="page-header" justify="flex-end">
          <AutoComplete
            allowClear
            value={searchValue}
            onSelect={handleSearchSelect}
            onChange={handleSearch}
            options={searchOptions}
            placeholder={
              <>
                <SearchOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                <span>Search Documents</span>
              </>
            }
            notFoundContent="Not found"
          />
        </StyledPageHeader>
        <CardWithTitle
          data-testid="documentsTest"
          title={
            <Text strong color="violet2" size={18}>
              Documents
            </Text>
          }
          styles={{
            body: { padding: '0' },
          }}
          extra={[
            <Button
              key="0"
              type="primary"
              height={screenWidth > 560 ? 36 : 27}
              fontSize={screenWidth > 560 ? 16 : 12}
              padding1={1}
              padding2={15}
              bottom={0}
              disabled={featureDisabled}
              icon={
                <PlusSquareOutlined
                  style={{ fontSize: screenWidth > 560 ? '14px' : '13px', color: styledTheme.colors.white }}
                />
              }
              onClick={() => {
                setShowPanel('document');
                setDrawerVisible(true);
              }}
              data-testid="addDocumentButtonTest"
            >
              ADD A DOCUMENT
            </Button>,
          ]}
        >
          {featureDisabled ? (
            <Result
              status="info"
              icon={<CrownFilled style={{ color: token.colorDark }} />}
              title="This is a Premium Feature"
              subTitle="Upgrade to get unlimited access to document management and e-signatures."
              extra={[
                <div className="result-extra-wrapper" style={{ maxWidth: '576px', margin: '24px auto' }}>
                  <StyledVideoWrapper key="video">
                    <iframe
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        border: 0,
                      }}
                      src="https://www.youtube.com/embed/GDcDuzhiQyA?si=9fIqoC04LkUnOqrk&rel=0&list=PLe3Odl1JD1ZcvB47HxDj_BlD-F-MUi-lP"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </StyledVideoWrapper>
                  <div key="upgrade" style={{ margin: '12px', textAlign: 'center' }}>
                    <Link to="/landlord/service-plans">
                      <Button color="yellow">UPGRADE</Button>
                    </Link>
                  </div>
                </div>,
              ]}
            />
          ) : (
            <Table
              data-testid="documentsTableTest"
              columns={columns}
              dataSource={docs}
              onChange={handleChangeTable}
              loading={isLoading}
            />
          )}
        </CardWithTitle>
      </motion.div>
    </>
  );
}

export default Documents;
