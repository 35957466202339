import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Card as AntCard } from 'antd';

// Components
import Text from 'components/Text';
import FinancialGoalsTitle from './FinancialGoalsTitle';

const FinancialGoals = (props) => {
  const { setDrawerVisible, setShowRentCredProfile } = props;
  const session = useSelector((store) => store.session);
  const {
    getOutOfDebt,
    buyAHome,
    planForRetirement,
    haveAnEmergencyFund,
    saveForCollege,
    investInMyself,
    generatePassiveIncome,
    getABetterPayingJob,
  } = session.userData.tenantMeta.financialGoals;

  return (
    <StyledCard
      title={
        <FinancialGoalsTitle setDrawerVisible={setDrawerVisible} setShowRentCredProfile={setShowRentCredProfile} />
      }
    >
      <ul>
        {getOutOfDebt && (
          <li key="1">
            <Text color="black" size={14}>
              Get out of debt
            </Text>
          </li>
        )}
        {buyAHome && (
          <li key="2">
            <Text color="black" size={14}>
              Buy a home
            </Text>
          </li>
        )}
        {planForRetirement && (
          <li key="3">
            <Text color="black" size={14}>
              Plan for retirement
            </Text>
          </li>
        )}
        {haveAnEmergencyFund && (
          <li key="4">
            <Text color="black" size={14}>
              Have an emergency fund
            </Text>
          </li>
        )}
        {saveForCollege && (
          <li key="5">
            <Text color="black" size={14}>
              Save for college
            </Text>
          </li>
        )}
        {investInMyself && (
          <li key="6">
            <Text color="black" size={14}>
              Invest in myself{' '}
            </Text>
          </li>
        )}
        {generatePassiveIncome && (
          <li key="7">
            <Text color="black" size={14}>
              Generate passive income
            </Text>
          </li>
        )}
        {getABetterPayingJob && (
          <li key="7">
            <Text color="black" size={14}>
              Get a better paying job
            </Text>
          </li>
        )}
      </ul>
    </StyledCard>
  );
};

const StyledCard = styled(AntCard)`
  .ant-card-body {
    padding: 24px 10px;
  }
`;

export default FinancialGoals;
