import React from 'react';
import styled from 'styled-components';
import { Empty } from 'antd';
import PropTypes from 'prop-types';

export default function EmptyLarge({ description, image }) {
  return (
    <StyledEmptyWrapper>
      <StyledEmpty description={description} image={image} />
    </StyledEmptyWrapper>
  );
}

const StyledEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 320px;
  max-width: 480px;
  margin: 0 auto;
`;

const StyledEmpty = styled(Empty)`
  .ant-empty-image {
    height: 220px;
  }
`;

StyledEmpty.defaultProps = {
  description: 'Nothing to see here',
};
StyledEmpty.propTypes = {
  descrtiption: PropTypes.string,
  image: PropTypes.node,
};
