// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import * as serviceWorker from './serviceWorker';
import 'styles/index.css';
import TagManager from 'react-gtm-module';
import { IntercomProvider } from 'react-use-intercom';
import { theme } from 'styles/theme';
import browsee from '@browsee/web-sdk';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Spin, ConfigProvider, App } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// Redux
import store from './store';

// import Tap from '@tapfiliate/tapfiliate-js';

// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// Tap.init(process.env.REACT_APP_TAPFILIATE, { integration: 'stripe' });
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      refetchInterval: false,
      refetchIntervalInBackground: true,
    },
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-WSXD5LR',
  preview: process.env.REACT_APP_GTM_ENV,
  auth: process.env.REACT_APP_GTM_AUTH,
};
TagManager.initialize(tagManagerArgs);

const root = document.getElementById('root');

if (process.env.REACT_APP_ENV === 'production') {
  console.log('Initializing browsee...');
  browsee.init({ apiKey: process.env.REACT_APP_BROWSEE });
  // coupon code SWITCH10
  // LogRocket.init('udz4mp/payrent-customer-app');
}

if (window.WePay) window.WePay.set_endpoint(process.env.REACT_APP_WEPAY_MODE);

const INTERCOM_APP_ID = process.env.REACT_APP_ENV === 'production' ? 'hlmx9md6' : 'fzefpwfs';

if (root !== null) {
  ReactDOM.render(
    <ConfigProvider theme={theme}>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <App>
          <QueryClientProvider client={queryClient}>
            <CacheBuster
              currentVersion={version}
              isEnabled
              isVerboseMode={false} //If true, the library writes verbose logs to console.
              loadingComponent={<Spin style={{ display: 'block', position: 'fixed', top: '50%', right: '50%' }} />} //If not pass, nothing appears at the time of new version check.
            >
              <Provider store={store().store}>
                <PersistGate loading={null} persistor={store().persistor}>
                  <BrowserRouter>
                    <Router />
                  </BrowserRouter>
                </PersistGate>
              </Provider>
            </CacheBuster>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </App>
      </IntercomProvider>
    </ConfigProvider>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

serviceWorker.unregister();
