export const getDashboardSteps = (
  absorbFees,
  absorbFeesPercent,
  achFee,
  ccFee,
  creditCardTrxFee,
  isDesktop,
  hideBack,
  showSkip
) => {
  const dashboardSteps = [
    {
      title: 'Account Balance',
      target: '.balance', //Account Balance Card,
      content: 'This is your current account balance not including upcoming charges or credits.',
      disableBeacon: true,
      disableOverlay: true,
    },
    {
      title: 'Upcoming Charges',
      target: '.upcomingCharges', // upcoming charges and credits card
      disableBeacon: true,
      disableOverlay: true,
      content: 'This section shows you any upcoming charges or credits to your account',
    },
    {
      title: 'RentCred',
      target: '.rent-cred-status', // upcoming charges and credits card
      disableBeacon: true,
      disableOverlay: true,
      hideBackButton: hideBack,
      showSkipButton: showSkip,
      content:
        'RentCred™ is an incentive program that rewards you for consistently paying rent on-time through PayRent.com. As You build a payment history you unlock perks like emergency cash and credit boost.',
    },
    {
      title: 'Recent Transactions',
      target: '.recentTransactions', // recent transactions card
      disableBeacon: true,
      disableOverlay: true,
      content:
        'This section shows your most recent transactions. A full transaction history is available on the transactions page.',
    },
    {
      title: 'Add a Payment Method',
      target: '.paymentMethod', //Add a default payment method link
      disableBeacon: true,
      disableOverlay: true,
      content: `Click here to add your payment method. ${
        absorbFees && absorbFeesPercent === '100'
          ? 'Your landlord is absorbing the fees for bank transfers'
          : `Bank transfer fees are ${achFee.toFixed(2)}%`
      } and credit/debit cards are ${ccFee.toFixed(2)}% + $${creditCardTrxFee}.`,
    },
    {
      title: 'Make a Payment',
      target: '.payButton', // PAY button
      content: 'To make a payment, you will click here after adding a verified payment method',
      disableBeacon: true,
      disableOverlay: true,
    },
    {
      title: 'Navigation Menu',
      target: isDesktop ? '.ant-menu' : '.anticon-menu', // hamburger menu (mobile) or left sidbar (desktop)
      content: 'View transactions and manage your wallet using the main menu.',
      disableBeacon: true,
      disableOverlay: true,
    },
    // {
    //   title: 'Schedule a Payment',
    //   target: '', // wallet screen > scheduled payments card
    //   content: 'Now that you have a payment method setup, you can schedule a one-time or recurring payment.',
    // },
    {
      title: 'Finished!',
      target: 'body', // home screen top
      content: "You're all set! Happy renting!",
      disableBeacon: true,
      disableOverlay: true,
    },
  ];

  return dashboardSteps;
};

export const makePaymentSteps = [
  {
    title: 'Make a Payment',
    target: '.makePaymentStep', //Make a payment div
    content: 'Pay the full balance due or uncheck the box to enter another amount',
    disableBeacon: true,
    disableOverlay: true,
  },
  {
    title: 'Total Payment Amount',
    target: '.totalPaymentStep', //Total Payment Aount box
    content: 'This shows your the total amount billed to your payment method including any fees.',
    disableBeacon: true,
    disableOverlay: true,
  },
  {
    title: 'Pay Now',
    target: '.payButtonStep', //Pay Now button
    content: 'Clicking on Pay Now will complete the transaction.',
    disableBeacon: true,
    disableOverlay: true,
  },
  {
    title: 'Schedule Payment',
    target: '.scheduleButtonStep', //schedule payment link
    content: 'Want to schedule a future one-time or recurring payment? Click here.',
    disableBeacon: true,
    disableOverlay: true,
  },
];
