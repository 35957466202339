import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Empty } from 'antd';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { uploadSilaDoc } from 'services/api/landlordApi/create';

import { getPropertyGroupOnboardingStatus } from 'services/api/landlordApi/read';

import {
  identityVerification,
  SSNVerification,
  businessVerification,
  FEINVerification,
  kycKybMap,
} from 'resources/SilaDocs';

import { displayAlert, truncateString } from 'resources/helpers';

import AlertFade from 'components/AlertFade/AlertFade';

import Button from 'components/Button';

import Text from 'components/Text';
import Space from 'components/Space';
import KycDocs from 'components/KycDocs';
import PanelFooter from 'components/Panel/PanelFooter';

const acceptableFileTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];

const VerificationDocs = (props) => {
  const { propertyGroup, boardingStatus, setRequiredDocs, setDrawerVisible, setOnboardingState, setDocsReview } =
    props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const [uploading, setUploading] = useState(false);

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const [uploadState, setUploadState] = useState({
    fileList: [],
    uploading: false,
  });

  const [showUpload, setShowUpload] = useState({
    kycDocs1: false,
    kycDocs2: false,
    kybDocs1: false,
    kybDocs2: false,
  });

  const [uploadingState, setUploadingState] = useState({
    kycDocs1: false,
    kycDocs2: false,
    kybDocs1: false,
    kybDocs2: false,
  });

  const [uploadsFinished, setUploadsFinished] = useState(false);

  useEffect(() => {
    // console.log('ƒ useEffect on showUpload', showUpload);
    setUploadsFinished(!Object.values(showUpload).includes(true));
  }, [showUpload]);

  useEffect(() => {
    // console.log('ƒ useEffect on groupOnboardingStatus', groupOnboardingStatus);
    // map tags to upload components
    const { documents } = boardingStatus?.ach;
    if (documents) {
      const documentKeys = Object.entries(documents)
        .filter((entry) => entry[1].length !== 0)
        .map((e) => e[0]);
      console.log('documents required for', documentKeys);
      documentKeys.forEach((key) => {
        const uploadSetting = kycKybMap[key];
        setShowUpload((prev) => ({ ...prev, ...uploadSetting }));
      });
    }
    return () => console.log('cancel');
  }, [boardingStatus]);

  const customRequest = async (file) => {
    // console.log('ƒ customRequest', file);
    try {
      const { info, source, documentType } = file;

      if (info.file.type === undefined) throw Error('Invalid file type');

      if (!acceptableFileTypes.includes(info.file.type)) {
        throw Error('Invalid File Type');
      }
      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }

      // setUploading(true);
      setUploadingState((prev) => {
        return { ...prev, [source]: true };
      });

      setUploadState({ ...uploadState, fileList: [...uploadState.fileList, file] });

      setShowUpload({ ...showUpload, [source]: false });

      setUploadingState((prev) => {
        return { ...prev, [source]: false };
      });
      // displayAlert(setAlert, 'successful upload', 'success', 4000);
      setUploading(false);

      // const files = [];
      // if (uploadState.fileList.length) {
      //   for (let i = 0; i < uploadState.fileList.length; i++) {
      //     files.push();
      //   }
      //
      //   await Promise.all(files);
      //
      //   setUploadState({ ...uploadState, fileList: [] });
      // }
      // const ext = type.mime === 'image/jpeg' ? 'jpeg' : type.ext;
      // setSilaVerificationDocs(false);
      // openPlaid();
    } catch (error) {
      setUploadingState({
        kycDocs1: false,
        kycDocs2: false,
        kybDocs1: false,
        kybDocs2: false,
      });
      displayAlert(setAlert, error?.message || 'There was an error with your request', 'warning', 4000);
    }
  };

  const onFinish = async () => {
    try {
      setUploading(true);
      await uploadSilaDoc(cognitoToken, uploadState.fileList, propertyGroup[0].id, boardingStatus.ach.silaHandle);
      const res = await getPropertyGroupOnboardingStatus(cognitoToken, propertyGroup[0].id);
      setOnboardingState(res);
      setUploading(false);
      displayAlert(setAlert, 'successful upload', 'success', 4000);
      setTimeout(() => {
        setRequiredDocs(false);
        setDocsReview(true);
        // setDrawerVisible(false);
      }, 4000);
    } catch (error) {
      displayAlert(setAlert, 'Something went wrong', 'warning', 4000);
      setUploading(false);
    }
  };

  const trashFile = (index) => {
    const keptFiles = uploadState.fileList.filter((f, i) => i !== index);
    const fileSource = uploadState.fileList[index].source;

    setUploadState({ fileList: keptFiles, uploading: false });
    setShowUpload((prev) => {
      console.log(prev);
      return { ...prev, [fileSource]: true };
    });
  };

  return (
    <Container>
      <ContentContainer className="content-container">
        <Text color="black" size={24} sizeXs={20} strong centered>
          Upload Your Documents
        </Text>
        <Space vertical={20} />

        <Text color="black" size={14} centered>
          To help the government fight terrorism funding and money laundering, all financial institutions are
          required by federal law to obtain, verify, and record information about you, including your name,
          address, and date of birth. Please upload the following documents:
        </Text>

        <Space vertical={20} />

        {showUpload.kycDocs1 && (
          <KycDocs
            id="kycDocs1"
            key="kyc-docs-1"
            title="Please provide valid a photo Id"
            placeholder="Select Itendity Document"
            files={identityVerification}
            setUploadState={setUploadState}
            uploadState={uploadState}
            customRequest={customRequest}
            accept={acceptableFileTypes}
            showUpload={showUpload}
            uploadingState={uploadingState}
          />
        )}

        {showUpload.kycDocs2 && (
          <KycDocs
            id="kycDocs2"
            key="kyc-docs-2"
            title="Please provide tax verification"
            placeholder="Select Tax Document"
            files={SSNVerification}
            setUploadState={setUploadState}
            uploadState={uploadState}
            customRequest={customRequest}
            accept={acceptableFileTypes}
            showUpload={showUpload}
            uploadingState={uploadingState}
          />
        )}
        {showUpload.kybDocs1 && (
          <KycDocs
            id="kybDocs1"
            key="kyb-docs-1"
            title="Please provide business verification"
            placeholder="Select Business Document"
            files={businessVerification}
            setUploadState={setUploadState}
            uploadState={uploadState}
            customRequest={customRequest}
            accept={acceptableFileTypes}
            showUpload={showUpload}
            uploadingState={uploadingState}
          />
        )}
        {showUpload.kybDocs2 && (
          <KycDocs
            id="kybDocs2"
            key="kyb-docs-2"
            title="Please provide business tax verification"
            placeholder="Select Business Tax Document"
            files={FEINVerification}
            setUploadState={setUploadState}
            uploadState={uploadState}
            customRequest={customRequest}
            accept={acceptableFileTypes}
            showUpload={showUpload}
            uploadingState={uploadingState}
          />
        )}

        <Text color="black" size={14} key="text">
          Uploaded Documents
        </Text>
        <AnimatePresence>
          <UploadContainer
            key="upload-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 3 } }}
          >
            {uploadState.fileList.length ? (
              uploadState.fileList.map((file, i) => {
                const f = file.info.file;
                return (
                  <div
                    style={{ display: 'flex', alignItems: 'center', marginTop: '4px', marginBottom: '4px' }}
                    key={f.uid}
                  >
                    <PaperClipOutlined style={{ fontSize: '14px', marginRight: '5px' }} />
                    <span style={{ flexGrow: 2 }}>{truncateString(f.name, 30, true)}</span>

                    <DeleteOutlined style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => trashFile(i)} />
                  </div>
                );
              })
            ) : (
              <Empty
                style={{ margin: 0 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ height: 28 }}
                description="No Documents"
              />
            )}
          </UploadContainer>
        </AnimatePresence>
      </ContentContainer>

      <PanelFooter>
        <AlertFade
          type={alert.type}
          isVisible={!!alert.message}
          message={alert.message}
          // description={message.description}
          // alertPosition="absolute"
          position="absolute"
          width={80}
          bottom={100}
          minHeight="40"
        />
        <Button
          color="violet"
          bottom={0}
          alignment="center"
          onClick={onFinish}
          disabled={!uploadsFinished}
          loading={uploading}
        >
          FINISH
        </Button>
      </PanelFooter>
    </Container>
  );
};

const ContentContainer = styled.div`
  overflow: hidden;
  overflow-y: scroll !important;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 20px 0px 20px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const UploadContainer = styled(motion.div)`
  margin-top: 8px;
  text-overflow: ellipsis;
  border: 1px dashed #d9d9d9;
  padding: 12px;
`;

export default VerificationDocs;
