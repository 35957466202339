import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Row, Col, Drawer } from 'antd';

import { CheckOutlined } from '@ant-design/icons';

import { getSessionDetails, getPropertyGroups, getBillingDetails } from 'services/api/landlordApi/read';
import { getServicePlanData } from 'services/api/common/read';
import { updatePaymentGateway } from 'services/api/landlordApi/update';
import { upgradeDowngradeServicePlan } from 'services/api/landlordApi/create';

import { displayAlertAction } from 'store/actions/globalActions';
import { getServicePlanAction } from 'store/actions/sessionActions';

import CardWithTitle from 'components/CardWithTitle';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';

import AccountProfilePanel from '../components/AccountProfilePanel/AccountProfilePanel';
import AccountPreferencesPanel from '../components/AccountPreferencesPanel/AccountPreferencesPanel';
import PlansBillingsPanel from '../components/PlansBillingsPanel/PlansBillingsPanel';
import BillingsDetailsPanel from '../components/BillingDetailsPanel/BillingDetailsPanel';
import NewsPanel from '../components/NewsPanel/NewsPanel';
import OnboardingOverlay from '../components/OnboardingOverlay/OnboardingOverlay';

import Onboarding from '../Onboarding/Onboarding';

//import './style.scss';

let timerId = '';

const ServicePlansWepay = (props) => {
  // console.log('[ServicePlansWepay.js]', props);
  const { showOnboardingOverlay, setShowOnboardingOverlay, setShowOnboarding, setDrawerVisible } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const [loading, setLoading] = useState({
    button1: false,
    button2: false,
    button3: false,
  });
  const [userServicePlan, setUserServicePlan] = useState({ id: 0 });
  const [disabledButtons, setDisabledButtons] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading({
        button1: true,
        button2: true,
        button3: true,
      });

      const cognitoToken = session.cognitoTokens.idToken.jwtToken;
      const currentServicePlan = await getServicePlanData(cognitoToken);
      const propertyGroups = await getPropertyGroups(cognitoToken, 1, 20);
      // console.log(propertyGroups);
      if (propertyGroups.items.length > 1) {
        setDisabledButtons(true);
      }

      setUserServicePlan(currentServicePlan);

      setLoading({
        button1: false,
        button2: false,
        button3: false,
      });
    } catch (error) {
      setLoading({
        button1: false,
        button2: false,
        button3: false,
      });
    }
  };

  const checkLandlordStatus = (setLoading1, setDisableButton1, setDisableButton2, displayAlert) => {
    try {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        const result = await getSessionDetails();

        if (result.state === 'new2-step2') {
          setLoading1(false);
          setDisableButton1(true);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.state === 'action-required') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.state === 'error') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
          displayAlert('There was an error with your request', 'warning');
        }
      }, 2000);
    } catch (error) {
      if (timerId) clearInterval(timerId);
      setLoading1(false);
      displayAlert('There was an error with your request', 'warning');
    }
  };

  const handleUpgradeDowngrade = async (sp, button) => {
    try {
      setLoading({
        ...loading,
        [`button${button}`]: true,
      });
      // Nuvei users that wants to change to wepay
      if (session.userData.paymentGateway === 'Nuvei') {
      }
      if (session.userData.paymentGateway === 'Wepay' || session.userData.paymentGateway === 'SilaStripe') {
        // only valid for wepay landlords
        const cognitoToken = session.cognitoTokens.idToken.jwtToken;
        const oldSp = userServicePlan.id;

        await upgradeDowngradeServicePlan(
          cognitoToken,
          {
            processorId: session.userData.servicePlan.processor.id,
            servicePlanId: sp,
          },
          +session.userData.id
        );
        dispatch(displayAlertAction('', 'success', 'Your Service Plan Was Successfully Updated', true, 5000));
        const currentServicePlan = await getServicePlanData(cognitoToken);
        setUserServicePlan(currentServicePlan);

        if (+oldSp === 6 || +oldSp === 5 || +oldSp === 1) {
          const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);

          if (!plansBillingRes.paymentMethod.paymentMethod) {
            props.setDrawerVisible(true);
            props.setShowBillingDetailsPanel(true);
          }
        }
        setLoading({
          button1: false,
          button2: false,
          button3: false,
        });
        dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
      }
    } catch (error) {
      setLoading({
        button1: false,
        button2: false,
        button3: false,
      });
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
    }
  };

  const checkCurrentServicePlan = (sp) => {
    if (sp === 'PayAsYouGo') {
      if (
        +userServicePlan.id === 6 ||
        +userServicePlan.id === 1 ||
        +userServicePlan.id === 8 ||
        +userServicePlan.id === 11
      ) {
        return true;
      }
      return false;
    }
  };

  return (
    <>
      <StyledDrawer
        closable={false}
        // closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
        placement="right"
        destroyOnClose
        onClose={() => {
          props.setDrawerVisible(false);
          props.setShowOnboarding(false);
          props.setShowAccountProfile(false);
          props.setShowAccountPreferences(false);
          props.setShowPlansBillingPanel(false);
          props.setShowBillingDetailsPanel(false);
          dispatch({
            type: 'UNSET_UPGRADE_DOWNGRADE',
          });
        }}
        open={props.drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {props.drawerVisible && props.showOnboarding && (
          <Onboarding
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            checkLandlordStatus={checkLandlordStatus}
            updatePaymentGateway={updatePaymentGateway}
          />
        )}

        {props.drawerVisible && props.showAccountProfile && (
          <AccountProfilePanel
            setShowAccountProfile={props.setShowAccountProfile}
            setDrawerVisible={props.setDrawerVisible}
          />
        )}
        {props.drawerVisible && props.showAccountPreferences && (
          <AccountPreferencesPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowAccountPreferences={props.setShowAccountPreferences}
          />
        )}
        {props.drawerVisible && props.showPlansBillingPanel && (
          <PlansBillingsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
          />
        )}

        {props.drawerVisible && props.showBillingDetailsPanel && (
          <BillingsDetailsPanel
            setDrawerVisible={props.setDrawerVisible}
            setShowBillingDetailsPanel={props.setShowBillingDetailsPanel}
            setShowPlansBillingPanel={props.setShowPlansBillingPanel}
            showBillingDetailsPanel={props.showBillingDetailsPanel}
          />
        )}
      </StyledDrawer>
      {/* Removed from UI 4.0.
      <NewsPanel
        title="What's New @PayRent"
        placement="right"
        closable={true}
        onClose={() => {
          props.setNewsDrawerVisible(false);
        }}
        open={props.newsDrawerVisible}
        posts={false}
        isOpen={props.newsDrawerVisible}
      /> */}
      {showOnboardingOverlay && (
        <OnboardingOverlay
          showOnboardingOverlay={showOnboardingOverlay}
          setShowOnboardingOverlay={setShowOnboardingOverlay}
          setShowOnboarding={setShowOnboarding}
          setDrawerVisible={setDrawerVisible}
        />
      )}
      <StyledRow justify="center" gutter={[52, 20]} className="service-plans-row">
        <Col xs={23} sm={18} md={11} xl={9} xxl={8}>
          <CardWithTitle
            title="Pay-As-You-Go"
            hoverable
            headFontSize={28}
            headAlign="center"
            bodyMinHeight={640}
            type="pricing"
            style={{ height: '100%', borderRadius: '8px' }}
          >
            <Space vertical={15} />
            <Text centered>
              <Text color="black" size={46}>
                $0
              </Text>
              <Text color="grey3" size={18} strong>
                / month
              </Text>
            </Text>
            <Space vertical={25} />
            <Text color="black" size={14} centered>
              Ideal for part-time landlords with less <br />
              than 10 properties
            </Text>
            <Space vertical={25} />
            <Text size={20} strong color="grey3" centered>
              Features
            </Text>
            <Text
              size={14}
              color="grey3"
              strong
              style={{ display: 'flex', marginBottom: '20px', marginTop: '30px' }}
            >
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Unlimited units
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              4-5 Day funding
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              $5 per bank transfer
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Express Funding available
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              3.5% + $0.30 per credit card transaction
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Rent reminders
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Payment controls
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              RentCred&trade; Rewards
            </Text>
            <Button
              style={{
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                justifyContent: 'center',
              }}
              disabled={checkCurrentServicePlan('PayAsYouGo') || disabledButtons}
              loading={loading.button1}
              color={
                +userServicePlan.id !== 6 || +userServicePlan.id !== 8 || +userServicePlan.id !== 11
                  ? 'yellow'
                  : 'grey'
              }
              onClick={() => handleUpgradeDowngrade(session.userData.paymentGateway === 'Wepay' ? 8 : 11, 1)}
              data-testid="upButtonTest"
            >
              {loading.button1 && 'LOADING'}
              {checkCurrentServicePlan('PayAsYouGo') && !loading.button1 && 'CURRENT PLAN'}
              {(+userServicePlan.id === 2 && !loading.button1 && 'DOWNGRADE') ||
                (+userServicePlan.id === 3 && !loading.button1 && 'DOWNGRADE') ||
                (+userServicePlan.id === 12 && !loading.button1 && 'DOWNGRADE') ||
                (+userServicePlan.id === 13 && !loading.button1 && 'DOWNGRADE')}
              {+userServicePlan.id === 5 && !loading.button1 && 'UPGRADE'}
            </Button>
          </CardWithTitle>
        </Col>
        <Col xs={23} sm={18} md={11} xl={9} xxl={8}>
          <CardWithTitle
            title="Do-It-Yourself"
            hoverable
            headFontSize={28}
            headAlign="center"
            bodyMinHeight={640}
            type="pricing"
            style={{ height: '100%', borderRadius: '8px' }}
          >
            <Space vertical={15} />
            <Text centered>
              <Text color="black" size={46}>
                $19
              </Text>
              <Text color="grey3" size={18} strong>
                / month
              </Text>
            </Text>
            <Space vertical={25} />
            <Text color="black" size={14} centered>
              Just right for full-time rental owners <br />
              with 5 to 25 units
            </Text>
            <Space vertical={25} />
            <Text size={20} strong color="grey3" centered>
              Features
            </Text>
            <Text
              size={14}
              color="grey3"
              strong
              style={{ display: 'flex', marginBottom: '20px', marginTop: '30px' }}
            >
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              25 units included ($3/additional unit)
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              4-5 Day funding
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              $2 per bank transfer
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Express Funding available
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              3.5% + $0.30 per credit card transaction
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              All Pay-As-You-Go features plus...
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Automatic Late Fees
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Rent Collection Enforcement
            </Text>
            <Button
              style={{
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                justifyContent: 'center',
              }}
              disabled={+userServicePlan.id === 2 || +userServicePlan.id === 12 || disabledButtons}
              loading={loading.button2}
              color={+userServicePlan.id !== 2 || +userServicePlan.id !== 12 ? 'yellow' : 'grey'}
              onClick={() => handleUpgradeDowngrade(session.userData.paymentGateway === 'Wepay' ? 2 : 12, 2)}
              data-testid="upButtonTest2"
            >
              {loading.button2 && 'LOADING'}
              {(+userServicePlan.id === 2 || +userServicePlan.id === 12) && !loading.button2 && 'CURRENT PLAN'}
              {(+userServicePlan.id === 3 || +userServicePlan.id === 13) && !loading.button2 && 'DOWNGRADE'}
              {+userServicePlan.id !== 2 &&
                +userServicePlan.id !== 12 &&
                +userServicePlan.id !== 3 &&
                +userServicePlan.id !== 13 &&
                !loading.button2 &&
                'UPGRADE'}
            </Button>
          </CardWithTitle>
        </Col>
        <Col xs={23} sm={18} md={11} xl={9} xxl={8}>
          <CardWithTitle
            title="Go-Like-A-Pro"
            hoverable
            headFontSize={28}
            headAlign="center"
            bodyMinHeight={640}
            type="pricing"
            style={{ height: '100%', borderRadius: '8px' }}
            className="golikeapro-column"
          >
            <Space vertical={15} />

            <Text centered>
              <Text color="black" size={46}>
                $49
              </Text>
              <Text color="grey3" size={18} strong>
                / month
              </Text>
            </Text>
            <Space vertical={25} />
            <Text color="black" size={14} centered>
              Perfect for professional property
              <br />
              managers with 25 or more rental units
            </Text>
            <Space vertical={25} />
            <Text size={20} strong color="grey3" centered>
              Features
            </Text>
            <Text
              size={14}
              color="grey3"
              strong
              style={{ display: 'flex', marginBottom: '20px', marginTop: '30px' }}
            >
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              10 units included ($3/additional unit)
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Express Funding included
            </Text>
            <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              FREE bank transfer
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              3.5% + $0.30 per credit card transaction
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              All Do-It-Yourself features plus...
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Manage multiple property portfolios
            </Text>
            <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
              <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
              Dedicated account management
            </Text>
            <Button
              style={{
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                justifyContent: 'center',
              }}
              color={+userServicePlan.id !== 3 || +userServicePlan.id !== 13 ? 'yellow' : 'grey'}
              disabled={+userServicePlan.id === 3 || +userServicePlan.id === 13}
              loading={loading.button3}
              onClick={() => handleUpgradeDowngrade(session.userData.paymentGateway === 'Wepay' ? 3 : 13, 3)}
              data-testid="upButtonTest3"
            >
              {loading.button3 && 'LOADING'}
              {+userServicePlan.id !== 3 && +userServicePlan.id !== 13 && !loading.button3 && 'UPGRADE'}
              {(+userServicePlan.id === 3 || +userServicePlan.id === 13) && !loading.button3 && 'CURRENT PLAN'}
            </Button>
          </CardWithTitle>
        </Col>
      </StyledRow>
    </>
  );
};

export default ServicePlansWepay;

const StyledRow = styled(Row)`
  margin-top: 10px !important;
  height: 85vh;
  @media screen and (max-width: 768px) {
    margin-top: 5px !important;
  }
  @media screen and (max-width: 576px) {
    margin-top: 5px !important;
  }
`;

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 1500px) {
        width: 460px !important;
      }
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    input {
      color: #595959;
    }

    @media screen and (max-height: 750px) {
      .ant-steps-small .ant-steps-item-description {
        font-size: 12px !important;
      }
    }
    @media screen and (max-height: 750px) {
      .ant-drawer-body h1 {
        font-size: 35px !important;
      }
      .ant-form-item {
        margin-bottom: 15px !important;
      }
    }
  `}
`;
