import React from 'react';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function ActionMenuButton(props) {
  const { children, ...rest } = props;
  return <StyledButton {...rest}>{children}</StyledButton>;
}

const StyledButton = styled(AntButton)`
  @media screen and (max-width: 1050px) {
    span {
      font-size: calc(8px + 0.5vw);
    }
  }
`;

ActionMenuButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ActionMenuButton.defaultProps = {
  type: 'link',
  children: 'Actions',
};

export default ActionMenuButton;
