import React from 'react';
import { Alert, Drawer as AntDrawer } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Text from 'components/Text/Text';
import CloseIcon from 'components/uielements/CloseIcon/CloseIcon';

function Drawer(props) {
  // console.log('[Drawer.js] props', props);
  const { children, message, type, alertMessage, alertType, styles, ...rest } = props;

  const mergedStyles = {
    header: {
      borderBottom: 'none',
      padding: '2px 0 0 0',
    },
    footer: {
      display: 'flex',
      height: '130px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    body: {
      padding: '0 24px',
    },
    ...styles,
  };

  return (
    <StyedlDrawer
      title={
        <div style={{ height: '68px', margin: '8px 24px 0 0' }}>
          {!!alertMessage && (
            <Alert
              message={
                <Text color="black" weight="normal">
                  {alertMessage}
                </Text>
              }
              type={alertType}
              showIcon
            />
          )}
        </div>
      }
      styles={mergedStyles}
      {...rest}
    >
      {children}
    </StyedlDrawer>
  );
}

Drawer.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeIcon: PropTypes.node,
  styles: PropTypes.object,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
};

Drawer.defaultProps = {
  width: 414,
  closeIcon: <CloseIcon />,
  alertType: 'info',
  alertMessage: 'here is a message',
};

const StyedlDrawer = styled(AntDrawer)`
  .ant-drawer-header-title {
    align-items: flex-start;
  }
`;

export default Drawer;
