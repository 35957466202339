import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Row, Col, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { fetchUserData } from 'services/api/landlordApi/read';
import { updateUserProfile } from 'services/api/landlordApi/update';
import { getUserDataV3 } from 'store/actions/sessionActions';
import { changePassword } from 'services/aws/cognito';
import { Montserrat } from 'resources/vgsFonts';
import { CloseSquareFilled, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

//import './style.scss';
import PanelFooter from 'components/Panel/PanelFooter';
import SpaceResponsive from 'components/SpaceResponsive';
import AlertFade from 'components/AlertFade';
import Button from 'components/Button';
import Text from 'components/Text';
import { checkZipCode, checkAddressValidator } from 'resources/FormValidators';
import { usStates } from '../../../../resources/FormData';
import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../../components/Panel/PanelHeader/PanelHeader';
import PhoneInput from 'components/PhoneInput/PhoneInput';

dayjs.extend(utc);

const css = {
  fontSize: '14px',
  border: '1px solid #D9D9D9',
  'background-clip': 'padding-box',
  boxSizing: 'border-box',
  width: '100%',
  borderRadius: '2px',
  padding: '4px 11px',
  color: '#595959',
  '@font-face': {
    'font-family': 'Montserrat',
    'font-style': 'normal',
    'font-weight': '400',
    src: Montserrat,
  },
  fontFamily: '"Montserrat"',
  fontVariant: 'tabular-nums',
  fontFeatureSettings: 'tnum, "tnum"',
  '&:focus': {
    border: '1px solid #ffdb29',
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f5f5f5',
    cursor: 'not-allowed',
    opacity: '1',
  },
  '@media screen and (max-width: 400px)': {
    fontSize: '14px',
  },
};

const { Option } = Select;

const AccountProfilePanel = (props) => {
  const { resetPanel, displayAlert } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const session = useSelector((store) => store.session);
  const [isLoading, setIsLoading] = useState(false);
  const [vgsForm, setVgsForm] = useState({});
  const [isLoaded, scriptLoaded] = useState(false);
  const [enableInput, setEnableInput] = useState(false);

  const [userFetchedData, setUserFetchedData] = useState(null);

  useEffect(() => {
    if (session.userData.paymentGateway === 'Nuvei') {
      const script = document.createElement('script');
      script.src = 'https://js.verygoodvault.com/vgs-collect/2.0/vgs-collect.js';
      script.async = true;
      script.onload = () => scriptLoaded(true);
      document.body.appendChild(script);
    }
  });

  useEffect(() => {
    if (isLoaded && session.userData.paymentGateway === 'Nuvei') {
      const createVgsForm = window.VGSCollect.create(
        process.env.REACT_APP_VGS_VAULT,
        process.env.REACT_APP_VGS_ENV,
        (s) => {
          if (s) {
            if (s.businessEin?.isDirty) form.validateFields(['businessEin']);
            if (s.routingNumber?.isDirty) form.validateFields(['routingNumber']);
            if (s.bankAccountNumber?.isDirty) form.validateFields(['bankAccountNumber']);
          }
        }
      );
      setVgsForm(createVgsForm);

      createVgsForm
        .field('#routingNumber .fake-input', {
          type: 'text',
          name: 'routingNumber',
          placeholder: '',
          validations: ['required'],
          css,
        })
        .mask('999999999', '');
      createVgsForm.field('#bankAccountNumber .fake-input', {
        type: 'text',
        name: 'bankAccountNumber',
        placeholder: '',
        validations: ['required'],
        css,
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const cognitoToken = session.cognitoTokens.idToken.jwtToken;
    try {
      // session.userData.id
      const data = await fetchUserData(cognitoToken, session.userData.id);

      setUserFetchedData({
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        website: data.website,
      });

      const formData = {
        businessName: data?.businessName ? data?.businessName : session?.userData?.cognito['custom:business_name'],
        city: data.city,
        address1: data.address1,
        zipCode: data.zip,
        email: session.userData.email,
        mobilePhone: data.cellPhone
          ? data.cellPhone.replace('+1', '')
          : session?.userData?.cognito['phone_number'].replace('+1', ''),
        birthdate: session?.userData?.dateOfBirth ? dayjs(session?.userData?.dateOfBirth).utc() : '',
      };

      let stateAbre = [];
      if (data?.state) {
        if (data?.state?.length > 2) {
          stateAbre = usStates.filter((s) => s.name === data.state.toUpperCase());
        }
        formData.state = data?.state.length > 2 ? stateAbre[0].abbreviation : data.state;
      }

      form.setFieldsValue(formData);
    } catch (error) {
      displayAlert('Your Request Failed', 'error');
    }
  };

  const handleUpdateRequest = async (cognitoToken, values) => {
    try {
      values.firstName = userFetchedData.firstName;
      values.lastName = userFetchedData.lastName;
      values.website = userFetchedData.website;
      values.id = userFetchedData.id;
      const birthdate = values.birthdate ? values.birthdate.format('YYYY-MM-DD') : '';

      values.birthdate = birthdate;
      await updateUserProfile(cognitoToken, values);
      dispatch(getUserDataV3(cognitoToken));

      displayAlert('Your profile was updated', 'success');
      setTimeout(() => {
        setIsLoading(false);
        resetPanel();
      }, 2500);
    } catch (error) {
      displayAlert(error.data?.errors[0]?.detail || 'Your Request Failed', 'error');
      setIsLoading(false);
    }
  };

  const onFinish = async (values) => {
    const cognitoToken = session.cognitoTokens.idToken.jwtToken;
    const normalizePhone = values.mobilePhone.replace(/\s/g, '');
    values.mobilePhone = normalizePhone;

    setIsLoading(true);
    if (values.password || values.confirm) {
      if (!values.oldPassword || values.oldPassword === '') {
        await form.validateFields(['oldPassword']);
        return;
      }
      if (values.password !== values.confirm) {
        form.validateFields(['password', 'confirm']);
        setIsLoading(false);
        return;
      }
      try {
        await changePassword(values.oldPassword, values.password);
      } catch (error) {
        let errMessage = error;
        if (error === 'Incorrect username or password.') {
          errMessage = 'Incorrect Old Password';
        }

        displayAlert(errMessage || 'Your Request Failed', 'error');
        setIsLoading(false);
        return;
      }
    }
    if (session.userData.paymentGateway === 'Nuvei') {
      if (enableInput) {
        vgsForm.submit(
          '/post',
          {},
          (status, response) => {
            values.routingNumber = JSON.parse(response.data).routingNumber;
            values.bankAccountNumber = JSON.parse(response.data).bankAccountNumber;
            handleUpdateRequest(cognitoToken, values);
          },
          (error) => {
            displayAlert('Your Request Failed', 'error');
            setIsLoading(false);
          }
        );
      } else {
        handleUpdateRequest(cognitoToken, values);
      }
    }
    if (session.userData.paymentGateway === 'Wepay' || session.userData.paymentGateway === 'Finix') {
      handleUpdateRequest(cognitoToken, values);
    }
  };

  const checkRoutingNumber = (rule, value) => {
    if (!vgsForm.state.routingNumber.isValid && enableInput) {
      return Promise.reject('Please input your ABA routing number.');
    }

    return Promise.resolve();
  };

  const checkBankAccountNumber = (rule, value) => {
    if (!vgsForm.state.bankAccountNumber.isValid && enableInput) {
      return Promise.reject('Please input your bank account number.');
    }
    return Promise.resolve();
  };

  return (
    <StyledForm form={form} name="accountProfile" onFinish={onFinish} data-testid="accountProfileFormTest">
      <Title>Account Settings</Title>
      <Subtitle>Profile</Subtitle>
      <SpaceResponsive lg={10} md={10} sm={10} />
      <FormContentContainer className="content-container">
        <Text size={20} color="black" weight={500} style={{ display: 'block', marginBottom: '10px' }}>
          Account Details
        </Text>
        Business Name
        <StyledItem
          name="businessName"
          data-testid="businessNameTest"
          rules={[
            {
              required: true,
              message: "Please enter the business's name.",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </StyledItem>
        <Text size={13} color="grey2" style={{ marginBottom: '5px' }}>
          Enter your full name if you’re not a business
        </Text>
        <Row gutter={15}>
          <Col span={14}>
            Street Address
            <Form.Item
              name="address1"
              data-testid="address1Test"
              rules={[
                { required: true, message: 'Please input your address', whitespace: true },
                {
                  pattern: new RegExp(/(?!^\d+$)^.+$/),
                  message: 'Address must be numbers and letters',
                },
                {
                  max: 100,
                  message: 'Address cant be more than 100 characters',
                },
                {
                  validator: checkAddressValidator,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            Zip Code
            <Form.Item
              name="zipCode"
              rules={[
                {
                  validator: checkZipCode,
                },
              ]}
            >
              <Input data-testid="zipCodeTest" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={14}>
            City
            <Form.Item
              name="city"
              data-testid="cityTest"
              rules={[
                {
                  required: true,
                  message: 'Please enter the city.',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            State
            <Form.Item name="state" rules={[{ required: true, message: 'Please select a state' }]}>
              <Select showSearch placeholder="Select State" onChange={() => {}}>
                {usStates.map((s, i) => (
                  <Option value={s.abbreviation} key={i}>
                    {s.abbreviation}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={14}>
            Email Address
            <Form.Item name="email" data-testid="emailTest">
              <Input disabled data-testid="emailInputTest" />
            </Form.Item>
          </Col>
          <Col span={10}>
            Mobile Phone
            <Form.Item
              name="mobilePhone"
              autoComplete="off"
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone number',
                },
                {
                  // pattern: new RegExp('^[0-9]{6,}$'),
                  // supports ten characters without + sign
                  // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                  pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                  message: 'Please enter only numbers - min length 10',
                },
                {
                  message: 'Please input a valid phone number.',
                  max: 12,
                },
              ]}
            >
              {/* <MaskedInput
                    className="masked-input"
                    placeholder="111 111 1111"
                    mask="000 000 0000"
                    minLength={6}
                    placeholderChar=" "
                    data-testid="phoneTest"
                    prefix="+1"
                    name="phone"
                  /> */}
              <PhoneInput />
            </Form.Item>
          </Col>
        </Row>
        Birthdate
        <Form.Item
          name="birthdate"
          whitespace="true"
          rules={[
            {
              required: true,
              message: 'Please enter your birthdate',
            },
          ]}
        >
          <DatePicker
            placeholder="Birthdate (mm/dd/yyyy)"
            disabledDate={(current) => current && current > dayjs().subtract(18, 'years')}
            defaultPickerValue={dayjs().subtract(18, 'years')}
            style={{ width: '100%' }}
            format="MM/DD/YYYY"
          />
        </Form.Item>
        Old password
        <Form.Item
          name="oldPassword"
          rules={[
            {
              pattern: new RegExp('^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@~#$%^&+*!=]).*$'),
              message: 'Min 8 characters, at least 1 capital, 1 lowercase, 1 number and 1 special character',
            },
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        New password
        <Form.Item
          name="password"
          dependencies={['confirm']}
          whitespace="true"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('confirm') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Passwords do not match!');
              },
            }),
            {
              pattern: new RegExp('^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@~#$%^&+*!=]).*$'),
              message: 'Min 8 characters, at least 1 capital, 1 lowercase, 1 number and 1 special character',
            },
          ]}
          data-testid="newPasswordTestFormItemTest"
        >
          <Input.Password autoComplete="off" data-testid="passwordTest" />
        </Form.Item>
        Confirm Password
        <Form.Item
          name="confirm"
          dependencies={['password']}
          // hasFeedback
          whitespace="true"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Passwords do not match!');
              },
            }),
            {
              pattern: new RegExp('^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@~#$%^&+*!=]).*$'),
              message: 'Min 8 characters, at least 1 capital, 1 lowercase, 1 number and 1 special character',
            },
          ]}
        >
          <Input.Password
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            data-testid="confirmPasswordTest"
          />
        </Form.Item>
        {session.userData.paymentGateway === 'Nuvei' && (
          <>
            <Row gutter={{ xs: 5, sm: 8, md: 15 }} style={{ display: !enableInput && 'none' }}>
              <Col span={12}>
                ABA Routing Number
                <StyledItem>
                  <Form.Item
                    name="routingNumber"
                    rules={[
                      {
                        validator: checkRoutingNumber,
                      },
                    ]}
                  >
                    <div
                      id="routingNumber"
                      className="form-group"
                      style={{ width: '99.9%' }}
                      key="2"
                      data-testid="fakeRoutingInputTest"
                    >
                      <div className="form-control-static">
                        <span className="fake-input"></span>
                      </div>
                    </div>
                  </Form.Item>
                </StyledItem>
              </Col>
              <Col span={12}>
                Bank Account Number
                <StyledItem>
                  <Form.Item
                    name="bankAccountNumber"
                    rules={[
                      {
                        validator: checkBankAccountNumber,
                      },
                    ]}
                  >
                    <div id="bankAccountNumber" className="form-group" style={{ width: '99.9%' }} key="3">
                      <div className="form-control-static">
                        <span className="fake-input"></span>
                      </div>
                    </div>
                  </Form.Item>
                </StyledItem>
              </Col>
            </Row>

            <Row gutter={{ xs: 5, sm: 8, md: 15 }} style={{ display: enableInput && 'none' }}>
              <Col span={12}>
                ABA Routing Number
                <StyledItem>
                  <Form.Item name="routingNumber">
                    <Input placeholder="********" disabled />
                  </Form.Item>
                </StyledItem>
              </Col>
              <Col span={12}>
                Bank Account Number
                <StyledItem>
                  <Form.Item name="bankAccountNumber">
                    <Input placeholder="********" disabled />
                  </Form.Item>
                </StyledItem>
                <Text
                  onClick={() => setEnableInput(true)}
                  size={14}
                  underline
                  pointer
                  style={{ display: 'block', textAlign: 'right' }}
                >
                  Change
                </Text>
              </Col>
            </Row>
          </>
        )}
      </FormContentContainer>
      <PanelFooter>
        <SaveItem className="save-form-item">
          <div style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              color="violet"
              loading={isLoading}
              alignment="center"
              data-testid="buttonTest"
              bottom={0}
              size="large"
            >
              SAVE
            </Button>
          </div>
        </SaveItem>
      </PanelFooter>
    </StyledForm>
  );
};

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 24px 0px 24px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const StyledForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 3px !important;
`;

export default AccountProfilePanel;
