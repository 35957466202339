import React from 'react';
import styled, { css } from 'styled-components';

const SpaceResponsive = (props) => (
  <StyledDiv
    xs={props.xs}
    sm={props.sm}
    md={props.md}
    xmd={props.xmd}
    lg={props.lg}
    data-testid="spaceResponsiveTest"
  />
);

const StyledDiv = styled.div`
  ${(props) => {
    if (props.lg) {
      return css`
        margin-top: ${props.lg}px !important;
        margin-bottom: ${props.lg}px !important;
      `;
    }
  }};
  ${(props) => {
    if (props.xmd) {
      return css`
        @media screen and (max-height: 850px) {
          margin-top: ${props.xmd}px !important;
          margin-bottom: ${props.xmd}px !important;
        }
      `;
    }
  }};
  ${(props) => {
    if (props.md) {
      return css`
        @media screen and (max-height: 800px) {
          margin-top: ${props.md}px !important;
          margin-bottom: ${props.md}px !important;
        }
      `;
    }
  }};
  ${(props) => {
    if (props.sm) {
      return css`
        @media screen and (max-height: 750px) and (min-width: 1280px) {
          margin-top: ${props.sm}px !important;
          margin-bottom: ${props.sm}px !important;
        }
      `;
    }
  }};
`;

export default SpaceResponsive;
