import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Icon, { MenuOutlined, LogoutOutlined } from '@ant-design/icons';

import { ReactComponent as HomeIcon } from 'assets/drawer-icons/home.svg';
import { ReactComponent as TransactionsIcon } from 'assets/drawer-icons/coin.svg';
import { ReactComponent as PaymentMethodsIcon } from 'assets/drawer-icons/wallet.svg';
import { ReactComponent as LifeBuoyIcon } from 'assets/drawer-icons/lifebuoy.svg';
import { ReactComponent as SettingsIcon } from 'assets/drawer-icons/settings.svg';
import { ReactComponent as RentCredIcon } from 'assets/images/rentcred/rentcred-icon.svg';

import { openFreshChatWidget } from 'utils';

// import './styles.scss';

const TenantMobileMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const session = useSelector((store) => store.session);
  const currentSelectedMenu = useSelector((store) => store.global.menu.currentSelectedMenu);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const subMenuNode = useRef();
  const moreIconRef = useRef();
  const moreTextRef = useRef();
  const liMoreRef = useRef();

  useEffect(() => {
    switch (location.pathname) {
      case '/tenant':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'tenant' });
        break;
      case '/tenant/wallet':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'wallet' });
        break;
      case '/tenant/transactions':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'transactions' });
        break;
      case '/tenant/settings':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'settings' });
        break;
      case '/tenant/rentcred':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'rentCred' });
        break;
      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (subMenuNode.current) {
      if (
        subMenuNode.current.contains(e.target) ||
        moreIconRef.current.contains(e.target) ||
        moreTextRef.current.contains(e.target) ||
        liMoreRef.current.contains(e.target)
      ) {
        // inside click
        return;
      } else {
        setShowSubMenu(false);
      }
    }
  };

  const handleClick = (value) => {
    let url;

    switch (value) {
      case 'tenant':
        url = '/tenant';
        break;
      case 'wallet':
        url = '/tenant/wallet';
        break;
      case 'transactions':
        url = '/tenant/transactions';
        break;
      case 'settings':
        url = '/tenant/settings';
        break;
      case 'rentCred':
        url = '/tenant/rentcred';
        break;
      case 'help':
        break;
      case 'logout':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'logout' });
        url = '/sign-out';
        break;
      default:
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'tenant' });
        url = '/tenant';
        break;
    }

    history.push(url);
  };

  const toggleMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  const redirect = (path) => {
    history.push(path);
    setShowSubMenu(false);
  };

  return (
    <TopRightMenu className="tenant-mobile-menu">
      <ul className="tenant-mobile-main-menu">
        <li className={currentSelectedMenu === 'tenant' ? 'mobile-active' : 'mobile-icon'}>
          <Icon component={HomeIcon} onClick={() => handleClick('tenant')} data-testid="homeTest" />
          <span className="text-menu">Home</span>
        </li>
        <li className={currentSelectedMenu === 'transactions' ? 'mobile-active' : 'mobile-icon'}>
          <Icon
            component={TransactionsIcon}
            onClick={() => handleClick('transactions')}
            data-testid="transactionsTest"
          />
          <span>Transactions</span>
        </li>
        <li className={currentSelectedMenu === 'wallet' ? 'mobile-active' : 'mobile-icon'}>
          <Icon component={PaymentMethodsIcon} onClick={() => handleClick('wallet')} data-testid="walletTest" />
          <span>Wallet</span>
        </li>
        <li className="mobile-icon more-mobile-button" onClick={toggleMenu} ref={liMoreRef} data-testid="moreTest">
          <MenuOutlined ref={moreIconRef} />
          <span ref={moreTextRef}>More</span>
        </li>
      </ul>
      {showSubMenu && (
        <SubMenu className={showSubMenu ? 'sub-menu display' : 'sub-menu'} ref={subMenuNode}>
          <ul>
            <li
              onClick={() => {
                openFreshChatWidget(
                  session.userData.id,
                  session.userData.firstName,
                  session.userData.email,
                  session.userData.cognito['custom:landlord'],
                  session.userData.servicePlan.name,
                  session.userData.servicePlan.version
                );
                setShowSubMenu(false);
              }}
              data-testid="freshChatTest"
            >
              <Icon component={LifeBuoyIcon} className="submenu-icon" />
              <span className="submenu-text">Support</span>
            </li>
            <li onClick={() => redirect('/sign-out')} data-testid="logOutTest">
              <LogoutOutlined className="submenu-icon" />

              <span className="submenu-text">Log Out</span>
            </li>
            <li
              onClick={() => redirect('/tenant/settings')}
              className={currentSelectedMenu === 'settings' && 'submenu-active'}
              data-testid="settingsTest"
            >
              <Icon component={SettingsIcon} className="submenu-icon" />
              <span className="submenu-text">Settings</span>
            </li>

            <li
              onClick={() => redirect('/tenant/rentcred')}
              className={currentSelectedMenu === 'rentCred' && 'submenu-active'}
              data-testid="rentcredTest"
            >
              <Icon component={RentCredIcon} className="submenu-icon" />
              <span className="submenu-text">RentCred</span>
            </li>
          </ul>
        </SubMenu>
      )}
    </TopRightMenu>
  );
};

const SubMenu = styled.div`
  width: 153px;
  height: 170px;
  position: absolute;
  background-color: white;
  color: #c0c0c0;
  border-radius: 4px;
  bottom: 63px;
  right: 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    padding: 10px 0px;
  }
`;

const TopRightMenu = styled.div`
  display: flex;
  align-content: center;
  justify-content: inherit;
  width: 100vw;
`;

export default TenantMobileMenu;
