import React, { useState } from 'react';
import SalesDrawer from '../SalesDrawer/SalesDrawer';
import SalesCopy from './SalesCopy/SalesCopy';
import { StyledCTA } from './SalesCard.styles';
import RightArrow from 'assets/images/right_arrow_wht.svg';

export default function SalesCard(props) {
  const [isDesktop] = useState(window.innerWidth > 768);

  if (isDesktop) {
    return (
      <div style={{ width: '380px', marginRight: '24px', padding: '24px' }} data-testid="sales-card-test">
        <SalesCopy />
        <StyledCTA>
          Yes I'm Ready!
          <span>
            <img className="right-arrow-wht" src={RightArrow} alt="right arrow" style={{ width: '48px' }} />
          </span>
        </StyledCTA>
      </div>
    );
  }
  return <SalesDrawer />;
}
