import React from 'react';
import { Descriptions } from 'antd';
import { calculateRenterFee } from 'resources/helpers';

const TransactionDescription = ({ record }) => {
  // console.log('[TransactionDescription.js]', record);
  const { detail } = record;
  const items = [
    {
      key: '1',
      label: 'Payment Method',
      children: detail.payment?.paymentMethodType || 'N/A',
    },
    {
      key: '2',
      label: 'Your fee',
      children: detail.payment
        ? `$${calculateRenterFee(detail.payment.fee.amount, detail.payment.fee.landlordAbsorbPercent)}`
        : 'N/A',
    },
    {
      key: '3',
      label: 'Amount',
      children: `$${detail.billingCharge ? detail.billingCharge.amount : detail.payment.amount}`,
    },
    {
      key: '4',
      label: 'Memo',
      children: detail.memo,
      span: 3,
    },
  ];

  return <Descriptions items={items} bordered={false} />;
};

export default TransactionDescription;
