import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Form, Input, InputNumber, Row, Col, Select, DatePicker, Checkbox, Tooltip } from 'antd';
import styled from 'styled-components';
import {
  boardingStep4CheckZipCode,
  boardingStep4checkCity,
  checkAddressValidator,
} from 'resources/FormValidators';
import parser from 'parse-address';

import { InfoCircleFilled } from '@ant-design/icons';
import { Montserrat } from 'resources/vgsFonts';
import MaskedInput from 'antd-mask-input';

import ExternalLink from 'components/ExternalLink';
import Button from 'components/Button';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import Text from 'components/Text';

import { getRoutingNumberStatus } from 'services/api/common/read';
import { createOnboarding, submitAppForOnboarding } from 'services/api/landlordApi/create';
import { getBoardingInformation, getOnboardingStatus, getBoardingStatus } from 'services/api/landlordApi/read';
import { updateOnBoarding } from 'services/api/landlordApi/update';

import { vgsValidator } from 'utils';
import { usStates } from '../../../../../../resources/FormData';

const { Option } = Select;

const css = {
  fontSize: '12px',
  border: '1px solid #D9D9D9',
  'background-clip': 'padding-box',
  boxSizing: 'border-box',
  width: '100%',
  borderRadius: '2px',
  padding: '4px 11px',
  color: '#595959',
  height: '32px',
  '@font-face': {
    'font-family': 'Montserrat',
    'font-style': 'normal',
    'font-weight': '400',
    src: Montserrat,
  },
  fontFamily: '"Montserrat"',
  fontVariant: 'tabular-nums',
  fontFeatureSettings: 'tnum, "tnum"',
  '&:focus': {
    border: '1px solid #ffdb29',
  },
  '&:disabled': {
    color: 'rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f5f5f5',
    cursor: 'not-allowed',
    opacity: '1',
  },
  '@media screen and (max-width: 400px)': {
    fontSize: '14px',
  },
};

const NuveiPaymentAccountForm = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const cognitoToken = useSelector((store) => store.session.cognitoTokens.idToken.jwtToken);
  const upgradeDowngrade = useSelector((store) => store.landlord.upgradeDowngrade);

  const { userData } = session;
  // const [form] = Form.useForm();
  const [message, setMessage] = useState({ type: 'warning', message: '' });
  const [loading, setLoading] = useState(false);
  const [vgsForm, setVgsForm] = useState({});
  const [isLoaded, scriptLoaded] = useState(false);

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [servicePlanId, setServicePlanId] = useState('');
  const ssnInput = useRef(null);
  const [selectOpen, setSelectOpen] = useState(false);
  const [rtnInstitution, setRtnInstitution] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.verygoodvault.com/vgs-collect/2.0/vgs-collect.js';
    script.async = true;
    script.onload = () => scriptLoaded(true);
    document.body.appendChild(script);
  });

  useEffect(() => {
    if (isLoaded) {
      const createVgsForm = window.VGSCollect.create(
        process.env.REACT_APP_VGS_VAULT,
        process.env.REACT_APP_VGS_ENV,
        (s) => {
          if (s) {
            // if (s.businessEin?.isDirty) form.validateFields(['businessEin']);
            // if (s.routingNumber?.isDirty) form.validateFields(['routingNumber']);
            if (s.bankAccountNumber?.isDirty) form.validateFields(['bankAccountNumber']);
          }
        }
      );
      setVgsForm(createVgsForm);

      // createVgsForm.field('#businessEin .fake-input', {
      //   type: 'text',
      //   name: 'businessEin',
      //   placeholder: '',
      //   validations: ['required'],
      //   css,
      // });
      // createVgsForm.field('#routingNumber .fake-input', {
      //   type: 'text',
      //   name: 'routingNumber',
      //   placeholder: '',
      //   validations: ['required', '/^[0-9]{9}$/'],
      //   css,
      // });
      createVgsForm.field('#bankAccountNumber .fake-input', {
        type: 'text',
        name: 'bankAccountNumber',
        placeholder: '',
        validations: ['required'],
        css,
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    setServicePlanId(upgradeDowngrade?.servicePlanId || props.boardingStatus?.servicePlan?.id);

    form.setFieldsValue({
      legalBusinessName:
        upgradeDowngrade.boardedApps.length &&
        upgradeDowngrade.status?.step4?.hasOwnProperty('data') &&
        upgradeDowngrade.status.step4.data.merchant.legal_name
          ? upgradeDowngrade.status.step4.data.merchant.legal_name
          : session.userData.cognito[`custom:business_name`],
      ownership:
        upgradeDowngrade.boardedApps.length &&
        upgradeDowngrade.status?.step4?.hasOwnProperty('data') &&
        upgradeDowngrade.status.step4.data.merchant.ownership_type
          ? upgradeDowngrade.status.step4.data.merchant.ownership_type
          : '',
      owner:
        upgradeDowngrade.boardedApps.length &&
        upgradeDowngrade.status?.step4?.hasOwnProperty('data') &&
        upgradeDowngrade.status.step4.data.owner.percent_ownership
          ? upgradeDowngrade.status.step4.data.owner.percent_ownership
          : 100,
      businessStartDate:
        upgradeDowngrade.boardedApps.length && upgradeDowngrade.status?.step4?.hasOwnProperty('data')
          ? moment(upgradeDowngrade.status.step4.data.merchant.business_start_date)
          : '',
      businessStreet:
        upgradeDowngrade.boardedApps.length &&
        upgradeDowngrade.status?.step4?.hasOwnProperty('data') &&
        upgradeDowngrade.status.step4.data.merchant.address.street
          ? upgradeDowngrade.status.step4.data.merchant.address.num
            ? `${upgradeDowngrade.status.step4.data.merchant.address.num} ${upgradeDowngrade.status.step4.data.merchant.address.street}`
            : upgradeDowngrade.status.step4.data.merchant.address.street
          : '',
      bZipcode:
        upgradeDowngrade.boardedApps.length &&
        upgradeDowngrade.status?.step4?.hasOwnProperty('data') &&
        upgradeDowngrade.status.step4.data.merchant.address.zip
          ? upgradeDowngrade.status.step4.data.merchant.address.zip
          : '',
      bCity:
        upgradeDowngrade.boardedApps.length &&
        upgradeDowngrade.status?.step4?.hasOwnProperty('data') &&
        upgradeDowngrade.status.step4.data.merchant.address.city
          ? upgradeDowngrade.status.step4.data.merchant.address.city
          : '',
      bState:
        upgradeDowngrade.boardedApps.length &&
        upgradeDowngrade.status?.step4?.hasOwnProperty('data') &&
        upgradeDowngrade.status.step4.data.merchant.address.state
          ? upgradeDowngrade.status.step4.data.merchant.address.state
          : '',
    });
  }, []);

  const displayAlert = (type, message, time) => {
    setMessage({
      type,
      message,
    });

    setTimeout(() => {
      setMessage({
        message: '',
      });
    }, time || 2000);
  };

  const onFinish = (values) => {
    setLoading(true);
    if (
      (props.boardingStatus.current === 'step3' && form.getFieldValue('ownership') === 'Sole Proprietorship') ||
      (props.boardingStatus.step3.status === 'waiting' &&
        form.getFieldValue('ownership') === 'Sole Proprietorship') ||
      (props.boardingStatus.current === 'step3' && upgradeDowngrade.servicePlanId)
    ) {
      displayAlert('warning', 'Finish account profile in previous step.', 4000);
      return setLoading(false);
    }

    let formData = values;

    if (userData.paymentGateway === 'Nuvei') formData.processorId = 1;
    if (userData.paymentGateway === 'Wepay') formData.processorId = 2;

    const extraVgsFields = {
      data: {
        routingNumber: formData.routingNumber,
      },
    };

    if (formData.ownership !== 'Sole Proprietorship') {
      const cleanBussinessEin = formData.businessEin.replace(/-/g, '').trim();
      extraVgsFields.data.businessEin = cleanBussinessEin;
    }

    vgsForm.submit(
      '/post',
      extraVgsFields,
      async (status, response) => {
        if (formData.ownership === 'Sole Proprietorship') {
          formData.businessEin = props.boardingStatus.step4.data.owner.ssn;
          formData.dbaName = formData.legalBusinessName;
          formData.legalBusinessName = `${props.boardingStatus.step4.data.owner.first_name} ${props.boardingStatus.step4.data.owner.last_name}`;
        } else {
          formData.businessEin = JSON.parse(response.data).businessEin;
        }

        formData.routingNumber = JSON.parse(response.data).routingNumber;
        formData.bankAccountNumber = JSON.parse(response.data).bankAccountNumber;

        let onBoardStatus = null;
        let calculatedStep = 0;
        try {
          const parsedAddress = parser.parseLocation(formData.businessStreet);
          if (!parsedAddress) throw 'Not a valid address';

          const streetNumber = parsedAddress.number;
          const streetName = formData.businessStreet.replace(`${parsedAddress.number} `, '');

          formData.bStreetNum = streetNumber;
          formData.bAddress = streetName;

          vgsValidator([
            [formData.businessEin, 'businessEin'],
            [formData.routingNumber, 'routingNumber'],
            [formData.bankAccountNumber, 'bankAccountNumber'],
          ]);
        } catch (error) {
          console.log(error);
          displayAlert('warning', 'There was an error with your request.');
          setLoading(false);
        }

        let method = 'PUT';
        let boardingData = '';
        let boardingStatus;

        try {
          boardingStatus = await getBoardingStatus(cognitoToken);
        } catch (error) {
          if (error.status === 400) {
            if (error.data.errors[0].detail === 'User has not been boarded yet') {
              method = 'POST';
            }
          } else {
            displayAlert(
              'warning',
              error.response?.data?.errors[0].detail || 'There was an error with you request.'
            );
            setLoading(false);
            return;
          }
        }

        try {
          formData.servicePlanId = upgradeDowngrade.servicePlanId || servicePlanId;

          if (method === 'POST') await createOnboarding(cognitoToken, formData);
          if (method === 'PUT') {
            // get boarding information
            boardingData = await getBoardingInformation(cognitoToken, boardingStatus.id);
            await updateOnBoarding(cognitoToken, boardingData, 4, formData, boardingStatus.id);

            onBoardStatus = await getOnboardingStatus(cognitoToken);
            calculatedStep = props.searchPending(onBoardStatus);

            if (
              (calculatedStep === 4 && session.userData.paymentGateway === 'Nuvei') ||
              (calculatedStep === 2 && session.userData.paymentGateway === 'Nuvei')
            ) {
              try {
                await submitAppForOnboarding(session.cognitoTokens.idToken.jwtToken, boardingStatus.id);
              } catch (error) {
                displayAlert('warning', 'Request failed, please try again.');
                setLoading(false);
                return;
              }
            }
          }
          displayAlert('success', 'Payment Account submitted.');

          setTimeout(async () => {
            // setLoading(false);
            // props.setCurrentStep(5);
            if (calculatedStep === -1 || calculatedStep === 2) {
              setLoading(false);
              props.setCurrentStep(5);
            } else {
              onBoardStatus = await getOnboardingStatus(cognitoToken);
              calculatedStep = props.searchPending(onBoardStatus);
              setLoading(false);
              if (calculatedStep === -1 || calculatedStep === 2) {
                props.setCurrentStep(5);
              } else {
                props.setCurrentStep(calculatedStep);
              }
            }
          }, 2000);
        } catch (error) {
          console.log(error);
          displayAlert(
            'warning',
            error.data?.errors[0].detail || JSON.stringify(error) || 'There was an error with you request.'
          );
          setLoading(false);
        }
      },
      (error) => {
        displayAlert('warning', 'There was an error with you request.');
        setLoading(false);
      }
    );
  };

  const handleAgreeTerms = (e) => {
    setAgreeTerms(e.target.checked);
  };

  const checkBusinessEin = (rule, value) => {
    if (value && form.getFieldValue('ownership') !== 'Sole Proprietorship') {
      if (!/^[1-9]\d?-\d{7}$/.test(value)) {
        return Promise.reject('Please input a valid Business EIN.');
      }
    }
    return Promise.resolve();
  };

  const checkRoutingNumber = async (rule, value) => {
    if (value) {
      if (value.length === 9 && !rtnInstitution) {
        try {
          const result = await getRoutingNumberStatus(cognitoToken, value);
          if (result.value) {
            setRtnInstitution(result.details.institution.name);
            return Promise.resolve();
          }
          setRtnInstitution('Error: No Bank Found');
          return Promise.resolve();
        } catch (error) {
          setRtnInstitution('Error: No Bank Found');
          return Promise.resolve();
        }
      } else {
        setRtnInstitution('');
      }
    }
    return Promise.resolve();
  };

  const checkBankAccountNumber = (rule, value) => {
    if (!vgsForm?.state?.bankAccountNumber?.isValid) {
      return Promise.reject('Please input your bank account number.');
    }
    return Promise.resolve();
  };

  const checkPercentage = (rule, value) => {
    if (value !== null) {
      return Promise.resolve();
    }
    return Promise.reject('Please input your ownership percentage.');
  };

  const checkAgreeTerms = (rule, value) => {
    if (value?.target?.checked) {
      return Promise.resolve();
    }
    return Promise.reject('You need to agree the terms and conditions.');
  };

  const poBoxValidation = (rule, value) => {
    if (value) {
      if (/\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/.test(value)) {
        return Promise.reject('Po boxes are not allowed.');
      }
    }
    return Promise.resolve();
  };

  const onFieldsChange = (_, allFields) => {
    if (_.length) {
      if (_[0].name.indexOf('ownership') !== -1) {
        if (_[0].value === 'Sole Proprietorship') {
          // check if step 3 was completed

          // if not show alert
          if (props.boardingStatus.current === 'step3') {
            return displayAlert('warning', 'Finish account profile in previous step.', 4000);
          }

          if (props.boardingStatus.current === 'step4') {
            setSelectOpen(false);
            form.setFieldsValue({
              businessEin: ' ',
              businessStreet: `${props.boardingStatus.step3.data.num} ${props.boardingStatus.step3.data.homeAddress}`,
              bZipcode: props.boardingStatus.step3.data.zipCode,
              bCity: props.boardingStatus.step3.data.city,
              bState: props.boardingStatus.step3.data.state,
            });
            // form.validateFields(['ownership']);
          }
        }
      }
    }
  };

  const handleClick = () => {
    setSelectOpen(!selectOpen);
  };

  return (
    <StyledMainDiv>
      <Form
        name="basic"
        form={form}
        initialValues={{ firstName: '', lastName: '', owner: 100 }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        onFieldsChange={onFieldsChange}
      >
        <LabelTooltipContainer>
          {form.getFieldValue('ownership') === 'Sole Proprietorship' ? 'DBA Business Name' : 'Legal Business Name'}
          <Tooltip placement="top" title="Use your name if no legal business entity" trigger="click">
            <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="propTooltipTest" />
          </Tooltip>
        </LabelTooltipContainer>
        <StyledItem>
          <Form.Item
            name="legalBusinessName"
            data-testid="legalBussinessTest"
            rules={[{ required: true, message: 'Please input your legal business name.', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </StyledItem>
        <Text size={13} color="grey2">
          Enter your name if sole proprietor
        </Text>
        <Space vertical={5} />
        <Row gutter={15}>
          <Col span={12}>
            Ownership
            <Form.Item
              name="ownership"
              data-testid="ownershipTest"
              rules={[{ required: true, message: 'Please select an option.', whitespace: true }]}
            >
              <Select placeholder="Select Ownership" open={selectOpen} onClick={handleClick}>
                <Option value="Sole Proprietorship">Sole Propietorship</Option>
                <Option value="LLC">LLC</Option>
                <Option value="Corporation">Corporation</Option>
                <Option value="Non-Profit">Non Profit</Option>
                <Option value="Government">Goverment Organization</Option>
                <Option value="Partnership">Partnership</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            Owner %
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership}
              noStyle
            >
              {() => {
                return (
                  <Form.Item
                    name="owner"
                    data-testid="ownerTest"
                    rules={[
                      {
                        validator: checkPercentage,
                      },
                    ]}
                  >
                    <InputNumber
                      // defaultValue={100}
                      min={1}
                      max={100}
                      // formatter={(value) => `${value}%`}
                      // parser={(value) => value.replace('%', '')}
                      // onChange={onChange}
                      disabled={form.getFieldValue('ownership') === 'Sole Proprietorship'}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <StyledCol span={12}>
            Business Start Date
            <StyledItem>
              <Form.Item
                rules={[{ required: true, message: 'Please select a date.' }]}
                name="businessStartDate"
                data-testid="startDateTest"
              >
                <DatePicker format="MM/DD/YYYY" autoComplete={`${Math.random()}`} />
              </Form.Item>
            </StyledItem>
            {form.getFieldValue('ownership') === 'Sole Proprietorship' && (
              <Text size={13} color="red" style={{ fontStyle: 'italic' }}>
                Enter first rental date
              </Text>
            )}
          </StyledCol>

          <Col span={12}>
            <LabelTooltipContainer>
              Business EIN {/**  VGS */}
              <Tooltip
                placement="topRight"
                title="Use social security number if no legal business entity"
                trigger="click"
              >
                <InfoCircleFilled style={{ marginLeft: '8px' }} />
              </Tooltip>
            </LabelTooltipContainer>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership}
              noStyle
            >
              {() => {
                return (
                  <Form.Item
                    name="businessEin"
                    rules={[
                      {
                        required: form.getFieldValue('ownership') !== 'Sole Proprietorship',
                        message: 'Please input your Business EIN',
                      },
                      {
                        validator: checkBusinessEin,
                      },
                    ]}
                  >
                    <MaskedInput
                      className="masked-input"
                      placeholder={
                        form.getFieldValue('ownership') === 'Sole Proprietorship' ? '**-*******' : '00-0000000'
                      }
                      mask={
                        form.getFieldValue('ownership') === 'Sole Proprietorship' ? '**-*******' : '00-0000000'
                      }
                      minLength={9}
                      placeholderChar=" "
                      disabled={form.getFieldValue('ownership') === 'Sole Proprietorship'}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div id="userSSN" ref={ssnInput} />
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={14}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership}
              noStyle
            >
              {() => {
                return (
                  <StyledItem>
                    Business Street Address
                    <Form.Item
                      name="businessStreet"
                      rules={[
                        {
                          validator: poBoxValidation,
                        },
                        {
                          required: form.getFieldValue('ownership') !== 'Sole Proprietorship',
                          message: 'Please input your address',
                          whitespace: true,
                        },
                        {
                          pattern: new RegExp(/(?!^\d+$)^.+$/),
                          message: 'Address must be numbers and letters',
                        },

                        {
                          max: 100,
                          message: 'Address cant be more than 100 characters',
                        },
                        {
                          validator: checkAddressValidator,
                        },
                      ]}
                    >
                      <Input disabled={form.getFieldValue('ownership') === 'Sole Proprietorship'} />
                    </Form.Item>
                    <Text size={13} color="red" style={{ fontStyle: 'italic' }}>
                      No P.O Boxes Allowed
                    </Text>
                  </StyledItem>
                );
              }}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership}
              noStyle
            >
              {() => {
                return (
                  <>
                    Zip Code
                    <Form.Item
                      name="bZipcode"
                      rules={[
                        {
                          validator: (rule, value) => boardingStep4CheckZipCode(rule, value, form),
                        },
                      ]}
                    >
                      <Input disabled={form.getFieldValue('ownership') === 'Sole Proprietorship'} />
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={14}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership}
              noStyle
            >
              {() => {
                return (
                  <>
                    City
                    <Form.Item
                      name="bCity"
                      rules={[
                        {
                          validator: (rule, value) => boardingStep4checkCity(rule, value, form),
                        },
                      ]}
                    >
                      <Input disabled={form.getFieldValue('ownership') === 'Sole Proprietorship'} />
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership}
              noStyle
            >
              {() => {
                return (
                  <>
                    State
                    <Form.Item
                      name="bState"
                      rules={[
                        {
                          required: form.getFieldValue('ownership') !== 'Sole Proprietorship',
                          message: 'Please select a state code.',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select State"
                        showSearch
                        disabled={form.getFieldValue('ownership') === 'Sole Proprietorship'}
                      >
                        {usStates.map((s, i) => (
                          <Option value={s.abbreviation} key={i}>
                            {s.abbreviation}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            ABA Routing Number
            <StyledItem>
              <Form.Item
                name="routingNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please input your routing number',
                    whitespace: true,
                  },
                  {
                    pattern: new RegExp(/^[0-9]{9}$/),
                    message: 'Please input a valid routing number',
                  },
                  {
                    validator: checkRoutingNumber,
                  },
                ]}
              >
                {/**
                 <div id="routingNumber" className="form-group" style={{ width: '99.9%' }} key="2">
                  <div className="form-control-static">
                    <span className="fake-input"></span>
                  </div>
                </div>
                 
                 */}

                <Input />
              </Form.Item>
            </StyledItem>
            <Text size={13} color="grey2">
              {rtnInstitution}
            </Text>
          </Col>
          <Col span={12}>
            Bank Account Number
            <StyledItem>
              <Form.Item
                name="bankAccountNumber"
                rules={[
                  {
                    validator: checkBankAccountNumber,
                  },
                ]}
              >
                <div id="bankAccountNumber" className="form-group" style={{ width: '99.9%' }} key="3">
                  <div className="form-control-static">
                    <span className="fake-input"></span>
                  </div>
                </div>
              </Form.Item>
            </StyledItem>
          </Col>
        </Row>
        <Form.Item
          name="agreeTerms"
          getValueProps={() => agreeTerms}
          rules={[
            {
              validator: checkAgreeTerms,
            },
          ]}
        >
          <Checkbox onClick={handleAgreeTerms}>
            <Text size={13} color="#595959">
              I agree to
            </Text>{' '}
            <ExternalLink color="violet" size={13} href="https://www.payrent.com/terms-conditions" target="_blank">
              terms, conditions and pricing
            </ExternalLink>{' '}
            <Text size={13} color="#595959">
              of this account
            </Text>
          </Checkbox>
        </Form.Item>
        <StyledAlertDiv>
          <AlertFade
            type={message.type}
            isVisible={!!message.message}
            message={message.message}
            alertPosition="absolute"
            position="relative"
            minHeight="40"
          />
        </StyledAlertDiv>
        <Space vertical={10} />
        <Form.Item>
          <StyledDiv style={{ textAlign: 'center' }}>
            <StyledText inline start onClick={() => props.setCurrentStep(3)} size={12} underline pointer>
              Previous
            </StyledText>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              color="violet"
              alignment="center"
              data-testid="buttonTest"
              style={{ margin: '0 auto' }}
            >
              APPLY
            </Button>
          </StyledDiv>
        </Form.Item>
      </Form>
    </StyledMainDiv>
  );
};

const StyledAlertDiv = styled.div`
  position: relative;
  height: 40px;
  margin-top: -15px;
`;

const StyledText = styled(Text)`
  position: absolute;
  padding-top: 22px;
`;

const StyledMainDiv = styled.div`
  @media screen and (max-width: 480px) {
    .ant-form {
      font-size: 13px !important;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const StyledItem = styled.div`
  .ant-form-item {
    margin-bottom: 3px !important;
  }
`;

const StyledCol = styled(Col)`
  .ant-row .ant-col .ant-picker {
    width: 100% !important;
  }
`;

const LabelTooltipContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: baseline;
`;

export default NuveiPaymentAccountForm;
