import React from 'react';

import AlertFade from 'components/AlertFade';

const Overlay = (props) => {
  const { silaOnboardingStatus, preventPayments } = props;

  const showSilaAlert = (state) => {
    console.log(state);
    switch (state) {
      case 'NOT_STARTED':
      case 'REGISTER_ERROR':
      case 'SUCCESS':
      case 'DOCUMENTS_REQUIRED':
      case 'DOCUMENTS_RECEIVED':
      case 'REVIEW':
        return true;
      default:
        return false;
    }
  };

  return (
    <div
      style={{
        padding: '14px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        zIndex: 10000,
        position: 'absolute',
        display: 'block',
        color: 'white',
        top: 65,
        height: '95%',
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
      }}
    >
      {preventPayments && (
        <AlertFade
          isVisible
          message="Payments Disabled"
          description="Your landlord has disabled payments on this platform. Please contact them for further details."
        />
      )}

      {showSilaAlert(silaOnboardingStatus.state) && (
        <AlertFade
          isVisible
          message="Verifying Your Identity"
          description="We are verifying your identity. Please check back in a few minutes to make your payment."
        />
      )}
      {silaOnboardingStatus.state === 'FAILED' && (
        <AlertFade
          isVisible
          message="Verification Failed"
          description="We were not able to verify your identity using the information provided. Please contact customer support."
          type="error"
        />
      )}
    </div>
  );
};

export default Overlay;
