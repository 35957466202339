import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Row, Col, Steps } from 'antd';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import * as tenantApi from 'services/api/tenantApi';

import { usStates } from 'resources/FormData';

import PanelFooter from 'components/Panel/PanelFooter';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import Space from 'components/Space';
import History from './History';
import Quote from './Quote';
import Checkout from './Checkout';
import Confirmation from './Confirmation';

const { Step } = Steps;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_QUADPAY_KEY);

const InsuranceSteps = (props) => {
  const {
    setInsuranceDrawer,
    setAlert,
    alert,
    showHistory,
    setShowHistory,
    showQuote,
    setShowQuote,
    showCheckout,
    setShowCheckout,
    showConfirmation,
    setShowConfirmation,
    getStep,
    fetchInsurancePolicy,
    setInsuranceStepsVisible,
    processingPayment,
    setProcessingPayment,
  } = props;
  const session = useSelector((store) => store.session);

  const [loading, setLoading] = useState(false);

  const [quoteResponse, setQuoteResponse] = useState(null);
  const [paymentCadence, setPaymentCadence] = useState('monthly');

  const [historyForm, setHistoryForm] = useState({
    losses: '0',
    lossDate: '',
    animalInjury: false,
    sni: false,
    sniFirstName: null,
    sniLastName: null,
  });

  // earthquake options are for california only and hurricane option is for florida only

  const [quoteForm, setQuoteForm] = useState({
    dwellingType: '',
    personalPropertyCoverage: 20000,
    allPerilDeductible: 100,
    liabilityLimit: 100000,
    allowReplacementCost: false,
    allowWaterBackup: false,
    allowEarthquake: false,
    allowPetDamage: false,
    allowIdentityFraud: true,
    medicalLimit: null,
  });

  let cancelToken = useRef(null);

  const quoteRequest = async () => {
    // Create a new CancelToken

    try {
      setLoading(true);
      const cognitoToken = session.cognitoTokens.idToken.jwtToken;
      const stateShort = usStates.filter((s) => s.name === session.userData.property.state.toUpperCase());
      const values = {
        email: session.userData.email,
        address: session.userData.property.address,
        unit: session.userData.property.address2,
        city: session.userData.property.city,
        state: stateShort[0].abbreviation,
        zip: session.userData.property.zip,
        tenantName: session.userData.firstName,
        tenantLastName: session.userData.lastName,
        tenantPhone: session.userData.phone,
        ...historyForm,
        ...quoteForm,
        paymentCadence,
      };

      if (cancelToken.current) {
        // Cancel the previous request before making a new request
        cancelToken.current.cancel();
      }
      cancelToken.current = axios.CancelToken.source();

      const res = await tenantApi.getSureQuote(cognitoToken, values, cancelToken.current);

      const monthly = res.premiumMonthly.toFixed(2);
      const annualy = res.premiumAnnual.toFixed(2);
      res.premiumMonthly = monthly;
      res.premiumAnnual = annualy;
      setQuoteResponse(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (typeof error === 'string') {
        if (error.includes('timeout')) {
          setLoading(false);
        }
      }
      if (typeof error === 'object') {
        setLoading(false);
        setShowHistory(false);
        setShowQuote(false);
        setShowConfirmation(false);
        setAlert({ ...alert, visible: true });
      }
    }
  };

  const getQuotation = async () => {
    try {
      const stateShort = usStates.filter((s) => s.name === session.userData.property.state.toUpperCase());
      const cognitoToken = session.cognitoTokens.idToken.jwtToken;
      const values = {
        email: session.userData.email,
        address: session.userData.property.address,
        unit: session.userData.property.address2,
        city: session.userData.property.city,
        state: stateShort,
        zip: session.userData.property.zip,
        tenantName: session.userData.firstName,
        tenantLastName: session.userData.lastName,
        tenantPhone: session.userData.phone,
        ...historyForm,
        ...quoteForm,
        paymentCadence,
      };

      if (cancelToken) {
        // Cancel the previous request before making a new request
        cancelToken.cancel();
      }
      cancelToken = axios.CancelToken.source();

      const res = await tenantApi.getSureQuote(cognitoToken, values, cancelToken);

      setQuoteResponse(res);
      setLoading(false);
      setShowQuote(false);
      setShowCheckout(true);
    } catch (error) {
      setLoading(false);
      setShowHistory(false);
      setShowQuote(false);
      setShowConfirmation(false);
      setAlert({ ...alert, visible: true });
    }
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <Row
          justify="center"
          align="vertical"
          gutter={[0, 15]}
          style={{ display: 'flex', height: 'calc(100% - 60px)' }}
        >
          <Col span={22} style={{ paddingBottom: '0px' }}>
            <Space vertical={15} />
            <Steps
              direction="vertical"
              current={getStep()}
              style={{ height: '172px' }}
              status={alert.visible ? 'error' : 'process'}
            >
              <Step title="Insurance History" />
              <Step title="View Quote" />
              <Step title="Purchase" />
            </Steps>
            {alert.visible && (
              <AlertFade
                message={alert.message}
                isVisible={alert.visible}
                description={alert.description}
                type={alert.type}
                width={100}
                position="relative"
                alertPosition="absolute"
              />
            )}
          </Col>

          {showHistory && (
            <History
              setShowHistory={setShowHistory}
              setShowQuote={setShowQuote}
              setHistoryForm={setHistoryForm}
              historyForm={historyForm}
              setAlert={setAlert}
              setShowConfirmation={setShowConfirmation}
              alert={alert}
            />
          )}
          {showQuote && (
            <Quote
              setShowQuote={setShowQuote}
              setShowCheckout={setShowCheckout}
              setQuoteForm={setQuoteForm}
              quoteForm={quoteForm}
              getQuotation={getQuotation}
              quoteResponse={quoteResponse}
              loading={loading}
              setLoading={setLoading}
              setQuoteResponse={setQuoteResponse}
              historyForm={historyForm}
              quoteRequest={quoteRequest}
              paymentCadence={paymentCadence}
              setPaymentCadence={setPaymentCadence}
            />
          )}
          {showCheckout && (
            <Checkout
              setShowCheckout={setShowCheckout}
              setShowConfirmation={setShowConfirmation}
              quoteResponse={quoteResponse}
              quoteForm={quoteForm}
              historyForm={historyForm}
              setShowQuote={setShowQuote}
              fetchInsurancePolicy={fetchInsurancePolicy}
              paymentCadence={paymentCadence}
              setInsuranceDrawer={setInsuranceDrawer}
              setInsuranceStepsVisible={setInsuranceStepsVisible}
              processingPayment={processingPayment}
              setProcessingPayment={setProcessingPayment}
            />
          )}
          {showConfirmation && (
            <Confirmation
              quoteForm={quoteForm}
              historyForm={historyForm}
              quoteResponse={quoteResponse}
              setShowConfirmation={setShowConfirmation}
              setInsuranceDrawer={setInsuranceDrawer}
              setInsuranceStepsVisible={setInsuranceStepsVisible}
              paymentCadence={paymentCadence}
            />
          )}
          {alert.visible && (
            <PanelFooter>
              <Col span={24}>
                <Space vertical={15} />
                <Button
                  color="violet"
                  alignment="center"
                  onClick={() => {
                    setInsuranceDrawer(false);
                    setShowHistory(true);
                    setShowQuote(false);
                    setAlert({ ...alert, visible: false });
                  }}
                  bottom={0}
                >
                  CLOSE
                </Button>
              </Col>
            </PanelFooter>
          )}
        </Row>
      </Elements>
    </>
  );
};

export default InsuranceSteps;
