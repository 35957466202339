import React from 'react';
import Lottie from 'lottie-react';
import lottieData from './6jxA1hqa74.json';

export default function FloatLoader() {
  return (
    <Lottie
      loop={true}
      animationData={lottieData}
      style={{
        height: '220px',
        width: 'auto',
        display: 'block',
        margin: '0 auto',
      }}
    />
  );
}
