import { CopyOutlined, EyeFilled, EyeInvisibleOutlined, FileOutlined, LinkOutlined } from '@ant-design/icons';
import { theme, Space as AntSpace } from 'antd';
import React from 'react';
import { camelToTitleCase } from 'resources/helpers';
import Text from 'components/Text/Text';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import IconBadge from 'components/uielements/IconBadge/IconBadge';
import FileTypeIcon from './DocumentTypeIcon';

dayjs.extend(relativeTime);

function DocumentDescription({ record }) {
  const { token } = theme.useToken();

  const associationList = (associations) => {
    let count = 0;
    let list = [];
    let summary = [];
    for (const key in associations) {
      associations[key].length > 0 && count++;
      summary.push(`${associations[key].length} ${camelToTitleCase(key)}`);
    }
    return { count, list: list.join(', '), summary: summary.join(', ') };
  };

  return (
    <AntSpace>
      <FileTypeIcon fileType={record.mimeType} />
      <Flex vertical wrap="wrap" gap={6}>
        <div style={{ textOverflow: 'ellipsis', display: 'inline-block' }}>
          <Text color="black" strong>
            {record.label}
          </Text>{' '}
          <Text color="black">({record.fileName})</Text>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Text style={{ marginRight: '12px' }} color="greyText">
            Uploaded {dayjs(record.createdAt).fromNow()}
          </Text>
          <div style={{ display: 'inline-flex' }}>
            {record.isPublic ? (
              <IconBadge title="Public" icon={EyeFilled} color={token.colorPrimary} />
            ) : (
              <IconBadge title="Private" icon={EyeInvisibleOutlined} color={token.colorTextDescription} />
            )}

            {record.isTemplate ? (
              <IconBadge title="Template" icon={CopyOutlined} rotate={180} color={token.colorTextDescription} />
            ) : (
              <IconBadge title="Document" icon={FileOutlined} color={token.colorTextDescription} />
            )}
            <IconBadge
              icon={LinkOutlined}
              title={`Linked to: ${associationList(record.associations).summary}`}
              color={
                associationList(record.associations).count > 0 ? token.colorPrimary : token.colorTextDescription
              }
            />
          </div>
        </div>
      </Flex>
    </AntSpace>
  );
}

export default DocumentDescription;
