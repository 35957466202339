import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { ReactComponent as SureLogoSimple } from 'assets/images/sure-logo-simple.svg';

import Text from 'components/Text';
import Space from 'components/Space';
import Button from 'components/Button';
import PanelFooter from 'components/Panel/PanelFooter';

const Confirmation = (props) => {
  const {
    quoteResponse,
    setInsuranceDrawer,
    setShowConfirmation,
    setInsuranceStepsVisible,
    paymentCadence,
  } = props;

  return (
    <PanelContainer>
      <StyledContainer className="content-container">
        <Row justify="center">
          <Col span={20}>
            <Text color="black" size={20} centered strong>
              You’re now covered with a renter’s insurance plan from
            </Text>
          </Col>
          <Col span={20} style={{ textAlign: 'center' }}>
            <SureLogoSimple />
          </Col>
          <Col span={20}>
            <PolicyContainer>
              <Text size={12} strong color="black" centered>
                Policy Summary
              </Text>
              <Text color="black" size={14} strong>
                Your Custom Plan Summary:
              </Text>
              <Space vertical={15} />
              <Text color="black" size="12" strong>
                Property Coverage:
              </Text>
              <Text color="black" size="12">
                {' '}
                {quoteResponse?.settings?.personalPropertyCoverage}
              </Text>
              <br />
              <Text color="black" size="12" strong>
                Deductible:
              </Text>
              <Text color="black" size="12">
                {' '}
                {quoteResponse?.settings?.allPerilDeductible}
              </Text>
              <br />
              <Text color="black" size="12" strong>
                Liability Amount:
              </Text>
              <Text color="black" size="12">
                {' '}
                {quoteResponse?.settings?.liabilityLimit}
              </Text>
              <br />
              <Text color="black" size="12" strong>
                Additional Coverage:
              </Text>
              <br />
              {quoteResponse?.settings?.includeIdentityFraud && (
                <>
                  <Text color="black" size="12" margin="0px 0px 0px 10px">
                    {' '}
                    Identify Theft
                  </Text>
                  <br />
                </>
              )}
              {quoteResponse?.settings?.includeReplacementCost && (
                <>
                  <Text color="black" size="12" margin="0px 0px 0px 10px">
                    {' '}
                    Replacement Cost
                  </Text>
                  <br />
                </>
              )}
              {quoteResponse?.settings?.includeWaterBackup && (
                <>
                  <Text color="black" size="12" margin="0px 0px 0px 10px">
                    {' '}
                    Sewer or Drain Backup
                  </Text>
                  <br />
                </>
              )}
              {quoteResponse?.settings?.includeEarthquake && (
                <>
                  <Text color="black" size="12" margin="0px 0px 0px 10px">
                    {' '}
                    Earthquake coverage
                  </Text>
                  <br />
                </>
              )}
              <Text color="black" size="12" strong>
                Start Date:
              </Text>
              <Text color="black" size="12">
                {' '}
                {moment().format('ll')}
              </Text>
              <br />
              <Text color="black" size="12" strong>
                Billing Cycle:
              </Text>
              <Text color="black" size="12">
                {' '}
                {paymentCadence === 'monthly' ? 'Monthly (11 cycles)' : 'Annual'}
              </Text>
              <Space vertical={15} />
              <Text color="black" size={12} centered>
                You’ll receive an email from Sure with your policy and a link to download their app. If you have
                any changes to make to your policy, contact Sure directly.
              </Text>
            </PolicyContainer>
          </Col>
        </Row>
      </StyledContainer>
      <PanelFooter>
        <Col span={24}>
          <Button
            color="violet"
            alignment="center"
            onClick={() => {
              setShowConfirmation(false);
              setInsuranceDrawer(false);
              setInsuranceStepsVisible(false);
            }}
            data-testid="doneButtonTest"
            bottom={0}
          >
            DONE
          </Button>
        </Col>
      </PanelFooter>
    </PanelContainer>
  );
};

const PanelContainer = styled.div`
  display: flex;
  height: calc(100% - 200px);
  flex-direction: column;
`;
const StyledContainer = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const PolicyContainer = styled.div`
  background-color: #f7f7f0;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px 10px;
`;

export default Confirmation;
