import React, { useState } from 'react';
import styled from 'styled-components';

import { MenuOutlined } from '@ant-design/icons';

import Logo from 'assets/images/logo.png';

const NavBar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMenuIconClick = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <StyledNav data-testid="landing-navbar">
      <div className="mobile-bar">
        <MenuOutlined
          style={{ fontSize: '30px', color: 'white' }}
          className="burger-icon"
          onClick={handleMenuIconClick}
          data-testid="menuIconTest"
        />
      </div>

      <StyledUl>
        <li className="landing-logo">
          <img src={Logo} alt="Payrent logo" />
        </li>

        <li className="nav-second-li nav-li" onClick={() => window.location.assign('https://payrent.com')}>
          Home
        </li>
        <li className="nav-li" onClick={() => window.location.assign('https://payrent.com')}>
          Landlords
        </li>
        <li className="nav-li" onClick={() => window.location.assign('https://payrent.com')}>
          Renter Sign-up
        </li>
        <li className="active nav-li">Pricing</li>
        <li className="menu-buttons">
          <a href="#servicePlans" className="menu-signup">
            Landlord Sign-up
          </a>
        </li>
        <li className="menu-buttons-2">
          <a href="/" className="menu-login" data-testid="desktopLoginTest">
            Login
          </a>
        </li>
      </StyledUl>
      {mobileMenu && (
        <StyledMobileUl data-testid="mobileNavTest">
          <li onClick={() => window.location.assign('https://payrent.com')} data-testid="landlordLinkTest">
            Landlords
          </li>
          <li onClick={() => window.location.assign('https://payrent.com')} data-testid="signupTest">
            Renter Sign-up
          </li>
          <li>Pricing</li>
          <li onClick={() => window.location.assign('https://payrent.com')} data-testid="landlordSignupTest">
            Landlord Sign-up
          </li>
          <li>
            <a href="/" className="menu-login">
              Login
            </a>
          </li>
        </StyledMobileUl>
      )}
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  background-color: #541388;
`;

const StyledMobileUl = styled.ul`
  list-style-type: none;
  padding-inline-start: 0px;
  li,
  a {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    white-space: nowrap;
    color: white;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      transition: all 0.4s ease-in-out;
    }
  }
`;

const StyledUl = styled.ul`
  list-style-type: none;
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: space-between;
  padding-inline-end: 10px;
  padding-inline-start: 10px;
  li a.menu-signup {
    border: 2px solid white;
    margin-left: 60px;
    border-radius: 3px;
    padding: 5px 20px 5px 20px !important;
    background-color: #ffffff;
    color: #8300e9 !important;
    font-weight: 700;
    text-decoration: none !important;
  }
  li a.menu-login {
    border: 2px solid white;
    margin-left: 3%;
    border-radius: 3px;
    padding: 5px 20px 5px 20px !important;
    color: white;
  }
  li {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    padding: 15px 5px;
    white-space: nowrap;
    color: white;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      transition: all 0.4s ease-in-out;
    }
  }
  li.active {
    text-decoration-line: none;
    margin-right: 18%;
  }
  .nav-second-li {
    margin-left: 2%;
  }
  .nav-li {
    padding-right: 2%;
  }
  .mobile-menu-icon {
    display: none;
  }
  @media screen and (max-width: 1392px) {
    li.active {
      margin-right: 10%;
    }
  }
  @media screen and (max-width: 1200px) {
    li.active {
      margin-right: 1%;
    }
    .nav-li {
      padding-right: 1%;
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    display: none;
    .mobile-menu-icon {
      display: unset;
    }
  }
`;
export default NavBar;
