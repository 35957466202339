import React from 'react';
import styled from 'styled-components';

const values = {
  firstText: '',
  color: '',
  secondText: '',
  thirdText: '',
};

const getValues = (status, id) => {
  switch (status) {
    case 'active':
      values.firstText = `Account ${id} is `;
      values.secondText = ' active';
      values.thirdText = ' - You can make payments';
      values.color = 'color-green';
      break;
    case 'property-link-missing':
      values.firstText = '';
      values.secondText = 'Property link missing';
      values.thirdText = ' - Please contact your landlord';
      values.color = 'color-red';
      break;
    case 'disabled':
      values.firstText = '';
      values.secondText = 'Your account is disabled';
      values.thirdText = ' - You cannot make payments';
      values.color = 'color-red';
      break;
    case 'error':
      values.firstText = '';
      values.secondText = 'Your account is disabled';
      values.thirdText = ' - You cannot make payments';
      values.color = 'color-red';
      break;
    default:
      break;
  }
};

const AccountStatus = (props) => {
  getValues(props.status, props.userId);

  return (
    <StyledDiv>
      {values.firstText}
      <span className={values.color} style={{ margin: '0px 5px' }}>
        <strong data-testid="userStatus">{values.secondText}</strong>
      </span>
      {values.thirdText}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
`;

export default AccountStatus;
