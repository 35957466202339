import { usStates } from 'resources/FormData';
import { requiredFields as businessProfileRequiredFields } from './BusinessProfileForm';
import { requiredFields as personProfileRequiredFields } from './PersonProfileForm';
import { requiredFields as bankAccountRequiredFields } from '../../components/FinixPaymentMethodForm/FinixPaymentMethodForm';

export const checkFormReady = (name, allValues, errors) => {
  // console.log('ƒ checkFormReady', name, allValues, errors);
  const hasErrors = errors.map((err) => err.errors.length > 0).includes(true);

  const isIncomplete = Object.keys(allValues)
    .map((key) => {
      let requiredFields;
      switch (name) {
        case 'business_profile_form':
          requiredFields = businessProfileRequiredFields;
          break;
        case 'personal_profile_form':
          requiredFields = personProfileRequiredFields;
          break;
        case 'merchant_bank_account_form':
          requiredFields = bankAccountRequiredFields;
          break;
        default:
          break;
      }

      // Check all keys in array of associated owners
      if (key === 'associated_owners' && allValues[key]?.length > 0) {
        const associatedOwnerIsIncomplete = allValues[key].map((o, i) => {
          return Object.keys(o)
            .map((k) => {
              return (o[k] === undefined || o[k] === '' || o[k] === false) && requiredFields?.includes(k);
            })
            .includes(true);
        });
        // console.log('associated_owners incomplete', associatedOwnerIsIncomplete);
        return associatedOwnerIsIncomplete.includes(true);
      } else {
        return (
          (allValues[key] === undefined || allValues[key] === '' || allValues[key] === false) &&
          requiredFields?.includes(key)
        );
      }
    })
    .includes(true);
  // console.log(`ƒ checkFormReady ${name} errors: ` + hasErrors, 'incomplete: ' + isIncomplete);
  return hasErrors || isIncomplete ? false : true;
};

export const stateOptions = usStates.map((state) => ({
  label: state.name,
  value: state.abbreviation,
}));

export const fetchUserIpAddress = async () => {
  try {
    var response = await fetch('https://api.ipify.org/?format=json');
    const ip = response.json().ip;

    return ip;
  } catch (error) {
    console.error(error);
    return '0.0.0.0';
  }
};

export function getGeolocation(options) {
  return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));
}
