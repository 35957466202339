const environment = process.env.REACT_APP_ENV === 'production' ? 'live' : 'sandbox';

export default async function createFinixFraudSessionId(mid) {
  try {
    if (!window.Finix) throw new Error('Finix is not loaded');
    if (!mid) throw new Error('No merchant id provided');
    return window.Finix.Auth(environment, mid, (sessionKey) => {
      // console.log('ƒ createFinixFraudSessionId', sessionKey);
      return sessionKey;
    });
  } catch (e) {
    throw new Error(e.message);
  }
}
