import React from 'react';
import { Tag, Typography } from 'antd';
import {
  RocketFilled,
  FormatPainterFilled,
  SoundFilled,
  FireFilled
} from '@ant-design/icons';
const { Text } = Typography;

const NewsTag = (props) => {
  let { tag } = props;
  tag = tag || 'General';
  let tagProps = {};

  switch (tag) {
    case 'New Feature':
      tagProps = {
        tagColor: '#541388',
        iconColor: '#fff',
        textColor: '#fff',
        styledIcon: <RocketFilled style={{ color: '#fff' }} />
      };
      break;
    case 'Improvement':
      tagProps = {
        tagColor: '#cb47b8',
        iconColor: '#fff',
        textColor: '#fff',
        styledIcon: <FormatPainterFilled style={{ color: '#fff' }} />
      };
      break;
    case 'Announcement':
      tagProps = {
        tagColor: '#ffcc00',
        iconColor: '#122c34',
        textColor: '#122c34',
        styledIcon: <SoundFilled style={{ color: '#122c34' }} />
      };
      break;
    default:
      tagProps = {
        tagColor: '#122c34',
        iconColor: '#fff',
        textColor: '#fff',
        styledIcon: <FireFilled style={{ color: '#fff' }} />
      };
  }
  return (
    <Tag color={tagProps.tagColor} icon={tagProps.styledIcon}>
      <Text style={{ color: tagProps.textColor }}>{tag}</Text>
    </Tag>
  );
};
export default NewsTag;
