import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Card as AntCard, Divider } from 'antd';
import BuyUploadPolicy from './InsuranceMenu/BuyUploadPolicy';
import ViewPolicy from './InsuranceMenu/ViewPolicy';

const InsuranceCard = (props) => {
  // console.log('[InsuranceCard.js] props:', props);
  const {
    setInsuranceDrawer,
    setGetUploadVisible,
    setShowPolicyDetails,
    userPolicy,
    setShowUploadedPolicyDetails,
  } = props;

  const uploadedInsuranceFile = useSelector((store) => store.tenant.uploadedInsuranceFile);
  const disableInsurance = useSelector((store) => store.tenant.disableInsurance);

  // console.log('[InsuranceCard.js] uploadedInsuranceFile:', uploadedInsuranceFile);
  return (
    <AntCard
      title="Renters Insurance"
      styles={{
        header: {
          fontSize: '16px',
          color: '#541388',
          fontWeight: 600,
        },
      }}
      data-testid="cardTest"
    >
      <ItemContainer>
        Insurance Policy
        <div>
          <BuyUploadPolicy
            disabled={userPolicy || disableInsurance}
            setInsuranceDrawer={setInsuranceDrawer}
            setGetUploadVisible={setGetUploadVisible}
          />
          <Divider type="vertical" />
          <ViewPolicy
            setInsuranceDrawer={setInsuranceDrawer}
            setShowUploadedPolicyDetails={setShowUploadedPolicyDetails}
            setShowPolicyDetails={setShowPolicyDetails}
            uploadedInsuranceFile={uploadedInsuranceFile}
            disabled={!userPolicy || disableInsurance}
          />
        </div>
      </ItemContainer>
    </AntCard>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 12px;
`;

export default InsuranceCard;
