// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Space as AntSpace } from 'antd';
//import './style.scss';

const Space = (props) => (
  <div
    className={`space--${props.vertical}`}
    style={{ marginTop: props.vertical || 12, marginBottom: props.vertical || 12 }}
    data-testid="spaceTest"
  />
);

export default Space;
