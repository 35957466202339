import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';
import { Form, Input, Row, Col, Select, DatePicker, Tooltip, Spin, Checkbox } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import parser from 'parse-address';
import MaskedInput from 'antd-mask-input';
import { InfoCircleFilled, BankFilled } from '@ant-design/icons';
import { checkZipCode, checkCity, checkAddressValidator } from 'resources/FormValidators';
import { createOnboarding } from 'services/api/landlordApi/create';
import { getUserDataV3 } from 'store/actions/sessionActions';
import {
  getBoardingInformation,
  getBoardingStatus,
  getSessionDetails,
  getRentersV3,
} from 'services/api/landlordApi/read';
import { updateOnBoarding } from 'services/api/landlordApi/update';

import PanelFooter from 'components/Panel/PanelFooter';

import Button from 'components/Button';
import Space from 'components/Space';
import SpaceResponsive from 'components/SpaceResponsive';
import AlertFade from 'components/AlertFade';
import Text from 'components/Text';
import TermsConditions from '../../../components/TermsConditions/TermsConditions';

import { usStates } from '../../../../../resources/FormData';

const { Option } = Select;

const SilaOnboarding = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { globalLoading, setDrawerVisible } = props;

  const session = useSelector((state) => state.session);
  const cognitoToken = useSelector((state) => state.session.cognitoTokens.idToken.jwtToken);
  const upgradeDowngrade = useSelector((store) => store.landlord.upgradeDowngrade);

  const { userData } = session;

  const [loading, setLoading] = useState(false);
  const [vgsForm, setVgsForm] = useState({});
  const [isLoaded, scriptLoaded] = useState(false);
  const [message, setMessage] = useState({ type: 'success', message: '' });
  const [servicePlanId, setServicePlanId] = useState('');
  const [plaidToken, setPlaidToken] = useState(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [disableButton1, setDisableButton1] = useState(true);
  const [disableButton2, setDisableButton2] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(false);

  const [termsConditions, setTermsConditions] = useState(false);
  const [validatedForm, setValidatedForm] = useState(false);
  const fieldList = [
    'businessType',
    'businessRole',
    'firstName',
    'lastName',
    'homeAddress',
    'zipCode',
    'city',
    'state',
    'email',
    'mobilePhone',
    'ssn',
    'ssnItinConfirm',
    'dateOfBirth',
    'businessLegalName',
    // 'dba',
    'businessAddress',
    'businessZipCode',
    'businessCity',
    'businessState',
    'businessEmail',
    'businessPhone',
    'businessStartDate',
    'businessEin',
  ];

  const onSuccess = async (token, metadata) => {
    try {
      console.log(token);
      console.log(metadata);
      // await achMicrodepositVerification(session.cognitoTokens.idToken.jwtToken, item.id);
      // dispatch(getPaymentMethods(session.userData.paymentGateway));
      // displayAlert('Payment Method Verified', 'success', '');
    } catch (error) {
      // displayAlert('Payment Method Error', 'warning', 'Something went wrong while verifying your payment method');
    }
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
  };
  const { open, ready, error } = usePlaidLink(config);

  const getPlaidToken = async () => {
    try {
      // get token ??
      // if (
      //   item.paymentMethodType === 'ACH' &&
      //   item.state === 'SCHEDULED' &&
      //   item?.paymentGatewayMeta?.state === 'pending verification'
      // ) {
      //   const tokenRes = await getPlaidLinkToken(session.cognitoTokens.idToken.jwtToken, item.id);
      //   setPlaidToken(tokenRes?.linkToken);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkStep();
  }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://js.verygoodvault.com/vgs-collect/2.0/vgs-collect.js';
  //   script.async = true;
  //   script.onload = () => scriptLoaded(true);
  //   document.body.appendChild(script);
  // });

  useEffect(() => {
    if (!globalLoading) {
      const createVgsForm = window.VGSCollect.create(
        process.env.REACT_APP_VGS_VAULT,
        process.env.REACT_APP_VGS_ENV,
        (s) => {
          if (s) {
            // if (s.businessEin?.isDirty) form.validateFields(['businessEin']);
            // if (s.routingNumber?.isDirty) form.validateFields(['routingNumber']);
            // if (s.bankAccountNumber?.isDirty) form.validateFields(['bankAccountNumber']);
          }
        }
      );

      // const ssn_number = {
      //   type: 'text',
      //   name: 'emptyssn',
      //   // placeholder: 'AAA-GG-SSSS',
      //   fontFamily: 'Montserrat',
      //   css,
      // };
      // const bankAccount = {
      //   type: 'text',
      //   name: 'bankAccount',
      //   // placeholder: 'AAA-GG-SSSS',
      //   fontFamily: 'Montserrat',
      //   css,
      // };
      //
      // createVgsForm.field('#emptyssn .fake-input', ssn_number);
      // createVgsForm.field('#emptyBankAccount .fake-input', bankAccount);
      // setVgsForm(createVgsForm);
      scriptLoaded(true);
    }
  }, [globalLoading]);

  useEffect(() => {
    setServicePlanId(props.boardingStatus?.servicePlan?.id);
    if (props.boardingStatus?.step3?.status === 'finished') {
      form.setFieldsValue({
        firstName: props.boardingStatus?.step3?.data?.firstName,
        lastName: props.boardingStatus?.step3?.data?.lastName,
        mobilePhone: props.boardingStatus?.step3?.data?.phone
          ? props.boardingStatus?.step3?.data?.phone.replace('+1', '')
          : '',
        homeAddress: props.boardingStatus?.step3?.data?.num
          ? `${props.boardingStatus?.step3?.data?.num} ${props.boardingStatus?.step3?.data?.homeAddress}`
          : props.boardingStatus?.step3?.data?.homeAddress,
        zipCode: props.boardingStatus?.step3?.data?.zipCode,
        city: props.boardingStatus?.step3?.data?.city,
        state: props.boardingStatus?.step3?.data?.state,
        dateOfBirth: props.boardingStatus?.step3?.data?.dateOfBirth
          ? moment(props.boardingStatus?.step3?.data?.dateOfBirth)
          : '',
      });
      setUpdatedProfile(true);
    } else {
      form.setFieldsValue({
        firstName: session.userData?.cognito?.given_name ? session.userData?.cognito?.given_name : '',
        lastName: session.userData?.cognito?.family_name ? session.userData?.cognito?.family_name : '',
        mobilePhone: session.userData?.cognito?.phone_number
          ? session.userData?.cognito?.phone_number.replace('+1', '')
          : '',
      });
    }
    if (props.boardingStatus?.step3?.status !== 'finished') {
      fetchBoardingInformation();
    }
  }, [props.boardingStatus]);

  const fetchBoardingInformation = async () => {
    try {
      // get the boarding id
      const boardingStatusRes = await getBoardingStatus(cognitoToken);
      // get the boarding info
      if (boardingStatusRes?.id) {
        setUpdatedProfile(true);
        const boardingInfoRes = await getBoardingInformation(cognitoToken, boardingStatusRes.id);
        form.setFieldsValue({
          firstName: boardingInfoRes.owner.firstName,
          lastName: boardingInfoRes.owner.lastName,
          mobilePhone: boardingInfoRes.owner.phone ? boardingInfoRes.owner.phone.replace('+1', '') : '',
          homeAddress: boardingInfoRes.owner.address.num
            ? `${boardingInfoRes.owner.address.num} ${boardingInfoRes.owner.address.street}`
            : boardingInfoRes.owner.address.street,
          zipCode: boardingInfoRes.owner.address.zip,
          city: boardingInfoRes.owner.address.city,
          state: boardingInfoRes.owner.address.state,
          dateOfBirth: boardingInfoRes.owner.dateOfBirth ? moment(boardingInfoRes.owner.dateOfBirth) : '',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const displayAlert = (text, type) => {
    setMessage({
      message: text,
      type,
    });

    setTimeout(() => {
      setMessage({
        message: '',
      });
    }, 2500);
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = values;
    const cleanSsn = formData.ssn.replace(/-/g, '').trim();

    const normalizePhone = formData.mobilePhone.replace(/\s/g, '');
    formData.mobilePhone = normalizePhone;

    if (userData.paymentGateway === 'Nuvei') formData.processorId = 1;
    if (userData.paymentGateway === 'Wepay') formData.processorId = 2;

    vgsForm.submit(
      '/post',
      {
        data: {
          ssn: cleanSsn,
          bankAccountNumber: formData.bankAccountNumber,
        },
      },
      async (status, response) => {
        props.setUserSsn(formData.ssn);
        formData.ssn = JSON.parse(response.data).ssn;
        formData.bankAccountNumber = JSON.parse(response.data).bankAccountNumber;
        setLoading(true);
        let method = 'PUT';
        let boardingData = '';
        let boardingStatus;
        try {
          boardingStatus = await getBoardingStatus(cognitoToken);
        } catch (error) {
          if (error.status === 400) {
            if (error.data.errors[0].detail === 'User has not been boarded yet') {
              method = 'POST';
            }
          } else {
            displayAlert(
              error.response?.data?.errors[0].detail || 'There was an error with you request.',
              'warning'
            );
            setLoading(false);
            return;
          }
        }
        try {
          let onBoardStatus = null;
          let calculatedStep = 0;

          const parsedAddress = parser.parseLocation(formData.homeAddress);
          if (!parsedAddress) throw 'Not a valid address';

          const streetNumber = parsedAddress.number;
          const streetName = formData.homeAddress.replace(`${parsedAddress.number} `, '');

          formData.streetNumber = streetNumber;
          formData.streetName = streetName;
          formData.servicePlanId = upgradeDowngrade.servicePlanId || servicePlanId;

          if (upgradeDowngrade.servicePlanId) {
            delete formData.servicePlanId;
            const statusObject = {
              ...upgradeDowngrade.status,
              current: 'step4',
              step3: {
                ...upgradeDowngrade.status.step3,
                status: 'finished',
                data: {
                  ...upgradeDowngrade.status.step3.data,
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  dateOfBirth: formData.dateOfBirth.format('YYYY-MM-DD'),
                  num: streetNumber,
                  street: streetName,
                  city: formData.city,
                  state: formData.state,
                  zip: formData.zipCode,
                  phone: formData.mobilePhone,
                  ssn: formData.ssn,
                },
              },
              step4: {
                ...upgradeDowngrade.status.step4,
                data: {
                  ...upgradeDowngrade.status.step4?.data,
                  merchant: {
                    ...upgradeDowngrade.status.step4.data?.merchant,
                  },
                  owner: {
                    ...upgradeDowngrade.status.step4?.data?.owner,
                    full_name: `${formData.firstName} ${formData.lastName}`,
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    date_of_birth: formData.dateOfBirth.format('YYYY-MM-DD'),
                    address: {
                      num: streetNumber,
                      street: streetName,
                      city: formData.city,
                      state: formData.state,
                      zip: formData.zipCode,
                    },
                    phone: formData.mobilePhone,
                    ssn: formData.ssn,
                  },
                },
              },
            };

            dispatch({
              type: 'UPDATE_UPGRADE_DOWNGRADE',
              payload: {
                servicePlanId: upgradeDowngrade.servicePlanId,
                status: {
                  ...statusObject,
                },
              },
            });
            props.setBoardingStatus(upgradeDowngrade.status);
            displayAlert('Profile updated.', 'success');

            setTimeout(async () => {
              props.setCurrentStep(4);
            }, 2500);
            return;
          }

          if (method === 'POST') await createOnboarding(cognitoToken, formData);
          if (method === 'PUT') {
            // get boarding information
            try {
              boardingData = await getBoardingInformation(cognitoToken, boardingStatus.id);

              await updateOnBoarding(cognitoToken, boardingData, 3, formData, boardingStatus.id);
              // If gateway is nuvei check OnboardingStatus and redirect

              // if (calculatedStep === -1 && session.userData.paymentGateway === 'Nuvei') {
              //   await submitAppForOnboarding(session.cognitoTokens.idToken.jwtToken, boardingStatus.id);
              // }
            } catch (error) {
              displayAlert(error.data?.errors[0].detail || 'There was an error with you request.', 'warning');
              setLoading(false);
              return;
            }
          }

          const renters = await getRentersV3(cognitoToken, 1, 10, '', '');

          displayAlert('Profile updated.', 'success');

          props.setBoardingStatus(onBoardStatus);
          if (disableButton1) {
            setDisableButton1(false);
          }
          setUpdatedProfile(true);
          dispatch(getUserDataV3(cognitoToken));
          /*
          setTimeout(async () => {
            if (onBoardStatus.step3.status === 'finished' && onBoardStatus.step4.status === 'approved') {
              const plansBillingRes = await getBillingDetails(
                session.cognitoTokens.idToken.jwtToken,
                session.userData.id
              );
              setLoading(false);
              if (!plansBillingRes?.paymentMethod?.paymentMethod) {
                dispatch({
                  type: 'SET_CURRENT_ONBOARDING_STEP',
                  payload: {
                    selectedStep: 5,
                  },
                });
              } else {
                setDrawerVisible(false);
              }
            } else {
              setLoading(false);
            }
          }, 2500);
          */
        } catch (error) {
          displayAlert(
            error?.data?.errors[0].detail || error || 'There was an error with you request.',
            'warning'
          );
          setLoading(false);
        }
      },
      (errors) => {
        console.log(errors);
        displayAlert('There was an error with you request.', 'warning');
        setLoading(false);
      }
    );
  };

  const checkStep = async () => {
    setLoading1(true);
    setLoading2(true);
    const sessionDetail = await getSessionDetails();

    if (sessionDetail.state === 'pending-step') {
      displayAlert('Pending step', 'warning');
      setDisableButton1(false);
      setDisableButton2(true);
      setLoading1(false);
      setLoading2(false);
    }
    if (sessionDetail.state === 'new2-step1') {
      setDisableButton1(false);
      setDisableButton2(true);
      setLoading1(false);
      setLoading2(false);
    }
    if (sessionDetail.state === 'new2-step2') {
      setDisableButton1(true);
      setDisableButton2(false);
      setLoading1(false);
      setLoading2(false);
    }
    if (sessionDetail.state === 'action-required') {
      displayAlert('Action required: verify your identity', 'warning');
      setDisableButton1(true);
      setDisableButton2(false);
      setLoading1(false);
      setLoading2(false);
    }
    if (sessionDetail.state === 'error') {
      displayAlert('There is an error in your account', 'warning');
      setDisableButton1(false);
      setDisableButton2(true);
      setLoading1(false);
      setLoading2(false);
    }
    if (sessionDetail.state === 'active' || sessionDetail.state === 'stripe-payment-method-required') {
      displayAlert('Wepay account configured', 'success');
      setDisableButton1(true);
      setDisableButton2(true);
      setLoading1(false);
      setLoading2(false);
    }
  };

  const checkFields = (fields, formState) => {
    for (let i = 0; i < fields.length; i++) {
      const key = fields[i];
      if (!formState.hasOwnProperty(key)) {
        return false;
      }
      if (formState.hasOwnProperty(key)) {
        if (!formState[key]) {
          return false;
        }
      }
    }
    return true;
  };

  const onValuesChange = async (changedFields, allFields) => {
    const errors = await form.getFieldsError();

    let formFields = fieldList;
    if (allFields.hasOwnProperty('businessType')) {
      if (allFields.businessType === 'Sole Proprietorship') {
        formFields = fieldList.slice(0, 13);
      } else {
        formFields = fieldList;
      }
    }

    const formState = checkFields(formFields, allFields);

    if (!errors.some((item) => item.errors.length > 0) && form.getFieldValue('termsConditions') && formState) {
      setValidatedForm(true);
    } else {
      setValidatedForm(false);
    }

    const resetFields = [
      'businessLegalName',
      'businessZipCode',
      'businessAddress',
      'dba',
      'businessCity',
      'businessState',
      'businessEmail',
      'businessPhone',
      'businessStartDate',
      'businessEin',
    ];
    if (changedFields) {
      if (changedFields.hasOwnProperty('businessType')) {
        if (changedFields.businessType === 'Sole Proprietorship') {
          form.validateFields(resetFields);
          form.resetFields(resetFields);
        }
      }
    }
  };

  const handleTermsAndConditions = (e) => {
    if (e.target.checked) {
      setDisabledSaveButton(false);
    } else {
      setDisabledSaveButton(true);
    }
  };

  return (
    <StyledForm
      name="basic"
      form={form}
      initialValues={{ firstName: '', lastName: '' }}
      onFinish={onFinish}
      onFinishFailed={() => {}}
      onValuesChange={onValuesChange}
    >
      <ContentContainer className="content-container">
        {/** <Steps onboardingData={onboardingData} stepTitle={stepTitle} stepIcon={stepIcon} stepStatus={stepStatus} /> */}
        {termsConditions && <TermsConditions setTermsConditions={setTermsConditions} />}

        {globalLoading ? (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        ) : (
          <>
            <Text size={12} color="black" centered margin="0 auto">
              To help the government fight terrorism funding and money laundering, all financial institutions are
              required by federal law to obtain, verify, and record information about you, including your name,
              address, and date of birth. We may also ask to see your driver's license or other identifying
              documents.
            </Text>
            <SpaceResponsive lg={10} xmd={20} md={15} sm={15} />
            <Row gutter={15}>
              <Col span={12}>
                Business Type
                <Form.Item
                  name="businessType"
                  data-testid="firstNameTest"
                  rules={[{ required: true, message: 'Please input your first name', whitespace: true }]}
                >
                  <Select placeholder="Select Type">
                    <Option value="Sole Proprietorship">Sole Propietorship</Option>
                    <Option value="LLC">LLC</Option>
                    <Option value="Corporation">Corporation</Option>
                    <Option value="Non-Profit">Non Profit</Option>
                    <Option value="Government">Goverment Organization</Option>
                    <Option value="Partnership">Partnership</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                Business Role
                <Form.Item
                  name="businessRole"
                  rules={[{ required: true, message: 'Please input your last name', whitespace: true }]}
                >
                  <Select placeholder="Select Role">
                    <Option value="r1">role 1</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                First Name
                <Form.Item
                  name="firstName"
                  data-testid="firstNameTest"
                  rules={[{ required: true, message: 'Please input your first name', whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                Last Name
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your last name', whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Home Address
                <Form.Item
                  rules={[
                    { required: true, message: 'Please input your address', whitespace: true },
                    {
                      pattern: new RegExp(/(?!^\d+$)^.+$/),
                      message: 'Address must be numbers and letters',
                    },
                    {
                      max: 100,
                      message: 'Address cant be more than 100 characters',
                    },
                    {
                      validator: checkAddressValidator,
                    },
                  ]}
                  name="homeAddress"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={10}>
                Zip Code
                <Form.Item
                  name="zipCode"
                  rules={[
                    {
                      validator: checkZipCode,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                City
                <Form.Item
                  name="city"
                  data-testid="cityTest"
                  rules={[
                    {
                      validator: checkCity,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={10}>
                State
                <Form.Item
                  name="state"
                  data-testid="stateTest"
                  rules={[{ required: true, message: 'Please select a state' }]}
                >
                  <Select placeholder="Select State" showSearch onChange={() => {}}>
                    {usStates.map((s, i) => (
                      <Option value={s.abbreviation} key={i}>
                        {s.abbreviation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Email Address
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not a valid email',
                    },
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                Mobile Phone
                <Form.Item
                  name="mobilePhone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone number',
                    },
                    {
                      // pattern: new RegExp('^[0-9]{6,}$'),
                      // supports ten characters without + sign
                      // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                      pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                      message: 'Please enter only numbers - min length 10',
                    },
                    {
                      message: 'Please input a valid phone number.',
                      max: 12,
                    },
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111 111 1111"
                    mask="000 000 0000"
                    minLength={6}
                    placeholderChar=" "
                    data-testid="phoneTest"
                    prefix="+1"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <SsnContainer>
                  SSN or ITIN{' '}
                  <Tooltip
                    placement="top"
                    title="US Federal regulations require us to obtain your Social Security Number to confirm your identity. This will not impact your credit."
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ssnTooltip" />
                  </Tooltip>
                </SsnContainer>
                <Form.Item
                  name="ssn"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value) {
                          const number = value.replace(/-/g, '').trim();
                          if (number.length === 9) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Ssn must be nine digits');
                        }
                        return Promise.reject('Please input your SSN');
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111-11-1111"
                    mask="000-00-0000"
                    minLength={11}
                    placeholderChar=" "
                  />
                </Form.Item>
                <div id="emptyssn" style={{ position: 'absolute' }} key="3">
                  <div className="form-control-static">
                    <StyledSpan className="fake-input"></StyledSpan>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                Re-enter SSN
                <Form.Item
                  name="ssnItinConfirm"
                  dependencies={['ssn']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your SSN',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('ssn') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('The two SSN that you entered do not match');
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111-11-1111"
                    mask="000-00-0000"
                    minLength={6}
                    placeholderChar=" "
                    data-testid="maskedInputTest"
                  />
                </Form.Item>
              </Col>
            </Row>
            Date of Birth
            <Form.Item
              name="dateOfBirth"
              data-testid="dateOfBirthTest"
              rules={[{ required: true, message: 'Please select your date of birth.' }]}
            >
              <DatePicker
                format="MM/DD/YYYY"
                placeholder="mm/dd/yyyy"
                disabledDate={(current) => current && current > moment().subtract(18, 'years')}
                style={{ width: '100%' }}
                data-testid="datePickerTest"
              />
            </Form.Item>
            <Row gutter={15}>
              <Col span={12}>
                Business Legal Name
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessLegalName"
                        rules={[
                          {
                            required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                            message: 'Please input your business name',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col span={12}>
                DBA (if applicable)
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="dba"
                        // rules={[
                        //   {
                        //     required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                        //     message: 'Please input your dba name ',
                        //     whitespace: true,
                        //   },
                        // ]}
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Business Street Address
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        rules={[
                          {
                            required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                            message: 'Please input your address',
                            whitespace: true,
                          },
                          {
                            pattern: new RegExp(/(?!^\d+$)^.+$/),
                            message: 'Address must be numbers and letters',
                          },
                          {
                            max: 100,
                            message: 'Address cant be more than 100 characters',
                          },
                          {
                            validator: checkAddressValidator,
                          },
                        ]}
                        name="businessAddress"
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col span={10}>
                Zip Code
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessZipCode"
                        rules={[
                          {
                            validator:
                              form.getFieldValue('businessType') !== 'Sole Proprietorship' ? checkZipCode : false,
                          },
                        ]}
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                City
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessCity"
                        rules={[
                          {
                            validator:
                              form.getFieldValue('businessType') !== 'Sole Proprietorship' ? checkCity : false,
                          },
                        ]}
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col span={10}>
                State
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessState"
                        rules={[
                          {
                            required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                            message: 'Please select a state',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select State"
                          showSearch
                          onChange={() => {}}
                          disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'}
                        >
                          {usStates.map((s, i) => (
                            <Option value={s.abbreviation} key={i}>
                              {s.abbreviation}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Business Email Address
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessEmail"
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not a valid email',
                          },
                          {
                            required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                            message: 'Please enter your email',
                          },
                        ]}
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={10}>
                Business Phone
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessPhone"
                        rules={[
                          {
                            required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                            message: 'Please enter your phone number',
                          },
                          {
                            // pattern: new RegExp('^[0-9]{6,}$'),
                            // supports ten characters without + sign
                            // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                            pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                            message: 'Please enter only numbers - min length 10',
                          },
                          {
                            message: 'Please input a valid phone number.',
                            max: 12,
                          },
                        ]}
                      >
                        <MaskedInput
                          className="masked-input"
                          placeholder="111 111 1111"
                          mask="000 000 0000"
                          minLength={6}
                          placeholderChar=" "
                          data-testid="phoneTest"
                          prefix="+1"
                          disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                Business Start Date
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessStartDate"
                        rules={[
                          {
                            required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                            message: 'Please input your first name',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col span={12}>
                Business EIN
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.businessType !== curValues.businessType}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item
                        name="businessEin"
                        rules={[
                          {
                            required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                            message: 'Please input your last name',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <BankFilled style={{ fontSize: '14px', color: 'black' }} />
              <span
                onClick={() => open()}
                style={{
                  textDecoration: 'underline',
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: 'rgb(203, 72, 183)',
                  margin: '5px 5px',
                }}
              >
                Connect a bank account
              </span>
              <Tooltip
                placement="top"
                trigger="click"
                title={
                  'Connect your bank account securely using Plaid. We do not store your login credentials in our system.'
                }
              >
                <InfoCircleFilled style={{ fontSize: '14px', color: 'black' }} />
              </Tooltip>
            </div>
          </>
        )}
      </ContentContainer>
      <PanelFooter>
        <AlertFade
          type={message.type}
          isVisible={!!message.message}
          message={message.message}
          alertPosition="absolute"
          position="absolute"
          minHeight="80"
          // width={80}
        />
        <div style={{ display: 'flex' }}>
          <Form.Item noStyle name="termsConditions" valuePropName="checked">
            <Checkbox onChange={handleTermsAndConditions} />
          </Form.Item>
          <StyledText style={{ color: 'black', marginLeft: '5px' }}>I agree to the </StyledText>
          <StyledText
            style={{
              color: '#cb48b7',
              textDecoration: 'underline',
              cursor: 'pointer',
              margin: '0px 5px 0px 5px',
            }}
            onClick={() => setTermsConditions(true)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <a href="https://payrent.com/terms-conditions" target="_blank" rel="noopener noreferrer">
              terms and conditions
            </a>
          </StyledText>
          and{' '}
          <StyledText
            style={{
              color: '#cb48b7',
              textDecoration: 'underline',
              cursor: 'pointer',
              margin: '0px 5px 0px 5px',
            }}
            onClick={() => setTermsConditions(true)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            <a href="https://payrent.com/privacy-policy" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>
          </StyledText>
          <StyledText style={{ color: 'black' }}> of this account</StyledText>
        </div>

        <Space vertical={10} />
        <StyledItem>
          <Row justify="center">
            <Col span={7} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {/**
               <StyledText
                inline
                start
                onClick={() => props.setCurrentStep(2)}
                size={12}
                underline
                pointer
                margin="0px 0px 0px 13px"
              >
                Previous
              </StyledText>
               
               */}
            </Col>
            <Col span={10}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                color="violet"
                alignment="center"
                bottom={0}
                // relativeMarginLeft={4}
                data-testid="buttonTest"
                disabled={!validatedForm}
              >
                SAVE
              </Button>
            </Col>
            <Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/**
               
                <StyledText
                end
                onClick={() => props.setCurrentStep(4)}
                size={12}
                data-testid="skipText"
                underline
                pointer
              >
                Skip this for now
              </StyledText>
               */}
            </Col>
          </Row>
        </StyledItem>
      </PanelFooter>
    </StyledForm>
  );
};

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px;
  width: 100%;
  @media screen and (max-height: 750px) {
    &&&&&&&.ant-form-item {
      margin-bottom: 0px !important;
    }
  }
`;

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 116px);
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 30px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const StyledSpan = styled.span`
  iframe {
    height: 32px;
    width: 98%;
  }
`;

const SsnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const StyledText = styled.span`
  font-size: 14px;

  @media screen and (max-width: 445px) {
    font-size: 12px;
  }
  @media screen and (max-width: 390px) {
    font-size: 11px;
  }
`;

export default SilaOnboarding;
