import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';
import { Form, Checkbox, Spin } from 'antd';
import styled from 'styled-components';
import parser from 'parse-address';
import { createOnboarding, createPaymentMethodV3, submitAppForOnboarding } from 'services/api/landlordApi/create';
import { displayAlertAction } from 'store/actions/globalActions';
import { vgsCss } from 'utils/vgs';
import {
  getSilaBusinessRoles,
  getPropertyGroupBoardingSubmit,
  getPropertyGroupOnboardingStatus,
  getPlaidOauthLinkToken,
} from 'services/api/landlordApi/read';
import { displayAlert } from 'resources/helpers';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import PanelFooter from 'components/Panel/PanelFooter';
import GeneralFields from '../GeneralFields';
import BusinessFields from '../BusinessFields';
import Finished from '../Finished';
import VerificationDocs from '../VerificationDocs/VerificationDocs';
import DocsReview from '../DocsReview';
import StripeSetupPanel from '../StripeSetupPanel';

//import './style.scss';

const SilaPayments = (props) => {
  const {
    propertyGroup,
    setDrawerVisible,
    setShowAcceptPayments,
    setOnboardingState,
    onboardingState,
    stripeTimer,
    fetchPropertyGroupStatus,
  } = props;
  const dispatch = useDispatch();
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [businessRoles, setBusinessRoles] = useState([]);
  const [termsConditions, setTermsConditions] = useState(false);
  const [showBusinessFields, setShowBusinessFields] = useState(false);
  const [enableContinue, setEnableContinue] = useState(true);
  const [vgsForm, setVgsForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoaded, scriptLoaded] = useState(false);
  const [plaidToken, setPlaidToken] = useState(null);
  const [disabledSave, setDisabledSave] = useState(true);
  const [setupAccount, setSetupAccount] = useState(false);
  const [wepayPm, setWepayPm] = useState(false);
  const [finixPm, setFinixPm] = useState(false);
  const [finished, setFinished] = useState(false);
  const [boardingSubmitted, setBoardingSubmitted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [requiredDocs, setRequiredDocs] = useState(false);
  const [showStripePanel, setShowStripePanel] = useState(false);
  const [showDocsReview, setDocsReview] = useState(false);

  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  const [form] = Form.useForm();
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  /* **
   *  ADD SPINNER
   *
   *
   */

  const fieldList = [
    'ownership',
    'businessRole',
    'firstName',
    'lastName',
    'homeAddress',
    'zipCode',
    'city',
    'state',
    'email',
    'mobilePhone',
    'ssn',
    'ssnItinConfirm',
    'dateOfBirth',
    'termsConditions',
    'legalBusinessName',
    // 'dbaName',
    'businessAddress',
    'bZipcode',
    'bCity',
    'bState',
    'businessEmail',
    'businessPhone',
    'businessStartDate',
    'businessEin',
  ];

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  useEffect(() => {
    console.log(session.userData.paymentGateway);
    if (session.userData.paymentGateway === 'Wepay') setWepayPm(true);
    if (session.userData.paymentGateway === 'Finix') setFinixPm(true);
    if (session.userData.paymentGateway === 'SilaStripe') {
      if (!boardingSubmitted && !processing) setSetupAccount(true);
      const createVgsForm = window.VGSCollect.create(
        process.env.REACT_APP_VGS_VAULT,
        process.env.REACT_APP_VGS_ENV,
        (s) => {
          if (s) {
            // if (s.businessEin?.isDirty) form.validateFields(['businessEin']);
            // if (s.routingNumber?.isDirty) form.validateFields(['routingNumber']);
            // if (s.bankAccountNumber?.isDirty) form.validateFields(['bankAccountNumber']);
          }
        }
      );

      const businessEin = {
        type: 'text',
        name: 'emptyssn',
        // placeholder: 'AAA-GG-SSSS',
        fontFamily: 'Montserrat',
        css: vgsCss,
      };

      if (!finished && setupAccount) {
        createVgsForm.field('#emptyssn .fake-input', businessEin);
        setVgsForm(createVgsForm);
        scriptLoaded(true);
      }
    }
  }, [setupAccount]);

  const onSuccess = async (token, metadata) => {
    try {
      const plaid = {
        token,
        metadata,
      };
      setProcessing(true);
      await createPaymentMethodV3(cognitoToken, plaid, 'SilaStripe', 'landlord', +propertyGroup[0].id);
      fetchPropertyGroupStatus(propertyGroup);
      setProcessing(false);
      setShowStripePanel(true);
      // setFinished(true);
    } catch (error) {
      console.log(error);
      setDrawerVisible(false);
      setProcessing(false);
      setShowAcceptPayments(false);
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
      // displayAlert('Payment Method Error', 'warning', 'Something went wrong while verifying your payment method');
    }
  };

  const onExit = (error, metadata) => {
    dispatch(displayAlertAction('', 'warning', 'Onboarding incomplete.', true, 5000));
    setDrawerVisible(false);
    setShowAcceptPayments(false);
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    // receivedRedirectUri: window.location.href,
    token: plaidToken,
    onSuccess,
    onExit,
  };
  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  useEffect(() => {
    if (ready) {
      if (boardingSubmitted && session.userData.paymentGateway === 'SilaStripe') {
        // and if ach is null
        if (
          (!onboardingState.ach.silaSettlement && onboardingState.creditCard.chargesEnabled) ||
          (!onboardingState.ach.silaSettlement && onboardingState.creditCard.payoutsEnabled) ||
          (!onboardingState.ach.silaSettlement && onboardingState.creditCard.settlement) ||
          (!onboardingState.ach.silaSettlement &&
            !onboardingState.creditCard.chargesEnabled &&
            !onboardingState.creditCard.payoutsEnabled &&
            !onboardingState.creditCard.optIn) ||
          (!onboardingState.ach.silaSettlement &&
            !onboardingState.creditCard.chargesEnabled &&
            !onboardingState.creditCard.payoutsEnabled &&
            onboardingState.creditCard.optIn)
        ) {
          open();
        }
      }
    }
  }, [ready, open, boardingSubmitted]);

  useEffect(() => {
    if (session.userData.paymentGateway === 'SilaStripe') {
      fetchSilaFormData();
    }
  }, []);

  const fetchSilaFormData = async (businessRole) => {
    try {
      setProcessing(true);
      let boardingSub = false;
      setLoadingRoles(true);

      if (isOAuthRedirect) {
        setPlaidToken(localStorage.getItem('link_token'));
      } else {
        if (session.userData.paymentGateway !== 'Wepay') {
          const plaidOauthToken = await getPlaidOauthLinkToken(session.cognitoTokens.idToken.jwtToken);
          localStorage.setItem('link_token', plaidOauthToken.linkToken);
          setPlaidToken(plaidOauthToken.linkToken);
        }
      }

      if (onboardingState.ach.state === 'DOCUMENTS_RECEIVED') {
        setSetupAccount(false);
        setDocsReview(true);
      }

      try {
        setProcessing(true);
        await getPropertyGroupBoardingSubmit(cognitoToken, +propertyGroup[0].id);
        boardingSub = true;
        setBoardingSubmitted(true);
        setProcessing(false);
        setSetupAccount(false);
        setFinished(false);
        if (
          !onboardingState.creditCard.chargesEnabled &&
          !onboardingState.creditCard.payoutsEnabled &&
          !onboardingState.creditCard.settlement &&
          onboardingState.creditCard.optIn &&
          onboardingState.ach.state !== 'DOCUMENTS_RECEIVED'
        )
          setShowStripePanel(true);
      } catch (e) {
        setBoardingSubmitted(false);
        setProcessing(false);
      }
      if (
        ((onboardingState.ach.state === 'DOCUMENTS_REQUIRED' || onboardingState.ach.state === 'REVIEW') &&
          !onboardingState.creditCard.optIn &&
          onboardingState.ach.silaSettlement) ||
        ((onboardingState.ach.state === 'DOCUMENTS_REQUIRED' || onboardingState.ach.state === 'REVIEW') &&
          onboardingState.creditCard.optIn &&
          onboardingState.creditCard.chargesEnabled &&
          onboardingState.creditCard.payoutsEnabled &&
          onboardingState.ach.silaSettlement) ||
        ((onboardingState.ach.state === 'DOCUMENTS_REQUIRED' || onboardingState.ach.state === 'REVIEW') &&
          (onboardingState.creditCard.state === 'ACTION_REQUIRED' ||
            onboardingState.creditCard.state === 'PENDING'))
      ) {
        setFinished(false);
        setSetupAccount(false);
        setRequiredDocs(true);
      }
      const bRoles = await getSilaBusinessRoles();
      setBusinessRoles(bRoles.businessRoles);
      if (businessRole) form.setFieldsValue({ businessRole });

      setLoadingRoles(false);
    } catch (e) {
      setProcessing(false);
      setLoadingRoles(false);
      console.log(e);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    setLoading(true);
    if (values.ownership !== 'Sole Proprietorship' && !showBusinessFields) {
      setEnableContinue(false);
    }

    const data = {
      ssn: values.ssn,
    };

    if (values.ownership !== 'Sole Proprietorship') {
      data.businessEin = values.businessEin;
    }

    vgsForm.submit(
      '/post',
      {
        data,
      },
      async (status, response) => {
        try {
          console.log(response);
          values.ssn = JSON.parse(response.data).ssn;

          const parsedAddress = parser.parseLocation(values.homeAddress);
          if (!parsedAddress) throw { message: 'Not a valid address' };

          const streetNumber = parsedAddress.number;
          const streetName = values.homeAddress.replace(`${parsedAddress.number} `, '');

          values.streetNumber = streetNumber;
          values.streetName = streetName;

          if (values.ownership !== 'Sole Proprietorship') {
            values.businessEin = JSON.parse(response.data).businessEin;
            const bparsedAddress = parser.parseLocation(values.businessAddress);
            if (!bparsedAddress) throw { message: 'Not a valid address' };

            const bstreetNumber = bparsedAddress.number;
            const bstreetName = values.businessAddress.replace(`${bparsedAddress.number} `, '');
            const bPhone = values.businessPhone.replace(/\s/g, '');

            values.bAddress = bstreetName;
            values.bStreetNum = bstreetNumber;
            values.businessPhone = bPhone;
          }

          values.processorId = +session.userData.servicePlan.processor.id;
          values.servicePlanId = +session.userData.servicePlan.id;
          values.propertyGroupId = +propertyGroup[0].id;
          values.mobilePhone = values.mobilePhone.replace(/\s/g, '');

          const res = await createOnboarding(cognitoToken, values);
          await submitAppForOnboarding(cognitoToken, +res.id);
          setDisabledSave(true);
          setLoading(false);
          displayAlert(setAlert, 'Form successfully saved', 'success', 5000);
          setTimeout(() => {
            setBoardingSubmitted(true);
            setSetupAccount(false);
            // setShowStripePanel(true);
            open();
          }, 3500);
        } catch (e) {
          console.log(e);
          displayAlert(
            setAlert,
            e.data?.errors[0].detail || 'There was an error with you request.',
            'warning',
            3000
          );
          setLoading(false);
        }
      },
      (errors) => {
        console.log(errors);
        setLoading(false);
        displayAlert(setAlert, 'There was an error with your request.', 'warning', 3000);
      }
    );
  };

  const checkFields = (fields, formState) => {
    for (let i = 0; i < fields.length; i++) {
      const key = fields[i];
      if (!formState.hasOwnProperty(key)) {
        return false;
      }
      if (formState.hasOwnProperty(key)) {
        if (!formState[key]) {
          return false;
        }
      }
    }
    return true;
  };

  const onValuesChange = async (values, allFields) => {
    // console.log('ƒ onValuesChange', values, allFields, form.getFieldsError());
    let formFields = fieldList;
    const [key] = Object.keys(values);

    if (key === 'ownership') {
      const value = values[key];
      form.setFieldsValue(
        value === 'Sole Proprietorship' ? { businessRole: 'beneficial_owner' } : { businessRole: null }
      );
    }

    if (allFields.hasOwnProperty('ownership')) {
      if (allFields.ownership !== 'Sole Proprietorship') {
        setTimeout(async () => {
          const delayedErr = await form.getFieldsError();
          formFields = fieldList.slice(0, 12);

          const formState = checkFields(formFields, allFields);

          if (!delayedErr.some((item) => item.errors.length > 0) && formState) {
            setEnableContinue(false);
            setDisabledSave(true);
          } else {
            setEnableContinue(true);
            setDisabledSave(true);
          }
        }, 500);
      } else {
        setTimeout(() => {
          const delayedErr = form.getFieldsError();
          formFields = fieldList.slice(0, 14);

          const formState = checkFields(formFields, allFields);

          if (!delayedErr.some((item) => item.errors.length > 0) && formState) {
            setDisabledSave(false);
          } else {
            setDisabledSave(true);
          }
        }, 500);
      }
    }

    if (showBusinessFields) {
      setTimeout(async () => {
        const delayedErr = await form.getFieldsError();
        formFields = fieldList;

        const formState = checkFields(formFields, allFields);

        if (!delayedErr.some((item) => item.errors.length > 0) && formState) {
          setDisabledSave(false);
        } else {
          setDisabledSave(true);
        }
      }, 500);
    }
  };

  const validateTermsAndConditions = (rule, value) => {
    if (!value) {
      return Promise.reject(new Error('Please accept the terms and conditions.'));
    }
    return Promise.resolve();
  };

  const fetchOnboardingStatus = async () => {
    try {
      const res = await getPropertyGroupOnboardingStatus(cognitoToken, propertyGroup[0].id);
      setOnboardingState(res);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <StyledForm
        form={form}
        name="basic"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        onValuesChange={onValuesChange}
      >
        {processing && !finished && !setupAccount && <Spin spinning />}
        {!finished && setupAccount && (
          <>
            <Spin spinning={processing}>
              <FormContentContainer className="content-container">
                <GeneralFields
                  businessRoles={businessRoles}
                  loadingRoles={loadingRoles}
                  setShowBusinessFields={setShowBusinessFields}
                  showBusinessFields={showBusinessFields}
                  form={form}
                  enableContinue={enableContinue}
                />
                {showBusinessFields && <BusinessFields form={form} />}
              </FormContentContainer>
            </Spin>
            <PanelFooter>
              <AlertFade
                type={alert.type}
                isVisible={!!alert.message}
                message={alert.message}
                // description={message.description}
                alertPosition="absolute"
                position="absolute"
                minHeight="40"
                // closable={alert.closable}
                // marginBottom={alert.marginBottom}
                // width={90}
                bottom={82}
              />

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <StyledItem>
                  <Form.Item
                    name="termsConditions"
                    valuePropName="checked"
                    rules={[{ validator: validateTermsAndConditions }]}
                    noStyle
                  >
                    <Checkbox />
                  </Form.Item>
                  <StyledText style={{ color: 'black', marginLeft: '5px' }}>I agree to the </StyledText>
                  <StyledText
                    style={{
                      color: '#cb48b7',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      margin: '0px 5px 0px 5px',
                    }}
                    onClick={() => setTermsConditions(true)}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <a href="https://payrent.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                      terms and conditions
                    </a>
                  </StyledText>
                  and{' '}
                  <StyledText
                    style={{
                      color: '#cb48b7',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      margin: '0px 5px 0px 5px',
                    }}
                    onClick={() => setTermsConditions(true)}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <a href="https://payrent.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                      privacy policy
                    </a>
                  </StyledText>
                </StyledItem>
              </div>

              <SaveItem className="save-form-item">
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.ownership !== curValues.ownership}
                  noStyle
                >
                  {() => {
                    return (
                      <>
                        {typeof form.getFieldValue === 'function'
                          ? (form.getFieldValue('ownership') === 'Sole Proprietorship' ||
                              form.getFieldValue('ownership') === undefined ||
                              showBusinessFields) && (
                              <Button
                                type="primary"
                                htmlType="submit"
                                bottom={0}
                                color="violet"
                                loading={loading}
                                alignment="center"
                                data-testid="buttonTest"
                                disabled={disabledSave}
                              >
                                SAVE
                              </Button>
                            )
                          : ''}

                        {typeof form.getFieldValue === 'function'
                          ? form.getFieldValue('ownership') !== 'Sole Proprietorship' &&
                            form.getFieldValue('ownership') &&
                            !showBusinessFields && (
                              <Button
                                color="violet"
                                bottom={0}
                                disabled={enableContinue}
                                alignment="center"
                                onClick={() => setShowBusinessFields(true)}
                              >
                                CONTINUE
                              </Button>
                            )
                          : ''}
                      </>
                    );
                  }}
                </Form.Item>
              </SaveItem>
            </PanelFooter>
          </>
        )}
        {showStripePanel && (
          <StripeSetupPanel
            setShowStripePanel={setShowStripePanel}
            open={open}
            stripeTimer={stripeTimer}
            propertyGroup={propertyGroup}
            setFinished={setFinished}
            setOnboardingState={setOnboardingState}
            setRequiredDocs={setRequiredDocs}
            fetchOnboardingStatus={fetchOnboardingStatus}
          />
        )}
        {finished && (
          <Finished
            boardingStatus={onboardingState}
            setDrawerVisible={setDrawerVisible}
            setShowAcceptPayments={setShowAcceptPayments}
          />
        )}
        {requiredDocs && (
          <VerificationDocs
            propertyGroup={propertyGroup}
            boardingStatus={onboardingState}
            setRequiredDocs={setRequiredDocs}
            setDrawerVisible={setDrawerVisible}
            setOnboardingState={setOnboardingState}
            setDocsReview={setDocsReview}
          />
        )}
        {showDocsReview && <DocsReview setDrawerVisible={setDrawerVisible} setDocsReview={setDocsReview} />}
      </StyledForm>
    </>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledText = styled.span`
  font-size: 14px;

  @media screen and (max-width: 445px) {
    font-size: 12px;
  }
  @media screen and (max-width: 390px) {
    font-size: 11px;
  }
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  @media screen and (max-height: 750px) {
    margin-bottom: 0px !important;
  }
`;

const FormContentContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 25px 0px 25px;
  width: 100% !important;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 10px !important;
  padding: 0 25px;
`;

export default SilaPayments;
