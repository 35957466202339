import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Form, Input, Row, Col, Checkbox, Tooltip, Spin } from 'antd';
import { updatePropertyGroup } from 'services/api/landlordApi/update';
import { getServicePlanAction } from 'store/actions/sessionActions';
import { InfoCircleFilled } from '@ant-design/icons';
import SpaceResponsive from 'components/SpaceResponsive';
import Space from 'components/Space';
import Text from 'components/Text';
import AdvancedFeaturesOverlay from '../../../components/AdvancedFeaturesOverlay/AdvancedFeaturesOverlay';
import AdditionalFeesFields from '../../../components/AdditionalFeesFields/AdditionalFeesFields';
import PropertyRentPolicy from '../../../components/PropertyRentPolicy/PropertyRentPolicy';
import OnboardingResult from '../OnboardingResult';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';

const PortfolioSettingsForm = (props) => {
  const { setIsLoading, setCurrentStep, currentStep, isLoading, isError, propertyGroup } = props;
  // console.log('[PortfolioSettingsForm.js]', props);
  const session = useSelector((store) => store.session);
  const quickStart = session.userData.landlordMeta.landlordProfile?.landlordProfile?.quickStart;
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  // Hooks
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const {
  //   isLoading,
  //   isError,
  //   error,
  //   data: propertyGroup,
  // } = useQuery({
  //   queryKey: ['defaultPropertyGroup'],
  //   queryFn: () =>
  //     getPropertyGroups(session.cognitoTokens.idToken.jwtToken, 1, 10000).then((res) =>
  //       res.items.filter((pg) => pg.default === 1)
  //     ),
  //   staleTime: 100,
  // });

  const [message, setMessage] = useState({
    type: 'success',
    message: '',
    description: '',
    closable: false,
    marginBottom: 0,
  });

  useEffect(() => {
    fetchAndSetData();
    dispatch(getServicePlanAction(cognitoToken));
  }, [propertyGroup]);

  const fetchAndSetData = async () => {
    try {
      if (propertyGroup) {
        const fields = {
          portfolioName: propertyGroup.name,
          acceptCreditCard: propertyGroup.settings.acceptCreditCard,
          absorbFees: propertyGroup.settings.absorbFees,
          fullPayment: propertyGroup.settings.requireFullPayment,
          preventPayment: propertyGroup.settings.preventPayments,
          complianceLevel: propertyGroup.settings.complianceLevel,
          securityDepositAmount: propertyGroup.settings.propertyFees.securityDepositAmount,
          securityDepositSwitch: propertyGroup.settings.propertyFees.securityDeposit,
          discountAmountEarly: propertyGroup.settings.propertyFees.discountAmount,
          daysEarly: propertyGroup.settings.propertyFees.discountDays,
          daysEarlySwitch: propertyGroup.settings.propertyFees.discount,
          lateFeeAmount: propertyGroup.settings.propertyFees.lateFeeAmount,
          daysLateFee: propertyGroup.settings.propertyFees.lateFeeDays,
          daysLateSwitch: propertyGroup.settings.propertyFees.lateFee,
          parkingFeeAmount: propertyGroup.settings.propertyFees.parkingAmount,
          percent: propertyGroup.settings.absorbFeesPercent,
          periodParkingFee: propertyGroup.settings.propertyFees.parkingFrequency,
          parkingFeeSwitch: propertyGroup.settings.propertyFees.parking,
          moveInFee: propertyGroup.settings.propertyFees.moveInAmount,
          moveInFeeSwitch: propertyGroup.settings.propertyFees.moveIn,
          moveOutFee: propertyGroup.settings.propertyFees.moveOutAmount,
          moveOutFeeSwitch: propertyGroup.settings.propertyFees.moveOut,
        };
        form.setFieldsValue(fields);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayAlert = (text, type, description, time, close, marginBottom) => {
    if (message.message) {
      setMessage({
        message: '',
        description: '',
      });
    }
    setMessage({
      message: text,
      type,
      description,
      closable: !close,
      marginBottom,
    });
    if (close) {
      setTimeout(() => {
        setMessage({
          message: '',
          description: '',
          marginBottom: 0,
        });
      }, time);
    }
  };

  const onFinish = async (values) => {
    // console.log('[PortfolioSettingsForm.js] onFinish', values);
    try {
      setIsLoading(true);
      await updatePropertyGroup(cognitoToken, propertyGroup.id, values);
      setIsLoading(false);
      dispatch(
        updateQuickStartStatusAction(
          session.userData.id,
          { ...quickStart, lastCompletedStep: currentStep },
          cognitoToken
        )
      );
      setCurrentStep(currentStep + 1);
    } catch (error) {
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.title;
      displayAlert(detail || 'Request failed.', 'warning', '', 3000, true);
    }
  };

  const onValuesChange = (values) => {
    if (values.securityDepositAmount) {
      if (isNaN(values.securityDepositAmount)) {
        form.setFieldsValue({ securityDepositAmount: '' });
      }
    }
    if (values.discountAmountEarly) {
      if (isNaN(values.discountAmountEarly)) {
        form.setFieldsValue({ discountAmountEarly: '' });
      }
    }
    if (values.daysEarly) {
      if (isNaN(values.daysEarly)) {
        form.setFieldsValue({ daysEarly: '' });
      }
    }
    if (values.lateFeeAmount) {
      if (isNaN(values.lateFeeAmount)) {
        form.setFieldsValue({ lateFeeAmount: '' });
      }
    }
    if (values.daysLateFee) {
      if (isNaN(values.daysLateFee)) {
        form.setFieldsValue({ daysLateFee: '' });
      }
    }
    if (values.parkingFeeAmount) {
      if (isNaN(values.parkingFeeAmount)) {
        form.setFieldsValue({ parkingFeeAmount: '' });
      }
    }
    if (values.moveInFee) {
      if (isNaN(values.moveInFee)) {
        form.setFieldsValue({ moveInFee: '' });
      }
    }
    if (values.moveOutFee) {
      if (isNaN(values.moveOutFee)) {
        form.setFieldsValue({ moveOutFee: '' });
      }
    }
    // Switchs
    if (values?.securityDepositSwitch === false) {
      form.resetFields(['securityDepositAmount']);
    }
    if (values?.daysEarlySwitch === false) {
      form.resetFields(['discountAmountEarly', 'daysEarly']);
    }

    if (values?.daysLateSwitch === false) {
      if (form.getFieldValue('complianceLevel') === 0) {
        form.setFieldsValue({ daysLateSwitch: false });
        form.resetFields(['lateFeeAmount', 'daysLateFee', 'lateFeeFrequency']);
      } else {
        form.setFieldsValue({ daysLateSwitch: true });
      }
    }

    if (values?.parkingFeeSwitch === false) {
      form.resetFields(['parkingFeeAmount', 'periodParkingFee']);
    }
    if (values?.moveInFeeSwitch === false) {
      form.resetFields(['moveInFee']);
    }
    if (values?.moveOutFeeSwitch === false) {
      form.resetFields(['moveOutFee']);
    }
    if (values?.complianceLevel) {
      form.setFieldsValue({ daysLateSwitch: true });
    }
    if (values?.hasOwnProperty('complianceLevel')) {
      if (!values.complianceLevel) {
        form.setFieldsValue({ daysLateSwitch: false });
        form.resetFields(['lateFeeAmount', 'daysLateFee', 'lateFeeFrequency']);
      }
    }
  };

  const checkPercentage = (rule, value) => {
    if (form.getFieldValue('absorbFees')) {
      if (Number(value) <= 100 && Number(value) > 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Please enter a valid percentage.'));
    }
    return Promise.resolve();
  };

  if (isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        className="spin-container"
      >
        <Spin spinning={isLoading} />
      </div>
    );
  } else if (isError) {
    return <OnboardingResult />;
  } else {
    return (
      <StyledForm
        form={form}
        name="portfolio-settings-onboarding"
        data-testid="portfolioSettingsFormTest"
        initialValues={{
          percent: 100,
          acceptCreditCard: true,
          absorbFees: true,
          securityDepositSwitch: false,
          daysEarlySwitch: false,
          daysLateSwitch: false,
          parkingFeeSwitch: false,
          moveInFeeSwitch: false,
          moveOutFeeSwitch: false,
        }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        onValuesChange={onValuesChange}
      >
        Portfolio Name
        <Form.Item name="portfolioName">
          <Input placeholder="My Portfolio" data-testid="portfolioNameInputTest" />
        </Form.Item>
        <SpaceResponsive lg={10} md={10} sm={10} />
        <Text size={16} color="black" strong>
          Default Property Settings
        </Text>
        <PaymentControlsContainer>
          <Form.Item
            name="acceptCreditCard"
            valuePropName="checked"
            // getValueProps={() => creditCardPayments}
            style={{ marginBottom: '10px !important' }}
          >
            <Checkbox>
              <Text color="#595959">Accept credit card payments</Text>
              <Tooltip
                placement="top"
                title="Choose to allow renters to pay rent with a credit card. Renters pay these fees."
                trigger="click"
              >
                <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ccTooltip" />
              </Tooltip>
            </Checkbox>
          </Form.Item>
          {session.userData.servicePlan.metaFeatures.rENTERPAYSFees && (
            <Row data-testid="absorbFeesContainerTest">
              <Col span={6} className="absorbField">
                <Form.Item name="absorbFees" valuePropName="checked">
                  <Checkbox>
                    <Text color="#595959">Absorb</Text>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => prevValues.absorbFees !== curValues.absorbFees}
                  noStyle
                >
                  {() => {
                    return (
                      <Form.Item>
                        <Form.Item
                          name="percent"
                          noStyle
                          rules={[
                            {
                              validator: checkPercentage,
                            },
                          ]}
                        >
                          <Input
                            disabled={!form.getFieldValue('absorbFees')}
                            style={{ width: '23%' }}
                            data-testid="absorbPercentTest"
                          />
                        </Form.Item>
                        <span>&nbsp;&nbsp;percent of ACH fees</span>
                        <Tooltip
                          placement="top"
                          title="Absorb, split or pass-through ACH transaction fees. If selected, you are paying some percentage of ACH fees."
                          trigger="click"
                        >
                          <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="absorbFeeTooltip" />
                        </Tooltip>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item name="fullPayment" valuePropName="checked">
            <Checkbox>
              <Text color="#595959">Require full payment</Text>
              <Tooltip
                placement="top"
                title="If selected, renters must pay their full account balance. If their account balance is zero, they cannot make a payment"
                trigger="click"
              >
                <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="fullPaymentTooltip" />
              </Tooltip>
            </Checkbox>
          </Form.Item>
          <Form.Item name="preventPayment" valuePropName="checked">
            <Checkbox>
              <Text color="#595959">Prevent payment</Text>
              <Tooltip
                placement="top"
                title="If selected, renters are prevented from making any payments."
                trigger="click"
              >
                <InfoCircleFilled style={{ marginLeft: '8px' }} />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </PaymentControlsContainer>
        <Space vertical={20} />
        <PropertyRentPolicy servicePlan={session.userData.servicePlan} />
        <Text strong color="black">
          Default Assessments
        </Text>
        <Tooltip
          placement="top"
          title="These are additional billing items automatically posted to a renters account based on the rules you set below."
          trigger="click"
        >
          <InfoCircleFilled style={{ marginLeft: '8px' }} />
        </Tooltip>
        <PaymentControlsContainer>
          {!session.userData.servicePlan.metaFeatures.aDDITIONALAssessments ? (
            <AdvancedFeaturesOverlay setShowAccountPreferences={props.setShowPropertyPanel} />
          ) : (
            ''
          )}
          <AdditionalFeesFields form={form} />
        </PaymentControlsContainer>
      </StyledForm>
    );
  }
};

const StyledForm = styled(Form)``;

const PaymentControlsContainer = styled.div`
  margin-top: 10px;
  position: relative;
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

export default PortfolioSettingsForm;
