import * as React from 'react';
import { Avatar as AntAvatar } from 'antd';

const pickRandomColor = () => {
  const values = ['#CB47B8', '#122C34', '#FFCC00', '#541388'];
  const color = values[Math.floor(Math.random() * values.length)];
  return color;
};

const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
  );
};

const areEqual = (prevProps, nextProps) => {
  if (arrayEquals(prevProps.children, nextProps.children)) {
    return true;
  }
  return false;
};

const Avatar = React.memo((props) => {
  const { children, size = 36, float, marginRight } = props;

  return (
    <AntAvatar
      shape="square"
      size={size}
      style={{
        backgroundColor: pickRandomColor(),
        verticalAlign: 'middle',
        marginTop: '4px',
        borderRadius: '4px',
        fontSize: '14px !important',
        float,
        marginRight: marginRight && `${marginRight}px`,
      }}
    >
      {children}
    </AntAvatar>
  );
}, areEqual);

export default Avatar;
