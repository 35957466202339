import React from 'react';
import { ReactComponent as LvbleLogo } from 'assets/images/rentcred/lvble_logo_green.svg';
import Text from 'components/Text/Text';
export default function PoweredByLvble() {
  return (
    <div className="lvble-logo" style={{ display: 'inline-flex', gap: 8 }}>
      <Text color="black" size={9}>
        powered by
      </Text>
      <LvbleLogo style={{ width: '56px', height: 'auto' }} />
    </div>
  );
}
