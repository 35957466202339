import React from 'react';

import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';

const PinIcon = (props) => {
  // console.log('PinIcon props', props);
  const { handleUpdateItem, buttons, feed } = props;

  return (
    <a>
      {buttons.pinned ? (
        <PushpinFilled
          style={{ fontSize: '20px', color: 'black' }}
          onClick={() => handleUpdateItem(buttons.id, 'unpin', feed.filter((f) => f.id === buttons.id)[0])}
          data-testid="pushpinTest"
        />
      ) : (
        <PushpinOutlined
          style={{ fontSize: '20px', color: 'black' }}
          onClick={() => {
            setTimeout(async () => {
              await handleUpdateItem(buttons.id, 'pin', feed.filter((f) => f.id === buttons.id)[0]);
            }, 250);
          }}
          data-testid="pushpinOutlinedTest"
        />
      )}
    </a>
  );
};

export default PinIcon;
