import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isDesktop } from 'react-device-detect';
import { CloseSquareFilled } from '@ant-design/icons';

export default function ExpandablePanel(props) {
  const {
    closable,
    children,
    title,
    drawerExpanded,
    setDrawerExpanded,
    placement,
    drawerOpen,
    setDrawerOpen,
    footer,
  } = props;
  const [width, setWidth] = useState(null);

  const handleClose = () => {
    setDrawerOpen(false);
    setDrawerExpanded(false);
  };

  useEffect(() => {
    if (drawerExpanded) {
      setWidth('100%');
    } else {
      setWidth(isDesktop ? '414px' : '100%');
    }
  }, [drawerExpanded]);

  return (
    <StyledDrawer
      title={title}
      placement={placement}
      onClose={handleClose}
      closable={closable}
      open={drawerOpen}
      width={width}
      footer={footer}
      closeIcon={<CloseSquareFilled style={{ fontSize: '25px', color: '#122C34' }} />}
    >
      {children}
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)``;

ExpandablePanel.defaultProps = {
  children: <div>Your content here</div>,
  title: 'Drawer Title',
  closabe: true,
  placement: 'right',
  width: 500,
  footer: null,
};

ExpandablePanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func,
  footer: PropTypes.node,
};
