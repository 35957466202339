import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Spin } from 'antd';
import styled from 'styled-components';
import { usePlaidLink } from 'react-plaid-link';
import { useFlagsmith } from 'flagsmith-react';

import { CloseOutlined } from '@ant-design/icons';

import {
  getPropertyGroupOnboardingStatus,
  getPropertyGroups,
  getPropertyGroupBoardingSubmit,
  getPlaidOauthLinkToken,
} from 'services/api/landlordApi/read';
import { createPaymentMethodV3 } from 'services/api/landlordApi/create';

import { displayAlertAction } from 'store/actions/globalActions';
import { AnimatePresence } from 'framer-motion';

import Text from 'components/Text';
import Space from 'components/Space';
import AccountSetup from './components/AccountSetup';
import BusinessSetup from './components/BusinessSetup';
import VerificationDocs from './components/VerificationDocs';
import WepayAccountSetup from './components/WepayAccountSetup';
import FinishedSetup from './components/FinishedSetup';
import YourRentals from './components/YourRentals';
import SubscriptionPayment from './components/SubscriptionPayment';
import VerifyIdentity from './components/VerifyIdentity';
import Upgrade from './components/Upgrade';
import StripeSetup from './components/StripeSetup';
import DocsReview from './components/DocsReview';

let timerId = '';

const OnboardingFullscreen = (props) => {
  const { setShowOnboardingFullscreen, stripeTimer } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, isError, hasFeature, getValue, identify, isIdentified, logout } = useFlagsmith();

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const landlord = useSelector((store) => store.landlord);

  const [yourRentals, setYourRentals] = useState(false);
  const [silaAccountSetup, setSilaAccountSetup] = useState(false);
  const [silaBusinessSetup, setSilaBusinessSetup] = useState(false);
  const [silaVerificationDocs, setSilaVerificationDocs] = useState(false);
  const [verifyIdentity, setVerifyIdentity] = useState(false);
  const [wepayAccountSetup, setWepayAccountSetup] = useState(false);
  const [finixAccountSetup, setFinixAccountSetup] = useState(false);
  const [subscriptionPayment, setSubscriptionPayment] = useState(false);
  const [upgradeServicePlan, setUpgradeServicePlan] = useState(false);
  const [finishedSetup, setFinishedSetup] = useState(false);
  const [plaidToken, setPlaidToken] = useState(null);
  const [showStripeSetup, setShowStripeSetup] = useState(false);
  const [showDocsReview, setShowDocsReview] = useState(false);

  const [silaAccountSetupValues, setSilaAccountSetupValues] = useState(null);
  const [identityValues, setIdentityValues] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [defaultPropertyGroup, setDefaultPropertyGroup] = useState(null);
  const [groupOnboardingStatus, setGroupOnboardingStatus] = useState(null);
  const [boardingSubmitted, setBoardingSubmitted] = useState(false);
  const defaultPropertyGroupId = useRef(null);

  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  const [userOldSp, setUserOldSp] = useState(null);

  const [upsellSp, setUpsellSp] = useState(null);

  // Needs to handle wepay and sila

  const onSuccess = async (token, metadata) => {
    try {
      setProcessing(true);
      const plaid = {
        token,
        metadata,
      };

      await createPaymentMethodV3(cognitoToken, plaid, 'SilaStripe', 'landlord', defaultPropertyGroupId.current);
      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, defaultPropertyGroupId.current);
      setGroupOnboardingStatus(groupStatus);
      setProcessing(false);

      setShowStripeSetup(true);

      const flowId = getValue('onboarding-flow');

      console.log('Flow id ', flowId);
    } catch (error) {
      console.log(error);
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
      setProcessing(false);
      setShowOnboardingFullscreen(false);
    }
  };

  const onExit = (error, metadata) => {
    dispatch(displayAlertAction('', 'warning', 'Onboarding incomplete.', true, 5000));
    setProcessing(false);
    setShowOnboardingFullscreen(false);
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
    onExit,
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  useEffect(() => {
    if (!isLoading) {
      if (!isIdentified) identify(session.userData.id);
    }
  }, [isLoading, identify]);

  const fetchData = async () => {
    try {
      setProcessing(true);

      let boardingSub = false;

      if (isOAuthRedirect) {
        setPlaidToken(localStorage.getItem('link_token'));
      }
      {
        if (session.userData.paymentGateway !== 'Wepay') {
          const plaidOauthToken = await getPlaidOauthLinkToken(cognitoToken);
          setPlaidToken(plaidOauthToken.linkToken);
          // store link_token temporarily in case of OAuth redirect
          localStorage.setItem('link_token', plaidOauthToken.linkToken);
        }
      }

      const defPg = await getPropertyGroups(cognitoToken, 1, 10, '&default=1');

      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, +defPg.items[0].id);
      setDefaultPropertyGroup(defPg.items);

      // const mockStatus = {
      //   ach: {
      //     silaSettlement: null,
      //     state: 'PASSED',
      //   },
      //   creditCard: {
      //     chargesEnabled: false,
      //     payoutsEnabled: false,
      //     settlement: 'STRIPE TEST BANK ending in 6789',
      //   },
      // };
      setGroupOnboardingStatus(groupStatus);

      defaultPropertyGroupId.current = +defPg.items[0].id;
      if (session.userData.paymentGateway === 'SilaStripe') {
        try {
          await getPropertyGroupBoardingSubmit(cognitoToken, defaultPropertyGroupId.current);
          boardingSub = true;
          setBoardingSubmitted(true);
        } catch (e) {
          setBoardingSubmitted(false);
          // setSilaAccountSetup(true);
        }
      }

      if (session.userData.paymentGateway === 'Wepay') {
        if (groupStatus.wepayRegistrationState !== 'finished') setWepayAccountSetup(true);
      }
      if (session.userData.paymentGateway === 'Wepay') {
        if (groupStatus.wepayRegistrationState === 'finished') {
          setFinishedSetup(true);
        }
      }
      if (session.userData.paymentGateway === 'SilaStripe') {
        if (!boardingSub) setYourRentals(true);
        if (
          groupStatus.ach.state === 'REGISTERED' ||
          groupStatus.ach.state === 'REGISTER_ERROR' ||
          groupStatus.ach.state === 'FAILED' ||
          groupStatus.ach.state === 'PASSED'
        ) {
          if (groupStatus.ach.silaSettlement) {
            setFinishedSetup(true);
          }
        }

        if (
          ((groupStatus.ach.state === 'DOCUMENTS_REQUIRED' || groupStatus.ach.state === 'REVIEW') &&
            groupStatus.creditCard.settlement &&
            groupStatus.creditCard.chargesEnabled) ||
          ((groupStatus.ach.state === 'DOCUMENTS_REQUIRED' || groupStatus.ach.state === 'REVIEW') &&
            !groupStatus.creditCard.optIn) ||
          ((groupStatus.ach.state === 'DOCUMENTS_REQUIRED' || groupStatus.ach.state === 'REVIEW') &&
            groupStatus.creditCard.settlement &&
            (groupStatus.creditCard.state === 'PENDING' || groupStatus.creditCard.state === 'ACTION_REQUIRED'))
        ) {
          setSilaVerificationDocs(true);
        }

        if (
          (groupStatus.ach.state === 'DOCUMENTS_RECEIVED' &&
            groupStatus.creditCard.settlement &&
            groupStatus.creditCard.chargesEnabled) ||
          (groupStatus.ach.state === 'DOCUMENTS_RECEIVED' && !groupStatus.creditCard.optIn) ||
          (groupStatus.ach.state === 'DOCUMENTS_RECEIVED' &&
            groupStatus.creditCard.settlement &&
            (groupStatus.creditCard.state === 'PENDING' || groupStatus.creditCard.state === 'ACTION_REQUIRED'))
        ) {
          setShowDocsReview(true);
        }
      }

      setProcessing(false);
    } catch (e) {
      console.log(e);
      setProcessing(false);
    }
  };

  const handleClose = () => {
    if (stripeTimer.current) clearInterval(stripeTimer.current);
    setShowOnboardingFullscreen(false);
    history.push('/landlord');
  };

  const checkLandlordStatus = (setLoading1, setDisableButton1, setDisableButton2, displayAlert) => {
    try {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        const result = await getPropertyGroupOnboardingStatus(
          session.cognitoTokens.idToken.jwtToken,
          landlord.defaultPropertyGroupId
        );
        // console.log(result.wepayRegistrationState);

        if (result.wepayRegistrationState === 'new2-step2') {
          setLoading1(false);
          setDisableButton1(true);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.wepayRegistrationState === 'action-required') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
        }
        if (result.wepayRegistrationState === 'error') {
          setLoading1(false);
          setDisableButton1(false);
          setDisableButton2(false);
          clearInterval(timerId);
          displayAlert('There was an error with your request', 'warning');
        }
      }, 2000);
    } catch (e) {
      if (timerId) clearInterval(timerId);
      setLoading1(false);
      console.log(e);
      displayAlert('There was an error with your request', 'warning');
    }
  };
  useEffect(() => {
    // session.userData.paymentGateway = 'SilaStripe';
    // session.userData.state = 'active';
    fetchData(); // comment out this line to manually enable various boarding panels
  }, []);

  useEffect(() => {
    if (ready) {
      if (boardingSubmitted && session.userData.paymentGateway === 'SilaStripe') {
        // and if ach is null
        if (
          groupOnboardingStatus.ach.silaSettlement &&
          !groupOnboardingStatus.creditCard.chargesEnabled &&
          !groupOnboardingStatus.creditCard.payoutsEnabled &&
          !groupOnboardingStatus.creditCard.settlement &&
          groupOnboardingStatus.creditCard.optIn &&
          boardingSubmitted
        ) {
          setShowStripeSetup(true);
        }
        if (
          (!groupOnboardingStatus.ach.silaSettlement &&
            !groupOnboardingStatus.creditCard.chargesEnabled &&
            boardingSubmitted) ||
          (!groupOnboardingStatus.ach.silaSettlement &&
            !groupOnboardingStatus.creditCard.payoutsEnabled &&
            boardingSubmitted) ||
          (!groupOnboardingStatus.ach.silaSettlement &&
            !groupOnboardingStatus.creditCard.settlement &&
            boardingSubmitted) ||
          (!groupOnboardingStatus.ach.silaSettlement &&
            groupOnboardingStatus.creditCard.settlement &&
            boardingSubmitted)
        ) {
          open();
        }
      }
    }
  }, [ready, open, boardingSubmitted, groupOnboardingStatus]);

  return (
    <StyledOverlay>
      <StyledContainer>
        <StyledCloseOutlined onClick={handleClose} data-testid="closeOnboardingTest" />
        {processing && <Spin size="large" />}
        <AnimatePresence exitBeforeEnter>
          {yourRentals && (
            <YourRentals
              key="your-rentals"
              setUpsellSp={setUpsellSp}
              setUpgradeServicePlan={setUpgradeServicePlan}
              setYourRentals={setYourRentals}
              setSubscriptionPayment={setSubscriptionPayment}
              setSilaAccountSetup={setSilaAccountSetup}
              setShowOnboardingFullscreen={setShowOnboardingFullscreen}
            />
          )}
          {upgradeServicePlan && (
            <Upgrade
              key="upgrade"
              setSubscriptionPayment={setSubscriptionPayment}
              setUpgradeServicePlan={setUpgradeServicePlan}
              setSilaAccountSetup={setSilaAccountSetup}
              upsellSp={upsellSp}
              setUserOldSp={setUserOldSp}
              setFinishedSetup={setFinishedSetup}
              setShowOnboardingFullscreen={setShowOnboardingFullscreen}
            />
          )}
          {subscriptionPayment && (
            <SubscriptionPayment
              key="subscription-payment"
              setSilaAccountSetup={setSilaAccountSetup}
              setSubscriptionPayment={setSubscriptionPayment}
            />
          )}

          {silaAccountSetup && (
            <AccountSetup
              key="account-setup"
              setSilaAccountSetup={setSilaAccountSetup}
              open={open}
              setSilaBusinessSetup={setSilaBusinessSetup}
              setSilaAccountSetupValues={setSilaAccountSetupValues}
              silaAccountSetupValues={silaAccountSetupValues}
              setVerifyIdentity={setVerifyIdentity}
              defaultPropertyGroup={defaultPropertyGroup}
              groupOnboardingStatus={groupOnboardingStatus}
            />
          )}

          {verifyIdentity && (
            <VerifyIdentity
              key="verify-identity"
              setSilaAccountSetup={setSilaAccountSetup}
              setVerifyIdentity={setVerifyIdentity}
              setSilaBusinessSetup={setSilaBusinessSetup}
              silaAccountSetupValues={silaAccountSetupValues}
              setIdentityValues={setIdentityValues}
              identityValues={identityValues}
              setSilaVerificationDocs={setSilaVerificationDocs}
              defaultPropertyGroup={defaultPropertyGroup}
              groupOnboardingStatus={groupOnboardingStatus}
              open={open}
              setShowStripeSetup={setShowStripeSetup}
            />
          )}

          {silaBusinessSetup && (
            <BusinessSetup
              key="business-setup"
              setSilaAccountSetup={setSilaAccountSetup}
              setSilaBusinessSetup={setSilaBusinessSetup}
              open={open}
              silaAccountSetupValues={silaAccountSetupValues}
              setSilaVerificationDocs={setSilaVerificationDocs}
              identityValues={identityValues}
              setVerifyIdentity={setVerifyIdentity}
              defaultPropertyGroup={defaultPropertyGroup}
              groupOnboardingStatus={groupOnboardingStatus}
              setShowStripeSetup={setShowStripeSetup}
            />
          )}
          {silaVerificationDocs && (
            <VerificationDocs
              key="verification-docs"
              setSilaVerificationDocs={setSilaVerificationDocs}
              setShowOnboardingFullscreen={setShowOnboardingFullscreen}
              open={open}
              groupOnboardingStatus={groupOnboardingStatus}
              defaultPropertyGroup={defaultPropertyGroup}
              setShowDocsReview={setShowDocsReview}
              defaultPropertyGroupId={defaultPropertyGroupId}
            />
          )}

          {wepayAccountSetup && (
            <WepayAccountSetup
              key="wepay-account-setup"
              checkLandlordStatus={checkLandlordStatus}
              setWepayAccountSetup={setWepayAccountSetup}
              setFinishedSetup={setFinishedSetup}
              groupOnboardingStatus={groupOnboardingStatus}
              defaultPropertyGroup={defaultPropertyGroup}
            />
          )}
          {showStripeSetup && (
            <StripeSetup
              key="stripe-setup"
              defaultPropertyGroup={defaultPropertyGroup}
              stripeTimer={stripeTimer}
              open={open}
              setShowStripeSetup={setShowStripeSetup}
              setFinishedSetup={setFinishedSetup}
              groupOnboardingStatus={groupOnboardingStatus}
              setGroupOnboardingStatus={setGroupOnboardingStatus}
              setSilaVerificationDocs={setSilaVerificationDocs}
            />
          )}
          {showDocsReview && (
            <DocsReview
              key="docs-review"
              setShowDocsReview={setShowDocsReview}
              setShowOnboardingFullscreen={setShowOnboardingFullscreen}
            />
          )}
          {finishedSetup && (
            <FinishedSetup
              key="finished"
              setFinishedSetup={setFinishedSetup}
              setShowOnboardingFullscreen={setShowOnboardingFullscreen}
              groupOnboardingStatus={groupOnboardingStatus}
            />
          )}
          {(yourRentals ||
            upgradeServicePlan ||
            subscriptionPayment ||
            silaAccountSetup ||
            verifyIdentity ||
            wepayAccountSetup ||
            silaVerificationDocs ||
            silaBusinessSetup) && (
            <>
              <Space vertical={15} />
              <Text color="violet" size={12} onClick={() => handleClose()} underline pointer>
                I&apos;ll do this later
              </Text>
            </>
          )}
        </AnimatePresence>
      </StyledContainer>
    </StyledOverlay>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 492px;
  margin: 0 auto;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  font-size: 25px;
  color: dimgray;
  position: absolute;
  top: 25px;
  left: 25px;
  cursor: pointer;
  z-index: 50;
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 10px;
  }
`;

const StyledOverlay = styled.div`
  background: rgba(247, 247, 244, 1);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  animation: fadeIn linear 0.1s;
  -webkit-animation: fadeIn linear 0.2s;
  -moz-animation: fadeIn linear 0.2s;
  -o-animation: fadeIn linear 0.2s;
  -ms-animation: fadeIn linear 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export default OnboardingFullscreen;
