import { formVariants } from 'animations/variants';
import { Card, Checkbox, Col, Row, theme, Space as AntSpace, Button } from 'antd';
import Text from 'components/Text/Text';
import React from 'react';
import MigrationServicePlans from './MigrationServicePlans';
import { MotionMainContent } from '../WepayOffboarding.styles';
import Space from 'components/Space/Space';
import { useDispatch, useSelector } from 'react-redux';
import ServicePlanPricing from './ServicePlanPricing';
import { upgradeDowngradeServicePlan } from 'services/api/landlordApi/create';
import { getServicePlanAction } from 'store/actions/sessionActions';
import { getBillingDetails, getServicePlans } from 'services/api/landlordApi/read';
import { servicePlanMapper } from 'utils/servicePlans';
import { sendSibEvent } from 'utils/sendInBlue';

function SubscriptionSwitcher(props) {
  // console.log('[SubscriptionSwitcher.js]', props);
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const [hasAccepted, setHasAccepted] = React.useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
  });
  const [isSwitching, setIsSwitching] = React.useState(false);
  const servicePlan = useSelector((store) => store.session?.userData?.servicePlan);
  const [allServicePlans, setAllServicePlans] = React.useState([]);
  const [newDefaultServicePlan, setNewDefaultServicePlan] = React.useState({});
  const allAccepted = React.useMemo(() => Object.values(hasAccepted).every((v) => v), [hasAccepted]);
  const isMigrated = React.useMemo(() => servicePlan?.processor?.id === 4, [servicePlan]);

  const fetchAndSetServicePlans = React.useCallback(async () => {
    try {
      const allServicePlans = await getServicePlans();
      setAllServicePlans(allServicePlans);

      const { id } = servicePlanMapper[+servicePlan.id].find((sp) => sp.default);
      const newServicePlan = allServicePlans.find((sp) => sp.id === id);
      setNewDefaultServicePlan(newServicePlan);
      // console.log('[SubscriptionSwitcher.js] ƒ fetchAndSetServicePlans', allServicePlans, newServicePlan);
    } catch (err) {}
  }, [servicePlan.id]);

  const handleSubscriptionSwitch = async (sp) => {
    try {
      setIsSwitching(true);
      await upgradeDowngradeServicePlan(
        cognitoToken,
        {
          processorId: session.userData.servicePlan.processor.id,
          servicePlanId: sp.id,
        },
        +session.userData.id
      );

      const sibProperties = {
        EMAIL: session.userData.email,
        FIRSTNAME: session.userData.firstName,
        LASTNAME: session.userData.lastName,
      };

      const sibEventData = {
        data: { migration_type: 'default' },
      };

      await sendSibEvent('migration_completed', sibProperties, sibEventData);
      // const currentServicePlan = await getServicePlanData(cognitoToken);
      // setUserServicePlan(currentServicePlan);
      dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));

      if (sp.subscriptionFee > 0) {
        const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);
        // console.log('plansBillingRes', plansBillingRes);
        if (!plansBillingRes.paymentMethod?.paymentMethod) {
          props.setDrawerVisible(true);
          props.setVisiblePanel('billing');
        }
      }
      props.setCurrentStep(1);
      setIsSwitching(false);
    } catch (err) {
      console.error(err);
      setIsSwitching(false);
    }
  };

  React.useEffect(() => {
    fetchAndSetServicePlans();
  }, [fetchAndSetServicePlans]);

  return (
    <MotionMainContent
      bordercolor={token.colorBorderSecondary}
      key="subscription"
      variants={formVariants}
      initial="hide"
      animate="show"
      exit="leave"
      data-testid="subscriptionSwitcherContainerTest"
    >
      <Text color="black" size={18} as="div" strong style={{ marginBottom: '12px' }}>
        Your Current Subscription
      </Text>
      <Row gutter={[24, 24]}>
        <Col sm={24} md={12}>
          <Card
            title={
              <Text color="violet2" strong size={18}>
                {servicePlan?.name}
              </Text>
            }
          >
            <ServicePlanPricing item={servicePlan} showTransactionPricing />
          </Card>
        </Col>
        <Col sm={24} md={12}>
          <Text strong color="black">
            When you update:
          </Text>
          <AntSpace size={12} direction="vertical">
            <Checkbox
              onChange={(e) => setHasAccepted((prev) => ({ ...prev, check1: e.target.checked }))}
              disabled={isMigrated}
            >
              <Text color="black">
                Your ability to accept payments will be paused but your PayRent account remains active.
              </Text>
            </Checkbox>
            <Checkbox
              onChange={(e) => setHasAccepted((prev) => ({ ...prev, check2: e.target.checked }))}
              disabled={isMigrated}
            >
              <Text color="black">All in-process payments will be completed and settled to your bank.</Text>
            </Checkbox>
            <Checkbox
              onChange={(e) => setHasAccepted((prev) => ({ ...prev, check3: e.target.checked }))}
              disabled={isMigrated}
            >
              <Text color="black">
                You will not be able to accept payments until your new payment account is set-up and approved.
              </Text>
            </Checkbox>
            <Checkbox
              onChange={(e) => setHasAccepted((prev) => ({ ...prev, check4: e.target.checked }))}
              disabled={isMigrated}
            >
              <Text color="black">
                Tenant payment methods and scheduled payments will need to be re-created. (We will notify them.)
              </Text>
            </Checkbox>
            <Button
              disabled={!allAccepted}
              block
              type="primary"
              size="large"
              onClick={() => handleSubscriptionSwitch(newDefaultServicePlan)}
              style={{ marginBottom: '0px' }}
              loading={isSwitching}
            >
              Update
            </Button>
          </AntSpace>
        </Col>
      </Row>
      <Space vertical={24} />
      <MigrationServicePlans hasAccepted={allAccepted} allServicePlans={allServicePlans} {...props} />
    </MotionMainContent>
  );
}

export default SubscriptionSwitcher;
