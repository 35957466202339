import React, { useEffect } from 'react';
import { Input, Form, Row, Col, Radio, Space, Select, DatePicker } from 'antd';
import { layout, rowStyle } from './layout';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { validatePhone, validateTaxId, validateUrl } from './validators';
import { checkFormReady, stateOptions } from './helpers';
import dayjs from 'dayjs';
import CurrencyInput from 'components/uielements/CurrencyInput/CurrencyInput';
import {
  AuditOutlined,
  GlobalOutlined,
  SafetyCertificateOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import PhoneInput from 'components/PhoneInput/PhoneInput';

const { Item, useForm } = Form;
const { Button } = Radio;

const StyledRadioButton = styled(Button)`
  width: 96px !important;
  font-size: 12px !important;
  height: 64px !important;
  padding: 12px 0 !important;
  border-radius: 8px !important;
  line-height: 22px !important;
`;

export const requiredFields = [
  'entity_business_type',
  'entity_business_name',
  'entity_business_address_line1',
  'entity_business_address_city',
  'entity_business_address_region',
  'entity_business_address_postal_code',
  'entity_business_phone',
  'additional_underwriting_data_business_description',
  'additional_underwriting_data_monthly_rental_income',
  'entity_business_tax_id',
  'entity_incorporation_date',
  'entity_ownership_type',
];

const solePropLabels = {
  entity_business_name: {
    default: 'Legal Business Name',
    alternate: 'Sole Proprietor Name',
  },
  entity_business_tax_id: {
    default: 'EIN',
    alternate: 'SSN or ITIN',
  },
};

export default function BusinessProfileForm(props) {
  const [form] = useForm();
  const { handleChangedValues, formValues, setFormReady } = props;

  useEffect(() => {
    // console.log('[BusinessProfileForm.js] useEffect on formValues', formValues);
    form.setFieldsValue(formValues);
  }, [formValues, form]);

  return (
    <Form
      data-testid="businessProfileFormTest"
      name="business-profile-form"
      layout="vertical"
      form={form}
      initialValues={formValues}
      onValuesChange={async (changedValues, allValues) => {
        handleChangedValues(changedValues, allValues);
        setTimeout(() => {
          const errors = form.getFieldsError();
          // console.log('ƒ onValuesChange errors', errors);
          setFormReady((prevFormReady) => ({
            ...prevFormReady,
            0: checkFormReady('business_profile_form', allValues, errors),
          }));
        });
      }}
      requiredMark={'optional'}
    >
      <Row>
        <Col span={24}>
          <Item
            label="Select Business Type"
            name="entity_business_type"
            rules={[{ required: requiredFields.includes('entity_business_type') }]}
            hasFeedback
            data-testid="businessTypeTest"
          >
            <Radio.Group buttonStyle="solid" size="large" data-testid="businessTypeRadioTest">
              <Space direction={isMobile && 'vertical'} style={{ flexWrap: 'wrap' }} size={8}>
                <StyledRadioButton className="radio-box-lg" value="INDIVIDUAL_SOLE_PROPRIETORSHIP">
                  <UserOutlined style={{ fontSize: '24px' }} />
                  <div>Individual</div>
                </StyledRadioButton>
                <StyledRadioButton className="radio-box-lg" value="LIMITED_LIABILITY_COMPANY">
                  <SafetyCertificateOutlined style={{ fontSize: '24px' }} />
                  <div>LLC</div>
                </StyledRadioButton>
                <StyledRadioButton className="radio-box-lg" value="CORPORATION">
                  <GlobalOutlined style={{ fontSize: '24px' }} />
                  <div>Corporation</div>
                </StyledRadioButton>
                <StyledRadioButton className="radio-box-lg" value="PARTNERSHIP">
                  <TeamOutlined style={{ fontSize: '24px' }} />
                  <div>Partnership</div>
                </StyledRadioButton>
                <StyledRadioButton className="radio-box-lg" value="ASSOCIATION_ESTATE_TRUST">
                  <AuditOutlined style={{ fontSize: '24px' }} />
                  <div>Trust</div>
                </StyledRadioButton>
              </Space>
            </Radio.Group>
          </Item>
        </Col>
      </Row>
      <Row gutter={layout.rowGutter} style={rowStyle}>
        <Col {...layout.twoCol}>
          <Item
            label={
              formValues.entity_business_type === 'INDIVIDUAL_SOLE_PROPRIETORSHIP'
                ? solePropLabels.entity_business_name.alternate
                : solePropLabels.entity_business_name.default
            }
            name="entity_business_name"
            rules={[
              { required: requiredFields.includes('entity_business_name') },
              { max: 120 },
              { pattern: /^[a-zA-Z0-9\s]+$/, message: 'Only letters, numbers and spaces allowed' },
            ]}
            hasFeedback
            data-testid="businessNameTest"
          >
            <Input data-testid="businessNameInputTest" />
          </Item>
        </Col>
        <Col {...layout.twoCol}>
          <Item
            label="DBA"
            name="entity_doing_business_as"
            data-testid="dbaTest"
            tooltip="The name under which the business operates. If the business is a sole proprietorship, this is the owner's name."
            rules={[
              {
                required: requiredFields.includes('entity_doing_business_as'),
              },
              { max: 60 },
              { pattern: /^[a-zA-Z0-9\s]+$/, message: 'Only letters, numbers and spaces allowed' },
            ]}
            hasFeedback
          >
            <Input data-testid="dbaInputTest" />
          </Item>
        </Col>
        <Col {...layout.twoCol}>
          <Item
            label="Business Street Address"
            name="entity_business_address_line1"
            rules={[
              {
                required: requiredFields.includes('entity_business_address_line1'),
              },
              { max: 35, message: 'Max 35 characters' },
              {
                message: 'PO Boxes not allowed',
                validator: (_, value) => {
                  if (
                    value &&
                    (value.toLowerCase().includes('po box') ||
                      value.toLowerCase().includes('p.o. box') ||
                      value.toLowerCase().includes('p o box'))
                  ) {
                    return Promise.reject('PO Boxes not allowed');
                  }
                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <Input />
          </Item>
        </Col>
        <Col {...layout.twoCol}>
          <Item
            label="Address Line 2"
            name="entity_business_address_line2"
            rules={[
              {
                required: requiredFields.includes('entity_business_address_line2'),
              },
              { max: 35 },
            ]}
            hasFeedback
          >
            <Input />
          </Item>
        </Col>
        <Col {...layout.twoCol}>
          <Item
            label="City"
            name="entity_business_address_city"
            rules={[
              {
                required: requiredFields.includes('entity_business_address_city'),
              },

              { max: 20 },
            ]}
            hasFeedback
          >
            <Input />
          </Item>
        </Col>
        <Col {...layout.fourCol}>
          <Item
            label="State"
            name="entity_business_address_region"
            rules={[
              {
                required: requiredFields.includes('entity_business_address_region'),
              },
            ]}
            hasFeedback
          >
            <Select options={stateOptions} data-testid="stateSelectTest" />
          </Item>
        </Col>
        <Col {...layout.fourCol}>
          <Item
            label="Zip"
            name="entity_business_address_postal_code"
            rules={[
              {
                required: requiredFields.includes('entity_business_address_postal_code'),
              },

              { max: 5 },
              { min: 5 },
            ]}
            hasFeedback
          >
            <Input />
          </Item>
        </Col>
        <Item name="entity_business_address_country">
          <Input type="hidden" />
        </Item>
        <Col {...layout.twoCol}>
          <Item
            label="Business Phone"
            name="entity_business_phone"
            rules={[{ required: requiredFields.includes('entity_business_phone') }, validatePhone]}
            hasFeedback
          >
            <PhoneInput />
          </Item>
        </Col>
        <Col {...layout.twoCol}>
          <Item
            label="Rental Listing Link"
            name="entity_url"
            data-testid="businessWebsiteTest"
            rules={[{ required: requiredFields.includes('entity_url') }, validateUrl, { max: 100 }]}
            hasFeedback
          >
            <Input
              prefix="https://"
              placeholder="www.zillow.com/apartments/5Xyrgs"
              data-testid="businessWebsiteInputTest"
            />
          </Item>
        </Col>
        <Col {...layout.oneCol}>
          <Item
            label="Business Description"
            name="additional_underwriting_data_business_description"
            rules={[
              {
                required: requiredFields.includes('additional_underwriting_data_business_description'),
              },
              { max: 200 },
              {
                message: 'Description cannot begin with a number',
                validator: (_, value) => {
                  if (value && /^\d/.test(value)) {
                    return Promise.reject('Description cannot begin with a number');
                  }
                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <Input />
          </Item>
        </Col>
        <Col {...layout.fourCol}>
          <Item
            label="Income"
            name="additional_underwriting_data_monthly_rental_income"
            rules={[
              {
                required: requiredFields.includes('additional_underwriting_data_monthly_rental_income'),
              },
            ]}
            tooltip="Monthly rental income rounded to the nearest dollar (e.g. 1000)"
            hasFeedback
          >
            <CurrencyInput placeholder="10000" />
          </Item>
        </Col>
        <Col {...layout.fourCol}>
          <Item
            label={
              formValues.entity_business_type === 'INDIVIDUAL_SOLE_PROPRIETORSHIP'
                ? solePropLabels.entity_business_tax_id.alternate
                : solePropLabels.entity_business_tax_id.default
            }
            name="entity_business_tax_id"
            data-testid="taxIdTest"
            rules={[
              { required: requiredFields.includes('entity_business_tax_id') },
              { pattern: /^\d{9}$/, message: 'Must be a 9-digit number' },
              validateTaxId,
            ]}
            hasFeedback
          >
            <Input placeholder="987654321" data-testid="taxIdInputTest" />
          </Item>
        </Col>
        <Col {...layout.fourCol}>
          <Item
            label="Established"
            name="entity_incorporation_date"
            rules={[{ required: requiredFields.includes('entity_incorporation_date') }]}
            hasFeedback
          >
            <DatePicker
              data-testid="incorporationDateTest"
              style={{ width: '100%' }}
              format={'MM/DD/YYYY'}
              showNow={false}
              disabledDate={(current) => {
                // Can not select days after today
                return current && current > dayjs();
              }}
            />
          </Item>
        </Col>
        <Col {...layout.fourCol}>
          <Item
            label="Ownership"
            name="entity_ownership_type"
            data-testid="ownershipTypeTest"
            rules={[{ required: requiredFields.includes('entity_ownership_type') }, { max: 7 }]}
            hasFeedback
          >
            <Select
              disabled={formValues.entity_business_type === 'INDIVIDUAL_SOLE_PROPRIETORSHIP'}
              options={[{ value: 'PRIVATE' }, { value: 'PUBLIC' }]}
              data-testid="ownershipTypeSelectTest"
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
}
