export const validatePhone = () => ({
  validator(_, value) {
    console.log('validatePhone', value);
    const regex = /^[2-9]{1}[0-9]{2}\s[0-9]{3}\s[0-9]{4}$/g;
    if (value && value.match(regex)) {
      value.replace(/(\+1)?[^\d+]+/g, '');
      return Promise.resolve();
    } else if (value === undefined || value === null || value === '') {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('invalid phone number'));
    }
  },
});

export const validateUrl = () => ({
  validator(_, value) {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/g;
    if ((value && value.match(regex)) || !value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid URL'));
  },
});

export const validateEqualValue = (form, field) => ({
  validator(_, value) {
    // console.log('validateEqualValue', form, field, value);
    if (value === form.getFieldValue(field)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Fields do not match'));
  },
});

export const validateTaxId = () => ({
  validator(_, value) {
    // regex to check of 9 digits of same number
    const regex = /^(.)\1{8}$/g;
    if (value === undefined || value === null || value === '') {
      return Promise.resolve();
    } else if (value && !value.match(regex)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('Invalid tax id'));
    }
  },
});
