export const formVariants = {
  hide: { opacity: 0 },
  show: { opacity: 1, transition: { duration: 0.3 } },
  leave: { opacity: 0 },
};

export const tabVariants = {
  hide: { opacity: 0 },
  show: { opacity: 1 },
  leave: { opacity: 0 },
};

export const alertVariants = {
  hide: { opacity: 0 },
  show: { opacity: 1 },
  leave: { opacity: 0 },
};
