import React from 'react';
import Text from 'components/Text';
import { Descriptions } from 'antd';
import { calculateLandlordFee } from 'resources/helpers';

const TransactionDescription = ({ record }) => {
  // console.log('[TransactionDescription.js]', record);

  const items = [
    {
      key: '1',
      label: <Text color="black">Payment Method</Text>,
      children: <Text color="black">{record.method || 'N/A'}</Text>,
    },
    {
      key: '2',
      label: <Text color="black">Your Fee</Text>,
      children: (
        <Text color="black">
          {record.refType === 'Payment'
            ? `$${calculateLandlordFee(record.refType, record.fee, record.landlordAbsorbPercent)}`
            : 'N/A'}
        </Text>
      ),
    },
    {
      key: '3',
      label: <Text color="black">Net Settlement</Text>,
      children: <Text color="black">{record.netSettlement || 'N/A'}</Text>,
    },
    {
      key: '4',
      label: <Text color="black">Settlement Date</Text>,
      children: <Text color="black">{record.settlementDate || 'N/A'}</Text>,
    },
    {
      key: '5',
      label: <Text color="black">Memo</Text>,
      children: <Text color="black">{record.memo}</Text>,
      span: {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 4,
        xl: 4,
        xxl: 4,
      },
    },
  ];

  return (
    <Descriptions
      items={items}
      bordered={true}
      column={{
        xs: 1,
        sm: 2,
        md: 2,
        lg: 4,
        xl: 4,
        xxl: 4,
      }}
    />
  );
};

export default TransactionDescription;
