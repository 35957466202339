import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Space as AntSpace } from 'antd';
import styled from 'styled-components';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { createUpdateBillingDetails } from 'services/api/landlordApi/create';
import PanelFooter from 'components/Panel/PanelFooter';
import poweredByStripe from 'assets/images/powered-stripe.png';
import Space from 'components/Space';
import Button from 'components/Button';

const Title = styled.h1`
  font-family: Poppins;
  font-size: 42px;
  text-align: center;
  color: #122c34;
  margin-bottom: 10px !important;
`;

const Subtitle = styled.div`
  width: 320px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  color: #122c34;
`;

const BillingPanelForm = (props) => {
  // console.log('[BillingPanelForm.js] props', props);
  const { displayAlert, resetPanel } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disableCancelButton, setCancelDisableButton] = useState(false);
  const [disableFinishButton, setDisableFinishButton] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const onFinish = async (values) => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setLoading(true);
      setCancelDisableButton(true);
      const { error, setupIntent } = await stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'https://my-site.com/account/payments/setup-complete',
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (e.g., payment
        // details incomplete)

        const errorObject = {
          message: error.message,
        };

        throw errorObject;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

      await createUpdateBillingDetails(
        cognitoToken,
        session.userData.id,
        session.userData,
        setupIntent.payment_method
      );

      displayAlert('Billing details and payment method updated', 'success');
      setLoading(false);
      setDisableFinishButton(true);
      setTimeout(() => {
        setLoading(false);
        resetPanel();
      }, 4000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      displayAlert(
        error.data?.errors[0].detail || error?.message || 'There was an error with you request.',
        'error'
      );
    }
  };

  return (
    <>
      <StyledForm
        form={form}
        name="plansBillingDetails"
        data-testid="plansBillingDetailsFormTest"
        onFinish={onFinish}
        onFinishFailed={() => {}}
        initialValues={{ paymentMethod: 'card' }}
      >
        <Title data-testid="titleTest">Account Settings</Title>
        <Subtitle data-testid="subtitleTest">Plans and Billing</Subtitle>
        <Space vertical={15} />
        <FormContentContainer className="content-container">
          <div style={{ minHeight: '360px' }}>
            <PaymentElement />
          </div>
          <Row justify="center" style={{ marginBottom: '10px' }}>
            <img src={poweredByStripe} alt="Powered by Stripe" />
          </Row>
        </FormContentContainer>
        <PanelFooter>
          <Row justify="center">
            <AntSpace size={100}>
              <Button
                outlined
                onClick={() => {
                  resetPanel();
                }}
                data-testid="cancelButton"
                disabled={disableCancelButton}
                size="large"
              >
                CANCEL
              </Button>
              <Button
                color="violet"
                htmlType="submit"
                loading={loading}
                data-testid="finishButtonTest"
                disabled={disableFinishButton}
                size="large"
              >
                FINISH
              </Button>
            </AntSpace>
          </Row>
        </PanelFooter>
      </StyledForm>
    </>
  );
};

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  height: 50px;
  padding: 0px 24px 0px 24px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const Header = styled.div`
  color: white;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 2px;
  font-weight: 600;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 13px;
`;

const StyledDiv = styled.div`
  position: relative;
  min-height: 80px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`;

export default BillingPanelForm;
