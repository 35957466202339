import React from 'react';
import MaskedInput from 'antd-mask-input';

function PhoneInput(props) {
  return (
    <MaskedInput
      className="ant-input ant-input-success"
      placeholder="987 654 3210"
      mask="000 000 0000"
      data-testid="phoneTest"
      prefix="+1"
      style={{ fontSize: '16px' }}
      {...props}
    />
  );
}

export default PhoneInput;
