// @flow
import React, { useState, useEffect } from 'react';
import { Menu, Form } from 'antd';
import Icon, { FileTextFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useFlagsmith } from 'flagsmith-react';

//import './style.scss';

// Icons
import { LogoutOutlined } from '@ant-design/icons';
import { ReactComponent as HomeIcon } from 'assets/drawer-icons/home.svg';
import { ReactComponent as PropertiesIcon } from 'assets/drawer-icons/rental.svg';
import { ReactComponent as TransactionsIcon } from 'assets/drawer-icons/money.svg';
import { ReactComponent as RentersIcon } from 'assets/drawer-icons/renters.svg';
import { ReactComponent as SettingsIcon } from 'assets/drawer-icons/settings.svg';
import { ReactComponent as LifeBuoyIcon } from 'assets/drawer-icons/lifebuoy.svg';
import { ReactComponent as LaunchIcon } from 'assets/drawer-icons/launch.svg';
import { ReactComponent as ApplicationIcon } from 'assets/drawer-icons/application.svg';
import { ReactComponent as FinancingIcon } from 'assets/drawer-icons/fin-menu.svg';
import { useHistory, useLocation } from 'react-router-dom';

import { openFreshChatWidget } from 'utils';
import Drawer from 'components/Drawer/Drawer';
import AccountProfilePanel from 'screens/Landlord/components/AccountProfilePanel/AccountProfilePanel';
import AccountPreferencesPanel from 'screens/Landlord/components/AccountPreferencesPanel/AccountPreferencesPanel';
import PlansBillingsPanel from 'screens/Landlord/components/PlansBillingsPanel/PlansBillingsPanel';
import BillingDetailsPanel from 'screens/Landlord/components/BillingDetailsPanel/BillingDetailsPanel';
import AccountSettingsDrawer from './AccountSettingsDrawer/AccountSettingsDrawer';

const menuStyle = {
  width: 'auto',
  padding: 0,
  backgroundColor: '#122C34',
};

const { SubMenu } = Menu;

const LandLordMenu = (props) => {
  const { setShowOnboardingFullscreen } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const currentSelectedMenu = useSelector((store) => store.global.menu.currentSelectedMenu);
  const { logout, isIdentified } = useFlagsmith();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const [visiblePanel, setVisiblePanel] = useState('');
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [alert, setAlert] = useState({ message: '', type: 'success' });

  const displayAlert = (text, type) => {
    setAlert({
      message: text,
      type,
    });
    setTimeout(() => {
      setAlert({
        message: '',
      });
    }, 3000);
  };

  const resetPanel = () => {
    setDrawerVisible(false);
    setDrawerExpanded(false);
    setVisiblePanel('');
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 576);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/landlord':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'landlord' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      case '/landlord/onboarding':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'onboarding' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      case '/landlord/properties':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'properties' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      case '/landlord/transactions':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'transactions' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      case '/landlord/renters':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'renters' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      case '/landlord/documents':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'documents' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      case '/landlord/screening':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'screening' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      case '/landlord/financing':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'financing' });
        dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: false } });
        break;
      default:
        break;
    }
  }, [location]);

  const handleClick = (e) => {
    let url;

    switch (e.key) {
      case 'landlord':
        url = '/landlord';
        break;
      case 'properties':
        url = '/landlord/properties';
        break;
      case 'transactions':
        url = '/landlord/transactions';
        break;
      case 'renters':
        url = '/landlord/renters';
        break;
      case 'onboarding':
        url = '/landlord/onboarding';
        break;
      case 'documents':
        url = '/landlord/documents';
        break;
      case 'screening':
        url = '/landlord/screening';
        break;
      case 'financing':
        url = '/landlord/financing';
        break;
      case 'help':
        break;
      case 'getStarted':
        break;
      case 'logout':
        if (session.userRole === 'landlord') {
          if (isIdentified) logout();
        }
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'logout' });
        url = '/sign-out';
        break;
      default:
        url = '/landlord';
        break;
    }
    if (e.key !== 'settings1' && e.key !== 'settings2' && e.key !== 'settings3') {
      history.push(url);
    }
    props.closeDrawer(false);
  };

  const [openKeys, setOpenKeys] = useState([]);
  const onOpenChange = (openKeys) => setOpenKeys([...openKeys]);

  const items = [
    {
      key: 'landlord',
      label: <span data-testid="homeTest">Home</span>,
      icon: <Icon size={20} component={HomeIcon} />,
    },
    session.userData?.paymentGateway === 'Finix' && {
      key: 'onboarding',
      icon: <Icon className="hightlight-menu-item" size={20} component={LaunchIcon} />,
      label: (
        <span className="highlight-menu-item" data-testid="quickStartTest">
          Quick Start
        </span>
      ),
    },
    {
      key: 'properties',
      label: <span data-testid="propertiesTest">Properties</span>,
      icon: <Icon size={20} component={PropertiesIcon} />,
    },
    {
      label: <span data-testid="transactionsTest">Transactions</span>,
      key: 'transactions',
      icon: <Icon size={20} component={TransactionsIcon} />,
    },
    {
      icon: <Icon size={20} component={RentersIcon} />,
      label: <span data-testid="rentersTest">Renters</span>,
      key: 'renters',
    },
    {
      icon: <Icon size={20} component={FileTextFilled} />,
      label: (
        <span style={{ display: 'inline-flex' }}>
          <span data-testid="documentsTest">Documents</span>
          <NewLabel className="new-label">NEW!</NewLabel>
        </span>
      ),
      key: 'documents',
    },
    {
      icon: <Icon size={20} component={ApplicationIcon} />,
      label: (
        <span style={{ display: 'inline-flex' }} data-testid="screeningTest">
          Applications
        </span>
      ),
      key: 'screening',
    },
    {
      icon: <Icon size={20} component={FinancingIcon} />,
      label: (
        <span style={{ display: 'inline-flex' }}>
          <span data-testid="reportsTest">Financing</span>
        </span>
      ),
      key: 'financing',
    },
  ];

  const accountMenuItems = [
    session.userData?.paymentGateway === 'Wepay' && {
      key: 'legacy',
      icon: <Icon className="hightlight-menu-item" size={20} component={LaunchIcon} />,
      label: (
        <span className="highlight-menu-item" data-testid="quickStartTest">
          Accept Payments
        </span>
      ),
      onClick: () => setShowOnboardingFullscreen(true),
    },
    {
      key: 'sub1',
      icon: <Icon size={20} component={SettingsIcon} />,
      label: 'Account Settings',
      popupOffset: [0, -50],
      popupClassName: 'pop-up-menu',
      className: 'profileMenuTour',
      children: [
        {
          key: 'settings1',
          label: 'Profile',
          name: 'profile',
          onClick: () => {
            setDrawerVisible(true);
            setVisiblePanel('profile');
          },
        },
        {
          label: 'Preferences',
          key: 'settings2',
          name: 'preferences',
          onClick: () => {
            setDrawerVisible(true);
            setVisiblePanel('preferences');
          },
        },
        {
          label: 'Plans and Billing',
          key: 'settings3',
          name: 'plans',
          onClick: () => {
            setDrawerVisible(true);
            setVisiblePanel('plans');
          },
        },
      ],
    },
    {
      key: 'help',
      icon: <Icon size={20} component={LifeBuoyIcon} />,
      label: <span data-testid="customerTest">Customer Support</span>,
      className: 'customerSupportTour',
      id: 'support_menu_item',
      // onClick: () =>
      //   openFreshChatWidget(
      //     session.userData.id,
      //     session.userData.firstName,
      //     session.userData.email,
      //     session.userData.cognito['custom:landlord'],
      //     session.userData.servicePlan.name
      //   ),
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: <span data-testid="logout">Logout</span>,
    },
  ];

  return (
    <>
      <AccountSettingsDrawer
        visiblePanel={visiblePanel}
        setVisiblePanel={setVisiblePanel}
        drawerVisible={drawerVisible}
        resetPanel={resetPanel}
        displayAlert={displayAlert}
        alert={alert}
        drawerExpanded={drawerExpanded}
      />
      <div
        className="menu-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 180px)',
          justifyContent: 'space-between',
          paddingBottom: '48px',
        }}
      >
        <Menu
          onClick={handleClick}
          theme="dark"
          style={menuStyle}
          selectedKeys={[currentSelectedMenu]}
          mode="inline"
          items={items}
        />

        <Menu
          onClick={handleClick}
          theme="dark"
          style={menuStyle}
          mode="inline"
          items={accountMenuItems}
          selectedKeys={[currentSelectedMenu]}
          forceSubMenuRender
          triggerSubMenuAction="click"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onSelect={() => setOpenKeys([])}
        />
      </div>
    </>
  );
};

const NewLabel = styled.span`
  align-self: center;
  border: 1px solid white;
  padding: 0px 3px 0px 3px;
  border-radius: 5px;
  height: 22px;
  line-height: 21px;
  font-size: 10px;
  font-weight: 700;
  margin-left: 10px;
`;

export default LandLordMenu;
