import { Button, Space } from 'antd';
import ExpandablePanel from 'components/uielements/ExpandablePanel/ExpandablePanel';
import React from 'react';
import CreateMerchantStepper from '../FinixOnboarding/CreateMerchantWizard';
import { motion } from 'framer-motion';
import { formVariants } from 'animations/variants';

const Development = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [drawerExpanded, setDrawerExpanded] = React.useState(false);
  return (
    <div style={{ textAlign: 'center' }}>
      <p>Development Purposes Only</p>
      <Button onClick={() => setShowModal(true)}>Open</Button>
      <CreateMerchantStepper showModal={showModal} setShowModal={setShowModal} />
      <ExpandablePanel
        drawerOpen={drawerVisible}
        setDrawerOpen={setDrawerVisible}
        drawerExpanded={drawerExpanded}
        setDrawerExpanded={setDrawerExpanded}
      >
        {drawerExpanded ? (
          <motion.div key="shrink" initial="hide" animate="show" variants={formVariants}>
            <Button disabled={!drawerExpanded} onClick={() => setDrawerExpanded(false)}>
              Contract
            </Button>
          </motion.div>
        ) : (
          <motion.div key="expand" initial="hide" animate="show" variants={formVariants}>
            <Button disabled={drawerExpanded} type="primary" onClick={() => setDrawerExpanded(true)}>
              Expand
            </Button>
          </motion.div>
        )}
      </ExpandablePanel>
    </div>
  );
};

export default Development;
