import React from 'react';
import { ReactComponent as ArchiveIconSvg } from 'assets/dashboard-icons/achive-3.svg';
import { ReactComponent as ArchiveIconFilledSvg } from 'assets/dashboard-icons/archive-filled.svg';

const ArchiveIcon = (props) => {
  const { handleUpdateItem, buttons } = props;

  return (
    <a>
      {buttons.archived ? (
        <ArchiveIconFilledSvg
          onClick={() => {
            setTimeout(() => {
              handleUpdateItem(buttons.id, 'unarchive');
            }, 250);
          }}
        />
      ) : (
        <ArchiveIconSvg
          onClick={() => {
            setTimeout(() => {
              handleUpdateItem(buttons.id, 'archive');
            }, 250);
          }}
        />
      )}
    </a>
  );
};

export default ArchiveIcon;
