import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space } from 'antd';
import { CalculatorOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { deleteParentProperty } from 'services/api/landlordApi/delete';
import { displayAlertAction } from 'store/actions/globalActions';
import { getPropertiesListAction } from 'store/actions/landlordActions';
import MoreMenu from 'components/MoreMenu/MoreMenu';
import Text from 'components/Text/Text';

const MorePropertyActions = (props) => {
  const { id, properties, fetchProperties, page, parentProperty } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((state) => state.session);
  const [loading, setLoading] = useState(false);
  const [deleteTransactionText, setDeleteTransactionText] = useState({
    text: 'Delete Property',
    color: 'inherit',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: true,
  });

  const resetDropdown = () => {
    setDeleteTransactionText({
      text: 'Delete Property',
      style: { color: 'inherit', fontWeight: 'inherit' },
      action: false,
    });
  };

  const handleDeleteProperty = async (propId) => {
    try {
      if (!deleteTransactionText.action) {
        return setDeleteTransactionText({
          text: 'Really Delete?',
          color: '#CB48B7',
          style: { color: '#CB48B7', fontWeight: 500 },
          action: true,
        });
      }
      setLoading(true);
      await deleteParentProperty(session.cognitoTokens.idToken.jwtToken, propId);
      await fetchProperties(properties.items[0].propertyGroupId, page, false);
      dispatch(getPropertiesListAction(session.cognitoTokens.idToken.jwtToken, 1, 10000, '', ''));
      setLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].detail || 'Request failed.', true, 5000));
      setLoading(false);
    }
  };

  const handleViewTransactionHistory = () => {
    history.push(`/landlord/transactions?search=${parentProperty.address}`);
  };

  const items = [
    {
      label: (
        <Space>
          <DeleteOutlined />
          <span>{deleteTransactionText.text}</span>
        </Space>
      ),
      onClick: () => handleDeleteProperty(id),
      style: deleteTransactionText.style,
      key: '0',
    },
    {
      label: (
        <Space>
          <CalculatorOutlined />
          <Text color="black">View Transaction History</Text>
        </Space>
      ),
      key: '1',
      onClick: handleViewTransactionHistory,
    },
  ];

  const confirmKeys = ['0'];

  return <MoreMenu items={items} confirmKeys={confirmKeys} loading={loading} resetDropdown={resetDropdown} />;
};

export default MorePropertyActions;
