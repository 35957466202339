export const wepayOnboarding = (fullName, email) => {
  const clientId = process.env.REACT_APP_WEPAY_CLIENT;
  const redirectUri = process.env.REACT_APP_WEPAY_REDIRECT_URI;

  const wepayPermissionsScope = [
    'manage_accounts',
    'collect_payments',
    'view_user',
    'send_money',
    'preapprove_payments',
  ];
  return new Promise((accept, reject) => {
    const params = {
      client_id: `${clientId}`,
      scope: wepayPermissionsScope,
      user_name: fullName,
      user_email: email,
      redirect_uri: redirectUri,
      top: 100, // control the positioning of the popup with the top and left params
      left: 100,
      state: 'robot',
      callback: (data) => {
        if (data.code.length !== 0) {
          const paymentGateway = {
            paymentGateway: 'Wepay',
            action: 'access_token',
            params: {
              code: data.code,
            },
          };
          accept(paymentGateway);
        } else if (data.error) {
          reject(data.error);
        } else {
          accept(false);
          // an error has occurred and will be in data.error or closed window
        }
      },
    };
    
    window.WePay.oauth2_params = params;
    window.WePay.OAuth2.authorize();
  });
};
