import React, { useEffect, useState } from 'react';
import { Card, Layout, Space } from 'antd';
import { useLocation } from 'react-router';
import logo from 'assets/payrent_logo.svg';
import FinixFileUploadForm from './FinixFileUploadForm';
import { getFinixMerchantVerification } from 'services/api/landlordApi/read';
const { Content } = Layout;

export default function FinixFileUpload() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const merchantId = queryParams.get('merchant_id');
  const verificationId = queryParams.get('verification_id');
  const [remediationList, setRemediationList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const getAndSetRemediationItems = async (id, controller) => {
    try {
      const verification = await getFinixMerchantVerification(id, controller);
      if (!verification.raw) return;
      setRemediationList(() => {
        if (verification.raw instanceof Array) {
          return verification.raw.map((i) => i.remediationItem);
        } else {
          return [verification.raw];
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (verificationId) {
      getAndSetRemediationItems(verificationId, controller);
    }
    return () => {
      controller.abort();
    };
  }, [verificationId]);

  return (
    <Layout style={{ height: '100vh' }}>
      <Content style={{ margin: '24px auto' }}>
        <Space direction="vertical" size="large" align="center">
          <img src={logo} className="drawer-brand-logo" alt="Payrent logo" data-testid="imgTest" />
          <Card title="Secure Document Upload" style={{ margin: '20px', maxWidth: '568px' }}>
            <FinixFileUploadForm
              disabled={!merchantId}
              merchantId={merchantId}
              remediationList={remediationList}
              fileList={fileList}
              setFileList={setFileList}
              showSubmitButton
            />
          </Card>
        </Space>
      </Content>
    </Layout>
  );
}
