import React from 'react';
import { AutoComplete as AntAutoComplete } from 'antd';
import styled, { css } from 'styled-components';

const AutoComplete = (props) => {
  const {
    children,
    onChange,
    onSelect,
    placeholder,
    width,
    onKeyUp,
    defaultActiveFirstOption,
    onSearch,
    allowClear,
    notFoundContent,
    options,
  } = props;
  return (
    <Container>
      <StyledAutoComplete
        allowClear={allowClear}
        onChange={onChange}
        onSelect={onSelect}
        onSearch={onSearch}
        options={options}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        width={width}
        backfill
        defaultActiveFirstOption={defaultActiveFirstOption}
        notFoundContent={notFoundContent}
        data-testid="autocompleteTest"
        className="custom-autocomplete"
      >
        {children}
      </StyledAutoComplete>
    </Container>
  );
};

const Container = styled.div`
  width: 24.4%;
  .custom-autocomplete .ant-select-selector {
    height: 36px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 34px;
  }
  .ant-select-selection-placeholder {
    color: #bfbfbf !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 1680px) {
    width: 24.5%;
  }
  @media screen and (max-width: 1199px) {
    width: 49.2%;
  }
  @media screen and (max-width: 991px) {
    width: 49.5%;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

const StyledAutoComplete = styled(AntAutoComplete)`
  width: 100%;
  border-radius: 6px;
  height: 36px;
  ${(props) => {
    if (props.mobilewidth) {
      return css`
        @media screen and (max-width: 575px) {
          width: ${props.mobilewidth}%;
        }
      `;
    }
  }};
`;

export const Option = AntAutoComplete.Option;
export const OptGroup = AntAutoComplete.OptGroup;

export default AutoComplete;
