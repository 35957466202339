import React from 'react';
import { AuditOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';

const ViewPolicy = (props) => {
  // console.log('ViewPolicy props', props);
  const handleClick = () => {
    props.setInsuranceDrawer(true);
    if (props.uploadedInsuranceFile) {
      props.setShowUploadedPolicyDetails(true);
    } else {
      props.setShowPolicyDetails(true);
    }
  };

  return (
    <IconButton
      toolTitle="View Policy"
      icon={<AuditOutlined />}
      handleClick={() => handleClick()}
      disabled={props.disabled}
      data-testid="editRenterTest"
    />
  );
};

export default ViewPolicy;
