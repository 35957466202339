import React from 'react';
import { Input, Form, Row, Col, Select, DatePicker, Button, Tooltip, Card } from 'antd';
import { layout } from './layout';
import { validatePhone } from './validators';
import { stateOptions } from './helpers';
import dayjs from 'dayjs';
import { requiredFields } from './PersonProfileForm';
import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import PhoneInput from 'components/PhoneInput/PhoneInput';

const { Item } = Form;

export default function AssociatedOwnersFields({ formValues }) {
  return (
    <Form.List name="associated_owners">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map(({ key, name, ...rest }, index) => (
              <Card
                key={key}
                data-testid={`associatedOwnersCardTest-${index}`}
                title={<span>Owner {index + 1}</span>}
                extra={
                  <Tooltip title="Remove Owner">
                    <Button type="text" icon={<UserDeleteOutlined />} onClick={() => remove(name)} />
                  </Tooltip>
                }
                style={{
                  marginBottom: '18px',
                }}
              >
                <Row gutter={layout.rowGutter}>
                  <Col {...layout.threeCol}>
                    <Item
                      label="First Name"
                      name={[name, `entity_first_name`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_first_name'),
                        },
                        { max: 20 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.threeCol}>
                    <Item
                      label="Last Name"
                      name={[name, `entity_last_name`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_last_name'),
                        },
                        { max: 20 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.sixCol}>
                    <Item
                      label="Role"
                      name={[name, `entity_title`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_title'),
                        },
                        { max: 60 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.sixCol}>
                    <Item
                      label="% Owned"
                      name={[name, `entity_principal_percentage_ownership`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_principal_percentage_ownership'),
                        },
                        {
                          message: 'Must be greater the 25%',
                          validator: (_, value) => {
                            if (value < 25) {
                              return Promise.reject('Must be at least 25%');
                            }
                            return Promise.resolve();
                          },
                        },
                        { max: 3 },
                        {
                          pattern: /^[0-9]{1,3}$/,
                          message: 'No decimals allowed',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.twoCol}>
                    <Item
                      label="Personal Street Address"
                      name={[name, `entity_personal_address_line1`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_personal_address_line1'),
                        },
                        {
                          message: 'PO Boxes not allowed',
                          validator: (_, value) => {
                            if (
                              value &&
                              (value.toLowerCase().includes('po box') ||
                                value.toLowerCase().includes('p.o. box') ||
                                value.toLowerCase().includes('p o box'))
                            ) {
                              return Promise.reject('PO Boxes not allowed');
                            }
                            return Promise.resolve();
                          },
                        },
                        { max: 60 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.twoCol}>
                    <Item
                      label="Personal Address Line 2"
                      name={[name, `entity_personal_address_line2`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_personal_address_line2'),
                        },
                        { max: 60 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.twoCol}>
                    <Item
                      label="City"
                      name={[name, `entity_personal_address_city`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_personal_address_city'),
                        },

                        { max: 20 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.fourCol}>
                    <Item
                      label="State"
                      name={[name, `entity_personal_address_region`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_personal_address_region'),
                        },
                      ]}
                      hasFeedback
                    >
                      <Select options={stateOptions} />
                    </Item>
                  </Col>
                  <Col {...layout.fourCol}>
                    <Item
                      label="Zip"
                      name={[name, `entity_personal_address_postal_code`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_personal_address_postal_code'),
                        },

                        { max: 7 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.fourCol}>
                    <Item
                      label="Phone"
                      name={[name, `entity_phone`]}
                      rules={[{ required: requiredFields.includes('entity_phone') }, validatePhone]}
                      hasFeedback
                    >
                      <PhoneInput />
                    </Item>
                  </Col>
                  <Col {...layout.fourCol}>
                    <Item
                      label="Email"
                      name={[name, `entity_email`]}
                      rules={[
                        { required: requiredFields.includes('entity_email') },
                        { type: 'email' },
                        { max: 100 },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.fourCol}>
                    <Item
                      label="Owner SSN"
                      name={[name, `entity_tax_id`]}
                      rules={[
                        {
                          required: requiredFields.includes('entity_tax_id'),
                        },
                        {
                          pattern: /^\d{9}$/,
                          message: 'Must be a 9-digit number',
                        },
                        {
                          validator(_, value) {
                            if (
                              !value ||
                              formValues.entity_business_tax_id !== value ||
                              formValues.entity_business_type === 'INDIVIDUAL_SOLE_PROPRIETORSHIP'
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Cannot be EIN'));
                          },
                        },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col {...layout.fourCol}>
                    <Item
                      label="Date of Birth"
                      name={[name, `entity_dob`]}
                      rules={[{ required: requiredFields.includes('entity_dob') }]}
                      hasFeedback
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format="MM/DD/YYYY"
                        disabledDate={(current) => {
                          // Can not select days before today and today
                          return current && current > dayjs().subtract(18, 'year');
                        }}
                      />
                    </Item>
                  </Col>
                </Row>
              </Card>
            ))}
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() =>
                      add(Object.assign({}, ...requiredFields.map((field) => ({ [field]: undefined }))))
                    }
                    size="large"
                    block
                    icon={<UserAddOutlined />}
                    disabled={fields.length >= 4}
                  >
                    Add Owner
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
}
