import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { Spin } from 'antd';

// Components
import PanelFooter from 'components/Panel/PanelFooter';

import Button from 'components/Button';
import SpaceResponsive from 'components/SpaceResponsive';

import OnboardingStatus from '../../../components/OnboardingStatus';
import Steps from '../Steps';

const Finished = (props) => {
  const onboardingData = useSelector((store) => store.landlord.onboarding);
  const session = useSelector((store) => store.session);
  const { stepTitle, stepIcon, stepStatus, loading, servicePlan } = props;
  return (
    <>
      <Container className="content-container">
        <Steps
          onboardingData={onboardingData}
          stepTitle={stepTitle}
          stepIcon={stepIcon}
          stepStatus={stepStatus}
          padding
        />
        <SpaceResponsive lg={20} xmd={20} md={15} sm={15} />
        {loading ? (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        ) : (
          <OnboardingStatus
            boardingStatus={props.boardingStatus}
            servicePlan={servicePlan}
            paymentGateway={session.userData.paymentGateway}
          />
        )}
      </Container>

      <PanelFooter>
        <Button
          htmlType="button"
          type="primary"
          color="violet"
          onClick={() => {
            props.setDrawerVisible(false);
            props.setShowOnboarding(false);
          }}
          data-testid="closeButtonTest"
          bottom={0}
        >
          CLOSE
        </Button>
      </PanelFooter>
    </>
  );
};

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const Container = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  margin: 0 auto;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default Finished;
