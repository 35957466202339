import React, { useState } from 'react';
import { Dropdown, Checkbox, theme } from 'antd';
import styled from 'styled-components';
import { FilterOutlined } from '@ant-design/icons';

const FilterDropdown = (props) => {
  const { setFilters, filters, filterActivityFeed, fetchActivityFeed } = props;
  const [visible, setVisible] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const { token } = theme.useToken();
  // console.log('token', token);

  const onChange = (checkedValues) => {
    // console.log('checked = ', checkedValues);
    setFilters(checkedValues);
    setCheckedList(checkedValues);
    filterActivityFeed(checkedValues);
  };

  const handleMenuClick = (e) => {
    // console.log('handleMenuClick', e.key, filters);
    setVisible(true);
    if (filters.length) {
      filterActivityFeed(filters);
    }
  };

  const handleResetFilters = () => {
    setCheckedList([]);
    setFilters([]);
    fetchActivityFeed();
  };

  const handleVisibleChange = (flag, source) => {
    if (source === 'trigger') {
      setVisible(flag);
    }
  };

  const checkboxOptions = [
    { label: 'Payments', value: 'payment' },
    { label: 'Verifications', value: 'verification' },
    { label: 'Billing Items', value: 'billing' },
    { label: 'Documents', value: 'document' },
    { label: 'Schedule Payments', value: 'schedule' },
    { label: 'Chargebacks', value: 'chargeback' },
    { label: 'Flagged', value: 'flagged' },
    { label: 'Archived', value: 'archived' },
  ];

  const items = [
    {
      key: 'filter',
      label: (
        <Checkbox.Group
          options={checkboxOptions}
          onChange={onChange}
          value={checkedList}
          data-testid="filterMenuTest"
        />
      ),
    },
  ];
  return (
    <Dropdown
      menu={{ items, onClick: handleMenuClick }}
      trigger={['click']}
      onOpenChange={(open, { source }) => handleVisibleChange(open, source)}
      open={visible}
      data-testid="dropdownTest"
      dropdownRender={(menu) => (
        <FilterMenuContainer className="activity-filter-menu" token={token}>
          {React.cloneElement(menu, { style: { boxShadow: 'none' } })}
        </FilterMenuContainer>
      )}
    >
      <FilterOutlined
        style={{ fontSize: '22px', color: '#541388' }}
        onClick={(e) => e.preventDefault()}
        data-testid="filterTest"
      />
    </Dropdown>
  );
};

const FilterMenuContainer = styled.div`
  background-color: ${(props) => props.token.colorBgElevated};
  border-radius: ${(props) => `${props.token.borderRadiusLG}px`};
  box-shadow: ${(props) => props.token.boxShadowSecondary};
  min-width: 22px;
  max-width: 224px;
  padding: 16px 8px;
`;

export default FilterDropdown;
