import React from 'react';

import Text from 'components/Text';

const LateFeeForgiveness = (props) => {
  const { rentCredStatus } = props;
  return (
    <>
      <Text color="violet2" size={14} strong>
        Late Fee Forgiveness Credits:{' '}
      </Text>
      <Text color="black" size={14}>
        {rentCredStatus.lateFeeForgiveness}
      </Text>
    </>
  );
};

export default LateFeeForgiveness;
