import React from 'react';
import styledVariables from 'styles/styledComponents/variables';
import styled from 'styled-components';

const ExternalLink = (props) => {
  const { color, size, centered, inline, children, end, ...rest } = props;

  return (
    <StyledLink {...rest} color={color} size={size} rel="noopener noreferrer" data-testid="linkTest">
      {children}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${(props) =>
    props.color ? styledVariables.colors[props.color] : styledVariables.colors.violet} !important;
  font-size: ${(props) => (props.size ? `${props.size}px` : '')};
`;

export default ExternalLink;
