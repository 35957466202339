/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Segmented as AntSegmented } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styles/styledComponents/variables';

export default function Segmented(props) {
  const { options, size, defaultValue, onChange, testId, ...rest } = props;
  return (
    <Container className="segmented-wrapper">
      <StyledeSegmented options={options} size={size} defaultValue={defaultValue} onChange={onChange} {...rest} />
    </Container>
  );
}

const StyledeSegmented = styled(AntSegmented)`
  border-radius: 4px;
  label {
    font-size: 14px;
  }
  .ant-segmented-item-selected {
    background-color: ${theme.colors.violet};
    color: ${theme.colors.white};
    border-radius: 4px;
  }
`;

const Container = styled.div`
  display: block;
  text-align: center;
`;

Segmented.propTypes = {
  options: PropTypes.array.isRequired,
  size: PropTypes.string,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
};
Segmented.defaultProps = {
  size: 'small',
  'data-testid': 'segmentedTest',
  className: 'antd-segmented',
};
