import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import Jsona from 'jsona';
import { useHistory } from 'react-router-dom';
import { useIntercom as useReactIntercom } from 'react-use-intercom';

const dataFormatter = new Jsona();
dayjs.extend(require('dayjs/plugin/utc'));

export default function useIntercom() {
  const { boot, update } = useReactIntercom();
  const { userData: user, userRole } = useSelector((store) => store.session);
  const history = useHistory();
  const landlord = useSelector((store) => store.landlord);
  const cognitoToken = useSelector((store) => store.session.cognitoTokens.idToken.jwtToken);
  const isInitialized = React.useRef(false);

  const fetchAndSetIntercomSession = React.useCallback(
    async (user, controller) => {
      try {
        if (isInitialized.current) return;
        console.log('Initializing Intercom session...');
        const options = {
          signal: controller?.signal,
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL_V3}/users/verification`,
          headers: {
            Authorization: `Bearer ${cognitoToken}`,
          },
        };

        const { hash } = await axios.request(options).then((res) => dataFormatter.deserialize(res.data));
        // console.log('hash:', hash);
        const userDetails = {
          userId: user.id,
          userHash: hash,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          phone: user.phone,
          createdAt: dayjs(user.createdAt).unix(),
          customAttributes: {
            is_landlord: userRole === 'landlord',
            service_plan: user.servicePlan.name,
            account_status: user.state,
            merchant_status: landlord?.merchantAccount?.status || null,
          },
        };
        // console.log('[useIntercom.js] userDetails:', userDetails);
        boot({ ...userDetails, customLauncherSelector: '#support_menu_item' });
        isInitialized.current = true;
      } catch (e) {
        console.log(e);
      }
    },
    [boot, cognitoToken, landlord.merchantAccount, userRole]
  );

  React.useEffect(() => {
    let controller = new AbortController();
    if (user.servicePlan.name) fetchAndSetIntercomSession(user, controller);
    return () => controller.abort();
  }, [fetchAndSetIntercomSession, user]);

  React.useEffect(() => {
    if (!history.location.pathname) return;
    update();
  }, [history.location.pathname, update]);

  return null;
}
