import React from 'react';
import { Steps as AntSteps } from 'antd';

const { Step } = AntSteps;

const Steps = (props) => {
  const { stepTitle, stepIcon, stepStatus, onboardingData, padding } = props;

  return (
    <AntSteps
      direction="vertical"
      size="small"
      current={onboardingData.currentStep - 1}
      style={{ margin: padding ? '0px 20px' : '0px', minHeight: '227px' }}
    >
      <Step title={stepTitle(1)} icon={stepIcon(1)} description="Add your first property" />
      <Step title={stepTitle(2)} icon={stepIcon(2)} description="Invite a renter" />
      <Step title={stepTitle(3)} icon={stepIcon(3)} description="Complete your account profile" />
      <Step
        title={stepTitle(4)}
        icon={stepIcon(4)}
        status={stepStatus(4)}
        description="Set-up your payment account"
      />
    </AntSteps>
  );
};

export default Steps;
