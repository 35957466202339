// @flow
import * as React from 'react';
import { Typography, theme } from 'antd';
import styled, { css } from 'styled-components';
import styledVariables from '../../styles/styledComponents/variables';

const AntText = Typography.Text;

const Text = (props) => {
  const { token } = theme.useToken();
  const {
    color,
    size,
    centered,
    strong,
    margin,
    width,
    children,
    underline,
    pointer,
    sizeXs,
    sizeLg,
    sizeXlg,
    sizeXl,
    weight,
    position,
    ...rest
  } = props;

  return (
    <StyledText
      {...rest}
      color={color}
      size={size}
      centered={centered}
      strong={strong}
      margin={margin}
      width={width}
      underline={underline}
      pointer={pointer}
      sizeXs={sizeXs}
      sizeLg={sizeLg}
      sizeXlg={sizeXlg}
      sizeXl={sizeXl}
      weight={weight}
      position={position}
      token={token}
    >
      {children}
    </StyledText>
  );
};

// Styled Components
const StyledText = styled(
  ({
    color,
    size,
    centered,
    margin,
    width,
    strong,
    underline,
    pointer,
    sizeXs,
    sizeLg,
    sizeXlg,
    sizeXl,
    sizeSm,
    weight,
    position,
    ...rest
  }) => <AntText data-testid="textTest" {...rest} />
)`
  color: ${(props) =>
    props.color ? styledVariables.colors[props.color] : styledVariables.colors.violet} !important;
  font-size: ${(props) => (props.size ? `${props.size}px` : `${props.token.fontSize}px`)};
  ${(props) => {
    if (props.centered) {
      return css`
        display: block;
        text-align: ${(props) => (props.centered ? 'center' : '')};
      `;
    }
  }};
  ${(props) => {
    if (props.sizeXl) {
      return css`
        @media screen and (max-width: 1680px) {
          font-size: ${props.sizeXl}px !important;
        }
      `;
    }
  }};
  ${(props) => {
    if (props.sizeXlg) {
      return css`
        @media screen and (max-width: 1350px) and (min-width: 1200px) {
          font-size: ${props.sizeXlg}px !important;
        }
      `;
    }
  }};
  ${(props) => {
    if (props.sizeLg) {
      return css`
        @media screen and (max-width: 1200px) {
          font-size: ${props.sizeLg}px !important;
        }
      `;
    }
  }};
  ${(props) => {
    if (props.sizeXs) {
      return css`
        @media screen and (max-width: 480px) {
          font-size: ${props.sizeXs}px !important;
        }
      `;
    }
  }};
  ${(props) => {
    if (props.sizesm) {
      return css`
        @media screen and (max-width: 440px) {
          font-size: ${props.sizesm}px !important;
        }
      `;
    }
  }};
  ${(props) => {
    if (props.underline) {
      return css`
        text-decoration: underline;
      `;
    }
  }};
  ${(props) => {
    if (props.pointer) {
      return css`
        cursor: pointer;
      `;
    }
  }};
  ${(props) => {
    if (props.position) {
      return css`
        position: ${props.position};
      `;
    }
  }};
  font-weight: ${(props) => (props.strong ? 600 : '' || props.weight)};
  margin: ${(props) => (props.margin ? props.margin : '')};
  width: ${(props) => (props.width ? `${props.width}px` : '')};
`;

export default Text;
