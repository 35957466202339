import React, { useEffect, useState } from 'react';
import { UndoOutlined } from '@ant-design/icons';

import { Tooltip } from 'antd';
import styled from 'styled-components';
import theme from 'styles/styledComponents/variables';

export default function ResetButton(props) {
  const { record, handleResetBalance } = props;
  const details = record?.details;
  const ba = record?.details?.billingAccount || record?.billingAccounts[0];
  // console.log('[ResetButton.js]', props);
  const hasBalance = Number(ba?.balance) !== 0 || false;
  return (
    <StyledWrapper
      onClick={() => handleResetBalance(details)}
      onKeyDown={() => handleResetBalance(details)}
      tabIndex="0"
      role="button"
      hasBalance={hasBalance}
    >
      <Tooltip title="Resets balance to $0">
        <UndoOutlined
          spin={record.resetLoading}
          style={{
            color: hasBalance ? theme.colors.violet : theme.colors.grey,
          }}
        />
      </Tooltip>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.span`
  margin-left: 8px;

  &:hover {
    cursor: ${(props) => (props.hasBalance ? 'pointer' : 'not-allowed')};
  }
`;
