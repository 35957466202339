// @flow
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import { Transition } from 'react-spring/renderprops';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

function BottomCard(props) {
  const { children, isVisible, setVisible, translation } = props;
  const divContainer = React.useRef(null);
  BottomCard.keepBottomCardVisible = useSelector((store) => store.global.bottomCard.forceVisible);

  BottomCard.handleClickOutside = (e) => {
    const target = e.target;
    const parentTag = target.parentElement;
    const isToggleButton =
      target?.dataset?.name === 'toggle-bottom-card' || parentTag?.dataset?.name === 'toggle-bottom-card';

    if (isToggleButton || BottomCard.keepBottomCardVisible) return; // Use the button handler instead of the click-outside function
    setVisible(false);
  };

  return (
    <div data-testid="bottomCardTest">
      <Transition
        items={isVisible}
        from={{
          transform: `translate3d(0, ${translation}px, 0)`,
        }}
        enter={{
          transform: 'translate3d(0, 0px, 0)',
        }}
        leave={{
          transform: `translate3d(0, ${translation}px, 0)`,
        }}
      >
        {(esVisible) =>
          esVisible &&
          ((transitionProps) => (
            <Container ref={divContainer} style={transitionProps}>
              {children}
            </Container>
          ))
        }
      </Transition>
    </div>
  );
}

const Container = styled.div`
  padding: 20px 30px;
  background-color: white;
  position: fixed;
  bottom: 0;
  height: auto;
  width: 100%;
  z-index: 20;
  left: 0;
  box-shadow: 1px 0 11px 0 rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 768px) {
    bottom: 64px;
  }
`;

const clickOutsideConfig = {
  handleClickOutside: () => BottomCard.handleClickOutside,
};

export default onClickOutside(BottomCard, clickOutsideConfig);
