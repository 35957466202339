// @flow
import * as React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import styledVariables from '../../styles/styledComponents/variables';
const AntTitle = Typography.Title;

const Title = (props) => {
  const { color, centered, strong, bottom, weight, children, ...rest } = props;

  return (
    <StyledTitle {...rest} color={color} strong={strong} bottom={bottom} centered={centered} weight={weight}>
      {children}
    </StyledTitle>
  );
};

// Styled Components
const StyledTitle = styled(({ color, centered, weight, bottom, ...rest }) => (
  <AntTitle {...rest} data-testid="titleTest" />
))`
  margin: 10px 0 ${(props) => (props.bottom >= 0 ? `${props.bottom}px` : '10px')} 0 !important;
  color: ${(props) =>
    props.color ? styledVariables.colors[props.color] : styledVariables.colors.violet} !important;
  text-align: ${(props) => (props.centered ? 'center' : '')};
  font-weight: ${(props) => (props.strong ? 600 : '' || props.weight)};
  font-family: 'Poppins', sans-serif !important;
`;

export default Title;
