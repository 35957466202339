import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Row, Col, Input, Select } from 'antd';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import { checkZipCode, checkAddressValidator } from 'resources/FormValidators';

import { createUpdateBillingDetails } from 'services/api/landlordApi/create';
import { displayAlert } from 'resources/helpers';

import poweredByStripe from 'assets/images/powered-stripe.png';
import verifiedSecure from 'assets/images/verified-secure.png';

import Button from 'components/Button';
import AlertFade from 'components/AlertFade';

const { Option } = Select;

const BillingForm = (props) => {
  const { setSubscriptionPayment, setSilaAccountSetup } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const stripe = useStripe();
  const elements = useElements();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { error, setupIntent } = await stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'https://my-site.com/account/payments/setup-complete',
        },
      });

      console.log(setupIntent);

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (e.g., payment
        // details incomplete)

        const errorObject = {
          message: error.message,
        };

        throw errorObject;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

      await createUpdateBillingDetails(
        cognitoToken,
        session.userData.id,
        session.userData,
        setupIntent.payment_method
      );
      setLoading(false);

      // check flagsmith
      setSubscriptionPayment(false);
      setSilaAccountSetup(true);
    } catch (error) {
      setLoading(false);
      displayAlert(setAlert, 'There was an error with your request', 'warning', 3000);
    }
  };

  return (
    <>
      <StyledForm name="accountSetup" form={form} onFinish={onFinish}>
        <ContentContainer className="content-container">
          <PaymentElement />
        </ContentContainer>
        <StyledFooter>
          <AlertFade
            isVisible={alert.isVisible}
            message={alert.message}
            type={alert.type}
            minHeight="80"
            alertPosition="absolute"
            bottom={150}
          />

          <Row justify="center" style={{ marginBottom: '10px' }}>
            <img src={poweredByStripe} alt="Powered by Stripe" />
          </Row>
          <Row justify="center" style={{ marginBottom: '20px' }}>
            <Col span={24}>
              <img src={verifiedSecure} alt="Verified and secure" width={151} />
            </Col>
          </Row>
          <Button
            color="violet"
            alignment="center"
            bottom={0}
            htmlType="submit"
            loading={loading}
            disabled={disableButton}
          >
            CONTINUE
          </Button>
        </StyledFooter>
      </StyledForm>
    </>
  );
};

const StyledForm = styled(Form)`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled.div`
  min-height: 165px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
`;

export default BillingForm;
