import Button from 'components/Button/Button';
import React, { useState } from 'react';
import Drawer from 'components/Drawer/Drawer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import SendPanel from './SendPanel/SendPanel';
import SignPanel from './SignPanel/SignPanel';
import DocumentPanel from './DocumentPanel/DocumentPanel';

dayjs.extend(relativeTime);

function DocumentDrawer(props) {
  // console.log('[DocumentDrawer.js] props', props);
  const {
    showPanel,
    tagOptions,
    drawerVisible,
    setDrawerVisible,
    setEditDocument,
    setSubmitting,
    submitting,
    handleMutation,
    editDocument,
  } = props;

  const [alert, setAlert] = useState({ message: '', type: 'success' });

  const displayAlert = (text, type) => {
    setAlert({
      message: text,
      type,
    });
    setTimeout(() => {
      setAlert({
        message: '',
      });
    }, 3000);
  };

  const commonProps = {
    setDrawerVisible,
    setEditDocument,
    drawerVisible,
    setSubmitting,
    displayAlert,
    handleMutation,
    editDocument,
  };

  return (
    <Drawer
      open={drawerVisible}
      onClose={() => {
        setDrawerVisible(false);
        setEditDocument(null);
      }}
      alertMessage={alert.message}
      alertType={alert.type}
      footer={
        <Button type="primary" size="large" htmlType="submit" form={`${showPanel}-form`} loading={submitting}>
          Save
        </Button>
      }
    >
      {showPanel === 'document' ? (
        <DocumentPanel tagOptions={tagOptions} {...commonProps} />
      ) : showPanel === 'send' ? (
        <SendPanel {...commonProps} />
      ) : showPanel === 'sign' ? (
        <SignPanel {...commonProps} />
      ) : null}
    </Drawer>
  );
}

export default DocumentDrawer;
