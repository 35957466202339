import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlagsmith } from 'flagsmith-react';
import { Form, Row, Col, Select, Input, DatePicker } from 'antd';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import MaskedInput from 'antd-mask-input';
import parser from 'parse-address';

import { vgsCss } from 'utils/vgs';

import { displayAlert } from 'resources/helpers';

import { createOnboarding, submitAppForOnboarding } from 'services/api/landlordApi/create';

import { checkZipCode, checkCity, checkAddressValidator } from 'resources/FormValidators';
import styledVariables from 'styles/styledComponents/variables';

import Space from 'components/Space';
import Text from 'components/Text';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import SpaceResponsive from 'components/SpaceResponsive';
import { boardingElement, boardingText } from '../../animation/variants';

import { usStates } from '../../../../../../resources/FormData';

const { Option } = Select;

const BusinessSetup = (props) => {
  const {
    setSilaAccountSetup,
    setSilaBusinessSetup,
    open: openPlaid,
    setSilaVerificationDocs,
    silaAccountSetupValues,
    identityValues,
    setVerifyIdentity,
    defaultPropertyGroup,
    setShowStripeSetup,
  } = props;

  const { isLoading, isError, hasFeature, getValue } = useFlagsmith();

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [form] = Form.useForm();
  const [vgsForm, setVgsForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoaded, scriptLoaded] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  useEffect(() => {
    const createVgsForm = window.VGSCollect.create(
      process.env.REACT_APP_VGS_VAULT,
      process.env.REACT_APP_VGS_ENV,
      (s) => {
        if (s) {
          // if (s.businessEin?.isDirty) form.validateFields(['businessEin']);
          // if (s.routingNumber?.isDirty) form.validateFields(['routingNumber']);
          // if (s.bankAccountNumber?.isDirty) form.validateFields(['bankAccountNumber']);
        }
      }
    );

    const businessEin = {
      type: 'text',
      name: 'emptyEin',
      // placeholder: 'AAA-GG-SSSS',
      fontFamily: 'Montserrat',
      css: vgsCss,
    };

    createVgsForm.field('#emptyEin .fake-input', businessEin);
    setVgsForm(createVgsForm);
    scriptLoaded(true);
  }, []);

  const onFinish = async (values) => {
    // open();
    console.log(values);
    try {
      setLoading(true);
      const formData = values;
      const parsedAddress = parser.parseLocation(formData.businessAddress);
      if (!parsedAddress) throw { message: 'Not a valid address' };

      const streetNumber = parsedAddress.number;
      const streetName = formData.businessAddress.replace(`${parsedAddress.number} `, '');
      const bPhone = formData.businessPhone.replace(/\s/g, '');

      formData.bAddress = streetName;
      formData.bStreetNum = streetNumber;
      formData.businessPhone = bPhone;

      const mergedValues = {
        ...identityValues,
        ...silaAccountSetupValues,
        ...formData,
        propertyGroupId: +defaultPropertyGroup[0].id,
      };

      vgsForm.submit(
        '/post',
        {
          data: {
            businessEin: values.businessEin,
          },
        },
        async (status, response) => {
          try {
            console.log(response);
            mergedValues.businessEin = JSON.parse(response.data).businessEin;

            const res = await createOnboarding(cognitoToken, mergedValues);
            await submitAppForOnboarding(cognitoToken, +res.id);
            const flowId = getValue('onboarding-flow');
            setLoading(false);

            if (flowId === 1 || flowId === 2 || flowId === 3) {
              // if documents are required
              // setSilaBusinessSetup(false);
              // setSilaVerificationDocs(true);
              // if not
              setSilaBusinessSetup(false);
              // setShowStripeSetup(true);
              openPlaid();
            }
          } catch (error) {
            console.log(error);
            displayAlert(
              setAlert,
              error.data?.errors[0].detail || 'There was an error with you request.',
              'warning',
              3000
            );
            setLoading(false);
          }
        },
        (errors) => {
          console.log(errors);
          setLoading(false);
          displayAlert(setAlert, 'There was an error with your request.', 'warning', 3000);
        }
      );
    } catch (error) {
      console.log(error);
      displayAlert(
        setAlert,
        error.data?.errors[0].detail || 'There was an error with you request.',
        'warning',
        3000
      );
      setLoading(false);
    }
  };

  const handlePrevious = async () => {
    setSilaBusinessSetup(false);
    setVerifyIdentity(true);
  };

  const EinValidator = (rule, value) => {
    if (!value) {
      return Promise.reject('Please input your business EIN');
    }
    const justNumbers = value.replace(/-|_/g, '');
    if (justNumbers.length === 9) {
      return Promise.resolve();
    }
    if (justNumbers.length < 9) {
      return Promise.reject('Not a valid EIN');
    }

    return Promise.reject('Please input your business EIN');
  };

  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong>
          Add Your Business Details
        </Text>
      </motion.div>
      <SpaceResponsive lg={15} xmd={10} md={10} sm={10} />
      <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave">
        <StyledForm
          name="businessSetup"
          form={form}
          onFinish={onFinish}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Text color="black" size={14} sizesm={12}>
            Please enter the business information for your account. Your business legal name and business address
            must match the information associated with your business tax id (EIN). No P.O. Boxes are allowed for a
            business address.
          </Text>
          <Space vertical={15} />
          <ContentContainer className="content-container" id="contentContainerId">
            <Row gutter={15}>
              <Col span={12}>
                Business Legal Name
                <Form.Item
                  name="legalBusinessName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your business name',
                      whitespace: true,
                      min: 5,
                    },
                  ]}
                >
                  <Input disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'} />
                </Form.Item>
              </Col>

              <Col span={12}>
                DBA (if applicable)
                <Form.Item
                  name="dbaName"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your dba name ',
                  //     whitespace: true,
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Business Street Address
                <Form.Item
                  rules={[
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please input your address',
                      whitespace: true,
                    },
                    {
                      pattern: new RegExp(/(?!^\d+$)^.+$/),
                      message: 'Address must be numbers and letters',
                    },
                    {
                      max: 100,
                      message: 'Address cant be more than 100 characters',
                    },
                    {
                      validator: checkAddressValidator,
                    },
                  ]}
                  name="businessAddress"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={10}>
                Zip Code
                <Form.Item
                  name="bZipcode"
                  rules={[
                    {
                      validator: checkZipCode,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                City
                <Form.Item
                  name="bCity"
                  rules={[
                    {
                      validator: form.getFieldValue('businessType') !== 'Sole Proprietorship' ? checkCity : false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={10}>
                State
                <Form.Item
                  name="bState"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a state',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select State"
                    showSearch
                    onChange={() => {}}
                    getPopupContainer={() => document.getElementById('contentContainerId')}
                  >
                    {usStates.map((s, i) => (
                      <Option value={s.abbreviation} key={i}>
                        {s.abbreviation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Business Email Address
                <Form.Item
                  name="businessEmail" // This values is missing
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not a valid email',
                    },
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                Business Phone
                <Form.Item
                  name="businessPhone"
                  rules={[
                    {
                      required: form.getFieldValue('businessType') !== 'Sole Proprietorship',
                      message: 'Please enter your phone number',
                    },
                    {
                      // pattern: new RegExp('^[0-9]{6,}$'),
                      // supports ten characters without + sign
                      // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                      pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                      message: 'Please enter only numbers - min length 10',
                    },
                    {
                      message: 'Please input a valid phone number.',
                      max: 12,
                    },
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111 111 1111"
                    mask="000 000 0000"
                    minLength={6}
                    placeholderChar=" "
                    data-testid="phoneTest"
                    prefix="+1"
                    disabled={form.getFieldValue('businessType') === 'Sole Proprietorship'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15} style={{ flexGrow: 1 }}>
              <Col span={12}>
                Business Start Date
                <Form.Item
                  name="businessStartDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your business start date',
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%', borderRadius: '4px' }}
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                Business EIN
                <Form.Item
                  name="businessEin" // should we tokenize this value?
                  rules={[
                    {
                      validator: EinValidator,
                    },
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="11-1111111"
                    mask="00-0000000"
                    minLength={6}
                    placeholderChar=" "
                  />
                </Form.Item>
                <div id="emptyEin" style={{ position: 'absolute' }} key="3">
                  <div className="form-control-static">
                    <StyledSpan className="fake-input"></StyledSpan>
                  </div>
                </div>
              </Col>
            </Row>
          </ContentContainer>
          <StyledFooter>
            <AlertFade
              isVisible={alert.isVisible}
              message={alert.message}
              type={alert.type}
              minHeight="80"
              alertPosition="absolute"
              bottom={150}
            />
            <div
              style={{
                width: '100%',
                paddingTop: '10px',
                display: 'flex',
                justifyContent: 'start',
                position: 'relative',
              }}
            >
              <StyledText color="violet" size={12} underline pointer onClick={handlePrevious}>
                Previous
              </StyledText>
              <Button
                color="violet"
                htmlType="submit"
                alignment="center"
                bottom={0}
                style={{ margin: '0 auto' }}
                loading={loading}
              >
                CONTINUE
              </Button>
            </div>
          </StyledFooter>
        </StyledForm>
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: ${styledVariables.colors.white2};
  height: 80%;
  border: 1px solid ${styledVariables.colors.grey5};
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 20px 10px 20px;
  @media screen and (max-width: 488px) {
    width: 95%;
    padding: 22px 10px 10px 10px;
  }
`;

const StyledText = styled(Text)`
  position: absolute;
  padding-top: 10px;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled(Form)`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const StyledFooter = styled.div`
  border-top: 1px solid lightgrey;
  min-height: 80px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
`;

const StyledSpan = styled.span`
  iframe {
    height: 32px;
    width: 50%;
  }
`;

export default BusinessSetup;
