import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Input, Row, Col, Select, DatePicker, Checkbox, Form, Tooltip, Space, theme } from 'antd';
import MaskedInput from 'antd-mask-input';

import axios from 'axios';

import FileType from 'file-type/browser';

import { disableLeaseStartDate, disableLeaseEndDate } from 'resources/FormValidators';
import { createRenter } from 'services/api/landlordApi/create';
import { getProperties } from 'services/api/landlordApi/read';
import { documentsPresignedUrl } from 'services/api/common/create';
import { deleteInviteV3 } from 'services/api/landlordApi/delete';

import { getRentersListAction, getSummaryDataAction, getV3RentersListAction } from 'store/actions/landlordActions';
import { CloseSquareFilled, InfoCircleFilled } from '@ant-design/icons';

import PanelFooter from 'components/Panel/PanelFooter';
import UploadButton from 'components/UploadButton';
import SpaceResponsive from 'components/SpaceResponsive';
import AlertFade from 'components/AlertFade';
import Text from 'components/Text';
import DropdownButton from 'components/DropdownButton';

import PropertyDetails from './components/PropertyDetails';
import PhoneInput from 'components/PhoneInput/PhoneInput';
const Title = styled.h1`
  font-family: Poppins;
  font-size: 42px;
  text-align: center;
  color: #122c34;
  margin-bottom: 10px !important;
`;

const Subtitle = styled.div`
  width: 320px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  color: #122c34;
`;

const { Option } = Select;

const InvitationPanel = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { token } = theme.useToken();
  const { inviteProperty, form, fetchRenters, cPage, cSize, setSelectedParentProperty } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const dispatch = useDispatch();
  const [message, setMessage] = useState({ type: 'success', message: '' });
  // const [form] = Form.useForm();
  const [properties, setProperties] = useState([]);
  const [selectedProp, setSelectedProp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProperties, setLoadingProperties] = useState(false);
  const [uploadState, setUploadState] = useState({
    fileList: [],
    uploading: false,
  });

  const [createTransaction, setCreateTransaction] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoadingProperties(true);
      const propertiesRes = await getProperties(false, 1, 10000, false, 'VACANT');

      setProperties(propertiesRes);

      const propertyIdDetail = form.getFieldValue('propertyId');
      setLoadingProperties(false);

      if (inviteProperty || propertyIdDetail) {
        const property = propertiesRes.filter((p) => p.id === inviteProperty?.id || p.id === propertyIdDetail);

        setSelectedProp(property);
        if (inviteProperty?.id) {
          form.setFieldsValue({
            propertyId: inviteProperty?.id,
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoadingProperties(false);
      displayAlert('Request failed.', 'warning');
    }
  };

  const displayAlert = (text, type) => {
    setMessage({
      message: text,
      type,
    });
    setTimeout(() => {
      setMessage({
        message: '',
      });
    }, 3000);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    let invitationRes = '';
    let selectedProperty = '';

    try {
      let normalizePhone = '';
      if (values.mobilePhone) {
        normalizePhone = `+1${values?.mobilePhone.replace(/\s/g, '')}`;
        values.mobilePhone = normalizePhone;
      }
      values.extendMonth = !values.extendMonth;
      values.landlordId = session.userData.id;

      invitationRes = await createRenter(cognitoToken, values);
      dispatch(getV3RentersListAction(cognitoToken, 1, 10000, '', ''));
      selectedProperty = properties.filter((p) => p.id === values.propertyId);
    } catch (error) {
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.detail;
      displayAlert(detail || 'Request failed.', 'warning');
      return;
    }
    try {
      if (uploadState.fileList.length) {
        const type = await FileType.fromBlob(uploadState.fileList[0]);

        const ext = type.mime === 'image/jpeg' ? 'jpeg' : type.ext;

        const res = await documentsPresignedUrl(
          session.cognitoTokens.idToken.jwtToken,
          invitationRes.billingAccounts.id,
          'upload',
          `leasedocument.${ext}`,
          'lease'
        );

        const instance = axios.create({
          headers: { 'Content-Type': uploadState.fileList[0].type },
        });
        delete instance.defaults.headers.common['Authorization'];
        delete instance.defaults.headers.common['Accept'];

        await instance.put(res.presignedUrl, uploadState.fileList[0]);
      }
    } catch (error) {
      await deleteInviteV3(cognitoToken, invitationRes.id);
      dispatch(getV3RentersListAction(cognitoToken, 1, 10000, '', ''));
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.detail;
      displayAlert(detail || 'Request failed.', 'warning');
      return;
    }
    try {
      // dispatch(getRentersListAction(1, 1000, '-lastName'));
      if (cPage && cSize) fetchRenters(cPage.current, cSize.current, '');

      setIsLoading(false);
      displayAlert('Renter successfully invited.', 'success');
      // if (props.fetchProperties) props.fetchProperties();
      setTimeout(() => {
        form.resetFields();
        dispatch(getSummaryDataAction(cognitoToken, 'people'));
        props.setShowInvitationPanel(false);
        if (createTransaction) {
          dispatch({
            type: 'RENTER_TRANSACTION',
            payload: {
              userName: `${invitationRes.firstName} ${invitationRes.lastName} - ${selectedProperty[0].address} ${selectedProperty[0].address2}`,
            },
          });
          props.setShowTransactionPanel(true);
        }
        if (!createTransaction) {
          props.setDrawerVisible(false);
          if (location.pathname !== 'landlord/renters') history.push('/landlord/renters');
        }
      }, 1000);
    } catch (error) {
      console.log(error);
      const detail = error?.data?.errors[0]?.detail;
      displayAlert(detail || 'Request failed.', 'warning');
    }
  };

  const handleMenuClick = () => {
    setCreateTransaction(true);
    form.submit();
  };

  const handleButtonClick = () => {
    setCreateTransaction(false);
  };

  const handleOnValuesChange = (changedValues, allValues) => {
    if (changedValues.mobilePhone) {
      form.validateFields(['email']);
    }
    if (changedValues.email) {
      form.validateFields(['mobilePhone']);
    }
    if (changedValues.propertyId) {
      const property = properties.filter((p) => p.id === changedValues.propertyId);
      setSelectedProp(property);
    }
  };

  const handleChangePropertyDetails = () => {
    // console.log('ƒ handleChangePropertyDetails', selectedProp);
    if (selectedProp.length) {
      const propertyGroupId = +selectedProp[0].propertyGroup.id;
      const parentPropertyId = +selectedProp[0].parentPropertyId;
      const unitId = +selectedProp[0].id;
      const parentProperty = selectedProp[0];

      setSelectedParentProperty({ ...parentProperty, propertyGroupId, id: parentPropertyId });
      props.setEditInvitationProperty(true);
      props.setShowInvitationPanel(false);
      props.setEditProperty({ ...selectedProp[0], id: unitId });
      props.setShowUnitPanel(true);
    }
  };

  // const disableLeaseStartDate = (current) => {
  //   if (form.getFieldValue('leaseEndDate')) {
  //     return current && current > moment(form.getFieldValue('leaseEndDate')).subtract(3, 'd');
  //   }
  // };
  //
  // const disableLeaseEndDate = (current) => {
  //   if (form.getFieldValue('leaseStartDate')) {
  //     return current && current < moment(form.getFieldValue('leaseStartDate')).add(3, 'd');
  //   }
  //   return current && current < moment().startOf('day');
  // };

  return (
    <>
      <DrawerContainer data-testid="invitationContainterTest">
        <StyledForm
          form={form}
          layout="vertical"
          requiredMark={false}
          name="basic"
          initialValues={{
            remember: true,
            extendMonth: false,
            mobilePhone: '',
            leaseStartDate: '',
            leaseEndDate: '',
            requireInsurance: true,
          }}
          onFinish={onFinish}
          onFinishFailed={() => {}}
          onValuesChange={handleOnValuesChange}
        >
          <Header data-testid="invitationPanelHeaderTest">
            <CloseSquareFilled
              style={{ fontSize: '25px', color: '#122C34' }}
              onClick={() => {
                props.setDrawerVisible(false);
                props.setShowInvitationPanel(false);
                props.form.resetFields();
                props.setEditInvitationProperty(false);
              }}
              data-testid="closePanelTest"
            />
          </Header>
          <Title>Invitation</Title>
          <Subtitle>Invite your renter to pay rent safely and simply.</Subtitle>
          <SpaceResponsive lg={15} md={15} sm={15} />
          <FormContentContainer className="content-container">
            <Row justify="space-between" gutter={20}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  data-testid="firstNameTest"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the tenant's first name.",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  data-testid="lastNameTest"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the tenant's last name.",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.mobilePhone !== curValues.mobilePhone}
              noStyle
            >
              {() => {
                return (
                  <Form.Item
                    label="Email Address (optional)"
                    name="email"
                    data-testid="emailTest"
                    rules={[
                      {
                        required: !form.getFieldValue('mobilePhone'),
                        type: 'email',
                        message:
                          !form.getFieldValue('email') && !form.getFieldValue('mobilePhone')
                            ? 'Requires either email address or mobile phone number.'
                            : 'Please enter an email address',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.email !== curValues.email} noStyle>
              {() => {
                return (
                  <Form.Item
                    label="Renter Mobile Phone (optional)"
                    name="mobilePhone"
                    rules={[
                      {
                        required: !form.getFieldValue('email'),
                        message:
                          !form.getFieldValue('email') && !form.getFieldValue('mobilePhone')
                            ? 'Requires either email address or mobile phone number.'
                            : 'Please enter a phone number',
                      },
                      {
                        // pattern: new RegExp('^[0-9]{6,}$'),
                        // supports ten characters without + sign
                        // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                        pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                        message: 'Please enter only numbers - min length 10',
                      },
                      {
                        message: 'Please enter a valid phone number.',
                        max: 16,
                      },
                    ]}
                  >
                    <PhoneInput />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              label="Property"
              name="propertyId"
              data-testid="propertyTest"
              rules={[{ required: true, message: 'Please select a property' }]}
            >
              <Select
                placeholder="Select property"
                onChange={() => {}}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                data-testid="propSelectTest"
                loading={loadingProperties}
              >
                {properties.length &&
                  properties.map((property, i) => (
                    <Option value={property.id} key={i}>
                      {property.address2 ? `${property.address} - #${property.address2}` : `${property.address}`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Row gutter={16} justify="start">
              <Col span={12}>
                <Form.Item
                  label="Lease Start"
                  name="leaseStartDate"
                  data-testid="leaseStartDateTest"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a date.',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => disableLeaseStartDate(current, form)}
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    data-testid="leaseStartDatePickerTest"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Lease End"
                  name="leaseEndDate"
                  data-testid="leaseEndDateTest"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a date.',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => disableLeaseEndDate(current, form)}
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    data-testid="leaseEndDatePickerTest"
                    // disabled={!form.getFieldValue('leaseStartDate')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <ExtendItem name="extendMonth" valuePropName="checked">
              <Checkbox>
                <Text size={16} color="#595959">
                  Remove renter at end of lease.
                </Text>
                <Tooltip
                  placement="top"
                  title="Closes tenants billing account and removes them from the property"
                  trigger="click"
                >
                  <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ccTooltip" />
                </Tooltip>
              </Checkbox>
            </ExtendItem>
            <ExtendItem name="requireInsurance" valuePropName="checked">
              <Checkbox>
                <Text size={16} color="#595959">
                  Require Renters Insurance
                </Text>
              </Checkbox>
            </ExtendItem>
            <Row justify="center">
              <UploadCol span={24}>
                <UploadButton
                  config={{
                    accept: '.pdf,.jpg,.jpeg,.png',
                    showUploadList: true,
                    multiple: false,
                    fileList: uploadState.fileList,

                    // customRequest: (info) => customRequest(info),
                    onRemove: (file) => {
                      const index = uploadState.fileList.indexOf(file);
                      const newFileList = uploadState.fileList.slice();
                      newFileList.splice(index, 1);
                      setUploadState({ ...uploadState, fileList: newFileList });
                    },
                    beforeUpload: (file) => {
                      FileType.fromBlob(file).then((type) => {
                        let valid = true;
                        if (type === undefined) {
                          valid = false;
                          displayAlert('Invalid file type', 'warning');
                        }

                        if (
                          type?.ext !== 'pdf' &&
                          type?.ext !== 'jpg' &&
                          type?.ext !== 'jpeg' &&
                          type?.ext !== 'png'
                        ) {
                          valid = false;
                          displayAlert('Invalid file type', 'warning');
                        }
                        if (file.size / 1024 / 1020 > 10) {
                          valid = false;
                          displayAlert('File Size Exceeds 10 MB', 'warning');
                        }
                        if (valid) setUploadState({ ...uploadState, fileList: [...uploadState.fileList, file] });
                        return false;
                      });
                      return false;
                    },
                  }}
                  text="Upload Lease"
                  type="thin"
                  // uploading={uploading}
                  disabled={uploadState.fileList.length}
                />
              </UploadCol>
            </Row>
            <PropertyDetails
              property={selectedProp}
              cognitoToken={cognitoToken}
              handleChangePropertyDetails={handleChangePropertyDetails}
            />
          </FormContentContainer>
          <PanelFooter>
            <AlertFade
              type={message.type}
              isVisible={!!message.message}
              message={message.message}
              alertPosition="absolute"
              position="absolute"
              minHeight="100"
              width={90}
              bottom={82}
            />

            <SaveItem className="save-form-item">
              {/**
               <Button
                type="primary"
                htmlType="submit"
                bottom={0}
                color="violet"
                loading={isLoading}
                alignment="center"
                data-testid="saveButtonTest"
              >
                SAVE
              </Button>               
               */}

              <DropdownButton
                text="Save"
                items={[{ key: '1', action: 'Save and Add a Transaction' }]}
                disabled={isLoading}
                loading={isLoading}
                type="primary"
                size="large"
                handleMenuClick={handleMenuClick}
                handleButtonClick={handleButtonClick}
              />
            </SaveItem>
          </PanelFooter>
        </StyledForm>
      </DrawerContainer>
    </>
  );
};

const DrawerContainer = styled.div`
  max-width: 100%;
  height: 100%;

  .ant-input,
  .ant-select-selection-item,
  input {
    font-size: 16px;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-select-selector {
    height: 31px !important;
    color: #595959;
  }

  @media screen and (max-height: 750px) {
    .ant-form-item.save-form-item {
      margin-bottom: 0px !important;
    }
  }
`;

const FormContentContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  height: 50px;
  padding: 0px 25px 0px 25px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const UploadCol = styled(Col)`
  > span {
    display: flex;
    justify-content: center;
  }
  .ant-upload-list-item-info {
    max-width: 240px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const ExtendItem = styled(Form.Item)`
  margin-bottom: 12px;
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

const Header = styled.div`
  color: white;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 2px;
  font-weight: 600;
`;

const StyledRow = styled(Row)`
  margin-bottom: -14px;
`;

export default InvitationPanel;
