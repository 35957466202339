import { combineReducers } from 'redux';

// Reducers
import landlord from 'store/reducers/landLordReducer';
import global from './reducers/globalReducer';
import dashboard from './reducers/dashboardReducer';
import session from './reducers/sessionReducer';
import tenant from './reducers/tenantReducer';

// Actions
import { LOG_OUT, LOGIN } from './actions';

const combinedReducers = combineReducers({
  global,
  session,
  dashboard,
  landlord,
  tenant,
});

const rootReducer = (state, action) => {
  const finalState = action.type === LOG_OUT || action.type === LOGIN ? undefined : state; // Clear the state
  return combinedReducers(finalState, action);
};

export default rootReducer;
