import React from 'react';

const HelpAndFeedback = (props) => {
  return (
    <div>
      <h1>
        HelpAndFeedback
      </h1>
    </div>
  );
};

export default HelpAndFeedback;
