import React from 'react';
import MaskedInput from 'antd-mask-input';
import PropTypes from 'prop-types';

// Date input in US format
function LocalDateInput(props) {
  // console.log('[LocalDateInput.js]', props);
  return <MaskedInput className="masked-input ant-input ant-input-success" {...props} />;
}

export default LocalDateInput;

LocalDateInput.propTypes = {
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  prefix: PropTypes.string,
};
LocalDateInput.defaultProps = {
  placeholder: 'mm/dd/yyyy',
  mask: '00/00/0000',
  prefix: ' ',
};
