import { EditOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';
import React from 'react';

function EditProperty(props) {
  const {
    setDrawerVisible,
    setShowPropertyPanel,
    setEditProperty,
    setDropdownVisible,
    resetDropdown,
    properties,
    id,
  } = props;

  const handleEditProperty = () => {
    setDrawerVisible(true);
    setShowPropertyPanel(true);
    setEditProperty(id);
    setDropdownVisible(resetDropdown(properties.items));
  };

  return <IconButton toolTitle="Edit Property" handleClick={handleEditProperty} icon={<EditOutlined />} />;
}

export default EditProperty;
