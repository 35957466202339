import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getStripeLink } from 'services/api/landlordApi/read';
import { stripeOptIn } from 'services/api/landlordApi/create';
import { displayAlert } from 'resources/helpers';

import Button from 'components/Button';
import Text from 'components/Text';
import AlertFade from 'components/AlertFade';
import PanelFooter from 'components/Panel/PanelFooter';
import StripeDetails from '../../StripeDetails';

const StripeSetupPanel = (props) => {
  const {
    open,
    setShowStripePanel,
    stripeTimer,
    propertyGroup,
    setFinished,
    setOnboardingState,
    setRequiredDocs,
    fetchOnboardingStatus,
  } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [stripeState, setStripeState] = useState(null);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const retries = useRef(0);

  useEffect(() => {
    fetchOnboardingStatus();
    if (retries.current === 0) {
      setLoading(true);
      setTimeout(() => {
        fetchData();
      }, 1500);
    }
  }, []);

  const fetchData = async () => {
    try {
      if (stripeTimer.current) clearInterval(stripeTimer.current);

      stripeTimer.current = setInterval(async () => {
        try {
          if (retries.current >= 5) {
            clearInterval(stripeTimer.current);
            setLoading(false);
            setDisabled(true);
            displayAlert(setAlert, 'Not available. Try again later.', 'warning', 5000);
            return;
          }
          const stripeLink = await getStripeLink(cognitoToken, +propertyGroup[0].id);

          if (stripeLink?.url) {
            clearInterval(stripeTimer.current);
            setStripeState(stripeLink);
          }
          setLoading(false);
        } catch (error) {
          const r = retries.current + 1;
          retries.current = r;
        }
      }, 2000);
    } catch (error) {
      console.log(error);
      clearInterval(stripeTimer.current);
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    if (stripeState) {
      if (stripeState.url) {
        setLoading(true);
        window.location.href = stripeState.url;
      }
    }
  };

  const handleOptIn = async () => {
    try {
      // api call
      setLoading(true);
      await stripeOptIn(cognitoToken, +propertyGroup[0].id, false);
      const res = await fetchOnboardingStatus();
      setLoading(false);
      // show finished
      setShowStripePanel(false);
      if (res?.ach?.state === 'DOCUMENTS_REQUIRED' || res?.ach?.state === 'REVIEW') {
        setRequiredDocs(true);
      } else {
        setFinished(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <StripeDetails />
      <PanelFooter>
        <AlertFade
          isVisible={alert.isVisible}
          message={alert.message}
          type={alert.type}
          minHeight="80"
          alertPosition="absolute"
          bottom={150}
        />
        <Button
          color="violet"
          bottom={0}
          htmlType="submit"
          loading={loading}
          disabled={disabled}
          onClick={handleRedirect}
        >
          CONTINUE
        </Button>
        <Text color="violet" size="12" underline pointer onClick={() => handleOptIn()}>
          No Thanks
        </Text>
      </PanelFooter>
    </>
  );
};

export default StripeSetupPanel;
