import React from 'react';
import { Button, Flex } from 'antd';
import Title from 'components/Title/Title';
import Text from 'components/Text/Text';
import { ReactComponent as Blueprint } from 'assets/images/blueprint.svg';
import MigrationFaqs from './components/MigrationFAQs';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Link } from 'react-router-dom';
import { StyledCard } from './WepayOffboarding.styles';

function MigrationStart(props) {
  return (
    <StyledCard>
      <div style={{ textAlign: 'center' }}>
        <Icon component={Blueprint} style={{ fontSize: '64px', margin: '24px' }} />
        <Title color="black" level={4} strong>
          We've Upgraded Our Payment Processing
        </Title>
        <Text color="black" size={16}>
          We are committed to making rent collection and payments better for you and your tenants. Based on
          customer feedback we've made significant improvements to our payment processing.
        </Text>
      </div>
      <Flex gap="24px" wrap="wrap">
        <div>
          <Text as="div" color="black" strong>
            Here's what's in it for you:
          </Text>
          <ul>
            <li>Fewer NSF returns</li>
            <li>Lower debit card fees</li>
            <li>Settlement reporting</li>
            <li>Same-day ACH funding option</li>
          </ul>
        </div>

        <div>
          <Text as="div" color="black" strong>
            What you'll need to complete the process:
          </Text>
          <ol>
            <li>Owner Name, Address and Phone</li>
            <li>Owner SSN and Business EIN</li>
            <li>Bank account information</li>
          </ol>
        </div>
      </Flex>
      <div style={{ textAlign: 'center' }}>
        <Button type="primary" size="large">
          <Link to="/landlord/wepay-migration/wizard">Get Started</Link>
        </Button>
      </div>
      <MigrationFaqs />
    </StyledCard>
  );
}

export default MigrationStart;
