import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import Link from 'components/Link';

import { capSentence } from 'utils';

import CardWithMenu from 'components/CardWithMenu';

import { getBillingItems } from 'services/api/tenantApi';

const transformString = (str) => {
  const lowerCase = str.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

const transformChargeString = (str) => {
  const newStr = str.replace('_', ' ');

  const upperStr = capSentence(newStr);
  if (str === 'MISC_FEE') {
    return 'Fee';
  }
  return upperStr;
};

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (bi) => {
      let style = 'color-green weight-500';
      if (bi.billingItemType === 'CHARGE' || bi.state === 'NOT_PROCESSED') {
        style = 'color-red weight-500';
      }
      return (
        <span className={style}>
          {bi.billingCharge
            ? transformChargeString(bi.state === 'NOT_PROCESSED' ? 'Failed' : bi.billingCharge.chargeType)
            : transformString(bi.state === 'NOT_PROCESSED' ? 'Failed' : bi.billingItemType)}
        </span>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    render: (pbi) => {
      let style = 'color-green weight-500';
      if (pbi.billingItemType === 'CHARGE') {
        style = 'color-red weight-500';
      }
      return (
        <span className={style}>
          {pbi.billingItemType === 'CREDIT' && '-'}$
          {pbi.billingCharge ? pbi.billingCharge?.amount.toFixed(2) : pbi?.payment?.amount}
        </span>
      );
    },
  },
];

const RecentTransactions = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [recentBillingItems, setRecentBillingItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    fetchData();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useImperativeHandle(ref, () => ({
    fetchData: fetchData,
  }));

  const filterString = (value) => {
    switch (value) {
      case 'Credits':
        return '+billingItemType:CREDIT';
      case 'Charges':
        return '+billingItemType:CHARGE';
      case 'All':
      default:
        return null;
    }
  };
  const fetchData = async (filter) => {
    try {
      setLoading(true);

      const recentRes = await getBillingItems(
        'state:PROCESSING,state:PROCESSED,state:NOT_PROCESSED,state:POSTED',
        filterString(filter),
        'orderDate',
        'payment,billingCharge',
        10
      );

      let billingData = [];

      if (recentRes.length) {
        billingData = recentRes.map((pbi) => {
          return {
            key: pbi.id,
            date: moment(pbi.orderDate).format('MM-DD-YYYY'),
            type: pbi,
            amount: pbi,
          };
        });
      }
      setRecentBillingItems(billingData);
      setLoading(false);
      if (!mountedRef.current) return null;
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <CardWithMenu
      id="transactionHistory"
      title="Recent Transactions"
      handleChangeFilter={fetchData}
      styles={{
        header: {
          fontSize: '20px',
          color: '#541388',
          fontWeight: 600,
        },
      }}
      className="recentTransactions"
      items={[
        {
          text: 'All',
          link: '#',
        },
        {
          text: 'Credits',
          link: '#',
        },
        {
          text: 'Charges',
          link: '#',
        },
      ]}
    >
      <StyledTable
        columns={columns}
        dataSource={recentBillingItems}
        pagination={false}
        size="small"
        loading={loading}
      />
      <Link
        to="/tenant/transactions"
        end="true"
        onClick={() => dispatch({ type: 'SET_SELECTED_MENU', payload: 'transactions' })}
        style={{ marginTop: '4px' }}
      >
        More
      </Link>
    </CardWithMenu>
  );
});

const StyledTable = styled((props) => <Table {...props} data-testid="recentTransactionsTable" />)`
  th.ant-table-cell {
    font-weight: 600;
    font-size: 14px;
  }

  @media screen and (max-width: 400px) {
    .ant-table-row-expand-icon-cell {
      span {
        font-size: 18px !important;
      }
    }

    tr.ant-table-expanded-row {
      td.ant-table-cell {
        border: 2px solid #efefef;
      }
    }

    td.ant-table-cell {
      font-size: 13px !important;
      span {
        font-size: 13px !important;
      }
    }
    td.ant-table-row-expand-icon-cell {
      span {
        font-size: 18px !important;
      }
    }
  }
`;

export default RecentTransactions;
