import React from 'react';
import { Card } from 'antd';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';

function InsuranceDetails(props) {
  const { record, insurancePolicy, loading, insuranceFileAvailable, handleDownloadInsurancePolicy } = props;
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <Card bordered={false} loading={loading} style={{ height: '96px', paddingTop: '5px' }}>
              <Card.Meta
                description={
                  <>
                    <strong>Policy Number:</strong>{' '}
                    <Text color="black" pointer size={14}>
                      {insurancePolicy?.policy?.policyNumber && insurancePolicy.policy.policyNumber}
                    </Text>
                    <br /> <strong>Status:</strong>{' '}
                    <Text color="black" pointer size={14}>
                      {insurancePolicy?.policy?.status && insurancePolicy.policy.status}
                    </Text>
                    <br /> <strong>Carrier:</strong>{' '}
                    <Text color="black" pointer size={14}>
                      {insurancePolicy?.policy?.carrier && insurancePolicy.policy.carrier}
                    </Text>
                    <br /> <strong>Liability Amount:</strong>{' '}
                    <Text color="black" pointer size={14}>
                      {insurancePolicy?.policy?.liability && `$${insurancePolicy.policy.liability}`}
                    </Text>
                  </>
                }
              />
            </Card>
          </td>
          <td>
            <Card bordered={false} loading={loading} style={{ height: '96px', paddingTop: '5px' }}>
              <Card.Meta
                description={
                  <>
                    <strong>Start Date:</strong>{' '}
                    <Text color="black" pointer size={14}>
                      {insurancePolicy?.policy?.startDate && insurancePolicy.policy.startDate}
                    </Text>
                    <br /> <strong>End Date:</strong>{' '}
                    <Text color="black" pointer size={14}>
                      {insurancePolicy?.policy?.endDate && insurancePolicy.policy.endDate}
                    </Text>
                    <br /> <strong>Billing Cycle:</strong>{' '}
                    <Text color="black" pointer size={14}>
                      {insurancePolicy?.policy?.billingCycle
                        ? insurancePolicy.policy.billingCycle === 'monthly'
                          ? 'Monthly (11 cyles)'
                          : 'Annual'
                        : ''}
                    </Text>
                  </>
                }
              />
            </Card>
          </td>
          <td style={{ verticalAlign: 'bottom', textAlign: 'center', minWidth: '150px' }}>
            {!loading && insuranceFileAvailable && (
              <Button
                color="violet"
                disabled={!insuranceFileAvailable}
                onClick={() => handleDownloadInsurancePolicy(record, 'download')}
                data-testid="downloadButton"
              >
                View Policy
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default InsuranceDetails;
