import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Confetti from 'react-confetti';

import { Card as AntCard, Progress, Space as AntSpace } from 'antd';

import Text from 'components/Text';
import RentCredTitle from '../RentCredTitle/RentCredTitle';
import PaymentCounter from './components/PaymentCounter/PaymentCounter';
import RentCredBadge from '../RentCredBadge/RentCredBadge';
import { motion } from 'framer-motion';

const RentCredStatusAnimation = (props) => {
  const { setShowRentCredStep } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const tenant = useSelector((store) => store.tenant);
  const rentCredStatus = useSelector((store) => store.tenant.rentCred);
  const [isMilestone, setIsMilestone] = useState(false);
  const [paymentCount, setPaymentCount] = useState(0);
  const milestones = [1, 3, 6, 9];
  const statusCalled = useRef(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const mountedRef = useRef(true);

  useEffect(() => {
    if (session.userData.id && rentCredStatus.renterTierId === 0 && !statusCalled.current) {
      setWidth(document.querySelector('.content-col').offsetWidth);
      setHeight(document.querySelector('.content-col').offsetHeight);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [session]);

  useEffect(() => {
    // manually set points/tier for testing

    const params = new URLSearchParams(history.location.search);
    if (params.get('tier-id')) {
      rentCredStatus.renterTierId = +params.get('tier-id');
    }
    if (params.get('points')) {
      rentCredStatus.points = +params.get('points');
    }
    dispatch({ type: 'SET_RENTCRED_DATA', payload: rentCredStatus });
    // end of manual set points/tier for testing

    setPaymentCount(rentCredStatus.points);

    if (!tenant.rentCredMilestone && milestones.includes(rentCredStatus.points)) {
      setIsMilestone(true);
      dispatch({
        type: 'SET_RENTCRED_MILESTONE',
        payload: true,
      });
    }
    if (!mountedRef.current) return () => null;
  }, [rentCredStatus]);

  // const fetchData = async () => {
  //    const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  //   try {
  //     let tierId = null;
  //     let points = null;

  //     const params = new URLSearchParams(history.location.search);

  //     if (params.get('tier-id')) {
  //       tierId = params.get('tier-id');
  //     }
  //     if (params.get('points')) {
  //       points = params.get('points');
  //     }

  //     statusCalled.current = true;
  //     const res = await getRentCred(cognitoToken, session.userData.id);

  //     if (tierId) res.renterTierId = +tierId;
  //     if (points) res.points = +points;

  //     setRentCredStatus(res);
  //     setPaymentCount(res.points);
  //     if (!tenant.rentCredMilestone && milestones.includes(res.points)) {
  //       setIsMilestone(true);
  //       dispatch({
  //         type: 'SET_RENTCRED_MILESTONE',
  //         payload: true,
  //       });
  //     }
  //     if (!mountedRef.current) return null;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <StyledRentCredCard
      title={<RentCredTitle subtitle="Rewards" />}
      className="content-col rent-cred-status"
      extra={<RentCredBadge />}
    >
      {isMilestone && (
        <Confetti
          width={width}
          height={height * 1.5}
          initialVelocityX={7}
          initialVelocityY={7}
          confettiSource={{ x: 0, y: 10, w: width, h: height }}
          recycle={false}
        />
      )}
      <StyledContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} key="rent-cred-container">
        <StyledProgress
          type="circle"
          percent={+`${rentCredStatus.points === 9 ? rentCredStatus.points + 1 : rentCredStatus.points}0`}
          format={() =>
            isMilestone ? <PaymentCounter paymentCount={paymentCount} /> : `${rentCredStatus.points}`
          }
          strokeColor="#541388"
          strokeWidth={10}
          size={48}
        />
        <AntSpace direction="vertical" size={0}>
          <Text color="black" size={14} weight={500}>
            On-time Payments
          </Text>

          {paymentCount < 1 ? (
            <Text
              color="violet"
              size={14}
              weight={500}
              underline
              pointer
              onClick={() => setShowRentCredStep(true)}
            >
              What´s this
            </Text>
          ) : (
            <div>
              <Text color="black" size={14} weight={500}>
                qualifies you for a few{' '}
              </Text>
              <Text
                color="violet"
                size={14}
                weight={500}
                underline
                onClick={() => history.push('/tenant/rentcred')}
                pointer
              >
                extra perks
              </Text>
            </div>
          )}
        </AntSpace>
      </StyledContainer>
    </StyledRentCredCard>
  );
};

const StyledProgress = styled(Progress)`
  .ant-progress-text {
    font-size: 28px;
    font-weight: 600;
  }
  .ant-progress-text {
    color: #000 !important;
  }
`;

const StyledContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 18px;
`;

const StyledRentCredCard = styled(AntCard)`
  .ant-card-head-title {
    padding-top: 5px;
    padding-bottom: 0px;
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

export default RentCredStatusAnimation;
