import * as actions from '../actions';

const initalState = {
  userRole: '',
  userData: {
    state: '',
    property: {
      id: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      rentAmount: 0,
      rentDueDay: 0,
      paymentSettings: {
        absorbFeesPercent: '100',
        preventPayments: false,
        requireFullPayment: false,
        absorbFees: false,
        acceptCreditCard: false,
        compliance: {},
      },
    },
    billingAccounts: [],
    cognito: {},
    loading: false,
    paymentGateway: '',
    id: '',
    paymentSettings: {
      preventPayments: false,
      requireFullPayment: false,
      absorbFees: false,
      absorbFeesPercent: '100',
      acceptCreditCard: false,
    },
    notificationSettings: {
      paymentConfirmations: false,
    },
    servicePlan: {
      achRateFee: '0.00',
      achTrxFee: '0.00',
      cap: '0.00',
      creditCardRateFee: '0.00',
      creditCardTrxFee: '0.00',
      id: '0',
      name: '',
      state: '',
      subscriptionFee: '0.00',
      agent: '',
      createdAt: '',
      debitRateFee: '0.00',
      debitTrxFee: '0.00',
      office: '',
      propUnitPrice: 0,
      properties: 0,
      stripePriceId: '',
      stripeProductId: '',
      processor: '',
      trial: 0,
      type: '',
      version: '',
      metaFeatures: {
        version: '2.0',
        upgradable: false,
        pAYBYText: false,
        sUBAccount: false,
        fLEXFunding: false,
        bALANCECheck: false,
        rENTALListing: false,
        rENTReminders: false,
        aCHCCPayments: false,
        eVICTIONAssist: false,
        eXPRESSFunding: false,
        pAYMENTControls: false,
        rENTERPAYSFees: false,
        aDVANCEDReporting: false,
        sCHEDULEDPayments: false,
        rENTERTIERUpgrade: false,
        aCCOUNTINGIntegration: false,
        aDDITIONALAssessments: false,
        oNTIMERENTGuarantee: false,
        tENANTISSUEMGTComms: false,
        mULTIPLEPROPERTYGroup: false,
        rENTCOLLECTIONPOLICYManagement: false,
      },
    },
    ssnToken: null,
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case actions.SET_USER_SESSION_DATA:
      return {
        ...state,
        isAuthenticated: true,
        userRole: action.role,
        userData: {
          ...state.userData,
          ...action.userData,
        },
        cognitoTokens: action.cognitoTokens,
        oldTokens: action.oldTokens,
      };
    case actions.UPDATE_USER_SESSION_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          cognito: {
            ...state.userData.cognito,
            ...action.userData,
          },
        },
      };
    case actions.UPDATE_USER_COGNITO_TOKENS:
      return {
        ...state,
        cognitoTokens: {
          ...state.cognitoTokens,
          ...action.cognitoTokens,
        },
      };
    case actions.GET_USER_SESSION:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
          loading: false,
        },
      };
    case actions.SET_SESSION_LOADER:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: action.payload,
        },
      };
    case actions.GET_SERVICE_PLAN_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          servicePlan: action.payload,
        },
      };
    case actions.UPDATE_NOTIFICATIONS_SETTINGS:
      const newState = { ...state };
      const { notificationsGroup } = action.payload;
      newState.userData.notificationSettings.paymentConfirmations = notificationsGroup.some(
        (value) => value === 'paymentConfirmations'
      );
      newState.userData.notificationSettings.rentReminders = notificationsGroup.some(
        (value) => value === 'rentReminders'
      );
      return newState;
    default:
      return state;
  }
};
