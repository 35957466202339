import axios from 'axios';
import Jsona from 'jsona';

const dataFormatter = new Jsona();
export const adminFetchUserData = async (id) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_ADMIN_KEY,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/admin/users/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const adminFetchUserServicePlan = async (id) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_ADMIN_KEY,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/admin/service-plans/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};
