import React from 'react';
import { Button, Space } from 'antd';
import { CrownFilled, SignatureOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Text from 'components/Text/Text';
import IconButton from 'components/uielements/IconButton/IconButton';
import { useSelector } from 'react-redux';

function EsignDocument(props) {
  // console.log('[EsignDocument.js]', props);
  const { setShowPanel, setEditDocument, setDrawerVisible, record } = props;
  const session = useSelector((store) => store.session);
  const actionDisabled = !session.userData?.servicePlan?.metaFeatures.eSIGNDocument;

  const handleEsignDocument = () => {
    // console.log('ƒ handleClick E-sign document', props.record);
    setShowPanel('sign');
    setDrawerVisible(true);
    setEditDocument(record);
  };

  return (
    <IconButton
      showPopover={actionDisabled}
      content={
        <>
          <Text size="small" as="div">
            Get Unlimited E-sign documents!
          </Text>
          <div style={{ margin: '12px', textAlign: 'center' }}>
            <Link to="/landlord/service-plans">
              <Button style={{ background: '#ffcc00' }}>Upgrade</Button>
            </Link>
          </div>
        </>
      }
      popTitle={
        <Space>
          <CrownFilled />
          <Text strong color="black">
            Premium Feature
          </Text>
        </Space>
      }
      icon={<SignatureOutlined />}
      toolTitle="E-sign Document"
      handleClick={handleEsignDocument}
      disabled={actionDisabled}
      {...props}
    />
  );
}

export default EsignDocument;
