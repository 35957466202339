import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Row, Col, Card } from 'antd';

import { getOnboardingStatusAction } from 'store/actions/landlordActions';

import { getBoardingStatus } from 'services/api/landlordApi/read';

import { CloseOutlined } from '@ant-design/icons';

import PropertyImage from 'assets/images/onboarding-overlay/property.svg';
import BankImage from 'assets/images/onboarding-overlay/bank.svg';
import InviteImage from 'assets/images/onboarding-overlay/invite.svg';

import CardWithTitle from 'components/CardWithTitle';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
// import CardBlank from 'components/CardBlank';

// import './styles.scss';
import { useHistory, useLocation } from 'react-router';

const OnboardingOverlay = (props) => {
  const { setShowOnboardingOverlay, setDrawerVisible, setShowOnboarding, isDesktop } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const history = useHistory();
  const location = useLocation();
  const [disableMerchantProfile, setDisableMerchantProfile] = useState(true);
  const onboardingStatus = useSelector((store) => store.landlord.onboardingStatus);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [allSet, setAllSet] = useState(false);

  useEffect(() => {
    dispatch(getOnboardingStatusAction(session.cognitoTokens.idToken.jwtToken));
    checkMerchantProfileStep();
  }, []);

  const handleCloseButton = () => {
    setShowOnboardingOverlay(false);
  };

  const checkMerchantProfileStep = async () => {
    try {
      const boardingStatusRes = await getBoardingStatus(cognitoToken);

      if (boardingStatusRes.id && session.userData.state === 'active') {
        setDisableMerchantProfile(true);
      } else {
        setDisableMerchantProfile(false);
      }
    } catch (error) {
      if (error?.data?.errors[0]?.detail === 'User has not been boarded yet') {
        setDisableMerchantProfile(false);
      }
    }
  };

  const disableButton = (step) => {
    switch (onboardingStatus[`step${step}`].status) {
      case 'finished':
        return true;
      case 'in progress':
        return true;
      case 'in progess':
        return true;
      case 'pending':
      case 'waiting':
        return false;
      default:
        return true;
    }
  };

  const selectStep = (step) => {
    dispatch({
      type: 'SET_CURRENT_ONBOARDING_STEP',
      payload: {
        selectedStep: step,
      },
    });
    setDrawerVisible(true);
    setShowOnboarding(true);
  };

  const showFinalMessage = () => {
    if (
      onboardingStatus?.step1?.status === 'finished' &&
      onboardingStatus?.step2?.status === 'finished' &&
      onboardingStatus?.step3?.status === 'finished' &&
      onboardingStatus?.current === 'finished'
    ) {
      setAllSet(true);
    } else {
      setAllSet(false);
    }
  };

  useEffect(() => {
    showFinalMessage();
  }, [onboardingStatus]);

  return (
    <StyledOverlay>
      <StyledContainer>
        {isDesktop && (
          <CloseOutlined
            style={{
              fontSize: '25px',
              color: 'dimgray',
              position: 'absolute',
              top: '25px',
              left: '25px',
              cursor: 'pointer',
              zIndex: 50,
            }}
            onClick={handleCloseButton}
          />
        )}
        <StyledRow justify="center" align="center" finished={onboardingStatus.current}>
          {!isDesktop && (
            <Button type="link" onClick={handleCloseButton}>
              Hide this for now
            </Button>
          )}
          <Col span={24}>
            <Row
              justify="center"
              gutter={[
                { xxl: 60, xl: 60, lg: 10, md: 10, sm: 10 },
                { xxl: 0, xl: 0, lg: 0, md: 10, sm: 10, xs: 10 },
              ]}
              style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}
              className="overlay-row"
            >
              <Col xxl={7} xl={7} lg={8} md={10} sm={20}>
                <CardWithTitle
                  title="Step 1"
                  headFontSize={28}
                  headAlign="center"
                  bodyMinHeight={200}
                  style={{ zIndex: 2, borderRadius: '4px' }}
                  type="pricing"
                  bodyPadding="15px 24px"
                  // className="pricing-columns"
                >
                  <Text color="black" size={14} centered>
                    Add your first property
                  </Text>
                  <Space vertical={15} />
                  <Row justify="center" align="middle">
                    <Col span={18} style={{ textAlign: 'center' }}>
                      <img src={PropertyImage} alt="Best Value" style={{ width: '80px' }} />
                    </Col>
                  </Row>
                  <Space vertical={15} />
                  <Text color="black" size={14} centered>
                    Add a rental unit and set rent amount, due date and other payment controls.
                  </Text>
                  <Space vertical={15} />
                  <Button
                    color="violet"
                    alignment="center"
                    bottom={0}
                    disabled={disableButton(1)}
                    onClick={() => selectStep(1)}
                    data-testid="propertyButtonTest"
                  >
                    ADD A PROPERTY
                  </Button>
                </CardWithTitle>
              </Col>
              <Col xxl={7} xl={7} lg={8} md={10} sm={20}>
                <CardWithTitle
                  title="Step 2"
                  headFontSize={28}
                  headAlign="center"
                  bodyMinHeight={200}
                  style={{ zIndex: 2, borderRadius: '4px' }}
                  type="pricing"
                  bodyPadding="15px 24px"
                  // className="pricing-columns"
                >
                  <Text color="black" size={14} centered>
                    Invite a renter to join
                  </Text>
                  <Space vertical={15} />
                  <Row justify="center" align="middle">
                    <Col span={18} style={{ textAlign: 'center' }}>
                      <img src={InviteImage} alt="Best Value" style={{ width: '80px' }} />
                    </Col>
                  </Row>
                  <Space vertical={15} />
                  <Text color="black" size={14} centered>
                    Invite the renter from your property to join the service. We’ll send the invite.
                  </Text>
                  <Space vertical={15} />
                  <Button
                    color="violet"
                    alignment="center"
                    bottom={0}
                    disabled={onboardingStatus.step2.status === 'finished'}
                    onClick={() => selectStep(2)}
                    data-testid="step2ButtonTest"
                  >
                    INVITE A RENTER
                  </Button>
                </CardWithTitle>
              </Col>
              <Col xxl={7} xl={7} lg={8} md={10} sm={20}>
                <CardWithTitle
                  title="Step 3"
                  headFontSize={28}
                  headAlign="center"
                  bodyMinHeight={200}
                  style={{ zIndex: 2, borderRadius: '4px' }}
                  type="pricing"
                  bodyPadding="15px 24px"
                  // className="pricing-columns"
                >
                  <Text color="black" size={14} centered>
                    Set up your payment processing
                  </Text>
                  <Space vertical={15} />
                  <Row justify="center" align="middle">
                    <Col span={18} style={{ textAlign: 'center' }}>
                      <img src={BankImage} alt="Best Value" style={{ width: '80px' }} />
                    </Col>
                  </Row>
                  <Space vertical={15} />
                  <Text color="black" size={14} centered>
                    Set up your merchant account to accept credit cards and bank transfers.
                  </Text>
                  <Space vertical={15} />
                  <Button
                    color="violet"
                    alignment="center"
                    bottom={0}
                    disabled={(disableButton(3) && disableButton(4)) || disableMerchantProfile}
                    onClick={() => selectStep(3)}
                    data-testid="step3ButtonTest"
                  >
                    ACCEPT PAYMENTS
                  </Button>
                </CardWithTitle>
              </Col>
            </Row>
            {allSet && (
              <Row
                align="middle"
                justify="center"
                style={{ width: '100%', marginTop: '20px', marginLeft: '0px', marginRight: '0px' }}
                gutter={{ xxl: 60, xl: 60, lg: 10, md: 10, sm: 10 }}
              >
                <Col xxl={21} xl={21} lg={24} md={24} sm={24}>
                  <StyledCard
                    styles={{
                      body: {
                        padding: '15px 0px 15px 0px',
                        fontSize: '16px',
                      },
                    }}
                  >
                    <Row
                      align="middle"
                      justify="center"
                      gutter={[
                        { xxl: 60, xl: 60, lg: 10, md: 10, sm: 10 },
                        { xxl: 0, xl: 0, lg: 0, md: 10, sm: 20, xs: 20 },
                      ]}
                    >
                      <Col xxl={8} xl={8} lg={8} md={10} sm={10} xs={18}>
                        <Text color="black" size={24} centered strong>
                          You´re All Set!
                        </Text>
                        <Button color="violet" alignment="center" bottom={0} onClick={handleCloseButton}>
                          GO TO MY DASHBOARD
                        </Button>
                      </Col>
                      <Col xxl={8} xl={8} lg={8} md={10} sm={10} xs={18} className="congratulations-text">
                        <Text color="black" size={14}>
                          Congratulations on completing all the steps for setting up your account. Please let us
                          know how we can help make your experience the best it can be.
                        </Text>
                      </Col>
                      <Col xxl={8} xl={8} lg={8} md={10} sm={10} xs={18}>
                        <Text color="black" size={20} centered strong>
                          Service Plan
                        </Text>
                        <Text color="black" size={14} centered>
                          {session.userData.servicePlan.name}
                        </Text>
                        <Text color="black" size={14} centered>
                          {session.userData.servicePlan.subscriptionFee} per month
                        </Text>
                        <Text color="black" size={14} centered>
                          ${session.userData.servicePlan.achTrxFee} ACH/{' '}
                          {(+session.userData.servicePlan.creditCardRateFee * 100).toFixed(2)}%+$
                          {session.userData.servicePlan.creditCardTrxFee} Credit Card
                        </Text>
                      </Col>
                    </Row>
                  </StyledCard>
                </Col>
              </Row>
            )}
          </Col>
        </StyledRow>
      </StyledContainer>
    </StyledOverlay>
  );
};

const StyledCard = styled(Card)`
  border: 1px solid #d9d9d9;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1420px;
  margin: 0 auto;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const StyledOverlay = styled.div`
  background: #dfdfdf;
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  animation: fadeIn linear 0.1s;
  -webkit-animation: fadeIn linear 0.2s;
  -moz-animation: fadeIn linear 0.2s;
  -o-animation: fadeIn linear 0.2s;
  -ms-animation: fadeIn linear 0.2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const StyledRow = styled(Row)`
  ${(props) => {
    if (props.finished === 'finished') {
      return css`
        @media screen and (max-width: 991px) {
          height: 100%;
          margin-top: 20px;
        }
      `;
    }
  }};
  @media screen and (max-width: 767px) {
    height: 100%;
    margin-top: 140px;
  }
`;

export default OnboardingOverlay;
