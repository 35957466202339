// @flow

// node_modules
import React, { useState, useEffect, useCallback } from 'react';
import { BankFilled, CreditCardFilled, InfoCircleFilled } from '@ant-design/icons';
import { Tooltip, Row, Drawer, message } from 'antd';
import { addACHMethod } from 'services/wepay';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { usePlaidLink } from 'react-plaid-link';

// Local services
import { getPlaidOauthLinkToken } from 'services/api/landlordApi/read';
import * as tenantApi from 'services/api/tenantApi';
import { getPaymentMethods, getPaymentMethodsHistory } from 'store/actions/tenantActions';
import { clearPanel } from 'store/actions/globalActions';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

// Local components
import Button from 'components/Button';
import PaymentMethodDetail from '../PaymentMethodDetail/PaymentMethodDetail';
import {
  createFinixBuyerIdentity,
  createPaymentInstrument,
  createPlaidFinixProcessorToken,
} from 'services/api/common/create';
import { createCustomerIdentitySchema } from 'services/finix/validators/identitySchema';
import { usStates } from 'resources/FormData';

const PaymentMethodLinks = (props) => {
  // console.log('[PaymentMethodLinks.js]', props);
  const dispatch = useDispatch();
  const paymentMethodsHistory = useSelector((store) => store.tenant.paymentMethodsHistory);
  const panel = useSelector((store) => store.global.panel);
  const session = useSelector((store) => store.session);
  const servicePlanData = useSelector((store) => store.session.userData.servicePlan);
  const tenant = useSelector((store) => store.tenant);
  const userEmail = useSelector((state) => state.session.userData.email);
  const gateway = useSelector((state) => state.session.userData.paymentGateway);
  const [plaidToken, setPlaidToken] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [newPaymentMethodType, setNewPaymentMethodType] = useState('');
  const [plaidProduct, setPlaidProduct] = useState(['auth']);
  const [messageApi, contextHolder] = message.useMessage();
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  // customizacion del css https://support.plaid.com/hc/en-us/articles/360008420353-Plaid-Link-customizatio

  let timerId = '';

  const openPanel = (value) => {
    switch (value) {
      case 'add-credit-card':
        setDrawerVisible(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);
    dispatch(getPaymentMethodsHistory(gateway, true, session.cognitoTokens.idToken.jwtToken));
    dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
    dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));

    if (panel) {
      openPanel(panel);
      dispatch(clearPanel());
    }
    return () => {
      controller.abort();
    };
  }, []);

  const fetchAndSetPlaidToken = useCallback(async () => {
    try {
      const plaidOauthToken = await getPlaidOauthLinkToken(session.cognitoTokens.idToken.jwtToken);
      setPlaidToken(plaidOauthToken.linkToken);
      localStorage.setItem('link_token', plaidOauthToken.linkToken);
    } catch (error) {
      console.log(error);
    }
  }, [session.cognitoTokens.idToken.jwtToken]);

  const fetchData = async () => {
    try {
      if (isOAuthRedirect) {
        setPlaidToken(localStorage.getItem('link_token'));
      } else if (session.userData.paymentGateway !== 'Wepay') {
        const linkToken = localStorage.getItem('link_token');
        if (!linkToken) {
          fetchAndSetPlaidToken();
        } else {
          setPlaidToken(linkToken);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (session.userData.paymentGateway === 'SilaStripe') {
      const microdepositPm = tenant.paymentMethods.filter((pm) => {
        if (pm.paymentGatewayMeta?.state) {
          if (
            pm.paymentGatewayMeta?.state === 'pending verification' &&
            pm.microDepositsFlow === true &&
            pm.state === 'SCHEDULED'
          ) {
            return true;
          }
          return false;
        }
        return false;
      });
      if (microdepositPm.length) {
        setPlaidProduct(['auth', 'transactions']);
      } else {
        setPlaidProduct(['auth']);
      }
    }
  }, [session.userData.paymentGateway, tenant]);

  const displayAlert = (message, type, description, persist) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    if (!persist) {
      setTimeout(() => {
        dispatch({
          type: 'UNSET_ALERT',
        });
      }, 5000);
    }
  };

  const isLoading = (value) => {
    dispatch({
      type: 'SET_LOADER',
      payload: value,
    });
  };

  const pollCheck = (pMethods) => {
    const filtered =
      pMethods.filter(
        (p) =>
          p &&
          p.hasOwnProperty('state') &&
          p.hasOwnProperty('microDepositsFlow') &&
          ((!p.microDepositsFlow && p.state === 'PENDING') || p.state === 'SCHEDULED')
      ).length > 0;
    return filtered;
  };

  const checkPaymentMethodStatus = async () => {
    const updated = dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
    const arr = updated?.payload ? updated.payload : [];
    const poll = pollCheck(arr);
    if (poll) {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        try {
          const methods = await tenantApi.getPaymentMethodsV3(
            gateway,
            false,
            session.cognitoTokens.idToken.jwtToken
          );

          let update = pollCheck(methods);
          if (!update) {
            dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
            clearInterval(timerId);
          }
        } catch (error) {
          console.log(error);
        }
      }, 3000);
    }
  };

  const handleAddBankAccount = async (plaid) => {
    try {
      if (props.setPaymentMethodsDrawerVisible) props.setPaymentMethodsDrawerVisible(false);
      // if (props.showPayMethods) props.showPayMethods(false);
      if (gateway === 'Wepay') {
        // Check if there is a pending, scheduled or deleted microdeposit ach
        const microDepositPm = paymentMethodsHistory.filter(
          (pm) =>
            (pm.microDepositsFlow === true && pm.paymentGatewayMeta?.state === 'pending') ||
            (pm.microDepositsFlow === true && pm.state === 'PENDING') ||
            (pm.microDepositsFlow === true && pm.state === 'SCHEDULED')
        );

        const disableMicrodeposits = microDepositPm.length > 0;

        const paymentMethod = await addACHMethod(userEmail, disableMicrodeposits);

        if (paymentMethod === null) {
          displayAlert(
            'Payment Method Error',
            'warning',
            'Something went wrong while adding your payment method!'
          );
          return;
        }

        let alertTitle = 'Payment Method Added';
        let alertType = 'success';
        let description = '';
        let microdeposits = false;
        let persist = false;

        if (paymentMethod.flow === 'microdeposits') {
          microdeposits = true;
          alertTitle = 'Payment Method Requires Verification';
          alertType = 'warning';
          persist = 'true';
          description =
            'We have sent 2 microdeposits to your account to verify ownership. Please check your email for details and follow the instructions to confirm this payment method.';
        }
        delete paymentMethod.flow;
        const res = await tenantApi.createPaymentMethod(paymentMethod);
        const pmethodId = res.id;

        if (props.defPaymentMethod) {
          await tenantApi.setDefaultPaymentMethodV3(pmethodId, session.cognitoTokens.idToken.jwtToken);
        }
        // dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
        dispatch(getPaymentMethodsHistory(gateway, true, session.cognitoTokens.idToken.jwtToken));
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        isLoading(false);

        displayAlert(alertTitle, alertType, description, persist);
        if (!microdeposits) checkPaymentMethodStatus();
      }
      if (gateway === 'Nuvei' || gateway === 'SilaStripe') {
        isLoading(true);
        try {
          const result = await tenantApi.createACHPaymentMethod(
            session.cognitoTokens.idToken.jwtToken,
            plaid,
            gateway
          );

          if (props.defPaymentMethod) {
            await tenantApi.setDefaultPaymentMethodV3(result.id, session.cognitoTokens.idToken.jwtToken);
          }
          // dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
          dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
          isLoading(false);

          displayAlert('Payment Method Added', 'success', '');
        } catch (e) {
          isLoading(false);
          displayAlert(
            'Payment Method Error',
            'warning',
            'Something went wrong while adding your payment method!'
          );
        }
      }

      if (gateway === 'Finix') {
        messageApi.open({
          content: 'Creating your payment method...',
          key: 'addPaymentMethod',
          duration: 0,
          type: 'loading',
        });
        const stateShort = usStates.filter((s) => s.name === session.userData.property.state.toUpperCase())[0]
          .abbreviation;

        const buyerIdentityRequest = {
          tags: {
            renter_id: session.userData.id,
          },
          entity: {
            first_name: session.userData.firstName.substring(0, 20) || '',
            last_name: session.userData.lastName.substring(0, 20),
            email: session.userData.email,
            phone: session.userData.phone,
            personal_address: {
              line1: session.userData.property.address.substring(0, 35),
              line2: session.userData.property.address2.substring(0, 35) || '',
              country: 'USA',
              region: stateShort,
              city: session.userData.property.city.substring(0, 20),
              postal_code: session.userData.property.zip.toString(),
            },
          },
        };

        await createCustomerIdentitySchema.validateAsync(buyerIdentityRequest);
        const identity = createFinixBuyerIdentity(session.cognitoTokens.idToken.jwtToken, buyerIdentityRequest);

        const processorTokenRequest = {
          publicToken: plaid.token,
          accounts: plaid.metadata.accounts,
        };

        const processorToken = createPlaidFinixProcessorToken(
          session.cognitoTokens.idToken.jwtToken,
          processorTokenRequest
        );

        const [processorTokenRes, identityRes] = await Promise.all([processorToken, identity]);
        // console.log('ƒ createPlaidFinixProcessorToken', processorTokenRes, identityRes);

        const paymentMethodRequest = {
          identity: identityRes.identityId,
          third_party: 'PLAID',
          third_party_token: processorTokenRes.processorToken,
          type: 'BANK_ACCOUNT',
          tags: {
            renter_id: session.userData.id,
            plaid_metadata: {
              access_token: processorTokenRes.accessToken.accessToken,
              item_id: processorTokenRes.accessToken.itemId,
              account_id: plaid.metadata.accounts[0].id,
            },
          },
        };
        await createPaymentInstrument(session.cognitoTokens.idToken.jwtToken, paymentMethodRequest);
        // console.log('ƒ createPlaidFinixPaymentInstrument', result);

        messageApi.open({
          content: 'Payment Method Added!',
          key: 'addPaymentMethod',
          duration: 2,
          type: 'success',
        });
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
        displayAlert('Payment Method Added', 'success', '');
      }
    } catch (err) {
      console.log(err);
      message.destroy('addPaymentMethod');
      displayAlert('Payment Method Error', 'warning', 'Something went wrong while adding your payment method!');
    }
  };

  const handleAddCreditCard = () => {
    // console.log('handleAddCreditCard');
    if (props.setPaymentMethodsDrawerVisible) props.setPaymentMethodsDrawerVisible(false);

    setDrawerVisible(true);
    setNewPaymentMethodType('card');
  };

  const onSuccess = (token, metadata) => {
    // console.log(token);
    // console.log(metadata);
    const plaid = {
      token,
      metadata,
    };
    handleAddBankAccount(plaid);
  };

  const onExit = (error, metadata) => {
    // console.log('onError', error, metadata);
    if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
      fetchAndSetPlaidToken();
    }
    if (error != null) {
      displayAlert('Payment Method Error', 'warning', error.display_message || error.error_message);
    }
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: plaidProduct,
    countryCodes: ['US'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
    onExit,
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  const getAchButtonColor = (tok) => {
    if (session.userData.paymentGateway === 'SilaStripe') {
      if (tok) {
        return '#CB48B7';
      }
      return 'grey';
    }
    return '#CB48B7';
  };

  return (
    <>
      {contextHolder}
      <StyledDrawer
        closable={false}
        placement="right"
        destroyOnClose
        onClose={() => {
          setDrawerVisible(false);
        }}
        open={drawerVisible}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <PaymentMethodDetail
          setDrawerVisible={setDrawerVisible}
          handleAddBankAccount={handleAddBankAccount}
          plaidOpen={open}
          newPaymentMethodType={newPaymentMethodType}
          checkPaymentMethodStatus={checkPaymentMethodStatus}
        />
      </StyledDrawer>
      {tenant.paymentMethods.length < 5 ? (
        <>
          <Row justify="center" align="middle">
            <Button
              onClick={() => {
                if (gateway === 'Wepay') {
                  handleAddBankAccount(null);
                } else if (gateway === 'Nuvei' || gateway === 'SilaStripe' || gateway === 'Finix') {
                  if (plaidToken) {
                    open();
                    if (props.setPaymentMethodsDrawerVisible) props.setPaymentMethodsDrawerVisible(false);
                  }
                }
              }}
              type="link"
              alignment="center"
              bottom={1}
              data-testid="bankButtonTest"
              color="linkDisabled"
            >
              <BankFilled
                style={{
                  marginRight: '10px',
                  fontSize: '20px',
                  color: 'black',
                }}
              />
              <span
                style={{
                  fontSize: '14px',
                  textDecoration: 'underline',
                  fontWeight: 'normal',
                  color: getAchButtonColor(plaidToken),
                }}
              >
                Add a bank account
              </span>
            </Button>
            <Tooltip
              placement="right"
              trigger="click"
              title={`${(Number(servicePlanData?.achRateFee) * 100).toFixed(2)}% + $${Number(
                servicePlanData?.achTrxFee
              ).toFixed(2)}`}
            >
              <InfoCircleFilled
                style={{
                  fontSize: '15px',
                  color: 'black',
                }}
                data-testid="achTooltip"
              />
            </Tooltip>
          </Row>
          <Row justify="center" align="middle">
            <Button
              onClick={handleAddCreditCard}
              type="link"
              alignment="center"
              bottom={1}
              data-testid="creditCardTest"
              disabled={!session.userData?.property?.paymentSettings?.acceptCreditCard}
            >
              <CreditCardFilled
                style={{
                  marginRight: '10px',
                  fontSize: '20px',
                  color: 'black',
                }}
              />
              <span
                style={{
                  fontSize: '14px',
                  textDecoration: 'underline',
                  fontWeight: 'normal',
                }}
              >
                Add a credit card
              </span>
            </Button>
            {session.userData?.property?.paymentSettings?.acceptCreditCard && (
              <Tooltip
                placement="right"
                trigger="click"
                title={`${(Number(servicePlanData?.creditCardRateFee) * 100).toFixed(2)}% + $${Number(
                  servicePlanData?.creditCardTrxFee
                ).toFixed(2)}`}
              >
                <InfoCircleFilled
                  style={{
                    fontSize: '15px',
                    color: 'black',
                  }}
                  data-testid="ccTooltip"
                />
              </Tooltip>
            )}
            {!session.userData?.property?.paymentSettings?.acceptCreditCard && (
              <Tooltip placement="right" title="Your landlord does not accept credit cards" trigger="click">
                <InfoCircleFilled
                  style={{
                    fontSize: '15px',
                    color: 'black',
                  }}
                  data-testid="ccTooltip"
                />
              </Tooltip>
            )}
          </Row>
        </>
      ) : null}
    </>
  );
};

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-content-wrapper {
      width: 480px !important;

      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
  `}
`;

export default PaymentMethodLinks;
