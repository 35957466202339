import { Button, Col, Row, Space } from 'antd';
import CardBlank from 'components/CardBlank/CardBlank';
import Text from 'components/Text/Text';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledVideoWrapper } from 'screens/Landlord/WepayOffboarding/WepayOffboarding.styles';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';
import styled from 'styled-components';
import OnboardingActions from './OnboardingActions';
import OnboardingForms from './OnboardingForms/OnboardingForms';
import OnboardingSteps from './OnboardingSteps';
const { REACT_APP_ENV } = process.env;

function DesktopGetStarted(props) {
  const { setCurrentStep } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const dispatch = useDispatch();

  return (
    <>
      <CardBlank style={{ minWidth: '840px', margin: '0 auto' }}>
        <Text as="div" strong size={36} color="black" style={{ textAlign: 'center' }}>
          Welcome!
        </Text>
        <Text as="div" size={20} color="black" style={{ textAlign: 'center' }}>
          You'll be ready to collect rent in a few simple steps.
        </Text>
        <Space vertical={24} />
        <StyledContainer className="onboarding-container">
          <Row gutter={16}>
            <Col xs={7} lg={6}>
              <Space direction="vertical" style={{ display: 'flex' }}>
                <OnboardingSteps {...props} />
                <div data-testid="videoBoxTest">
                  <Text color="black">Learn More</Text>
                  <StyledVideoWrapper>
                    <iframe
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        border: 0,
                      }}
                      src="https://pymt.fyi/0mq2Pkl7cyfI"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </StyledVideoWrapper>
                </div>
              </Space>
            </Col>
            <Col xs={10} lg={12}>
              <OnboardingForms {...props} />
            </Col>
            <Col xs={7} lg={6}>
              <OnboardingActions {...props} />
            </Col>
          </Row>
        </StyledContainer>
      </CardBlank>
      {REACT_APP_ENV !== 'production' && (
        <Button
          type="link"
          style={{ float: 'right' }}
          onClick={() => {
            dispatch(
              updateQuickStartStatusAction(
                session.userData.id,
                {
                  visits: 0,
                  lastCompletedStep: -1,
                  finished: false,
                },
                cognitoToken
              )
            );
            setCurrentStep(0);
          }}
        >
          Reset quickstart status (testing use only)
        </Button>
      )}
    </>
  );
}

const StyledContainer = styled.div`
  display: block;
  margin: 24px auto;
  width: 100%;
`;

export default DesktopGetStarted;
