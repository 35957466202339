import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

import IdleTimer from 'react-idle-timer';

// Components
import ErrorBoundary from 'components/ErrorBoundary';
import PrivateRoute from 'components/PrivateRoute';
import SignOut from 'components/SignOut';
import AuthScreens from './screens/Auth/Router';
import PublicCheckout from './screens/Landing/Checkout/PublicCheckout/PublicCheckout';
import Compliance from './screens/Landing/Compliance/Compliance';
import TenantScreens from './screens/Tenant/Router';
import LandlordScreens from './screens/Landlord/Router';
import Pricing from './screens/Landing/Pricing';
import RemoveRenter from './screens/RemoveRenter';
import setAuthToken from './utils/setAuthToken';
import FinixFileUpload from 'screens/Landing/FinixFileUpload/FinixFileUpload';
import Error403 from 'screens/Access/Error403';

// Redux
// import store from './store';

const token = sessionStorage.getItem('payrent.session');

if (token) {
  setAuthToken(`Bearer ${token}`);
}

function Router() {
  const [userIsInactive, setUserIsInactive] = useState(false);
  const dispatch = useDispatch();
  let location = useLocation();
  const session = useSelector((store) => store.session);

  useEffect(() => {
    window.cwr('recordPageView', location.pathname);
    if (session.userData.id) {
      window.cwr('addSessionAttributes', { user_id: session.userData.id, role: session.userRole });
    }

    if (session.userRole === 'tenant' && location.pathname === '/') {
      dispatch({ type: 'SET_SELECTED_MENU', payload: 'tenant' });
    } else if (session.userRole === 'landlord' && location.pathname === '/') {
      dispatch({ type: 'SET_SELECTED_MENU', payload: 'landlord' });
    }
  }, [location, dispatch, session.userRole, session.userData.id]);

  const onIdle = () => {
    setUserIsInactive(true);
  };

  return (
    <>
      <IdleTimer element={document} onIdle={onIdle} debounce={250} timeout={1000 * 60 * 60} />

      {userIsInactive && <Redirect to="/sign-out" />}
      {session.userRole === 'tenant' && location.pathname === '/' && <Redirect to="/tenant" />}
      {session.userRole === 'landlord' && location.pathname === '/' && <Redirect to="/landlord" />}
      <Switch>
        <ErrorBoundary>
          {/* Auth */}
          <Route exact path="/" component={AuthScreens} />
          <Route exact path="/access-denied" component={Error403} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/wepay/checkout/:id/property-group/:pid" component={PublicCheckout} />
          <Route exact path="/finix/onboarding/file-upload" component={FinixFileUpload} />
          <Route exact path="/unsubscribe/rent-reminder" component={RemoveRenter} />
          <Route exact path="/pci-compliance" component={Compliance} />
          <Route path={['/auth', '/sign-in', '/sign-up/:landlord?']} component={AuthScreens} />
          <Route path={['/sign-out']} component={SignOut} />

          {/* Tenant */}
          <PrivateRoute path="/tenant" roles={['admin', 'tenant']} component={TenantScreens} />

          {/* Landlord */}
          <PrivateRoute path="/landlord" roles={['admin', 'landlord']} component={LandlordScreens} />
        </ErrorBoundary>
      </Switch>
    </>
  );
}

export default Router;
