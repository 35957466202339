import React from 'react';
import { Button, Card, Form, Input } from 'antd';
import Title from 'components/Title';
import LoginOutlined from '@ant-design/icons/LoginOutlined';
import AlertFade from 'components/AlertFade';
import Link from 'components/Link';
import Text from 'components/Text/Text';

const EmailForm = (props) => {
  const onFinish = (values) => {
    props.sendRecoveryPasswordEmail(values.email);
  };

  const cardStyle = {
    maxWidth: '380px',
    padding: '10px',
    marginBottom: '5px',
  };

  return (
    <div>
      <Card style={cardStyle} data-testid="emailFormTest">
        <Title size={16} data-testid="titleTest" level={5} color={'black'} strong={true}>
          Forgot Password
        </Title>

        <AlertFade
          isVisible={props.alertData.visible}
          message={props.alertData.message}
          type={props.alertData.type}
        />

        <Text data-testid="textTest" color={'black'}>
          Enter your email to reset your password. A reset code will be sent.
        </Text>

        <Form onFinish={onFinish} className="auth-form" size="large">
          <Form.Item>
            <Form.Item
              name="email"
              whitespace="true"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
              data-testid="emailTest"
            >
              <Input placeholder="Email" data-testid="emailInputTest" autoComplete="new-password" />
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="form-container__button"
              icon={<LoginOutlined />}
              loading={props.isLoading}
              data-testid="buttonTest"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div>
        <Link size={14} to="/" data-testid="backTest">
          Back to Sign In
        </Link>
      </div>
    </div>
  );
};

export default EmailForm;
