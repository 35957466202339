const Joi = require('joi');
const moment = require('moment');

module.exports.listIdentitySchema = Joi.object({
  limit: Joi.number().integer(),
  page: Joi.number().integer(),
  user_id: Joi.number().integer(),
  identity_id: Joi.string(),
  role: Joi.string(),
});

module.exports.createCustomerIdentitySchema = Joi.object({
  tags: Joi.object()
    .keys({
      renter_id: Joi.number().integer().required(),
    })
    .unknown(true),
  entity: {
    phone: Joi.string().min(11).max(12),
    first_name: Joi.string().max(20).required(),
    last_name: Joi.string().max(20).required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .max(100)
      .required(),
    personal_address: {
      city: Joi.string().max(20).required(),
      country: Joi.string().valid('USA').required(),
      region: Joi.string().min(2).max(2).required(),
      line2: Joi.string().max(35).allow(''),
      line1: Joi.string().max(35).required(),
      postal_code: Joi.string().max(7).required(),
    },
  },
});

module.exports.createMerchantIdentitySchema = Joi.object({
  additional_underwriting_data: {
    annual_ach_volume: Joi.number().integer().max(9999999999),
    average_ach_transfer_amount: Joi.number().integer().max(9999999999),
    average_card_transfer_amount: Joi.number().integer().max(9999999999),
    business_description: Joi.string().max(200),
    card_volume_distribution: {
      card_present_percentage: Joi.number().integer().min(0).max(100),
      mail_order_telephone_order_percentage: Joi.number().integer().min(0).max(100),
      ecommerce_percentage: Joi.number().integer().min(0).max(100),
    },
    credit_check_allowed: Joi.boolean(),
    credit_check_ip_address: Joi.string().ip(),
    credit_check_timestamp: Joi.string().isoDate(),
    credit_check_user_agent: Joi.string().max(200),
    merchant_agreement_accepted: true,
    merchant_agreement_ip_address: Joi.string().ip(),
    merchant_agreement_timestamp: Joi.string().isoDate(),
    merchant_agreement_user_agent: Joi.string().max(200),
    refund_policy: Joi.string().uppercase().valid('NO_REFUNDS', 'MERCHANDISE_EXCHANGE_ONLY', '30_DAYS', 'OTHER'),
    volume_distribution_by_business_type: {
      other_volume_percentage: Joi.number().integer().min(0).max(100),
      consumer_to_consumer_volume_percentage: Joi.number().integer().min(0).max(100),
      business_to_consumer_volume_percentage: Joi.number().integer().min(0).max(100),
      business_to_business_volume_percentage: Joi.number().integer().min(0).max(100),
      person_to_person_volume_percentage: Joi.number().integer().min(0).max(100),
    },
  },
  entity: {
    annual_card_volume: Joi.number().integer().max(999999999999999).required(),
    business_address: {
      city: Joi.string().max(20).required(),
      country: Joi.string().valid('USA').required(),
      region: Joi.string().min(2).max(2).required(),
      line2: Joi.string().max(35).allow(''),
      line1: Joi.string().max(35).required(),
      postal_code: Joi.string().max(7).required(),
    },
    business_name: Joi.string()
      .max(120)
      .pattern(/^[a-zA-Z0-9\s]+$/)
      .required(),
    business_phone: Joi.string().min(11).max(12).required(),
    business_tax_id: Joi.string()
      .min(9)
      .max(9)
      .pattern(/^[0-9]+$/)
      .required(),
    business_type: Joi.string()
      .valid(
        'INDIVIDUAL_SOLE_PROPRIETORSHIP',
        'CORPORATION',
        'LIMITED_LIABILITY_COMPANY',
        'PARTNERSHIP',
        'ASSOCIATION_ESTATE_TRUST',
        'TAX_EXEMPT_ORGANIZATION',
        'INTERNATIONAL_ORGANIZATION',
        'GOVERNMENT_AGENCY'
      )
      .required(),
    default_statement_descriptor: Joi.string().required(),
    dob: {
      year: Joi.number().integer().min(1900).max(moment().subtract(18, 'years').year()).required(),
      day: Joi.number().integer().min(1).max(31).required(),
      month: Joi.number().integer().min(1).max(12).required(),
    },
    doing_business_as: Joi.when('business_type', {
      is: 'INDIVIDUAL_SOLE_PROPRIETORSHIP',
      then: Joi.string().max(60).required(),
      otherwise: Joi.string().max(120),
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .max(100)
      .required(),
    first_name: Joi.string().max(20).required(),
    has_accepted_credit_cards_previously: Joi.boolean().required(),
    incorporation_date: {
      year: Joi.number().integer().min(1900).max(moment().year()).required(),
      day: Joi.number().integer().min(1).max(31).required(),
      month: Joi.number().integer().min(1).max(12).required(),
    },
    last_name: Joi.string().max(20).required(),
    max_transaction_amount: Joi.number().integer().max(999999999999),
    ach_max_transaction_amount: Joi.number().integer().max(999999999999),
    mcc: Joi.string().valid('6513').required(),
    ownership_type: Joi.string().valid('PRIVATE', 'PUBLIC').required(),
    personal_address: {
      city: Joi.string().max(20).required(),
      country: Joi.string().valid('USA').required(),
      region: Joi.string().min(2).max(2).required(),
      line2: Joi.string().max(35).allow(''),
      line1: Joi.string().max(35).required(),
      postal_code: Joi.string().max(7).required(),
    },
    phone: Joi.string().min(11).max(12).required(),
    principal_percentage_ownership: Joi.number().integer().min(0).max(100),

    tax_id: Joi.string()
      .min(9)
      .max(9)
      .pattern(/^[0-9]+$/)
      .required(),
    title: Joi.string().max(60).required(),
    url: Joi.string(),
  },
  tags: Joi.object()
    .keys({
      property_group_id: Joi.number().integer().required(),
    })
    .unknown(true),
});

module.exports.createAssociatedIdentitySchema = Joi.object({
  entity: {
    first_name: Joi.string().max(20).required(),
    last_name: Joi.string().max(20).required(),
    title: Joi.string().max(60).required(),
    dob: {
      year: Joi.number().integer().min(1900).max(moment().subtract(18, 'years').year()).required(),
      day: Joi.number().integer().min(1).max(31).required(),
      month: Joi.number().integer().min(1).max(12).required(),
    },
    principal_percentage_ownership: Joi.number().integer().min(0).max(100),
    phone: Joi.string().min(11).max(12).required(),
    personal_address: {
      city: Joi.string().max(20).required(),
      country: Joi.string().valid('USA').required(),
      region: Joi.string().min(2).max(2).required(),
      line2: Joi.string().max(35).allow(''),
      line1: Joi.string().max(35).required(),
      postal_code: Joi.string().max(7).required(),
    },
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .max(100)
      .required(),
    tax_id: Joi.string()
      .min(9)
      .max(9)
      .pattern(/^[0-9]+$/)
      .required(),
  },
});
