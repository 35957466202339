import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Checkbox } from 'antd';
import { updateNotificationSettingsV3 } from 'services/api/landlordApi/update';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

//import './style.scss';

import PanelFooter from 'components/Panel/PanelFooter';
import SpaceResponsive from 'components/SpaceResponsive';
import Text from 'components/Text';
import Button from 'components/Button';

import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';

const AccountPreferencesPanel = (props) => {
  const { resetPanel, displayAlert } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log('AccountPreferencesPanel.js -> useEffect() -> session', session.userData);

    const { userData } = session;
    dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    let setFieldsObject = {
      paymentsConfirmations: userData.notificationSettings.paymentConfirmations,
      failedPayments: userData.notificationSettings.paymentFailure,
    };

    form.setFieldsValue(setFieldsObject);
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateNotificationSettingsV3(session.userData.id, values, session.cognitoTokens.idToken.jwtToken);
      dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
      displayAlert('Preferences have been updated!', 'success');
      setLoading(false);
      setTimeout(() => {
        resetPanel();
      }, 1000);
    } catch (error) {
      setLoading(false);
      const message = error?.data?.errors[0]?.detail || 'There was an error in your request';
      displayAlert('warning', message);
    }
  };

  return (
    <StyledForm
      data-testid="accountPreferencesFormTest"
      form={form}
      name="accountPreferences"
      onFinish={onFinish}
      initialValues={{
        paymentsConfirmations: false,
        failedPayments: false,
      }}
    >
      <Title>Account Settings</Title>
      <Subtitle>Preferences</Subtitle>
      <SpaceResponsive lg={20} md={20} sm={20} />
      <FormContentContainer className="content-container">
        <Text size={20} color="black" weight={500}>
          Notification Settings
        </Text>
        <SpaceResponsive lg={10} md={10} sm={10} />
        <StyledItem name="paymentsConfirmations" valuePropName="checked">
          <Checkbox>
            <Text size={16} color="grey3">
              Email me when payments are made
            </Text>
          </Checkbox>
        </StyledItem>
        <StyledItem name="failedPayments" valuePropName="checked" data-testid="emailPaymentFailTest">
          <Checkbox>
            <Text size={16} color="grey3">
              Email me when a payment fails
            </Text>
          </Checkbox>
        </StyledItem>
        <div style={{ marginTop: '18px' }}>
          <Text color="black" size={14}>
            View our{' '}
            <a href="https://payrent.com/terms-conditions" rel="noopener noreferrer" target="_blank">
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a href="https://payrent.com/privacy-policy" rel="noopener noreferrer" target="_blank">
              Privacy Policy
            </a>
          </Text>
        </div>
      </FormContentContainer>
      <PanelFooter>
        <SaveItem className="save-form-item">
          <div style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              bottom={0}
              color="violet"
              loading={loading}
              alignment="center"
              data-testid="buttonTest"
              size="large"
            >
              SAVE
            </Button>
          </div>
        </SaveItem>
      </PanelFooter>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 24px 0px 24px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const SaveItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

export default AccountPreferencesPanel;
