import React, { useCallback, useEffect, useState } from 'react';
import MoreMenu from 'components/MoreMenu/MoreMenu';
import { Space } from 'antd';
import { CalculatorOutlined, MailOutlined, SendOutlined } from '@ant-design/icons';
import Text from 'components/Text/Text';

const MoreRenterActions = (props) => {
  // console.log('MoreRenterActions props', props);
  const { checkPendingBa, item } = props;
  const [activeMenuItems, setActiveMenuItems] = useState([]);

  const resetDropdown = () => {
    props.setDeleteRenterText({
      text: 'Remove Renter',
      style: { color: 'inherit', fontWeight: 'inherit' },
      action: false,
    });

    props.setDeleteInvitationText({
      text: 'Delete Invitation',
      style: { color: 'inherit', fontWeight: 'inherit' },
      action: false,
    });
  };

  const renterMenu = useCallback(
    (item) => [
      {
        label: (
          <Space>
            <SendOutlined />
            <Text color="black">Send Past Due Notice</Text>
          </Space>
        ),
        key: '0',
        onClick: () => {
          props.handleSendRentReminder(item.id);
        },
        'data-testid': 'rentReminderTest',
      },
      {
        label: (
          <Space>
            <CalculatorOutlined />
            <Text color="black">View Transaction History</Text>
          </Space>
        ),
        key: '1',
        onClick: () => props.handleViewTransactionHistory(item),
        'data-testid': 'transactionHistoryTest',
      },
    ],
    [props]
  );

  const invitedMenu = useCallback(
    (item) => [
      {
        label: (
          <Space>
            <MailOutlined />
            <Text color="black">Resend Invitation</Text>
          </Space>
        ),
        key: '0',
        onClick: () => props.handleResendInvite(item.id),
        'data-testid': 'resendTest',
      },
    ],

    [props]
  );

  useEffect(() => {
    setActiveMenuItems(() => (checkPendingBa(item) ? invitedMenu(item) : renterMenu(item)));
  }, [checkPendingBa, invitedMenu, item, renterMenu]);

  return <MoreMenu items={activeMenuItems} resetDropdown={resetDropdown} loading={props.item.isSending} />;
};

export default MoreRenterActions;
