import React from 'react';
import { Progress as AntProgress, theme } from 'antd';
import PropTypes from 'prop-types';

function Progress(props) {
  return <AntProgress {...props} />;
}

Progress.propTypes = {
  percent: PropTypes.number,
  steps: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['small', 'default'])]),
  success: PropTypes.number,
  strokeColor: PropTypes.string,
};

Progress.defaultProps = {
  percent: 0,
  steps: 0,
  size: 16,
  success: 0,
  strokeColor: theme.defaultSeed.colorSuccess,
};
export const strokeColors = {
  success: theme.defaultSeed.colorSuccess,
  error: theme.defaultSeed.colorError,
  warning: theme.defaultSeed.colorWarning,
  info: theme.defaultSeed.colorPrimary,
};

export default Progress;
