import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Input, InputNumber, Card, Row, Col, Button } from 'antd';

import BestValue from 'assets/images/bestValue.svg';

import { emailQualityScore } from 'services/api/common/read';
import { createLead } from 'services/api/common/create';
import AlertFade from 'components/AlertFade';

import { costCalculator } from 'utils';

//import './style.scss';

const Item = Form.Item;

const Calculator = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    showCosts: false,
    units: 0,
    email: '',
    planCosts: [],
    errors: {
      message: '',
      success: false,
      type: 'warning',
    },
  });
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const [alert, setAlert] = useState({ visible: false, message: '', type: 'warning' });

  const handleOnClick = (e) => {
    return setState({ ...state, planCosts: [] });
  };

  const handleFinish = async (e) => {
    setLoading(true);
    setState({
      ...state,
      errors: {
        success: false,
        message: '',
      },
    });
    try {
      const leadRes = await createLead(e);

      if (leadRes.status === 200) {
        const costArr = costCalculator(e.unitCount);
        setLoading(false);
        return setState({ ...state, planCosts: costArr });
      }
      setLoading(false);
      return setState({
        ...state,
        errors: {
          success: false,
          message: 'There was a problem with createLead',
        },
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const validateMessages = {
    required: 'Required input',
  };

  const throttleFunc = async (rule, value) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(value)) {
      return Promise.reject('This is not a valid email');
    }
    if (re.test(value)) {
      try {
        const res = await emailQualityScore(value);

        if (res.disposable === true || res.fraud_score >= 85) {
          return Promise.reject('This is not a valid email');
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(JSON.stringify(error));
      }
    }
  };

  const inputFields = (
    <>
      <Row justify="center">
        <Col span={24} className="calc-title">
          <h2
            style={{
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontStyle: 'normal',
              fontSize: '28px',
              color: '#541388',
            }}
            data-testid="calculatorTitleTest"
          >
            Estimate Your Monthly Costs for Each Plan
          </h2>
        </Col>
      </Row>
      <Row justify="center" gutter={[16, 16]}>
        <Col span={24}>
          <StyledForm
            form={form}
            {...layout}
            requiredMark={false}
            onFinish={handleFinish}
            //layout="vertical"
            validateMessages={validateMessages}
          >
            <Row
              gutter={10}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
              className="calculator-form-container"
            >
              <Col span={5} xs={15} sm={5}>
                <Item
                  name="unitCount"
                  label="Number of Units"
                  rules={[
                    { required: true },
                    {
                      pattern: /^[\d]{0,11}$/,
                      message: 'Max 11 characters',
                    },
                  ]}
                >
                  <InputNumber placeholder="Unit Qty." data-testid="unitCountTest" />
                </Item>
              </Col>
              <Col span={9} xs={20} sm={9}>
                <Item name="email" label="Email Address" hasFeedback rules={[{ validator: throttleFunc }]}>
                  <Input
                    type="email"
                    placeholder="Enter email address"
                    className="text-input"
                    data-testid="emailInputTest"
                  />
                </Item>
              </Col>
              <Col span={3} xs={24} sm={3}>
                <Item>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    data-testid="calculateButtonTest"
                  >
                    CALCULATE
                  </StyledButton>
                </Item>
              </Col>
            </Row>
          </StyledForm>
        </Col>
      </Row>
      <div className="errorMsg">
        <AlertFade
          type={state.errors.type}
          message={state.errors.message}
          isVisible={state.errors.message}
          closable
        />
      </div>
    </>
  );

  const BestValueBanner = (
    <div
      style={{
        zIndex: '999',
        position: 'absolute',
        top: -16,
        left: '50%',
        transform: 'translate(-50%,0%)',
      }}
    >
      <img className="bestValue" src={BestValue} alt="Best Value" style={{ width: '120px' }} />
    </div>
  );
  const costResults = (
    <>
      <StyledRow justify="center" gutter={[2, 4]} className="calculator-results">
        {state.planCosts.map((p, key) => {
          return (
            <Col span={8} xs={24} sm={8} key={key} className="calculator-result-col">
              <Card
                style={
                  p.bestValue
                    ? {
                        boxShadow: '0px 0px 12px 2px rgba(0, 0, 0, 0.2)',
                        zIndex: '2',
                      }
                    : {}
                }
              >
                {p.bestValue ? BestValueBanner : null}
                <h5>
                  <strong>{p.servicePlanName.toUpperCase()}</strong>
                </h5>
                <h6>ESTIMATED MONTHLY COST</h6>
                <span style={{ fontSize: '32px' }}>${p.totalCost}</span>
                <span> per month</span>
                <div className="cost-breakdown">
                  <h6>Cost Breakdown:</h6>
                  <h6>${p.subscriptionCost} Subscription Fee</h6>
                  <h6>${p.achCost} ACH Fees</h6>
                </div>
              </Card>
            </Col>
          );
        })}
      </StyledRow>
      {/**
         *  Reset button
            <StyledButton onClick={handleOnClick}>Reset</StyledButton>
         */}
    </>
  );
  return <Container>{state.planCosts.length === 0 ? inputFields : costResults}</Container>;
};

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 55px;
`;

const StyledRow = styled(Row)`
  width: 63%;
  @media screen and (max-width: 1600px) {
    width: 75%;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    width: 95%;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #ffcc00;
  font-weight: 600;
  color: #541388;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
`;

export const StyledForm = styled(Form)`
  label {
    font-size: 14px;
  }
  input,
  .ant-input-number {
    height: 34px;
    border-radius: 4px !important;
  }
  input.text-input {
    height: 34px;
  }
`;

export default Calculator;
