import React from 'react';
import Text from 'components/Text/Text';
import { Space } from 'antd';

function UnitFaqs() {
  const faqs = [
    {
      q: 'What if my property has more than 1 unit?',
      a: 'Add your first unit here. You can add additional units later on the Properties screen.',
    },
    {
      q: 'Who pays for the transaction fees?',
      a: 'Depending on your service plan, you can pass through ACH fees to your tenant. Credit card fees are always paid by the renter.',
    },
    {
      q: 'How do I pass the ACH fees to the renter?',
      a: 'Uncheck “Absorb X percent of ACH fees”. If this is not available, your service plan will not allow it.',
    },
    {
      q: 'What is Rent Collection Policy? Can I change it?',
      a: 'Setting a Rent Collection Policy allows you to require auto-pay and back-up payment methods to insure rent is always paid on time.',
    },
  ];
  return faqs.map((faq, index) => (
    <Space direction="vertical" key={index}>
      <Text color="black" strong>
        {faq.q}
      </Text>
      <Text color="black">{faq.a}</Text>
    </Space>
  ));
}

export default UnitFaqs;
