// Global
export const HEADER_BUTTON_VISIBLE = 'HEADER_BUTTON_VISIBLE';
export const SET_ACTIVE_BOX_MENU = 'SET_ACTIVE_BOX_MENU';
export const BOTTOM_CARD_FORCE_VISIBLE = 'BOTTOM_CARD_FORCE_VISIBLE';
export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';
export const SET_ALERT = 'SET_ALERT';
export const UNSET_ALERT = 'UNSET_ALERT';
export const SET_LOADER = 'SET_LOADER';
export const SET_LOADING_OVERLAY = 'SET_LOADING_OVERLAY';
export const SET_REDIRECT_PATHNAME = 'SET_REDIRECT_PATHNAME';
export const SET_PANEL = 'SET_PANEL';

// Session
export const SET_USER_SESSION_DATA = 'SET_USER_SESSION_DATA';
export const LOGIN = 'LOGIN';
export const LOG_OUT = 'LOG_OUT';
export const GET_USER_SESSION = 'GET_USER_SESSION';
export const UPDATE_USER_SESSION_DATA = 'UPDATE_USER_SESSION_DATA';
export const SET_SESSION_LOADER = 'SET_SESSION_LOADER';
export const UPDATE_USER_COGNITO_TOKENS = 'UPDATE_USER_COGNITO_TOKENS';
export const SET_SHOW_TOUR = 'SET_SHOW_TOUR';

// Landlord
export const SET_CURRENT_ONBOARDING_STEP = 'SET_CURRENT_ONBOARDING_STEP';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SET_TRANSACTIONS_LOADING = 'SET_TRANSACTIONS_LOADING';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';
export const GET_RENTERS_LIST = 'GET_RENTERS_LIST';
export const SET_RENTERS_LIST_LOADING = 'SET_RENTERS_LIST_LOADING';
export const GET_RENTERS_LIST_ERROR = 'GET_RENTERS_LIST_ERROR';
export const GET_PAYMENTS_SUMMARY_DATA = 'GET_PAYMENTS_SUMMARY_DATA';
export const GET_ALL_SUMMARY_DATA = 'GET_ALL_SUMMARY_DATA';
export const GET_PROPERTIES_SUMMARY_DATA = 'GET_PROPERTIES_SUMMARY_DATA';
export const GET_PROPERTIES_LIST = 'GET_PROPERTIES_LIST';
export const GET_PROPERTIES_LIST_ERROR = 'GET_PROPERTIES_LIST_ERROR';
export const GET_PEOPLE_SUMMARY_DATA = 'GET_PEOPLE_SUMMARY_DATA';
export const SET_BOARDING_STATUS = 'SET_BOARDING_STATUS';
export const SET_SUMMARY_CARD_PAYMENT_TYPE = 'SET_SUMMARY_CARD_PAYMENT_TYPE';
export const SET_ACTIVITY_FEED_ROW = 'SET_ACTIVITY_FEED_ROW';
export const SET_UPGRADE_DOWNGRADE = 'SET_UPGRADE_DOWNGRADE';
export const UNSET_UPGRADE_DOWNGRADE = 'UNSET_UPGRADE_DOWNGRADE';
export const UPDATE_UPGRADE_DOWNGRADE = 'UPDATE_UPGRADE_DOWNGRADE';
export const SET_ONBOARDING_STATUS = 'SET_ONBOARDING_STATUS';
export const RENTER_TRANSACTION = 'RENTER_TRANSACTION';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_DEFAULT_PROPERTY_GROUP = 'SET_DEFAULT_PROPERTY_GROUP';

// Tenant
export const GET_BILLING_ITEMS = 'GET_BILLING_ITEMS';
export const GET_BILLING_ITEMS_LOADING = 'GET_BILLING_ITEMS_LOADING';
export const GET_PROCESSED_BILLING_ITEMS = 'GET_PROCESSED_BILLING_ITEMS';
export const GET_PROCESSING_BILLING_ITEMS = 'GET_PROCESSING_BILLING_ITEMS';
export const GET_DELAYED_BILLING_ITEMS = 'GET_DELAYED_BILLING_ITEMS';
export const GET_BILLING_ITEMS_ERROR = 'GET_BILLING_ITEMS_ERROR';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_HISTORY = 'GET_PAYMENT_METHODS_HISTORY';
export const GET_PAYMENT_SETTINGS = 'GET_PAYMENT_SETTINGS';
export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const TRANSACTION_HISTORY_ERROR = 'TRANSACTION_HISTORY_ERROR';
export const SET_LOADING_TRANSACTION_HISTORY = 'SET_LOADING_TRANSACTION_HISTORY';
export const CLEAR_TRANSACTION_HISTORY = 'CLEAR_TRANSACTION_HISTORY';
export const UPDATE_NOTIFICATIONS_SETTINGS = 'UPDATE_NOTIFICATIONS_SETTINGS';
export const GET_SCHEDULED_PAYMENTS = 'GET_SCHEDULED_PAYMENTS';
export const CREATE_SCHEDULED_PAYMENT = 'CREATE_SCHEDULED_PAYMENT';
export const UPDATE_SCHEDULED_PAYMENT = 'UPDATE_SCHEDULED_PAYMENT';
export const DELETE_SCHEDULED_PAYMENT = 'DELETE_SCHEDULED_PAYMENT';
export const SET_LOADING_SCHEDULED_PAYMENTS = 'SET_LOADING_SCHEDULED_PAYMENTS';
export const SCHEDULED_PAYMENTS_ERROR = 'SCHEDULED_PAYMENTS_ERROR';
export const SET_RCP_COMPLIANCE = 'SET_RCP_COMPLIANCE';
export const SET_INSURANCE_POLICY_FILE = 'SET_INSURANCE_POLICY_FILE';
export const SET_DISABLE_INSURANCE_FEATURE = 'SET_DISABLE_INSURANCE_FEATURE';
export const SET_FIRST_ACH_PAYMENT = 'SET_FIRST_ACH_PAYMENT';
export const GET_SERVICE_PLAN_DATA = 'GET_SERVICE_PLAN_DATA';
export const SET_RENTCRED_MILESTONE = 'SET_RENTCRED_MILESTONE';
export const SHOW_RENTCRED_PROFILE = 'SHOW_RENTCRED_PROFILE';
export const SET_RENTCRED_DATA = 'GET_RENTCRED_DATA';
export const SET_LVBLE_TOKEN = 'SET_LVBLE_TOKEN';
export const SET_LVBLE_TOKEN_LOADING = 'SET_LVBLE_TOKEN_LOADING';
export const SET_LVBLE_TOKEN_ERROR = 'SET_LVBLE_TOKEN_ERROR';
export const SET_LVBLE_EXPERIENCE = 'SET_LVBLE_EXPERIENCE';
export const SET_LVBLE_ACCOUNT = 'SET_LVBLE_ACCOUNT';
export const SET_LVBLE_ACCOUNT_ERROR = 'SET_LVBLE_ACCOUNT_ERROR';
