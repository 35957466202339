import axios from 'axios';
import Jsona from 'jsona';
import { refreshToken } from '../aws/cognito';
import { v4 as uuidV4 } from 'uuid';
import store from '../../store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const dataFormatter = new Jsona();

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

// Make a payment
export const makePayment = async (
  gateway,
  amount,
  paymentMethod,
  billingAccount,
  paymentSettings,
  cognitoToken,
  renter,
  fraudSessionId = null,
  lvbleData
) => {
  // console.log(
  //   '[tenantApi.js] ƒ makePayment',
  //   gateway,
  //   amount,
  //   paymentMethod,
  //   billingAccount,
  //   paymentSettings,
  //   cognitoToken,
  //   renter,
  //   fraudSessionId,
  //   lvbleData
  // );

  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  const wepayRequest = {
    data: {
      attributes: {
        amount,
      },
      relationships: {
        billingAccount: {
          data: {
            id: billingAccount.id,
            type: billingAccount.type,
            method: 'update',
          },
        },
        paymentMethod: {
          data: {
            id: paymentMethod.id,
            type: paymentMethod.type,
            method: 'update',
          },
        },
      },
      type: 'payments',
    },
    included: [
      {
        id: billingAccount.id,
        type: 'billing-accounts',
      },
      {
        id: paymentMethod.id,
        type: 'payment-methods',
      },
    ],
  };
  // Nuvei body
  const nuveiRequest = {
    data: {
      attributes: {
        paymentMethodId: paymentMethod.id,
        amount,
        landlordId: Number(paymentSettings.customer?.id),
        propertyId: Number(paymentSettings.id),
      },
    },
  };

  const silaRequest = {
    data: {
      attributes: {
        paymentMethodId: paymentMethod.id,
        amount,
        landlordId: +paymentSettings.customer?.id,
        renterId: +renter.id,
        propertyId: +paymentSettings.id,
        paymentGateway: gateway,
        paymentMethodType: paymentMethod.paymentMethodType,
        billingAccount: billingAccount.id,
      },
    },
  };

  const finixRequest = {
    data: {
      attributes: {
        paymentMethodId: paymentMethod.id,
        amount,
        landlordId: +paymentSettings.customer.id,
        propertyId: +paymentSettings.id,
        paymentGateway: gateway,
        paymentMethodType: paymentMethod.paymentMethodType,
        billingAccount: billingAccount.id,
        idempotency_id: uuidV4(),
        tags: {
          renter_id: +renter.id,
          property_id: +paymentSettings.id,
          renter_fullname: renter.privacyFullNameForRenter,
          renter_fulladdress: `${paymentSettings.address?.replace(',', ' ')} ${
            paymentSettings.address2 && ` ${paymentSettings.address2}`
          } ${paymentSettings.city} ${paymentSettings.state} ${paymentSettings.zip}`,
          ...(!!lvbleData && { lvble_data: lvbleData }),
        },
      },
    },
  };

  if (gateway === 'Finix' && fraudSessionId) {
    finixRequest.data.attributes.fraud_session_id = fraudSessionId;
  }

  const headers = {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  };
  if (gateway === 'Nuvei' || gateway === 'SilaStripe' || gateway === 'Finix')
    headers.headers.Authorization = `Bearer ${token}`;
  const url =
    gateway === 'Wepay'
      ? `${process.env.REACT_APP_API_URL}/payments`
      : `${process.env.REACT_APP_API_URL_V3}/payments`;

  let body = '';

  switch (gateway) {
    case 'Wepay':
      body = wepayRequest;
      break;
    case 'Nuvei':
      body = nuveiRequest;
      break;
    case 'SilaStripe':
      body = silaRequest;
      break;
    case 'Finix':
      body = finixRequest;
      break;
    default:
      break;
  }

  try {
    const response = await axios.post(url, body, headers);

    const result = dataFormatter.deserialize(response.data);
    // console.log('[tenantApi.js] ƒ makePayment result', result);
    return result;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createPaymentMethod = async (paymentMethod) => {
  const oldApiToken = sessionStorage.getItem('payrent.session');
  try {
    const data = {
      data: {
        attributes: {
          ...paymentMethod,
        },
        type: 'payment-methods',
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${oldApiToken}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/payment-methods`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createACHPaymentMethod = async (cognitoToken, plaid, gateway, type, stripe, userId) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    let data = {};

    if (type === 'creditCard' && gateway === 'SilaStripe') {
      data = {
        data: {
          attributes: {
            paymentGateway: gateway,
            paymentMethodType: 'Card',
            stripePaymentMethodId: stripe.payment_method,
            stripeId: stripe.stripeCustomerId,
            customerId: userId,
          },
        },
      };
    } else {
      data = {
        data: {
          attributes: {
            paymentGateway: gateway,
            paymentMethodType: 'ACH',
            publicToken: plaid.token,
            mask: plaid.metadata.account.mask,
            accountId: plaid.metadata.account.id,
            authFlow: 'instant_auth',
          },
        },
      };
    }
    if (plaid) {
      if (
        plaid.metadata.account.verification_status === 'pending_manual_verification' ||
        plaid.metadata.account.verification_status === 'pending_automatic_verification'
      ) {
        data.data.attributes.authFlow = 'microdeposit';
        data.data.attributes.meta = {
          subtype: plaid.metadata.account.subtype,
          type: plaid.metadata.account.type,
          accountId: plaid.metadata.account.id,
          name: plaid.metadata.account.name,
        };
      }
    }

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods`,
    };

    const res = await axios(options);

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const savePlaidToken = async (cognitoToken, paymentMethod, plaid) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  const data = {
    data: {
      type: 'payment-method',
      id: paymentMethod.id,
      attributes: {
        'public-token': plaid.token,
        mask: paymentMethod.paymentGatewayMeta.account_last_four,
      },
    },
  };

  const options = {
    method: 'PUT',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
    },
    data,
    url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${paymentMethod.id}`,
  };

  return axios(options);
};

export const getAccountBalance = async (cognitoToken, paymentMethodId) => {
  const options = {
    method: 'GET',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${cognitoToken}`,
    },
    url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${paymentMethodId}/balance`,
  };

  try {
    const res = await axios(options);
    return dataFormatter.deserialize(res?.data);
  } catch (error) {
    throw error.response?.data?.errors;
  }
};

export const getBillingItems = async (filter, filterType, sort, include, pageSize, pageNumber) => {
  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/billing-items?`;
    if (filter !== null) apiUrl += `filter[gql]=(${encodeURIComponent(filter)})`;
    if (filterType !== null) apiUrl += encodeURIComponent(`${filterType}`);
    if (sort) apiUrl += `&sort=-${sort}`;
    if (include) apiUrl += `&include=${include}`;
    if (pageSize) apiUrl += `&page[size]=${pageSize}`;
    if (pageNumber) apiUrl += `&page[number]=${pageNumber}`;
    const res = await axios.get(apiUrl);
    const items = dataFormatter.deserialize(res.data);
    return items;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

// deprecated v4.0 2024-01-01
export const getPaymentMethods = async (gateway, history) => {
  try {
    let filter = '';
    if (history) {
      filter = 'state:ACTIVE,state:PENDING,state:SCHEDULED,state:ERROR,state:DELETED';
    } else {
      filter = 'state:ACTIVE,state:PENDING,state:SCHEDULED,state:ERROR';
    }

    const url = `${process.env.REACT_APP_API_URL}/payment-methods?filter[gql]=(${filter})${encodeURIComponent(
      `+paymentGateway:${gateway}`
    )}`;

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    console.log(error);
    throw new Error('Get Payment methods Error!');
  }
};

export const getPaymentMethodsV3 = async (gateway, history, cognitoToken) => {
  // console.log('[tenantApi.js] ƒ getPaymentMethodsV3', gateway, history, cognitoToken);
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    let filter = '';
    if (history) {
      filter =
        'payment_method_state:ACTIVE,payment_method_state:PENDING,payment_method_state:SCHEDULED,payment_method_state:ERROR,payment_method_state:DELETED,';
    } else {
      filter =
        'payment_method_state:ACTIVE,payment_method_state:PENDING,payment_method_state:SCHEDULED,payment_method_state:ERROR,';
    }

    const url = `${process.env.REACT_APP_API_URL_V3}/payment-methods?filter=${
      filter + `payment_gateway:${encodeURIComponent(gateway)}`
    }`;

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    console.log(error);
    throw new Error('Get Payment methods Error!');
  }
};

export const getPaymentMethod = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/payment-methods?filter[gql]=(id:${id})${encodeURIComponent(
      '+paymentGateway:Wepay'
    )}`;

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw new Error('Get Payment methods Error!');
  }
};

export const getPaymentSettings = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/properties/${id}?include=customer,paymentSettings`;
  const options = {
    method: 'GET',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
    url,
  };

  const res = await axios(options);
  return dataFormatter.deserialize(res.data);
};

// deprecated 4.0 2024-01-01
export const deletePaymentMethod = async (id) => {
  const oldApiToken = sessionStorage.getItem('payrent.session');

  const url = `${process.env.REACT_APP_API_URL}/payment-methods/${id}`;

  const options = {
    method: 'DELETE',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${oldApiToken}`,
    },
    url,
  };

  const res = await axios(options);
  return dataFormatter.deserialize(res.data);
};

export const setDefaultPM = async (id) => {
  // const oldApiToken = sessionStorage.getItem('payrent.session');
  const data = {
    data: {
      attributes: {
        defaultMethod: true,
        id: id,
      },
      id: id,
      type: 'payment-methods',
    },
  };

  const options = {
    method: 'PATCH',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      // Authorization: `Bearer ${oldApiToken}`,
    },
    data,
    url: `${process.env.REACT_APP_API_URL}/payment-methods/${id}`,
  };

  return axios(options);
};

export const setDefaultPaymentMethodV3 = async (id, cognitoToken, controller) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const data = {
      data: {
        attributes: {
          defaultMethod: true,
          id: id,
        },
        id: id,
        type: 'payment-methods',
      },
    };

    const options = {
      signal: controller?.signal,
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${id}`,
    };

    return axios(options);
  } catch (error) {
    throw error;
  }
};

// deprecated 4.0 2024-01-01 use V3
export const updateTenantMeta = async (id, creditBoost, renterProfile, financialGoals) => {
  // TODO move this to V3
  try {
    const data = {
      data: {
        id,
        relationships: {
          notificationSettings: {
            data: {
              id: `temp-id${id}`,
              type: 'notification-settings',
            },
          },
          tenantMeta: {
            data: {
              id: `temp-id${id}`,
              type: 'tenant-meta',
            },
          },
        },
      },
      included: [
        {
          type: 'tenant-meta',
          id: `temp-id${id}`,
          attributes: {
            system: 'PayrentLandlord',
            creditBoost,
            renterProfile,
            financialGoals,
          },
        },
      ],
    };
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/session/${id}`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateTenantMetaV3 = async (id, creditBoost, renterProfile, financialGoals, cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        id,
        relationships: {
          notificationSettings: {
            data: {
              id: `temp-id${id}`,
              type: 'notification-settings',
            },
          },
          tenantMeta: {
            data: {
              id: `temp-id${id}`,
              type: 'tenant-meta',
            },
          },
        },
      },
      included: [
        {
          type: 'tenant-meta',
          id: `temp-id${id}`,
          attributes: {
            system: 'PayrentLandlord',
            creditBoost,
            renterProfile,
            financialGoals,
          },
        },
      ],
    };
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/session/account-settings`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

// deprecated 4.0 2024-01-01 use V3
export const updateUserPreferences = (userId, preferences) => {
  const oldApiToken = sessionStorage.getItem('payrent.session');

  const data = {
    data: {
      id: userId,
      relationships: {
        notificationSettings: {
          data: {
            id: `temp-id${userId}`,
            method: 'update',
            type: 'notification-settings',
          },
        },
      },
    },
    included: [
      {
        attributes: {
          id: `temp-id${userId}`,
          paymentConfirmations: preferences.notificationsGroup.some((value) => value === 'paymentConfirmations'),
          rentReminders: preferences.notificationsGroup.some((value) => value === 'rentReminders'),
        },
        id: `temp-id${userId}`,
        type: 'notification-settings',
      },
    ],
  };

  const options = {
    method: 'PATCH',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${oldApiToken}`,
    },
    data,
    url: `${process.env.REACT_APP_API_URL}/session/${userId}`,
  };

  return axios(options);
};

export const updateUserPreferencesV3 = async (userId, preferences, cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  const data = {
    data: {
      id: userId,
      relationships: {
        notificationSettings: {
          data: {
            id: `temp-id${userId}`,
            method: 'update',
            type: 'notification-settings',
          },
        },
      },
    },
    included: [
      {
        attributes: {
          id: `temp-id${userId}`,
          paymentConfirmations: preferences.notificationsGroup.some((value) => value === 'paymentConfirmations'),
          rentReminders: preferences.notificationsGroup.some((value) => value === 'rentReminders'),
        },
        id: `temp-id${userId}`,
        type: 'notification-settings',
      },
    ],
  };

  const options = {
    method: 'PATCH',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
    },
    data,
    url: `${process.env.REACT_APP_API_URL_V3}/session/account-settings`,
  };

  return axios(options);
};
// Scheduled payments

export const schedulePayment = async (cognitoToken, formData, renterId) => {
  const { balanceDue, paymentAmount, frequency, endPaymentDate, firstPaymentDate, deliverBy, payFrom } = formData;

  const balanceDueCheck = typeof balanceDue === 'boolean' ? balanceDue : balanceDue.target.checked;

  try {
    let data = {
      data: {
        type: 'scheduled-payment',
        attributes: {
          balanceDue: balanceDueCheck,
          fixAmount: balanceDueCheck ? 0 : Number(paymentAmount),
          frequency,
          endDate: endPaymentDate ? dayjs.utc(endPaymentDate.format('YYYY-MM-DD')).format('YYYY-MM-DD') : null,
          schedule: frequency === 'ONE-TIME' ? 'Send subsequent payments on' : deliverBy,
        },
        relationships: {
          paymentMethod: {
            data: {
              id: payFrom,
              type: 'payment-method',
            },
          },
          customerRenter: {
            data: {
              id: renterId,
              type: 'customer',
            },
          },
        },
      },
    };

    if (firstPaymentDate) {
      data.data.attributes.firstPaymentDate = dayjs
        .utc(firstPaymentDate.format('YYYY-MM-DD'))
        .format('YYYY-MM-DD');
    }

    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const res = await axios.post(`${process.env.REACT_APP_API_URL_V3}/scheduled-payments`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getScheduledPayments = async (cognitoToken) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    const res = await axios.get(`${process.env.REACT_APP_API_URL_V3}/scheduled-payments`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getSoonestScheduledPayment = async (cognitoToken) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    const res = await axios.get(`${process.env.REACT_APP_API_URL_V3}/scheduled-payments/soonest`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const getScheduledPayment = async (cognitoToken, id) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    const res = await axios.get(`${process.env.REACT_APP_API_URL_V3}/scheduled-payments/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const updateScheduledPayment = async (cognitoToken, id, renterId, formData, balanceDueUntouched) => {
  try {
    const { balanceDue, paymentAmount, frequency, endPaymentDate, firstPaymentDate, deliverBy, payFrom } =
      formData;

    let balanceDueCheck = balanceDueUntouched;
    if (balanceDue) balanceDueCheck = balanceDue.target.checked;

    let data = {
      data: {
        type: 'scheduled-payment',
        attributes: {
          id: id,
          balanceDue: balanceDueCheck,
          fixAmount: balanceDueCheck ? 0 : paymentAmount,
          frequency,
          firstPaymentDate: dayjs.utc(firstPaymentDate.format('YYYY-MM-DD')).format('YYYY-MM-DD'), // "2020-08-02"
          endDate: endPaymentDate ? dayjs.utc(endPaymentDate.format('YYYY-MM-DD')).format('YYYY-MM-DD') : null,
          schedule: deliverBy,
        },
        relationships: {
          paymentMethod: {
            data: {
              id: payFrom,
              type: 'payment-method',
            },
          },
          customerRenter: {
            data: {
              id: renterId,
              type: 'customer',
            },
          },
        },
      },
    };

    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    const res = await axios.patch(`${process.env.REACT_APP_API_URL_V3}/scheduled-payments/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};

export const deleteScheduledPayment = async (cognitoToken, id) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    await axios.delete(`${process.env.REACT_APP_API_URL_V3}/scheduled-payments/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (error) {
    throw Error(error);
  }
};

export const activateNuveiCreditCard = async (cognitoToken, paymentMethodId) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${paymentMethodId}/authorize`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getServicePlanData = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/service-plan`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateTenantProfile = async (cognitoToken, id, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const data = {
      data: {
        type: 'user',
        id,
        attributes: {
          firstName: values.given_name,
          lastName: values.family_name,
          cellPhone: values.phone_number,
          dob: values.birthdate,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPlaidLinkToken = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${id}/link`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const achMicrodepositVerification = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'payment-method',
        id,
        attributes: {
          authFlow: 'verification',
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getAds = async (cognitoToken, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          ...values,
        },
      },
    };
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/ads/media-alpha`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getBillingAccount = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/billing-accounts/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

// deprecated 4.0 2024-01-01 use V3
export const saveComplianceDate = async (userId) => {
  try {
    const data = {
      data: {
        id: userId,
        relationships: {
          paymentSettings: {
            data: {
              id: `temp-id${userId}`,
              method: 'update',
              type: 'payment-settings',
            },
          },
        },
      },
      included: [
        {
          attributes: {
            compliance: {
              acceptanceDate: dayjs().format('YYYY-MM-DD'),
            },
          },
          id: `temp-id${userId}`,
          type: 'payment-settings',
        },
      ],
    };

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        // Authorization: `Bearer ${oldApiToken}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/session/${userId}`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const saveComplianceDateV3 = async (userId, cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        id: userId,
        relationships: {
          paymentSettings: {
            data: {
              id: `temp-id${userId}`,
              method: 'update',
              type: 'payment-settings',
            },
          },
        },
      },
      included: [
        {
          attributes: {
            compliance: {
              acceptanceDate: dayjs().format('YYYY-MM-DD'),
            },
          },
          id: `temp-id${userId}`,
          type: 'payment-settings',
        },
      ],
    };

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/session/account-settings`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getSureQuote = async (cognitoToken, values, cancelToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    // Check if we made a request

    const data = {
      data: {
        attributes: {
          paymentCadence: values.paymentCadence,
          email: values.email,
          property_address: {
            street_address: values.address,
            unit: values.unit,
            city: values.city,
            region: values.state,
            postal: values.zip,
            country_code: 'US',
          },
          details: {
            pni_first_name: values.tenantName,
            pni_last_name: values.tenantLastName,
            pni_phone_number: values.tenantPhone,
            has_mailing_address: false,
            number_of_losses: values.losses,
            loss_date: values.lossDate,
            animal_injury: values.animalInjury,
            dwelling_type: values.dwellingType,
            has_sni: values.sni,
            sni_first_name: values.sniFirstName,
            sni_last_name: values.sniLastName,
          },
          settings: {
            include_pet_damage: values.allowPetDamage,
            include_water_backup: values.allowWaterBackup,
            include_earthquake: values.allowEarthquake,
            include_replacement_cost: values.allowReplacementCost,
            include_identity_fraud: values.allowIdentityFraud,
            personal_property_coverage: values.personalPropertyCoverage,
            all_peril_deductible: values.allPerilDeductible,
            liability_limit: values.liabilityLimit,
            medical_limit: null,
          },
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/documents/insurance/quotation`,
      cancelToken: cancelToken.token,
      timeout: 60000,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    if (axios.isCancel(error)) {
      // Handle if request was cancelled
      console.log('Request canceled', error.message);
      throw Error('Request canceled');
    } else {
      // Handle usual errors
      console.log('Something went wrong: ', error?.message);
      const { response, message } = error;
      throw response || message;
    }
  }
};

export const purchaseInsurance = async (cognitoToken, stripeToken, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          planId: values.planId,
          quoteId: values.quoteId,
          paymentCadence: values.paymentCadence,
          token: stripeToken,
          email: values.email,
          property_address: {
            street_address: values.address,
            unit: values.unit,
            city: values.city,
            region: values.state,
            postal: values.zip,
            country_code: 'US',
          },
          details: {
            pni_first_name: values.tenantName,
            pni_last_name: values.tenantLastName,
            pni_phone_number: values.tenantPhone,
            has_mailing_address: false,
            number_of_losses: values.losses,
            animal_injury: values.animalInjury,
            dwelling_type: values.dwellingType,
            has_sni: values.sni,
            sni_first_name: values.sniFirstName,
            sni_last_name: values.sniLastName,
          },
          settings: {
            include_pet_damage: values.allowPetDamage,
            include_water_backup: values.allowWaterBackup,
            include_earthquake: values.allowEarthquake,
            include_replacement_cost: values.allowReplacementCost,
            include_identity_fraud: values.allowIdentityFraud,
            personal_property_coverage: values.personalPropertyCoverage,
            all_peril_deductible: values.allPerilDeductible,
            liability_limit: values.liabilityLimit,
            medical_limit: null,
          },
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/documents/insurance/purchase`,
      timeout: 30000,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response, message } = error;
    throw response || message;
  }
};

export const editInsurancePolicy = async (cognitoToken, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const data = {
      data: {
        attributes: {
          policyNumber: values.policyNumber,
          carrier: values.carrier,
          liability: values.liability,
          startDate: values.startDate,
          endDate: values.endDate,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/documents/policy`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getRentCred = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}/rent-cred`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const quadpayCheckout = async (cognitoToken, amount) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          amount,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/quadpay/checkout`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const quadpayMerchantFee = async (cognitoToken, state, amount) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          state,
          amount,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/quadpay/merchantFee`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const makeQuadpayPayment = async (cognitoToken, amount) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          amount,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/quadpay/checkout`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getQuadpayFee = async (cognitoToken, state, amount) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          state,
          amount,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/quadpay/merchantFee`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const postQuadpayError = async (cognitoToken, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      ...values,
    };
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/quadpay/cancelled`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateTenantKyc = async (cognitoToken, id, ssn) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'user',
        id,
        attributes: {
          ssnToken: ssn,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getStripeIntent = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/stripe-connect/payment-method-token`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getTenantBoardingStatus = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}/boarding-status`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const cancelSilaTransaction = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/payments/${id}/cancel`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getBillingItem = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/billing-items/${id}?include=billingCharge,payment`;
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    return error;
  }
};

export const getLvbleAccount = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/lvble/tenants/${id}/account`,
    };
    const res = await axios(options);
    // console.log('ƒ getLvbleAccount result', res);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateLvbleAccount = async (cognitoToken, id, data) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'PATCH',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/lvble/tenants/${id}/account`,
      data,
    };
    const res = await axios(options);
    // console.log('ƒ getLvbleAccount result', res);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getLvbleToken = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/lvble/tenants/${id}`,
    };
    const res = await axios(options);
    // console.log('ƒ tenantApi/getLvbleToken result', res);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createUpdateLvbleAccount = async (cognitoToken, data) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/lvble/tenants`,
      data,
    };
    const res = await axios(options);
    // console.log('ƒ createUpdateLvbleAccount result', res);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const settleLvblePayment = async (cognitoToken, data) => {
  // console.log('[tenantApi.js] ƒ settleLvblePayment', data);
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/lvble/payments`,
      data,
    };
    const res = await axios(options);
    // console.log('ƒ settleLvblePayment result', res);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};
