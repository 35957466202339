import React from 'react';
import { ClearOutlined, CloseSquareOutlined, UserDeleteOutlined } from '@ant-design/icons';
import IconButton from 'components/uielements/IconButton/IconButton';

const DeleteRenter = (props) => {
  const { checkPendingBa, item } = props;

  const handleConfirmRemoveRenter = (item) => {
    console.log('handleConfirmRemoveRenter', item);
    props.handleRemoveRenter(
      item.billingAccounts[0].property.id,
      item.id,
      item.billingAccounts[0].property.parentPropertyId,
      item.billingAccounts[0].property.propertyGroup
    );
  };

  const handleConfirmDeleteInvite = (item) => {
    // console.log('handleConfirmDeleteInvite', item);
    props.handleDeleteInvitation(item.id);
  };

  return checkPendingBa(item) ? (
    <IconButton
      toolTitle="Delete Invitation"
      icon={<UserDeleteOutlined />}
      handleClick={() => handleConfirmDeleteInvite(item)}
      loading={item.isDeleting}
      popTitle="Really Delete?"
      data-testid="deleteInvitedRenterTest"
      showPopconfirm
    />
  ) : (
    <IconButton
      toolTitle="Remove Renter"
      icon={<CloseSquareOutlined />}
      handleClick={() => handleConfirmRemoveRenter(item)}
      loading={item.isDeleting}
      popTitle="Really Remove?"
      data-testid="removeRenterTest"
      showPopconfirm
    />
  );
};

export default DeleteRenter;
