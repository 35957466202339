import { Button, Col, Divider, Row, Space } from 'antd';
import CardBlank from 'components/CardBlank/CardBlank';
import { isMobile } from 'react-device-detect';
import Text from 'components/Text/Text';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';
import styled from 'styled-components';
import OnboardingActions from './OnboardingActions';
import OnboardingForms from './OnboardingForms/OnboardingForms';
import OnboardingSteps from './OnboardingSteps';
const { REACT_APP_ENV } = process.env;

function MobileGetStarted(props) {
  const { setCurrentStep } = props;
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();

  return (
    <>
      <CardBlank style={{ margin: '0 auto' }}>
        <Text as="div" strong size={36} color="black" style={{ textAlign: 'center' }}>
          Welcome!
        </Text>
        <Text as="div" size={20} color="black" style={{ textAlign: 'center' }}>
          You'll be ready to collect rent in a few simple steps.
        </Text>
        <Space vertical={24} />
        <StyledContainer className="onboarding-container">
          <OnboardingSteps {...props} />
          {isMobile && <Divider />}
          <OnboardingForms {...props} />
          <OnboardingActions {...props} />
        </StyledContainer>
      </CardBlank>
      {REACT_APP_ENV !== 'production' && (
        <Button
          type="link"
          style={{ float: 'right' }}
          onClick={() => {
            dispatch(
              updateQuickStartStatusAction(
                session.userData.id,
                {
                  visits: 0,
                  lastCompletedStep: -1,
                  finished: false,
                },
                session.cognitoTokens.idToken.jwtToken
              )
            );
            setCurrentStep(0);
          }}
        >
          Reset quickstart status (testing use only)
        </Button>
      )}
    </>
  );
}

const StyledContainer = styled.div`
  display: block;
  margin: 24px auto;
`;

export default MobileGetStarted;
