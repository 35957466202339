import React from 'react';
import { Tag as AntTag } from 'antd';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Tag = (props) => {
  const {
    children,
    color,
    outlined,
    alignment,
    bottom,
    top,
    right,
    fontSize,
    backgroundcolor,
    bordercolor,
    responsive,
    textColor,
    keyId,
    ...rest
  } = props;

  return (
    <TagContainer
      bottom={bottom}
      alignment={alignment}
      top={top}
      right={right}
      responsive={responsive ? 'true' : 'false'}
      className="customTag"
      // key={keyId}
    >
      <StyledTag
        {...rest}
        color={color}
        backgroundcolor={backgroundcolor}
        bordercolor={bordercolor}
        responsive={responsive ? 'true' : 'false'}
        fontSize={fontSize}
        textcolor={textColor}
      >
        {children}
      </StyledTag>
    </TagContainer>
  );
};

Tag.propTypes = {
  color: PropTypes.string,
  backgroundcolor: PropTypes.string,
  bordercolor: PropTypes.string,
  fontSize: PropTypes.number,
  textColor: PropTypes.string,
  responsive: PropTypes.bool,
};

Tag.defaultProps = {
  fontSize: 12,
  responsive: true,
};

const TagContainer = styled.div`
  display: inline;
  ${(props) => {
    if (props.responsive === 'true') {
      return css`
        @media screen and (max-width: 1050px) {
          font-size: calc(8px + 0.5vw);
        }
        @media screen and (max-width: 575px) {
          display: inline;
          margin-bottom: 5px;
        }
        @media screen and (max-width: 479px) {
          font-size: 12px;
        }
        @media screen and (max-width: 392px) {
          font-size: 12px;
        }
      `;
    }
  }};
`;
const StyledTag = styled(AntTag)`
  padding: 3px 7px;
  border-radius: 4px;
  margin-bottom: 8px;
  ${(props) => {
    if (props.fontSize) {
      return css`
        font-size: ${props.fontSize}px;
      `;
    }
  }};
  ${(props) => {
    if (props.textcolor) {
      return css`
        color: ${props.textcolor};
      `;
    }
  }};
  ${(props) => {
    if (props.backgroundcolor) {
      return css`
        background-color: ${props.backgroundcolor};
      `;
    }
  }};
  ${(props) => {
    if (props.bordercolor) {
      return css`
        border: 1px solid ${props.bordercolor};
      `;
    }
  }};
  ${(props) => {
    if (props.responsive === 'true') {
      return css`
        @media screen and (max-width: 1050px) {
          font-size: calc(7px + 0.5vw);
        }
        @media screen and (max-width: 575px) {
        }
        @media screen and (max-width: 479px) {
          font-size: 12px;
        }
        @media screen and (max-width: 392px) {
          font-size: 12px;
        }
      `;
    }
  }};
`;

export default Tag;
