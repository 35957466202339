import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Card, ConfigProvider, List, Avatar as AntAvatar, theme, Skeleton } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { displayAlertAction } from 'store/actions/globalActions';
import { updateFeedItem } from 'services/api/landlordApi/update';
import Text from 'components/Text';
import Tag from 'components/Tag';
import FilterDropdown from './FilterDropdown';
import PinIcon from './PinIcon';
import FlagIcon from './FlagIcon';
import ArchiveIcon from './ArchiveIcon';
import { ReactComponent as ActivityFeedSvg } from 'assets/empty-table-images/Feed.svg';
import EmptyLarge from 'components/EmptyLarge/EmptyLarge';

const TableContext = React.createContext(false);

const ActivityFeed = (props) => {
  // console.log('[ActivityFeed.js] props', props);
  const { token } = theme.useToken();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const { activityFeed, feedLength, setFeedLength, fetchActivityFeed, filterActivityFeed, loading } = props;
  const [pinnedFeed, setPinnedFeed] = useState([]);
  const [feed, setFeed] = useState([]);
  const [filters, setFilters] = useState([]);
  const [animateRow, setAnimateRow] = useState({});

  const [isDesktop, setDesktop] = useState(window.innerWidth > 575);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 575);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    // console.log('ƒ useEffect on activityFeed', activityFeed);
    if (activityFeed) {
      let items = [];
      let pinnedItems = [];
      if (filters.includes('archived')) {
        items = activityFeed.feed.filter((f) => f.pinned === false);
        pinnedItems = activityFeed.pinned;
      } else {
        items = activityFeed.feed.filter((f) => f.archived === false && f.pinned === false);
        pinnedItems = activityFeed.pinned.filter((f) => f.archived === false);
      }

      setPinnedFeed(pinnedItems);
      setFeed(items.slice(0, feedLength));
    }
  }, [activityFeed]);

  const avatarLetters = (name) => {
    const nameArr = name.split(' ');
    if (nameArr.length) {
      const initials = [];
      nameArr.forEach((n) => initials.push(n.charAt(0)));
      return initials.join('');
    }
    return name.charAt(0);
  };

  const tagMap = (tag) => {
    switch (tag) {
      case 'verification':
        return 'Enrolled';
      case 'payment':
        return 'Payment';
      case 'billing':
        return 'Billing';
      case 'schedule':
        return 'Schedule';
      case 'credit':
        return 'Credit';
      case 'chargeback':
        return 'Chargeback';
      case 'document':
        return 'Document';
      default:
        return null;
    }
  };

  const handleUpdateItem = async (id, action, data) => {
    const cognitoToken = session.cognitoTokens.idToken.jwtToken;
    try {
      // console.log('ƒ handleUpdateItem', cognitoToken, id, action, data);
      await updateFeedItem(cognitoToken, id, action, data);
      if (action === 'pin') setFeedLength(feed.length);
      fetchActivityFeed(filters);
    } catch (error) {
      console.log('error', error);
      dispatch(displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Request failed.', true, 5000));
    }
  };

  const renderTags = (tags) => (
    <div className="tags-wrapper">
      {tags.map((tag) => {
        const colors = {
          verification: 'purple',
          payment: 'green',
          schedule: 'geekblue',
          chargeback: 'volcano',
          billing: 'orange',
          credit: 'green',
          document: 'magenta',
        };
        return (
          <Tag color={colors[tag.toLowerCase()]} key={tag} fontSize={12}>
            {tagMap(tag)}
          </Tag>
        );
      })}
    </div>
  );

  const ActivityFeedList = ({ feed }) => (
    <StyledList
      dataSource={parseData(feed)}
      itemLayout={isDesktop ? 'horizontal' : 'vertical'}
      size="small"
      pagination={false}
      color={token.colorText}
      loading={loading}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            <PinIcon
              handleUpdateItem={handleUpdateItem}
              buttons={item.buttons}
              feed={feed}
              animateRow={animateRow}
            />,
            <FlagIcon buttons={item.buttons} handleUpdateItem={handleUpdateItem} />,
            <ArchiveIcon buttons={item.buttons} handleUpdateItem={handleUpdateItem} />,
          ]}
          extra={isDesktop ? null : renderTags(item.tags)}
        >
          <List.Item.Meta
            avatar={
              <AntAvatar size={36} shape="square">
                {avatarLetters(item.name.name)}
              </AntAvatar>
            }
            title={item.name.name}
            description={
              <Text color="black">
                {item.name.feed} {moment(item.name.createdAt).fromNow()}
              </Text>
            }
          />
          {isDesktop ? renderTags(item.tags) : null}
        </List.Item>
      )}
    />
  );

  const loadMore = () => {
    if (feed.length >= 20) {
      const withoutArchivedItems = activityFeed.feed.filter((f) => f.archived === false);
      setFeed([...feed, ...withoutArchivedItems.slice(feed.length, feed.length + 10)]);
    }
  };

  const parseData = (values) => {
    const parsed = values.map((v, i) => {
      return {
        key: v.id + i,
        name: {
          name: v.renterName,
          feed: v.message,
          createdAt: Number(v.createdAt),
        },
        tags: [v.type],
        landlordId: v.landlordId,
        id: v.id,
        renterId: v.renterId,
        buttons: {
          id: v.id,
          pinned: v.pinned,
          flagged: v.flagged,
          archived: v.archived,
        },
        mobile: {
          key: v.id + i,
          name: v.renterName,
          feed: v.message,
          createdAt: Number(v.createdAt),
          tags: [v.type],
          archived: v.archived,
          landlordId: v.landlordId,
          id: v.id,
          renterId: v.renterId,
          flagged: v.flagged,
          pinned: v.pinned,
        },
      };
    });
    return parsed;
  };

  return (
    <>
      <StyledCard
        title="Recent Activity"
        extra={
          <div className="card-actions">
            <Badge count={pinnedFeed.length} offset={[7, 2]}>
              {pinnedFeed.length ? (
                <PushpinFilled style={{ fontSize: '20px', color: token.colorPrimary }} />
              ) : (
                <PushpinOutlined style={{ fontSize: '20px', color: token.colorPrimary }} />
              )}
            </Badge>
            <FilterDropdown
              setFilters={setFilters}
              filters={filters}
              filterActivityFeed={filterActivityFeed}
              fetchActivityFeed={fetchActivityFeed}
            />
          </div>
        }
        styles={{
          header: {
            fontSize: '20px',
            fontWeight: 600,
            padding: '0 24px 0 24px',
            color: '#541388',
            mrginBottom: '0px',
          },
        }}
        className="activityFeedTour"
      >
        {!!pinnedFeed.length && (
          <div
            id="scrollablePinnedDiv"
            style={{
              maxHeight: isDesktop ? 205 : 262,
              overflow: 'auto',
              borderBottom: `1px solid ${token.colorBorderSecondary}`,
            }}
            data-testid="pinnedDivTest"
          >
            <ActivityFeedList feed={pinnedFeed} />
          </div>
        )}
        <TableContext.Provider value={false}>
          <ConfigProvider
            renderEmpty={() => (
              <EmptyLarge
                image={<ActivityFeedSvg />}
                description="No activities yet but recent scheduled and completed payments will appear here."
              />
            )}
          >
            <div id="scrollableDiv" style={{ maxHeight: 600, overflow: 'auto' }} data-testid="dataTest">
              <InfiniteScroll
                dataLength={feed.length}
                next={loadMore}
                hasMore={feed.length < activityFeed.count}
                loader={
                  <Skeleton
                    active
                    avatar={{ shape: 'square', size: 36 }}
                    paragraph={{ rows: 1 }}
                    style={{ padding: '8px 16px' }}
                  />
                }
                scrollableTarget="scrollableDiv"
              >
                <ActivityFeedList feed={feed} />
              </InfiniteScroll>
            </div>
          </ConfigProvider>
        </TableContext.Provider>
      </StyledCard>
    </>
  );
};

// ant-table-wrapper border-bottom: 1px #f0f0f0 solid

const StyledList = styled(List)`
  .ant-list-item {
    gap: 8px;
  }
  h4.ant-list-item-meta-title {
    font-size: 16px !important;
    font-weight: 600;
    color: ${(props) => props.color} !important;
  }
  ul.ant-list-item-action {
    margin-inline-start: 50px !important;
  }
`;

const StyledCard = styled(Card)`
  min-height: 360px;
  .ant-card-body {
    padding: 0px;
  }
  .ant-card-extra {
    width: 16.5%;
  }
  .card-actions {
    display: flex;
    justify-content: flex-end;
    gap: 36px;
  }
  @media screen and (max-width: 1178px) {
    .ant-card-extra {
      width: 18%;
    }
  }
  @media screen and (max-width: 994px) {
    .ant-card-extra {
      width: 20%;
    }
  }
  @media screen and (max-width: 576px) {
    .ant-card-extra {
      width: 30%;
    }
  }
`;

export default ActivityFeed;
