export const displayAlertAction = (message, type, description, closable, time) => (dispatch) => {
  dispatch({
    type: 'SET_ALERT',
    payload: {
      isVisible: true,
      message,
      type,
      description,
      closable,
    },
  });

  setTimeout(() => {
    dispatch({
      type: 'UNSET_ALERT',
    });
  }, time);
};

export const clearPanel = () => (dispatch) => {
  dispatch({
    type: 'SET_PANEL',
    payload: null,
  });
};
