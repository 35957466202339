import React from 'react';
import { Menu, Dropdown } from 'antd';

import styled from 'styled-components';

const DropdownButton = (props) => {
  const { handleMenuClick, handleButtonClick, items, text, type, loading } = props;

  const menuItems = items.map((i) => ({ key: i.key, label: i.action }));

  // const menu = <Menu onClick={handleMenuClick}>{menuItems}</Menu>;

  return (
    <Dropdown.Button
      menu={{ items: menuItems, onClick: handleMenuClick }}
      htmlType="submit"
      type={type}
      onClick={handleButtonClick}
      buttonsRender={([leftButton, rightButton]) => [
        <div title="tooltip" key="leftButton">
          {leftButton}
        </div>,
        React.cloneElement(rightButton, { loading }),
      ]}
      disabled={loading}
      {...props}
    >
      {text}
    </Dropdown.Button>
  );
};

const StyledDropdownButton = styled(Dropdown.Button)`
  font-weight: 500;
  font-family: Poppins, sans-serif;
  div .ant-btn {
    border-radius: 4px 0px 0px 4px;
    height: 34px;
    padding: 5px 15px;
  }
  .ant-btn-icon-only {
    height: 34px;
  }
  .ant-btn {
    background-color: #cb48b7 !important;
    border-color: white !important;
    color: white;
    border-radius: 0px 4px 4px 0px;
    &:hover {
      background-color: #7530ac;
      border-color: #7530ac;
      color: white;
    }
    &:active {
      background-color: #7530ac;
      border-color: #7530ac;
      color: white;
    }

    &:focus {
      background-color: #7530ac;
      border-color: #7530ac;
      color: white;
    }
  }
`;

export default DropdownButton;
