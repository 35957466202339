export const featureDescriptions = {
  pAYBYText: 'Tenants can pay by SMS text message',
  fLEXFunding: 'Payout funds to multiple bank accounts',
  rENTALListing: 'List properties on rental websites',
  rENTReminders: 'Automatic rent reminders',
  aCHCCPayments: 'Accept ACH and credit card payments',
  eVICTIONAssist: 'Eviction assistance',
  eSIGNDocument: 'eSign documents',
  dOCUMENTStorage: 'Store and share documents',
  eXPRESSFunding: 'Same-day funding (additional fees apply)',
  pAYMENTControls: 'Prevent partial payments',
  aDVANCEDReporting: 'Receive emailed settlement reports',
  rENTERPAYSFees: 'Pass-through ACH fees to tenants',
  sCHEDULEDPayments: 'Tenants can setup auto-pay',
  aDDITIONALAssessments: 'Automatically charge late fees',
  oNTIMERENTGuarantee: 'Protect against late and lost rent',
  tENANTISSUEMGTComms: 'Manage tenant communications',
  mULTIPLEPROPERTYGroup: 'Manage multiple bank accounts and tax IDs',
  rENTCOLLECTIONPOLICYManagement: 'Require automatic rent payments',
};
