import React from 'react';
import { theme } from 'antd';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';
import { isDesktop } from 'react-device-detect';
import { StyledSteps } from '../WepayOffboarding.styles';

const MigrationSteps = (props) => {
  // console.log('[MigrationSteps.js] props', props);
  const { currentStep, stepTitle } = props;
  const { token } = theme.useToken();

  return (
    <>
      <Text color="black" strong>
        Migration Steps:
      </Text>
      <Space vertical={12} />
      <StyledSteps
        direction="vertical"
        size="small"
        current={currentStep}
        token={token}
        items={[
          {
            title: stepTitle?.[0].title,
            description: isDesktop && stepTitle?.[0].description,
          },
          {
            title: stepTitle?.[1].title,
            description: isDesktop && stepTitle?.[1].description,
          },
        ]}
      />
    </>
  );
};

export default MigrationSteps;
