import React from 'react';
import { Steps, theme } from 'antd';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';
import styled from 'styled-components';

const OnboardingSteps = (props) => {
  const { currentStep, stepTitle, isDesktop } = props;
  // console.log('[OnboardingSteps] props', props);
  const { token } = theme.useToken();

  return (
    <>
      <Text color="black" strong>
        Quick Start Steps:
      </Text>
      <Space vertical={12} />
      <StyledSteps
        data-testid="onboardingStepsTest"
        direction="vertical"
        size="small"
        current={currentStep}
        token={token}
        items={[
          {
            title: stepTitle[0],
            description: isDesktop && 'Name your portfolio and set default preferences.',
          },
          {
            title: stepTitle[1],
            description: isDesktop && 'Add your first property. You can add more later.',
          },
          {
            title: stepTitle[2],
            description: isDesktop && 'Add rental payment preferences and details.',
          },
          {
            title: stepTitle[3],
            description: isDesktop && 'Setup your account to accept payments.',
          },
          {
            title: stepTitle[4],
            description: isDesktop && `We'll take care of the rest!`,
          },
        ]}
      />
    </>
  );
};

const StyledSteps = styled(Steps)`
  .ant-steps-item-description {
    font-size: 13px !important;
    color: ${(props) => props.token.colorTextSecondary} !important;
  }

  .ant-steps-item-wait .ant-steps-item-description {
    color: ${(props) => props.token.colorTextQuaternary} !important;
  }
`;

export default OnboardingSteps;
