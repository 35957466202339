import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MaskedInput from 'antd-mask-input';

import { Form, Input } from 'antd';

import Text from 'components/Text';
import Space from 'components/Space';

const IdentityVerification = () => {
  const session = useSelector((store) => store.session);

  return (
    <>
      <Text size={24} color="violet2" centered strong>
        Identity Verification
      </Text>
      <Space vertical={10} />
      <Text size={16} color="black" centered>
        US Federal regulations require us to obtain your Social Security Number to confirm your identity. This will
        not impact your credit.
      </Text>
      <Space vertical={30} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        Social Security Number
        <Form.Item
          name="ssn"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  const number = value.replace(/-/g, '').trim();
                  if (number.length === 9) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Ssn must be nine digits');
                }
                return Promise.reject('Please input your SSN');
              },
            }),
          ]}
        >
          <MaskedInput
            className="masked-input"
            placeholder="111-11-1111"
            mask="000-00-0000"
            minLength={11}
            placeholderChar=" "
            disabled={session.userData.ssnToken}
          />
        </Form.Item>
        <div id="emptyssn" style={{ position: 'absolute', top: '100px' }} key="3">
          <div className="form-control-static">
            <StyledSpan className="fake-input"></StyledSpan>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledSpan = styled.span`
  iframe {
    height: 32px;
    width: 98%;
  }
`;

export default IdentityVerification;
