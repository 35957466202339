import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { Form, DatePicker, Input, Row, Col, Tooltip } from 'antd';
import { useFlagsmith } from 'flagsmith-react';
import styled from 'styled-components';
import moment from 'moment';
import MaskedInput from 'antd-mask-input';

import { displayAlert } from 'resources/helpers';

import { vgsCss } from 'utils/vgs';

import { InfoCircleFilled } from '@ant-design/icons';

import { ReactComponent as Certified } from 'assets/images/onboarding-fullscreen/certified.svg';

import { createOnboarding, submitAppForOnboarding } from 'services/api/landlordApi/create';

import Text from 'components/Text';
import SpaceResponsive from 'components/SpaceResponsive';
import Space from 'components/Space';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import { boardingElement, boardingText } from '../../animation/variants';

const VerifyIdentity = (props) => {
  const {
    setSilaAccountSetup,
    setVerifyIdentity,
    setSilaBusinessSetup,
    silaAccountSetupValues,
    setIdentityValues,
    identityValues,
    setSilaVerificationDocs,
    open: openPlaid,
    defaultPropertyGroup,
    groupOnboardingStatus,
    setShowStripeSetup,
  } = props;

  const { isLoading, isError, hasFeature, getValue } = useFlagsmith();

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [form] = Form.useForm();
  const [vgsForm, setVgsForm] = useState({});
  const [isLoaded, scriptLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  useEffect(() => {
    const createVgsForm = window.VGSCollect.create(
      process.env.REACT_APP_VGS_VAULT,
      process.env.REACT_APP_VGS_ENV,
      (s) => {
        if (s) {
          // if (s.businessEin?.isDirty) form.validateFields(['businessEin']);
          // if (s.routingNumber?.isDirty) form.validateFields(['routingNumber']);
          // if (s.bankAccountNumber?.isDirty) form.validateFields(['bankAccountNumber']);
        }
      }
    );

    const ssn_number = {
      type: 'text',
      name: 'emptyssn',
      // placeholder: 'AAA-GG-SSSS',
      fontFamily: 'Montserrat',
      css: vgsCss,
    };

    createVgsForm.field('#emptyssn .fake-input', ssn_number);
    setVgsForm(createVgsForm);
    scriptLoaded(true);
  }, []);

  useEffect(() => {
    if (identityValues) {
      form.setFieldsValue({
        dateOfBirth: identityValues.dateOfBirth,
      });
    }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const formData = values;
    const cleanSsn = formData.ssn.replace(/-/g, '').trim();
    const flowId = getValue('onboarding-flow');

    vgsForm.submit(
      '/post',
      {
        data: {
          ssn: cleanSsn,
        },
      },
      async (status, response) => {
        try {
          console.log(response);
          formData.ssn = JSON.parse(response.data).ssn;
          delete formData.ssnItinConfirm;

          setIdentityValues(formData);
          // if sole propietorship create onboarding
          if (silaAccountSetupValues.ownership === 'Sole Proprietorship') {
            const mergedValues = {
              ...formData,
              ...silaAccountSetupValues,
              propertyGroupId: +defaultPropertyGroup[0].id,
            };
            const res = await createOnboarding(cognitoToken, mergedValues);

            await submitAppForOnboarding(cognitoToken, +res.id);
            setLoading(false);
            if (flowId === 1) {
              setVerifyIdentity(false);
              // if documents are required
              // setSilaBusinessSetup(true);
              // else open plaid
              // open stripe
              // setShowStripeSetup(true);
              openPlaid();
            }
          } else {
            setLoading(false);
            if (flowId === 1 || flowId === 2 || flowId === 3) {
              setVerifyIdentity(false);

              setSilaBusinessSetup(true);
            }
          }

          // if not show business details
        } catch (error) {
          setLoading(false);
          displayAlert(setAlert, 'There was an error with your request.', 'warning', 4000);
        }
      },
      (errors) => {
        console.log(errors);
        displayAlert(setAlert, 'There was an error with your request.', 'warning', 4000);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong>
          Verify Your Identity
        </Text>
      </motion.div>
      <SpaceResponsive lg={15} xmd={10} md={10} sm={10} />
      <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave" id="verifyContainer">
        <StyledForm name="verifyIdentity" form={form} onFinish={onFinish}>
          <ContentContainer className="content-container">
            <Text size={16} color="black">
              US Federal regulations require us to obtain your Social Security Number to confirm your identity.
              This will not impact your credit.
            </Text>
            <Space vertical={25} />
            <Row gutter={10}>
              <Col span={12}>
                <SsnContainer>
                  SSN or ITIN{' '}
                  <Tooltip
                    placement="top"
                    title="US Federal regulations require us to obtain your Social Security Number to confirm your identity. This will not impact your credit."
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ssnTooltip" />
                  </Tooltip>
                </SsnContainer>
                <Form.Item
                  name="ssn"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const justNumbers = value.replace(/-|_/g, '');

                        if (justNumbers) {
                          const number = value.replace(/-|_/g, '').trim();
                          if (number.length === 9) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Ssn must be nine digits');
                        }
                        return Promise.reject('Please input your SSN');
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111-11-1111"
                    mask="000-00-0000"
                    minLength={11}
                    placeholderChar=" "
                  />
                </Form.Item>
                <div id="emptyssn" style={{ position: 'absolute' }} key="3">
                  <div className="form-control-static">
                    <StyledSpan className="fake-input"></StyledSpan>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                Re-enter SSN
                <Form.Item
                  name="ssnItinConfirm"
                  dependencies={['ssn']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your SSN',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const justNumbers = value.replace(/-|_/g, '');
                        if (justNumbers) {
                          if (getFieldValue('ssn') === value) {
                            return Promise.resolve();
                          }
                        }

                        return Promise.reject('The two SSN that you entered do not match');
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111-11-1111"
                    mask="000-00-0000"
                    minLength={6}
                    placeholderChar=" "
                    data-testid="maskedInputTest"
                  />
                </Form.Item>
              </Col>
            </Row>
            Date of Birth
            <Form.Item name="dateOfBirth" rules={[{ required: true, message: 'Required' }]}>
              <DatePicker
                format="MM/DD/YYYY"
                placeholder="mm/dd/yyyy"
                disabledDate={(current) => current && current > moment().subtract(18, 'years')}
                defaultPickerValue={moment().subtract(18, 'years')}
                getPopupContainer={() => document.getElementById('verifyContainer')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </ContentContainer>
          <StyledFooter>
            <Certified />
            <AlertFade
              isVisible={alert.isVisible}
              message={alert.message}
              type={alert.type}
              minHeight="80"
              alertPosition="absolute"
              bottom={150}
            />
            <Button color="violet" bottom={0} htmlType="submit" loading={loading}>
              CONTINUE
            </Button>
          </StyledFooter>
        </StyledForm>
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: #ffffff;
  min-height: 70%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 20px 20px 20px;
  display: flex;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
  @media screen and (max-width: 400px) {
    padding: 22px 10px 15px 10px;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledFooter = styled.div`
  min-height: 110px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
  gap: 20px;
`;

const StyledSpan = styled.span`
  iframe {
    height: 32px;
    width: 98%;
  }
`;

const SsnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export default VerifyIdentity;
