// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Steps } from 'antd';
import {
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CloseSquareFilled,
} from '@ant-design/icons';
import { getSessionDetails, getOnboardingStatus, getBillingDetails } from 'services/api/landlordApi/read';

import { searchPending } from 'utils';

// Actions
import { SET_CURRENT_ONBOARDING_STEP } from 'store/actions';

// Components
import Space from 'components/Space';
import InvitesForm from './components/InvitesForm';
import SilaOnboarding from './components/SilaOnboarding';
import PropertiesForm from './components/PropertiesForm';
import WepayPaymentAccountForm from './components/PaymentAccount/WepayPaymentAccountForm';
import NuveiPaymentAccountForm from './components/PaymentAccount/NuveiPaymentAccountForm';
import SubscriptionContainer from './components/SubscriptionContainer';
import Finished from './components/Finished';
import { Title, Subtitle } from '../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../components/Panel/PanelHeader/PanelHeader';

const Onboarding = (props) => {
  const dispatch = useDispatch();
  const onboardingData = useSelector((store) => store.landlord.onboarding);
  const upgradeDowngrade = useSelector((store) => store.landlord.upgradeDowngrade);
  const session = useSelector((state) => state.session);

  const [loading, setLoading] = useState(false);
  const [userSession, setUserSession] = useState({});
  const [userSsn, setUserSsn] = useState('');
  const [boardingStatus, setBoardingStatus] = useState({
    step1: {
      data: {},
      status: '',
    },
    step2: {
      data: {},
      status: '',
    },
    step3: {
      data: {},
      status: '',
    },
    step4: {
      data: {},
      status: '',
    },
    servicePlan: {
      trial_end: '',
      trial_start: '',
    },
  });

  // const [currentStep, setCurrentStep] = useState(1);
  // const currentStep = 3;
  const currentStep = onboardingData.selectedStep;
  let stepComponent;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchBoardingStatus();
  }, [onboardingData.currentStep]);

  const fetchBoardingStatus = async () => {
    try {
      if (upgradeDowngrade.servicePlanId) {
        setBoardingStatus({ ...upgradeDowngrade.status });
        return upgradeDowngrade.status;
      }
      const onBoardStatus = await getOnboardingStatus(session.cognitoTokens.idToken.jwtToken);
      if (onBoardStatus) setBoardingStatus({ ...onBoardStatus });
      return onBoardStatus;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const onBoardStatus = await fetchBoardingStatus();
      const result = await getSessionDetails();
      const plansBillingRes = await getBillingDetails(session.cognitoTokens.idToken.jwtToken, session.userData.id);

      setUserSession(result);
      const calculateStep = searchPending(onBoardStatus);

      if (calculateStep === -1) {
        if (session.userData.paymentGateway === 'Wepay') {
          if (result.state !== 'active') await props.updatePaymentGateway();
        }
        if (session.userData.paymentGateway === 'Nuvei') {
          // const boarding = await getBoardingStatus(session.cognitoTokens.idToken.jwtToken);
          // await submitAppForOnboarding(session.cognitoTokens.idToken.jwtToken, boarding.id);
        }
        if (plansBillingRes?.paymentMethod) {
          if (plansBillingRes?.paymentMethod.paymentMethod && session.userData.paymentGateway === 'Wepay') {
            setCurrentStep(6);
          } else {
            setCurrentStep(5);
          }
        } else {
          setCurrentStep(5);
        }
      } else {
        if (result.state === 'action-required') {
          setCurrentStep(4);
        } else {
          setCurrentStep(onboardingData.selectedStep);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const setCurrentStep = (step) => {
    dispatch({
      type: SET_CURRENT_ONBOARDING_STEP,
      payload: {
        currentStep: step,
      },
    });

    // Store the step in local storage
    const localStorageSession = localStorage.getItem('payrent.session');
    const SessionData = localStorageSession ? JSON.parse(localStorageSession) : {};

    const data = {
      ...SessionData,
      onBoarding: {
        activeStep: step,
      },
    };
    localStorage.setItem('payrent.session', JSON.stringify(data));
  };

  const stepTitle = (elementStep) => {
    // onboarding

    if (boardingStatus[`step${elementStep}`].status === 'finished') return 'Finished';
    if (boardingStatus[`step${elementStep}`].status === 'submitted') return 'Submitted';
    if (boardingStatus[`step${elementStep}`].status === 'approved' && userSession.state !== 'action-required')
      return 'Approved';
    if (boardingStatus[`step${elementStep}`].status === 'Completed' && userSession.state !== 'action-required')
      return 'Approved';
    if (boardingStatus[`step${elementStep}`].status === 'approved' && userSession.state === 'action-required')
      return 'Incomplete';
    if (boardingStatus[`step${elementStep}`].status === 'in progess') {
      if (elementStep === currentStep) return 'In progress';
      if (elementStep < currentStep) return 'Incomplete';
    }
    if (boardingStatus[`step${elementStep}`].status === 'in progress') {
      if (elementStep === currentStep) return 'In progress';
      if (elementStep < currentStep) return 'Incomplete';
    }
    if (boardingStatus[`step${elementStep}`].status === 'declined') return 'Declined';
    if (boardingStatus[`step${elementStep}`].status === 'Failed') return 'Declined';
    if (boardingStatus[`step${elementStep}`].status === 'waiting') {
      if (elementStep === currentStep) {
        return 'In progress';
      }
      if (elementStep < currentStep) {
        return 'Incomplete';
      }
      return 'Waiting';
    }
    return 'Pending';
  };

  const stepIcon = (elementStep) => {
    // Onboarding

    if (boardingStatus[`step${elementStep}`].status.toUpperCase() === 'SUBMITTED') return <SyncOutlined />;
    if (boardingStatus[`step${elementStep}`].status.toUpperCase() === 'PENDING') return <SyncOutlined />;
    if (boardingStatus[`step${elementStep}`].status === 'incomplete' && currentStep !== elementStep)
      return <ExclamationCircleOutlined style={{ color: 'red' }} />;
    if (boardingStatus[`step${elementStep}`].status === 'in progess' && currentStep !== elementStep)
      return <ExclamationCircleOutlined style={{ color: 'red' }} />;
    if (boardingStatus[`step${elementStep}`].status === 'waiting' && currentStep > elementStep)
      return <ExclamationCircleOutlined style={{ color: 'red' }} />;
    // if (
    //   boardingStatus[`step${elementStep}`].status === 'approved' &&
    //   currentStep !== elementStep &&
    //   userSession.state === 'action-required'
    // )
    //   return <ExclamationCircleOutlined style={{ color: 'red' }} />;
    // if (
    //   boardingStatus[`step${elementStep}`].status === 'Failed' &&
    //   currentStep !== elementStep &&
    //   userSession.state === 'error'
    // )
    //   return <CloseCircleOutlined style={{ color: 'red' }} />;
    if (boardingStatus[`step${elementStep}`].status === 'declined')
      return <CloseCircleOutlined style={{ color: 'red' }} />;
  };

  const stepStatus = (elementStep) => {
    if (
      boardingStatus[`step${elementStep}`].status === 'Failed' &&
      currentStep !== elementStep &&
      userSession.state === 'error'
    ) {
      return 'error';
    }
    if (
      boardingStatus[`step${elementStep}`].status === 'approved' &&
      currentStep !== elementStep &&
      userSession.state === 'action-required'
    ) {
      return 'error';
    }
  };

  const getPaymentAccountComponent = () => {
    if (!upgradeDowngrade.servicePlanId) {
      switch (session.userData.paymentGateway) {
        case 'Wepay':
          return (
            <WepayPaymentAccountForm
              setCurrentStep={setCurrentStep}
              setDrawerVisible={props.setDrawerVisible}
              checkLandlordStatus={props.checkLandlordStatus}
              setShowOnboarding={props.setShowOnboarding}
              setBoardingStatus={setBoardingStatus}
              getOnboardingStatus={getOnboardingStatus}
              stepTitle={stepTitle}
              stepIcon={stepIcon}
              stepStatus={stepStatus}
              loading={loading}
            />
          );
        case 'Nuvei':
          return (
            <NuveiPaymentAccountForm
              setCurrentStep={setCurrentStep}
              setDrawerVisible={props.setDrawerVisible}
              checkLandlordStatus={props.checkLandlordStatus}
              setBoardingStatus={setBoardingStatus}
              getOnboardingStatus={getOnboardingStatus}
              searchPending={searchPending}
              boardingStatus={boardingStatus}
              userSsn={userSsn}
            />
          );
      }
    }
    if (upgradeDowngrade.servicePlanId) {
      return (
        <NuveiPaymentAccountForm
          setCurrentStep={setCurrentStep}
          setDrawerVisible={props.setDrawerVisible}
          checkLandlordStatus={props.checkLandlordStatus}
          setBoardingStatus={setBoardingStatus}
          getOnboardingStatus={getOnboardingStatus}
          searchPending={searchPending}
          boardingStatus={boardingStatus}
          userSsn={userSsn}
        />
      );
    }
  };

  const getStep5Form = () => {
    switch (+session.userData.servicePlan.id) {
      case 2:
      case 3:
        return (
          <SubscriptionContainer
            setDrawerVisible={props.setDrawerVisible}
            checkLandlordStatus={props.checkLandlordStatus}
            setBoardingStatus={setBoardingStatus}
            getOnboardingStatus={getOnboardingStatus}
            setShowOnboarding={props.setShowOnboarding}
            boardingStatus={boardingStatus}
            stepTitle={stepTitle}
            stepIcon={stepIcon}
            stepStatus={stepStatus}
            globalLoading={loading}
          />
        );
      default:
        return (
          <Finished
            setCurrentStep={setCurrentStep}
            setDrawerVisible={props.setDrawerVisible}
            setShowOnboarding={props.setShowOnboarding}
            boardingStatus={boardingStatus}
            currentStep={currentStep}
            stepTitle={stepTitle}
            stepIcon={stepIcon}
            stepStatus={stepStatus}
            loading={loading}
          />
        );
    }
  };

  switch (currentStep) {
    case 1:
      stepComponent = (
        <PropertiesForm
          setCurrentStep={setCurrentStep}
          setBoardingStatus={setBoardingStatus}
          getOnboardingStatus={getOnboardingStatus}
          stepTitle={stepTitle}
          stepIcon={stepIcon}
          stepStatus={stepStatus}
          loading={loading}
          setDrawerVisible={props.setDrawerVisible}
          setShowOnboarding={props.setShowOnboarding}
        />
      );
      break;
    case 2:
      stepComponent = (
        <InvitesForm
          setCurrentStep={setCurrentStep}
          cancel={props.cancel}
          setBoardingStatus={setBoardingStatus}
          getOnboardingStatus={getOnboardingStatus}
          stepTitle={stepTitle}
          stepIcon={stepIcon}
          stepStatus={stepStatus}
          loading={loading}
          setDrawerVisible={props.setDrawerVisible}
          setShowOnboarding={props.setShowOnboarding}
        />
      );
      break;
    case 3:
      stepComponent = (
        <SilaOnboarding
          setCurrentStep={setCurrentStep}
          setBoardingStatus={setBoardingStatus}
          getOnboardingStatus={getOnboardingStatus}
          searchPending={searchPending}
          boardingStatus={boardingStatus}
          setUserSsn={setUserSsn}
          stepTitle={stepTitle}
          stepIcon={stepIcon}
          stepStatus={stepStatus}
          globalLoading={loading}
          setDrawerVisible={props.setDrawerVisible}
          checkLandlordStatus={props.checkLandlordStatus}
          setShowOnboarding={props.setShowOnboarding}
        />
      );
      break;
    case 4:
      stepComponent = getPaymentAccountComponent();
      break;
    case 5:
      stepComponent = getStep5Form();
      break;
    case 6:
      stepComponent = (
        <Finished
          setCurrentStep={setCurrentStep}
          setDrawerVisible={props.setDrawerVisible}
          setShowOnboarding={props.setShowOnboarding}
          boardingStatus={boardingStatus}
          stepTitle={stepTitle}
          stepIcon={stepIcon}
          stepStatus={stepStatus}
          loading={loading}
        />
      );
      break;
    default:
      stepComponent = (
        <PropertiesForm
          setCurrentStep={setCurrentStep}
          setBoardingStatus={setBoardingStatus}
          getOnboardingStatus={getOnboardingStatus}
        />
      );
  }

  return (
    <>
      <Header>
        <CloseSquareFilled
          style={{ fontSize: '25px', color: '#122C34' }}
          onClick={() => {
            props.setDrawerVisible(false);
            props.setShowOnboarding(false);
            dispatch({
              type: 'UNSET_UPGRADE_DOWNGRADE',
            });
          }}
          data-testid="drawerCloseTest"
        />
      </Header>
      <Container>
        <Title>Get Started</Title>

        <Subtitle>In just a few simple steps you’ll be collecting rent faster, easier and more securely</Subtitle>
        <Space vertical={10} />

        {/*
        loading && (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        )*/}
        {stepComponent}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 27px);
  margin: 0px auto;
  border-radius: 24px;
  width: 100%;
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #1890ff;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #1890ff;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #1890ff;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: #1890ff;
    border-color: #1890ff;
  }

  .ant-input,
  .ant-select-selection-item,
  input {
    font-size: 16px;
  }
  .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-select-selector {
    height: 31px !important;
    color: #595959;
  }
`;

export default Onboarding;
