import styled from "styled-components";
import { Button, Card } from "antd";

export const LandingButton = styled(Button)`
  height: 120px;
  width: 240px;
  padding: 24px;
  background: #cb47b8;
  border-radius: 6px;
  border-width: 3px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-weight: 600;
  font-size: 18px;

  &:hover {
    color: #cb47b8;
    border-color: #cb47b8;
  }
`;