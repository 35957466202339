import React from 'react';
import { Button, Tooltip, Popover, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { QuestionOutlined } from '@ant-design/icons';

function IconButton(props) {
  // console.log('IconButton props', props);
  const { popTitle, toolTitle, content, icon, showPopover, handleClick, loading, disabled, showPopconfirm } =
    props;

  return showPopover ? (
    <Popover content={content} title={popTitle} placement="topRight" data-testid="iconButtonPopoverTest">
      <Button
        type="link"
        size="large"
        icon={icon}
        onClick={handleClick}
        disabled={disabled}
        loading={loading}
        data-testid={props['data-testid']}
      />
    </Popover>
  ) : showPopconfirm ? (
    <Tooltip title={toolTitle} trigger="hover">
      <Popconfirm
        description={popTitle}
        onConfirm={handleClick}
        okText="Yes"
        cancelText="No"
        placement="topRight"
        data-testid="iconButtonPopconfirmTest"
      >
        <Button
          type="link"
          size="large"
          icon={icon}
          disabled={disabled}
          loading={loading}
          data-testid={props['data-testid']}
        />
      </Popconfirm>
    </Tooltip>
  ) : (
    <Tooltip title={toolTitle} trigger="hover">
      <Button
        type="link"
        size="large"
        icon={icon}
        onClick={handleClick}
        disabled={disabled}
        loading={loading}
        data-testid={props['data-testid']}
      />
    </Tooltip>
  );
}

IconButton.propTypes = {
  popTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toolTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  showPopover: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

IconButton.defaultProps = {
  popTitle: 'popTitle missing',
  toolTitle: 'toolTitle missing',
  content: 'No content provided',
  icon: <QuestionOutlined />,
  showPopover: false,
  disabled: false,
  loading: false,
};

export default IconButton;
