import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Form, Input, Select } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';

const NuveiBankAccountDetail = (props) => {
  const { onFinish, editPaymentMethod } = props;
  const userData = useSelector((store) => store.session.userData);
  const servicePlanData = useSelector((store) => store.session.userData.servicePlan);

  const [form] = Form.useForm();

  useEffect(() => {
    if (editPaymentMethod?.length) {
      const accountNumber = editPaymentMethod[0].paymentGatewayMeta?.account;
      const routing = editPaymentMethod[0].paymentGatewayMeta?.routing;
      const maskedAccountNumber = accountNumber.replace(/.(?=.{4,}$)/g, '*');
      const maskedRouting = routing.replace(/.(?=.{4,}$)/g, '*');
      form.setFieldsValue({
        paymentMethodName: editPaymentMethod[0].label,
        accountNumber: maskedAccountNumber,
        routingNumber: maskedRouting,
      });
    }
  }, []);
  return (
    <>
      <Text size={12} color={'black'} strong centered>
        {userData.property?.paymentSettings?.absorbFeesPercent === 100 &&
        userData.property?.paymentSettings?.absorbFees
          ? 'Fees: $0 Your landlord is paying this fee'
          : `Fees: ${(Number(servicePlanData?.achRateFee) * 100).toFixed(2)}% per transaction (max. $${
              servicePlanData?.cap
            })`}
      </Text>
      {userData.property?.paymentSettings?.absorbFees &&
        userData.property?.paymentSettings?.absorbFeesPercent > 0 &&
        userData.property?.paymentSettings?.absorbFeesPercent < 100 && (
          <Text size={12} color={'black'} strong centered>
            Your landlord is absorbing {userData.property?.paymentSettings?.absorbFeesPercent}% of this fee
          </Text>
        )}
      <Space vertical={10} />
      <div>
        <Form form={form} onFinish={onFinish}>
          <span className="inputTitle">Payment Method Name</span>
          <Form.Item name="paymentMethodName" whitespace="true">
            <Input disabled />
          </Form.Item>

          <span className="inputTitle">Bank Account</span>
          <Form.Item
            name="accountNumber"
            whitespace="true"
            rules={[{ required: true, message: 'Please enter your account number' }]}
          >
            <Input disabled />
          </Form.Item>
          <span className="inputTitle">Bank Routing Number</span>
          <Form.Item
            name="routingNumber"
            whitespace="true"
            rules={[{ required: true, message: 'Please enter your account number' }]}
          >
            <Input disabled />
          </Form.Item>
          <Button htmlType="submit" color="violet" alignment="center" size="medium" disabled>
            Save
          </Button>
        </Form>
        {/**
       <Button type="link" alignment="center" onClick={() => {}}>
        Delete payment method
      </Button>
       */}
      </div>
    </>
  );
};

export default NuveiBankAccountDetail;
