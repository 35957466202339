export const boardingContanter = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
};

export const boardingElement = {
  hidden: { opacity: 0, x: '50%' },
  enter: { opacity: 1, x: 0, transition: { ease: 'easeInOut', duration: 0.5 } },
  leave: { opacity: 0, x: '-50%', transition: { ease: 'easeInOut', duration: 0.5 } },
};

export const boardingText = {
  hidden: { opacity: 0 },
  enter: { opacity: 1, transition: { delay: 0.3 } },
  leave: { opacity: 0 },
};
