import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlagsmith } from 'flagsmith-react';
import { Form, Select, Row, Col, Input, Checkbox } from 'antd';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import parser from 'parse-address';
import MaskedInput from 'antd-mask-input';
import Space from 'components/Space';

import { getSilaBusinessRoles } from 'services/api/landlordApi/read';

import { checkZipCode, checkCity, checkAddressValidator } from 'resources/FormValidators';
import { usStates } from 'resources/FormData';
import Text from 'components/Text';
import SpaceResponsive from 'components/SpaceResponsive';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import { boardingElement, boardingText } from '../../animation/variants';

const { Option } = Select;

const AccountSetup = (props) => {
  const {
    setSilaAccountSetup,
    open,
    setSilaBusinessSetup,
    silaAccountSetupValues,
    setSilaAccountSetupValues,
    setVerifyIdentity,
  } = props;

  const { isLoading, isError, hasFeature, getValue } = useFlagsmith();

  const landlord = useSelector((store) => store.landlord);
  const session = useSelector((store) => store.session);

  const [form] = Form.useForm();
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [businessRoles, setBusinessRoles] = useState([]);
  const [termsConditions, setTermsConditions] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const handleValuesChange = (changedValues) => {
    const [key] = Object.keys(changedValues);
    const value = changedValues[key];
    let newValue;

    switch (key) {
      case 'ownership':
        newValue = value === 'Sole Proprietorship' ? { businessRole: 'beneficial_owner' } : { businessRole: null };
        form.setFieldsValue(newValue);
        break;
      case 'termsConditions':
        setDisableButton(!value);
        break;
      default:
        // console.log(`No case found for ${key} field`);
        break;
    }
  };

  useEffect(() => {
    let businessRole = null;
    if (landlord.onboardingStatus?.step3?.status === 'finished') {
      businessRole = landlord.onboardingStatus?.step3?.businessRole;
      form.setFieldsValue({
        businessType: landlord.onboardingStatus.step3?.data?.businessType,
        firstName: landlord.onboardingStatus.step3?.data?.firstName,
        lastName: landlord.onboardingStatus.step3?.data?.lastName,
        homeAddress: landlord.onboardingStatus.step3?.data?.num
          ? `${landlord.onboardingStatus.step3?.data?.num} ${landlord.onboardingStatus.step3?.data?.homeAddress}`
          : landlord.onboardingStatus.step3?.data?.homeAddress,
        zipCode: landlord.onboardingStatus.step3?.data?.zipCode,
        city: landlord.onboardingStatus.step3?.data?.city,
        state: landlord.onboardingStatus.step3?.data?.state,
        email: landlord.onboardingStatus.step3?.data?.email,
        mobilePhone: landlord.onboardingStatus.step3?.data?.phone
          ? landlord.onboardingStatus.step3?.data?.phone.replace('+1', '')
          : '',
      });
    } else if (silaAccountSetupValues) {
      businessRole = silaAccountSetupValues.businessRole;
      delete silaAccountSetupValues.ssn;
      delete silaAccountSetupValues.ssnItinConfirm;
      delete silaAccountSetupValues.businessRole;
      form.setFieldsValue({
        ...silaAccountSetupValues,
      });
    }
    fetchData(businessRole);
  }, []);

  const fetchData = async (businessRole) => {
    try {
      setLoadingRoles(true);
      const bRoles = await getSilaBusinessRoles();
      setBusinessRoles(bRoles.businessRoles);
      if (businessRole) form.setFieldsValue({ businessRole });
      setLoadingRoles(false);
    } catch (error) {
      setLoadingRoles(false);
      console.log(error);
    }
  };

  const validateTermsAndConditions = (rule, value, form) => {
    if (!value) {
      return Promise.reject('Please accept the terms and conditions.');
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    try {
      console.log(getValue('onboarding-flow'));
      setLoading(true);
      const formData = values;

      formData.processorId = session.userData.servicePlan.processor.id;
      formData.servicePlanId = +session.userData.servicePlan.id;
      formData.mobilePhone = formData.mobilePhone.replace(/\s/g, '');

      const parsedAddress = parser.parseLocation(formData.homeAddress);
      if (!parsedAddress) throw { message: 'Not a valid address' };

      const streetNumber = parsedAddress.number;
      const streetName = formData.homeAddress.replace(`${parsedAddress.number} `, '');

      formData.streetNumber = streetNumber;
      formData.streetName = streetName;

      setLoading(false);

      setSilaAccountSetupValues(formData);
      setSilaAccountSetup(false);
      setVerifyIdentity(true);

      // await createOnboarding(cognitoToken, formData);
    } catch (error) {
      console.log(error);
      displayAlert(
        error?.message || error.data?.errors[0].detail || 'There was an error with you request.',
        'warning'
      );
    }
  };

  const displayAlert = (message, type) => {
    setAlert({
      isVisible: true,
      message,
      type,
    });

    setTimeout(() => {
      setAlert({
        isVisible: false,
      });
    }, 2000);
  };

  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong>
          Tell Us About You
        </Text>
      </motion.div>
      <SpaceResponsive lg={15} xmd={10} md={10} sm={10} />
      <Container
        data-testid="silaSetupTest"
        variants={boardingElement}
        initial="hidden"
        animate="enter"
        exit="leave"
      >
        <Text size={16} color="black">
          All financial institutions are required by federal law to obtain, verify, and record information about
          you, including your name, address, and date of birth. We may also ask to see your driver&apos;s license
          or other identifying documents.
        </Text>
        <Space vertical={25} />
        <StyledForm
          name="accountSetup"
          form={form}
          onFinish={onFinish}
          onValuesChange={(changedValues) => handleValuesChange(changedValues)}
        >
          <ContentContainer className="content-container" id="contentContainerId">
            <Row gutter={15}>
              <Col span={12}>
                Business Type
                <Form.Item
                  name="ownership"
                  rules={[{ required: true, message: 'Please select your business type', whitespace: true }]}
                >
                  <Select
                    placeholder="Select Type"
                    getPopupContainer={() => document.getElementById('contentContainerId')}
                  >
                    <Option value="Sole Proprietorship">Sole Propietorship</Option>
                    <Option value="LLC">LLC</Option>
                    <Option value="Corporation">Corporation</Option>
                    <Option value="Non-Profit">Non Profit</Option>
                    <Option value="Government">Goverment Organization</Option>
                    <Option value="Partnership">Partnership</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                Business Role
                <Form.Item name="businessRole" rules={[{ required: true, message: 'Please select your role' }]}>
                  <Select
                    placeholder="Select Role"
                    loading={loadingRoles}
                    getPopupContainer={() => document.getElementById('contentContainerId')}
                  >
                    {businessRoles.map((br) => (
                      <Option value={br.name} key={br.uuid}>
                        {br.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                First Name
                <Form.Item
                  name="firstName"
                  data-testid="firstNameTest"
                  rules={[{ required: true, message: 'Please input your first name', whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                Last Name
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your last name', whitespace: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Home Address
                <Form.Item
                  rules={[
                    { required: true, message: 'Please input your address', whitespace: true },
                    {
                      pattern: new RegExp(/(?!^\d+$)^.+$/),
                      message: 'Address must be numbers and letters',
                    },
                    {
                      max: 100,
                      message: 'Address cant be more than 100 characters',
                    },
                    {
                      validator: checkAddressValidator,
                    },
                  ]}
                  name="homeAddress"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={10}>
                Zip Code
                <Form.Item
                  name="zipCode"
                  rules={[
                    {
                      validator: checkZipCode,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                City
                <Form.Item
                  name="city"
                  data-testid="cityTest"
                  rules={[
                    {
                      validator: checkCity,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={10}>
                State
                <Form.Item
                  name="state"
                  data-testid="stateTest"
                  rules={[{ required: true, message: 'Please select a state' }]}
                >
                  <Select
                    placeholder="Select State"
                    showSearch
                    onChange={() => {}}
                    getPopupContainer={() => document.getElementById('contentContainerId')}
                  >
                    {usStates.map((s, i) => (
                      <Option value={s.abbreviation} key={i}>
                        {s.abbreviation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={14}>
                Email Address
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not a valid email',
                    },
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10}>
                Mobile Phone
                <Form.Item
                  name="mobilePhone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone number',
                    },
                    {
                      // pattern: new RegExp('^[0-9]{6,}$'),
                      // supports ten characters without + sign
                      // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                      pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                      message: 'Please enter only numbers - min length 10',
                    },
                    {
                      message: 'Please input a valid phone number.',
                      max: 12,
                    },
                  ]}
                >
                  <MaskedInput
                    className="masked-input"
                    placeholder="111 111 1111"
                    mask="000 000 0000"
                    minLength={6}
                    placeholderChar=" "
                    data-testid="phoneTest"
                    prefix="+1"
                  />
                </Form.Item>
              </Col>
            </Row>
          </ContentContainer>
          <StyledFooter>
            <AlertFade
              isVisible={alert.isVisible}
              message={alert.message}
              type={alert.type}
              minHeight="80"
              alertPosition="absolute"
              bottom={150}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <StyledItem>
                <Form.Item
                  name="termsConditions"
                  valuePropName="checked"
                  rules={[{ validator: validateTermsAndConditions }]}
                  noStyle
                >
                  <Checkbox />
                </Form.Item>
                <StyledText style={{ color: 'black', marginLeft: '5px' }}>I agree to the </StyledText>
                <StyledText
                  style={{
                    color: '#cb48b7',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    margin: '0px 5px 0px 5px',
                  }}
                  onClick={() => setTermsConditions(true)}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <a href="https://payrent.com/terms-conditions" target="_blank" rel="noopener noreferrer">
                    terms and conditions
                  </a>
                </StyledText>
                and{' '}
                <StyledText
                  style={{
                    color: '#cb48b7',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    margin: '0px 5px 0px 5px',
                  }}
                  onClick={() => setTermsConditions(true)}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <a href="https://payrent.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                    privacy policy
                  </a>
                </StyledText>
                <StyledText style={{ color: 'black' }}> of this account</StyledText>
              </StyledItem>
            </div>

            <Button
              color="violet"
              alignment="center"
              bottom={0}
              htmlType="submit"
              loading={loading}
              disabled={disableButton}
            >
              CONTINUE
            </Button>
          </StyledFooter>
        </StyledForm>
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: #ffffff;
  min-height: 80%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 20px 15px 20px;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
  @media screen and (max-width: 400px) {
    padding: 22px 10px 15px 10px;
  }
`;

const StyledSpan = styled.span`
  iframe {
    height: 32px;
    width: 98%;
  }
`;

const SsnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const StyledText = styled.span`
  font-size: 14px;

  @media screen and (max-width: 445px) {
    font-size: 12px;
  }
  @media screen and (max-width: 390px) {
    font-size: 11px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 10px !important;
`;

const StyledForm = styled(Form)`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 80%;
`;

const StyledFooter = styled.div`
  border-top: 1px solid lightgrey;
  min-height: 110px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
`;
export default AccountSetup;
