import * as actions from 'store/actions';

// Local storage step
const localStorageSession = localStorage.getItem('payrent.session');
const SessionData = localStorageSession ? JSON.parse(localStorageSession) : {};
const step = SessionData.onBoarding?.activeStep;

const initalState = {
  login: {
    state: 'newState',
  },
  clearRedux: {
    state: 'clearRedux',
  },
  merchantAccount: {
    status: null,
    loading: true,
    errors: null,
  },
  summaryCards: {
    metrics: [
      { id: 'revenue', values: { mtd: 0, ytd: 0 }, hasNull: false },
      { id: 'occupancy', values: { mtd: 0, ytd: 0 }, hasNull: false },
      { id: 'units', values: { mtd: 0, ytd: 0 }, hasNull: false },
      {
        id: 'gross-rent-multiplier',
        values: { mtd: 0, ytd: 0 },
        hasNull: false,
      },
      {
        id: 'market-value',
        values: { mtd: 0, ytd: 0 },
        hasNull: false,
      },
      {
        id: 'net-operating-income',
        values: { mtd: 0, ytd: 0 },
        hasNull: false,
      },
      {
        id: 'cash-flow',
        values: { mtd: 0, ytd: 0 },
        hasNull: false,
      },
      { id: 'cap-rate', values: { mtd: 0, ytd: 0 }, hasNull: false },
    ],
  },
  onboarding: {
    lastCompletedStep: 0,
    currentStep: step || 0,
    selectedStep: 0,
  },
  onboardingStatus: {
    current: '',
    step1: {
      status: '',
      data: {
        propertyId: 0,
        address: '',
        unit: '',
        rentDueDate: 0,
        amount: '',
      },
    },
    step2: {
      status: '',
    },
    step3: {
      status: '',
      data: {
        firstName: '',
        lastName: '',
        homeAddress: null,
        num: null,
        zipCode: null,
        city: null,
        state: null,
        website: null,
        dateOfBirth: null,
        phone: '',
      },
    },
    step4: {
      status: '',
      data: {
        wepayStatus: '',
      },
    },
    servicePlan: {
      id: 0,
      name: '',
      processor_id: null,
      subscription_fee: '',
      ach_rate_fee: '',
      ach_trx_fee: '',
      debit_rate_fee: null,
      debit_trx_fee: null,
      credit_card_rate_fee: '',
      credit_card_trx_fee: '',
      properties: 0,
      meta_features: {
        version: '',
        PAY_BY_TEXT: true,
        SUB_ACCOUNT: false,
        FLEX_FUNDING: false,
        BALANCE_CHECK: false,
        RENTAL_LISTING: false,
        RENT_REMINDERS: true,
        ACH_CC_PAYMENTS: true,
        EVICTION_ASSIST: false,
        EXPRESS_FUNDING: false,
        PAYMENT_CONTROLS: true,
        RENTER_PAYS_FEES: false,
        ADVANCED_REPORTING: false,
        SCHEDULED_PAYMENTS: true,
        ACCOUNTING_INTEGRATION: false,
        ADDITIONAL_ASSESSMENTS: false,
        ON_TIME_RENT_GUARANTEE: false,
        TENANT_ISSUE_MGT_COMMS: false,
        RENT_COLLECTION_POLICY_MANAGEMENT: false,
      },
      state: '',
      cap: '',
      stripe_price_id: '',
      stripe_product_id: '',
      prop_unit_price: '',
      trial: 0,
      agent: null,
      office: null,
      created_at: '',
      updated_at: '',
    },
  },
  activityFeed: {
    row: '',
  },
  transactions: {
    items: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  renters: {
    items: [],
    totalCount: 0,
    loading: true,
    error: null,
  },
  units: {
    items: [],
    totalCount: 0,
    loading: true,
    error: null,
  },
  upgradeDowngrade: {
    servicePlanId: null,
    boardedApps: [],
    status: {
      step1: {
        data: null,
        status: '',
      },
      step2: {
        data: null,
        status: '',
      },
      step3: {
        data: null,
        status: '',
      },
      step4: {
        data: null,
        status: '',
      },
    },
  },
  addTransaction: {
    userName: null,
  },
  defaultPropertyGroupId: null,
};

export default (state = initalState, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_ONBOARDING_STEP:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...action.payload,
        },
      };
    case actions.SET_ONBOARDING_STATUS:
      return {
        ...state,
        onboardingStatus: {
          ...state.onboardingStatus,
          ...action.payload,
        },
      };
    case actions.GET_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          items: action.payload.items,
          totalCount: action.payload.totalCount,
          loading: false,
          error: null,
        },
      };
    case actions.SET_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: true,
        },
      };
    case actions.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          error: action.payload,
          loading: false,
        },
      };
    case actions.GET_RENTERS_LIST:
      return {
        ...state,
        renters: {
          ...state.renters,
          items: action.payload.items,
          totalCount: action.payload.total,
          loading: false,
        },
      };
    case actions.GET_PROPERTIES_LIST:
      return {
        ...state,
        units: {
          ...state.units,
          ...action.payload,
          loading: false,
        },
      };
    case actions.GET_PROPERTIES_LIST_ERROR:
      return {
        ...state,
        units: {
          ...state.units,
          error: action.payload,
          loading: false,
        },
      };
    case actions.SET_BOARDING_STATUS:
      return {
        ...state,
        merchantAccount: {
          ...state.merchantAccount,
          ...action.payload,
        },
      };
    case actions.SET_RENTERS_LIST_LOADING:
      return {
        ...state,
        renters: {
          ...state.renters,
          loading: true,
        },
      };
    case actions.GET_RENTERS_LIST_ERROR:
      return {
        ...state,
        renters: {
          ...state.renters,
          error: action.payload,
        },
      };
    case actions.GET_ALL_SUMMARY_DATA:
      return {
        ...state,
        summaryCards: {
          ...state.summaryCards,
          metrics: action.payload,
        },
      };
    // case actions.GET_PAYMENTS_SUMMARY_DATA:
    //   return {
    //     ...state,
    //     summaryCards: {
    //       ...state.summaryCards,
    //       payments: action.payload,
    //     },
    //   };
    case actions.GET_PROPERTIES_SUMMARY_DATA:
      return {
        ...state,
        summaryCards: {
          ...state.summaryCards,
          properties: action.payload,
        },
      };
    // case actions.GET_PEOPLE_SUMMARY_DATA:
    //   return {
    //     ...state,
    //     summaryCards: {
    //       ...state.summaryCards,
    //       people: action.payload,
    //     },
    //   };
    // case actions.SET_SUMMARY_CARD_PAYMENT_TYPE:
    //   return {
    //     ...state,
    //     summaryCards: {
    //       ...state.summaryCards,
    //       payments: {
    //         ...state.summaryCards.payments,
    //         type: action.payload,
    //       },
    //     },
    //   };
    case actions.SET_ACTIVITY_FEED_ROW:
      return {
        ...state,
        activityFeed: {
          row: action.payload,
        },
      };
    case actions.SET_UPGRADE_DOWNGRADE:
      return {
        ...state,
        upgradeDowngrade: {
          servicePlanId: action.payload.servicePlanId,
          boardedApps: action.payload.boardedApps,
          status: {
            ...action.payload.status,
          },
        },
      };
    case actions.UPDATE_UPGRADE_DOWNGRADE:
      return {
        ...state,
        upgradeDowngrade: {
          ...state.upgradeDowngrade,
          servicePlanId: action.payload.servicePlanId,
          status: {
            ...action.payload.status,
          },
        },
      };
    case actions.UNSET_UPGRADE_DOWNGRADE:
      return {
        ...state,
        upgradeDowngrade: {
          servicePlanId: null,
          boardedApps: [],
          status: {
            step1: {
              data: null,
              status: '',
            },
            step2: {
              data: null,
              status: '',
            },
            step3: {
              data: null,
              status: '',
            },
            step4: {
              data: null,
              status: '',
            },
          },
        },
      };
    case actions.RENTER_TRANSACTION:
      return {
        ...state,
        addTransaction: {
          userName: action.payload.userName,
        },
      };
    case actions.SET_DEFAULT_PROPERTY_GROUP:
      return {
        ...state,
        defaultPropertyGroupId: action.payload,
      };
    default:
      return state;
  }
};
