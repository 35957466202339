import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Input, Col, Row, Select, Spin } from 'antd';
import moment from 'moment';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { createUpdateBillingDetails } from 'services/api/landlordApi/create';

import AlertFade from 'components/AlertFade';
import poweredByStripe from 'assets/images/powered-stripe.png';

import PanelFooter from 'components/Panel/PanelFooter';

import Text from 'components/Text';
import Space from 'components/Space';
import Button from 'components/Button';

import { checkZipCode } from 'resources/FormValidators';
import { usStates } from '../../../../../../../resources/FormData';

const { Option } = Select;

const SubscriptionForm = (props) => {
  const session = useSelector((store) => store.session);
  const { globalLoading } = props;

  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'warning' });
  const [disableButton, setDisableButton] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const onFinish = async (values) => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setLoading(true);
      const { error, setupIntent } = await stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: 'https://my-site.com/account/payments/setup-complete',
        },
      });

      console.log(setupIntent);

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (e.g., payment
        // details incomplete)

        const errorObject = {
          message: error.message,
        };

        throw errorObject;
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
      await createUpdateBillingDetails(cognitoToken, session.userData.id, values, setupIntent.payment_method);

      setAlert({ message: 'Payment Method Added', type: 'success' });
      setLoading(false);
      setDisableButton(true);

      setTimeout(() => {
        props.setShowOnboarding(false);
        props.setDrawerVisible(false);
        setAlert({ message: '', type: 'success' });
      }, 4000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlert({
        message: error.data?.errors[0].detail || error?.message || 'There was an error with your request.',
        type: 'warning',
      });
      setTimeout(() => {
        setAlert({ message: '', type: 'success' });
      }, 4000);
    }
  };

  return (
    <>
      <StyledForm
        form={form}
        name="basic"
        initialValues={{ remember: true, extendMonth: false }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
      >
        <ContentContainer className="content-container">
          {/**
           <Steps
            onboardingData={onboardingData}
            stepTitle={stepTitle}
            stepIcon={stepIcon}
            stepStatus={stepStatus}
          />
           
           
           */}

          {globalLoading ? (
            <LoadingContainer>
              <Spin />
            </LoadingContainer>
          ) : (
            <>
              <Space vertical={5} />
              <Text color="black" strong size={20}>
                Subscription Payment Method
              </Text>
              <Space vertical={5} />
              {props.boardingStatus?.servicePlan?.trial_end && (
                <Text color="black" strong size={16}>
                  {moment().isAfter(props.boardingStatus?.servicePlan?.trial_end)
                    ? `Your trial ended on ${moment(props.boardingStatus?.servicePlan?.trial_end).format('LL')}`
                    : `You will not be billed until ${moment(props.boardingStatus?.servicePlan?.trial_end).format(
                        'LL'
                      )}
          `}
                </Text>
              )}
              <Space vertical={5} />
              <Row justify="center" gutter={10}>
                <Col span={12}>
                  First Name
                  <StyledFormItem
                    name="firstName"
                    data-testid="firstNameTest"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the full name of the renter',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input autocomplete="name" data-testid="firstNameInputTest" />
                  </StyledFormItem>
                </Col>
                <Col span={12}>
                  Last Name
                  <StyledFormItem
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your last name',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input data-testid="lastNameTest" />
                  </StyledFormItem>
                </Col>
              </Row>
              Billing Email Address
              <StyledFormItem
                name="billingEmailAddress"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please input a valid email',
                    whitespace: true,
                  },
                ]}
              >
                <Input data-testid="billingEmailAddressTest" />
              </StyledFormItem>
              Business Name (Optional)
              <StyledFormItem
                name="bussinessName"
                rules={[
                  {
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </StyledFormItem>
              <Row gutter={10}>
                <Col span={15}>
                  Billing Street Address
                  <StyledFormItem
                    name="billingStreetAddress"
                    data-testid="billingStreetAddressTest"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a valid address',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input data-testid="billingStreetTest" />
                  </StyledFormItem>
                </Col>
                <Col span={9}>
                  Billing Zip Code
                  <StyledFormItem
                    name="billingZipCode"
                    rules={[
                      {
                        validator: checkZipCode,
                      },
                    ]}
                  >
                    <Input data-testid="zipCodeTest" />
                  </StyledFormItem>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={15}>
                  Billing City
                  <StyledFormItem
                    name="billingCity"
                    data-testid="billingCityTest"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a valid city',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input data-testid="billingCityInputTest" />
                  </StyledFormItem>
                </Col>
                <Col span={9}>
                  Billing State
                  <StyledFormItem
                    name="billingState"
                    rules={[{ required: true, message: 'Please select a state' }]}
                  >
                    <Select showSearch placeholder="Select State" onChange={() => {}} data-testid="stateTest">
                      {usStates.map((s, i) => (
                        <Option value={s.abbreviation} key={i}>
                          {s.abbreviation}
                        </Option>
                      ))}
                    </Select>
                  </StyledFormItem>
                </Col>
              </Row>
              Payment Method
              <StyledFormItem
                name="paymentMethod"
                rules={[{ required: true, message: 'Please select a payment method' }]}
              >
                <Select data-testid="paymentMethodTest">
                  <Option key="1" value="card">
                    Credit Card
                  </Option>
                  <Option key="2" disabled value="ach">
                    Bank Account
                  </Option>
                </Select>
              </StyledFormItem>
              <PaymentElement style={{ minHeight: '255px' }} />
            </>
          )}
        </ContentContainer>
        <PanelFooter>
          <AlertFade
            type={alert.type}
            isVisible={!!alert.message}
            message={alert.message}
            alertPosition="absolute"
            position="absolute"
            minHeight="80"
            width={90}
            bottom={50}
          />
          <Row justify="center" style={{ marginBottom: '10px' }}>
            <img src={poweredByStripe} alt="Powered by Stripe" />
          </Row>
          <StyledItem>
            <Row justify="center">
              <Col span={6} style={{ display: 'flex' }}>
                {/**
                    <StyledText
                  inline
                  start
                  onClick={() => props.setCurrentStep(4)}
                  size={12}
                  underline
                  pointer
                  margin="0px 0px 0px 13px"
                >
                  Previous
                </StyledText>
                   
                   */}
              </Col>
              <Col span={12}>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  alignment="center"
                  // relativeMarginLeft={10}
                  bottom={0}
                  data-testid="buttonTest"
                  disabled={disableButton}
                >
                  SAVE
                </Button>
              </Col>
              <Col span={6} style={{ display: 'flex' }}>
                {/**
                  <StyledText
                  end
                  onClick={() => props.setCurrentStep(6)}
                  size={12}
                  data-testid="skipText"
                  underline
                  pointer
                >
                  Skip this for now
                </StyledText>
                 
                 */}
              </Col>
            </Row>
          </StyledItem>
        </PanelFooter>
      </StyledForm>
    </>
  );
};

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px;
  width: 100%;
  @media screen and (max-height: 750px) {
    &&&&&&&.ant-form-item {
      margin-bottom: 0px !important;
    }
  }
`;

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 116px);
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 30px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 375px) {
    font-size: 12px;
    .ant-typography {
      font-size: 18px;
    }
    .ant-col {
      font-size: 12px;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`;

export default SubscriptionForm;
