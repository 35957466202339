import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Row, Col, Select, DatePicker, Checkbox, Spin } from 'antd';
import MaskedInput from 'antd-mask-input';

import styled from 'styled-components';

import { getProperties } from 'services/api/landlordApi/read';
import { createRenter } from 'services/api/landlordApi/create';
import { disableLeaseStartDate, disableLeaseEndDate } from 'resources/FormValidators';

// Components
import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';
import SpaceResponsive from 'components/SpaceResponsive';
import Button from 'components/Button';
import Text from 'components/Text';

const { Option } = Select;

const InvitesForm = (props) => {
  const dispatch = useDispatch();
  const { loading, setDrawerVisible, setShowOnboarding } = props;
  const session = useSelector((store) => store.session);
  const oldSystemToken = useSelector((state) => state.session.oldTokens.token);
  const cognitoToken = useSelector((state) => state.session.cognitoTokens.idToken.jwtToken);
  const upgradeDowngrade = useSelector((store) => store.landlord.upgradeDowngrade);
  const token = `Bearer ${oldSystemToken}`;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [message, setMessage] = useState({ type: 'success', message: '' });
  const [extendMonth, setExtendMonth] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const propRes = await getProperties(false, 1, 10000, false, false);

      setProperties(propRes);
    } catch (error) {
      console.log(error);
    }
  };

  const displayAlert = (type, m) => {
    setMessage({
      type,
      message: m,
    });

    setTimeout(() => {
      setMessage({
        message: '',
      });
    }, 2000);
  };

  const onFinish = async (formValues) => {
    try {
      const extendedMonth = formValues.extendMonth.target
        ? formValues.extendMonth.target.checked
        : formValues.extendMonth;
      setIsLoading(true);

      let normalizePhone = '';
      if (formValues.mobilePhone) {
        normalizePhone = `${formValues?.mobilePhone.replace(/\s/g, '')}`;
        formValues.mobilePhone = normalizePhone;
      }

      formValues.landlordId = session.userData.id;
      formValues.extendMonth = extendedMonth;

      await createRenter(cognitoToken, formValues);

      displayAlert('success', 'Invitation sent!');

      setTimeout(() => {
        setIsLoading(false);
        setDrawerVisible(false);
        setShowOnboarding(false);

        props.setCurrentStep(3);
      }, 2500);
    } catch (error) {
      displayAlert('warning', error.response?.data?.errors[0].detail || 'Request failed');
      setIsLoading(false);
    }
  };

  const handleExtendMonth = (e) => {
    setExtendMonth(e.target.checked);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.mobilePhone) {
      form.validateFields(['email']);
    }
    if (changedValues.email) {
      form.validateFields(['mobilePhone']);
    }
  };

  return (
    <StyledForm
      form={form}
      name="basic"
      initialValues={{ remember: true, extendMonth: false, requireInsurance: true }}
      onFinish={onFinish}
      onFinishFailed={() => {}}
      onValuesChange={onValuesChange}
    >
      <ContentContainer className="content-container">
        {/**<Steps onboardingData={onboardingData} stepTitle={stepTitle} stepIcon={stepIcon} stepStatus={stepStatus} /> */}

        {loading ? (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        ) : (
          <>
            <SpaceResponsive lg={20} xmd={20} md={15} sm={15} />
            <Row justify="space-between" gutter={20}>
              <Col span={12}>
                First Name
                <Form.Item
                  name="firstName"
                  data-testid="firstNameTest"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the tenant's first name.",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                Last Name
                <Form.Item
                  name="lastName"
                  data-testid="lastNameTest"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the tenant's last name.",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            Renter Email Address
            <Form.Item
              shouldUpdate={(prevValues, curValues) => prevValues.mobilePhone !== curValues.mobilePhone}
              noStyle
            >
              {() => {
                return (
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not a valid email',
                      },
                      {
                        required: !form.getFieldValue('mobilePhone'),
                        message:
                          !form.getFieldValue('email') && !form.getFieldValue('mobilePhone')
                            ? 'Requires either email address or mobile phone number.'
                            : 'Please enter an email address',
                      },
                    ]}
                  >
                    <Input autocomplete="email" data-testid="emailTest" />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.email !== curValues.email} noStyle>
              {() => {
                return (
                  <Form.Item
                    label="Renter Phone Number"
                    name="mobilePhone"
                    rules={[
                      {
                        required: !form.getFieldValue('email'),
                        message:
                          !form.getFieldValue('email') && !form.getFieldValue('mobilePhone')
                            ? 'Requires either email address or mobile phone number.'
                            : 'Please enter a phone number.',
                      },
                      {
                        // pattern: new RegExp('^[0-9]{6,}$'),
                        // supports ten characters without + sign
                        // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                        pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                        message: 'Please enter only numbers - min length 10',
                      },
                      {
                        message: 'Please enter a valid phone number.',
                        max: 16,
                      },
                    ]}
                  >
                    <MaskedInput
                      placeholder="111 111 1111"
                      mask="000 000 0000"
                      placeholderChar=" "
                      minLength={6}
                      data-testid="phoneTest"
                      prefix="+1"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item name="propertyId" rules={[{ required: true, message: 'Please select a property' }]}>
              <Select defaultValue="Select property" onChange={() => {}} data-testid="selectPropertyTest">
                {properties.length &&
                  properties.map((property) => (
                    <Option value={property.id}>{`${property.address} - ${property.address2}`}</Option>
                  ))}
              </Select>
            </Form.Item>
            <StyledRow gutter={16}>
              <Col span={11}>
                Lease Start
                <Form.Item
                  name="leaseStartDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a date.',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => disableLeaseStartDate(current, form)}
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    data-testid="pickerTest"
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                Lease end
                <Form.Item
                  name="leaseEndDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a date.',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => disableLeaseEndDate(current, form)}
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    data-testid="leaseEndPickerTest"
                  />
                </Form.Item>
              </Col>
            </StyledRow>
            <Form.Item name="extendMonth" getValueProps={() => extendMonth} style={{ marginBottom: '0px' }}>
              <Checkbox onClick={handleExtendMonth} data-testid="extendMonthTest">
                <Text size={16} color="#595959">
                  Extend Month-to-Month
                </Text>
              </Checkbox>
            </Form.Item>
            <Form.Item name="requireInsurance" valuePropName="checked" style={{ marginBottom: '0px' }}>
              <Checkbox onClick={handleExtendMonth} data-testid="insuranceTest">
                <Text size={16} color="#595959">
                  Require Renters Insurance
                </Text>
              </Checkbox>
            </Form.Item>
          </>
        )}
      </ContentContainer>
      <PanelFooter>
        <AlertFade
          type={message.type}
          isVisible={!!message.message}
          message={message.message}
          alertPosition="absolute"
          position="absolute"
          minHeight="80"
          // width={80}
        />

        <StyledItem>
          <Row justify="center">
            <Col span={7} style={{ display: 'flex' }} />
            <Col span={10}>
              <Button
                type="primary"
                bottom={0}
                htmlType="submit"
                color="violet"
                loading={isLoading}
                alignment="center"
                data-testid="buttonTest"
              >
                SAVE
              </Button>
            </Col>
            <Col span={7} style={{ display: 'flex', justifyContent: 'center' }}>
              {/** <StyledText
                end
                onClick={() => props.setCurrentStep(3)}
                size={12}
                data-testid="linkTest"
                underline
                pointer
              >
                Skip this for now
              </StyledText> */}
            </Col>
          </Row>
        </StyledItem>
      </PanelFooter>
    </StyledForm>
  );
};

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px;
  width: 100%;
  @media screen and (max-height: 750px) {
    &&&&&&&.ant-form-item {
      margin-bottom: 0px !important;
    }
  }
`;

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 116px);
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 30px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled(Row)`
  margin-bottom: -14px;
`;

export default InvitesForm;
