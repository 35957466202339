import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Layout, Button, Skeleton } from 'antd';
import styled from 'styled-components';
import CardWithTitle from 'components/CardWithTitle/CardWithTitle';
import ComplianceForm from './ComplianceForm';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchUserIpAddress } from 'screens/Landlord/FinixOnboarding/forms/helpers';
import { getFinixComplianceForm } from 'services/api/landlordApi/read';
import { createFinixComplianceFormLink } from 'services/api/landlordApi/create';
import Text from 'components/Text/Text';
import pciLogo from 'assets/pci-dss-compliant-logo-vector.svg';
import Space from 'components/Space';
import Result from 'components/uielements/Result/Result';

const { Content, Footer } = Layout;

const viewVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function Compliance() {
  const location = useLocation();
  const [formId, setFormId] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({});
  const [noParams, setNoParams] = useState(false);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const fetchAndSetForm = async (id) => {
    setIsFetching(true);
    try {
      const userIp = await fetchUserIpAddress();
      const userAgent = navigator.userAgent;

      // fetch the file metadata
      const formMetaData = await getFinixComplianceForm(id);
      //   console.log('ƒ fetchAndSetForm formMetaData', formMetaData);

      if (formMetaData.state === 'INCOMPLETE') {
        const fileLink = await createFinixComplianceFormLink(formMetaData.files.unsignedFile, {
          type: 'VIEW',
          duration: 60 * 24 * 7,
        });
        setFormData({
          ...formMetaData,
          fileLink,
          userIp,
          userAgent,
        });
      }

      if (formMetaData.state === 'COMPLETE') {
        setFormData({ ...formMetaData });
        setIsComplete(true);
      }

      setIsFetching(false);
    } catch (err) {
      console.log('ƒ fetchAndSetForm err', err);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (formId) {
      //   console.log('ƒ useEffect on formId', formId);
      fetchAndSetForm(formId);
    }
  }, [formId]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      // console.log('ƒ useEffect on location.search', params.get('form'));
      setFormId(params.get('form'));
    } else {
      setNoParams(true);
    }
  }, [location]);

  const FormDetails = () => {
    return (
      <StyledMetadata className="pci-form-metadata">
        <Skeleton active loading={isFetching} title={false} paragraph={{ rows: 3 }}>
          <ul>
            <li>
              <Text color="black">Name: {formData.type}</Text>
            </li>
            <li>
              <Text color="black">Status: {formData.state}</Text>
            </li>
            <li>
              <Text color="black">Due Date: {dayjs(formData.dueAt).format('MM-DD-YYYY')}</Text>
            </li>
          </ul>
        </Skeleton>
      </StyledMetadata>
    );
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Content style={{ margin: '24px auto' }}>
        {noParams ? (
          <Result status="404" title="404" subTitle="Sorry, the page you visited cannot be accessed." />
        ) : (
          <StyledCard
            title={<FormDetails />}
            extra={[
              <Button
                key="view-document"
                disabled={!formData?.fileLink?.url}
                onClick={() => openInNewTab(formData?.fileLink?.url)}
                data-testid="viewDocumentButtonTest"
              >
                View Document
              </Button>,
            ]}
            data-testid="complianceFormCardTest"
          >
            <div>
              <div style={{ width: '20vw', minWidth: '180px', margin: '0 auto' }}>
                <img src={pciLogo} alt="pci logo" />
              </div>
              <Text color="black">
                The{' '}
                <a href="https://listings.pcisecuritystandards.org/pci_security/completing_self_assessment">
                  PCI DSS self-assessment questionnaire (SAQ)
                </a>{' '}
                is validation tools intended to assist merchants and service providers in reporting the results of
                their PCI DSS self-assessment. Our merchant bank requires you to complete this questionnaire
                annually{' '}
                <Text color="black" underline>
                  for each of your PayRent merchant accounts
                </Text>
                . We have removed any of the work required to complete this questionnaire by providing a pre-filled
                form for you to attest to. To download and save a copy of the pre-filled form click on the button
                above.{' '}
              </Text>
              <Text strong color="black">
                There is nothing for you to fill out or return.
              </Text>
              <Space vertical={18} />
              <Text color="black">Please fill-in the information below and click "Attest".</Text>
            </div>
            <Space vertical={18} />
            {isComplete ? (
              <motion.div key="result" as={motion.div} variant={viewVariants} initial="hidden" animate="visible">
                <Result
                  status="success"
                  title="Thank You! Your PCI Questionnaire has been submitted."
                  subTitle="You can download and save a copy of the document or close this tab."
                />
              </motion.div>
            ) : (
              <ComplianceForm
                as={motion.div}
                key="form"
                variants={viewVariants}
                initial="hidden"
                animate="visible"
                formId={formId}
                formMetaData={formData}
                isComplete={isComplete}
                isFetching={isFetching}
                setIsComplete={setIsComplete}
              />
            )}
          </StyledCard>
        )}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        &copy; {dayjs().format('YYYY')} PayRent Inc. All rights reserved.
      </Footer>
    </Layout>
  );
}

const StyledCard = styled(CardWithTitle)`
  width: 960px !important;
  @media (max-width: 991px) {
    width: 97vw !important;
  }
`;

const StyledMetadata = styled.div`
  max-width: 320px;
  margin: 12px 0;
  ul {
    list-style-type: none;
  }
`;

export default Compliance;
