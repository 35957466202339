import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { getPropertyGroupOnboardingStatus, getPropertyGroups } from 'services/api/landlordApi/read';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PortfolioSettingsForm from './PortfolioSettingsForm';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';
import PropertyForm from './PropertyForm';
import UnitForm from './UnitForm';
import CreateMerchantStepper from 'screens/Landlord/FinixOnboarding/CreateMerchantWizard';
import InvitationForm from './InvitationForm';
import FinishedSetup from 'screens/Landlord/components/OnboardingFullScreen/components/FinishedSetup/FinishedSetup';

function OnboardingForms(props) {
  const { currentStep, stepTitle } = props;
  const session = useSelector((store) => store.session);
  const { data: propertyGroup, ...pgQuery } = useQuery({
    queryKey: ['defaultPropertyGroup'],
    queryFn: () =>
      getPropertyGroups(session.cognitoTokens.idToken.jwtToken, 1, 10000).then((res) =>
        res.items.find((pg) => pg.default === 1)
      ),
    staleTime: 100,
  });

  const { data: groupOnboardingStatus } = useQuery({
    queryKey: ['defaultGroupOnboardingStatus'],
    queryFn: () => getPropertyGroupOnboardingStatus(session.cognitoTokens.idToken.jwtToken, +propertyGroup.id),
    enabled: !!propertyGroup?.id,
    staleTime: 2500,
    refetchInterval: 3000,
  });

  return (
    <StyledFormsWrapper
      as={motion.div}
      key={currentStep}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      data-testid="onboardingFormsTest"
    >
      <Text strong color="black">
        {stepTitle[currentStep]}
      </Text>
      <Space vertical={12} />
      {currentStep === 0 && <PortfolioSettingsForm propertyGroup={propertyGroup} {...props} {...pgQuery} />}
      {currentStep === 1 && <PropertyForm propertyGroup={propertyGroup} {...props} {...pgQuery} />}
      {currentStep === 2 && <UnitForm propertyGroup={propertyGroup} {...props} {...pgQuery} />}
      {currentStep === 3 && <CreateMerchantStepper propertyGroup={propertyGroup} {...pgQuery} {...props} />}
      {currentStep === 4 && <InvitationForm propertyGroup={propertyGroup} {...props} {...pgQuery} />}
      {currentStep === 5 && (
        <FinishedSetup groupOnboardingStatus={groupOnboardingStatus} {...props} {...pgQuery} />
      )}
    </StyledFormsWrapper>
  );
}

const StyledFormsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px;
  @media screen and (max-width: 768px) {
    padding: 0;
    font-size: 10px;
  }
`;

export default OnboardingForms;
