// @flow
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Button, Form, Input, Select, Col, Row, Radio, Tooltip } from 'antd';
import LoginOutlined from '@ant-design/icons/LoginOutlined';
import moment from 'moment';

import jwt from 'jsonwebtoken';
import styled from 'styled-components';
import Text from 'components/Text';

//import './style.scss';
import { StyledSignUpButton } from '../TenantSignup/TenantSignup.styles';
import LocalDateInput from 'components/LocalDateInput/LocalDateInput';
import PhoneInput from 'components/PhoneInput/PhoneInput';

const { Option } = Select;

const LandlordSignupForm = (props) => {
  const { token } = useParams();
  // console.log('[LandlordSignupForm.js]', props.history, token);
  const [isLandLord, setIsLandLord] = useState(token === 'landlord');
  const [userRole, setUserRole] = useState('renter');
  const [apid, setApid] = useState('');
  const inviteToken = jwt.decode(token);
  const inviteEmail = inviteToken ? inviteToken.email : '';
  const [form] = Form.useForm();

  useEffect(
    () =>
      function cleanup() {
        props.setAuthError({ error: false, message: '' });
      },
    []
  );

  useEffect(() => {
    if (token === 'landlord') {
      setIsLandLord(true);
      const params = new URLSearchParams(props.history.location.search);
      if (params.get('plan')) {
        const plan = params.get('plan');
        const validate = props.servicePlans.filter((sp) => sp.id === plan);
        if (validate.length) {
          form.setFieldsValue({
            servicePlan: plan,
          });
        }
      }
    }
  }, [props.servicePlans]);

  useEffect(() => {
    if (props.history.location.pathname === '/sign-up/landlord') {
      setUserRole('landlord');
      const params = new URLSearchParams(props.history.location.search);
      // console.log('[LandlordSignupForm.js] ƒ useEffect', params.get('apid'));
      if (!params.get('apid') && localStorage.getItem('apid')) {
        setApid(localStorage.getItem('apid'));
      }
      if (params.get('apid')) {
        const id = params.get('apid') || localStorage.getItem('apid');
        localStorage.setItem('apid', params.get('apid'));
        setApid(id);
      }
    }
  }, [props.history.location]);

  const handleClose = () => {
    props.setAuthError({ ...props.authError, error: false });
  };

  // Close error after 5 seconds - restore state
  if (props.authError.error) {
    setTimeout(handleClose, 4000);
  }

  const onFinish = (values) => {
    // console.log(values);
    if (isLandLord) {
      window.dataLayer.push({
        event: 'landlord_signup',
        eventProps: {
          category: 'landlord_signup',
          action: 'signup',
          label: 'step-1',
        },
      });
    }

    const validDate = moment(values.birthdate).isValid();
    if (validDate) {
      const normalizePhone = `1${values.phoneNumber.replace(/\s/g, '')}`;
      const years = moment().diff(values.birthdate, 'years');

      if (years < 18) return props.setAuthError({ error: true, message: 'Birthdate must be over age 18' });

      const data = {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: normalizePhone,
        businessName: values.businessName,
        servicePlan: values.servicePlan,
        apid: apid || localStorage.getItem('apid') || '',
        birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
        isLandLord,
      };
      // console.log('[LandlordSignupForm.js] ƒ onFinish', data);
      props.signUpHandler(data);
    } else {
      return props.setAuthError({ error: true, message: 'Birthdate invalid date' });
    }
  };

  return (
    <div>
      <Form
        data-testid="landlordSignupFormTest"
        onFinish={onFinish}
        className="auth-form"
        size="large"
        form={form}
        initialValues={{ email: inviteEmail }}
      >
        <Form.Item
          name="servicePlan"
          rules={[
            {
              required: true,
              message: 'Please select your service plan',
            },
          ]}
        >
          <StyledSelect placeholder="Select Subscription" data-testid="servicePlanSelectTest">
            {props.servicePlans.map((sp, i) => (
              <Option value={sp.id} key={i}>
                {sp.name} (${sp.subscriptionFee} per mo.)
              </Option>
            ))}
          </StyledSelect>
        </Form.Item>

        <Form.Item
          name="businessName"
          whitespace="true"
          rules={[
            {
              max: 256,
              message: 'Bussiness name cannot be longer than 256 characters',
            },
          ]}
        >
          <Input placeholder="Business Name (optional)" data-testid="businessNameTest" />
        </Form.Item>

        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your first name',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="First Name" data-testid="firstNameTest" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your last name',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Last Name" data-testid="lastNameTest" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="birthdate"
          whitespace="true"
          rules={[
            {
              required: true,
              message: 'Please enter your birthdate',
            },
          ]}
        >
          <LocalDateInput placeholder="Birthdate (mm/dd/yyyy)" data-testid="birthdateTest" />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: 'Please enter your phone number',
            },
            {
              // pattern: new RegExp('^[0-9]{6,}$'),
              // supports ten characters without + sign
              // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
              pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
              message: 'Please enter only numbers - min length 10',
            },
            {
              message: 'Please input a valid phone number.',
              max: 16,
            },
          ]}
        >
          <PhoneInput placeholder="111 111 1111" data-testid="phoneTest" prefix="+1" minLength={6} />
        </Form.Item>

        <Form.Item
          name="email"
          whitespace="true"
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email',
            },
            {
              required: true,
              message: 'Please enter your email',
            },
          ]}
        >
          <Input placeholder="Email (username)" data-testid="emailTest" />
        </Form.Item>

        <Form.Item
          name="password"
          whitespace="true"
          rules={[
            { required: true, message: 'Min 8 characters', max: 256 },
            { message: 'Max 256 characters', max: 256 },
            {
              pattern: new RegExp('^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@~#$%^&+*!=]).*$'),
              message: 'Min 8 characters, at least 1 capital, 1 lowercase, 1 number and 1 special character',
            },
          ]}
        >
          <Input.Password placeholder="Password" data-testid="passwordTest" />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            id="signup-btn"
            htmlType="submit"
            icon={<LoginOutlined />}
            loading={props.isLoading}
            data-testid="signupButtonTest"
          >
            Sign Up
          </Button>
        </Form.Item>

        <Text centered={true} size={12} color={'black'} data-testid="termsConditions">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          By signing up to create an account I accept PayRent's{' '}
          <a target="_blank" href="https://www.payrent.com/terms-conditions/" rel="noopener noreferrer">
            Terms of Use
          </a>{' '}
          and{' '}
          <a target="_blank" href="https://www.payrent.com/privacy-policy" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </Text>
      </Form>
    </div>
  );
};

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 35px !important;
    @media screen and (max-width: 768px) {
      height: 35px !important;
      font-size: 16px !important;
    }
  }
`;

export default LandlordSignupForm;
