import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import moment from 'moment';
import * as Iot from 'services/aws/iot/aws-iot';

import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

import AppLayout from '../../layouts/AppLayout';

import Dashboard from './Dashboard';
import MakePayment from './Payment/MakePayment/MakePayment';
import PaymentSchedule from './Payment/PaymentSchedule/PaymentSchedule';
import Transactions from './Transactions/Transactions';
import Wallet from './Wallet';
import Settings from './Settings/Settings';
import RentCred from './RentCred';
import { getRentCredData } from 'store/actions/tenantActions';
import useIntercom from 'hooks/useIntercom';

const Router = (props) => {
  useIntercom();
  const { match } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const rentCred = useSelector((store) => store.tenant.rentCred);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  let mountedRef = useRef(false);
  let sessionId = useRef();

  useEffect(() => {
    const controller = new AbortController();
    Iot.initNewClient({
      accessKeyId: session.cognitoTokens.identityCredentials.AccessKeyId,
      secretAccessKey: session.cognitoTokens.identityCredentials.SecretKey,
      sessionToken: session.cognitoTokens.identityCredentials.SessionToken,
    });
    Iot.attachConnectHandler(() => {});

    Iot.attachMessageHandler((topic, message) => {
      const string = new TextDecoder().decode(message);
      const ms = JSON.parse(string);
      if (ms.event === 'RCP_UPDATE') {
        dispatch(getServicePlanAction(cognitoToken));
        dispatch(getUserDataV3(cognitoToken, controller));
      }
      if (ms.event === 'BILLING_ACCOUNT_UPDATE') {
        dispatch(getUserDataV3(cognitoToken, controller));
      }
    });
    dispatch(getRentCredData(cognitoToken, session.userData.id));

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (session.userData.id !== sessionId.current) {
      const topic = `${process.env.REACT_APP_ENV}/${session.userData.id}`;
      Iot.subscribe(topic);
      // Iot.publish(topic, JSON.stringify({ event: 'React app online' }));
      sessionId.current = session.userData.id;
    }

    if (!mountedRef.current && session.userData && 'label' in rentCred) {
      // window.userGuiding.identify(session.userData.id, {
      //   email: session.userData.email,
      //   name: `${session.userData.firstName} ${session.userData.lastName}`,
      //   nickname: session.userData.firstName,
      //   customer_type: session.userRole,
      //   subscription: session.userData.servicePlan.name.split(' - ')[0],
      //   processor: session.userData.servicePlan.processor.name,
      //   rent_cred_status: rentCred.label,
      //   created_at: moment(session.userData.createdAt).format('MM/DD/YYYY'),
      // });

      // eslint-disable-next-line consistent-return
      return () => {
        mountedRef.current = true;
      };
    }
  }, [session, rentCred]);

  return (
    <AppLayout>
      {/* Dashboard */}
      <Route exact path={`${match.url}`} component={Dashboard} />
      <Route exact path={`${match.url}/quadpay/confirm`} component={Dashboard} />
      <Route exact path={`${match.url}/quadpay/cancel`} component={Dashboard} />
      <Route path={`${match.url}/settings`} component={Settings} />

      {/* Payments */}
      <Route exact path={`${match.url}/wallet`} component={Wallet} />
      <Route exact path={`${match.url}/rentcred`} component={RentCred} />
      <Route exact path={`${match.url}/payment`} component={MakePayment} />

      {/* <Route path={`${match.url}/payment/status`} component={PaymentStatus} /> */}
      <Route exact path={`${match.url}/payment/schedule`} component={PaymentSchedule} />
      {/* <Route path={`${match.url}/payment/schedule/:id`} component={PaymentSchedule} /> */}
      <Route path={`${match.url}/transactions`} component={Transactions} />
    </AppLayout>
  );
};

export default Router;
