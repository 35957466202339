import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { getStripeLink, getPropertyGroupOnboardingStatus } from 'services/api/landlordApi/read';

import { displayAlert } from 'resources/helpers';

import { stripeOptIn } from 'services/api/landlordApi/create';

import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import StripeDetails from '../../../StripeDetails';
import { boardingElement, boardingText } from '../../animation/variants';

const StripeSetup = (props) => {
  const {
    defaultPropertyGroup,
    stripeTimer,
    setShowStripeSetup,
    open,
    setFinishedSetup,
    groupOnboardingStatus,
    setGroupOnboardingStatus,
    setSilaVerificationDocs,
  } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [stripeState, setStripeState] = useState(null);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const retries = useRef(0);

  useEffect(() => {
    if (retries.current === 0) {
      setLoading(true);
      setTimeout(() => {
        fetchData();
      }, 1500);
    }
  }, []);

  useEffect(() => {
    fetchOnboardingStatus();
  }, []);

  const fetchOnboardingStatus = async () => {
    try {
      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, +defaultPropertyGroup[0].id);
      setGroupOnboardingStatus(groupStatus);
      return groupStatus;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (stripeTimer.current) clearInterval(stripeTimer.current);
      stripeTimer.current = setInterval(async () => {
        try {
          if (retries.current >= 5) {
            clearInterval(stripeTimer.current);
            setLoading(false);
            setDisabled(true);
            displayAlert(setAlert, 'Not available. Try again later.', 'warning', 5000);
            setTimeout(() => {
              if (
                groupOnboardingStatus?.ach?.state === 'DOCUMENTS_REQUIRED' ||
                groupOnboardingStatus?.ach?.state === 'REVIEW'
              ) {
                setShowStripeSetup(false);
                setSilaVerificationDocs(true);
              } else {
                setShowStripeSetup(false);
                setFinishedSetup(true);
              }
            }, 3000);
            return;
          }

          const stripeLink = await getStripeLink(cognitoToken, +defaultPropertyGroup[0].id);
          if (stripeLink?.url) {
            clearInterval(stripeTimer.current);
            setStripeState(stripeLink);
          }
          setLoading(false);
        } catch (error) {
          const r = retries.current + 1;
          retries.current = r;
        }
      }, 2000);
    } catch (error) {
      console.log(error);
      clearInterval(stripeTimer.current);
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    if (stripeState) {
      if (stripeState.url) {
        setLoading(true);
        window.location.href = stripeState.url;
      }
    }
  };

  const handleOptIn = async () => {
    try {
      setLoading(true);
      await stripeOptIn(cognitoToken, +defaultPropertyGroup[0].id, false);
      const groupStatus = await fetchOnboardingStatus();
      setLoading(false);
      if (groupStatus?.ach?.state === 'DOCUMENTS_REQUIRED' || groupOnboardingStatus?.ach?.state === 'REVIEW') {
        setShowStripeSetup(false);
        setSilaVerificationDocs(true);
      } else {
        setShowStripeSetup(false);
        setFinishedSetup(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong>
          Accept Credit Cards
        </Text>
      </motion.div>
      <Space vertical={15} />
      <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave">
        <StyledDiv>
          <StripeDetails />
          <StyledFooter>
            <AlertFade
              isVisible={alert.isVisible}
              message={alert.message}
              type={alert.type}
              minHeight="80"
              alertPosition="absolute"
              bottom={150}
            />
            <Button
              color="violet"
              bottom={0}
              htmlType="submit"
              loading={loading}
              disabled={disabled}
              onClick={handleRedirect}
            >
              CONTINUE
            </Button>
            <Text color="violet" size="12" underline pointer onClick={() => handleOptIn()}>
              No Thanks
            </Text>
          </StyledFooter>
        </StyledDiv>
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: #ffffff;
  min-height: 70%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 20px 20px 20px;
  display: flex;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
  @media screen and (max-width: 400px) {
    padding: 22px 10px 15px 10px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 80%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;import { AnimatePresence } from 'framer-motion';

  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDiv = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const StyledFooter = styled.div`
  min-height: 110px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
  gap: 20px;
`;
export default StripeSetup;
