import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Text from 'components/Text/Text';
import { Skeleton, Space } from 'antd';
import { motion } from 'framer-motion';
import Tag from 'components/Tag/Tag';
import { formVariants } from 'animations/variants';

function DocumentHistory(props) {
  const { record, esignData } = props;
  const qc = useQueryClient();

  const renderStatusTag = (status) => {
    switch (status) {
      case 'InProgress':
        return <Tag color="blue">In Progress</Tag>;
      case 'Completed':
        return <Tag color="green">Completed</Tag>;
      case 'Declined':
      case 'Expired':
      case 'Revoked':
        return <Tag color="red">{status}</Tag>;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        marginTop: '18px',
        marginBottom: '18px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Space align="middle" style={{ justifyContent: 'space-between' }}>
        <Text as="div" color="black" strong>
          E-sign History:
        </Text>
        <motion.div key="esign-status" variants={formVariants} initial="hide" animate="show">
          {!!record?.esignId && !qc.isFetching(['esignData']) && renderStatusTag(esignData?.status)}
        </motion.div>
      </Space>
      <Skeleton loading={!!qc.isFetching(['esignData'])} paragraph={{ rows: 3 }} title={false} active>
        <motion.div key="esign-history" variants={formVariants} initial="hide" animate="show">
          {!!esignData?.documentHistory.length ? (
            esignData?.documentHistory.map((e, i) => (
              <div key={i} style={{ marginBottom: '6px' }}>
                <Text color="greyText" size={14}>
                  {e.action} by {e.name} <br />
                  on {dayjs.unix(e.timestamp).format('MM-DD-YYYY h:mmA')}
                </Text>
              </div>
            ))
          ) : (
            <Text as="div" style={{ textAlign: 'center' }} color="greyText">
              No history found
            </Text>
          )}
        </motion.div>
      </Skeleton>
    </div>
  );
}

export default DocumentHistory;
