import React from 'react';
import { Input } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import styled, { css } from 'styled-components';

const InputSearch = (props) => {
  const { placeholder, mobilewidth, handleSearch, onkeydown, onkeyup } = props;
  return (
    <Container>
      <StyledInput
        placeholder={placeholder}
        mobilewidth={mobilewidth}
        onChange={handleSearch}
        onKeyDown={onkeydown}
        onKeyUp={onkeyup}
        prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
        data-testid="searchInputTest"
      />
    </Container>
  );
};

const Container = styled.div`
  width: 24.4%;
  input::placeholder {
    color: #bfbfbf;
    font-size: 14px;
  }
  @media screen and (max-width: 1680px) {
    width: 24.5%;
  }
  @media screen and (max-width: 1199px) {
    width: 49.2%;
  }
  @media screen and (max-width: 991px) {
    width: 49.5%;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;
const StyledInput = styled(Input)`
  width: 100%;
  height: 36px;
  ${(props) => {
    if (props.mobilewidth) {
      return css`
        @media screen and (max-width: 575px) {
          width: ${props.mobilewidth}%;
        }
      `;
    }
  }};
`;

export default InputSearch;
