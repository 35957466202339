// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';

// Icons
import { LogoutOutlined } from '@ant-design/icons';
import { ReactComponent as HomeIcon } from 'assets/drawer-icons/home.svg';
import { ReactComponent as TransactionsIcon } from 'assets/drawer-icons/coin.svg';
import { ReactComponent as PaymentMethodsIcon } from 'assets/drawer-icons/wallet.svg';
import { ReactComponent as SettingsIcon } from 'assets/drawer-icons/settings.svg';
import { ReactComponent as LifeBuoyIcon } from 'assets/drawer-icons/lifebuoy.svg';
import { ReactComponent as RentCredIcon } from 'assets/images/rentcred/rencred_icon_inv.svg';

import { openFreshChatWidget } from 'utils';

const topMenuStyle = {
  width: 'auto',
  padding: 0,
  backgroundColor: '#122C34',
};

const bottomMenuStyle = {
  marginBottom: '48px',
};

const TenantMenu = (props) => {
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const tenant = useSelector((store) => store.tenant);
  const currentSelectedMenu = useSelector((store) => store.global.menu.currentSelectedMenu);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/tenant':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'tenant' });
        break;
      case '/tenant/wallet':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'wallet' });
        break;
      case '/tenant/transactions':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'transactions' });
        break;
      case '/tenant/settings':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'settings' });
        break;
      case '/tenant/rentcred':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'rentCred' });
        break;
      default:
        break;
    }
  }, [location]);

  const handleClick = (e) => {
    let url;

    switch (e.key) {
      case 'tenant':
        url = '/tenant';
        break;
      case 'wallet':
        url = '/tenant/wallet';
        break;
      case 'transactions':
        url = '/tenant/transactions';
        break;
      case 'settings':
        url = '/tenant/settings';
        break;
      case 'rentCred':
        url = '/tenant/rentcred';
        break;
      case 'help':
        break;
      case 'logout':
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'logout' });
        url = '/sign-out';
        break;
      default:
        dispatch({ type: 'SET_SELECTED_MENU', payload: 'tenant' });
        url = '/tenant';
        break;
    }

    history.push(url);
    props.closeDrawer();
  };
  const items = [
    {
      key: 'tenant',
      icon: <Icon component={HomeIcon} />,
      label: <span data-testid="homeTest">Home</span>,
    },
    {
      key: 'transactions',
      icon: <Icon component={TransactionsIcon} />,
      label: <span data-testid="transactionsTest">Transactions</span>,
    },
    {
      key: 'wallet',
      icon: <Icon component={PaymentMethodsIcon} />,
      label: <span data-testid="walletTest">Wallet</span>,
    },
    {
      key: 'rentCred',
      icon: <Icon component={RentCredIcon} />,
      label: <span data-testid="rentCredTest">RentCred</span>,
    },
  ];

  const bottomMenuItems = [
    {
      key: 'settings',
      icon: <Icon component={SettingsIcon} />,
      label: <span data-testid="accountTest">Account Settings</span>,
    },
    {
      key: 'help',
      icon: <Icon component={LifeBuoyIcon} />,
      label: <span data-testid="customerTest">Customer Support</span>,
      id: 'support_menu_item',
      'data-testid': 'intercomTest',
      // // onClick: () =>
      // //   openFreshChatWidget(
      // //     session.userData.id,
      // //     session.userData.firstName,
      // //     session.userData.email,
      // //     session.userData.cognito['custom:landlord'],
      // //     session.userData.servicePlan.name
      // //   ),
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: <span data-testid="logoutTest">Logout</span>,
    },
  ];

  return (
    <>
      <Menu
        onClick={handleClick}
        theme="dark"
        style={topMenuStyle}
        selectedKeys={[currentSelectedMenu]}
        mode="inline"
        items={items}
      />
      <Menu
        onClick={handleClick}
        theme="dark"
        style={bottomMenuStyle}
        mode="inline"
        selectedKeys={[currentSelectedMenu]}
        items={bottomMenuItems}
      />
    </>
  );
};

export default TenantMenu;
