import React from 'react';

export const dashboardSteps = [
  {
    title: 'Account Dashboard',
    content: (
      <h3>
        Here is a high level overview of Payments, Properties and People. You can explore quick actions by clicking
        the three vertical dots.
      </h3>
    ),
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    // placement: 'center',
    target: '.summaryCardsTour',
  },
  {
    title: 'Recent Activity',
    content: <h3>This is a feed of recent account activity. You can search, filter, pin, flag, archive items.</h3>,
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'top',
    target: '.activityFeedTour',
  },
  {
    title: 'Account settings',
    content: <h3>View or change your profile and preferences.</h3>,
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'right',
    target: '.profileMenuTour',
  },
  {
    title: 'Customer Support',
    content: <h3>Get live in-app chat support or view FAQs.</h3>,
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'top',
    target: '.customerSupportTour',
  },
];

export const propertiesSteps = [
  {
    title: 'Properties List',
    content: <h3>This is a list of all your properties. Click on the expansion arrows to view renter details.</h3>,
    disableBeacon: true,
    disableOverlay: true,
    disableScrolling: true,
    placement: 'top',
    isFixed: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'top',
    target: '.propertiesTour',
  },
  {
    title: 'Add a Property',
    content: <h3>Click here to add a new property.</h3>,
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'top',
    target: '.addPropertyTour',
  },
  {
    title: 'Invite a Renter',
    content: <h3>Click here to invite a renter.</h3>,
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'top',
    target: '.inviteRenterTour',
  },
];

export const transactionsSteps = [
  {
    title: 'Transaction List',
    content: (
      <h3>
        This is a list of all your transactions. Use the search box or filter icons to quickly locate a set of
        transactions.
      </h3>
    ),
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'top',
    target: '.transactionsTour',
  },
  {
    title: 'Download Transactions',
    content: <h3>Click here to download a filtered list of your transactions.</h3>,
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'right',
    target: '.downloadButtonTour',
  },
];

export const rentersSteps = [
  {
    title: 'Renters List',
    content: (
      <h3>
        View your verified and invited renters. Send past due notices and view transaction history from the three
        vertical dots.
      </h3>
    ),
    disableBeacon: true,
    disableOverlay: true,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'top',
    target: '.rentersTour',
  },
];
