// @flow
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LOGIN } from 'store/actions';
import Browsee from '@browsee/web-sdk';
// import LogRocket from 'logrocket';
import jwt from 'jsonwebtoken';
import axios from 'axios';

import { getSessionDataV3, ipPhoneEmailValidation } from 'services/api/common/read';
import { attachPolicy } from 'services/api/common/create';

import { authenticate, registerCognitoUser } from 'services/aws/cognito';
import setAuthToken from 'utils/setAuthToken';

import { getServicePlanName } from 'resources/helpers';
// Styles
//import './style.scss';
import { getServicePlans } from 'services/api/landlordApi/read';

// Components
import SignInForm from './components/SignInForm/SignInForm';
import SalesCard from './components/SalesCard/SalesCard';
import LandlordSignup from './components/LandlordSignup/LandlordSignup';
import TenantSignup from './components/TenantSignup/TenantSignup';

const Signing = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const redirectPathName = useSelector((store) => store.global.redirectPathName);
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState({ error: false, message: '' });
  const [servicePlans, setServicePlans] = useState([]);
  const [ipQualityScoreCheck, setIpQualityScoreCheck] = useState(true);
  const isIdentified = useRef(false);

  useEffect(() => {
    // console.log('Signing.js: useEffect()');

    if (history.location.pathname === '/' || history.location.pathname === '/sign-in') {
      // console.log('Signing.js: useEffect() - dispatch(LOGIN)');
      dispatch({
        type: LOGIN,
      });
    }

    const params = new URLSearchParams(history.location.search);

    if (params.get('ipqualityscore')) {
      // console.log('Signing.js: useEffect() - ipqualityscore');
      const value = params.get('ipqualityscore');
      setIpQualityScoreCheck(value === 'true');
    }

    if (history.location.state?.from) {
      // console.log('Signing.js: useEffect() - SET_REDIRECT_PATHNAME');
      dispatch({
        type: 'SET_REDIRECT_PATHNAME',
        payload: history.location.state.from.pathname + history.location.state.from.search,
      });
    }

    fetchData();

    return () => {
      // console.log('Signing.js: useEffect() - cleanup');
    };
  }, []);

  const fetchData = async () => {
    // console.log('Signing.js: fetchData()');
    try {
      const servicePlansResult = await getServicePlans();
      // console.log('Signing.js: fetchData() - servicePlansResult', servicePlansResult);
      servicePlansResult.unshift(servicePlansResult.pop());
      const filtered = servicePlansResult.filter((sp) => sp.access === 'PUBLIC');

      const sortedSp = filtered.sort((a, b) => a.subscriptionFee - b.subscriptionFee);
      setServicePlans(sortedSp);
    } catch (error) {
      setAuthError({ error: true, message: 'There was a problem fetching service plans.' });
    }
  };

  const cleanRedirect = () => {
    dispatch({
      type: 'SET_REDIRECT_PATHNAME',
      payload: null,
    });
  };

  const signInHandler = async (data) => {
    setIsLoading(true);

    const { username, password } = data;
    let responseStatus = { hasError: false, data: {} };
    const authResult = await authenticate(username, password).catch((err) => {
      // Store this for later use
      responseStatus = { hasError: true, data: err };
    });

    if (responseStatus.hasError) {
      setIsLoading(false);

      // Not confirmed user error
      if (responseStatus.data.code === 'UserNotConfirmedException') {
        const location = {
          pathname: '/auth/confirm-sign-up',
          state: {
            userData: {
              email: username,
              password,
            },
            alertData: {
              message: responseStatus.data.message,
              type: 'info',
            },
          },
        };
        props.history.push(location);
        return;
      }
      if (responseStatus.data.code === 'PasswordResetRequiredException') {
        setAuthError({ error: true, message: 'Please reconfirm your email address and reset password' });
        setTimeout(() => {
          const location = {
            pathname: '/auth/forgot-password',
          };
          props.history.push(location);
        }, 4000);
        return;
      }

      // Generic error
      setAuthError({ error: true, message: `${responseStatus.data.message} Please contact customer support.` });
      return;
    }

    const userData = jwt.decode(authResult.idToken.jwtToken);

    const AuthStr = `Bearer ${authResult.idToken.jwtToken}`;
    try {
      await attachPolicy(authResult.identityCredentials.identityId, authResult.idToken.jwtToken);
    } catch (error) {
      console.log(error);
    }

    let Url = '';

    Url = `${process.env.REACT_APP_API_URL_V3}/auth/legacy?id=${userData['custom:customerId']}`;

    // Get a valid token from the old system
    axios
      .get(Url, { headers: { Authorization: AuthStr } })
      .then(async (response) => {
        sessionStorage.setItem('payrent.session', response.data.data.attributes.token.token);

        if (userData['custom:apid']) localStorage.setItem('apid', userData['custom:apid']);

        setAuthToken(`Bearer ${response.data.data.attributes.token.token}`);

        const sessionUserData = await getSessionDataV3(authResult.idToken.jwtToken);

        const role = userData['custom:landlord'] === 'true' ? 'landlord' : 'tenant';

        if (process.env.REACT_APP_ENV === 'production') {
          if (!isIdentified.current) {
            console.log('Identifying visitor...');
            const customerData = {
              name: `${userData.given_name} ${userData.family_name}`,
              email: userData.email,
              subscriptionType: await getServicePlanName(userData['custom:servicePlan']),
              role,
            };

            // LogRocket.identify(userData['custom:customerId'], customerData);
            Browsee.identify(userData['custom:customerId'], customerData);
            isIdentified.current = true;
          }
        }

        dispatch({
          type: 'SET_USER_SESSION_DATA',
          userData: {
            cognito: userData,
            ...sessionUserData,
          },
          role,
          cognitoTokens: authResult,
          oldTokens: {
            token: response.data.data.attributes.token.token,
            expires: response.data.data.attributes.token.expires,
          },
        });
        dispatch({ type: 'SET_SELECTED_MENU', payload: role });
        // removed from UX flow 01/2024
        // if (!redirectPathName && !sessionUserData?.tenantMeta?.renterProfile?.gender && role === 'tenant') {
        //   dispatch({
        //     type: 'SHOW_RENTCRED_PROFILE',
        //     payload: true,
        //   });
        //   history.push('/tenant/rentcred?profile=true');
        // } else {
        history.push(redirectPathName || `/${role}`);
        if (redirectPathName) cleanRedirect();
        // }
      })
      .catch((error) => {
        console.log(`error ${error}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const signUpHandler = async (userData) => {
    try {
      setIsLoading(true);
      userData.ipQualityScore = '';

      const ipPhoneEmailRes = await ipPhoneEmailValidation(userData.email);

      if (ipPhoneEmailRes.email.disposable) {
        throw Error('This is not a valid email');
      }
      if (ipQualityScoreCheck) {
        if (userData?.isLandLord && ipPhoneEmailRes.email.fraudScore >= 85) {
          throw Error('This is not a valid email');
        }
      }

      userData.ipQualityScore = ipPhoneEmailRes.email.requestId;

      const callback = () => {
        setIsLoading(false);
        const formatedPhone = `+${userData.phoneNumber.substring(0, 4)}-${userData.phoneNumber.substring(
          4,
          7
        )}-${userData.phoneNumber.substring(7, 11)}`;
        const location = {
          pathname: '/auth/confirm-sign-up',
          state: {
            userData: {
              firstName: userData.firstName,
              lastName: userData.lastName,
              businessName: userData?.businessName || '',
              servicePlan: userData.servicePlan,
              apid: userData?.apid || '',
              ipQualityScore: userData?.ipQualityScore || '',
              phone: formatedPhone,
              email: userData.email,
              password: userData.password,
              role: props.match.params.token === 'landlord' ? 'landlord' : 'tenant',
              isLandLord: userData.isLandLord,
              birthdate: userData.birthdate,
            },
          },
        };
        history.push(location);
      };

      const errorCallback = (errorMessage) => {
        setIsLoading(false);
        let message = errorMessage;
        if (message.includes('PreSignUp failed with error Renter has no invitation'))
          message = 'Renter has no invitation. Please request an invite from your landlord.';
        setAuthError({ error: true, message });
      };

      registerCognitoUser(userData, callback, errorCallback);

      // Redirect to confirm Sign Up
    } catch (error) {
      setIsLoading(false);
      setAuthError({ error: true, message: error?.message || 'There was a problem with your request' });
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
        {props.match.url.startsWith('/sign-up') && <SalesCard />}
        <div className="tab-container" data-testid="signInTest">
          {props.match.url.startsWith('/sign-in') || props.match.url === '/' ? (
            <SignInForm
              signInHandler={signInHandler}
              isLoading={isLoading}
              authError={authError}
              setAuthError={setAuthError}
              history={props.history}
            />
          ) : props.match.url.startsWith('/sign-up/landlord') ? (
            <LandlordSignup
              signUpHandler={signUpHandler}
              isLoading={isLoading}
              authError={authError}
              setAuthError={setAuthError}
              urlParams={props.match.params}
              history={props.history}
              servicePlans={servicePlans}
            />
          ) : props.match.url.startsWith('/sign-up/tenant') ? (
            <TenantSignup
              signUpHandler={signUpHandler}
              isLoading={isLoading}
              authError={authError}
              setAuthError={setAuthError}
              urlParams={props.match.params}
              history={props.history}
              servicePlans={servicePlans}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Signing;
