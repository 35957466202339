import React, { useState, useEffect, useCallback } from 'react';
import { Title, Subtitle } from 'components/Panel/PanelTitle/PanelTitle';
import { Form, Select, Input, Space as AntSpace, Card, theme } from 'antd';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { formVariants } from 'animations/variants';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';
import { useDispatch, useSelector } from 'react-redux';
import { getRentersV3, getSenderIdentity } from 'services/api/landlordApi/read';
import { globalDisplayAlert } from 'resources/helpers';
import DocumentHistory from 'screens/Landlord/Documents/components/DocumentHistory';
import Button from 'components/Button/Button';
import { downloadEsignDocument, getEsignDocumentData } from 'services/api/common/read';
import * as landlordApi from 'services/api/landlordApi/create';
import { DeleteOutlined, MinusSquareOutlined, PlusSquareOutlined, SecurityScanFilled } from '@ant-design/icons';
import { revokeEsignDocument } from 'services/api/landlordApi/delete';
import { useQueryClient, useQueries } from '@tanstack/react-query';
import Result from 'components/uielements/Result/Result';
import Spin from 'components/uielements/Spin/Spin';

const { Item, useForm } = Form;

function SignPanel(props) {
  // console.log('[SignPanel.js]', props);
  const { token } = theme.useToken();
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const cognitoToken = session.cognitoTokens?.idToken.jwtToken;
  const { setSubmitting } = props;
  const [form] = useForm();
  const [tenantSelectOptions, setTenantSelectOptions] = useState([]);
  const [resendLoading, setResendLoading] = useState(false);
  const [esignVerificationStatus, setEsignVerificationStatus] = useState('unknown');
  const [recipientsRequired, setRecipientsRequired] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const [hasMaxSigners, setHasMaxSigners] = useState(false);
  const [revokeDocument, setRevokeDocument] = useState({
    text: 'Revoke Document',
    action: false,
    buttonType: 'default',
  });

  const [{ data: senderData, isLoading: senderLoading }, { isFetching: tenantLoading }, { data: esignData }] =
    useQueries({
      queries: [
        {
          queryKey: ['senderIdentity'],
          queryFn: () =>
            getSenderIdentity(cognitoToken).then((res) => {
              if (!!res.result.length) setEsignVerificationStatus(res.result[0].status.toLowerCase());
              return res;
            }),
          staleTime: 1000,
          refetchIntervalInBackground: true,
          refetchInterval: () => (esignVerificationStatus === 'pending' ? 3000 : false),
          refetchOnWindowFocus: false,
        },
        {
          queryKey: ['tenants'],
          queryFn: () =>
            getRentersV3(cognitoToken, 1, 10000, '').then((res) => {
              if (res.items.length) {
                setTenantSelectOptions(() =>
                  res.items.map((t) => ({
                    label: `${t.firstName} ${t.lastName}`,
                    value: `${t.firstName} ${t.lastName},${t.email}`,
                  }))
                );
              } else {
                setTenantSelectOptions([]);
              }
              return res;
            }),
          refetchOnWindowFocus: false,
        },
        {
          queryKey: ['esignData'],
          enabled: !!props.editDocument?.esignId,
          queryFn: () => getEsignDocumentData(cognitoToken, props.editDocument?.esignId),
          refetchOnWindowFocus: false,
        },
      ],
    });

  // console.log('senderData', senderLoading, senderData);
  // console.log('tenantData', tenantLoading, tenantData);
  // console.log('esignData', esignLoading, esignData);

  function documentDataMap(doc) {
    return {
      id: doc.id,
      landlordId: doc.landlordId,
      label: doc.label,
      fileName: doc.fileName,
      isPublic: doc.isPublic,
      isTemplate: doc.isTemplate,
      mimeType: doc.mimeType,
      tags: doc.tags,
      associations: doc.associations,
      events: doc.events,
    };
  }

  const updateEventList = useCallback(
    (doc, message) => {
      let eventList = doc.events;

      eventList.push({
        eventType: 'signed',
        entity: 'document',
        occurredAt: new Date().toISOString(),
        message: message || 'E-signature event occurred',
        metadata: {
          eventCreatedBy: session.userData.id,
        },
      });

      return eventList;
    },
    [session.userData.id]
  );

  const handleFinish = useCallback(
    async (values) => {
      // console.log('ƒ handleFinish values', values);
      try {
        setSubmitting(true);
        if (!props.editDocument) throw new Error('No document to send');

        const { id, fileName, tags, label, metadata } = props.editDocument;

        const newTags = {
          ...tags,
          system: tags.system?.length ? [...tags.system, 'E-sign Pending'] : ['E-sign Pending'],
        };

        const documentData = {
          ...documentDataMap(props.editDocument),
          tags: newTags,
          events: updateEventList(props.editDocument, 'Sent a document for e-signature'),
        };

        const senderName = session.userData.privacyFullNameForRenter;

        function signers(tenants, others) {
          console.log('tenants+others', tenants, others);
          let signerList = [];

          const combinedSenders = tenants
            .map((t) => ({ name: t.split(',')[0], emailAddress: t.split(',')[1] }))
            .concat(others.map((o) => ({ name: o.fullName, emailAddress: o.email })));

          combinedSenders.forEach((s, i) => {
            const formCoordinates = [
              { x: 160, y: 890 },
              { x: 412, y: 890 },
              { x: 160, y: 960 },
              { x: 412, y: 960 },
            ];

            signerList.push({
              name: s.name,
              emailAddress: s.emailAddress,
              signerOrder: i + 1,
              authenticationType: 'None',
              deliveryMode: 'Email',
              signerType: 'Signer',
              formFields: [
                {
                  type: 'Signature',
                  pageNumber: metadata?.pageCount || 1,
                  isRequired: true,
                  name: `Tenant Signature (${s.name})`,
                  bounds: {
                    ...formCoordinates[i],
                    width: 240,
                    height: 64,
                  },
                },
              ],
            });
          });
          console.log('signerList', signerList);
          return signerList;
        }

        const esignData = {
          id,
          title: label,
          fileName,
          message: `${senderName} is requesting your e-signature on this document`,
          onBehalfOf: session.userData.email,
          labels: [id],
          autoDetectFields: true,
          'reminderSettings.enableAutoReminder': true,
          signers: signers(values.tenant, values.otherRecipients || []),
        };

        console.log('esignData', esignData, documentData);
        await landlordApi.sendEsignDocument(cognitoToken, esignData).then(() => {
          props.handleMutation.mutateAsync(documentData);
          qc.resetQueries('esignData');
        });

        setSubmitting(false);

        setTimeout(() => {
          props.setDrawerVisible(false);
          globalDisplayAlert(dispatch, '', 'success', `Document sucessfully sent.`);
        }, 300);
      } catch (err) {
        setSubmitting(false);
        props.displayAlert('Failed to send document', 'error');
        console.error('ƒ handleFinish', err);
      }
    },
    [
      cognitoToken,
      dispatch,
      props,
      qc,
      session.userData.email,
      session.userData.privacyFullNameForRenter,
      setSubmitting,
      updateEventList,
    ]
  );

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      await downloadEsignDocument(cognitoToken, props.editDocument?.esignId, session.userData.email);
      qc.invalidateQueries(['esignData']);
    } catch (error) {
      console.error('ƒ handleDownload', error);
      props.displayAlert('Failed to download document', 'error');
    }
    setIsDownloading(false);
  };

  const handleRevoke = async () => {
    try {
      if (!revokeDocument.action) {
        setRevokeDocument({ text: 'Really Revoke Document?', action: true, buttonType: 'primary' });
        return;
      }
      setIsRevoking(true);

      const newTags = {
        ...props.editDocument.tags,
        system: props.editDocument.tags.system.filter((t) => t !== 'E-sign Pending').concat('E-sign Revoked'),
      };
      // console.log('newTags', newTags);

      const documentData = {
        ...documentDataMap(props.editDocument),
        tags: newTags,
        events: updateEventList(props.editDocument, 'Revoked a document for e-signature'),
      };

      const reason = { message: 'Revoked by sender', onBehalfOf: session.userData.email };
      await revokeEsignDocument(cognitoToken, props.editDocument?.esignId, reason);
      props.handleMutation.mutateAsync(documentData);
      setIsRevoking(false);
      setTimeout(() => {
        props.setDrawerVisible(false);
        setRevokeDocument({ text: 'Revoke Document', action: false, buttonType: 'default' });
        globalDisplayAlert(dispatch, '', 'success', `Document sucessfully revoked.`);
      }, 300);
    } catch (error) {
      console.error('ƒ handleRevoke', error);
      setIsRevoking(false);
      setRevokeDocument({ text: 'Revoke Document', action: false, buttonType: 'default' });
      props.displayAlert('Failed to revoke document', 'error');
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    // console.log('ƒ handleChangedValues', changedValues, allValues);
    changedValues.tenant?.length ? setRecipientsRequired(false) : setRecipientsRequired(true);
    allValues.tenant?.length + allValues.otherRecipients?.length >= 4
      ? setHasMaxSigners(true)
      : setHasMaxSigners(false);
  };

  const handleResendVerification = async () => {
    setResendLoading(true);
    try {
      await landlordApi.resendEsignVerification(cognitoToken);
    } catch (err) {
      console.error(err);
    }
    setResendLoading(false);
  };

  const createNewSenderIdentity = useCallback(
    async (controller) => {
      try {
        const senderData = {
          email: session.userData.email,
          name: `${session.userData.firstName} ${session.userData.lastName}`,
          notificationSettings: {
            viewed: true,
            sent: false,
            deliveryFailed: true,
            declined: true,
            revoked: true,
            completed: true,
            expired: true,
          },
        };

        await landlordApi.createSenderIdentity(cognitoToken, senderData, controller);
        setEsignVerificationStatus('pending');
      } catch (err) {
        console.error(err);
      }
    },
    [cognitoToken, session.userData]
  );

  const verificationResultProps = {
    unverified: {
      status: 'info',
      icon: <SecurityScanFilled style={{ color: token.colorPrimary }} />,
      title: (
        <Text strong color="black" size={18}>
          Email Verification Required
        </Text>
      ),
      subTitle: 'Please check your email for the verification link.',
      extra: [
        <Button
          type="primary"
          size="large"
          key="resend"
          onClick={handleResendVerification}
          loading={resendLoading}
        >
          Resend Verification Email
        </Button>,
      ],
    },
    pending: {
      status: 'warning',
      icon: <SecurityScanFilled style={{ color: token.colorPrimary }} />,
      title: (
        <Text strong color="black" size={18}>
          Email Verification Pending
        </Text>
      ),
      subTitle: 'Please check your email for a verification link.',
      extra: [
        <Button
          type="primary"
          size="large"
          key="resend"
          onClick={handleResendVerification}
          loading={resendLoading}
        >
          Resend Verification Email
        </Button>,
      ],
    },
    error: {
      status: 'error',
      title: 'Email Verification Failed',
      subTitle: 'Your sender status could not be verified. Please contact support for assistance.',
    },
  };

  useEffect(() => {
    console.log('ƒ useEffect on data', senderData);
    const controller = new AbortController();
    if (senderData) {
      if (!senderData.result.length) {
        createNewSenderIdentity(controller);
      }
    }
    return () => {
      controller.abort();
    };
  }, [createNewSenderIdentity, qc, senderData]);

  useEffect(() => {
    // console.log('ƒ useEffect on esignData', props.editDocument, esignData);
    if (props.editDocument?.esignId) {
      qc.invalidateQueries(['esignData']);
    } else {
      qc.resetQueries(['esignData']);
    }
  }, [esignData, props.editDocument, qc]);

  return (
    <DrawerContainer variants={formVariants} initial="hide" animate="show">
      <Title>Sign Document</Title>
      <Subtitle>Send, e-sign and store documents</Subtitle>
      <FormContentContainer>
        {senderLoading || tenantLoading ? (
          <Spin spinning={true} />
        ) : esignVerificationStatus !== 'verified' ? (
          <Result {...verificationResultProps[esignVerificationStatus]} />
        ) : (
          <Form
            form={form}
            name="sign-form"
            layout="vertical"
            requiredMark="optional"
            initialValues={{ tenant: [], otherRecipients: [] }}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          >
            <Item name="tenant" label="Tenants">
              <Select
                options={tenantSelectOptions}
                placeholder="Select a Tenant"
                loading={tenantLoading}
                disabled={tenantLoading || !!props.editDocument?.esignId}
                showSearch
                mode="multiple"
                maxCount={4}
              />
            </Item>
            <Form.List
              name="otherRecipients"
              rules={[{ required: recipientsRequired, message: 'Requires either tenant or other recipients(s)' }]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <AntSpace
                      key={key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Item
                        {...restField}
                        name={[name, 'fullName']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing name',
                          },
                        ]}
                      >
                        <Input placeholder="Full Name" />
                      </Item>
                      <Item
                        {...restField}
                        name={[name, 'email']}
                        hasFeedback
                        validateTrigger={['onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing email address',
                          },
                          {
                            type: 'email',
                            message: 'Invalid email address',
                          },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Item>
                      <MinusSquareOutlined onClick={() => remove(name)} />
                    </AntSpace>
                  ))}
                  <Item help={hasMaxSigners ? 'Maximum of 4 signatures allowed' : ''}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusSquareOutlined />}
                      disabled={!!props.editDocument?.esignId || hasMaxSigners}
                    >
                      Add recipient
                    </Button>
                  </Item>
                </>
              )}
            </Form.List>
          </Form>
        )}
      </FormContentContainer>
      <Card>
        <Text as="div" color="black" strong>
          Document Details:
        </Text>
        {props.editDocument && (
          <>
            <Space vertical={8} />
            <Text as="div" color="black">
              Name: {props.editDocument.label}
            </Text>
            <Text as="div" color="greyText">
              {props.editDocument.fileName}
            </Text>
          </>
        )}
        <DocumentHistory record={props.editDocument} esignData={esignData} />
        <Button
          type="link"
          block
          onClick={handleDownload}
          loading={isDownloading}
          disabled={!props.editDocument?.esignId}
        >
          Download Esign Document
        </Button>
      </Card>
      <Space vertical={24} />
      <Button
        style={{ justifyContent: 'flex-end' }}
        type={revokeDocument.buttonType}
        onClick={handleRevoke}
        loading={isRevoking}
        icon={<DeleteOutlined />}
        disabled={
          !props.editDocument?.esignId || esignData?.status === 'Completed' || esignData?.status === 'Revoked'
        }
        danger
      >
        {revokeDocument.text}
      </Button>
    </DrawerContainer>
  );
}

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px 0;
  flex: 0 0 420px;
  @media screen and (max-width: 400px) {
    padding: 20px 0;
  }
`;

const DrawerContainer = styled(motion.div)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export default SignPanel;
