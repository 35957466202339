import React, { useState, useEffect, useCallback } from 'react';
import { Title, Subtitle } from 'components/Panel/PanelTitle/PanelTitle';
import { Form, Select, Input } from 'antd';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { formVariants } from 'animations/variants';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';
import { useDispatch, useSelector } from 'react-redux';
import { getRentersV3 } from 'services/api/landlordApi/read';
import { globalDisplayAlert } from 'resources/helpers';
import { validateEmailList } from 'resources/FormValidators';
import useFileManager from 'hooks/useFileManager';
import { sendMessage } from 'services/api/common/create';

const { Item, useForm } = Form;

function SendPanel(props) {
  // console.log('[SendPanel.js]', props);
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const { getFileUrl } = useFileManager();
  const cognitoToken = session.cognitoTokens?.idToken.jwtToken;
  const { setSubmitting } = props;
  const [form] = useForm();
  const [tenantSelectOptions, setTenantSelectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recipientsRequired, setRecipientsRequired] = useState(true);

  const handleFinish = useCallback(
    async (values) => {
      console.log('ƒ handleFinish', values);
      try {
        setSubmitting(true);
        if (!props.editDocument) throw new Error('No document to send');

        const { id, fileName, mimeType, associations, isPublic, isTemplate, landlordId, tags, label } =
          props.editDocument;
        const presignedUrl = await getFileUrl(id, 'download', fileName, 'document', null, landlordId);

        const recipients = () => {
          const otherArray = values.otherRecipients?.replace(/\s/g, '').split(',') || [];
          const tenantArray = values.tenant || [];
          return [...tenantArray, ...otherArray];
        };

        const eventList = (editDoc) => {
          let eventList = editDoc.events;

          eventList.push({
            eventType: 'sent',
            entity: 'document',
            message: `Sent the document ${label}`,
            occurredAt: new Date().toISOString(),
            metadata: {
              eventCreatedBy: session.userData.id,
            },
          });

          return eventList;
        };

        const documentData = {
          id,
          landlordId,
          label,
          fileName,
          isPublic,
          isTemplate,
          mimeType,
          tags,
          associations,
          events: eventList(props.editDocument),
        };

        const senderName = session.userData.privacyFullNameForRenter;

        const messageData = {
          from: '"Payrent" <noreply@payrent.com>',
          replyTo: `"${senderName}" <${session.userData.email}>`,
          to: recipients(),
          subject: `${values.subject} via PayRent`,
          text: values.message,
          attachments: [
            {
              filename: fileName,
              href: presignedUrl,
              contentType: mimeType,
            },
          ],
        };

        // console.log('ƒ handleFinish/documentData', documentData, messageData);
        await Promise.allSettled([
          props.handleMutation.mutateAsync(documentData),
          sendMessage(cognitoToken, messageData),
        ]);

        setSubmitting(false);
        setTimeout(() => {
          props.setDrawerVisible(false);
          globalDisplayAlert(dispatch, '', 'success', `Document sucessfully sent.`);
        }, 100);
      } catch (err) {
        setSubmitting(false);
        props.displayAlert('Failed to send document', 'error');
        console.error('ƒ handleFinish', err);
      }
    },
    [
      cognitoToken,
      dispatch,
      getFileUrl,
      props,
      session.userData.email,
      session.userData.id,
      session.userData.privacyFullNameForRenter,
      setSubmitting,
    ]
  );

  const fetchAndSetData = useCallback(
    async (controller) => {
      try {
        setLoading(true);
        form.setFieldsValue({
          tenant: [],
          otherRecipients: null,
          message: `Hi There,\n\nPlease find the ${props.editDocument?.label} document attached for your review. \n\nThank you for your prompt attention to this matter. \n\nBest Regards,\n\n${session.userData.privacyFullNameForRenter}`,
          subject: `Document from ${session.userData.privacyFullNameForRenter}`,
        });

        const tenants = await getRentersV3(cognitoToken, 1, 10000, '', controller);
        // console.log('ƒ fetchAndSetData/tenants', tenants);

        if (tenants?.items.length) {
          setTenantSelectOptions(() =>
            tenants.items.map((t) => ({
              label: `${t.firstName} ${t.lastName}`,
              value: `"${t.firstName} ${t.lastName}" <${t.email}>`,
            }))
          );
        }
      } catch (error) {
        console.error('ƒ fetchAndSetData', error);
      }
      setLoading(false);
    },
    [cognitoToken, form, props.editDocument, session.userData.privacyFullNameForRenter]
  );

  useEffect(() => {
    const controller = new AbortController();
    if (props.drawerVisible) {
      fetchAndSetData(controller);
    }
    return () => {
      controller.abort();
    };
  }, [fetchAndSetData, form, props.drawerVisible]);

  return (
    <DrawerContainer variants={formVariants} initial="hide" animate="show">
      <Title>Send Document</Title>
      <Subtitle>Share Documents via Email</Subtitle>
      <Space vertical={24} />
      <Text color="black">Document Details here</Text>
      <FormContentContainer>
        <Form
          form={form}
          name="send-form"
          layout="vertical"
          requiredMark="optional"
          onValuesChange={(changed) =>
            changed.tenant?.length ? setRecipientsRequired(false) : setRecipientsRequired(true)
          }
          onFinish={handleFinish}
        >
          <Item name="tenant" label="Tenants">
            <Select
              options={tenantSelectOptions}
              placeholder="Select a Tenant"
              loading={loading}
              disabled={loading}
              showSearch
              mode="multiple"
              maxCount={50}
            />
          </Item>
          <Item
            name="otherRecipients"
            label="Other Recipients"
            hasFeedback
            rules={[
              { validator: (rule, value) => validateEmailList(rule, value) },
              {
                required: recipientsRequired,
                message: 'Requires either tenant or other recipients(s)',
              },
            ]}
          >
            <Input.TextArea placeholder="Enter comma separated email addresses" rows={3} />
          </Item>
          <Item name="subject" label="Subject" rules={[{ required: true }]}>
            <Input placeholder="Enter email subject" />
          </Item>
          <Item name="message" label="Message" rules={[{ required: true }]}>
            <Input.TextArea placeholder="Enter email message" rows={12} />
          </Item>
        </Form>
      </FormContentContainer>
    </DrawerContainer>
  );
}

const FormContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 25px 0;
  @media screen and (max-width: 400px) {
    padding: 20px 0;
  }
`;

const DrawerContainer = styled(motion.div)`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export default SendPanel;
