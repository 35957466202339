import React from 'react';

import { FlagFilled, FlagOutlined } from '@ant-design/icons';

const FlagIcon = (props) => {
  const { handleUpdateItem, buttons } = props;

  return (
    <a>
      {buttons.flagged ? (
        <FlagFilled
          style={{ fontSize: '20px', color: 'black' }}
          onClick={() => {
            handleUpdateItem(buttons.id, 'unflag');
          }}
          data-testid="flagIconTest"
        />
      ) : (
        <FlagOutlined
          style={{ fontSize: '20px', color: 'black' }}
          onClick={() => {
            handleUpdateItem(buttons.id, 'flag');
          }}
          data-testid="flagIconOutlinedTest"
        />
      )}
    </a>
  );
};

export default FlagIcon;
