import React from 'react';
import { Popover, Space } from 'antd';
import Text from 'components/Text/Text';
import { CrownFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button/Button';

export default function PremiumFeaturePopover({ children, ...props }) {
  const { cta, showPopover } = props;
  const history = useHistory();

  if (!showPopover) {
    return children;
  } else {
    return (
      <Popover
        placement="topRight"
        trigger={['hover', 'click']}
        title={
          <Space>
            <CrownFilled />
            <Text strong color="black">
              Premium Feature
            </Text>
          </Space>
        }
        content={
          <Space align="center" direction="vertical">
            <Text size="small" as="div">
              {cta}
            </Text>
            <Button
              color="yellow"
              onClick={() => {
                history.push('/landlord/service-plans');
                if (props.handleDrawerReset) props.handleDrawerReset();
              }}
            >
              UPGRADE
            </Button>
          </Space>
        }
      >
        {children}
      </Popover>
    );
  }
}
