import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import StatusText from './components/StatusText/StatusText';

//import './style.scss';

const AccountStatus = (props) => {
  const { user } = props;
  const [state, setState] = useState({
    status: '',
    id: '',
    text: '',
    color: '#d48806',
    background: '#ffe58f',
  });

  const getUserStatus = (values) => {
    switch (values.state) {
      case 'active':
        setState({
          status: 'active',
          id: values?.id,
          text: 'Active',
          color: '#52c41a',
          background: '#D9F7BE',
        });
        break;
      case 'action-required':
      case 'error':
        setState({
          status: 'error',
          id: values?.id,
          text: 'Disabled',
          color: '#f5222d',
          background: '#FFA39E',
        });
        break;
      case 'new2-step1':
      case 'new2-step2':
      case 'pending':
        setState({
          status: 'inactive',
          id: values?.id,
          text: 'Inactive',
          color: '#d48806',
          background: '#ffe58f',
        });
        break;
      case 'stripe-payment-method-required':
        setState({
          status: 'stripe-payment-method-required',
          id: values?.id,
          text: 'disabled',
          color: '#f5222d',
          background: '#ffe58f',
        });
        break;
      case 'disabled':
        setState({
          status: 'disabled',
          id: values?.id,
          text: 'disabled',
          color: '#f5222d',
          background: '#ffe58f',
        });
        break;
      default:
        setState({
          status: '',
          id: '',
          text: '',
          color: '#d48806',
          background: '#ffe58f',
        });
        break;
    }
  };

  useEffect(() => {
    getUserStatus(user);
  }, [user]);

  return (
    <Container
      color={state.background}
      className="account-status"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div className="account-status-text" style={{ alignSelf: 'center' }}>
        <Text size={16} color="black" weight={500}>
          Account Status:{' '}
        </Text>
        <StatusText
          state={state}
          setDrawerVisible={props.setDrawerVisible}
          setShowOnboarding={props.setShowOnboarding}
          setShowPlansBillingPanel={props.setShowPlansBillingPanel}
          setShowOnboardingOverlay={props.setShowOnboardingOverlay}
        />
      </div>
      {/* <Button
        color="violet"
        onClick={() => dispatch({ type: 'SET_SHOW_TOUR', payload: { showMainTour: true } })}
        style={{ alignSelf: 'center' }}
        bottom={0}
      >
        TAKE THE TOUR!
      </Button> */}
      {/* <NewsButton setNewsDrawerVisible={props.setNewsDrawerVisible} newPosts /> */}

      {/* (state.status === 'active' || state.status === 'inactive') && (
        <Button
          color="yellow"
          inline
          bottom={0}
          height={33}
          padding1={5}
          padding2={13}
          onClick={() => history.push('/landlord/service-plans')}
        >
          UPGRADE
        </Button>
      ) */}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.color};
  height: 51px;
  padding-left: 101px;
  font-family: inherit;
  font-size: 12px;
  font-weight: normal;
  color: #595959;
  @media screen and (max-width: 768px) {
    padding-left: 16px;
    paddingt-right: 3px;
    text-align: center;
    height: 51px;
  }
  @media screen and (max-width: 576px) {
    padding-left: unset;
    paddingt-right: unset;
    text-align: center;
  }
  @media screen and (max-width: 434px) {
    padding-left: unset;
    paddingt-right: unset;
    text-align: center;
  }
`;

export default AccountStatus;
