import Text from 'components/Text/Text';
import React from 'react';
import BoardingFaqs from './BoardingFaqs';
import PortfolioFaqs from './PortfolioFaqs';
import PropertyFaqs from './PropertyFaqs';
import TenantFaqs from './TenantFaqs';
import UnitFaqs from './UnitFaqs';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { formVariants } from 'animations/variants';
import FinalFaqs from './FinalFaqs';

function GetStartedHelp(props) {
  const { currentStep } = props;

  return (
    <StyledHelpContainer
      key="faqs"
      variants={formVariants}
      initial="hide"
      animate="show"
      data-testid="getStartedHelpTest"
    >
      <Text as="div" color="black" strong>
        FAQs
      </Text>
      {currentStep === 0 ? (
        <PortfolioFaqs />
      ) : currentStep === 1 ? (
        <PropertyFaqs />
      ) : currentStep === 2 ? (
        <UnitFaqs />
      ) : currentStep === 3 ? (
        <BoardingFaqs />
      ) : currentStep === 4 ? (
        <TenantFaqs />
      ) : currentStep === 5 ? (
        <FinalFaqs />
      ) : null}
    </StyledHelpContainer>
  );
}

const StyledHelpContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
`;

export default GetStartedHelp;
