import { Form, Input, Row, Select, Col, InputNumber, Space, Radio } from 'antd';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { addACHMethod } from 'services/wepay/paymentMethod';
import { MaskedInput } from 'antd-mask-input';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { usStates } from 'resources/FormData';
import { validateCreditCard } from 'resources/FormValidators';
import { ReactComponent as PlaidLogo } from 'assets/images/plaid_logo.svg';
import Segmented from 'components/Segmented/Segmented';
// import wePayCheckout from '../../../../../services/wepay/payment';

// testing number 4111111111111111
const { Item, useForm } = Form;
const { Option } = Select;

function PaymentForm(props) {
  // console.log('[PaymentForm.js]', props);
  const {
    activePaymentMethod,
    setActivePaymentMethod,
    bankInfo,
    setBankInfo,
    loading,
    fieldValues,
    pricing,
    paymentSummary,
    setPaymentSummary,
    cardValidation,
    setCardValidation,
    onFinish,
  } = props;
  const [form] = useForm();
  // const [activePaymentMethod, setActivePaymentMethod] = useState('creditcard');
  // const [bankInfo, setBankInfo] = useState();
  // const [loading, setLoading] = useState(false);
  // const [paymentSummary, setPaymentSummary] = useState({ total: 0, fee: 0 });

  const calculateFee = useCallback(
    (changedValue) => {
      // console.log('ƒ calculateFee', changedValue, form.getFieldsValue(), pricing);
      const recalc = ['paymentMethod', 'paymentAmount'];
      if (recalc.some((k) => Object.keys(changedValue).includes(k))) {
        const { paymentAmount, paymentMethod } = form.getFieldsValue();
        const fee =
          paymentMethod === 'creditcard'
            ? paymentAmount * pricing?.creditCardRateFee + pricing?.creditCardTrxFee
            : pricing.achTrxFee;
        const total = fee + paymentAmount;
        setPaymentSummary({ total, fee });
      }
    },
    [form, pricing]
  );

  const creditCardMask = useMemo(() => {
    return {
      mask: [
        { mask: '0000', lazy: true, cardType: undefined },
        { mask: '0000 0000 0000 0000', lazy: true, cardType: 'visa' },
        { mask: '0000 0000 0000 0000', lazy: true, cardType: 'mastercard' },
        { mask: '0000 000000 00000', lazy: true, cardType: 'american-express' },
      ],
      dispatch: (_appended, dynamicMasked) => {
        // console.log(_appended, dynamicMasked, cardValidation);
        return dynamicMasked.compiledMasks.find((m) => m.cardType === cardValidation.card?.type);
      },
    };
  }, [cardValidation]);

  const currencyFormatter = (value) => {
    return new Intl.NumberFormat('en-us', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const handleBankInfo = async (email) => {
    const response = await addACHMethod(email, true);
    // console.log(response);
    setBankInfo(response);
  };

  const gutter = [8, 8];

  useEffect(() => {
    if (fieldValues) {
      const { paymentAmount } = fieldValues;
      form.setFieldsValue(fieldValues);
      calculateFee({ paymentAmount });
    }
  }, [fieldValues, form, calculateFee]);

  return (
    <Form
      data-testid="paymentForm"
      form={form}
      onFinish={onFinish}
      initialValues={{ paymentMethod: 'creditcard' }}
      onValuesChange={(changedValue) => calculateFee(changedValue)}
    >
      <h4>Payment Detail</h4>
      <Item name="paymentAmount" rules={[{ required: true, message: 'Payment amount is required' }]}>
        <InputNumber
          data-testid="paymentAmountInput"
          style={{ height: '34px' }}
          autoFocus
          controls={false}
          placeholder="Payment Amount"
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/[^0-9.]/g, '')}
          precision={2}
        />
      </Item>
      <Item name="reference" rules={[{ max: 255 }]}>
        <StyledInput placeholder="Reference or Invoice Number" data-testid="referenceInput" />
      </Item>
      <Item
        name="email"
        hasFeedback
        rules={[
          { required: true, message: 'Email is required' },
          { type: 'email', message: 'Not a valid email address' },
        ]}
      >
        <StyledInput placeholder="Email Address" data-testid="emailInput" />
      </Item>
      <h4>Payment Method</h4>

      <Item name="paymentMethod">
        <Segmented
          size="large"
          onChange={(value) => setActivePaymentMethod(value)}
          options={[
            { label: 'Credit Card', value: 'creditcard' },
            { label: 'Bank Account', value: 'ach' },
          ]}
        />
      </Item>
      <AnimatePresence exitBeforeEnter>
        {activePaymentMethod === 'creditcard' ? (
          <motion.div
            key="motion-credit-card"
            style={{ height: '120px' }}
            initial={{ x: '-70%' }}
            transition={{ easeInOut: 0.3 }}
            animate={{ x: 0 }}
            exit={{ x: '-70%' }}
          >
            <Item name="cardholderName" rules={[{ required: true, message: 'Cardholder name is required' }]}>
              <StyledInput placeholder="Name on Card" data-testid="cardholderInput" />
            </Item>
            <Row gutter={gutter}>
              <Col xs={{ span: 14 }} md={{ span: 12 }}>
                <Item
                  name="cardholderNumber"
                  hasFeedback
                  rules={[
                    { required: true, message: 'Credit card number is required' },
                    {
                      validator: (rule, value) =>
                        validateCreditCard(rule, value).then((card) => setCardValidation(card)),
                    },
                  ]}
                >
                  <MaskedInput
                    data-testid="creditcardInput"
                    mask={creditCardMask.mask}
                    maskOptions={{ dispatch: creditCardMask.dispatch }}
                    placeholder="Card Number"
                  />
                </Item>
              </Col>
              <Col xs={{ span: 5 }} md={{ span: 6 }}>
                <Item
                  name="cvv"
                  rules={[
                    { required: true, message: 'CVV is required' },
                    { max: 4, message: '3 or 4 digits' },
                  ]}
                  hasFeedback
                >
                  <StyledInput placeholder="CVV" data-testid="cvvInput" />
                </Item>
              </Col>
              <Col xs={{ span: 5 }} md={{ span: 6 }}>
                <Item name="expDate" rules={[{ required: true, message: 'Expiration date is required' }]}>
                  <MaskedInput mask="00/00" placeholder="Exp MM/YY" data-testid="expDateInput" />
                </Item>
              </Col>
            </Row>
          </motion.div>
        ) : (
          <motion.div
            key="motion-bank-account"
            initial={{ x: '70%' }}
            animate={{ x: 0 }}
            exit={{ x: '70%' }}
            transition={{ easeInOut: 0.3 }}
            style={{
              height: '120px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'start',
            }}
          >
            <span>We verify bank details using</span>
            <PlaidLogo style={{ height: 'auto', width: '120px' }} />

            {bankInfo ? (
              <div>Verified: {bankInfo.label}</div>
            ) : (
              <Button onClick={() => handleBankInfo('test@test.com')} style={{ marginBottom: '0px' }}>
                Verify Bank Account
              </Button>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <h4>Billing Address</h4>
      <Row gutter={gutter}>
        <Col xs={{ span: 24 }} md={{ span: 16 }}>
          <Item name="address" rules={[{ required: true, message: 'Address is required' }]}>
            <StyledInput placeholder="Address" data-testid="addressInput" />
          </Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Item name="address2">
            <StyledInput placeholder="Suite, Apt. or Mailbox" data-testid="address2Input" />
          </Item>
        </Col>
      </Row>

      <Row gutter={gutter}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Item name="city" rules={[{ required: true, message: 'City is required' }]}>
            <StyledInput placeholder="City" data-testid="cityInput" />
          </Item>
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Item name="state" rules={[{ required: true, message: 'State is required' }]}>
            <Select placeholder="State" data-testid="stateSelect">
              {usStates.map((s, i) => (
                <Option value={s.abbreviation} key={i}>
                  {s.abbreviation}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Item name="zip" rules={[{ required: true, message: 'Zip code is required' }]}>
            <StyledInput placeholder="Zip/Postal Code" data-testid="zipInput" />
          </Item>
        </Col>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Space direction="vertical" style={{ textAlign: 'right' }}>
          <Text data-testid="convenienceFeeText">
            Convenience Fee: {currencyFormatter(paymentSummary.fee || 0)}
          </Text>
          <Text>Total Payment Amount: {currencyFormatter(paymentSummary.total || 0)}</Text>
        </Space>
      </div>
      <div style={{ marginTop: '12px', marginBottom: '12px' }}>
        <Text size={10} color="#000">
          By clicking “Submit Payment”, you agree to pay the above amount and provide your information to help us
          process your payment and detect fraud. Please see our Privacy Policy for more information on our data
          practices.
        </Text>
      </div>
      <Row>
        <Col xs={{ offset: 6, span: 6 }} md={{ offset: 17, span: 7 }}>
          <Button type="primary" htmlType="submit" loading={loading} data-testid="submitPaymentButton">
            Submit Payment
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const StyledInput = styled(Input)`
  padding: 5px 11px;
`;

export default PaymentForm;
