import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';
import { Form } from 'antd';
import styled from 'styled-components';

import { getSessionDetails, getPropertyGroupOnboardingStatus } from 'services/api/landlordApi/read';

import { wepayOnboarding } from 'services/wepay/onboarding';
import { createPaymentGateway } from 'services/api/landlordApi/create';

import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import { AnimatePresence, motion } from 'framer-motion';
import { boardingElement } from '../../animation/variants';

const WepayAccountSetup = (props) => {
  const {
    checkLandlordStatus,
    setWepayAccountSetup,
    setFinishedSetup,
    groupOnboardingStatus,
    defaultPropertyGroup,
  } = props;
  const [form] = Form.useForm();

  const session = useSelector((state) => state.session);
  const cognitoToken = useSelector((state) => state.session.cognitoTokens.idToken.jwtToken);

  const [disableButton1, setDisableButton1] = useState(false);
  const [disableButton2, setDisableButton2] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  useEffect(() => {
    if (groupOnboardingStatus.wepayRegistrationState === 'new2-step1') {
      setDisableButton1(false);
      setDisableButton2(true);
    }
    if (groupOnboardingStatus.wepayRegistrationState === 'new2-step2') {
      setDisableButton1(true);
      setDisableButton2(false);
    }
    if (groupOnboardingStatus.wepayRegistrationState === 'active') {
      setWepayAccountSetup(false);
      setFinishedSetup(true);
    }
    if (groupOnboardingStatus.wepayRegistrationState === 'error') {
      // setWepayAccountSetup(false);
      setFinishedSetup(false);
      displayAlert(groupOnboardingStatus.wepayState, 'warning');
    }
  }, []);

  const handleWepay1 = async () => {
    try {
      setLoading1(true);
      const sessionRes = await getSessionDetails();
      const fullName = `${sessionRes.firstName} ${sessionRes.lastName}`;
      const result = await wepayOnboarding(fullName, sessionRes.email);
      result.propertyGroupId = +defaultPropertyGroup[0].id;
      await createPaymentGateway(result);

      checkLandlordStatus(setLoading1, setDisableButton1, setDisableButton2, displayAlert);
    } catch (error) {
      // show error alert
      setLoading1(false);
      // console.log(error);
      displayAlert('There was an error with your request', 'warning');
    }
  };

  const handleWepay2 = async () => {
    setLoading2(true);

    try {
      const groupStatus = await getPropertyGroupOnboardingStatus(cognitoToken, +defaultPropertyGroup[0].id);
      // console.log('Group Status', groupStatus);
      if (groupStatus?.updateUri) {
        const url = groupStatus.updateUri;
        setTimeout(() => {
          window.location.href = url;
          // window.open(url, '_blank'); //to open new page
        }, 300);
      }
    } catch (error) {
      // console.log(error);
      setLoading2(false);
    }
  };

  const displayAlert = (message, type) => {
    setAlert({
      isVisible: true,
      message,
      type,
    });

    setTimeout(() => {
      setAlert({
        isVisible: false,
      });
    }, 2000);
  };

  const onFinish = () => {};

  return (
    <>
      <Container
        className="content-container"
        variants={boardingElement}
        initial="hidden"
        animate="enter"
        exit="leave"
      >
        {isIOS && (
          <AlertFade
            isVisible
            type="error"
            display
            marginTop={10}
            marginBottom={10}
            message={
              <span>
                You must disable Pop-up Blocking in your browser to complete the steps below.{' '}
                <a href="https://www.howtogeek.com/770127/how-to-disable-pop-up-blocker-in-safari-on-iphone-and-ipad/">
                  Learn How
                </a>
              </span>
            }
          />
        )}

        <Text size={16} color="black" centered margin="0 auto">
          To accept payments you must first register with Chase WePay, our payment processor. Click the button
          below to open a new, secure registration window.
        </Text>
        <Space vertical={10} />
        <Button
          color="yellow"
          alignment="center"
          fontSize={14}
          onClick={handleWepay1}
          disabled={disableButton1}
          loading={loading1}
          data-testid="step1Test"
          bottom={0}
        >
          STEP 1: CREATE YOUR MERCHANT ACCOUNT
        </Button>
        <Space vertical={10} />
        <Text size={16} color="black" centered margin="0 auto">
          Once your account is created, click on STEP 2 to verify your identity and add business details. You
          cannot accept rent payments until this step is completed.
        </Text>
        <Space vertical={10} />
        <Button
          color="yellow"
          alignment="center"
          fontSize={14}
          onClick={handleWepay2}
          disabled={disableButton2}
          loading={loading2}
          data-testid="step2Test"
        >
          STEP 2: VERIFY YOUR IDENTITY AND BUSINESS
        </Button>
        <AlertFade isVisible={alert.isVisible} message={alert.message} type={alert.type} minHeight="80" />
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: #ffffff;
  min-height: 76%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 13px;
  overflow: hidden;
  overflow-y: scroll !important;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
`;

export default WepayAccountSetup;
