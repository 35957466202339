import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, theme } from 'antd';

import logo from '../../assets/payrent_logo_inv.svg';
//import './style.scss';
const { Content } = Layout;

const layoutStyle = {
  minHeight: '100vh',
};

const { useToken } = theme;

const AuthLayout = (props) => {
  const { token } = useToken();

  const contentStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: token.colorPrimary,
  };

  return (
    <Layout style={layoutStyle} data-testid="authLayoutTest">
      <Content style={contentStyle}>
        <Link to="/">
          <img className="drawer-brand-logo" src={logo} alt="Payrent logo" />
        </Link>
        {props.children}
      </Content>
    </Layout>
  );
};

export default AuthLayout;
