// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { Card as AntCard, Menu, Dropdown, Drawer, Spin } from 'antd';

import * as Iot from 'services/aws/iot/aws-iot';

import { deleteScheduledPayment, getScheduledPayments } from 'store/actions/tenantActions';
import { clearPanel } from 'store/actions/globalActions';
import { getUserInsurancePolicy } from 'services/api/common/read';
import TenantDrawer from 'components/TenantDrawer/TenantDrawer';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

// Icons
import { PlusCircleFilled, MoreOutlined } from '@ant-design/icons';

// Components
import AlertFade from 'components/AlertFade';
import CardBlank from 'components/CardBlank';
import Space from 'components/Space';
import Button from 'components/Button';
import PaymentMethods from '../components/PaymentMethods/PaymentMethods';
import PaymentSchedule from '../Payment/PaymentSchedule';
import ComplianceAlert from '../components/ComplianceAlert';
import Documents from './Documents';
import Insurance from '../Insurance';
import InsuranceAlert from '../components/InsuranceAlert';
import AccountStatusTitle from '../components/AccountStatusTitle';
import MicrodepositAlert from '../components/MicrodepositAlert';
import InsuranceCard from './InsuranceCard/InsuranceCard';
import PaymentMethodLinks from '../components/PaymentMethods/components/PaymentMethodLinks/PaymentMethodLinks';

const Wallet = (props) => {
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const panel = useSelector((store) => store.global.panel);
  const uploadedInsuranceFile = useSelector((store) => store.tenant.uploadedInsuranceFile);
  const userData = useSelector((store) => store.session.userData);
  const globalLoading = useSelector((store) => store.global.loading);
  const alert = useSelector((store) => store.global.alert);
  const scheduledPayments = useSelector((store) => store.tenant.scheduledPayments);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [scheduledPaymentId, setScheduledPaymentId] = useState(null);
  const [dropDownFlag, setDropDownFlag] = useState({});
  const [deleteText, setDeleteText] = useState({
    text: 'Delete Scheduled Payment',
    color: 'inherit',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: false,
  });

  const [userPolicy, setUserPolicy] = useState(false);
  const [userPolicyDetail, setUserPolicyDetail] = useState(null);

  const [insuranceDrawer, setInsuranceDrawer] = useState(false);
  const [getUploadVisible, setGetUploadVisible] = useState(false);
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [showUploadedPolicyDetails, setShowUploadedPolicyDetails] = useState(false);

  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const resetDropdown = (items) => {
    const object = {};
    for (let i = 0; i < items.length; i++) {
      object[i] = false;
    }
    return object;
  };

  const openPanel = (value, policy) => {
    switch (value) {
      case 'scheduled-payments':
        setDrawerVisible(true);
        break;
      case 'upload-buy-policy':
        setInsuranceDrawer(true);
        setGetUploadVisible(true);
        break;
      case 'policy-details':
        if (policy) {
          setInsuranceDrawer(true);
          if (uploadedInsuranceFile) {
            setShowUploadedPolicyDetails(true);
          } else {
            setShowPolicyDetails(true);
          }
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    Iot.attachMessageHandler(async (topic, message) => {
      const string = new TextDecoder().decode(message);
      const ms = JSON.parse(string);

      const billingAccount = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (ms.event === 'INSURANCE_POLICY_UPDATE') {
        if (billingAccount.length) {
          await fetchInsurancePolicy();
          if (!insuranceDrawer) {
            setInsuranceDrawer(true);
          }
          setGetUploadVisible(false);
          setShowUploadedPolicyDetails(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(props.history.location.search);
    if (params.get('panel')) {
      const insurancePanel = params.get('panel');
      if (insurancePanel === 'insurance') {
        setInsuranceDrawer(true);
        setGetUploadVisible(true);
      }
    }
  }, []);

  useEffect(() => {
    // if (scheduledPayments.items.length === 0) {
    dispatch(getServicePlanAction(cognitoToken));
    dispatch({ type: 'SET_LOADING_SCHEDULED_PAYMENTS' });
    dispatch(getScheduledPayments(cognitoToken));
    // }
    setDropDownFlag(resetDropdown(scheduledPayments.items));
    fetchInsurancePolicy();
  }, []);

  const fetchInsurancePolicy = async () => {
    try {
      const billingAccount = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (billingAccount.length) {
        const res = await getUserInsurancePolicy(cognitoToken, billingAccount[0].id);
        if (!Object.keys(res.policy).length && (res?.filename === '' || !res?.filename)) {
          setUserPolicy(false);
          setUserPolicyDetail({ ...userPolicyDetail, policy: {} });
          dispatch({ type: 'SET_INSURANCE_POLICY_FILE', payload: false }); // to disable buttons
          if (panel) {
            openPanel(panel, false);
            dispatch(clearPanel());
          }
          return;
        }
        setUserPolicyDetail(res);
        setUserPolicy(true);
        if (panel) {
          openPanel(panel, true);
          dispatch(clearPanel());
        }
      }
    } catch (error) {
      setUserPolicy(false);
      if (panel) {
        openPanel(panel, false);
        dispatch(clearPanel());
      }
    }
  };

  const ordinalSuffix = (i) => {
    let j = i % 10;
    let k = i % 100;

    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  };

  const displayAlert = (message, type, description) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    setTimeout(() => {
      dispatch({
        type: 'UNSET_ALERT',
      });
    }, 5000);
  };

  const paymentDate = (frequency, schedule) => {
    switch (frequency) {
      case 'WEEKLY':
        return schedule;
      case 'MONTHLY':
        return `the ${ordinalSuffix(schedule)}`;
      default:
        break;
    }
  };

  const menu = (id, handleClickDelete, editText) => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        onClick={() => {
          setScheduledPaymentId(id);
          setDrawerVisible(true);
        }}
      >
        {editText}
      </Menu.Item>
      <Menu.Item key="2" onClick={(e) => handleClickDelete(e, id)}>
        <span style={{ color: deleteText.color }}>{deleteText.text}</span>
      </Menu.Item>
    </Menu>
  );

  const items = (id, handleClickDelete, editText) => ({
    items: [
      {
        key: '1',
        label: editText,
        onClick: () => {
          setScheduledPaymentId(id);
          setDrawerVisible(true);
          setDropDownFlag(resetDropdown(scheduledPayments.items));
        },
      },
      {
        key: '2',
        label: <span style={deleteText.style}>{deleteText.text}</span>,
        onClick: (e) => handleClickDelete(e, id),
      },
    ],
  });

  const handleClickDeleteScheduledPayment = async (e, id) => {
    if (deleteText.action) {
      try {
        // const updatedItems = scheduledPayments.items.filter((i) => i.id !== id);
        setDropDownFlag(resetDropdown(scheduledPayments.items));
        setDeleteText({
          text: 'Delete Scheduled Payment',
          color: 'inherit',
          style: { color: 'inherit', fontWeight: 'inherit' },
          action: false,
        });
        dispatch(deleteScheduledPayment(session.cognitoTokens.idToken.jwtToken, id));
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
        displayAlert('Scheduled Payment Removed', 'success', 'You can add a new scheduled payment at any time.');
      } catch (error) {
        setDropDownFlag(resetDropdown(scheduledPayments.items));
        displayAlert('There was an error', 'warning', 'Your scheduled payment was not removed');
        setDeleteText({
          text: 'Delete Scheduled Payment',
          color: 'inherit',
          style: { color: 'inherit', fontWeight: 'inherit' },
          action: false,
        });
      }
    } else {
      setDeleteText({
        text: 'Really Delete?',
        color: '#CB47B8',
        style: { color: '#cb47b8', fontWeight: 500 },
        action: true,
      });
    }
  };

  const handleVisibleChange = (arr, flag, source) => {
    console.log('ƒ handleVisibleChange', arr, flag, source);
    if (!flag) {
      setDeleteText({
        text: 'Delete Scheduled Payment',
        color: 'inherit',
        style: { color: 'inherit', fontWeight: 'inherit' },
        action: false,
      });
    }

    const object = {};
    object[arr] = flag;
    setDropDownFlag({ ...dropDownFlag, ...object });
  };

  const handleMenuClick = (e) => {
    if (e.key === '1') {
      setDropDownFlag(resetDropdown(scheduledPayments.items));
    }
  };

  return (
    <>
      <StyledDrawer
        closable={false}
        placement="right"
        destroyOnClose
        onClose={() => {
          setDrawerVisible(false);
        }}
        open={drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <PaymentSchedule
          setDrawerVisible={setDrawerVisible}
          scheduledPaymentId={scheduledPaymentId}
          data-testid="drawerTest"
        />
      </StyledDrawer>
      <Insurance
        insuranceDrawer={insuranceDrawer}
        setInsuranceDrawer={setInsuranceDrawer}
        setGetUploadVisible={setGetUploadVisible}
        setShowPolicyDetails={setShowPolicyDetails}
        setShowUploadedPolicyDetails={setShowUploadedPolicyDetails}
        showUploadedPolicyDetails={showUploadedPolicyDetails}
        getUploadVisible={getUploadVisible}
        showPolicyDetails={showPolicyDetails}
        userPolicyDetail={userPolicyDetail}
        fetchInsurancePolicy={fetchInsurancePolicy}
        userPolicy={userPolicy}
        openPanel={openPanel}
      />
      <CardBlank title={<AccountStatusTitle userData={userData} />}>
        <AlertFade
          isVisible={alert.isVisible}
          message={alert.message}
          description={alert.description}
          type={alert.type}
          display
          closable={alert.closable}
          marginTop={10}
          onClose={() => {
            dispatch({
              type: 'UNSET_ALERT',
            });
          }}
        />
        {globalLoading && (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        )}
        <MicrodepositAlert />
        <InsuranceAlert
          userPolicy={userPolicy}
          setInsuranceDrawer={setInsuranceDrawer}
          setGetUploadVisible={setGetUploadVisible}
        />
        <ComplianceAlert setDrawerVisible={setDrawerVisible} />
      </CardBlank>
      <Space vertical={10} />
      <AntCard
        title="Payment Methods"
        styles={{
          header: {
            fontSize: '16px',
            color: '#541388',
            fontWeight: 600,
          },
        }}
      >
        <PaymentMethods data-testid="pMethodsTest" />
        <PaymentMethodLinks data-testid="pMethodLinksTest" />
      </AntCard>
      <Space vertical={10} />
      <AntCard
        title="Scheduled Payments"
        styles={{
          header: {
            fontSize: '16px',
            color: '#541388',
            fontWeight: 600,
          },
        }}
        loading={scheduledPayments.loading}
        data-testid="cardTest"
      >
        {scheduledPayments.items.length > 0 &&
          scheduledPayments.items.map((i, arr) => (
            <ItemContainer key={i.id}>
              {i.frequency.charAt(0).toUpperCase() + i.frequency.slice(1).toLowerCase()}
              &nbsp;for {i.balanceDue ? 'balance due' : `$${Number(i.fixAmount).toFixed(2)}`}
              &nbsp;
              {i.firstPaymentDate &&
                (i.frequency === 'ONE-TIME'
                  ? `on ${moment.utc(i.firstPaymentDate).format('MM/DD/YYYY')}`
                  : `on ${paymentDate(i.frequency, i.schedule)}`)}
              <Dropdown
                menu={items(
                  i.id,
                  handleClickDeleteScheduledPayment,
                  'Edit Scheduled Payment',
                  'Delete Scheduled Payment'
                )}
                trigger={['click']}
                onOpenChange={(flag, { source }) => {
                  if (flag) {
                    const recordState = {
                      [arr]: true,
                    };
                    setDropDownFlag({ ...dropDownFlag, ...recordState });
                  }
                  if (!flag && source === 'trigger') {
                    const recordState = {
                      [arr]: false,
                    };
                    setDropDownFlag({ ...dropDownFlag, ...recordState });
                    setDeleteText({
                      text: 'Delete Payment Method',
                      color: 'inherit',
                      style: { color: 'inherit', fontWeight: 'inherit' },
                      action: false,
                    });
                  }
                }}
                open={dropDownFlag[arr]}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                  data-testid="scheduledMoreTest"
                >
                  <MoreOutlined style={{ fontSize: '20px', color: '#000000' }} />
                </a>
              </Dropdown>
            </ItemContainer>
          ))}
        <Button
          onClick={() => {
            setScheduledPaymentId(null);
            setDrawerVisible(true);
          }}
          type="link"
          alignment="center"
          bottom={1}
          data-testid="addScheduledButtonTest"
        >
          <PlusCircleFilled
            style={{
              marginRight: '10px',
              fontSize: '20px',
              color: '#85BB65',
            }}
          />
          <span style={{ fontSize: '14px' }}>Add a scheduled payment</span>
        </Button>
      </AntCard>
      <Space vertical={10} />
      <InsuranceCard
        setInsuranceDrawer={setInsuranceDrawer}
        setGetUploadVisible={setGetUploadVisible}
        setShowPolicyDetails={setShowPolicyDetails}
        setShowUploadedPolicyDetails={setShowUploadedPolicyDetails}
        userPolicy={userPolicy}
        setUserPolicy={setUserPolicy}
        userPolicyDetail={userPolicyDetail}
      />
      <Space vertical={10} />
      <Documents />
    </>
  );
};

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
`;

const ItemContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 20px;
`;

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
  `}
`;

export default Wallet;
