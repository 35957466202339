import React, { useState, useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { FlagsmithProvider } from 'flagsmith-react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import CustomLoadingOverlay from 'components/CustomLoadingOverlay';
import * as Iot from 'services/aws/iot/aws-iot';

import { clearPanel } from 'store/actions/globalActions';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

import AppLayout from '../../layouts/AppLayout';

// Components
import Dashboard from './Dashboard';
import PropertyGroups from './PropertyGroups';
import Renters from './Renters';
import Settings from './Settings';
import Transactions from './Transactions';
import HelpAndFeedback from './HelpAndFeedback';
import ServicePlans from './ServicePlans';
import Screening from './Screening/Screening';
import Financing from './Financing';
import Development from './Dev/Dev';
import GetStarted from './GetStarted/GetStarted';
import MigrationStart from './WepayOffboarding/MigrationStart';
import MigrationStepper from './WepayOffboarding/MigrationStepper';
import Documents from './Documents/Documents';
import useIntercom from 'hooks/useIntercom';

const Router = (props) => {
  useIntercom();
  const dispatch = useDispatch();
  const global = useSelector((store) => store.global);
  const session = useSelector((store) => store.session);
  const panel = useSelector((store) => store.global.panel);
  const landlord = useSelector((store) => store.landlord);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [newsDrawerVisible, setNewsDrawerVisible] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showOnboardingOverlay, setShowOnboardingOverlay] = useState(false);
  const [showOnboardingFullscreen, setShowOnboardingFullscreen] = useState(false);
  const [showAccountProfile, setShowAccountProfile] = useState(false);
  const [showAccountPreferences, setShowAccountPreferences] = useState(false);
  const [showPlansBillingPanel, setShowPlansBillingPanel] = useState(false);
  const [showBillingDetailsPanel, setShowBillingDetailsPanel] = useState(false);
  const [showUnitPanel, setShowUnitPanel] = useState(false);
  const [showPortfolioSettings, setShowPortfolioSettings] = useState(false);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const sessionId = useRef();
  const mountedRef = useRef(false);

  const openPanel = (value) => {
    switch (value) {
      case 'profile':
        setDrawerVisible(true);
        setShowAccountProfile(true);
        break;
      case 'preferences':
        setDrawerVisible(true);
        setShowAccountPreferences(true);
        break;
      case 'plans-and-billings':
        setDrawerVisible(true);
        setShowPlansBillingPanel(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      !session ||
      !landlord ||
      landlord.units.loading ||
      landlord.renters.loading ||
      landlord.merchantAccount.loading ||
      mountedRef.current
    )
      return;
    // console.log('ƒ useEffect on session/landlord', session, landlord);
    // window.userGuiding.identify(session.userData.id, {
    //   email: session.userData.email,
    //   name: `${session.userData.firstName} ${session.userData.lastName}`,
    //   nickname: session.userData.firstName,
    //   customer_type: session.userRole,
    //   subscription: session.userData.servicePlan.name.split(' - ')[0],
    //   processor: session.userData.servicePlan.processor.name,
    //   is_trialing: !!session.userData.servicePlan.trial,
    //   boarding_status: landlord.merchantAccount.status,
    //   has_units: !!landlord.units.totalCount,
    //   unit_count: landlord.units.totalCount,
    //   has_renters: !!landlord.renters.totalCount,
    //   created_at: dayjs(session.userData.createdAt).format('MM/DD/YYYY'),
    // });

    // eslint-disable-next-line consistent-return
    return () => {
      mountedRef.current = true;
    };
  }, [session, landlord]);

  useEffect(() => {
    Iot.initNewClient({
      accessKeyId: session.cognitoTokens.identityCredentials.AccessKeyId,
      secretAccessKey: session.cognitoTokens.identityCredentials.SecretKey,
      sessionToken: session.cognitoTokens.identityCredentials.SessionToken,
    });
    Iot.attachConnectHandler(() => {
      console.log('ƒ attachConnectHandler connected');
    });

    Iot.attachMessageHandler((topic, message) => {
      const string = new TextDecoder().decode(message);
      // console.log(string);
      const ms = JSON.parse(string);
      if (ms.event === 'USER_UPDATE') {
        dispatch(getServicePlanAction(cognitoToken));
        dispatch(getUserDataV3(cognitoToken));
      }
    });
    if (panel) {
      openPanel(panel);
      dispatch(clearPanel());
    }
  }, []);

  const unsetTransactionRenter = () => {
    dispatch({
      type: 'RENTER_TRANSACTION',
      payload: {
        userName: null,
      },
    });
  };

  useEffect(() => {
    if (session.userData.id !== sessionId.current) {
      const topic = `${process.env.REACT_APP_ENV}/${session.userData.id}`;
      Iot.subscribe(topic);
      // Iot.publish(topic, JSON.stringify({ event: 'React app online' }));
      sessionId.current = session.userData.id;
    }
  }, [session]);

  return (
    <FlagsmithProvider environmentId={process.env.REACT_APP_FLAGSMITH}>
      <CustomLoadingOverlay showOverlay={global.loadingOverlay}>
        <AppLayout
          setDrawerVisible={setDrawerVisible}
          showOnboarding={showOnboarding}
          setShowOnboarding={setShowOnboarding}
          setShowAccountProfile={setShowAccountProfile}
          setShowAccountPreferences={setShowAccountPreferences}
          setShowPlansBillingPanel={setShowPlansBillingPanel}
          newsDrawerVisible={newsDrawerVisible}
          setNewsDrawerVisible={setNewsDrawerVisible}
          // setShowOnboardingOverlay={setShowOnboardingOverlay}
          setShowOnboardingFullscreen={setShowOnboardingFullscreen}
        >
          <Route
            exact
            path={[`${props.match.url}`, `${props.match.url}/s/wepaykyc`]}
            render={(props) => (
              <Dashboard
                {...props}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                showOnboarding={showOnboarding}
                setShowOnboarding={setShowOnboarding}
                showAccountProfile={showAccountProfile}
                setShowAccountProfile={setShowAccountProfile}
                showAccountPreferences={showAccountPreferences}
                setShowAccountPreferences={setShowAccountPreferences}
                showPlansBillingPanel={showPlansBillingPanel}
                setShowPlansBillingPanel={setShowPlansBillingPanel}
                showBillingDetailsPanel={showBillingDetailsPanel}
                setShowBillingDetailsPanel={setShowBillingDetailsPanel}
                newsDrawerVisible={newsDrawerVisible}
                setNewsDrawerVisible={setNewsDrawerVisible}
                showOnboardingOverlay={showOnboardingOverlay}
                setShowOnboardingOverlay={setShowOnboardingOverlay}
                showUnitPanel={showUnitPanel}
                setShowUnitPanel={setShowUnitPanel}
                setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                showOnboardingFullscreen={showOnboardingFullscreen}
              />
            )}
          />
          <Route
            exact
            path={`${props.match.url}/renters`}
            render={(props) => (
              <Renters
                {...props}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                showOnboarding={showOnboarding}
                setShowOnboarding={setShowOnboarding}
                showAccountProfile={showAccountProfile}
                setShowAccountProfile={setShowAccountProfile}
                showAccountPreferences={showAccountPreferences}
                setShowAccountPreferences={setShowAccountPreferences}
                showPlansBillingPanel={showPlansBillingPanel}
                setShowPlansBillingPanel={setShowPlansBillingPanel}
                showBillingDetailsPanel={showBillingDetailsPanel}
                setShowBillingDetailsPanel={setShowBillingDetailsPanel}
                newsDrawerVisible={newsDrawerVisible}
                setNewsDrawerVisible={setNewsDrawerVisible}
                showOnboardingOverlay={showOnboardingOverlay}
                setShowOnboardingOverlay={setShowOnboardingOverlay}
                setShowUnitPanel={setShowUnitPanel}
                showUnitPanel={showUnitPanel}
                setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                showOnboardingFullscreen={showOnboardingFullscreen}
              />
            )}
          />
          <Route exact path={`${props.match.url}/onboarding`} render={(props) => <GetStarted {...props} />} />
          <Route exact path={`${props.match.url}/documents`} render={(props) => <Documents {...props} />} />
          <Route
            exact
            path={`${props.match.url}/wepay-migration`}
            render={(props) => <MigrationStart {...props} />}
          />
          <Route
            exact
            path={`${props.match.url}/wepay-migration/wizard`}
            render={(props) => <MigrationStepper {...props} />}
          />
          <Route
            exact
            path={`${props.match.url}/properties`}
            render={(props) => (
              <PropertyGroups
                {...props}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                showOnboarding={showOnboarding}
                setShowOnboarding={setShowOnboarding}
                showAccountProfile={showAccountProfile}
                setShowAccountProfile={setShowAccountProfile}
                showAccountPreferences={showAccountPreferences}
                setShowAccountPreferences={setShowAccountPreferences}
                showPlansBillingPanel={showPlansBillingPanel}
                setShowPlansBillingPanel={setShowPlansBillingPanel}
                showBillingDetailsPanel={showBillingDetailsPanel}
                setShowBillingDetailsPanel={setShowBillingDetailsPanel}
                newsDrawerVisible={newsDrawerVisible}
                setNewsDrawerVisible={setNewsDrawerVisible}
                showOnboardingOverlay={showOnboardingOverlay}
                setShowOnboardingOverlay={setShowOnboardingOverlay}
                setShowUnitPanel={setShowUnitPanel}
                showUnitPanel={showUnitPanel}
                showPortfolioSettings={showPortfolioSettings}
                setShowPortfolioSettings={setShowPortfolioSettings}
                setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                showOnboardingFullscreen={showOnboardingFullscreen}
              />
            )}
          />

          <Route
            exact
            path={`${props.match.url}/transactions`}
            render={(props) => (
              <Transactions
                {...props}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                showOnboarding={showOnboarding}
                setShowOnboarding={setShowOnboarding}
                showAccountProfile={showAccountProfile}
                setShowAccountProfile={setShowAccountProfile}
                showAccountPreferences={showAccountPreferences}
                setShowAccountPreferences={setShowAccountPreferences}
                showPlansBillingPanel={showPlansBillingPanel}
                setShowPlansBillingPanel={setShowPlansBillingPanel}
                showBillingDetailsPanel={showBillingDetailsPanel}
                setShowBillingDetailsPanel={setShowBillingDetailsPanel}
                newsDrawerVisible={newsDrawerVisible}
                setNewsDrawerVisible={setNewsDrawerVisible}
                showOnboardingOverlay={showOnboardingOverlay}
                setShowOnboardingOverlay={setShowOnboardingOverlay}
                setShowUnitPanel={setShowUnitPanel}
                showUnitPanel={showUnitPanel}
                setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                showOnboardingFullscreen={showOnboardingFullscreen}
              />
            )}
          />
          <Route exact path={`${props.match.url}/help-and-feedback`} component={HelpAndFeedback} />
          <Route exact path={`${props.match.url}/settings`} component={Settings} />
          <Route exact path={`${props.match.url}/development`} component={Development} />

          <Route
            exact
            path={`${props.match.url}/service-plans`}
            render={(props) => (
              <ServicePlans
                {...props}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                showOnboarding={showOnboarding}
                setShowOnboarding={setShowOnboarding}
                showAccountProfile={showAccountProfile}
                setShowAccountProfile={setShowAccountProfile}
                showAccountPreferences={showAccountPreferences}
                setShowAccountPreferences={setShowAccountPreferences}
                showPlansBillingPanel={showPlansBillingPanel}
                setShowPlansBillingPanel={setShowPlansBillingPanel}
                showBillingDetailsPanel={showBillingDetailsPanel}
                setShowBillingDetailsPanel={setShowBillingDetailsPanel}
                newsDrawerVisible={newsDrawerVisible}
                setNewsDrawerVisible={setNewsDrawerVisible}
                showOnboardingOverlay={showOnboardingOverlay}
                setShowOnboardingOverlay={setShowOnboardingOverlay}
                setShowUnitPanel={setShowUnitPanel}
                showUnitPanel={showUnitPanel}
                setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                showOnboardingFullscreen={showOnboardingFullscreen}
              />
            )}
          />
          <Route
            exact
            path={`${props.match.url}/screening`}
            render={(props) => (
              <Screening
                {...props}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                showOnboarding={showOnboarding}
                setShowOnboarding={setShowOnboarding}
                showAccountProfile={showAccountProfile}
                setShowAccountProfile={setShowAccountProfile}
                showAccountPreferences={showAccountPreferences}
                setShowAccountPreferences={setShowAccountPreferences}
                showPlansBillingPanel={showPlansBillingPanel}
                setShowPlansBillingPanel={setShowPlansBillingPanel}
                showBillingDetailsPanel={showBillingDetailsPanel}
                setShowBillingDetailsPanel={setShowBillingDetailsPanel}
                newsDrawerVisible={newsDrawerVisible}
                setNewsDrawerVisible={setNewsDrawerVisible}
                setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                showOnboardingFullscreen={showOnboardingFullscreen}
                setShowUnitPanel={setShowUnitPanel}
                showUnitPanel={showUnitPanel}
              />
            )}
          />
          <Route
            exact
            path={`${props.match.url}/financing`}
            render={(props) => (
              <Financing
                {...props}
                setShowOnboarding={setShowOnboarding}
                drawerVisible={drawerVisible}
                setDrawerVisible={setDrawerVisible}
                setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                showOnboardingFullscreen={showOnboardingFullscreen}
                showAccountProfile={showAccountProfile}
                setShowAccountProfile={setShowAccountProfile}
                setShowAccountPreferences={setShowAccountPreferences}
                showAccountPreferences={showAccountPreferences}
                showPlansBillingPanel={showPlansBillingPanel}
                setShowPlansBillingPanel={setShowPlansBillingPanel}
                showBillingDetailsPanel={showBillingDetailsPanel}
                setShowBillingDetailsPanel={setShowBillingDetailsPanel}
              />
            )}
          />
          <Route
            exact
            path={`${props.match.url}/legacy-onboarding`}
            render={() => {
              setShowOnboardingFullscreen(true);
              return (
                <Dashboard
                  {...props}
                  drawerVisible={drawerVisible}
                  setDrawerVisible={setDrawerVisible}
                  showOnboarding={showOnboarding}
                  setShowOnboarding={setShowOnboarding}
                  showAccountProfile={showAccountProfile}
                  setShowAccountProfile={setShowAccountProfile}
                  showAccountPreferences={showAccountPreferences}
                  setShowAccountPreferences={setShowAccountPreferences}
                  showPlansBillingPanel={showPlansBillingPanel}
                  setShowPlansBillingPanel={setShowPlansBillingPanel}
                  showBillingDetailsPanel={showBillingDetailsPanel}
                  setShowBillingDetailsPanel={setShowBillingDetailsPanel}
                  newsDrawerVisible={newsDrawerVisible}
                  setNewsDrawerVisible={setNewsDrawerVisible}
                  showOnboardingOverlay={showOnboardingOverlay}
                  setShowOnboardingOverlay={setShowOnboardingOverlay}
                  showUnitPanel={showUnitPanel}
                  setShowUnitPanel={setShowUnitPanel}
                  setShowOnboardingFullscreen={setShowOnboardingFullscreen}
                  showOnboardingFullscreen={showOnboardingFullscreen}
                />
              );
            }}
          />
        </AppLayout>
      </CustomLoadingOverlay>
    </FlagsmithProvider>
  );
};

export default Router;
