// @flow
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { Card as AntCard, Drawer, Spin } from 'antd';

import moment from 'moment';

import ReactJoyride from 'react-joyride';
import { usePlaidLink } from 'react-plaid-link';

import { getPlaidOauthLinkToken } from 'services/api/landlordApi/read';

import {
  getBillingItems,
  getPaymentMethods,
  getPaymentSettings,
  getScheduledPayments,
  checkFirstAchPayment,
  getPaymentMethodsHistory,
} from 'store/actions/tenantActions';
import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';
import { clearPanel } from 'store/actions/globalActions';
import { getTourStatus, getPropertyFees } from 'services/api/common/read';
import { updateTourStatus } from 'services/api/common/update';
import { addACHMethod } from 'services/wepay';
import * as tenantApi from 'services/api/tenantApi';

import { getBalanceDueWithPending } from 'resources/helpers';

// Icons
import { DollarCircleOutlined } from '@ant-design/icons';

// Components

import AlertFade from 'components/AlertFade';

import Title from 'components/Title';
import Text from 'components/Text';
import BottomCard from 'components/BottomCard';
import CardBlank from 'components/CardBlank';
import Button from 'components/Button';
import Space from 'components/Space';
import QuadpayIneligiblePanel from '../components/QuadpayIneligible';
import QuadpayEligible from '../components/QuadpayEligible';

import RecentTransactions from './components/RecentTransactions';
import UpcomingCharges from '../components/UpcomingCharges';
import PaymentMethods from '../components/PaymentMethods';
import MakePayment from '../Payment/MakePayment/MakePayment';
import PaymentStatus from '../Payment/PaymentStatus/PaymentStatus';
import PaymentSchedule from '../Payment/PaymentSchedule/PaymentSchedule';
import PaymentMethodDetail from '../components/PaymentMethods/components/PaymentMethodDetail';
import ComplianceAlert from '../components/ComplianceAlert';
import InsuranceAlert from '../components/InsuranceAlert/InsuranceAlert';
import RentCredStatusAnimation from '../components/RentCredStatusAnimation';
import { getDashboardSteps } from '../../../resources/tourSteps';
import AccountStatusTitle from '../components/AccountStatusTitle/AccountStatusTitle';
import SilaKyc from '../components/SilaKyc/SilaKyc';
import MicrodepositAlert from '../components/MicrodepositAlert';
import Lvble from 'components/Lvble/Lvble';
import LvbleProvider from 'components/Lvble/LvbleProvider';
import PaymentMethodsDrawer from '../components/PaymentMethodsDrawer/PaymentMethodsDrawer';
import PaymentMethodLinks from '../components/PaymentMethods/components/PaymentMethodLinks/PaymentMethodLinks';

const Dashboard = (props) => {
  const history = useHistory();
  const userData = useSelector((store) => store.session.userData, shallowEqual);
  let absorbFeesPercent = 100;
  let absorbFees = false;

  if (userData.property) {
    absorbFeesPercent = userData.property.paymentSettings.absorbFeesPercent;
    absorbFees = userData.property.paymentSettings.absorbFees;
  }

  const session = useSelector((store) => store.session);
  const panel = useSelector((store) => store.global.panel);
  const billingItems = useSelector((store) => store.tenant.billingItems);
  const scheduledPayments = useSelector((store) => store.tenant.scheduledPayments);
  const servicePlan = useSelector((store) => store.session.userData.servicePlan);
  const paymentMethods = useSelector((store) => store.tenant.paymentMethods);
  const paymentMethodsHistory = useSelector((store) => store.tenant.paymentMethodsHistory);
  const alert = useSelector((store) => store.global.alert);
  const globalLoading = useSelector((store) => store.global.loading);
  const userEmail = useSelector((state) => state.session.userData.email);
  const dispatch = useDispatch();
  const [paymentMethodSelection, setPaymentMethodSelection] = useState([]);
  const [balanceDue, setBalanceDue] = useState(true);
  const [otherAmount, setOtherAmount] = useState(0);
  const [paymentMethodsDrawerVisible, setPaymentMethodsDrawerVisible] = useState(false);
  const [showPaymentMethodDetail, setShowPaymentMethodDetail] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [payClicked, setPayClicked] = useState(false);
  const [showPaymentStatus, setShowPaymentStatus] = useState(false);
  const [showScheduledPayment, setShowScheduledPayment] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentError, setPaymentError] = useState([]);
  const [showRadioButtons, setShowRadioButtons] = useState(true);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [runTour, setRunTour] = useState(false);
  const [defPaymentMethod, setDefPaymentMethod] = useState(false);
  const [notification, setNotification] = useState(false);
  const [fromCompliance, setFromCompliance] = useState(false);
  const [showQuadpayIneligible, setShowQuadpayIneligible] = useState(false);
  const [showQuadpayEligible, setShowQuadpayEligible] = useState(false);
  const [quadpayRedirect, setQuadpayRedirect] = useState(null);

  const [showSilaKyc, setShowSilaKyc] = useState(false);

  const [additionalFees, setAdditionalFees] = useState(null);

  const [tourStatus, setTourStatus] = useState({ tourViewed: true });
  const [tourSteps, setTourSteps] = useState([]);
  const userDataLoaded = useRef(false);
  const [ontimePayments, setOntimePayments] = useState(false);
  const [showRentCredStep, setShowRentCredStep] = useState(false);
  const [quadpayResult, setQuadpayResult] = useState(null);
  const [silaOnboardingStatus, setSilaOnboardingStatus] = useState({ state: null });
  const [newPaymentMethodType, setNewPaymentMethodType] = useState('bank');

  const [plaidToken, setPlaidToken] = useState(null);

  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  const mountedRef = useRef(true);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const recentTransactionsRef = useRef(null);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  const updateRecentTransactions = () => {
    recentTransactionsRef.current.fetchData();
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const onSuccess = useCallback((token, metadata) => {
    const plaid = {
      token,
      metadata,
    };
    AddBankAccount(plaid);
  }, []);

  const onExit = () => {
    displayAlert('Payment Method Error', 'warning', 'Something went wrong while adding your payment method!');
  };

  // customizacion del css https://support.plaid.com/hc/en-us/articles/360008420353-Plaid-Link-customization

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
    onExit,
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  let timerId = null;

  useEffect(() => {
    const controller = new AbortController();
    checkQuadpayResult();
    fetchData(controller);

    return () => {
      controller.abort();
      if (timerId) clearInterval(timerId);
      mountedRef.current = false;
    };
  }, []);

  const checkQuadpayResult = async () => {
    if (history.location.pathname === '/tenant/quadpay/confirm') {
      const params = new URLSearchParams(history.location.search);
      if (params.get('success')) {
        setQuadpayResult({
          merchantId: params.get('merchantId'),
          orderId: params.get('orderId'),
          amount: params.get('amount'),
          merchantReference: params.get('merchantReference'),
          fee: params.get('fee'),
          success: true,
        });
        setDrawerVisible(true);
        setShowPaymentStatus(true);
      }
    }
    if (history.location.pathname === '/tenant/quadpay/cancel') {
      setQuadpayResult({
        cancelled: true,
      });
      setDrawerVisible(true);
      setShowPayment(true);
      const log = {
        message: 'quadpay checkout cancelled',
        userId: +session.userData.id,
        timestamp: Date.now(),
      };
      await tenantApi.postQuadpayError(session.cognitoTokens?.idToken?.jwtToken, log);
    }
  };

  const fetchData = async (controller) => {
    try {
      const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
      if (isOAuthRedirect) {
        setPlaidToken(localStorage.getItem('link_token'));
      } else {
        if (session.userData.paymentGateway !== 'Wepay') {
          const plaidOauthToken = await getPlaidOauthLinkToken(session.cognitoTokens.idToken.jwtToken);
          localStorage.setItem('link_token', plaidOauthToken.linkToken);
          setPlaidToken(plaidOauthToken.linkToken);
        }
      }

      dispatch({ type: 'GET_BILLING_ITEMS_LOADING' });
      dispatch({ type: 'SET_SESSION_LOADER', payload: true });
      dispatch(getServicePlanAction(cognitoToken));
      dispatch(getUserDataV3(cognitoToken, controller));
      dispatch(getBillingItems());
      dispatch(getScheduledPayments(cognitoToken));

      setRunTour(false);
      // check the onboarding status if it is a sila tenant

      if (!mountedRef.current) return null;
    } catch (e) {
      console.log(e);
    }
  };

  const openPanel = (value) => {
    switch (value) {
      case 'make-payment':
        setDrawerVisible(true);
        setShowPayment(true);
        break;
      case 'add-credit-card':
        setDrawerVisible(true);
        setShowPaymentMethodDetail(true);
        break;
      case 'scheduled-payments':
        setDrawerVisible(true);
        setShowScheduledPayment(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!userDataLoaded.current) {
      if (userData?.property?.id) {
        fetchDependencyData();
        dispatch(getPaymentMethodsHistory(userData.paymentGateway, true, session.cognitoTokens.idToken.jwtToken));
        fetchTourStatus();

        if (panel) {
          openPanel(panel);
          dispatch(clearPanel());
        }

        userDataLoaded.current = true;
      }
    }
    if (servicePlan.name) {
      const steps = getDashboardSteps(
        absorbFees,
        absorbFeesPercent,
        +servicePlan.achRateFee * 100,
        +servicePlan.creditCardRateFee * 100,
        servicePlan.creditCardTrxFee,
        isDesktop,
        false,
        true
      );
      setTourSteps(steps);
    }
  }, [userData, servicePlan]);

  const fetchTourStatus = async () => {
    try {
      const tourRes = await getTourStatus(session.cognitoTokens.idToken.jwtToken, session.userData.id);

      setTourStatus(tourRes);
      if (!mountedRef.current) return null;
    } catch (error) {
      displayAlert('Fetch Error', 'warning', error?.data?.errors[0].detail || 'Your request failed.');
    }
  };

  const fetchDependencyData = async () => {
    try {
      const propertyFeesRes = await getPropertyFees(session.cognitoTokens.idToken.jwtToken, userData.property.id);

      setAdditionalFees(propertyFeesRes);
      dispatch(getPaymentSettings(userData.property.id));
      // dispatch(checkFirstAchPayment());
      dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
      if (userData.paymentGateway === 'SilaStripe') {
        const status = await tenantApi.getTenantBoardingStatus(session.cognitoTokens.idToken.jwtToken);
        setSilaOnboardingStatus(status);
      }

      if (!mountedRef.current) return null;
    } catch (e) {
      console.log(e);
    }
  };

  const displayAlert = (message, type, description) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    setTimeout(() => {
      dispatch({
        type: 'UNSET_ALERT',
      });
    }, 5000);
  };

  const isLoading = (value) => {
    dispatch({
      type: 'SET_LOADER',
      payload: value,
    });
  };

  const togglePaymentMethods = () => {
    setPaymentMethodsDrawerVisible(!paymentMethodsDrawerVisible);
    setDefPaymentMethod(true);
  };

  const defaultPaymentMethod = paymentMethods.length
    ? paymentMethods.filter((p) => p.defaultMethod === true)[0]
    : false;

  const getBalance = (data) => {
    const account = data.billingAccounts.filter((bi) => bi.state === 'ACTIVE');
    if (account.length) {
      const newBalanceDue = getBalanceDueWithPending(account[0].balance, billingItems.processing);
      return newBalanceDue;
    }

    return 0;
  };

  function hasBalanceCredit(data) {
    return getBalance(data) < 0;
  }

  const AddBankAccount = async (plaid) => {
    try {
      if (userData.paymentGateway === 'Wepay') {
        const microDepositPm = paymentMethodsHistory.filter(
          (p) =>
            (p.microDepositsFlow === true && p.paymentGatewayMeta?.state === 'pending') ||
            (p.microDepositsFlow === true && p.state === 'PENDING') ||
            (p.microDepositsFlow === true && p.state === 'SCHEDULED')
        );

        const disableMicrodeposits = microDepositPm.length > 0;

        const paymentMethod = await addACHMethod(userEmail, disableMicrodeposits); // wepay window

        if (paymentMethod === null) {
          displayAlert(
            'Payment Method Error',
            'warning',
            'Something went wrong while adding your payment method!'
          );
          return;
        }
        // comparar mask y si no coinciden cortar la ejecucion
        if (plaid) {
          const filter = plaid.metadata.accounts.filter(
            (a) => a.mask === paymentMethod.metaData.TOKENIZATION.bank_account_last_four
          );
          if (!filter.length) throw new Error('Bank accounts dont match');
        }
        let alertTitle = 'Payment Method Added';
        let alertType = 'success';
        let description = '';
        let microdeposits = false;

        if (paymentMethod.flow === 'microdeposits') {
          microdeposits = true;
          alertTitle = 'Payment Method Requires Verification';
          alertType = 'warning';
          description =
            'We have sent 2 microdeposits to your account to verify ownership. Please check your email for details and follow the instructions to confirm this payment method.';
        }

        delete paymentMethod.flow;

        const res = await tenantApi.createPaymentMethod(paymentMethod);
        const pmethodId = res.id;
        const pm = {
          id: pmethodId,
          paymentGatewayMeta: {
            account_last_four: paymentMethod.metaData.TOKENIZATION.bank_account_last_four,
          },
        };
        if (plaid) {
          await tenantApi.savePlaidToken(session.cognitoTokens.idToken.jwtToken, pm, plaid);
        }

        dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
        dispatch(getPaymentMethodsHistory(userData.paymentGateway, true, session.cognitoTokens.idToken.jwtToken));
        displayAlert(alertTitle, alertType, description);
        if (!microdeposits) checkPaymentMethodStatus();
      }
      if (userData.paymentGateway === 'Nuvei' || userData.paymentGateway === 'SilaStripe') {
        isLoading(true);
        await tenantApi.createACHPaymentMethod(
          session.cognitoTokens.idToken.jwtToken,
          plaid,
          userData.paymentGateway
        );
        dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
        isLoading(false);
        displayAlert('Payment Method Added', 'success', '');
      }
    } catch (err) {
      displayAlert('Payment Method Error', 'warning', 'Something went wrong while adding your payment method!');
    }
  };

  const pollCheck = (pMethods) => {
    const filtered =
      pMethods.filter(
        (p) =>
          p &&
          p.hasOwnProperty('state') &&
          p.hasOwnProperty('microDepositsFlow') &&
          ((!p.microDepositsFlow && p.state === 'PENDING') || p.state === 'SCHEDULED')
      ).length > 0;
    return filtered;
  };

  const checkPaymentMethodStatus = async () => {
    const updated = dispatch(
      getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken)
    );
    const arr = updated?.payload ? updated.payload : [];
    const poll = pollCheck(arr);
    if (poll) {
      if (timerId !== '') clearInterval(timerId);
      timerId = setInterval(async () => {
        try {
          const methods = await tenantApi.getPaymentMethodsV3(
            userData.paymentGateway,
            false,
            session.cognitoTokens.idToken.jwtToken
          );

          const update = pollCheck(methods);
          if (!update) {
            dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
            clearInterval(timerId);
          }
        } catch (error) {
          console.log(error);
        }
      }, 3000);
    }
  };

  const handleJoyrideCallback = async (data) => {
    const { status, type, action, lifecycle, index, controlled } = data;
    if (index === 2 && showRentCredStep && lifecycle === 'complete' && (action === 'close' || action === 'skip')) {
      setShowRentCredStep(false);
    }
    if ((status === 'finished' && lifecycle === 'complete') || action === 'close') {
      try {
        await updateTourStatus(session.cognitoTokens.idToken.jwtToken, session.userData.id, {
          tourViewed: true,
          step: 1,
        });
        await fetchTourStatus();
      } catch (e) {
        displayAlert('Update Error', 'warning', e?.data?.errors[0].detail || 'Your request failed.');
      }
    }
  };

  const getDiscountDate = () => {
    const today = new Date();
    const rentDay = userData.property.rentDueDay;

    let date = '';
    const currentDay = today.getDate();

    if (+currentDay >= +rentDay) {
      date = moment(`${today.getMonth() + 1}-${rentDay}-${today.getFullYear()}`, 'MM-DD-YYYY')
        .add(1, 'M')
        .format('MM-DD-YYYY');
    } else {
      date = moment(`${today.getMonth() + 1}-${rentDay}-${today.getFullYear()}`, 'MM-DD-YYYY').format(
        'MM-DD-YYYY'
      );
    }

    const discountDate = moment(date, 'MM-DD-YYYY')
      .subtract(additionalFees.discountDays, 'days')
      .format('MM-DD-YYYY');
    return discountDate;
  };

  const showPaymentActions = () => {
    setShowPayment(true);
    setPayClicked(true);
    setShowPaymentMethodDetail(false);
    setDrawerVisible(true);
    setDefPaymentMethod(false);
    setShowPaymentStatus(false);
  };

  return (
    <div id="dashboard-container" display={{ position: 'relative' }}>
      <StyledDrawer
        closable={false}
        placement="right"
        destroyOnClose
        onClose={async () => {
          setBalanceDue(true);
          setPaymentData(null);
          setPaymentError([]);
          setShowPayment(false);
          setPayClicked(false);
          setShowScheduledPayment(false);
          setShowPaymentStatus(false);
          setDrawerVisible(false);
          setShowPaymentMethodDetail(false);
          setPaymentMethodId(null);
          setNotification(false);
          setFromCompliance(false);
          setQuadpayResult(null);
          setShowQuadpayIneligible(false);
          setShowQuadpayEligible(false);
          dispatch(checkFirstAchPayment());
          const propertyFeesRes = await getPropertyFees(
            session.cognitoTokens.idToken.jwtToken,
            userData.property.id
          );
          setAdditionalFees(propertyFeesRes);
        }}
        open={drawerVisible}
        getContainer={false}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {showPayment && (
          <MakePayment
            setDrawerVisible={setDrawerVisible}
            setPaymentData={setPaymentData}
            setShowPayment={setShowPayment}
            setPayClicked={setPayClicked}
            setShowScheduledPayment={setShowScheduledPayment}
            setShowPaymentStatus={setShowPaymentStatus}
            setPaymentMethodSelection={setPaymentMethodSelection}
            setPaymentError={setPaymentError}
            setShowPaymentMethodDetail={setShowPaymentMethodDetail}
            scheduledPayments={scheduledPayments}
            balanceDue={balanceDue}
            setBalanceDue={setBalanceDue}
            otherAmount={otherAmount}
            setOtherAmount={setOtherAmount}
            checkPaymentMethodStatus={checkPaymentMethodStatus}
            absorbFeesPercent={userData.property ? userData.property.paymentSettings.absorbFeesPercent : ''}
            additionalFees={additionalFees}
            timerId={timerId}
            setShowQuadpayIneligible={setShowQuadpayIneligible}
            quadpayResult={quadpayResult}
            setShowQuadpayEligible={setShowQuadpayEligible}
            setQuadpayRedirect={setQuadpayRedirect}
            setNewPaymentMethodType={setNewPaymentMethodType}
            silaOnboardingStatus={silaOnboardingStatus}
            updateRecentTransactions={updateRecentTransactions}
            data-testid="paymentComponent"
          />
        )}
        {showPaymentStatus && (
          <PaymentStatus
            paymentData={paymentData}
            paymentError={paymentError}
            paymentMethodSelection={paymentMethodSelection}
            setShowPaymentMethodDetail={setShowPaymentMethodDetail}
            setPayClicked={setPayClicked}
            setShowPayment={setShowPayment}
            setShowPaymentStatus={setShowPaymentStatus}
            setDrawerVisible={setDrawerVisible}
            setPaymentError={setPaymentError}
            AddBankAccount={AddBankAccount}
            quadpayResult={quadpayResult}
            setQuadpayResult={setQuadpayResult}
          />
        )}

        {showScheduledPayment && (
          <PaymentSchedule
            notification={notification}
            showScheduledPayment={showScheduledPayment}
            setShowPayment={setShowPayment}
            setShowScheduledPayment={setShowScheduledPayment}
            setDrawerVisible={setDrawerVisible}
            paymentMethodSelection={paymentMethodSelection}
            setNotification={setNotification}
            balanceDue={balanceDue}
            otherAmount={otherAmount}
            fromCompliance={fromCompliance}
            setFromCompliance={setFromCompliance}
          />
        )}
        {showPaymentMethodDetail && (
          <PaymentMethodDetail
            setDrawerVisible={setDrawerVisible}
            setShowPaymentMethodDetail={setShowPaymentMethodDetail}
            newPaymentMethodType={newPaymentMethodType}
            paymentMethodsDrawerVisible={paymentMethodsDrawerVisible}
            AddBankAccount={AddBankAccount}
            setShowPayment={setShowPayment}
            payClicked={payClicked}
            paymentMethodId={paymentMethodId}
            checkPaymentMethodStatus={checkPaymentMethodStatus}
            plaidOpen={open}
            defPaymentMethod={defPaymentMethod}
            setDefPaymentMethod={setDefPaymentMethod}
          />
        )}
        {showQuadpayIneligible && (
          <QuadpayIneligiblePanel
            setDrawerVisible={setDrawerVisible}
            setShowQuadpayIneligible={setShowQuadpayIneligible}
            setShowPayment={setShowPayment}
          />
        )}
        {showQuadpayEligible && (
          <QuadpayEligible
            setShowPayment={setShowPayment}
            setDrawerVisible={setDrawerVisible}
            setShowQuadpayEligible={setShowQuadpayEligible}
            quadpayRedirect={quadpayRedirect}
          />
        )}
        {showSilaKyc && (
          <SilaKyc
            setShowSilaKyc={setShowSilaKyc}
            setDrawerVisible={setDrawerVisible}
            setShowPayment={setShowPayment}
            silaOnboardingStatus={silaOnboardingStatus}
            setSilaOnboardingStatus={setSilaOnboardingStatus}
          />
        )}
      </StyledDrawer>
      <ReactJoyride
        stepIndex={!ontimePayments && showRentCredStep ? 2 : undefined}
        steps={
          !ontimePayments && showRentCredStep ? getDashboardSteps(false, 1, 2, 2, 2, 1, true, false) : tourSteps
        }
        continuous
        run={!tourStatus.tourViewed || showRentCredStep}
        scrollToFirstStep
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 20,
          },
          buttonNext: {
            display: showRentCredStep && 'none',
          },
        }}
      />
      <CardBlank title={<AccountStatusTitle userData={userData} />}>
        {globalLoading && (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        )}

        <AlertFade
          isVisible={alert.isVisible}
          message={alert.message}
          description={alert.description}
          type={alert.type}
          display
          closable={alert.closable}
          marginTop={10}
          onClose={() => {
            dispatch({
              type: 'UNSET_ALERT',
            });
          }}
        />
        <MicrodepositAlert />
        <InsuranceAlert />
        <ComplianceAlert
          setDrawerVisible={setDrawerVisible}
          setShowScheduledPayment={setShowScheduledPayment}
          togglePaymentMethods={togglePaymentMethods}
          setNotification={setNotification}
          setFromCompliance={setFromCompliance}
        />
      </CardBlank>
      <Space vertical={10} />
      <RentCredStatusAnimation setShowRentCredStep={setShowRentCredStep} />
      <Space vertical={10} />
      <LvbleProvider>
        <Lvble />
      </LvbleProvider>
      <Space vertical={10} />
      <AntCard
        id="accountStatus"
        title="Account Balance"
        items={[
          {
            text: 'Edit Payment Method',
            link: '#',
          },
          {
            text: 'Request Adjustment',
            link: '#',
          },
        ]}
        styles={{
          header: {
            fontSize: '20px',
            color: '#541388',
            fontWeight: 600,
          },
        }}
      >
        {userData && userData.billingAccounts?.length ? (
          <AccountStatusNumber data-testid="dueAmount" className="balance" hasInFavor={hasBalanceCredit(userData)}>
            ${getBalance(userData)}
            <small>{getBalance(userData) >= 0 ? 'DUE' : 'CREDIT'}</small>
          </AccountStatusNumber>
        ) : (
          <AccountStatusNumber hasInFavor={false} data-testid="dueAmount" className="balance">
            $0.00
            <small>DUE</small>
          </AccountStatusNumber>
        )}
        <CardText>
          <Text size={16} color="#122c34" centered>
            Not including upcoming charges and credits
          </Text>
        </CardText>
        <Space vertical={20} />
        {additionalFees?.discount && (
          <>
            <AlertFade
              isVisible={true}
              message={`Save $${additionalFees.discountAmount} if you pay by ${getDiscountDate()}`}
              type="success"
            />
            <Space vertical={20} />
          </>
        )}

        <Button
          type="primary"
          size="large"
          alignment="center"
          onClick={async (e) => {
            e.preventDefault();
            // silaOnboardingStatus.state === 'DOCUMENTS_REQUIRED' || DOCUMENTS_RECEIVED
            if (session.userData.paymentGateway === 'SilaStripe') {
              if (session.userData.property.commercial || session.userData.property.rentAmount >= 7000) {
                if (
                  !session.userData.ssnToken ||
                  silaOnboardingStatus.state === 'DOCUMENTS_REQUIRED' ||
                  silaOnboardingStatus.state === 'REVIEW'
                ) {
                  // check if docs are required also
                  // Docs required or Received
                  setShowSilaKyc(true);
                  setDrawerVisible(true);

                  // setShowPayment(false);
                  // setPayClicked(true);
                  // setShowPaymentMethodDetail(false);
                  // setDrawerVisible(true);
                  // setDefPaymentMethod(false);
                  // setShowPaymentStatus(true);
                } else {
                  showPaymentActions();
                }
              } else {
                showPaymentActions();
              }
            }

            if (session.userData.paymentGateway === 'Wepay' || session.userData.paymentGateway === 'Finix') {
              showPaymentActions();
            }

            const propertyFeesRes = await getPropertyFees(
              session.cognitoTokens.idToken.jwtToken,
              userData.property.id
            );
            setAdditionalFees(propertyFeesRes);
          }}
          disabled={
            paymentMethods.length === 0 ||
            !userData.property ||
            (session.userData.paymentGateway === 'SilaStripe' && !silaOnboardingStatus)
          }
          data-testid="payActionTest"
          className="payButton"
        >
          PAY
          <DollarCircleOutlined />
        </Button>

        <Space vertical={20} />

        <Button
          data-name="toggle-bottom-card"
          type={defaultPaymentMethod ? 'link' : 'button'}
          alignment="center"
          onClick={togglePaymentMethods}
          className="paymentMethod"
          color={!defaultPaymentMethod && 'violet'}
        >
          {defaultPaymentMethod ? `using ${defaultPaymentMethod.label}` : 'Add a default payment method'}
        </Button>

        <Space vertical={20} />
      </AntCard>
      <Space vertical={10} />
      <AntCard
        title="Upcoming Charges and Credits"
        styles={{
          header: {
            fontSize: '14px',
            color: '#541388',
            fontWeight: 600,
          },
        }}
        className="upcomingCharges"
      >
        <UpcomingCharges upcomingBillingItems={billingItems.delayed} loading={billingItems.loading} />
      </AntCard>
      <Space vertical={10} />
      <Space vertical={10} />
      <RecentTransactions ref={recentTransactionsRef} />
      <PaymentMethodsDrawer
        open={paymentMethodsDrawerVisible}
        onClose={() => setPaymentMethodsDrawerVisible(false)}
        title={
          <Title level={4} color="black" bottom={20} centered>
            Payment Methods
          </Title>
        }
      >
        <PaymentMethods
          paymentMethodsDrawerVisible={paymentMethodsDrawerVisible}
          setPaymentMethodsDrawerVisible={setPaymentMethodsDrawerVisible}
          showPaymentMethodDetail={showPaymentMethodDetail}
          setShowPaymentMethodDetail={setShowPaymentMethodDetail}
          setDrawerVisible={setDrawerVisible}
          setShowPayment={setShowPayment}
          showRadioButtons={showRadioButtons}
          setPaymentMethodId={setPaymentMethodId}
          defPaymentMethod={defPaymentMethod}
          setDefPaymentMethod={setDefPaymentMethod}
        />
        {}
        <PaymentMethodLinks
          paymentMethodsDrawerVisible={paymentMethodsDrawerVisible}
          setPaymentMethodsDrawerVisible={setPaymentMethodsDrawerVisible}
          showPaymentMethodDetail={showPaymentMethodDetail}
          setShowPaymentMethodDetail={setShowPaymentMethodDetail}
          setDrawerVisible={setDrawerVisible}
          setShowPayment={setShowPayment}
          showRadioButtons={showRadioButtons}
          setPaymentMethodId={setPaymentMethodId}
          defPaymentMethod={defPaymentMethod}
          setDefPaymentMethod={setDefPaymentMethod}
        />
      </PaymentMethodsDrawer>
    </div>
  );
};

const CardText = styled.span`
  font-size: 14px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : '#122C34')};
  display: ${(props) => (props.inline ? 'inline-block' : '')};
  margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}px` : '')};
  @media screen and (max-width: 575px) {
    &&&&&&&&&&& {
      font-size: 12px !important;
    }
  }
`;

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
`;

const AccountStatusNumber = styled.span`
  color: ${(props) => (props.hasInFavor ? '#85BB65' : 'red')};
  font-size: 48px;
  align-items: baseline;
  justify-content: center;
  display: flex;

  small {
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;

      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
    .ant-drawer-content::-webkit-scrollbar {
      display: none;
    }
    .ant-drawer-content::-webkit-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  `}
`;

export default Dashboard;
