// @flow
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import parser from 'parse-address';
import { usStates } from 'resources/FormData';
import { v4 } from 'uuid';

import { Form, Spin } from 'antd';

import { createProperty } from 'services/api/landlordApi/create';
import { getRentersV3 } from 'services/api/landlordApi/read';

// Components
import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';
import SpaceResponsive from 'components/SpaceResponsive';
import Button from 'components/Button';
import Text from 'components/Text';
import FormFieldSet from './components/FormFieldSet';

// import './styles.scss';

const PropertiesForm = (props) => {
  const { loading, setDrawerVisible, setShowOnboarding } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);

  const [locationData, setLocationData] = useState();
  const [addressInput, setAddressInput] = useState('');
  const [form] = Form.useForm();
  const [rentDay, setRentDay] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'success', message: '' });
  const oldSystemToken = useSelector((state) => state.session.oldTokens.token);
  const cognitoToken = useSelector((state) => state.session.cognitoTokens.idToken.jwtToken);
  const upgradeDowngrade = useSelector((store) => store.landlord.upgradeDowngrade);
  const [googleString, setGoogleString] = useState(null);
  const googleSelected = useRef(null);
  const customAddressInput = useRef(null);

  const onFinish = async (v) => {
    try {
      setIsLoading(true);
      const complianceLevel = false;
      const values = {
        acceptCreditCard: session.userData.paymentSettings.acceptCreditCard,
        absorbFees: session.userData.paymentSettings.absorbFees,
        preventPayment: session.userData.paymentSettings.preventPayments,
        fullPayment: session.userData.paymentSettings.requireFullPayment,
        unit: v.unit,
        rentDay: v.rentDate,
        rentAmount: v.rentAmount,
      };

      await createProperty(locationData, values, complianceLevel);

      setMessage({
        type: 'success',
        message: 'Property added!',
      });
      const onBoardStatus = await props.getOnboardingStatus(cognitoToken);
      const renters = await getRentersV3(cognitoToken, 1, 10, '', '');
      if (renters.items.length) {
        onBoardStatus.step2.status = 'finished';
      }
      dispatch({
        type: 'SET_ONBOARDING_STATUS',
        payload: onBoardStatus,
      });

      setTimeout(async () => {
        setMessage({ message: '' });
        if (upgradeDowngrade.servicePlanId) {
          dispatch({
            type: 'SET_UPGRADE_DOWNGRADE',
            payload: {
              servicePlanId: upgradeDowngrade.servicePlanId,
              boardedApps: upgradeDowngrade.boardedApps,
              status: {
                ...upgradeDowngrade.status,
                step1: {
                  ...upgradeDowngrade.status.step1,
                  status: 'finished',
                },
              },
            },
          });
        } else {
          props.setBoardingStatus(onBoardStatus);
        }

        setIsLoading(false);
        setDrawerVisible(false);
        setShowOnboarding(false);
        props.setCurrentStep(2);
      }, 2000);
    } catch (err) {
      setIsLoading(false);

      setMessage({
        type: 'error',
        message: err.response?.data?.errors[0].title || 'Request failed',
      });
      setTimeout(() => {
        setMessage({ message: '' });
      }, 2500);
    }
  };

  const handleSelect = (value) => {
    setRentDay(value);
  };

  const handlePlacesAutocomplete = (data) => {
    setLocationData(data);
    googleSelected.current = true;
    form.validateFields(['address']);
  };

  const onValuesChange = (data) => {};

  const addressValidator = () => {
    if (!locationData) {
      return Promise.reject('Please select the street address.');
    }
    // if (!addressInput.includes(locationData.address)) {
    //   return Promise.reject('Please select the street address.');
    // }
    if (addressInput.trim().length === 0) {
      return Promise.reject('Please select the street address.');
    }
    return Promise.resolve();
  };

  const check = (obj, arr) => {
    return arr.every((e) => Object.keys(obj).includes(e));
  };

  useEffect(() => {
    if (!addressInput && (googleSelected.current || customAddressInput.current)) {
      setLocationData(null);
      googleSelected.current = false;
      customAddressInput.current = false;
      form.validateFields(['address']);
    }
    if (addressInput && addressInput !== googleString) {
      const addr = parser.parseLocation(addressInput);
      if (addr) {
        const validKeyNames = ['city', 'number', 'state', 'street', 'zip'];

        const validAddr = check(addr, validKeyNames);
        if (validAddr) {
          const usState = usStates.filter((s) => s.abbreviation === addr.state.toUpperCase());
          const customAddress = {
            streetNumber: addr.number,
            address: addr.type ? `${addr.street} ${addr.type}` : addr.street,
            city: addr.city,
            state: usState[0].name.charAt(0).toUpperCase() + usState[0].name.slice(1).toLowerCase(),
            google_place_id: v4(),
            lat: 0.0,
            long: 0.0,
            zip: addr.zip,
            country: 'United States',
          };
          setLocationData({ ...customAddress });
          customAddressInput.current = true;
        } else {
          customAddressInput.current = false;
          setLocationData(null);
          form.validateFields(['address']);
        }
      }
    }
  }, [addressInput]);

  useEffect(() => {
    if (customAddressInput.current || googleSelected.current) {
      form.validateFields(['address']);
    }
  }, [locationData]);

  return (
    <StyledForm form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
      <ContentContainer className="content-container">
        {/**<Steps onboardingData={onboardingData} stepTitle={stepTitle} stepIcon={stepIcon} stepStatus={stepStatus} /> */}

        {loading ? (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        ) : (
          <>
            <SpaceResponsive lg={20} xmd={20} md={15} sm={15} />
            <Text size={12} color="black" centered margin="0 auto" width={320}>
              Begin typing the address of a property then select from the list of known addresses.
            </Text>
            <SpaceResponsive lg={15} xmd={15} md={15} sm={10} />
            <FormFieldSet
              groupId={1}
              handlePlacesAutocomplete={handlePlacesAutocomplete}
              handleSelect={handleSelect}
              setAddressInput={setAddressInput}
              setGoogleString={setGoogleString}
              addressValidator={addressValidator}
            />
            <SpaceResponsive lg={10} xmd={10} md={10} sm={10} />
            <Text color="black" size={12} centered>
              You can add more properties later.
            </Text>
          </>
        )}
      </ContentContainer>
      <PanelFooter className="panel-footer">
        <AlertFade
          type={message.type}
          isVisible={!!message.message}
          message={message.message}
          alertPosition="absolute"
          position="absolute"
          minHeight="80"
          // width={80}
        />

        <StyledItem className="form-item-button">
          <div style={{ 'text-align': 'center' }}>
            <Button
              type="primary"
              data-testid="buttonTest"
              htmlType="submit"
              color="violet"
              loading={isLoading}
              alignment="center"
              fontSize={14}
              bottom={0}
            >
              SAVE
            </Button>
          </div>
        </StyledItem>
      </PanelFooter>
    </StyledForm>
  );
};

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 116px);
`;
const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  padding: 0px 30px;
  margin: 0 auto;
  min-height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default PropertiesForm;
