import { ConfigProvider } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPropertyGroups } from 'services/api/landlordApi/read';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';
import { ReactComponent as PaySvg } from 'assets/empty-table-images/Pay.svg';
import EmptyLarge from 'components/EmptyLarge/EmptyLarge';

const TransactionsTable = (props) => {
  const {
    transactions,
    handleTableChange,
    itemType,
    currentPage,
    truncateString,
    handleClickDelete,
    deleteText,
    setDeleteText,
    dropdownVisible,
    setDropdownVisible,
    resetDropdown,
    onExpand,
    onRow,
    expandedKeys,
    handleMobileFilter,
    currentTypeFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    typeFilterMenu,
    renterFilterMenu,
    handleDateRangeFilter,
    handleResetDateRangeFilter,
    statusFilterMenu,
    dateFilterMenu,
    setDrawerVisible,
    setShowTransactionPanel,
    portfolioFilterMenu,
    handleRefund,
    setCurrentPage,
    loadSettlements,
    settlements,
    settleExpandedKeys,
    setSettleExpandedKeys,
    setSettleSelectedRows,
    handleSettlementSelect,
    setExpandedKeys,
    onSettleExpand,
    settlePage,
    handleSettleTableChange,
    currentPageRef,
    activeTab,
    onTabClick,
    settleStartDate,
    setSettleStartDate,
    settleEndDate,
    setSettleEndDate,
    setSettlePage,
    settlePageRef,
    handleSettleDateRangeFilter,
    handleSettleResetDateRangeFilter,
    settleDateFilterMenu,
    handleSettleSearch,
    handleSettleReset,
    setSettleSearchText,
    settleSearchText,
    searchedColumn,
  } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [propertyGroupsMapped, setPropertyGroupsMapped] = useState([]);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 576);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    // get property groups
    fetchPropertyGroups();
  }, []);

  const fetchPropertyGroups = async () => {
    try {
      const pg = await getPropertyGroups(cognitoToken, 1, 10000, null);

      const propertyGroupsFilter = pg.items.map((p) => {
        return { text: p.name, value: +p.id };
      });

      setPropertyGroupsMapped(propertyGroupsFilter);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ConfigProvider
        renderEmpty={() => (
          <EmptyLarge
            description="No transactions yet but all payments, credits and charges for your account will appear here."
            image={<PaySvg />}
          />
        )}
      >
        {isDesktop ? (
          <DesktopTable
            handleDateRangeFilter={handleDateRangeFilter}
            handleResetDateRangeFilter={handleResetDateRangeFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            transactions={transactions}
            handleTableChange={handleTableChange}
            itemType={itemType}
            currentPage={currentPage}
            truncateString={truncateString}
            handleClickDelete={handleClickDelete}
            deleteText={deleteText}
            setDeleteText={setDeleteText}
            dropdownVisible={dropdownVisible}
            setDropdownVisible={setDropdownVisible}
            resetDropdown={resetDropdown}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            typeFilterMenu={typeFilterMenu}
            renterFilterMenu={renterFilterMenu}
            statusFilterMenu={statusFilterMenu}
            dateFilterMenu={dateFilterMenu}
            setDrawerVisible={setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
            propertyGroupsMapped={propertyGroupsMapped}
            portfolioFilterMenu={portfolioFilterMenu}
            handleRefund={handleRefund}
            setCurrentPage={setCurrentPage}
            loadSettlements={loadSettlements}
            settlements={settlements}
            settleExpandedKeys={settleExpandedKeys}
            setSettleExpandedKeys={setSettleExpandedKeys}
            setSettleSelectedRows={setSettleSelectedRows}
            handleSettlementSelect={handleSettlementSelect}
            setExpandedKeys={setExpandedKeys}
            onSettleExpand={onSettleExpand}
            settlePage={settlePage}
            handleSettleTableChange={handleSettleTableChange}
            currentPageRef={currentPageRef}
            activeTab={activeTab}
            onTabClick={onTabClick}
            settleStartDate={settleStartDate}
            setSettleStartDate={setSettleStartDate}
            settleEndDate={settleEndDate}
            setSettleEndDate={setSettleEndDate}
            setSettlePage={setSettlePage}
            settlePageRef={settlePageRef}
            handleSettleDateRangeFilter={handleSettleDateRangeFilter}
            handleSettleResetDateRangeFilter={handleSettleResetDateRangeFilter}
            settleDateFilterMenu={settleDateFilterMenu}
            handleSettleSearch={handleSettleSearch}
            handleSettleReset={handleSettleReset}
            setSettleSearchText={setSettleSearchText}
            settleSearchText={settleSearchText}
            searchedColumn={searchedColumn}
          />
        ) : (
          <MobileTable
            transactions={transactions}
            itemType={itemType}
            handleTableChange={handleTableChange}
            currentPage={currentPage}
            truncateString={truncateString}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            setDrawerVisible={setDrawerVisible}
            setShowTransactionPanel={setShowTransactionPanel}
            loadSettlements={loadSettlements}
            settlements={settlements}
            settleExpandedKeys={settleExpandedKeys}
            setSettleExpandedKeys={setSettleExpandedKeys}
            setSettleSelectedRows={setSettleSelectedRows}
            handleSettlementSelect={handleSettlementSelect}
            setExpandedKeys={setExpandedKeys}
            onSettleExpand={onSettleExpand}
            settlePage={settlePage}
            handleSettleTableChange={handleSettleTableChange}
            currentPageRef={currentPageRef}
            activeTab={activeTab}
            onTabClick={onTabClick}
          />
        )}
      </ConfigProvider>
    </>
  );
};

//ant-card-head

export default TransactionsTable;
