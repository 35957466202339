import { Collapse } from 'antd';
import Text from 'components/Text/Text';
import React from 'react';
import styled from 'styled-components';
function MigrationFaqs() {
  const items = [
    {
      key: '1',
      label: 'Why do I need to change?',
      children: (
        <p>
          Our current processor is sunsetting their platform and will no longer process payments. PayRent has
          established a new processing relationship with several added benefits for our customers.
        </p>
      ),
    },
    {
      key: '2',
      label: 'Will I need to re-add my tenants',
      children: <p>No. Tenants will remain in your account along with their entire transaction history.</p>,
    },
    {
      key: '3',
      label: 'Will tenants need to add new payment methods?',
      children: (
        <p>
          Yes. Once your new account is active, tenants will need to re-add their payment method and any scheduled
          payments. We will notify them via email when you have made the transition and help them through the
          process.
        </p>
      ),
    },
  ];
  return (
    <StyledDiv className="mgration-faqs-container">
      <Text as="div" color="black" size={24} strong>
        Frequently Asked Questions
      </Text>
      <Collapse items={items} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  margin: 24px auto;
`;

export default MigrationFaqs;
