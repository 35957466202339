// @flux
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Radio, Spin } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Text from 'components/Text';
import VerifyPaymentMethod from '../../../VerifyPaymentMethod/VerifyPaymentMethod';
import IconButton from 'components/uielements/IconButton/IconButton';

const PaymentMethodItem = (props) => {
  // console.log('[PaymentMethodItem.js]', props);
  const { item, isDeleting, index, showRadioButtons, deleteItem } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const displayAlert = (message, type, description) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    setTimeout(() => {
      dispatch({
        type: 'UNSET_ALERT',
      });
    }, 5000);
  };

  const label = item.paymentGatewayMeta.payment_method_name
    ? item.paymentGatewayMeta.payment_method_name
    : item.label;

  return (
    <ItemContainer data-testid="itemLabelTest">
      {showRadioButtons ? (
        <Spin spinning={loading} size="small">
          <Radio value={item.id}>
            {label} <Spin spinning={props.isLoading} indicator={<LoadingOutlined style={{ fontSize: 20 }} />} />
            {item?.microDepositsFlow && item.paymentMethodType === 'ACH' && item?.state === 'SCHEDULED' && (
              <>
                {' - '}
                <Text size={16} color="grey">
                  Validating ...{' '}
                </Text>

                <VerifyPaymentMethod
                  item={item}
                  displayAlert={displayAlert}
                  paymentMethods
                  setLoading={setLoading}
                />
              </>
            )}
          </Radio>
        </Spin>
      ) : (
        <Spin spinning={loading} size="small">
          <LabelContainer>
            {label}
            {item?.microDepositsFlow && item.paymentMethodType === 'ACH' && item?.state === 'SCHEDULED' && (
              <>
                {' - '}
                <Text size={16} color="grey">
                  Needs verification...{' '}
                </Text>
                {/* removed until plaid is used directly */}
                {/* <VerifyPaymentMethod
                  item={item}
                  displayAlert={displayAlert}
                  paymentMethods
                  setLoading={setLoading}
                /> */}
              </>
            )}
          </LabelContainer>
        </Spin>
      )}
      <IconButton
        showPopconfirm
        loading={isDeleting.id === item.id}
        popTitle="Really Delete?"
        toolTitle="Delete Payment Method"
        icon={<DeleteOutlined />}
        handleClick={() => deleteItem(item.id)}
        data-testid={`moreButtonTest${index}`}
      />
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  span {
    font-size: 14px;
  }
`;

const LabelContainer = styled.div`
  position: relative;
`;

export default PaymentMethodItem;
