import styled from 'styled-components';
import { Card as AntCard } from 'antd';

export const StyledLvbleCard = styled(AntCard)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ant-card-head-title {
    padding-top: 5px;
    padding-bottom: 0px;
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

export const StyledLvbleContainer = styled.div`
  min-height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledLoadingContainer = styled.div`
  background: #fff;
  min-height: 164px;
  border-radius: 2px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;
