import * as actions from 'store/actions';

const initialState = {
  billingItems: {
    processed: [],
    processing: [],
    delayed: [],
    loading: true,
    error: null,
  },
  paymentMethods: [],
  paymentMethodsHistory: [],
  paymentSettings: {},
  transactionHistory: {
    items: [],
    totalCount: null,
    loading: false,
    error: false,
  },
  scheduledPayments: {
    items: [],
    loading: false,
    error: false,
  },
  firstPayment: false,
  rcpComplianceDate: 'waiting', // waiting - processing - accepted
  uploadedInsuranceFile: false,
  disableInsurance: false,
  rentCredMilestone: false,
  rentCred: { id: '', renterTierId: 0, points: 0, history: null },
  showRentCredProfile: false,
  lvbleAccount: {
    account: null,
    token: null,
    loading: false,
    error: false,
    experienceState: 'EXPERIENCE_CLOSED',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_FIRST_ACH_PAYMENT:
      return {
        ...state,
        firstPayment: action.payload,
      };
    case actions.GET_BILLING_ITEMS:
      return {
        ...state,
        billingItems: {
          processed: action.payload.processed,
          processing: action.payload.processing,
          delayed: action.payload.delayed,
          loading: false,
        },
      };
    case actions.GET_PROCESSED_BILLING_ITEMS:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          processed: action.payload,
          loading: false,
        },
      };
    case actions.GET_PROCESSING_BILLING_ITEMS:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          processing: action.payload,
          loading: false,
        },
      };
    case actions.GET_DELAYED_BILLING_ITEMS:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          delayed: action.payload,
          loading: false,
        },
      };
    case actions.GET_BILLING_ITEMS_LOADING:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          loading: true,
        },
      };
    case actions.SET_LVBLE_TOKEN_LOADING:
      return {
        ...state,
        lvbleAccount: {
          ...state.lvbleAccount,
          loading: true,
        },
      };
    case actions.SET_LVBLE_TOKEN:
      return {
        ...state,
        lvbleAccount: {
          ...state.lvbleAccount,
          account: {
            ...state.lvbleAccount.account,
            hasBalance: action.payload.owes,
            status: action.payload.status,
          },
          token: action.payload.lvbleToken,
          loading: false,
        },
      };
    case actions.SET_LVBLE_TOKEN_ERROR:
      return {
        ...state,
        lvbleAccount: {
          ...state.lvbleAccount,
          token: null,
          loading: false,
          error: true,
        },
      };
    case actions.GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case actions.GET_PAYMENT_METHODS_HISTORY:
      return {
        ...state,
        paymentMethodsHistory: action.payload,
      };
    case actions.GET_PAYMENT_SETTINGS:
      return {
        ...state,
        paymentSettings: action.payload,
      };
    case actions.GET_BILLING_ITEMS_ERROR:
      return {
        ...state,
        billingItems: {
          ...state.billingItems,
          loading: false,
          error: action.payload,
        },
      };
    case actions.GET_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: {
          items: action.payload.lazyLoad
            ? [...state.transactionHistory.items, ...action.payload.transactions]
            : action.payload.transactions,
          totalCount: action.payload.totalCount,
          loading: false,
          error: false,
        },
      };
    case actions.CLEAR_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: {
          items: state.transactionHistory.items,
          totalCount: null,
          loading: true,
          error: false,
        },
      };
    case actions.SET_LOADING_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          loading: true,
        },
      };
    case actions.TRANSACTION_HISTORY_ERROR:
      return {
        ...state,
        transactionHistory: {
          ...state.transactionHistory,
          loading: false,
          error: true,
        },
      };
    case actions.GET_SCHEDULED_PAYMENTS:
      return {
        ...state,
        scheduledPayments: {
          items: action.payload.reverse(),
          loading: false,
          error: false,
        },
      };
    case actions.CREATE_SCHEDULED_PAYMENT:
      return {
        ...state,
        scheduledPayments: {
          items: [action.payload, ...state.scheduledPayments.items],
          loading: false,
          error: false,
        },
      };
    case actions.UPDATE_SCHEDULED_PAYMENT:
      return {
        ...state,
        scheduledPayments: {
          items: state.scheduledPayments.items.map((i) => (i.id === action.payload.id ? action.payload : i)),
          loading: false,
          error: false,
        },
      };
    case actions.DELETE_SCHEDULED_PAYMENT:
      return {
        ...state,
        scheduledPayments: {
          items: state.scheduledPayments.items.filter((i) => i.id !== action.payload),
          loading: false,
          error: false,
        },
      };
    case actions.SET_LOADING_SCHEDULED_PAYMENTS:
      return {
        ...state,
        scheduledPayments: {
          ...state.scheduledPayments,
          loading: true,
        },
      };
    case actions.SCHEDULED_PAYMENTS_ERROR:
      return {
        ...state,
        scheduledPayments: {
          ...state.scheduledPayments,
          loading: false,
          error: action.payload,
        },
      };
    case actions.SET_RCP_COMPLIANCE:
      return {
        ...state,
        rcpComplianceDate: action.payload,
      };
    case actions.SET_INSURANCE_POLICY_FILE:
      return {
        ...state,
        uploadedInsuranceFile: action.payload,
      };
    case actions.SET_DISABLE_INSURANCE_FEATURE:
      return {
        ...state,
        disableInsurance: action.payload,
      };
    case actions.SET_RENTCRED_MILESTONE:
      return {
        ...state,
        rentCredMilestone: action.payload,
      };
    case actions.SHOW_RENTCRED_PROFILE:
      return {
        ...state,
        showRentCredProfile: action.payload,
      };
    case actions.SET_RENTCRED_DATA:
      return {
        ...state,
        rentCred: action.payload,
      };
    case actions.SET_LVBLE_ACCOUNT:
      return {
        ...state,
        lvbleAccount: {
          ...state.lvbleAccount,
          account: action.payload,
          loading: false,
        },
      };
    case actions.SET_LVBLE_ACCOUNT_ERROR:
      return {
        ...state,
        lvbleAccount: {
          ...state.lvbleAccount,
          account: null,
          loading: false,
          error: action.payload,
        },
      };
    case actions.SET_LVBLE_EXPERIENCE:
      return {
        ...state,
        lvbleAccount: {
          ...state.lvbleAccount,
          experienceState: action.payload,
        },
      };
    default:
      return state;
  }
};
