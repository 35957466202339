export default {
  colors: {
    red: '#ff4a54',
    violet: '#cb48b7',
    violet2: '#541388',
    violet3: '#431170',
    grey: '#C0C0C0',
    grey2: '#8C8C8C',
    grey3: '#595959',
    grey4: '#122C34',
    grey5: '#FAFAFA',
    greyText: 'rgba(0, 0, 0, 0.45)',
    yellow: '#ffcc00',
    blue: '#003A8C',
    blue2: '#1890FF',
    green: '#85BB65',
    black: 'rgba(0, 0, 0, 0.88)',
    white: 'white',
    white2: '#ffffff',
    lvbleGreen: '#15CFA2',
    danger: '#ff4a54',
  },
};
