import { Button, Drawer } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import SalesCopy from '../SalesCard/SalesCopy/SalesCopy';
import { StyledCTA, StyledSalesDrawer } from '../SalesCard/SalesCard.styles';

export default function SalesDrawer() {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <StyledSalesDrawer
      mask={false}
      closable={false}
      id="sales-drawer"
      width={'100vw'}
      placement="left"
      open={isOpen}
    >
      <SalesCopy />
      <StyledCTA>
        <Button type="primary" size="large" onClick={() => setIsOpen(false)} block>
          Get Started Now
        </Button>
      </StyledCTA>
    </StyledSalesDrawer>
  );
}
