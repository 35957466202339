import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Checkbox, Form, Input } from 'antd';
import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import AlertFade from 'components/AlertFade/AlertFade';
import Space from 'components/Space/Space';
import { updateFinixComplianceForm } from 'services/api/landlordApi/update';

const { Item, useForm } = Form;

function ComplianceForm(props) {
  //   console.log('ƒ ComplianceForm(props)', props);
  const [form] = useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    isVisible: false,
    type: 'error',
    description: "This is an error message, but it's not your fault",
  });

  const { formId, setIsComplete, formMetaData, isFetching } = props;

  const handleFinish = async (values) => {
    // console.log('ƒ handleFinish', values, formMetaData);
    if (formMetaData.state === 'INCOMPLETE') {
      setIsLoading(true);
      try {
        const data = {
          pci_saq_a: {
            ip_address: formMetaData.userIp,
            name: values.name,
            signed_at: dayjs().toISOString(),
            title: values.title,
            user_agent: formMetaData.userAgent,
          },
        };
        // console.log('ƒ handleFinish data', data);
        await updateFinixComplianceForm(formId, data);
        setIsLoading(false);
        setIsComplete(true);
      } catch (error) {
        console.log('ƒ handleFinish error', error);
        setIsLoading(false);
        setShowAlert({
          isVisible: true,
          type: 'error',
          description: error.data.message,
        });
      }
    }
  };

  const handleOnChange = () => {
    const { name, title, certify } = form.getFieldsValue();
    if (name && title && certify) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <div key="compliance-form" className="compliance-form-container">
      <div className="alert-wrapper" style={{ height: '68px' }}>
        <AlertFade {...showAlert} />
      </div>
      <Space vertical={18} />
      <Form
        form={form}
        onChange={handleOnChange}
        onFinish={handleFinish}
        layout="vertical"
        requiredMark={false}
        disabled={isFetching}
        data-testid="complianceFormTest"
      >
        <Item name="name" label="Executive Name" rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item name="title" label="Role" rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item name="certify" valuePropName="checked">
          <Checkbox>
            <Text strong color="black">
              By submitting the Self-Assessment Questionnaire, I certify that I am an authorized representative of
              the company and that all the information submitted is true and correct.
            </Text>
          </Checkbox>
        </Item>
        <Item wrapperCol={{ offset: 10, span: 2 }}>
          <Button
            data-testid="attestButtonTest"
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isDisabled}
            loading={isLoading}
          >
            Attest
          </Button>
        </Item>
      </Form>
    </div>
  );
}

export default ComplianceForm;
