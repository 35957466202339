import React, { useEffect } from 'react';
import { Space, Typography } from 'antd';
import { LandingButton } from './SignupLanding.styles';
import { ReactComponent as Income } from 'assets/images/income.svg';
import { ReactComponent as Renter } from 'assets/images/keys.svg';
import Icon from '@ant-design/icons/lib/components/Icon';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const { Title } = Typography;

export default function SignupLanding(props) {
  // console.log('[SignupLanding.js]', props);
  const params = new URLSearchParams(props.location.search);

  useEffect(() => {
    if (params.get('apid')) {
      localStorage.setItem('apid', params.get('apid'));
    }
  }, [props.location.search]);

  const buttonVariants = {
    hide: { opacity: 0 },
    show: { opacity: 1 },
  };
  return (
    <Space direction="vertical" align="center">
      <Title level={2} style={{ color: '#fff' }}>
        <motion.div variants={buttonVariants} initial="hide" animate="show" style={{ display: 'inline' }}>
          👋 Welcome!
        </motion.div>
      </Title>
      <motion.div
        key="container"
        style={{ flexWrap: 'wrap', justifyContent: 'center', display: 'flex', gap: '24px' }}
      >
        <motion.div
          variants={buttonVariants}
          initial="hide"
          animate="show"
          transition={{ duration: 0.3, delay: 0.2 }}
          key="landlord"
          whileTap={{ scale: 0.8 }}
        >
          <Link to="/sign-up/landlord" data-testid="landlordLinkTest">
            <LandingButton data-testid="landlordButtonTest">
              <Icon component={Income} style={{ fontSize: '36px' }} />
              <div>I'm a Landlord</div>
            </LandingButton>
          </Link>
        </motion.div>
        <motion.div
          variants={buttonVariants}
          initial="hide"
          animate="show"
          transition={{ duration: 0.3, delay: 0.4 }}
          whileTap={{ scale: 0.8 }}
          key="tenant"
        >
          <Link to="/sign-up/tenant">
            <LandingButton data-testid="tenantButtonTest">
              <Icon component={Renter} style={{ fontSize: '36px', transform: 'scaleX(-1)' }} />
              <div>I'm a Renter</div>
            </LandingButton>
          </Link>
        </motion.div>
      </motion.div>
    </Space>
  );
}
