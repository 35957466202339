import { FileImageFilled, FilePdfFilled, FileUnknownFilled, FileZipFilled } from '@ant-design/icons';
import { theme, Tooltip } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

function DocumentTypeIcon({ fileType }) {
  const { token } = theme.useToken();

  const fileIcon = () => {
    switch (fileType) {
      case 'application/pdf':
        return <FilePdfFilled />;
      case 'image/png':
      case 'image/jpeg':
        return <FileImageFilled />;
      case 'application/zip':
        return <FileZipFilled />;
      default:
        return <FileUnknownFilled />;
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: '24px', color: token.colorTextDescription }}>
      <Tooltip title={fileType?.toLowerCase() || 'unk'} placement="top">
        {fileIcon()}
      </Tooltip>
    </div>
  );
}

export default DocumentTypeIcon;
