import React from 'react';
import { Button, Form, Input, Card } from 'antd';
import { Link } from 'react-router-dom';
import CheckCircleOutlined from '@ant-design/icons/LoginOutlined';
import AlertFade from 'components/AlertFade';
import Text from 'components/Text/Text';
import Title from 'components/Title/Title';

const ConfirmPasswordForm = (props) => {
  const onFinish = (values) => {
    props.confirmEmailCode(values.code, values.password);
  };

  return (
    <div className="form-container" data-testid="confirmPasswordTest">
      <Card style={{ maxWidth: '380px' }}>
        <Title size={16} data-testid="h4Test" level={5} color={'black'} strong={true}>
          Forgot Password
        </Title>

        <AlertFade
          isVisible={props.alertData.visible}
          message={props.alertData.message}
          type={props.alertData.type}
        />

        <Text color="black" size={16}>
          Enter a new password along with the confirmation code sent to your email
        </Text>

        <Form onFinish={onFinish} className="auth-form" size="large">
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Please input the code',
                whitespace: true,
              },
              {
                pattern: new RegExp('^[0-9]*$'),
                message: 'Please input only numbers',
              },
            ]}
          >
            <Input placeholder="Confirmation code" data-testid="codeTest" autoComplete="new-password" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password', whitespace: true },
              {
                pattern: new RegExp('^(?=.*[A-Z]).{8,}$'),
                message: 'One upper case letter - min length 8',
              },
            ]}
          >
            <Input.Password autoComplete="new-password" placeholder="Password" data-testid="passwordTest" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="form-container__button"
              icon={<CheckCircleOutlined />}
              loading={props.isLoading}
              data-testid="buttonTest"
            >
              Reset password
            </Button>
          </Form.Item>
        </Form>
        <div className="form-container__botton-text">
          <Link to="/">Back to Sign In</Link>
        </div>
      </Card>
    </div>
  );
};

export default ConfirmPasswordForm;
