import React, { useState } from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ReactComponent as RentCredLogo } from 'assets/images/rentcred/rentcred-logo.svg';
import { ReactComponent as ZipLogo } from 'assets/images/zip_badge.svg';

import Text from 'components/Text';
import Space from 'components/Space';
import Button from 'components/Button';

const QuadpayEligible = (props) => {
  const { setShowQuadpayEligible, setShowPayment, quadpayRedirect } = props;
  const [proceed, setProceed] = useState(false);

  const handleAgreement = (e) => {
    setProceed(!proceed);
  };

  const handleProceed = () => {
    if (proceed) {
      window.location.href = quadpayRedirect;
    }
  };

  return (
    <Container>
      <Header>
        <ArrowLeftOutlined
          onClick={() => {
            setShowPayment(true);
            setShowQuadpayEligible(false);
          }}
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowTest"
        />
        RentCred Loan
      </Header>
      <ContentContainer>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
          <RentCredLogo width={120} height={52} />{' '}
          <Text size={16} color="black" strong>
            Loans
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text size={16} color="black">
            powered by
          </Text>
          <ZipLogo style={{ fontSize: '20px' }} />
        </div>
        <Space vertical={10} />
        <Text size={16} color="black" centered>
          RentCred loans is a fast and easy way to get emergency cash when you need it.
        </Text>
        <Space vertical={10} />
        <Text size={16} color="black" centered>
          Because of your on-time payment history you can borrow up to a maximum of $500 for unexpected rental
          expenses. Loans are paid back in 3 consecutive bi-weekly payments. Clicking on{' '}
          <span style={{ fontWeight: 600 }}>PROCEED</span> will take take you from our website to the Zip checkout
          experience where you will be asked to provide additional information to qualify. If approved, your
          landlord will be paid the full amount borrowed minus the transaction fee.
        </Text>
        <Space vertical={20} />
        <div style={{ display: 'flex', width: '80%' }}>
          <Checkbox onChange={handleAgreement} data-testid="checkboxTest" />
          <Text size={16} color="black" margin="0px 0px 0px 10px" centered>
            I agree to use RentCred loans responsibly
          </Text>
        </div>
        <Space vertical={10} />
        <Button color="violet" disabled={!proceed} onClick={handleProceed} data-testid="proceedTest">
          PROCEED <ArrowRightOutlined />
        </Button>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

export default QuadpayEligible;
