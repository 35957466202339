import React, { useState } from 'react';
import { sendRecoveryPasswordEmail, confirmNewPassword } from 'services/aws/cognito';
import { motion, AnimatePresence } from 'framer-motion';
import { formVariants } from 'animations/variants';

// Components
import EmailForm from './components/EmailForm';
import ConfirmPasswordForm from './components/ConfirmPasswordForm';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [emailWasSent, setEmailWasSent] = useState(false);
  const [alertData, setAlertData] = useState({ visible: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (email) => {
    setIsLoading(true);
    const callback = (data) => {
      // No errors
      setIsLoading(false);
      setEmail(email);

      setEmailWasSent(true); // Show the email code form
      setAlertData({ visible: true, message: 'Confirmation code sent.', type: 'success' });
      setTimeout(() => {
        setAlertData({ visible: false, message: 'Confirmation code sent.', type: 'success' });
      }, 5000);
    };

    const errorCallback = (err) => {
      //err.message || JSON.stringify(err)
      setAlertData({
        visible: true,
        message: err.message || 'There was an error sending the email.',
        type: 'error',
      });
      setTimeout(() => {
        setAlertData({ visible: false, message: 'There was an error sending the emailt.', type: 'error' });
      }, 5000);
      setIsLoading(false);
    };

    try {
      sendRecoveryPasswordEmail(email, callback, errorCallback);
    } catch (e) {
      console.log(e.message || 'Error');
    }
  };

  const confirmEmailCode = (verificationCode, newPassword) => {
    setIsLoading(true);
    confirmNewPassword(email, verificationCode, newPassword)
      .then((data) => {
        setIsLoading(false);
        setAlertData({ visible: true, message: 'Password successfully reset.', type: 'success' });
        setTimeout(() => {
          setAlertData({ visible: false, message: 'Password successfully reset.', type: 'success' });
          props.history.push('/sign-in');
        }, 3000);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertData({ visible: true, message: err.message || JSON.stringify(err) });
      });
  };

  return (
    <section className="screen screen-forgot-password" data-testid="forgotPasswordTest">
      <AnimatePresence exitBeforeEnter>
        <motion.div variants={formVariants} initial="hide" animate="show" exit="leave">
          {emailWasSent ? (
            <ConfirmPasswordForm
              key="confirm"
              confirmEmailCode={confirmEmailCode}
              isLoading={isLoading}
              alertData={alertData}
            />
          ) : (
            <EmailForm
              key="email"
              email={email}
              sendRecoveryPasswordEmail={sendEmail}
              isLoading={isLoading}
              alertData={alertData}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

export default ForgotPassword;
