import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Iot from 'services/aws/iot/aws-iot';
import { Row, Col, Collapse } from 'antd';
import { displayAlertAction, clearPanel } from 'store/actions/globalActions';
import { getSummaryDataAction } from 'store/actions/landlordActions';
import { setDashboard } from 'store/actions/dashboardActions';
import KpiCard from './KpiCard';
import { StyledCollapse } from './KpiDashboard.styles';

const { Panel } = Collapse;

function KpiDashboard(props) {
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  const panel = useSelector((store) => store.global.panel);
  const session = useSelector((state) => state.session);
  const metrics = useSelector((state) => state.landlord.summaryCards.metrics);
  const dashboard = useSelector((state) => state.dashboard.settings);
  const dispatch = useDispatch();

  const { isDesktop } = props;

  const fetchData = async () => {
    // console.log('ƒ fetchData');
    const cognitoToken = session.cognitoTokens.idToken.jwtToken;
    setLoading(true);
    try {
      dispatch(getSummaryDataAction(cognitoToken));
    } catch (error) {
      dispatch(
        displayAlertAction('', 'warning', error?.data?.errors[0].title || 'Your request failed.', true, 5000)
      );
    }
    setLoading(false);
  };

  const handleRefresh = async () => {
    // console.log('ƒ handleRefresh');
    setReloading(true);
    await fetchData();
    setTimeout(() => setReloading(false), 500);
  };

  useEffect(() => {
    // console.log('ƒ useEffect');
    fetchData();
    Iot.attachMessageHandler((topic, message) => {
      const string = new TextDecoder().decode(message);
      const ms = JSON.parse(string);
      if (ms.event === 'TRANSACTION_UPDATE') {
        fetchData();
      }
    });

    if (panel) {
      dispatch(clearPanel());
    }
    if (!isDesktop) {
      dispatch(setDashboard({ activeKey: 0 }));
    }
  }, []);

  useEffect(() => {
    if (!isDesktop) {
      dispatch(setDashboard({ activeKey: [] }));
    }
  }, [isDesktop]);

  const items = [
    {
      key: '1',
      label: <span>Dashboard</span>,
      children: (
        <div>
          <Row
            gutter={[10, 10]}
            justify="center"
            data-testid="kpiDashboardTest"
            className="kpiCardsTour card-transition"
          >
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="revenue"
                type="landlord"
                title="Revenue"
                data={metrics.find((e) => e.id === 'revenue')}
                prefix="$"
                suffix=""
                showToggle
                loading={loading}
                reloading={reloading}
                isDesktop={isDesktop}
              />
            </Col>
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="units"
                type="landlord"
                title="Units"
                data={metrics.find((e) => e.id === 'units')}
                prefix=""
                suffix=""
                loading={loading}
                reloading={reloading}
                isDesktop={isDesktop}
              />
            </Col>
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="occupancy"
                type="landlord"
                title="Occupancy"
                data={metrics.find((e) => e.id === 'occupancy')}
                prefix=""
                suffix="%"
                loading={loading}
                reloading={reloading}
                isDesktop={isDesktop}
              />
            </Col>
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="gross-rent-multiplier"
                type="landlord"
                toolTip="Purchase price divided by gross annual rental income"
                title="Gross Rent Multiplier"
                data={metrics.find((e) => e.id === 'gross-rent-multiplier')}
                prefix=""
                suffix=""
                loading={loading}
                reloading={reloading}
                handleRefresh={handleRefresh}
                isDesktop={isDesktop}
              />
            </Col>
          </Row>
          <Row
            gutter={[10, 10]}
            style={{ marginTop: 10 }}
            justify="center"
            data-testid="kpiCardsTest"
            className="kpiCardsTour card-transition"
          >
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="net-operating-income"
                type="landlord"
                title="Net Operating Income"
                toolTip="Income after maintenance, taxes, insurance and utilities"
                data={metrics.find((e) => e.id === 'net-operating-income')}
                prefix="$"
                suffix=""
                handleRefresh={handleRefresh}
                showToggle
                loading={loading}
                reloading={reloading}
                isDesktop={isDesktop}
              />
            </Col>
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="cash-flow"
                type="landlord"
                title="Cash Flow"
                toolTip="Free cash after operating expense and debt service"
                data={metrics.find((e) => e.id === 'cash-flow')}
                prefix="$"
                suffix=""
                handleRefresh={handleRefresh}
                showToggle
                loading={loading}
                reloading={reloading}
                isDesktop={isDesktop}
              />
            </Col>
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="market-value"
                type="landlord"
                title="Market Value"
                data={metrics.find((e) => e.id === 'market-value')}
                prefix="$"
                suffix=""
                handleRefresh={handleRefresh}
                loading={loading}
                reloading={reloading}
                isDesktop={isDesktop}
              />
            </Col>
            <Col span={6} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 6 }}>
              <KpiCard
                id="cap-rate"
                type="landlord"
                title="Cap Rate"
                toolTip="Net operating income divided by the current market value of properties"
                data={metrics.find((e) => e.id === 'cap-rate')}
                prefix=""
                suffix="%"
                handleRefresh={handleRefresh}
                loading={loading}
                reloading={reloading}
                isDesktop={isDesktop}
              />
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <StyledCollapse
      bordered={false}
      activeKey={dashboard.activeKey}
      onChange={(key) => {
        dispatch(setDashboard({ activeKey: key }));
      }}
      items={items}
    />
  );
}
export default KpiDashboard;
