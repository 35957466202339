// @flow
import React, { useEffect, useRef, useState } from 'react';
import { usStates } from 'resources/FormData';
import { loadExternalScript, scriptExists, capSentence } from 'utils';
import { theme } from 'antd';
import styled from 'styled-components';

const PlacesAutocompleteInput = (props) => {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';
  const googleUrl = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const autocompleteInput = useRef();
  const { token } = theme.useToken();
  // console.log('token', token);

  let autocomplete;
  // Maps JavaScript API

  const getLocationDetails = () => {
    const place = autocomplete.getPlace();

    if (place?.address_components?.length) {
      const locationDetails = {
        address: '',
        streetNumber: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        google_place_id: place.place_id,
        lat: place?.geometry?.location.lat(),
        long: place?.geometry?.location.lng(),
      };
      const googleTypesEquivalences = {
        street_number: 'streetNumber',
        route: 'address',
        neighborhood: 'city',
        sublocality_level_1: 'city',
        locality: 'city',
        administrative_area_level_1: 'state',
        country: 'country',
        postal_code: 'zip',
      };

      for (let i = 0; i < place.address_components.length; i++) {
        const type = place.address_components[i].types[0];
        if (googleTypesEquivalences[type]) {
          let value = place.address_components[i].long_name;
          if (googleTypesEquivalences[type] === 'state' && place.address_components[i].long_name.length === 2) {
            const shortState = usStates.filter(
              (us) => us.abbreviation === place.address_components[i].long_name.toUppercase()
            );
            value = shortState.length ? capSentence(shortState[0].name) : place.address_components[i].long_name;
          } else {
            locationDetails[googleTypesEquivalences[type]] = value;
          }
        }
      }

      if (locationDetails.address === '') {
        locationDetails.address = place.name;
      }
      if (props.setAddressInitValue) props.setAddressInitValue(null);

      if (props.setAddressInput) props.setAddressInput(place.formatted_address);
      if (props.setGoogleString) props.setGoogleString(place.formatted_address);
      props.callback(locationDetails);
    }
  };

  const initAutocomplete = () => {
    autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput.current);
    autocomplete.setComponentRestrictions({ country: ['us'] });
    // When the user selects an address from the dropdown, populate the address fields in the form.
    autocomplete.addListener('place_changed', getLocationDetails);
  };

  useEffect(() => {
    if (scriptLoaded) {
      if (scriptExists(googleUrl) && window.google?.maps) {
        initAutocomplete();
      }
    }
  }, [scriptLoaded, googleUrl]);

  useEffect(() => {
    if (scriptExists(googleUrl)) {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!scriptExists(googleUrl)) {
      console.log('no script, loading');
      const script = document.createElement('script');
      script.src = googleUrl;
      script.async = true;
      script.onload = () => setScriptLoaded(true);

      document.body.appendChild(script);
    }
  });

  const handleInputChange = (e) => {
    if (props.setAddressInitValue) props.setAddressInitValue(null);
    props.setAddressInput(e.target.value);
  };

  return (
    <StyledGooglePlacesInput token={token}>
      <span className="ant-input-prefix"></span>
      <input
        onChange={handleInputChange}
        data-testid="autocompleteTest"
        id="autocomplete"
        placeholder=""
        type="text"
        ref={autocompleteInput}
        value={props.addressInitValue}
        disabled={!scriptLoaded}
        className="ant-input"
      />
    </StyledGooglePlacesInput>
  );
};

export default PlacesAutocompleteInput;

const StyledGooglePlacesInput = styled.span`
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 3px 11px;
  font-size: inherit;
  line-height: 1.5;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.token.colorBorder};
  border-radius: ${(props) => `${props.token.borderRadius}px`};
  font-family: inherit;
  transition: all 0.2s;
  box-sizing: border-box;
  &:focus {
    border-color: ${(props) => props.token.colorPrimay};
    box-shadow: 0 0 0 2px rgba(36, 3, 48, 0.27);
    outline: 0;
  }
  &:hover {
    border-color: ${(props) => props.token.colorPrimay};
  }

  span.ant-input-prefix {
    display: flex;
    flex: none;
    align-items: center;
    margin-inline-end: 4px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.88);
    font-size: inherit;
    line-height: 1.5;
  }
  input {
    line-height: 1.5;
    list-style: none;
    position: relative;
    color: ${(props) => props.token.colorText};
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    border: none;
    border-radius: 0;
    outline: none;
    box-sizing: border-box;
  }
`;
