import React from 'react';
import Text from 'components/Text/Text';
import { Space } from 'antd';

function BoardingFaqs() {
  const faqs = [
    {
      q: 'Do I have to be business to use PayRent?',
      a: 'No, any individual with a Social Security Number can use the service to collect rent.',
    },
    {
      q: 'Who sees my business information?',
      a: 'Only PayRent and the bank see your business information. We do not share your information with anyone else.',
    },
    {
      q: 'Why do I need to provide my social security number?',
      a: 'Federal laws require us to verify the identity of anyone using our service. This is to prevent fraud and money laundering.',
    },
    {
      q: 'What if I am not the owner of the business?',
      a: 'If you own less than 25% you must list the other owners by clicking on the “Less than 25%” button on the Owner Details step. Anyone owning less than 25% does not need to be listed.',
    },
  ];
  return faqs.map((faq, index) => (
    <Space direction="vertical" key={index}>
      <Text color="black" strong>
        {faq.q}
      </Text>
      <Text color="black">{faq.a}</Text>
    </Space>
  ));
}

export default BoardingFaqs;
