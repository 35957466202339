import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { documentsPresignedUrl } from 'services/api/common/create';
import { getUserInsurancePolicy } from 'services/api/common/read';
import { getBillingAccount } from 'services/api/tenantApi';

import AlertFade from 'components/AlertFade';

import Text from 'components/Text';

const InsuranceAlert = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const uploadedInsuranceFile = useSelector((store) => store.tenant.uploadedInsuranceFile);
  const [alert, setAlert] = useState(false);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const requireInsurance = useRef(false);
  const insuranceFile = useRef(true);
  const insurancePolicy = useRef(false);
  const userIdRef = useRef('');
  const userPolicyRef = useRef(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    if (session.userData.id !== '') {
      let billingAccount = [];
      if (session.userData.billingAccounts.length) {
        billingAccount = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      }
      if (billingAccount.length) {
        if (
          session.userData.id !== userIdRef.current ||
          props.userPolicy !== userPolicyRef.current ||
          insurancePolicy.current !== billingAccount[0]?.requireInsurance
        ) {
          userIdRef.current = session.userData.id;
          userPolicyRef.current = props.userPolicy;

          if (!billingAccount.length) {
            dispatch({ type: 'SET_DISABLE_INSURANCE_FEATURE', payload: true });
            setAlert(false);
            return;
          }
          // checkInsuranceState(billingAccount[0].id);

          if (!billingAccount[0]?.requireInsurance) {
            insurancePolicy.current = false;
            setAlert(false);
          }
          if (billingAccount[0]?.requireInsurance) {
            checkInsuranceFile(billingAccount);
          }
        }
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, [session, props.userPolicy]);

  const checkInsuranceState = async (id) => {
    const res = await getBillingAccount(cognitoToken, id);
    console.log(res);
  };

  const checkInsuranceFile = async (ba) => {
    try {
      if (ba.length) {
        if (ba[0]?.requireInsurance !== requireInsurance.current) {
          insurancePolicy.current = true;
          fetchInsurancePolicy(ba[0].id, ba);
        }
        if (
          uploadedInsuranceFile !== insuranceFile.current &&
          ba[0]?.requireInsurance !== requireInsurance.current
        ) {
          const res = await documentsPresignedUrl(
            session.cognitoTokens.idToken.jwtToken,
            ba[0].id,
            'download',
            '',
            'insurance'
          );

          if (res?.presignedUrl) {
            dispatch({ type: 'SET_INSURANCE_POLICY_FILE', payload: true }); // to disable buttons
            setAlert(false);
          } else {
            insuranceFile.current = false;
          }
          if (!mountedRef.current) return null;
        }
      }
    } catch (error) {}
  };

  const fetchInsurancePolicy = async (id, ba) => {
    try {
      const res = await getUserInsurancePolicy(cognitoToken, id);
      if (!Object.keys(res.policy).length) {
        setAlert(true);
        return;
      }
      setAlert(false);
      if (!mountedRef.current) return null;
    } catch (error) {
      setAlert(true);
    }
  };

  return (
    <AlertFade
      message="Your Landlord Requires Renters Insurance"
      description={
        <>
          You can upload your proof of coverage or purchase renters insurance from the{' '}
          <Text
            color="violet"
            underline
            size={14}
            pointer
            onClick={() => {
              history.push('/tenant/wallet?panel=insurance');
              if (props.setInsuranceDrawer && props.setGetUploadVisible) {
                props.setInsuranceDrawer(true);
                props.setGetUploadVisible(true);
              }
            }}
          >
            Wallet screen
          </Text>
          .
        </>
      }
      display
      isVisible={alert}
      type="warning"
    />
  );
};

export default InsuranceAlert;
