import React, { useEffect, useState } from 'react';
import Link from 'components/Link';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

const StatusText = (props) => {
  const history = useHistory();
  const { state } = props;
  useEffect(() => {}, [props]);

  return (
    <>
      Your account {state.id} is <Status color={state.color}>{state.text}</Status>
      {state.status !== 'disabled' && state.status !== 'active' && ' - '}
      {state.status === 'inactive' && (
        <>
          <Link
            to="#"
            onClick={() => {
              props.setShowOnboardingOverlay(true);
            }}
            inline
          >
            Get started now
          </Link>{' '}
          collecting rent safely and simply
        </>
      )}
      {state.status === 'stripe-payment-method-required' && (
        <>
          <Link
            to="#"
            onClick={() => {
              props.setDrawerVisible(true);
              props.setShowPlansBillingPanel(true);
            }}
            inline
          >
            Please add a payment method for your service plan
          </Link>{' '}
          or{' '}
          <Link
            to="#"
            onClick={() => {
              history.push('/landlord/service-plans');
            }}
            inline
          >
            downgrade to a free plan
          </Link>
        </>
      )}
      {state.status === 'error' && 'You cannot accept payments'}
    </>
  );
};

const Status = styled.span`
  color: ${(props) => props.color};
  font-weight: 600;
`;

export default StatusText;
