// @flow
import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import PlacesAutocompleteInput from 'components/PlacesAutocompleteInput';
import styled from 'styled-components';

type Props = {
  groupId: number,
  handlePlacesAutocomplete: Function,
  handleSelect: Function,
};

const FormFieldSet = (props: Props) => {
  const { Option } = Select;
  const { groupId } = props;
  const [formDays, setFormDays] = useState([]);

  useEffect(() => {
    datesArray();
  }, []);

  const checkPrice = (rule, value) => {
    if (Number(value) > 0) {
      return Promise.resolve();
    }
    if (isNaN(Number(value))) {
      return Promise.reject('Amount is not a valid number.');
    }
    return Promise.reject('Payment amount cannot be less than $0.00');
  };

  const numberTOrdinalSuffix = (num) => {
    const suffix = ['st', 'nd', 'rd'][((((num + 90) % 100) - 10) % 10) - 1] || 'th';
    return `${num}${suffix}`;
  };

  const datesArray = () => {
    let days = [];
    for (let i = 1; i <= 31; i += 1) {
      days[i] = {
        label: `${numberTOrdinalSuffix(i)} of the month`,
        value: i,
      };
    }
    setFormDays(days);
  };

  return (
    <StyledDiv>
      <Row gutter={15}>
        <Col span={16}>
          Address
          <Form.Item
            name="address"
            data-testid="addressTest"
            rules={[
              {
                validator: props.addressValidator,
              },
            ]}
          >
            <PlacesAutocompleteInput
              callback={props.handlePlacesAutocomplete}
              groupId={groupId}
              setGoogleString={props.setGoogleString}
              setAddressInput={props.setAddressInput}
              addressValidator={props.addressValidator}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          Unit
          <Form.Item name="unit" data-testid="unitTest">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={15}>
        <Col span={16}>
          <br />
          <Form.Item
            name="rentDate"
            data-testid="rentDateTest"
            whitespace="true"
            rules={[
              {
                required: true,
                message: 'Please enter your rent date',
              },
            ]}
          >
            <Select placeholder="Rent Due Date" onChange={props.handleSelect} data-testid="rentDateSelectTest">
              {formDays.map((d, i) => (
                <Option value={d.value} key={i}>
                  {d.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          Amount
          <Form.Item
            name="rentAmount"
            data-testid="amountTest"
            rules={[
              {
                validator: checkPrice,
              },
            ]}
          >
            <Input data-testid="amountInputTest" />
          </Form.Item>
        </Col>
      </Row>
    </StyledDiv>
  );
};

const StyledDiv = styled.div``;

export default FormFieldSet;
