import React from 'react';

import { ReactComponent as DocsKycReview } from 'assets/images/doc_kyc_review.svg';

import Text from 'components/Text';
import Space from 'components/Space';

const DocsReceived = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <DocsKycReview />
      <Space vertical={10} />
      <Text size={16} color="black" centered width={300}>
        Thank you for your patience. We will notify you by email when your documents have been reviewed.
      </Text>
    </div>
  );
};

export default DocsReceived;
