import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import styled from 'styled-components';
import { QuestionOutlined } from '@ant-design/icons';

function IconBadge(props) {
  const { title, icon, rotate } = props;
  return (
    <StyledIconWrapper color={props.color} data-testid="iconBadgeTest" className="icon-badge">
      <Tooltip title={title}>
        <Icon component={icon} rotate={rotate} data-testid={props['data-testid']} />
      </Tooltip>
    </StyledIconWrapper>
  );
}

const StyledIconWrapper = styled.div`
  margin: 0 10px;
  .anticon {
    font-size: 20px;
    color: ${(props) => props.color || '#000'};
  }
`;

IconBadge.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

IconBadge.defaultProps = {
  title: 'Missing title',
  icon: QuestionOutlined,
};

export default IconBadge;
