import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Form, Input, Row, Col, Select, Checkbox, Tooltip, Radio, Spin, Space as AntSpace } from 'antd';
import parser from 'parse-address';
import { updateCreatePropertyFees } from 'services/api/landlordApi/create';
import { getPropertyFees } from 'services/api/common/read';
import { updatePropertyPreferences } from 'services/api/landlordApi/update';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';
import { getServicePlanAction } from 'store/actions/sessionActions';
import { numberTOrdinalSuffix } from 'resources/helpers';
import { InfoCircleFilled } from '@ant-design/icons';
import Space from 'components/Space';
import Text from 'components/Text';
import Segmented from 'components/Segmented/Segmented';
import AdvancedFeaturesOverlay from '../../../components/AdvancedFeaturesOverlay/AdvancedFeaturesOverlay';
import AdditionalFeesFields from '../../../components/AdditionalFeesFields/AdditionalFeesFields';
import PropertyRentPolicy from '../../../components/PropertyRentPolicy/PropertyRentPolicy';
import { useQuery } from '@tanstack/react-query';
import { getParentProperties, getPropertiesV3, getPropertyGroups } from 'services/api/landlordApi/read';
import OnboardingResult from '../OnboardingResult';

const { Option } = Select;

const UnitForm = (props) => {
  const dispatch = useDispatch();
  const { propertyGroup } = props;
  const session = useSelector((store) => store.session);
  const quickStart = session.userData.landlordMeta.landlordProfile.landlordProfile?.quickStart;
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const { setIsLoading, setCurrentStep, currentStep } = props;

  const [message, setMessage] = useState({
    type: 'success',
    message: '',
    description: '',
    closable: false,
    marginBottom: 0,
  });

  const [form] = Form.useForm();
  const [isCommercial, setIsCommercial] = useState(false);
  const [creditCardPayments, setCreditCardPayments] = useState(false);
  const [absorbFees, setAbsorbFees] = useState(false);
  const [fullPayment, setFullPayment] = useState(false);
  const [preventPayment, setPreventPayment] = useState(false);
  const [formDays, setFormDays] = useState([]);
  const [locationData, setLocationData] = useState(null);
  const googleSelected = useRef(null);
  const customAddressInput = useRef(null);
  const amountInput = useRef(null);

  const {
    isLoading: isLoadingProperty,
    isError: isErrorProperty,
    data: selectedParentProperty,
  } = useQuery({
    queryKey: ['parentProperty'],
    queryFn: () => getParentProperties(cognitoToken, propertyGroup.id, 1, 10).then((res) => res.items[0]),
    enabled: !!propertyGroup,
  });
  // console.log('selectedParentProperty', selectedParentProperty);

  const {
    isLoading: isLoadingUnit,
    isError: isErrorUnit,
    data: editProperty,
  } = useQuery({
    queryKey: ['editProperty'],
    queryFn: () => getPropertiesV3(cognitoToken, +selectedParentProperty.id).then((res) => res[0]),
    enabled: !!selectedParentProperty?.id,
  });
  // console.log('editProperty', editProperty);

  useEffect(() => {
    if (!editProperty) return;
    // console.log('ƒ useEffect on editProperty', editProperty);
    datesArray();
    fetchAndSetData();
    dispatch(getServicePlanAction(cognitoToken));
  }, [editProperty]);

  const fetchAndSetData = async () => {
    try {
      if (selectedParentProperty) {
        const parsedAddress = parser.parseLocation(selectedParentProperty.address);
        let streetNumber = '';
        let streetName = '';
        if (parsedAddress) {
          streetNumber = parsedAddress?.number;
          streetName = selectedParentProperty.address?.replace(`${parsedAddress.number} `, '');
        } else {
          streetName = selectedParentProperty.address?.split(',')[0];
        }

        setLocationData({
          address: streetName,
          city: selectedParentProperty.city,
          country: 'United States',
          google_place_id: selectedParentProperty.googlePlaceId,
          lat: selectedParentProperty ? selectedParentProperty.lat : editProperty.lat,
          long: selectedParentProperty ? selectedParentProperty.long : editProperty.long,
          state: selectedParentProperty.state,
          streetNumber,
          zip: selectedParentProperty.zip,
        });
      }

      const propertyData = editProperty;

      if (propertyData) {
        const feesRes = await getPropertyFees(cognitoToken, propertyData.id);
        // console.log('feesRes', feesRes);
        // const addressName =
        //   propertyData.city !== ''
        //     ? `${propertyData.address}, ${propertyData.city}, ${propertyData.state}, ${propertyData.zip}`
        //     : `${propertyData.address}, ${propertyData.state}, ${propertyData.zip}`;

        const settings = propertyData.paymentSettings || propertyData.settings;
        setCreditCardPayments(settings.acceptCreditCard);
        setAbsorbFees(settings.absorbFees);
        setFullPayment(settings.requireFullPayment);
        setPreventPayment(settings.preventPayments);

        let defaultSettings = {
          address: propertyData.address,
          bedrooms: Math.ceil(propertyData.bedrooms),
          bathrooms: propertyData.bathrooms,
          unit: propertyData.address2,
          rentDay: propertyData.rentDueDay,
          rentAmount: propertyData.rentAmount.toFixed(2),
          percent: settings.absorbFeesPercent,
          propertyCategory: propertyData.commercial,
        };

        if (session.userData.servicePlan.metaFeatures.aDDITIONALAssessments) {
          defaultSettings.complianceLevel = settings.complianceLevel;

          defaultSettings = setDefaultSettings(defaultSettings, 'edit', feesRes);
        }

        form.setFieldsValue(defaultSettings);
        if (propertyData.commercial) {
          setIsCommercial(true);
        }
      }

      // Default settings

      if (!propertyData) {
        setCreditCardPayments(propertyGroup.settings.acceptCreditCard);
        setAbsorbFees(propertyGroup.settings.absorbFees);
        setPreventPayment(propertyGroup.settings.preventPayments);
        setFullPayment(propertyGroup.settings.requireFullPayment);

        let defaultSettings = {
          percent: propertyGroup.settings.absorbFeesPercent,
          complianceLevel: propertyGroup.settings.complianceLevel,
        };

        if (session.userData.servicePlan.metaFeatures.aDDITIONALAssessments) {
          if (propertyGroup.settings.hasOwnProperty('propertyFees')) {
            defaultSettings = setDefaultSettings(defaultSettings, 'default', null);
          }
        }
        form.setFieldsValue(defaultSettings);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setDefaultSettings = (settingsObj, mode, feesResponse) => {
    // const { userData } = session;
    // const { paymentSettings } = userData;
    // const { propertyFees } = paymentSettings;

    const propertyFees = propertyGroup ? propertyGroup.settings.propertyFees : null;

    settingsObj.securityDepositSwitch =
      mode === 'default' ? propertyFees.securityDeposit : feesResponse.securityDeposit;
    settingsObj.securityDepositAmount =
      mode === 'default' ? propertyFees.securityDepositAmount || '' : feesResponse.securityDepositAmount || '';
    settingsObj.discountAmountEarly =
      mode === 'default' ? propertyFees.discountAmount || '' : feesResponse.discountAmount || '';
    settingsObj.daysEarly = mode === 'default' ? propertyFees.discountDays || '' : feesResponse.discountDays || '';
    settingsObj.daysEarlySwitch = mode === 'default' ? propertyFees.discount : feesResponse.discount;
    settingsObj.lateFeeAmount =
      mode === 'default' ? propertyFees.lateFeeAmount || '' : feesResponse.lateFeeAmount || '';
    settingsObj.lateFeeFrequency =
      mode === 'default' ? propertyFees.lateFeeFrequency || '' : feesResponse.lateFeeFrequency || '';
    settingsObj.daysLateFee = mode === 'default' ? propertyFees.lateFeeDays || '' : feesResponse.lateFeeDays || '';
    settingsObj.daysLateSwitch = mode === 'default' ? propertyFees.lateFee : feesResponse.lateFee;
    settingsObj.parkingFeeAmount =
      mode === 'default' ? propertyFees.parkingAmount || '' : feesResponse.parkingFeeAmount || '';
    settingsObj.periodParkingFee =
      mode === 'default' ? propertyFees.parkingFrequency || '' : feesResponse.parkingFeeFrequency || '';
    settingsObj.parkingFeeSwitch = mode === 'default' ? propertyFees.parking : feesResponse.parkingFee;
    settingsObj.moveInFeeSwitch = mode === 'default' ? propertyFees.moveIn : feesResponse.moveInFee;
    settingsObj.moveInFee =
      mode === 'default' ? propertyFees.moveInAmount || '' : feesResponse.moveInFeeAmount || '';
    settingsObj.moveOutFeeSwitch = mode === 'default' ? propertyFees.moveOut : feesResponse.moveOutFee;
    settingsObj.moveOutFee =
      mode === 'default' ? propertyFees.moveOutAmount || '' : feesResponse.moveOutFeeAmount || '';
    return settingsObj;
  };

  const displayAlert = (text, type, description, time, close, marginBottom) => {
    if (message.message) {
      setMessage({
        message: '',
        description: '',
      });
    }
    setMessage({
      message: text,
      type,
      description,
      closable: !close,
      marginBottom,
    });
    if (close) {
      setTimeout(() => {
        setMessage({
          message: '',
          description: '',
          marginBottom: 0,
        });
      }, time);
    }
  };

  const onFinish = async (values) => {
    try {
      let parentPropertyId = selectedParentProperty ? +selectedParentProperty.id : +editProperty.parentPropertyId;
      values.acceptCreditCard = creditCardPayments;
      values.absorbFees = absorbFees;
      values.preventPayment = preventPayment;
      values.fullPayment = fullPayment;
      values.propertyGroup = +propertyGroup.id;
      values.parentPropertyId = parentPropertyId;

      setIsLoading(true);
      let complianceLevel = false;

      if (session.userData.servicePlan.metaFeatures.rENTCOLLECTIONPOLICYManagement) {
        complianceLevel = true;
      }

      const property = {
        address: '',
        address2: values.unit,
        rentDueDay: values.rentDay,
        rentAmount: values.rentAmount,
        commercial: values.propertyCategory,
      };

      if (locationData) {
        property.address = !locationData.streetNumber
          ? locationData.address
          : `${locationData.streetNumber} ${locationData.address}`;
        property.city = locationData.city;
        property.googlePlaceId = locationData.google_place_id;
        property.lat = locationData.lat;
        property.long = locationData.long;
        property.state = locationData.state;
        property.zip = locationData.zip;
      }

      await updatePropertyPreferences(editProperty.propertyId, property, values, complianceLevel);
      await updateCreatePropertyFees(cognitoToken, values, editProperty.propertyId);
      dispatch(
        updateQuickStartStatusAction(
          session.userData.id,
          { ...quickStart, lastCompletedStep: currentStep },
          cognitoToken
        )
      );
      setIsLoading(false);
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.title;
      displayAlert(detail || 'Request failed.', 'warning', '', 2000, true);
    }
  };

  const onValuesChange = (values) => {
    if (values.securityDepositAmount) {
      if (isNaN(values.securityDepositAmount)) {
        form.setFieldsValue({ securityDepositAmount: '' });
      }
    }
    if (values.discountAmountEarly) {
      if (isNaN(values.discountAmountEarly)) {
        form.setFieldsValue({ discountAmountEarly: '' });
      }
    }
    if (values.daysEarly) {
      if (isNaN(values.daysEarly)) {
        form.setFieldsValue({ daysEarly: '' });
      }
    }
    if (values.lateFeeAmount) {
      if (isNaN(values.lateFeeAmount)) {
        form.setFieldsValue({ lateFeeAmount: '' });
      }
    }
    if (values.daysLateFee) {
      if (isNaN(values.daysLateFee)) {
        form.setFieldsValue({ daysLateFee: '' });
      }
    }
    if (values.parkingFeeAmount) {
      if (isNaN(values.parkingFeeAmount)) {
        form.setFieldsValue({ parkingFeeAmount: '' });
      }
    }
    if (values.moveInFee) {
      if (isNaN(values.moveInFee)) {
        form.setFieldsValue({ moveInFee: '' });
      }
    }
    if (values.moveOutFee) {
      if (isNaN(values.moveOutFee)) {
        form.setFieldsValue({ moveOutFee: '' });
      }
    }
    // Switchs
    if (values?.securityDepositSwitch === false) {
      form.resetFields(['securityDepositAmount']);
    }
    if (values?.daysEarlySwitch === false) {
      form.resetFields(['discountAmountEarly', 'daysEarly']);
    }

    if (values?.complianceLevel != undefined) {
      // if (form.getFieldValue('complianceLevel') === 0) {
      //   form.setFieldsValue({ daysLateSwitch: false });
      //   form.resetFields(['lateFeeAmount', 'daysLateFee', 'lateFeeFrequency']);
      // }

      if (form.getFieldValue('complianceLevel') === 1 || form.getFieldValue('complianceLevel') === 2) {
        form.setFieldsValue({ daysLateSwitch: true });
      }
    }

    if (values?.daysLateSwitch != undefined) {
      form.setFieldsValue({ daysLateSwitch: values?.daysLateSwitch });
    }
    if (values?.daysLateSwitch === false) {
      form.resetFields(['lateFeeAmount', 'daysLateFee']);
    }
    if (values?.parkingFeeSwitch === false) {
      form.resetFields(['parkingFeeAmount', 'periodParkingFee']);
    }
    if (values?.moveInFeeSwitch === false) {
      form.resetFields(['moveInFee']);
    }
    if (values?.moveOutFeeSwitch === false) {
      form.resetFields(['moveOutFee']);
    }
    if (values?.complianceLevel) {
      form.setFieldsValue({ daysLateSwitch: true });
    }

    if (values?.hasOwnProperty('propertyCategory')) return setIsCommercial(values.propertyCategory);
  };

  const handleCreditCardPayments = (e) => {
    setCreditCardPayments(e.target.checked);
  };

  const handleAbsorbFees = (e) => {
    setAbsorbFees(e.target.checked);
  };

  const handleFullPayment = (e) => {
    setFullPayment(e.target.checked);
  };

  const handlePreventPayment = (e) => {
    setPreventPayment(e.target.checked);
  };

  const datesArray = () => {
    let days = [];
    for (let i = 1; i <= 31; i += 1) {
      days[i] = {
        label: `${numberTOrdinalSuffix(i)} of the month`,
        value: i,
      };
    }
    setFormDays(days);
  };

  const checkPrice = (rule, value) => {
    if (Number(value) >= 0) {
      return Promise.resolve();
    }
    return Promise.reject('Payment amount cannot be empty');
  };

  const checkPercentage = (rule, value) => {
    if (absorbFees) {
      if (Number(value) <= 100 && Number(value) > 0) {
        return Promise.resolve();
      }
      return Promise.reject('Please enter a valid percentage.');
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (customAddressInput.current || googleSelected.current) {
      form.validateFields(['address']);
    }
  }, [locationData]);

  const checkNumber = (rule, value) => {
    if (!value) {
      return Promise.reject('Required');
    }
    if (value) {
      if (Number(value) >= 0) {
        return Promise.resolve();
      }
      return Promise.reject('Value should be a number');
    }
  };

  const checkNumberInt = (rule, value) => {
    if (!value) {
      return Promise.reject('Required');
    }
    if (value) {
      const n = +value;
      if (n > 0) {
        if (Number.isInteger(n)) {
          return Promise.resolve();
        }
      }
      return Promise.reject('Value should be a number');
    }
  };

  const handleInputLeave = (e) => {
    const number = Number(e.target.value).toFixed(2);
    amountInput.current.blur();

    form.setFieldsValue({
      rentAmount: number,
    });
  };

  if (isLoadingProperty || isLoadingUnit) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        className="spin-container"
      >
        <Spin spinning={true} />
      </div>
    );
  } else if (isErrorProperty || isErrorUnit) {
    return <OnboardingResult />;
  } else {
    return (
      <StyledForm
        form={form}
        name="unit-onboarding-form"
        data-testid="unitOnboardingFormTest"
        initialValues={{
          propertyCategory: false,
          bedrooms: 0,
          bathrooms: 0.0,
          percent: 100,
          acceptCreditCard: true,
          absorbFees: session.userData.servicePlan.metaFeatures.rENTERPAYSFees,
          securityDepositSwitch: false,
          daysEarlySwitch: false,
          daysLateSwitch: false,
          parkingFeeSwitch: false,
          moveInFeeSwitch: false,
          moveOutFeeSwitch: false,
        }}
        onFinish={onFinish}
        onFinishFailed={() => {}}
        onValuesChange={onValuesChange}
      >
        <Row gutter={16}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Form.Item name="propertyCategory" label="Type">
              <Segmented
                options={[
                  { label: 'Residential', value: false },
                  { label: 'Commercial', value: true },
                ]}
                size="small"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="general-settings">
          <Col span={8}>
            Unit No.
            <Form.Item name="unit">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            Bedrooms
            <Form.Item
              name="bedrooms"
              rules={
                !isCommercial && [
                  {
                    validator: checkNumberInt,
                  },
                ]
              }
            >
              <Input disabled={isCommercial} />
            </Form.Item>
          </Col>
          <Col span={8}>
            Baths
            <Form.Item
              name="bathrooms"
              rules={
                !isCommercial && [
                  {
                    validator: checkNumber,
                  },
                ]
              }
            >
              <Input disabled={isCommercial} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15} className="general-settings">
          <Col span={16}>
            <br />
            <Form.Item
              name="rentDay"
              whitespace="true"
              rules={[
                {
                  required: true,
                  message: 'Please enter your rent date',
                },
              ]}
            >
              <Select placeholder="Rent Due Date" onChange={props.handleSelect} data-testid="dueDayTest">
                {formDays.map((d, i) => (
                  <Option value={d.value} key={i}>
                    {d.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            Amount
            <Form.Item
              name="rentAmount"
              data-testid="amountTest"
              rules={[
                {
                  validator: checkPrice,
                },
              ]}
            >
              <Input
                data-testid="amountInputTest"
                ref={(input) => (amountInput.current = input)}
                onMouseLeave={handleInputLeave}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space vertical={20} />
        <Text color="black" strong>
          Property Settings
        </Text>
        <PaymentControlsContainer>
          <Form.Item
            name="acceptCreditCard"
            getValueProps={() => creditCardPayments}
            style={{ marginBottom: '10px !important' }}
          >
            <Checkbox
              onClick={handleCreditCardPayments}
              checked={creditCardPayments}
              // disabled={!propertyGroup?.settings?.acceptCreditCard || false}
            >
              <Text color="#595959">Accept credit card payments</Text>
              <Tooltip
                placement="top"
                title="Choose to allow renters to pay rent with a credit card. Renters pay these fees."
                trigger="click"
              >
                <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ccTooltip" />
              </Tooltip>
            </Checkbox>
          </Form.Item>
          {session.userData.servicePlan.metaFeatures.rENTERPAYSFees && (
            <Row>
              <Col span={6} className="absorbField">
                <Form.Item name="absorbFees" getValueProps={() => absorbFees}>
                  <Checkbox onClick={handleAbsorbFees} checked={absorbFees}>
                    <Text color="#595959">Absorb</Text>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item>
                  <Form.Item
                    name="percent"
                    noStyle
                    rules={[
                      {
                        validator: checkPercentage,
                      },
                    ]}
                  >
                    <Input disabled={!absorbFees} style={{ width: '23%' }} data-testid="absorbPercentTest" />
                  </Form.Item>
                  <span>&nbsp;&nbsp;percent of ACH fees</span>
                  <Tooltip
                    placement="top"
                    title="Absorb, split or pass-through ACH transaction fees. If selected, you are paying some percentage of ACH fees."
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="absorbFeeTooltip" />
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item name="fullPayment" getValueProps={() => fullPayment}>
            <Checkbox onClick={handleFullPayment} checked={fullPayment}>
              <Text color="#595959">Require full payment</Text>
              <Tooltip
                placement="top"
                title="If selected, renters must pay their full account balance. If their account balance is zero, they cannot make a payment"
                trigger="click"
              >
                <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="fullPaymentTooltip" />
              </Tooltip>
            </Checkbox>
          </Form.Item>
          <Form.Item name="preventPayment" getValueProps={() => preventPayment}>
            <Checkbox onClick={handlePreventPayment} checked={preventPayment}>
              <Text color="#595959">Prevent payment</Text>
              <Tooltip
                placement="top"
                title="If selected, renters are prevented from making any payments."
                trigger="click"
              >
                <InfoCircleFilled style={{ marginLeft: '8px' }} />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </PaymentControlsContainer>
        <Space vertical={20} />
        <PropertyRentPolicy
          servicePlan={session.userData.servicePlan}
          setShowAccountPreferences={props.setShowAccountPreferences}
          setShowUnitPanel={props.setShowUnitPanel}
          setDrawerVisible={props.setDrawerVisible}
        />
        <AntSpace direction="horizontal">
          <Text strong color="black">
            Additional Assessments
          </Text>
          <Tooltip
            placement="top"
            title="These are additional billing items automatically posted to a renters account based on the rules you set below."
            trigger="click"
          >
            <InfoCircleFilled style={{ marginLeft: '8px' }} />
          </Tooltip>
        </AntSpace>
        <PaymentControlsContainer>
          {!session.userData.servicePlan.metaFeatures.aDDITIONALAssessments ? (
            <AdvancedFeaturesOverlay
              setDrawerVisible={props.setDrawerVisible}
              setShowAccountPreferences={props.setShowAccountPreferences}
              setShowUnitPanel={props.setShowUnitPanel}
            />
          ) : (
            ''
          )}
          <AdditionalFeesFields form={form} />
        </PaymentControlsContainer>
      </StyledForm>
    );
  }
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const PaymentControlsContainer = styled.div`
  margin-top: 10px;
  position: relative;
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

export default UnitForm;
