import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown, Card as AntCard, Tooltip } from 'antd';

import { BankFilled, CreditCardFilled, PlusCircleFilled, InfoCircleFilled } from '@ant-design/icons';

import Link from 'components/Link';

const AddPaymentMethod = (props) => {
  const userData = useSelector((store) => store.session.userData);
  const servicePlanData = useSelector((store) => store.session.userData.servicePlan);

  const addPayMethodMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          if (userData.paymentGateway === 'Wepay') {
            props.AddBankAccount(null);
            if (props.setDrawerVisible) props.setDrawerVisible(false);
            if (props.setShowPayment) props.setShowPayment(false);
            if (props.setShowPaymentStatus) props.setShowPaymentStatus(false);
          }

          // if (userData.paymentGateway === 'Nuvei') {
          //   open();
          // }
        }}
        data-testid="addBankAccountTest"
      >
        <BankFilled
          style={{
            marginRight: '10px',
            fontSize: '20px',
            color: 'black',
          }}
        />
        Add a bank account
        <Tooltip
          placement="right"
          title={`${(Number(servicePlanData?.achRateFee) * 100).toFixed(2)}%`}
          trigger="click"
          data-testid="tooltipContent"
        >
          <InfoCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '13px',
              color: 'black',
            }}
            data-testid="achTooltipTest"
          />
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          props.setShowPayment(false);
          if (props.setShowPaymentStatus) props.setShowPaymentStatus(false);
          props.setShowPaymentMethodDetail(true);
        }}
        disabled={!userData?.property?.paymentSettings?.acceptCreditCard}
        data-testid="addCreditCardTest"
      >
        <CreditCardFilled
          style={{
            marginRight: '10px',
            fontSize: '20px',
            color: 'black',
          }}
        />
        Add a credit card
        <Tooltip
          placement="right"
          title={`${(Number(servicePlanData?.creditCardRateFee) * 100).toFixed(2)}% + $${Number(
            servicePlanData?.creditCardTrxFee
          ).toFixed(2)}`}
          trigger="click"
        >
          <InfoCircleFilled
            style={{
              marginLeft: '10px',
              fontSize: '13px',
              color: 'black',
            }}
            data-testid="ccTooltipTest"
          />
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const items = [
    {
      label: (
        <>
          <BankFilled
            style={{
              marginRight: '10px',
              fontSize: '20px',
              color: 'black',
            }}
          />
          Add a bank account
          <Tooltip
            placement="right"
            title={`${(Number(servicePlanData?.achRateFee) * 100).toFixed(2)}%`}
            trigger="click"
            data-testid="tooltipContent"
          >
            <InfoCircleFilled
              style={{
                marginLeft: '10px',
                fontSize: '13px',
                color: 'black',
              }}
              data-testid="achTooltipTest"
            />
          </Tooltip>
        </>
      ),
      onClick: () => {
        if (userData.paymentGateway === 'Wepay') {
          props.AddBankAccount(null);
          if (props.setDrawerVisible) props.setDrawerVisible(false);
          if (props.setShowPayment) props.setShowPayment(false);
          if (props.setShowPaymentStatus) props.setShowPaymentStatus(false);
        }
      },
      'data-testid': 'addBankAccountTest',
    },
    {
      label: (
        <>
          <CreditCardFilled
            style={{
              marginRight: '10px',
              fontSize: '20px',
              color: 'black',
            }}
          />
          Add a credit card
          <Tooltip
            placement="right"
            title={`${(Number(servicePlanData?.creditCardRateFee) * 100).toFixed(2)}% + $${Number(
              servicePlanData?.creditCardTrxFee
            ).toFixed(2)}`}
            trigger="click"
          >
            <InfoCircleFilled
              style={{
                marginLeft: '10px',
                fontSize: '13px',
                color: 'black',
              }}
              data-testid="ccTooltipTest"
            />
          </Tooltip>
        </>
      ),
      onClick: () => {
        props.setShowPayment(false);
        if (props.setShowPaymentStatus) props.setShowPaymentStatus(false);
        props.setShowPaymentMethodDetail(true);
      },
      disabled: !userData?.property?.paymentSettings?.acceptCreditCard,
      'data-testid': 'addCreditCardTest',
    },
  ];
  return (
    <Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
      <Link to="#" centered data-testid="addPaymentMethodTest">
        <PlusCircleFilled
          style={{
            color: '#85BB65',
            fontSize: '20px',
            marginRight: '10px',
          }}
        />
        Add a payment method
      </Link>
    </Dropdown>
  );
};

export default AddPaymentMethod;
