import React from 'react';

import Text from 'components/Text';
import Tag from 'components/Tag';

const CreditBoostTitle = (props) => {
  const { locked } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Text color="violet2" size={16} strong>
        Credit Reporting
      </Text>
      <Tag color="purple">{locked ? 'Locked' : 'Unlocked'}</Tag>
    </div>
  );
};

export default CreditBoostTitle;
