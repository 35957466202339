import axios from 'axios';
import Jsona from 'jsona';
import { refreshToken } from '../../aws/cognito';
import store from '../../../store';

const dataFormatter = new Jsona();

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const deleteBillingItem = async (id) => {
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL}/billing-items/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deleteInvite = async (id) => {
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL}/invites/${id}`,
    };
    const res = await axios(options);
    return res;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deleteInviteV3 = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/renter/${id}`,
    };
    await axios(options);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deleteStripePaymentMethod = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}/billing-details`,
    };
    await axios(options);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deletePropertyGroup = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/property-groups/${id}`,
    };
    await axios(options);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deleteProperty = async (id) => {
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL}/properties/${id}`,
    };
    const res = await axios(options);
    return res;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deleteParentProperty = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/parent-properties/${id}`,
    };
    await axios(options);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

// deprecated version 4.0 2024-01-01

export const deletePaymentMethod = async (id) => {
  try {
    const oldApiToken = sessionStorage.getItem('payrent.session');

    const url = `${process.env.REACT_APP_API_URL}/payment-methods/${id}`;

    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${oldApiToken}`,
      },
      url,
    };

    const res = await axios(options);
    return res;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deleteDocument = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/documents/${id}`,
    };
    const res = await axios(options);
    return res;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const revokeEsignDocument = async (cognitoToken, id, docData) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: docData,
      },
    };
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/esign/${id}/revoke`,
    };
    const res = await axios(options);
    return res;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};
