import { ClockCircleOutlined } from '@ant-design/icons';
import { Result, theme } from 'antd';
import React from 'react';
import styled from 'styled-components';

function DetailsPending(props) {
  const { token } = theme.useToken();
  return (
    <Result
      icon={<ClockCircleOutlined style={{ color: token.colorWarning }} />}
      title="Settlement Accruing..."
      subTitle={
        <span>
          Details for this settlement have not been finalized.
          <br />
          Please check back later.
        </span>
      }
    />
  );
}

export default DetailsPending;
