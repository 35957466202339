import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { ReactComponent as FilledLogo } from 'assets/filled-logo.svg';

import Text from 'components/Text';
import Space from 'components/Space';
import AccountStatus from '../AccountStatus/AccountStatus';

const AccountStatusTitle = (props) => {
  const { userData } = props;

  const getAddress = (property) => {
    if (property) {
      if (property.address && property.address2) {
        return `${property.address}, ${property.address2}`;
      }
      return property.address;
    }
  };

  return (
    <StyledDiv>
      <FilledLogo />
      <Space vertical={2} />
      <StyledResponsiveText color="black" weight={500}>{`Address: ${
        userData.property ? getAddress(userData.property) : ''
      }`}</StyledResponsiveText>
      {userData.loading ? (
        <LoadingContainer>
          <Spin />
        </LoadingContainer>
      ) : (
        <AccountStatus status={userData.state} userId={userData.id} />
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
`;

const StyledResponsiveText = styled(Text)`
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export default AccountStatusTitle;
