import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.session.userRole);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => {
        if (!userRole) {
          // Not logged in
          if (history.location?.pathname) {
            const params = new URLSearchParams(history.location.search);
            const panel = params.get('panel');

            dispatch({
              type: 'SET_REDIRECT_PATHNAME',
              payload: history.location.pathname,
            });

            if (panel) {
              dispatch({
                type: 'SET_PANEL',
                payload: panel,
              });
            }
          }

          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: props.location },
              }}
            />
          );
        }

        const userHasRole = () => roles.some((role) => userRole === role);
        if (!userHasRole()) {
          // Not authorized - Redirect to the dashboard user
          return <Redirect to={{ pathname: `/${userRole}` }} />;
        }

        // Authorized
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
