import { Alert, Card, Modal, message } from 'antd';
import React, { useMemo, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { addCardMethod } from 'services/wepay/paymentMethod';
import wePayCheckout from '../../../../services/wepay/payment';
import PaymentForm from './components/PaymentForm';
import { adminFetchUserServicePlan } from '../../../../services/api/adminApi/read';
import PaymentThankYou from './components/PaymentThankYou';

export default function PublicCheckout() {
  const [searchData, setSearchData] = useState();
  const [isAllowed, setIsAllowed] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState();
  const [transaction, setTransaction] = useState({});
  const [bankInfo, setBankInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentSummary, setPaymentSummary] = useState({ total: 0, fee: 0 });
  const [activePaymentMethod, setActivePaymentMethod] = useState('creditcard');
  const [cardValidation, setCardValidation] = useState({});
  const [pricing, setPricing] = useState();
  const { search } = useLocation();
  const params = useParams();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  // console.log(params, search, query.get('ref'));

  const decodeReferenceData = (str) => {
    try {
      const buff = Buffer.from(str, 'base64');
      const decodedRef = buff.toString('ascii');
      console.log(JSON.parse(decodedRef));
      setSearchData(JSON.parse(decodedRef));
    } catch (err) {
      console.error('Invalid ref data');
    }
  };

  const onFinish = async (values) => {
    console.log('ƒ onFinish', values, cardValidation);
    if (!cardValidation.isValid) return message.error('Payment method is not valid');
    setLoading(true);
    if (activePaymentMethod === 'creditcard') {
      const data = {
        userName: values.cardholderName,
        cardNumber: values.cardholderNumber.replace(' ', ''),
        cvv: values.cvv,
        postalCode: values.zip,
        expirationDate: `${values.expDate.split('/')[0]}-01-20${values.expDate.split('/')[1]}`,
      };
      addCardMethod(values.email, data)
        .then(async (result) => {
          console.log('paymentMethod result', result);
          try {
            const paymentData = {
              amount: paymentSummary.total,
              type: 'credit_card',
              id: result.metaData.TOKENIZATION.credit_card_id,
              shortDescription: values.reference,
              fee: { appFee: paymentSummary.fee.toFixed(2), feePayer: 'payer' },
            };
            await wePayCheckout(paymentData, params.pid).then((res) => {
              console.log('wePayCheckout result ', res);
              if (res.errorCode) return message.error(res.errorDescription, 3);
              setTransaction({ ...res, paymentMethodLabel: result.label });
              setIsSuccessful(true);
            });
            setLoading(false);

            return 'success';
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => console.error(err));
    } else {
      // console.log('ach', values, bankInfo);
      try {
        const paymentData = {
          amount: paymentSummary.total,
          type: 'payment_bank',
          id: bankInfo.metaData.TOKENIZATION.bank_account_id,
          shortDescription: values.reference,
          fee: { appFee: paymentSummary.fee, feePayer: 'payer' },
        };
        await wePayCheckout(paymentData, params.pid).then((res) => {
          if (res.errorCode) return message.error(res.errorDescription, 3);
          setTransaction({ ...res, paymentMethodLabel: bankInfo.label });
          setIsSuccessful(true);
        });
        setLoading(false);
      } catch (err) {
        throw new Error(err);
      }
    }
  };

  useEffect(() => {
    if (params.id) {
      adminFetchUserServicePlan(params.id)
        .then((data) => {
          // console.log(data);
          if (data.servicePlan.processor.name !== 'Wepay') setIsAllowed(false);
          const { achRateFee, achTrxFee, creditCardRateFee, creditCardTrxFee } = data.servicePlan;
          setPricing({ achRateFee, achTrxFee, creditCardRateFee, creditCardTrxFee });
        })
        .catch(() => setIsAllowed(false));
    }
    if (search && isAllowed) {
      decodeReferenceData(query.get('ref'));
    }
  }, [search, query, params]);

  return (
    <div
      style={{
        background: '#f7f7f0',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '24px',
        paddingBottom: '24px',
        minHeight: '100vh',
      }}
    >
      <Modal
        open={!isAllowed}
        closable={false}
        footer={null}
        styles={{ body: { padding: '48px' } }}
        data-testid="unauthorizedModal"
      >
        <Alert
          type="error"
          showIcon
          message="Unauthorized"
          description="You are not authorized to use this checkout page. Please contact customer support."
        />
      </Modal>
      <Card
        title="Make a Payment"
        style={{ minWidth: '414px', width: '640px', overflow: 'hidden' }}
        data-testid="formContainer"
      >
        {isSuccessful ? (
          <PaymentThankYou transaction={transaction} data-testid="paymentThankYou" />
        ) : (
          <PaymentForm
            cardValidation={cardValidation}
            setCardValidation={setCardValidation}
            activePaymentMethod={activePaymentMethod}
            setActivePaymentMethod={setActivePaymentMethod}
            bankInfo={bankInfo}
            setBankInfo={setBankInfo}
            loading={loading}
            paymentSummary={paymentSummary}
            setPaymentSummary={setPaymentSummary}
            onFinish={onFinish}
            fieldValues={searchData}
            pricing={pricing}
          />
        )}
      </Card>
    </div>
  );
}
