import React from 'react';

// Components

import Text from 'components/Text';

const FinancialGoalsTitle = (props) => {
  const { setDrawerVisible, setShowRentCredProfile } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Text color="violet2" size={16} strong>
        Financial Goals
      </Text>
      <Text
        color="violet2"
        size={14}
        pointer
        onClick={() => {
          setDrawerVisible(true);
          setShowRentCredProfile(true);
        }}
      >
        Edit
      </Text>
    </div>
  );
};

export default FinancialGoalsTitle;
