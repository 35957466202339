import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import FileType from 'file-type/browser';
import sanitize from 'sanitize-filename';
import axios from 'axios';

import { Drawer } from 'antd';
import { clearPanel } from 'store/actions/globalActions';
import { documentsPresignedUrl } from 'services/api/common/create';

import { ArrowLeftOutlined } from '@ant-design/icons';

import GetUploadInsurance from './GetUploadInsurance';
import InsuranceSteps from './InsuranceSteps';
import PolictyDetails from './PolicyDetails';
import UploadPolicyDetails from './UploadPolicyDetails';
import { sendSibEvent } from 'utils/sendInBlue';

const Insurance = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((store) => store.session);
  const panel = useSelector((store) => store.global.panel);
  const {
    insuranceDrawer,
    setInsuranceDrawer,
    setGetUploadVisible,
    setShowPolicyDetails,
    setShowUploadedPolicyDetails,
    showUploadedPolicyDetails,
    fetchInsurancePolicy,
    userPolicyDetail,
    userPolicy,
  } = props;
  const [insuranceStepsVisible, setInsuranceStepsVisible] = useState(false);
  const [showHistory, setShowHistory] = useState(true);
  const [showQuote, setShowQuote] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);

  const [alert, setAlert] = useState({
    message: 'Quote Error',
    description: 'We’re sorry but we are unable to issue a policy at this time.',
    type: 'error',
    visible: false,
  });

  window.onload = function () {
    initBeforeUnLoad(processingPayment);
  };

  const initBeforeUnLoad = (processingPayment) => {
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (processingPayment) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      }
    };
  };

  useEffect(() => {
    initBeforeUnLoad(processingPayment);
  }, [processingPayment]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (processingPayment) {
        return window.confirm('Are you sure you want to leave?');
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [processingPayment]);

  const getStep = () => {
    if (showHistory) {
      return 0;
    }
    if (showQuote) {
      return 1;
    }
    if (showCheckout) {
      return 2;
    }
    if (showConfirmation) {
      return 3;
    }
  };

  const handleUploadFile = async (info, record) => {
    const { file } = info;

    try {
      const type = await FileType.fromBlob(file);

      if (type === undefined) throw Error('Invalid file type');

      if (type?.ext !== 'pdf' && type?.ext !== 'jpg' && type?.ext !== 'jpeg') {
        throw Error('Invalid File Type');
      }
      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }
      const ext = type.mime === 'image/jpeg' ? 'jpeg' : type.ext;

      const ba = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      const sanitizedFilename = sanitize(file.name);
      const cleanFilename = sanitizedFilename.replace(/[^a-zA-Z0-9-_\.]/g, '');

      if (ba.length) {
        const res = await documentsPresignedUrl(
          session.cognitoTokens.idToken.jwtToken,
          ba[0].id,
          'upload',
          `${cleanFilename}`,
          'insurance',
          ext
        );

        const instance = axios.create({
          headers: { 'Content-Type': file.type },
        });
        instance.defaults.timeout = 1000 * 60;
        delete instance.defaults.headers.common['Authorization'];
        delete instance.defaults.headers.common['Accept'];

        await instance.put(res.presignedUrl, file);
        await sendSibEvent('insurance_uploaded', { EMAIL: session.userData.email });
        dispatch({ type: 'SET_INSURANCE_POLICY_FILE', payload: true }); // to disable buttons
        fetchInsurancePolicy();
      } else {
        throw Error('User needs an active billing account');
      }
    } catch (err) {
      const { response } = err;
      throw response || err;
    }
  };

  const handleCloseDrawer = () => {
    setInsuranceDrawer(false);
    props.setGetUploadVisible(false);
    props.setShowPolicyDetails(false);
    setInsuranceStepsVisible(false);
    setShowUploadedPolicyDetails(false);
    setShowCheckout(false);
    setShowQuote(false);
    setShowHistory(true);
    setShowConfirmation(false);
    setAlert({ ...alert, visible: false });
  };

  return (
    <StyledDrawer
      closable={false}
      placement="right"
      destroyOnClose
      onClose={() => {
        if (processingPayment) {
          if (window.confirm('Are you sure you want to leave?')) {
            handleCloseDrawer();
          }
        } else {
          handleCloseDrawer();
        }
      }}
      open={insuranceDrawer}
      getContainer={false}
      styles={{
        body: {
          padding: 0,
        },
      }}
      data-testid="drawerTest"
    >
      <Header>
        <ArrowLeftOutlined
          onClick={() => {
            if (processingPayment) {
              if (window.confirm('Are you sure you want to leave?')) {
                handleCloseDrawer();
              }
            } else {
              handleCloseDrawer();
            }
          }}
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowButtonTest"
        />
        Renters Insurance
      </Header>
      {props.getUploadVisible && (
        <GetUploadInsurance
          setGetUploadVisible={setGetUploadVisible}
          setInsuranceStepsVisible={setInsuranceStepsVisible}
          setShowUploadedPolicyDetails={setShowUploadedPolicyDetails}
          setInsuranceDrawer={setInsuranceDrawer}
          userPolicy={userPolicy}
          handleUploadFile={handleUploadFile}
        />
      )}
      {insuranceStepsVisible && (
        <InsuranceSteps
          setInsuranceDrawer={setInsuranceDrawer}
          alert={alert}
          setAlert={setAlert}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          showQuote={showQuote}
          setShowQuote={setShowQuote}
          showCheckout={showCheckout}
          setShowCheckout={setShowCheckout}
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          getStep={getStep}
          fetchInsurancePolicy={fetchInsurancePolicy}
          setInsuranceStepsVisible={setInsuranceStepsVisible}
          processingPayment={processingPayment}
          setProcessingPayment={setProcessingPayment}
        />
      )}
      {props.showPolicyDetails && (
        <PolictyDetails
          setShowPolicyDetails={setShowPolicyDetails}
          setInsuranceDrawer={setInsuranceDrawer}
          userPolicyDetail={userPolicyDetail}
          fetchInsurancePolicy={fetchInsurancePolicy}
        />
      )}
      {showUploadedPolicyDetails && (
        <UploadPolicyDetails
          userPolicyDetail={userPolicyDetail}
          setInsuranceDrawer={setInsuranceDrawer}
          setShowUploadedPolicyDetails={setShowUploadedPolicyDetails}
          fetchInsurancePolicy={fetchInsurancePolicy}
          handleUploadFile={handleUploadFile}
        />
      )}
    </StyledDrawer>
  );
};

const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

const StyledDrawer = styled(({ ...rest }) => <Drawer {...rest} />)`
  ${() => css`
    .ant-drawer-body {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      width: 480px !important;
      @media screen and (max-width: 480px) {
        width: 100vw !important;
      }
    }
  `}
`;

export default Insurance;
