// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import styledVariables from 'styles/styledComponents/variables';

type Props = {
  color?: 'red' | 'violet' | 'violet2' | 'violet3' | 'grey' | 'yellow' | 'blue' | 'green',
  size?: number,
  centered?: boolean,
  end?: string,
  inline?: boolean,
  children: React.Node,
};

const Link = (props: Props) => {
  const { color, size, centered, inline, children, end, ...rest } = props;

  return (
    <Container centered={centered} inline={inline} end={end} data-name="container">
      <StyledLink {...rest} color={color} size={size} data-name="link">
        {children}
      </StyledLink>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: ${(props) => {
    if (props.centered) {
      return 'center';
    }
    if (props.end === 'true') {
      return 'flex-end';
    }
    return 'inherit';
  }};
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledLink = styled(({ color, ...rest }) => <RouterLink {...rest} />)`
  text-decoration: underline;
  color: ${(props) =>
    props.color ? styledVariables.colors[props.color] : styledVariables.colors.violet} !important;
  font-size: ${(props) => (props.size ? `${props.size}px` : '')};
  display: flex;
  align-items: center;
`;

export default Link;
