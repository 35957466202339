// @flow
import React, { useEffect } from 'react';
import { Button, Form, Input, Card, theme } from 'antd';
import Text from 'components/Text';
import Title from 'components/Title';
import Link from 'components/Link';
// Icons
import LoginOutlined from '@ant-design/icons/LoginOutlined';

// Components
import AlertFade from 'components/AlertFade';
// import SocialButtons from '../SocialButtons';

// type Props = {
//   setAuthError: Object,
//   signInHandler: ({ username: string, password: string }) => Promise<any>,
//   authError: Object,
//   setCurrentTab: (tabName: string) => void,
//   isLoading: boolean,
// };

const SignInForm = (props) => {
  // console.log('[SignInForm.js]', props);
  const { token } = theme.useToken();
  useEffect(
    () =>
      function cleanup() {
        if (props.authError.error) {
          props.setAuthError({ error: false, message: '' });
        }
      },
    [props]
  );

  const handleClose = () => {
    props.setAuthError({ ...props.authError, error: false });
  };

  // Close error after 4 seconds - restore state
  if (props.authError.error) {
    setTimeout(handleClose, 4000);
  }

  const onFinish = (values) => {
    props.signInHandler({ username: values.email, password: values.password });
  };

  const cardStyle = {
    maxWidth: '380px',
    padding: '20px',
  };

  return (
    <div style={{ margin: '0 12px' }}>
      <Card style={cardStyle}>
        <div style={{ textAlign: 'center', height: '110px', marginBottom: '0.5em' }}>
          <Title level={3} color={token.colorTextBase}>
            Sign In To Your Account
          </Title>
          <AlertFade type="error" isVisible={props.authError.error} message={props.authError.message} />
        </div>
        <Form onFinish={onFinish} className="auth-form" size="large" data-testid="signinFormTest">
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not a valid email!',
              },
              {
                required: true,
                message: 'Please enter your email',
              },
            ]}
          >
            <Input placeholder="Email" autoComplete="email" data-testid="emailTest" size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please enter your password' },
              // {
              //   pattern: new RegExp('^(?=.*[A-Z]).{8,}$'),
              //   message: 'Min 8 characters, at least 1 capital and 1 number',
              // },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              data-testid="passwordTest"
            />
          </Form.Item>

          <div style={{ display: 'flex', margin: '5px 2px 10px' }}>
            <Text size={12} color={'black'}>
              Forgot your password?{'  '}
            </Text>
            <Link size={12} to="/auth/forgot-password">
              {' '}
              Reset password
            </Link>
          </div>

          <Form.Item className="margin-botton-10">
            <Button
              block
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
              loading={props.isLoading}
              data-testid="signInButtonTest"
            >
              Sign In
            </Button>
          </Form.Item>
          {/**
          <SocialButtons signInHandler={props.signInHandler} />
          */}
        </Form>
      </Card>
      <div>
        <Text size={16} color={'white'} weight={500}>
          No account?{' '}
        </Text>
        <Button
          onClick={() => props.history.push('/sign-up')}
          type="link"
          style={{ fontSize: '14px', padding: '3px 0px' }}
        >
          Create an Account
        </Button>
      </div>
    </div>
  );
};

export default SignInForm;
