import { wepayServicePlans, silaServicePlans } from './servicePlans';

export function scriptExists(url) {
  return document.querySelectorAll(`script[src="${url}"]`).length > 0;
}

// eslint-disable-next-line import/prefer-default-export
export function loadExternalScript(src) {
  return new Promise((resolve, reject) => {
    if (scriptExists(src)) {
      resolve();
    } else {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';

      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', (e) => {
        reject(e);
      });
      document.body.appendChild(script);
    }
  });
}

export function debounce(func, time = 100) {
  let timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, time, event);
  };
}

// based on https://stackoverflow.com/a/39466341/538901
export function ordinalize(n) {
  if (n < 1) return n;

  return n + ([, 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th');
}

export const openFreshChatWidget = (id, firstName, email, landlord, servicePlan) => {
  if (document.getElementById('fc_frame')) {
    document.getElementById('fc_frame').style.visibility = 'visible';
    document.getElementById('fc_frame').style.removeProperty('display');
    window.fcWidget.setExternalId(id);
    window.fcWidget.user.setFirstName(firstName);
    window.fcWidget.user.setEmail(email);

    window.fcWidget.user.setProperties({
      customer: landlord === 'true' ? 'Landlord' : 'Tenant', // property 1
      plan: servicePlan || '', // service plan of the landlord
    });

    window.fcWidget.open();
  }
};

let plans = wepayServicePlans;

export const costCalculator = (units = 1, servicePlans, processor) => {
  if (processor === 'SilaStripe') {
    plans = silaServicePlans;
  }
  let costArr = [];
  plans.map((p) => {
    const unitCost = units > p.properties ? (units - p.properties) * p.unitPrice : 0.0;
    const subscriptionCost = p.subscriptionFee + unitCost;
    const achCost = units * p.achRate * 1500 + units * p.achFee;
    const totalCost = subscriptionCost + achCost;
    const costObj = {
      servicePlanName: p.name,
      totalCost,
      subscriptionCost,
      achCost,
      bestValue: false,
      id: p.id,
    };
    return costArr.push(costObj);
  });
  const valuePlan = costArr.reduce((prev, curr) => {
    return prev.totalCost < curr.totalCost ? prev : curr;
  });
  valuePlan.bestValue = true;
  //console.log(valuePlan);
  return costArr;
};

export const searchPending = (onBoardStatus) => {
  const keys = Object.keys(onBoardStatus);
  const filteredKeys = keys.filter((k) => k.includes('step'));
  for (let i = 0; i < filteredKeys.length; i++) {
    // console.log(filteredKeys[i], onBoardStatus[filteredKeys[i]]);
    if (onBoardStatus[filteredKeys[i]].status === 'waiting') return i + 1;
    if (onBoardStatus[filteredKeys[i]].status === 'in progess') return i + 1;
    if (onBoardStatus[filteredKeys[i]].status === 'in progress') return i + 1;
    if (onBoardStatus[filteredKeys[i]].status === 'incomplete') return i + 1;
    if (onBoardStatus[filteredKeys[i]].status === 'Pending') {
      if (i > 2) {
        // ask Gelli what status is returned when wepay process fail
        return i + 2;
      }
      return i + 1;
    }
    if (onBoardStatus[filteredKeys[i]].status === 'Failed') {
      if (i > 2) {
        // ask Gelli what status is returned when wepay process fail
        return i + 2;
      }
      return i + 1;
    }
  }
  return -1;
};

export const capSentence = (text) => {
  const wordsArray = text.toLowerCase().split(' ');

  const capsArray = wordsArray.map((word) => {
    return word.replace(word[0], word[0].toUpperCase());
  });

  return capsArray.join(' ');
};

export const vgsValidator = (fields) => {
  for (let i = 0; i < fields.length; i++) {
    if (!fields[i][0].includes('tok_live') && !fields[i][0].includes('tok_sandbox')) {
      throw Error(`${fields[i][1]} was not tokenized`);
    }
  }
};
