import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, Form } from 'antd';
import { useFlagsmith } from 'flagsmith-react';
import { motion } from 'framer-motion';
import { updateLandlordProfile } from 'services/api/landlordApi/update';
import { displayAlert } from 'resources/helpers';
import { getUserDataV3 } from 'store/actions/sessionActions';
import styledVariables from 'styles/styledComponents/variables';
import { costCalculator } from 'utils';
import { ReactComponent as SingleFamily } from 'assets/images/onboarding-fullscreen/single-family.svg';
import { ReactComponent as MultiFamily } from 'assets/images/onboarding-fullscreen/multi-family.svg';
import { ReactComponent as Office } from 'assets/images/onboarding-fullscreen/office.svg';
import { ReactComponent as Industrial } from 'assets/images/onboarding-fullscreen/industrial.svg';
import { ReactComponent as InvertedIndustrial } from 'assets/images/onboarding-fullscreen/inv-ind.svg';
import { ReactComponent as InvertedOffice } from 'assets/images/onboarding-fullscreen/inv-office.svg';
import { ReactComponent as InvertedMultiFamily } from 'assets/images/onboarding-fullscreen/inv-mf.svg';
import { ReactComponent as InvertedSf } from 'assets/images/onboarding-fullscreen/inv-sf.svg';

import Text from 'components/Text';
import SpaceResponsive from 'components/SpaceResponsive';
import Space from 'components/Space';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';
import { boardingElement, boardingText } from '../../animation/variants';

// import './styles.scss';

const YourRentals = (props) => {
  const { setUpsellSp, setUpgradeServicePlan, setYourRentals, setSubscriptionPayment, setSilaAccountSetup } =
    props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const { isLoading, isError, hasFeature, getValue } = useFlagsmith();
  const [disabled, setDisabled] = useState(true);

  const [rentalType, setRentalType] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  useEffect(() => {
    if (session.userData?.landlordMeta) {
      if (session.userData.landlordMeta.landlordProfile) {
        if (session.userData.landlordMeta.landlordProfile?.landlordProfile) {
          const profile = session.userData.landlordMeta.landlordProfile.landlordProfile;
          if (profile.rentalType.length && profile.totalUnits) {
            setRentalType(profile.rentalType);
            calculate({ units: profile.totalUnits }, true);
          }
        }
      }
    }
  }, []);

  const calculate = async (values, resume) => {
    setLoading(true);
    console.log(getValue('onboarding-flow'));
    try {
      console.log(values);
      const units = +values.units;
      const result = costCalculator(units, null, session.userData.paymentGateway);
      let upSell = false;
      // console.log(result);
      // console.log(session);

      const bestValueSp = result.filter((sp) => sp.bestValue);
      const currentSp = result.filter((sp) => sp.id === +session.userData.servicePlan.id);

      if (
        bestValueSp[0].servicePlanName !== session.userData.servicePlan.name.toLowerCase() &&
        bestValueSp[0].servicePlanName !== 'pay as you go' &&
        +session.userData.servicePlan.id !== 13
      ) {
        console.log('upsell');
        console.log('Best value ', bestValueSp);
        console.log('Current Sp', currentSp);
        upSell = true;
        const costDiff = currentSp[0].totalCost - bestValueSp[0].totalCost;
        setUpsellSp({ costDiff, spId: bestValueSp[0].id });
      }
      if (!resume) {
        await updateLandlordProfile(
          units,
          rentalType,
          session.userData.id,
          session.cognitoTokens.idToken.jwtToken
        );
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
      }

      const flowId = getValue('onboarding-flow');
      setLoading(false);

      if (flowId === 1) {
        setYourRentals(false);
        if (upSell) setUpgradeServicePlan(true);
        if (
          +session.userData.servicePlan.id !== 8 &&
          !upSell &&
          +session.userData.servicePlan.id !== 11 &&
          !upSell
        ) {
          // if the user doesnt have a payment method
          setSubscriptionPayment(true);
        }
        if (
          (+session.userData.servicePlan.id === 8 && !upSell) ||
          (+session.userData.servicePlan.id === 11 && !upSell)
        ) {
          setSilaAccountSetup(true);
        }
      }
      if (flowId === 2) {
        setYourRentals(false);
        setSilaAccountSetup(true);
      }
      if (flowId === 3) {
        setYourRentals(false);
        setSilaAccountSetup(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      displayAlert(setAlert, 'There was an error with your request', 'warning', 3000);
    }
  };

  const handleAddRemoveItems = (value) => {
    let types = rentalType;
    if (types.includes(value)) {
      types = types.filter((t) => t !== value);
    } else {
      types.push(value);
    }
    if (types.length && form.getFieldValue('units')) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setRentalType([...types]);
  };

  const onValuesChange = (v) => {
    if (v.units) {
      if (rentalType.length) {
        setDisabled(false);
      }
    }
    if (!v.units) {
      setDisabled(true);
    }
  };

  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong>
          Tell Us About Your Rentals
        </Text>
      </motion.div>
      <SpaceResponsive lg={15} xmd={10} md={10} sm={10} />
      <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave">
        <Form
          name="yourRentals"
          form={form}
          onFinish={calculate}
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          onValuesChange={onValuesChange}
        >
          <ContentContainer className="content-container">
            <TotalUnitsContainer>
              <StyledItem name="units" rules={[{ required: true, message: 'Required' }]} label="Total Units">
                <Input data-testid="unitInput" />
              </StyledItem>
            </TotalUnitsContainer>

            <Space vertical={10} />
            <Text size={16} color="black" strong centered>
              Select Your Rental Types
            </Text>
            <Space vertical={10} />
            <Group>
              <RentalTypeBtn
                className={rentalType.includes('singleFamily') && 'selected-btn'}
                onClick={() => handleAddRemoveItems('singleFamily')}
                data-testid="singleFamilyTest"
              >
                {rentalType.includes('singleFamily') ? <InvertedSf /> : <SingleFamily />}
                <Text size={16} color={rentalType.includes('singleFamily') ? 'white' : 'black'}>
                  Single-family
                </Text>
              </RentalTypeBtn>
              <RentalTypeBtn
                className={rentalType.includes('multiFamily') && 'selected-btn'}
                onClick={() => handleAddRemoveItems('multiFamily')}
              >
                {rentalType.includes('multiFamily') ? <InvertedMultiFamily /> : <MultiFamily />}
                <Text size={16} color={rentalType.includes('multiFamily') ? 'white' : 'black'}>
                  Multi-family
                </Text>
              </RentalTypeBtn>
              <RentalTypeBtn
                className={rentalType.includes('office') && 'selected-btn'}
                onClick={() => handleAddRemoveItems('office')}
                data-testid="officeTest"
              >
                {rentalType.includes('office') ? <InvertedOffice /> : <Office />}
                <Text size={16} color={rentalType.includes('office') ? 'white' : 'black'}>
                  Office
                </Text>
              </RentalTypeBtn>
              <RentalTypeBtn
                className={rentalType.includes('industrial') && 'selected-btn'}
                onClick={() => handleAddRemoveItems('industrial')}
                data-testid="industrialTest"
              >
                {rentalType.includes('industrial') ? <InvertedIndustrial /> : <Industrial />}
                <Text size={16} color={rentalType.includes('industrial') ? 'white' : 'black'}>
                  Industrial
                </Text>
              </RentalTypeBtn>
            </Group>
          </ContentContainer>
          <StyledFooter>
            <AlertFade
              isVisible={alert.isVisible}
              message={alert.message}
              type={alert.type}
              minHeight="80"
              alertPosition="absolute"
              bottom={250}
            />
            <Button
              color="violet"
              alignment="center"
              bottom={0}
              type="primary"
              htmlType="submit"
              loading={loading}
              data-testid="continueTest"
              disabled={disabled}
            >
              CONTINUE
            </Button>
          </StyledFooter>
        </Form>
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: ${styledVariables.colors.white2};
  height: 65% !important;
  border: 1px solid ${styledVariables.colors.grey5};
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-height: 785px) {
    height: 75%;
  }
  @media screen and (max-width: 488px) {
    height: 75%;
    width: 95%;
    padding: 22px 10px 10px 10px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;

  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10%;
  row-gap: 30px;
  justify-content: center;
  @media screen and (max-width: 375px) {
    gap: 5%;
    row-gap: 12px;
  }
`;

const RentalTypeBtn = styled.div`
  width: 140px;
  height: 140px;
  border: 2px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const TotalUnitsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFooter = styled.div`
  min-height: 80px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
`;

const StyledItem = styled(Form.Item)`
  margin-bottom: 0px;
  max-width: 50%;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
`;

export default YourRentals;
