import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from 'antd';

import { getStripeLink } from 'services/api/landlordApi/read';

import Text from 'components/Text';
import Tag from 'components/Tag';
import Space from 'components/Space';

const CreditCardStatus = (props) => {
  const { ccStatus, propertyGroup } = props;

  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const [stripeState, setStripeState] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const stripeLink = await getStripeLink(cognitoToken, +propertyGroup[0].id);
      setStripeState(stripeLink);
    } catch (error) {
      console.log(error);
    }
  };

  const stripeOnboarding = async () => {
    if (stripeState) {
      if (stripeState.url) {
        window.location.href = stripeState.url;
      }
    }
  };

  const updateLoginLink = async () => {
    if (ccStatus.creditCard.loginLink) {
      window.location.href = ccStatus.creditCard.loginLink.url;
    }
  };

  return (
    <>
      {ccStatus.creditCard.chargesEnabled && ccStatus.creditCard.payoutsEnabled ? (
        <>
          <Row justify="center" gutter={[0, 20]}>
            <Col span={20}>
              <Tag color="green">Payments Enabled</Tag>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}></Col>
          </Row>
          <Space vertical={10} />
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Text size={16} weight={500} color="black">
                Settlement Bank
              </Text>
            </Col>
          </Row>
          <Space vertical={10} />
          <Row gutter={[0, 20]}>
            <Col span={20}>
              <Text size={16} color="black">
                {ccStatus.creditCard.settlement}
              </Text>
            </Col>
          </Row>
        </>
      ) : ccStatus.creditCard.state === 'PENDING' && ccStatus.creditCard.optIn ? (
        <Row justify="center" gutter={[0, 20]}>
          <Col span={20}>
            <Tag color="gold">Payments Pending</Tag>
          </Col>
          <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}></Col>
        </Row>
      ) : ccStatus.creditCard.state === 'ACTION_REQUIRED' && ccStatus.creditCard.optIn ? (
        <Row justify="center" gutter={[0, 20]}>
          <Col span={20}>
            <Tag color="yellow" textColor="red">
              Action Required
            </Tag>
          </Col>
          <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Text color="violet" size="14" underline onClick={() => updateLoginLink()} pointer>
              Update
            </Text>
          </Col>
        </Row>
      ) : (
        <Row justify="center" gutter={[0, 20]}>
          <Col span={20}>
            <Tag color="">Payments Not Enabled</Tag>
          </Col>
          <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Text color="violet" size="14" underline onClick={() => stripeOnboarding()} pointer>
              Enable
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CreditCardStatus;
