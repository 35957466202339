import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import { DollarCircleOutlined, HomeOutlined, MailOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Button from 'components/Button/Button';

const QuickAddMenu = (props) => {
  const { setDrawerVisible, setShowTransactionPanel, setShowInvitationPanel } = props;
  const history = useHistory();

  const items = [
    {
      key: '0',
      icon: <DollarCircleOutlined />,
      label: 'Add a Transaction',
      onClick: () => {
        setDrawerVisible(true);
        setShowTransactionPanel(true);
      },
    },
    {
      key: '1',
      icon: <MailOutlined />,
      label: 'Invite a Renter',
      onClick: () => {
        setDrawerVisible(true);
        setShowInvitationPanel(true);
      },
    },
    {
      key: '2',
      icon: <HomeOutlined />,
      label: 'Add a Property',
      onClick: () => history.push({ pathname: '/landlord/properties', state: { action: 'addProperty' } }),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button size="default" type="primary" icon={<PlusSquareOutlined />}>
        QUICK ADD
      </Button>
    </Dropdown>
  );
};

export default QuickAddMenu;
