import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import styled from 'styled-components';
import { Table, Tag, Tabs } from 'antd';
import { PlusSquareOutlined, CaretDownFilled, CaretRightFilled } from '@ant-design/icons';
import theme from 'styles/styledComponents/variables';

import { calculateLandlordFee } from 'resources/helpers';

import CardWithTitle from 'components/CardWithTitle';
import Button from 'components/Button';
import FinixDetailsTable from '../DesktopTable/FinixDetailsTable/FinixDetailsTable';
import DetailsPending from '../DesktopTable/FinixDetailsTable/components/DetailsPending';

const { TabPane } = Tabs;

const columns = [
  {
    title: 'Transaction Details',
    colSpan: 2,
    dataIndex: 'transactionDetails',
    key: 'transactionDetails',
    render: (text, row, index) => {
      return {
        children: text,
        props: {
          colSpan: 2,
        },
      };
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    colspan: 2,
    render: (status) => {
      const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      const StatusColor = {
        pending: 'gold',
        processed: 'green',
        billed: 'green',
        credited: 'green',
        paid: 'green',
        failed: 'red',
        scheduled: 'blue',
        posted: 'green',
        refund: 'orange',
      };
      return <Tag color={StatusColor[status]}>{statusCapitalized}</Tag>;
    },
  },
];

const TableDetails = (data) => {
  return (
    <ExpandedTable>
      {data.items.refType === 'Payment' && (
        <>
          <thead>
            <tr>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{`${data.items.address}`}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              {/**
           <th>Transaction ID</th>
          */}
              <th>Method</th>
              <th>Your Fee</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/**
            <td className="trnsactionId">{data.items.transactionId}</td>
           */}
              <td>{data.items.method}</td>
              <td>
                ${calculateLandlordFee(data.items.refType, data.items.fee, data.items.landlordAbsorbPercent)}
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>Settlement Date</th>
              <th>Net Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.items.settlementDate}</td>
              <td>{data.items.netSettlement}</td>
            </tr>
          </tbody>
        </>
      )}

      {data.items.refType === 'BillingCharge' && (
        <>
          <thead>
            <tr>
              <th>Memo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.items.memo}</td>
            </tr>
          </tbody>
        </>
      )}
    </ExpandedTable>
  );
};

const MobileTable = (props) => {
  const {
    transactions,
    itemType,
    handleTableChange,
    currentPage,
    truncateString,
    onExpand,
    expandedKeys,
    setDrawerVisible,
    setShowTransactionPanel,
    loadSettlements,
    settlements,
    settleExpandedKeys,
    handleSettlementSelect,
    onSettleExpand,
    settlePage,
    handleSettleTableChange,
    activeTab,
    onTabClick,
  } = props;

  const session = useSelector((store) => store.session);

  const dataMapping = (data) => {
    const mapped = data.map((d, i) => {
      let status = '';
      // if (d.state === 'PROCESSED') {
      //   status = 'processed';
      // } else if (d.state === 'NOT_PROCESSED') {
      //   status = 'failed';
      // } else if (d.state === 'PROCESSING') {
      //   status = 'pending';
      // } else if (d.state === 'DELAYED_PROCESSED') {
      //   status = 'scheduled';
      // } else if (d.state === 'POSTED') {
      //   status = 'posted';
      // }
      switch (true) {
        case d.refType === 'BillingCharge' && d.state === 'POSTED':
          status = d.billingItemType === 'CHARGE' ? 'billed' : 'credited';
          break;
        case d.refType === 'BillingCharge' && d.state === 'DELAYED_PROCESSED':
          status = 'scheduled';
          break;
        case d?.billingCharge?.chargeType === 'REFUND':
          status = 'refund';
          break;
        case d.refType === 'Payment' && d.state === 'PROCESSED':
          status = 'paid';
          break;
        case d.refType === 'Payment' && d.state !== 'PROCESSED':
          status = d.state === 'PROCESSING' ? 'pending' : 'failed';
          break;
        default:
          break;
      }

      let settle_date = '-';
      let settle_amt = '-';
      if (d.payment && d.payment.transactionMeta) {
        settle_date = d.payment.transactionMeta.settlement_date;
        settle_amt = d.payment.transactionMeta.net_settlement;
      }

      const object = {
        key: i,
        transactionDetails: (
          <table>
            <tbody>
              <tr className="innerStyle">
                <td>{moment(d.orderDate).utc().format('MM-DD-YYYY')}</td>
                <td>{d.customer.length ? `${d.customer[0]?.firstName} ${d.customer[0]?.lastName}` : 'N/A'}</td>
              </tr>
              <tr className="innerStyle">
                <td>{d.billingCharge ? itemType(d) : d.refType}</td>
                <td>${d.billingCharge ? d.billingCharge.amount.toFixed(2) : d.payment.amount}</td>
              </tr>
            </tbody>
          </table>
        ),
        status,
        address: `${d?.property[0]?.address}, #${d?.property[0]?.address2}`,
        portfolio: d?.property[0]?.propertyGroup?.name || '',
        transactionId: d.id,
        method: d.payment ? d.payment.paymentMethodType : '',
        fee: d.payment ? d.payment.fee.amount : 0,
        landlordAbsorbPercent: d.payment ? d.payment.fee.landlordAbsorbPercent : 0,
        settlementDate: settle_date,
        netSettlement: settle_amt,
        memo: d.memo,
        refType: d.refType,
      };
      return object;
    });
    return mapped;
  };

  const dataSource = dataMapping(transactions.items);

  const settlementsColumns = [
    {
      title: 'Settled',
      dataIndex: 'settlementDate',
      key: 'date',
      width: '10%',
    },
    {
      title: 'Property Group',
      dataIndex: 'propertyGroupName',
      key: 'propertyGroupName',
      width: '10%',
    },
    {
      title: 'Net Deposit',
      dataIndex: 'netDeposit',
      key: 'netDeposit',
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (status) => {
        const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        const StatusColor = {
          completed: 'green',
          failed: 'red',
        };
        return <Tag color={StatusColor[status]}>{statusCapitalized}</Tag>;
      },
      filters: [
        {
          text: 'Completed',
          value: 'completed',
        },
        {
          text: 'Failed',
          value: 'failed',
        },
      ],
    },
  ];

  const settleMap = (data) => {
    const record = data;
    const mapped = record.map((d, i) => {
      const o = {
        ...d,
        key: i,
        settlementDate: d.settlementDate ? moment(d.settlementDate).utc().format('MM-DD-YYYY') : '',
        createdAt: d.createdAt ? moment(d.createdAt).utc().format('MM-DD-YYYY') : '',
        totalSettlementFees: d.totalSettlementFees ? `$${Number(d.totalSettlementFees).toFixed(2)}` : '$0.00',
        grossSettlement: d.grossSettlement ? `$${Number(d.grossSettlement).toFixed(2)}` : '$0.00',
        adjustments: d.totalCharges ? `$${Number(d.totalCharges).toFixed(2)}` : '$0.00',
        netDeposit: d.netSettlement ? `$${Number(d.netSettlement).toFixed(2)}` : '$0.00',
      };

      return o;
    });
    return mapped;
  };

  const settleDataSource = settleMap(settlements.settlements);

  return (
    <Container>
      <CardWithTitle title="Transactions" type="landlord">
        <StyledTabs
          defaultActiveKey="1"
          activeKey={activeTab.current}
          onTabClick={onTabClick}
          size="large"
          tabBarExtraContent={
            <Button
              icon={<PlusSquareOutlined style={{ fontSize: '14px', color: theme.colors.white }} />}
              type="primary"
              height={27}
              fontSize={12}
              padding1={1}
              padding2={15}
              bottom={2}
              data-modal="transaction"
              onClick={() => {
                setDrawerVisible(true);
                setShowTransactionPanel(true);
              }}
              data-testid="transactionPanelTest"
            >
              ADD A TRANSACTION
            </Button>
          }
        >
          <TabPane tab="Transactions" key="1">
            <StyledTable
              columns={columns}
              loading={transactions.loading}
              size="small"
              onExpand={onExpand}
              expandedRowKeys={expandedKeys}
              expandable={{
                expandedRowRender: (record) => <TableDetails items={record} />,
                rowExpandable: (record) => record.name !== 'Not Expandable',
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <CaretDownFilled
                      style={{ color: '#cb47b8' }}
                      onClick={(e) => onExpand(record, e)}
                      data-testid="shrinkTest"
                    />
                  ) : (
                    <CaretRightFilled
                      style={{ color: '#cb47b8' }}
                      onClick={(e) => onExpand(record, e)}
                      data-testid="expandTest"
                    />
                  ),
              }}
              dataSource={dataSource}
              onChange={handleTableChange}
              pagination={{
                total: transactions.totalCount,
                size: 'small',
                showSizeChanger: true,
                current: currentPage,
              }}
            />
          </TabPane>
          {session.userData.paymentGateway === 'Finix' && (
            <TabPane tab="Settlements" key="2">
              <StyledTable
                columns={settlementsColumns}
                loading={loadSettlements}
                size="small"
                bordered
                onExpand={onSettleExpand}
                expandedRowKeys={settleExpandedKeys}
                expandable={{
                  expandedRowRender: (record) => {
                    return record.status === 'completed' ? (
                      <FinixDetailsTable
                        item={record}
                        truncateString={truncateString}
                        loadSettlements={loadSettlements}
                        itemType={itemType}
                      />
                    ) : (
                      <DetailsPending item={record} />
                    );
                  },
                  // rowExpandable: (record) => record.name !== 'Not Expandable',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <CaretDownFilled
                        style={{ color: '#cb47b8' }}
                        onClick={(e) => onExpand(record, e)}
                        // data-testid="shrinkedTest"
                      />
                    ) : (
                      <CaretRightFilled
                        style={{ color: '#cb47b8' }}
                        onClick={(e) => onExpand(record, e)}
                        // data-testid="expandTest"
                      />
                    ),
                }}
                dataSource={settleDataSource}
                onChange={handleSettleTableChange}
                pagination={{
                  total: settlements.total,
                  size: 'default',
                  showSizeChanger: true,
                  current: settlePage,
                }}
                rowSelection={{
                  hideSelectAll: true,
                  renderCell: (checked, record, index, originNode) => {
                    return record.status === 'completed' ? originNode : null;
                  },
                  onSelect: handleSettlementSelect,
                }}
                // data-testid="desktopTableTest"
              />
            </TabPane>
          )}
        </StyledTabs>
      </CardWithTitle>
    </Container>
  );
};

const StyledTable = styled((props) => <Table {...props} />)`
  .expand-parent td {
    border-bottom: 0px;
  }

  th.ant-table-cell {
    font-weight: 600;
    font-size: 12px;
  }
  .innerStyle td {
    width: 50%;
  }

  @media screen and (max-width: 575px) {
    ul.ant-pagination.mini.ant-table-pagination.ant-table-pagination-right {
      align-items: center;
    }
    .ant-pagination-options {
      display: inherit;
    }
    span.ant-select-selection-item {
      padding-top: 2px !important;
    }
    th.ant-table-cell {
      font-size: 11px !important;
      font-weight: 600;
      span {
        font-size: 11px !important;
      }
    }
    .ant-table-row-expand-icon-cell {
      span {
        font-size: 13px !important;
      }
    }
    tr.ant-table-expanded-row {
      td.ant-table-cell {
        background-color: #fff;
      }
    }

    td.ant-table-cell {
      font-size: 10px !important;
      span {
        font-size: 10px !important;
      }
    }
    td.ant-table-row-expand-icon-cell {
      span {
        font-size: 13px !important;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    flex-direction: column-reverse;
  }
`;

const Container = styled.div`
  .ant-card-body {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .ant-card-head {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 576px) {
    .ant-card-body {
      padding-top: 2px;
    }
  }
`;

const ExpandedTable = styled.table`
  font-size: 12px;
  .transactionId {
    width: 20%;
  }
  @media screen and (max-width: 488px) {
    font-size: 11px;
  }
`;

export default MobileTable;
