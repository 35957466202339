import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Row, Col, Collapse } from 'antd';

import { getPropertyFees } from 'services/api/common/read';

import { numberTOrdinalSuffix } from 'resources/helpers';

import Text from 'components/Text';
import Space from 'components/Space';
import Button from 'components/Button/Button';

const PropertyDetails = ({ property, cognitoToken, handleChangePropertyDetails }) => {
  const [propertyFees, setPropertyFees] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const res = await getPropertyFees(cognitoToken, property[0].id);
      setPropertyFees(res);
    } catch (error) {
      console.log(error);
    }
  }, [property, cognitoToken]);

  useEffect(() => {
    if (property.length) {
      fetchData();
    }
  }, [property, fetchData]);

  return (
    <div data-testid="propertyDetailsTest">
      <Collapse style={{ marginTop: '14px' }} id="property-details-header" ghost>
        <Collapse.Panel
          header={
            <Text size={16} strong color="black" id="property-details">
              Property Details
            </Text>
          }
          extra={
            <StyledButton
              data-testid="propertyDetailsButtonTest"
              onClick={handleChangePropertyDetails}
              type="link"
              size="small"
              disabled={!property.length}
            >
              Edit
            </StyledButton>
          }
        >
          <Container>
            <Row justify="center">
              <Col span={10} offset={1}>
                <Text size={16} strong color="black">
                  Rent Due:
                </Text>
                <Text size={16} color="black">
                  {property.length > 0 && ` ${numberTOrdinalSuffix(property[0].rentDueDay)} day`}
                </Text>
              </Col>
              <Col span={12} offset={1}>
                <Text size={16} strong color="black">
                  Rent Amount:
                </Text>
                <Text size={16} color="black">
                  {property.length > 0 && ` $${property[0].rentAmount.toFixed(2)}`}
                </Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={10} offset={1}>
                <Text size={16} strong color="black">
                  Credit Cards:
                </Text>
                <Text size={16} color="black">
                  {property.length > 0 ? (property[0].paymentSettings.acceptCreditCard ? ' Yes' : ' No') : ''}
                </Text>
              </Col>
              <Col span={12} offset={1}>
                <Text size={16} strong color="black">
                  Full Payment:
                </Text>
                <Text size={16} color="black">
                  {property.length > 0 ? (property[0].paymentSettings.requireFullPayment ? ' Yes' : ' No') : ''}
                </Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={10} offset={1}>
                <Text size={16} strong color="black">
                  Prevent Pymt:
                </Text>
                <Text size={16} color="black">
                  {property.length > 0 ? (property[0].paymentSettings.preventPayments ? ' Yes' : ' No') : ''}
                </Text>
              </Col>
              <Col span={12} offset={1}>
                <Text size={16} strong color="black">
                  Collection Policy:{' '}
                </Text>
                <Text size={16} color="violet" pointer onClick={handleChangePropertyDetails} underline>
                  Upgrade
                </Text>
              </Col>
            </Row>
            <Space vertical={10} />
            <Row justify="start">
              <Col span={10} offset={1}>
                <Text size={16} strong color="black">
                  Additional Fees:
                </Text>
              </Col>
            </Row>
            <Space vertical={10} />
            <Row justify="center">
              <Col span={10} offset={1}>
                <Text size={16} strong color="black">
                  Late:
                </Text>
                <Text size={16} color="black">
                  {propertyFees &&
                    (propertyFees.lateFee
                      ? ` $${propertyFees.lateFeeAmount} (${propertyFees.lateFeeDays} day${
                          +propertyFees.lateFeeDays > 1 ? 's' : ''
                        })`
                      : ' N/A')}
                </Text>
              </Col>
              <Col span={12} offset={1}>
                <Text size={16} strong color="black">
                  Early:
                </Text>
                <Text size={16} color="black">
                  {propertyFees &&
                    (propertyFees.discount
                      ? ` $${propertyFees.discountAmount} (${propertyFees.discountDays} day${
                          propertyFees.discountDays > 1 ? 's' : ''
                        })`
                      : ' N/A')}
                </Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={10} offset={1}>
                <Text size={16} strong color="black">
                  Parking:
                </Text>
                <Text size={16} color="black">
                  {propertyFees &&
                    (propertyFees.parkingFee
                      ? ` $${propertyFees.parkingFeeAmount} (${propertyFees.parkingFeeFrequency.toLowerCase()})`
                      : ' N/A')}
                </Text>
              </Col>
              <Col span={12} offset={1}>
                <Text size={16} strong color="black">
                  Security:
                </Text>
                <Text size={16} color="black">
                  {propertyFees &&
                    (propertyFees.securityDeposit ? ` $${propertyFees.securityDepositAmount}` : ' N/A')}
                </Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={10} offset={1}>
                <Text size={16} strong color="black">
                  Move-In:
                </Text>
                <Text size={16} color="black">
                  {propertyFees && (propertyFees.moveInFee ? ` $${propertyFees.moveInFeeAmount}` : ' N/A')}
                </Text>
              </Col>
              <Col span={12} offset={1}>
                <Text size={16} strong color="black">
                  Move-Out:
                </Text>
                <Text size={16} color="black">
                  {propertyFees && (propertyFees.moveOutFee ? ` $${propertyFees.moveOutFeeAmount}` : ' N/A')}
                </Text>
              </Col>
            </Row>
            <Space vertical={10} />
          </Container>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

const Container = styled.div`
  background-color: #fff;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  span {
    text-decoration-line: underline;
    text-decoration-style: solid;
    font-weight: 400;
  }
`;

export default PropertyDetails;
