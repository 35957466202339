const sendSibEvent = async (event, properties, data) => {
  try {
    return new Promise((resolve, reject) => {
      window.sendinblue.track(event, properties, data);
      resolve();
    });
  } catch (error) {
    throw new Error(error);
  }
};

const sendSibClickEvent = async (link, properties, data) => {
  try {
    return new Promise((resolve, reject) => {
      window.sendinblue.trackLink(link, properties, data);
      resolve();
    });
  } catch (error) {
    throw new Error(error);
  }
};

const sendSibIdentitiy = async (email, properties, data) => {
  try {
    return new Promise((resolve, reject) => {
      window.sendinblue.identify(email, properties, data);
      resolve();
    });
  } catch (error) {
    throw new Error(error);
  }
};
export { sendSibEvent, sendSibClickEvent, sendSibIdentitiy };
