import React from 'react';
import Text from 'components/Text/Text';
import { Space } from 'antd';

function PortfolioFaqs() {
  const faqs = [
    { q: 'What is a portfolio?', a: 'A portfolio is a collection of properties that you own or manage.' },
    {
      q: 'Can I have more than one portfolio?',
      a: 'Yes this is common when you want to track and manage a group of properties based on separate tax entities. You can create additional portfolios on the properties screen.',
    },
    {
      q: 'What are Default Property Settings?',
      a: 'Default property settings are the settings that are applied to all properties in the portfolio. You can override these settings on a per property basis.',
    },
  ];
  return faqs.map((faq, index) => (
    <Space direction="vertical" key={index}>
      <Text color="black" strong>
        {faq.q}
      </Text>
      <Text color="black">{faq.a}</Text>
    </Space>
  ));
}

export default PortfolioFaqs;
