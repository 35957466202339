import React, { useState, useEffect } from 'react';

import { Row, Col, Form, Switch, Input, Select } from 'antd';
import PremiumFeaturePopover from 'components/uielements/PremiumFeaturePopover/PremiumFeaturePopover';

//import './style.scss';

const { Option } = Select;

const AdditionalFeesFields = (props) => {
  const { form, additionalAssessmentsEnabled } = props;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateMedia = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const checkValidAmount = (rule, value, sw) => {
    if (value) {
      if (form.getFieldValue(sw)) {
        if (+value < 1) {
          return Promise.reject('Amount can´t be zero.');
        }
        return Promise.resolve();
      }
    }

    return Promise.resolve();
  };
  return (
    <div className="additional-fees">
      <Row justify="center">
        <Col xs={21}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.securityDepositSwitch !== curValues.securityDepositSwitch
            }
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <Form.Item
                    name="securityDepositAmount"
                    noStyle
                    rules={[
                      {
                        required: form.getFieldValue('securityDepositSwitch'),
                        message: 'Amount is required',
                      },
                      {
                        validator: (rule, value) => checkValidAmount(rule, value, 'securityDepositSwitch'),
                      },
                    ]}
                  >
                    <Input
                      prefix="$"
                      style={{ width: '25%' }}
                      disabled={!additionalAssessmentsEnabled || !form.getFieldValue('securityDepositSwitch')}
                      data-testid="depositAmountTest"
                    />
                  </Form.Item>
                  <span>&nbsp;&nbsp;security deposit</span>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={3}>
          <PremiumFeaturePopover
            showPopover={!additionalAssessmentsEnabled}
            cta="Auto-bill additional charges and credits"
            handleDrawerReset={props.handleDrawerReset}
          >
            <Form.Item name="securityDepositSwitch" valuePropName="checked">
              <Switch data-testid="secDepoSwTest" disabled={!additionalAssessmentsEnabled} />
            </Form.Item>
          </PremiumFeaturePopover>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={12}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.daysEarlySwitch !== curValues.daysEarlySwitch}
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <Form.Item
                    name="discountAmountEarly"
                    noStyle
                    rules={[
                      {
                        required: form.getFieldValue('daysEarlySwitch'),
                        message: 'Amount is required',
                      },
                      {
                        validator: (rule, value) => checkValidAmount(rule, value, 'daysEarlySwitch'),
                      },
                    ]}
                  >
                    <Input
                      prefix="$"
                      style={{ width: '45%' }}
                      disabled={!additionalAssessmentsEnabled || !form.getFieldValue('daysEarlySwitch')}
                      data-testid="discountEarlyAmountTest"
                    />
                  </Form.Item>
                  <span>&nbsp;&nbsp;discount if</span>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={9}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.daysEarlySwitch !== curValues.daysEarlySwitch}
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <Form.Item
                    name="daysEarly"
                    noStyle
                    rules={[
                      {
                        required: form.getFieldValue('daysEarlySwitch'),
                        message: 'Days is required',
                      },
                      {
                        validator: (rule, value) => checkValidAmount(rule, value, 'daysEarlySwitch'),
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '30%', marginLeft: '3px' }}
                      disabled={!additionalAssessmentsEnabled || !form.getFieldValue('daysEarlySwitch')}
                    />
                  </Form.Item>
                  <span>&nbsp;&nbsp;days early</span>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={3}>
          <PremiumFeaturePopover
            cta="Auto-bill additional charges and credits"
            handleDrawerReset={props.handleDrawerReset}
            showPopover={!additionalAssessmentsEnabled}
          >
            <Form.Item name="daysEarlySwitch" valuePropName="checked">
              <Switch data-testid="daysEarlySwTest" disabled={!additionalAssessmentsEnabled} />
            </Form.Item>
          </PremiumFeaturePopover>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={12}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.daysLateSwitch !== curValues.daysLateSwitch ||
              prevValues.complianceLevel !== curValues.complianceLevel
            }
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <Form.Item
                    name="lateFeeAmount"
                    noStyle
                    rules={[
                      {
                        required: form.getFieldValue('daysLateSwitch'),
                        message: 'Amount is required',
                      },
                      {
                        validator: (rule, value) => checkValidAmount(rule, value, 'daysLateSwitch'),
                      },
                    ]}
                  >
                    <Input
                      prefix="$"
                      style={{ width: '45%' }}
                      disabled={!additionalAssessmentsEnabled || !form.getFieldValue('daysLateSwitch')}
                    />
                  </Form.Item>
                  <span>&nbsp;&nbsp;late fee if</span>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        {/**
         <Col xxl={8} xl={9} lg={9} md={9} sm={9} xs={9}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.daysLateSwitch !== curValues.daysLateSwitch ||
              prevValues.complianceLevel !== curValues.complianceLevel
            }
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="lateFeeFrequency"
                  rules={[
                    {
                      required: form.getFieldValue('daysLateSwitch'),
                      message: 'Required',
                    },
                  ]}
                  className="frecuency-validation"
                >
                  <Select disabled={!form.getFieldValue('daysLateSwitch')} className="late-fee-select">
                    <Option value="ONE-TIME">One time late fee</Option>
                    <Option value="DAILY">Daily late fee</Option>
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
         */}

        <Col xs={9}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.daysLateSwitch !== curValues.daysLateSwitch ||
              prevValues.complianceLevel !== curValues.complianceLevel
            }
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <Form.Item
                    name="daysLateFee"
                    noStyle
                    rules={[
                      {
                        required: form.getFieldValue('daysLateSwitch'),
                        message: 'Required',
                      },
                    ]}
                  >
                    <Input
                      className="days-late-input"
                      style={{ width: '32%' }}
                      disabled={!additionalAssessmentsEnabled || !form.getFieldValue('daysLateSwitch')}
                    />
                  </Form.Item>
                  <span>&nbsp;&nbsp;days late</span>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={3}>
          <PremiumFeaturePopover
            cta="Auto-bill additional charges and credits"
            handleDrawerReset={props.handleDrawerReset}
            showPopover={!additionalAssessmentsEnabled}
          >
            <Form.Item name="daysLateSwitch" valuePropName="checked">
              <Switch data-testid="daysLateSwitch" disabled={!additionalAssessmentsEnabled} />
            </Form.Item>
          </PremiumFeaturePopover>
        </Col>
      </Row>
      <Row className="parking-fee-row" justify="center">
        <Col xs={5}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.parkingFeeSwitch !== curValues.parkingFeeSwitch}
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  name="parkingFeeAmount"
                  rules={[
                    {
                      required: form.getFieldValue('parkingFeeSwitch'),
                      message: 'Amount is required',
                    },
                    {
                      validator: (rule, value) => checkValidAmount(rule, value, 'parkingFeeSwitch'),
                    },
                  ]}
                >
                  <Input
                    prefix="$"
                    disabled={!additionalAssessmentsEnabled || !form.getFieldValue('parkingFeeSwitch')}
                    className="assesment-input"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={16}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.parkingFeeSwitch !== curValues.parkingFeeSwitch}
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <div
                    className="form-item-wrapper"
                    style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}
                  >
                    <Form.Item
                      style={{ margin: '0 12px', flex: 'auto' }}
                      name="periodParkingFee"
                      rules={[
                        {
                          required: form.getFieldValue('parkingFeeSwitch'),
                          message: 'Frequency is required',
                        },
                      ]}
                    >
                      <Select
                        disabled={!additionalAssessmentsEnabled || !form.getFieldValue('parkingFeeSwitch')}
                        className="parking-frequency"
                      >
                        <Option value="ONE-TIME">One Time</Option>
                        <Option value="WEEKLY">Weekly</Option>
                        <Option value="MONTHLY">Monthly</Option>
                      </Select>
                    </Form.Item>
                    <div style={{ marginRight: '12px' }}>parking fee</div>
                  </div>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col xs={3}>
          <PremiumFeaturePopover
            cta="Auto-bill additional charges and credits"
            handleDrawerReset={props.handleDrawerReset}
            showPopover={!additionalAssessmentsEnabled}
          >
            <Form.Item name="parkingFeeSwitch" valuePropName="checked">
              <Switch disabled={!additionalAssessmentsEnabled} data-testid="parkingFeeSwTest" />
            </Form.Item>
          </PremiumFeaturePopover>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={21}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.moveInFeeSwitch !== curValues.moveInFeeSwitch}
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <Form.Item
                    name="moveInFee"
                    noStyle
                    rules={[
                      {
                        required: form.getFieldValue('moveInFeeSwitch'),
                        message: 'Amount is required',
                      },
                      {
                        validator: (rule, value) => checkValidAmount(rule, value, 'moveInFeeSwitch'),
                      },
                    ]}
                  >
                    <Input
                      prefix="$"
                      style={{ width: '25%' }}
                      disabled={!additionalAssessmentsEnabled || !form.getFieldValue('moveInFeeSwitch')}
                    />
                  </Form.Item>
                  <span>&nbsp;&nbsp;move-in fee</span>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={3}>
          <PremiumFeaturePopover
            cta="Auto-bill additional charges and credits"
            handleDrawerReset={props.handleDrawerReset}
            showPopover={!additionalAssessmentsEnabled}
          >
            <Form.Item name="moveInFeeSwitch" valuePropName="checked">
              <Switch disabled={!additionalAssessmentsEnabled} data-testid="moveInFeeSwTest" />
            </Form.Item>
          </PremiumFeaturePopover>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={21}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => prevValues.moveOutFeeSwitch !== curValues.moveOutFeeSwitch}
            noStyle
          >
            {() => {
              return (
                <Form.Item>
                  <Form.Item
                    name="moveOutFee"
                    noStyle
                    rules={[
                      {
                        required: form.getFieldValue('moveOutFeeSwitch'),
                        message: 'Amount is required',
                      },
                      {
                        validator: (rule, value) => checkValidAmount(rule, value, 'moveOutFeeSwitch'),
                      },
                    ]}
                  >
                    <Input
                      prefix="$"
                      style={{ width: '25%' }}
                      disabled={!additionalAssessmentsEnabled || !form.getFieldValue('moveOutFeeSwitch')}
                    />
                  </Form.Item>
                  <span>&nbsp;&nbsp;move-out fee</span>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={3}>
          <PremiumFeaturePopover
            cta="Auto-bill additional charges and credits"
            handleDrawerReset={props.handleDrawerReset}
            showPopover={!additionalAssessmentsEnabled}
          >
            <Form.Item name="moveOutFeeSwitch" valuePropName="checked">
              <Switch disabled={!additionalAssessmentsEnabled} data-testid="moveOutFeeSwTest" />
            </Form.Item>
          </PremiumFeaturePopover>
        </Col>
      </Row>
    </div>
  );
};

export default AdditionalFeesFields;
