import React, { useState } from 'react';
import UploadButton from 'components/UploadButton';
import { Select, Button, Space } from 'antd';
import Text from 'components/Text/Text';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { CameraOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';

const KycDocs = (props) => {
  // console.log('[KycDocs1.js]', props);
  const { uploadState, customRequest, accept, showUpload, files, id, title, uploadingState } = props;
  const [documentType, setDocumentType] = useState();
  const [showCamera, setShowCamera] = useState(false);

  const options = files.map((file, i) => ({ label: file.label, value: file.documentType, key: i }));

  const uploadVariants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  };

  const handleTakePhoto = () => {
    console.log('ƒ handleTakePhoto');
  };

  if (!showUpload[id]) return null;

  return (
    <motion.div
      style={{ height: '84px' }}
      className="upload-wrapper"
      key={`motion-${id}`}
      variants={uploadVariants}
      initial="hidden"
      animate="enter"
      exit="leave"
    >
      <Text color="black" size={14}>
        {title}
      </Text>
      <div
        style={{
          paddingTop: '4px',
          paddingBottom: '4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Select
          placeholder="Select Identity Document"
          options={options}
          onSelect={(value) => setDocumentType(value)}
          style={{ textOverflow: 'ellipsis', width: '280px' }}
          getPopupContainer={() => document.getElementById('docsContainer')}
        />
        <Space size={8} direction="horizontal" align="baseline">
          {/* <Button type="text" icon={<CameraOutlined size={24} />} onClick={() => setShowCamera(true)} /> */}
          {showCamera && (
            <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri);
              }}
            />
          )}
          <UploadButton
            disabled={!documentType}
            text="Upload"
            size="middle"
            padding1={2}
            padding2={2}
            height={30}
            uploading={uploadingState[id]}
            config={{
              accept,
              showUploadList: false,
              maxCount: 1,
              multiple: false,
              fileList: uploadState.fileList,
              customRequest: (info) => customRequest({ info, documentType, source: id }),
            }}
          />
        </Space>
      </div>
    </motion.div>
  );
};

export default KycDocs;
