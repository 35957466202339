import React from 'react';
import Text from 'components/Text/Text';
import { Space } from 'antd';
import { Link } from 'react-router-dom';

function FinalFaqs() {
  const faqs = [
    {
      q: 'How long until my account is ready to accept payments?',
      a: 'Reviewing your account application usually takes 1 business day. Once your account is verified, you will receive an email notification.',
    },
    {
      q: 'What do I do next?',
      a: (
        <span>
          While your account is being reviewed you can complete other tasks to get started:
          <br />
          <ul>
            <li>
              <Link to="/landlord/properties">Add additional properties or units</Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    'https://app.tango.us/app/workflow/Tenant-Walk-through-for-landlords-b2affcd5a7e042388fd1544b738e6aef',
                }}
                target="_blank"
              >
                Walk-through the tenant experience
              </Link>
            </li>
            <li>
              <Link to="/landlord/renters">Invite Renters to Join</Link>
            </li>
          </ul>
        </span>
      ),
    },
  ];
  return faqs.map((faq, index) => (
    <Space direction="vertical" key={index}>
      <Text color="black" strong>
        {faq.q}
      </Text>
      <Text color="black">{faq.a}</Text>
    </Space>
  ));
}

export default FinalFaqs;
