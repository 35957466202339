import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCrop from 'react-image-crop';
import Compressor from 'compressorjs';
import styled from 'styled-components';
import { Select, Form } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import FileType from 'file-type/browser';

import { ArrowLeftOutlined, CheckCircleFilled } from '@ant-design/icons';
import { ReactComponent as DebtIcon } from 'assets/images/rentcred-profile/debt_free.svg';
import { ReactComponent as BuyHomeIcon } from 'assets/images/rentcred-profile/buy_home.svg';
import { ReactComponent as RetirementPlan } from 'assets/images/rentcred-profile/financial_planning.svg';
import { ReactComponent as EmergencyFund } from 'assets/images/rentcred-profile/savings.svg';
import { ReactComponent as College } from 'assets/images/rentcred-profile/college.svg';
import { ReactComponent as InvestMyself } from 'assets/images/rentcred-profile/education.svg';
import { ReactComponent as PassiveInconme } from 'assets/images/rentcred-profile/investing.svg';
import { ReactComponent as CareerAdvance } from 'assets/images/rentcred-profile/career_advance.svg';

import { ageNumbers } from 'resources/FormData';

import { documentsPresignedUrl } from 'services/api/common/create';

import { updateTenantMetaV3 } from 'services/api/tenantApi';
import { getUserDataV3 } from 'store/actions/sessionActions';

import Text from 'components/Text';
import Space from 'components/Space';
import Button from 'components/Button';
import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';
import Avatar from './components/Avatar';

const { Option } = Select;

const RentCredProfile = (props) => {
  const { setDrawerVisible } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const [alert, setAlert] = useState({ isVisible: false, type: 'warning', message: '' });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [outOfDebt, setOutOfDebt] = useState(false);
  const [buyHome, setBuyHome] = useState(false);
  const [retirementPlan, setRetirementPlan] = useState(false);
  const [emergencyFund, setEmergencyFund] = useState(false);
  const [saveForCollege, setSaveForCollege] = useState(false);
  const [investInMyself, setInvestInMyself] = useState(false);
  const [passiveInconme, setPassiveIncome] = useState(false);
  const [betterJob, setBetterJob] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [uploading, setUploading] = useState(false);

  // react-crop

  const [fileTypeValidation, setFileTypeValidation] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [upImg, setUpImg] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    // check if the user has a profile picture
    if (session.userData.tenantMeta) {
      const { gender, age, education, annualHouseholdIncome, photo } = session.userData.tenantMeta.renterProfile;
      const {
        getOutOfDebt,
        buyAHome,
        planForRetirement,
        haveAnEmergencyFund,
        saveForCollege,
        investInMyself,
        generatePassiveIncome,
        getABetterPayingJob,
      } = session.userData.tenantMeta?.financialGoals;
      const values = {
        gender,
        age,
        education,
        householdIncome: annualHouseholdIncome,
      };
      if (photo) setImageUrl(photo);
      if (gender || age || education || annualHouseholdIncome) {
        form.setFieldsValue(values);
      }
      setOutOfDebt(getOutOfDebt);
      setBuyHome(buyAHome);
      setRetirementPlan(planForRetirement);
      setEmergencyFund(haveAnEmergencyFund);
      setSaveForCollege(saveForCollege);
      setInvestInMyself(investInMyself);
      setPassiveIncome(generatePassiveIncome);
      setBetterJob(getABetterPayingJob);
    }
  }, []);

  const getCroppedImage = async (compCrop) => {
    const image = imgRef.current;
    const cropI = compCrop;

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = cropI.width * pixelRatio;
    canvas.height = cropI.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      cropI.x * scaleX,
      cropI.y * scaleY,
      cropI.width * scaleX,
      cropI.height * scaleY,
      0,
      0,
      cropI.width,
      cropI.height
    );
    canvas.toBlob((blob) => {
      if (blob) {
        blob.name = fileData.file.name;
        blob.lastModified = fileData.file.lastModified;
        setCroppedFile(blob);
      }
    }, fileData.file.type);
  };

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    getCroppedImage(completedCrop);
  }, [completedCrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const displayAlert = (message, type) => {
    setAlert({
      message,
      type,
      isVisible: true,
    });

    setTimeout(() => {
      setAlert({
        message: '',
        type: 'warning',
        isVisible: false,
      });
    }, 4000);
  };

  const validateAndCrop = async (info) => {
    const { file } = info;

    try {
      const type = await FileType.fromBlob(file);

      if (type === undefined) throw Error('Invalid file type');

      if (type?.ext !== 'jpg' && type?.ext !== 'jpeg' && type?.ext !== 'png') {
        throw Error('Invalid File Type');
      }
      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpImg(reader.result);
      });
      reader.readAsDataURL(info.file);
      setFileData(info);
      setFileTypeValidation(type);
    } catch (err) {
      const { response } = err;
      setUploading(false);
      displayAlert(response?.data?.errors[0].detail || err.message, 'warning');
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const renterProfile = {
        gender: values.gender,
        age: values.age,
        education: values.education,
        annualHouseholdIncome: values.householdIncome,
        photo: imageUrl || '',
      };

      const financialGoals = {
        getOutOfDebt: outOfDebt,
        buyAHome: buyHome,
        planForRetirement: retirementPlan,
        haveAnEmergencyFund: emergencyFund,
        saveForCollege: saveForCollege,
        investInMyself: investInMyself,
        generatePassiveIncome: passiveInconme,
        getABetterPayingJob: betterJob,
      };

      await updateTenantMetaV3(
        session.userData.id,
        session.userData.tenantMeta.creditBoost,
        renterProfile,
        financialGoals,
        cognitoToken
      );
      dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
      setLoading(false);

      displayAlert('Profile successfully saved.', 'success');
      setTimeout(() => {
        setDrawerVisible(false);
      }, 5000);
    } catch (error) {
      setLoading(false);
      displayAlert(error.data?.errors[0].detail || 'There was an error with your request.', 'warning');
    }
  };
  /*
  const beforeCrop = async (file) => {
    try {
      console.log(file);
      const type = await FileType.fromBlob(file);

      if (type === undefined) throw Error('Invalid file type');

      if (type?.ext !== 'jpg' && type?.ext !== 'jpeg' && type?.ext !== 'png') {
        throw Error('Invalid File Type');
      }
      if (file.size / 1024 / 1020 > 10) {
        throw Error('File Size Exceeds 10 MB');
      }
      setFileTypeValidation(type);
      return true;
      // const reader = new FileReader();
      // reader.addEventListener('load', () => {
      //   setUpImg(reader.result);
      // });
      // reader.readAsDataURL(info.file);
      // setFileData(info);
    } catch (err) {
      const { response } = err;
      setUploading(false);
      setFileTypeValidation(false);
      displayAlert(response?.data?.errors[0].detail || err.message, 'warning');
      return false;
    }
  };
  */

  const uploadImage = async () => {
    try {
      setImageUrl(null);
      setUploading(true);
      setUpImg(null);
      imgRef.current = null;

      const ext = fileTypeValidation.mime === 'image/jpeg' ? 'jpeg' : fileTypeValidation.ext;

      const ba = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      const storedFileName = uuidv4();

      if (ba.length) {
        const res = await documentsPresignedUrl(
          session.cognitoTokens.idToken.jwtToken,
          ba[0].id,
          'upload',
          `${storedFileName}.${ext}`,
          'profile',
          ext
        );

        //
        const instance = axios.create({
          headers: { 'Content-Type': fileTypeValidation.mime },
        });
        delete instance.defaults.headers.common['Authorization'];
        delete instance.defaults.headers.common['Accept'];

        await instance.put(res.presignedUrl, croppedFile);

        const photoUrl = `${process.env.REACT_APP_PROFILE_URL}/${session.userData.id}/profile/${storedFileName}.${ext}`;

        setImageUrl(photoUrl);

        const renterProfile = {
          ...session.userData.tenantMeta.renterProfile,
          photo: photoUrl,
        };

        await updateTenantMetaV3(
          session.userData.id,
          session.userData.tenantMeta.creditBoost,
          renterProfile,
          session.userData.tenantMeta.financialGoals,
          cognitoToken
        );
        setUploading(false);
        setFileData(null);
      } else {
        throw Error('User needs an active billing account');
      }
    } catch (err) {
      setUploading(false);
      const { response } = err;
      displayAlert(response?.data?.errors[0].detail || err.message, 'warning');
    }
  };

  const cancelUpload = () => {
    setFileData(null);
    setUpImg(null);
    setCroppedFile(null);
    imgRef.current = null;
  };

  return (
    <Container>
      <Header>
        <ArrowLeftOutlined
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowTest"
          onClick={() => setDrawerVisible(false)}
        />
        RentCred Profile
      </Header>
      <FormContainer>
        <StyledForm form={form} name="rentcredProfile" onFinish={onFinish}>
          <ContentContainer className="content-container">
            {/**
             <div styles={{ height: '64px' }}>
              <UserPlaceholder style={{ transform: 'scale(0.43)' }} />
            </div>
             */}
            <Space vertical={10} />
            <Text size={16} color="black" centered style={{ maxWidth: '80%' }}>
              Completing your profile helps us create a better experience for you. We never sell or share your
              information with anyone.
            </Text>
            <Space vertical={10} />
            <Avatar
              validateAndCrop={validateAndCrop}
              imageUrl={imageUrl}
              uploading={uploading}
              setUploading={setUploading}
              displayAlert={displayAlert}
              setImageUrl={setImageUrl}
            />
            {upImg && (
              <CropContainer>
                <ReactCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  style={{ width: '100%', textAlign: 'center' }}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <Button color="violet" bottom={0} onClick={cancelUpload}>
                    X
                  </Button>
                  <Button color="violet" bottom={0} onClick={uploadImage} loading={uploading}>
                    Save
                  </Button>
                </div>
              </CropContainer>
            )}

            <Space vertical={10} />
            <Form.Item
              name="gender"
              style={{ width: '75%' }}
              rules={[
                {
                  required: true,
                  message: 'Please select your gender',
                },
              ]}
            >
              <Select placeholder="Gender">
                <Option value="Male" key="1">
                  Male
                </Option>
                <Option value="Female" key="2">
                  Female
                </Option>
                <Option value="Non-binary" key="3">
                  Non-binary
                </Option>
                <Option value="Prefer not to say" key="4">
                  Prefer not to say
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="age"
              style={{ width: '75%' }}
              rules={[
                {
                  required: true,
                  message: 'Please select your age',
                },
              ]}
            >
              <Select placeholder="Age">
                {ageNumbers.map((a, i) => (
                  <Option value={a} key={i}>
                    {a}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="education"
              style={{ width: '75%' }}
              rules={[
                {
                  required: true,
                  message: 'Please select your education level',
                },
              ]}
            >
              <Select placeholder="Education">
                <Option value="High School" key="e1">
                  High School
                </Option>
                <Option value="Vocational School/Training" key="e2">
                  Vocational School/Training
                </Option>
                <Option value="Associates Degree" key="e3">
                  Associates Degree
                </Option>
                <Option value="Bachelors Degree or higher" key="e4">
                  Bachelors Degree or higher
                </Option>
                <Option value="Prefer not to say" key="e5">
                  Prefer not to say
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="householdIncome"
              style={{ width: '75%' }}
              rules={[
                {
                  required: true,
                  message: 'Please select your annual household income',
                },
              ]}
            >
              <Select placeholder="Annual Household Income">
                <Option value="Less than $70,000" key="a1">
                  Less than $70,000
                </Option>
                <Option value="Greater than $70,000" key="a2">
                  Greater than $70,000
                </Option>
                <Option value="Prefer not to say" key="a3">
                  Prefer not to say
                </Option>
              </Select>
            </Form.Item>

            <Text color="black" size={14} strong centered>
              My Financial Goals
            </Text>
            <Space vertical={10} />
            <GoalDiv>
              <OutterGoalCol>
                {outOfDebt ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setOutOfDebt(false);
                    }}
                    data-testid="outOfDebtCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setOutOfDebt(true);
                    }}
                    data-testid="outOfDebtCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Get out of debt
                </Text>
                <Text color="black" size={11}>
                  Payoff credit cards or student loans
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <DebtIcon />
              </OutterGoalCol>
            </GoalDiv>
            <GoalDiv>
              <OutterGoalCol>
                {buyHome ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setBuyHome(false);
                    }}
                    data-testid="buyHomeCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setBuyHome(true);
                    }}
                    data-testid="buyHomeCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Buy a home
                </Text>
                <Text color="black" size={11}>
                  Save for a down payment
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <BuyHomeIcon />
              </OutterGoalCol>
            </GoalDiv>
            <GoalDiv>
              <OutterGoalCol>
                {retirementPlan ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setRetirementPlan(false);
                    }}
                    data-testid="retirementPlanCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setRetirementPlan(true);
                    }}
                    data-testid="retirementPlanCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Plan for retirement
                </Text>
                <Text color="black" size={11}>
                  Contribute to your retirement savings
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <RetirementPlan />
              </OutterGoalCol>
            </GoalDiv>
            <GoalDiv>
              <OutterGoalCol>
                {emergencyFund ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setEmergencyFund(false);
                    }}
                    data-testid="emergencyFundCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setEmergencyFund(true);
                    }}
                    data-testid="emergencyFundCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Have an emergency fund
                </Text>
                <Text color="black" size={11}>
                  Set money aside for a rainy day
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <EmergencyFund />
              </OutterGoalCol>
            </GoalDiv>
            <GoalDiv>
              <OutterGoalCol>
                {saveForCollege ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setSaveForCollege(false);
                    }}
                    data-testid="saveCollegeCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setSaveForCollege(true);
                    }}
                    data-testid="saveCollegeCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Save for college
                </Text>
                <Text color="black" size={11}>
                  Create a college fund for someone
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <College />
              </OutterGoalCol>
            </GoalDiv>
            <GoalDiv>
              <OutterGoalCol>
                {investInMyself ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setInvestInMyself(false);
                    }}
                    data-testid="investCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setInvestInMyself(true);
                    }}
                    data-testid="investCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Invest in myself
                </Text>
                <Text color="black" size={11}>
                  Pay for additional degrees or training
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <InvestMyself />
              </OutterGoalCol>
            </GoalDiv>
            <GoalDiv>
              <OutterGoalCol>
                {passiveInconme ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setPassiveIncome(false);
                    }}
                    data-testid="passiveIncomeCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setPassiveIncome(true);
                    }}
                    data-testid="passiveIncomeCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Generate passive income
                </Text>
                <Text color="black" size={11}>
                  Create income from investments
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <PassiveInconme />
              </OutterGoalCol>
            </GoalDiv>
            <GoalDiv>
              <OutterGoalCol>
                {betterJob ? (
                  <CheckCircleFilled
                    style={{ fontSize: '40px', color: '#541388' }}
                    onClick={() => {
                      setBetterJob(false);
                    }}
                    data-testid="betterJobCircleFilledTest"
                  />
                ) : (
                  <Circle
                    onClick={() => {
                      setBetterJob(true);
                    }}
                    data-testid="betterJobCircleTest"
                  />
                )}
              </OutterGoalCol>
              <InnerGoalCol>
                <Text color="black" size={16} strong>
                  Get a better paying job
                </Text>
                <Text color="black" size={11}>
                  Increase my main source of income
                </Text>
              </InnerGoalCol>
              <OutterGoalCol>
                <CareerAdvance />
              </OutterGoalCol>
            </GoalDiv>
          </ContentContainer>
          <PanelFooter>
            <AlertFade
              isVisible={alert.isVisible}
              message={alert.message}
              type={alert.type}
              bottom={82}
              position="absolute"
            />
            <Button type="primary" bottom={0} htmlType="submit" loading={loading}>
              SAVE
            </Button>
          </PanelFooter>
        </StyledForm>
      </FormContainer>
    </Container>
  );
};

const ContentContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  align-items: center;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 65px);
`;

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const GoalDiv = styled.div`
  width: 80%;
  height: 84px;
  border-radius: 4px;
  border: solid #d9d9d9 1px;
  display: flex;
  margin-bottom: 18px;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
`;

const OutterGoalCol = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const InnerGoalCol = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

const Circle = styled.span`
  height: 40px;
  width: 40px;
  border: 2px solid #541388;
  border-radius: 50%;
  display: inline-block;
`;

const CropContainer = styled.div`
  position: absolute;
  max-width: 350px;
  min-width: 280px;
  z-index: 20;
  top: 83px;
  background: white;
  padding: 10px;
  border: 1px #bcbcbc solid;
  border-radius: 4px;
`;

export default RentCredProfile;
