import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col, Typography } from 'antd';

import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { documentsPresignedUrl } from 'services/api/common/create';
import { editInsurancePolicy } from 'services/api/tenantApi';

import { truncateString } from 'resources/helpers';

import UploadButton from 'components/UploadButton';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import PanelFooter from 'components/Panel/PanelFooter';
import AlertFade from 'components/AlertFade';

const UploadPolicyDetails = (props) => {
  const {
    setInsuranceDrawer,
    setShowUploadedPolicyDetails,
    userPolicyDetail,
    fetchInsurancePolicy,
    handleUploadFile,
  } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const uploadedInsuranceFile = useSelector((store) => store.tenant.uploadedInsuranceFile);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;

  const [message, setMessage] = useState({ message: '', description: '', type: 'success' });
  const [policyNumber, setPolicyNumber] = useState(userPolicyDetail?.policy?.policyNumber || '');
  const [carrier, setCarrier] = useState(userPolicyDetail?.policy?.carrier || '');
  const [liability, setLiability] = useState(userPolicyDetail?.policy?.liability || '');
  const [endDate, setEndDate] = useState(userPolicyDetail?.policy?.endDate || '');
  const [startDate, setStartDate] = useState(userPolicyDetail?.policy?.startDate || '');
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [filename, setFilename] = useState('');

  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const displayAlert = (title, description, type) => {
    setMessage({ message: title, description, type });

    setTimeout(() => {
      setMessage({ message: '', description: '', type: 'warning' });
    }, 4000);
  };

  useEffect(() => {
    if (userPolicyDetail?.filename) {
      setFilename(userPolicyDetail?.filename);
    }
    if (userPolicyDetail?.policy) {
      if (!Object.keys(userPolicyDetail?.policy).length) {
        setPolicyNumber('');
        setCarrier('');
        setLiability('');
        setEndDate('');
        setStartDate('');
      }
      if (Object.keys(userPolicyDetail?.policy).length) {
        setPolicyNumber(userPolicyDetail?.policy?.policyNumber || '');
        setCarrier(userPolicyDetail?.policy?.carrier || '');
        setLiability(userPolicyDetail?.policy?.liability || '');
        setEndDate(userPolicyDetail?.policy?.endDate || '');
        setStartDate(userPolicyDetail?.policy?.startDate || '');
      }
    }
  }, [userPolicyDetail]);

  useEffect(() => {
    downloadPolicy();
  }, []);

  const handleDeleteFile = async () => {
    try {
      if (!deleteWarning) {
        setDeleteWarning(true);
        return;
      }
      setDeleting(true);
      const ba = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      await documentsPresignedUrl(cognitoToken, ba[0].id, 'delete', '', 'insurance');
      dispatch({ type: 'SET_INSURANCE_POLICY_FILE', payload: false });
      setDeleting(false);
      setDeleteWarning(false);
      fetchInsurancePolicy();
    } catch (error) {
      displayAlert('There was a problem with your request', '', 'warning');
      setDeleting(false);
    }
  };

  const downloadPolicy = async () => {
    try {
      const ba = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (ba.length) {
        const res = await documentsPresignedUrl(cognitoToken, ba[0].id, 'download', '', 'insurance');
        setDownloadUrl(res.presignedUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditPolicy = async (event, field) => {
    try {
      const values = {
        policyNumber,
        carrier,
        liability,
        startDate,
        endDate,
      };
      values[field] = event;
      await editInsurancePolicy(cognitoToken, values);
      fetchInsurancePolicy();
    } catch (error) {
      console.log(error);
    }
  };

  const customRequest = async (info) => {
    try {
      setUploading(true);
      await handleUploadFile(info);
      downloadPolicy();
      displayAlert('Successful Upload', 'Your policy policy details will be available shortly.', 'success');
      setUploading(false);
    } catch (error) {
      displayAlert(error.message || 'There was a problem with your request', '', 'warning');
      setUploading(false);
    }
  };

  return (
    <>
      <StyledContainer className="content-container">
        <Row justify="center" gutter={[0, 20]}>
          <Col span={10}>
            <Space vertical={20} />
            <Text color="black" size={16} strong>
              {userPolicyDetail?.tenant?.name}
            </Text>
            <br />
            <Text color="black" size={10}>
              Applied to: {userPolicyDetail?.tenant?.address}
            </Text>
          </Col>
          <Col span={10}>
            <Space vertical={20} />
            <Text color="black" size={12} strong>
              Landlord Contact Info:
            </Text>
            <br />
            <Text color="black" size={16}>
              {userPolicyDetail?.landlord?.name}
            </Text>
            <br />
            <Text color="violet" size={10}>
              {userPolicyDetail?.landlord?.email}
            </Text>
          </Col>
        </Row>
        <Row justify="center" gutter={[0, 20]}>
          <Col span={20}>
            <InsuranceContainer>
              <Text color="black" size={14} strong centered>
                Renters Insurance
              </Text>
              <Space vertical={20} />
              <Text color="black" size={12} centered>
                Below is the information extracted from your uploaded document. If any information is incorrect,
                tap any field to update it.
              </Text>
              <Space vertical={20} />
              {uploadedInsuranceFile && (
                <Row justify="center">
                  <Col span={20} style={{ textAlign: 'center' }}>
                    {deleteWarning ? (
                      <>
                        <Text color="violet" size={14} onClick={handleDeleteFile} pointer>
                          Really delete?
                        </Text>
                        <CloseCircleOutlined
                          style={{ fontSize: '16px', marginLeft: '10px' }}
                          onClick={() => setDeleteWarning(false)}
                        />
                      </>
                    ) : (
                      <>
                        <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                          <Text color="violet" size={16} pointer>
                            {truncateString(filename, 17, true)}
                          </Text>
                        </a>

                        <DeleteOutlined
                          style={{ fontSize: '16px', marginLeft: '10px' }}
                          onClick={handleDeleteFile}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              )}

              <Space vertical={10} />
              <Row justify="center">
                <UploadButton
                  config={{
                    accept: '.pdf,.jpg,.jpeg',
                    showUploadList: false,
                    customRequest: (info) => customRequest(info),
                  }}
                  text="Upload Policy"
                  uploading={uploading}
                  disabled={uploadedInsuranceFile}
                />
              </Row>

              <Space vertical={10} />

              <Text color="black" size={12} strong>
                Policy Number:{' '}
              </Text>
              {uploadedInsuranceFile && (
                <Typography.Text
                  style={{ display: 'inline', fontSize: '12px' }}
                  editable={{
                    maxLength: 30,
                    onChange: (event) => {
                      handleEditPolicy(event, 'policyNumber');
                      setPolicyNumber(event);
                    },
                  }}
                  data-testid="editableInputTest"
                >
                  {policyNumber}
                </Typography.Text>
              )}
              <br />
              <Text color="black" size={12} strong>
                Carrier:{' '}
              </Text>

              {uploadedInsuranceFile && (
                <Typography.Text
                  style={{ display: 'inline', fontSize: '12px' }}
                  editable={{
                    maxLength: 30,
                    onChange: (event) => {
                      handleEditPolicy(event, 'carrier');
                      setCarrier(event);
                    },
                  }}
                >
                  {carrier}
                </Typography.Text>
              )}

              <br />
              <Text color="black" size={12} strong>
                Liability Amount:{' '}
              </Text>
              {uploadedInsuranceFile && (
                <Typography.Text
                  style={{ display: 'inline', fontSize: '12px' }}
                  disabled={!uploadedInsuranceFile}
                  editable={{
                    maxLength: 30,
                    onChange: (event) => {
                      handleEditPolicy(event, 'liability');
                      setLiability(event);
                    },
                  }}
                >
                  {liability && `$${liability}`}
                </Typography.Text>
              )}

              <br />
              <Text color="black" size={12} strong>
                Start Date:{' '}
              </Text>
              {uploadedInsuranceFile && (
                <Typography.Text
                  style={{ display: 'inline', fontSize: '12px' }}
                  disabled={!uploadedInsuranceFile}
                  editable={{
                    maxLength: 30,
                    onChange: (event) => {
                      handleEditPolicy(event, 'startDate');
                      setStartDate(event);
                    },
                  }}
                >
                  {startDate}
                </Typography.Text>
              )}
              <br />
              <Text color="black" size={12} strong>
                End Date:{' '}
              </Text>
              {uploadedInsuranceFile && (
                <Typography.Text
                  style={{ display: 'inline', fontSize: '12px' }}
                  editable={{
                    onChange: (event) => {
                      handleEditPolicy(event, 'endDate');
                      setEndDate(event);
                    },
                    maxLength: 30,
                  }}
                >
                  {endDate}
                </Typography.Text>
              )}
            </InsuranceContainer>
          </Col>
        </Row>
      </StyledContainer>
      <PanelFooter>
        <AlertFade
          type={message.type}
          isVisible={!!message.message}
          message={message.message}
          description={message.description}
          alertPosition="absolute"
          position="absolute"
          minHeight="80"
          width={85}
          bottom={100}
        />

        <Button
          color="violet"
          alignment="center"
          onClick={() => {
            setInsuranceDrawer(false);
            setShowUploadedPolicyDetails(false);
          }}
          data-testid="closeButtonTest"
          bottom={0}
          loading={deleting}
        >
          CLOSE
        </Button>
      </PanelFooter>
    </>
  );
};

const StyledContainer = styled.div`
  height: calc(100% - 180px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const InsuranceContainer = styled.div`
  background-color: #f7f7f0;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px 10px;
  textarea {
    font-size: 12px;
    padding: 5px 5px;
    display: inline;
    max-width: 65%;
    margin-left: 13px;
  }
`;

export default UploadPolicyDetails;
