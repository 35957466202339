import * as actions from 'store/actions';

const initalState = {
  activeBoxMenu: {},
  settings: {
    activeKey: ['1'],
    'cash-flow': { defaultPeriod: 'mtd' },
    'net-operating-income': { defaultPeriod: 'mtd' },
    revenue: { defaultPeriod: 'mtd' },
    units: { defaultPeriod: 'mtd' },
    occupancy: { defaultPeriod: 'mtd' },
    'gross-rent-multiplier': { defaultPeriod: 'mtd' },
    'cap-rate': { defaultPeriod: 'mtd' },
    'market-value': { defaultPeriod: 'mtd' },
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_BOX_MENU':
      return {
        ...state,
        activeBoxMenu: action.activeBoxMenu,
      };
    case actions.SET_DASHBOARD:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
      };
    default:
      return state;
  }
};
