// @flow
import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import CustomLoadingOverlay from 'components/CustomLoadingOverlay';
import AuthLayout from '../../layouts/AuthLayout';

// Components
import Signing from './Signing';
import SignupLanding from './Signing/components/SignupLanding/SignupLanding';
import ConfirmSignUp from './ConfirmSignUp';
import ForgotPassword from './ForgotPassword';

// Style
//import './style.scss'

const Router = (props) => {
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <CustomLoadingOverlay showOverlay={showOverlay}>
      <AuthLayout>
        <Route exact path={['/', '/sign-in', '/sign-up/:token']} render={(props) => <Signing {...props} />} />
        <Route exact path={'/sign-up'} render={(props) => <SignupLanding {...props} />} />
        <Route
          exact
          path="/auth/confirm-sign-up"
          render={(props) => <ConfirmSignUp {...props} setShowOverlay={setShowOverlay} />}
        />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
      </AuthLayout>
    </CustomLoadingOverlay>
  );
};

export default Router;
