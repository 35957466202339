import React, { useState } from 'react';
import Text from 'components/Text/Text';
import MoreMenu from 'components/MoreMenu/MoreMenu';
import { useSelector, useDispatch } from 'react-redux';
import { Space } from 'antd';
import { DeleteOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import * as LandlordApi from 'services/api/landlordApi/delete';
import { useQueryClient } from '@tanstack/react-query';
import { globalDisplayAlert } from 'resources/helpers';

const MoreDocumentActions = (props) => {
  const { record, setDrawerVisible, setEditDocument, setShowPanel } = props;
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const cognitoToken = session.cognitoTokens?.idToken.jwtToken;
  const qc = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState({
    text: 'Delete Document',
    style: { color: 'inherit', fontWeight: 'inherit' },
    action: false,
  });

  const actionDisabled = !session.userData?.servicePlan?.metaFeatures.dOCUMENTStorage;

  const resetDropdown = () => {
    setDeleteDocument({
      text: 'Delete Document',
      style: { color: 'inherit', fontWeight: 'inherit' },
      action: false,
    });
  };

  const handleDelete = async (id) => {
    // console.log('ƒ handleDelete document', e.key, record);
    if (deleteDocument.action) {
      try {
        setLoading(true);
        resetDropdown();
        await LandlordApi.deleteDocument(cognitoToken, id);
        qc.invalidateQueries(['documents']);
      } catch (err) {
        setLoading(false);
        console.error('ƒ handleDelete error', err);
      }
      globalDisplayAlert(dispatch, '', 'success', 'Document successfully deleted');
    } else {
      setDeleteDocument({
        text: 'Really Delete?',
        style: { color: '#cb47b8', fontWeight: 500 },
        action: true,
      });
    }
  };

  const handleEdit = () => {
    // console.log('ƒ handleEdit document', record);
    setEditDocument(record);
    setDrawerVisible(true);
    setShowPanel('document');
  };

  const handleSend = () => {
    // console.log('ƒ handleSend document', record);
    setEditDocument(record);
    setShowPanel('send');
    setDrawerVisible(true);
  };

  const items = [
    {
      label: (
        <Space>
          <EditOutlined />
          <Text color="black">Edit Document Details</Text>
        </Space>
      ),
      key: '0',
      onClick: handleEdit,
      disabled: actionDisabled,
    },
    {
      label: (
        <Space>
          <SendOutlined />
          <Text color="black">Send Document</Text>
        </Space>
      ),
      key: '1',
      onClick: handleSend,
      disabled: actionDisabled,
    },
    {
      label: (
        <Space style={deleteDocument.style}>
          <DeleteOutlined />
          <Text color={deleteDocument.action ? 'violet' : 'black'}>{deleteDocument.text}</Text>
        </Space>
      ),
      key: '2',
      onClick: () => handleDelete(record.id),
      disabled: actionDisabled,
    },
  ];

  const confirmKeys = ['2'];

  return (
    <>
      <MoreMenu items={items} confirmKeys={confirmKeys} resetDropdown={resetDropdown} loading={loading} />
    </>
  );
};

export default MoreDocumentActions;
