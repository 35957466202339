import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

import LoadingOverlay from 'react-loading-overlay';

const CustomLoadingOverlay = (props) => {
  const { showOverlay, children } = props;
  return (
    <StyledLoadingOverlay styles={{ content: () => {} }} active={showOverlay} spinner={<Spin size="large" />}>
      {children}
    </StyledLoadingOverlay>
  );
};

const StyledLoadingOverlay = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    background: rgba(34, 34, 34, 0.2);
    z-index: 15000;
  }
`;

export default CustomLoadingOverlay;
