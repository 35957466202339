// @flow

// node_modules
import React, { useState, useEffect } from 'react';
import { Space as AntSpace, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Local services
import { deletePaymentMethodV3 } from 'services/api/common/delete';
import * as tenantApi from 'services/api/tenantApi';
import { getPaymentMethods, getScheduledPayments, getPaymentMethodsHistory } from 'store/actions/tenantActions';

import { getServicePlanAction, getUserDataV3 } from 'store/actions/sessionActions';

// Local components
import PaymentMethodItem from './components/PaymentMethodItem/PaymentMethodItem';

const PaymentMethods = (props) => {
  // console.log('[PaymentMethods.js]', props);
  const dispatch = useDispatch();
  const paymentMethods = useSelector((store) => store.tenant.paymentMethods);
  const session = useSelector((store) => store.session);
  const [defaultPM, setDefaultPM] = useState(paymentMethods.filter((el) => el.defaultMethod)[0]?.id || '');
  const gateway = useSelector((state) => state.session.userData.paymentGateway);
  const [isDeleting, setIsDeleting] = useState({ id: null, loading: false });
  const [isLoading, setIsLoading] = useState({ id: null, loading: false });

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getPaymentMethodsHistory(gateway, true, session.cognitoTokens.idToken.jwtToken));
    dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
    dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
    dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
  }, []);

  const displayAlert = (message, type, description, persist) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    if (!persist) {
      setTimeout(() => {
        dispatch({
          type: 'UNSET_ALERT',
        });
      }, 5000);
    }
  };

  const deleteMethod = async (id) => {
    try {
      setIsDeleting({ id, loading: true });
      await deletePaymentMethodV3(id, session.cognitoTokens.idToken.jwtToken);
      dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
      dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));
      dispatch(getScheduledPayments(session.cognitoTokens.idToken.jwtToken));
      // if (!paymentMethods.length) setDropDownFlag({ 0: false });
      // if (props.showPayMethods) props.showPayMethods(false);

      // const updatedItems = paymentMethods.filter((i) => i.id !== id);

      displayAlert(
        'Payment Method Deleted',
        'success',
        'If you had any scheduled payments using this payment method, they have also been deleted.'
      );
      if (props.setPaymentMethodsDrawerVisible) props.setPaymentMethodsDrawerVisible(false);
    } catch (e) {
      console.log(e);
      setIsDeleting({ id: null, loading: false });
      // setDropDownFlag(resetDropdown(paymentMethods));
      displayAlert('Payment Method Error', 'warning', 'Your payment method was not deleted.');
    }
  };

  const setDefaultPaymentMethod = async (e) => {
    const methodId = e.target.value;
    setIsLoading({ id: methodId, loading: true });
    let hasError = false;
    await tenantApi.setDefaultPaymentMethodV3(methodId, session.cognitoTokens.idToken.jwtToken).catch(() => {
      hasError = true;
    });

    if (hasError) {
      displayAlert('Default payment method error', 'warning', '');
      return;
    }

    dispatch(getPaymentMethods(gateway, false, session.cognitoTokens.idToken.jwtToken));
    // if (props.showPayMethods) props.showPayMethods(false);
    if (props.setPaymentMethodsDrawerVisible) props.setPaymentMethodsDrawerVisible(false);
    setDefaultPM(e.target.value);

    displayAlert('Default payment method set', 'success', '');
    setIsLoading({ id: null, loading: false });
  };

  return (
    <div>
      <Space direction="vertical" size={15}>
        <RadioGroup
          onChange={setDefaultPaymentMethod}
          value={defaultPM}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {paymentMethods.map((item, i) => (
            <PaymentMethodItem
              item={item}
              deleteItem={deleteMethod}
              isDeleting={isDeleting}
              isLoading={isLoading.id === item.id}
              index={i}
              showRadioButtons={props.showRadioButtons}
              key={i}
            />
          ))}
        </RadioGroup>
      </Space>
    </div>
  );
};

const Space = styled(({ ...rest }) => <AntSpace {...rest} />)`
  width: 100%;
`;

const RadioGroup = styled(({ ...rest }) => <Radio.Group {...rest} />)`
  div:last-child {
    margin-bottom: 0;
  }
`;

export default PaymentMethods;
