import React from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getSettlementTransfers } from 'services/api/landlordApi/read';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { transferTypeMap } from 'resources/finix';
import { settlementDetailsMap } from 'resources/helpers';

const FinixDetailsTable = (props) => {
  // console.log('[FinixDetailsTable.js]', props);
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const { item } = props;

  const transfersUrl = process.env.REACT_APP_API_URL_V3 + '/finix' + item.transfersUri;
  // const fundingUrl = process.env.REACT_APP_API_URL_V3 + '/finix' + item.fundingTransfersUri;

  const transfers = useQuery({
    queryKey: ['finixTransfers', item.id],
    queryFn: () => getSettlementTransfers(cognitoToken, transfersUrl),
  });

  const { isLoading, isError, data: transfersData, error } = transfers;

  // console.log('[FinixDetailsTable.js] react-query tansfers', transfers || error);
  // console.log('[FinixDetailsTable.js] react-query payouts', payouts || payouts.error);
  // console.log('cache', qc.getQueryCache());

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const columns = [
    {
      title: 'Transaction Date',
      dataIndex: 'createdAt',
      key: 'created-at',
      render: (text) => dayjs(text).format('MM/DD/YYYY'),
      width: '10%',
    },
    {
      title: 'Tenant Name',
      dataIndex: 'renterFullname',
      key: 'renter-name',
      elipsis: true,
    },
    {
      title: 'Property Address',
      dataIndex: 'renterFulladdress',
      key: 'renter-address',
      elipsis: true,
    },
    {
      title: 'Payment',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: '10%',
      render: (text) => `$${text.toFixed(2)}`,
    },
    {
      title: 'Your Fees',
      dataIndex: 'fee',
      key: 'fee',
      align: 'right',
      width: '10%',
      render: (text) => `$${text.toFixed(2)}`,
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      key: 'net-amount',
      align: 'right',
      width: '10%',
      render: (text) => `$${text.toFixed(2)}`,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text) => transferTypeMap(text),
      width: '10%',
    },
  ];
  return (
    <Table
      className="child-table"
      pagination={false}
      loading={isLoading}
      columns={columns}
      dataSource={!isLoading && settlementDetailsMap(transfersData, item.finixId)}
    />
  );
};

export default FinixDetailsTable;
