export const kycKybMap = {
  kycAddressMatch: { kycDocs1: true },
  kycNameMatch: { kycDocs1: true },
  kycDobMatch: { kycDocs1: true },
  kycSsnMatch: { kycDocs1: true, kycDocs2: true },
  ssnNotVerified: { kycDocs1: true, kycDocs2: true },
  feinFound: { kybDocs2: true },
  nameMismatch: { kybDocs1: true },
  addressMatched: { kybDocs1: true },
  nameNotVerified: { kybDocs1: true },
  companyNameVerified: { kybDocs1: true },
  secretaryOfStateMatch: { kybDocs1: true },
};

// acceptable file type for each kyc/kyb status
export const identityVerification = [
  { label: 'Drivers License (front/back)', documentType: 'id_drivers_license' },
  { label: 'State ID Card', documentType: 'id_state' },
  { label: 'US Passport Book (first and second page)', documentType: 'id_passport' },
  { label: 'US Passport Card (front/back)', documentType: 'id_passport_card' },
];

export const SSNVerification = [
  { label: 'Social Security Card', documentType: 'doc_ss_card' },
  { label: 'IRS 1040 Tax Return', documentType: 'tax_1040' },
  { label: 'IRS Form W-2', documentType: 'tax_w2' },
];

export const businessVerification = [
  { label: 'Articles of Incorporation or Formation', documentType: 'other_doc' },
  { label: 'IRS EIN Verification Letter', documentType: 'doc_ssa' },
  { label: 'IRS Business Tax Return', documentType: 'tax_1095' },
  { label: 'Bank Statement', documentType: 'bank_statement' },
];

export const FEINVerification = [
  { label: 'IRS EIN Verfication Letter', documentType: 'doc_ssa' },
  { label: 'IRS Business Tax Return', documentType: 'tax_1095' },
  { label: 'IRS Form SS-4', documentType: 'doc_ssa' },
];
