import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Spin, theme } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Result from 'components/uielements/Result/Result';
import {
  createMerchantPaymentInstrument,
  createFinixBuyerIdentity,
  createPaymentInstrument,
} from 'services/api/common/create';
import { createCustomerIdentitySchema } from 'services/finix/validators/identitySchema';
import { usStates } from 'resources/FormData';

const { REACT_APP_FINIX_APP_ID, REACT_APP_ENV } = process.env;

const { useToken } = theme;

export const requiredFields = [
  'bank_account_name',
  'bank_routing_number',
  'bank_account_number',
  'confirm_bank_account_number',
  'account_type',
  'agree',
];

export default function FinixPaymentMethodForm(props) {
  // console.log('[FinixPaymentMethodForm.js] props', props);
  const formElementRef = useRef(null);
  const paymentFormRef = useRef(null);
  const session = useSelector((store) => store.session);
  const { token } = useToken();
  const [loadingError, setLoadingError] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  let bankFormOptions = {
    labels: {
      name: 'Accountholder Name',
      bank_code: '9-Digit Bank Routing Number',
    },
    showPlaceholders: false,
    errorMessages: {
      name: 'Please enter the name of the account holder',
      bank_code: 'Please enter a valid routing number',
    },
    onLoad: function () {
      setIsSpinning(false);
    },
    submitLabel: 'Save',
  };

  const { merchantIdentity, setPaymentInstrument, setStepStatus, next, replace, type, onError } = props;

  const handleSubmit = async () => {
    // update view to show loading spinner or disable the submit button
    setStepStatus && setStepStatus('wait');
    setIsSpinning(true);
    const tokenizePaymentMethod = new Promise((resolve, reject) => {
      paymentFormRef.current.submit(
        REACT_APP_ENV !== 'production' ? 'sandbox' : 'production',
        REACT_APP_FINIX_APP_ID,
        function (err, res) {
          if (err) {
            const errData = res.data || err;
            reject(errData);
          }

          const tokenData = res.data || {};
          resolve(tokenData);
        }
      );
    });

    try {
      // 1. Tokenize a payment method
      const tokenData = await tokenizePaymentMethod;
      // 2. Create a payment instrument with token
      const piData = {
        token: tokenData.id,
        type: 'TOKEN',
      };
      let paymentInstrumentRes = null;
      if (merchantIdentity) {
        piData.identity = merchantIdentity.merchantIdentityId;
        piData.tags = { account_id: merchantIdentity.id };
        paymentInstrumentRes = await createMerchantPaymentInstrument(
          session.cognitoTokens.idToken.jwtToken,
          piData,
          replace
        );
      } else {
        const stateShort = usStates.filter((s) => s.name === session.userData.property.state.toUpperCase())[0]
          .abbreviation;

        // truncate string to 35 characters
        const buyerIdentityRequest = {
          tags: {
            renter_id: session.userData.id,
          },
          entity: {
            first_name: session.userData.firstName.substring(0, 20) || '',
            last_name: session.userData.lastName.substring(0, 20),
            email: session.userData.email,
            phone: session.userData.phone,
            personal_address: {
              line1: session.userData.property.address.substring(0, 35),
              line2: session.userData.property.address2.substring(0, 35) || '',
              country: 'USA',
              region: stateShort,
              city: session.userData.property.city.substring(0, 20),
              postal_code: session.userData.property.zip.toString(),
            },
          },
        };
        await createCustomerIdentitySchema.validateAsync(buyerIdentityRequest);
        const res = await createFinixBuyerIdentity(session.cognitoTokens.idToken.jwtToken, buyerIdentityRequest);
        piData.identity = res.identityId;
        piData.tags = { renter_id: session.userData.id };
        paymentInstrumentRes = await createPaymentInstrument(
          session.cognitoTokens.idToken.jwtToken,
          piData,
          replace
        );
      }

      // console.log('ƒ createMerchantPaymentInstrument result', paymentInstrumentRes);
      setIsSpinning(false);
      setTimeout(() => {
        setPaymentInstrument && setPaymentInstrument(paymentInstrumentRes);
        setStepStatus && setStepStatus('finish');
        next && next();
      }, 500);
    } catch (err) {
      setIsSpinning(false);
      let errMsg = '';
      if (err._embedded?.errors) {
        errMsg = err._embedded.errors[0].message;
      } else if (err.response?.data?.message) {
        errMsg = err.response.data.message;
      } else if (err.message) {
        errMsg = err.message;
      }

      onError && onError(errMsg);
      setStepStatus && setStepStatus('error');
    }
  };

  bankFormOptions.styles = {
    default: {
      color: token.colorText,
      border: `1px solid ${token.colorBorder}`,
      borderRadius: token.borderRadius,
      padding: '4px 11px',
      fontFamily: token.fontFamily,
      fontSize: token.fontSize,
      boxShadow: 'none',
      height: '32px',
    },
    success: {
      color: token.colorText,
    },
    error: {
      color: token.colorErrorText,
      border: `1px solid ${token.colorErrorBorder}`,
    },
  };
  bankFormOptions.onSubmit = handleSubmit;

  const createFinixForm = useCallback(() => {
    // needs to wait a tic for div to load idkw
    setTimeout(() => {
      const element = document.getElementById('form-element');
      // console.log('ƒ createBankForm element', element);

      if (type === 'bank' && element) {
        paymentFormRef.current = window.Finix.BankTokenForm('form-element', bankFormOptions);
      } else if (type === 'card' && element) {
        paymentFormRef.current = window.Finix.CardTokenForm('form-element', {
          ...bankFormOptions,
          showAddress: true,
        });
      } else {
        setLoadingError(true);
      }
    }, 500);
  }, [bankFormOptions, type]);

  useEffect(() => {
    // console.log('ƒ useEffect FinixPaymentMethodForm', paymentFormRef.current, formElementRef.current);
    if (!window.Finix) {
      setLoadingError(true);
      return;
    }

    if (!paymentFormRef.current && formElementRef.current) {
      setIsSpinning(true);
      createFinixForm();
    }
    return () => {
      // console.log('ƒ useEffect MerchantPaymentMethod cleanup');
      paymentFormRef.current = null;
    };
  }, []);

  return (
    <StyledDiv
      token={token}
      className="hosted-form-container"
      data-testid="finixPaymentFormContainerTest"
      style={{}}
    >
      {loadingError ? (
        <Result status="error" title="Something went wrong." />
      ) : (
        <Spin spinning={isSpinning}>
          <div id="form-element" ref={formElementRef}></div>
        </Spin>
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  text-align: left;
  line-height: normal;

  #bank-form-element {
    min-width: 300px;
    font-family: ${(props) => props.token.fontFamily};
    font-size: ${(props) => props.token.fontSize};
  }

  #finix-form-container {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  #form-element #finix-form-container #finix-submit-button {
    background-color: ${(props) => props.token.Button.colorPrimary};
    box-shadow: ${(props) => props.token.Button.boxShadow};
    align-self: flex-end;
    width: 80px;
    height: 40px;
    line-height: normal;
    border-radius: ${(props) => props.token.Button.borderRadius};
  }
`;
