import React from 'react';

import { ReactComponent as ApplyConnectLogo } from 'assets/images/applications/apply-connect.svg';

const ApplyConnect = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        maxHeight: '42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ApplyConnectLogo />
    </div>
  );
};

export default ApplyConnect;
