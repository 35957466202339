import React from 'react';
import { Drawer as AntDrawer } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function TenantDrawer(props) {
  // console.log('[TenantDrawer.js] props', props);
  const { children, message, type, alertMessage, alertType, styles, ...rest } = props;

  const mergedStyles = {
    header: {
      borderBottom: 'none',
      padding: '2px 0 0 0',
    },
    footer: {
      display: 'flex',
      height: '130px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    body: {
      padding: '0 24px',
      overflowY: 'auto',
    },
    ...styles,
  };

  return (
    <StyedlDrawer styles={mergedStyles} {...rest}>
      {children}
    </StyedlDrawer>
  );
}

TenantDrawer.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeIcon: PropTypes.node,
  styles: PropTypes.object,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
};

TenantDrawer.defaultProps = {
  width: 414,
  closeIcon: null,
  alertType: 'info',
  alertMessage: 'here is a message',
};

const StyedlDrawer = styled(AntDrawer)`
  position: fixed !important;
  .ant-drawer-header-title {
    align-items: flex-start;
  }
`;

export default TenantDrawer;
