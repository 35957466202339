import * as actions from 'store/actions';
import {
  getTransactions,
  getRenters,
  getInvitedRenters,
  getSummary,
  getOnboardingStatus,
  getRentersV3,
  getProperties,
} from 'services/api/landlordApi/read';
import { updateLandlordProfile } from 'services/api/landlordApi/update';
import { getUserDataV3 } from './sessionActions';

export const getTransactionsAction =
  (page, size, filter, typeFilter, renterFilter, sort, currentSearch, dateRange, fieldsFilter, portfolioFilter) =>
  async (dispatch) => {
    try {
      const response = await getTransactions(
        page,
        size,
        filter,
        typeFilter,
        renterFilter,
        sort,
        currentSearch,
        dateRange,
        fieldsFilter,
        portfolioFilter
      );

      dispatch({
        type: actions.GET_TRANSACTIONS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_TRANSACTIONS_ERROR,
        payload: error,
      });
    }
  };

// deprecated
export const getRentersListAction = (page, size, sort, filter) => async (dispatch) => {
  try {
    let response = { items: [] };
    let invitedRes = { items: [] };
    let mergedRes = { items: [] };
    if (filter) {
      if (filter === 'CURRENT' || filter === 'PASTDUE' || filter === 'INACTIVE') {
        response = await getRenters(page, size, sort, filter);

        mergedRes.items = [...response.items];
      } else if (filter === 'INVITED') {
        invitedRes = await getInvitedRenters(1000);
        mergedRes.items = [...invitedRes.items];
      }
    } else {
      response = await getRenters(page, size, sort);
      invitedRes = await getInvitedRenters(1000);
      mergedRes.items = [...response.items, ...invitedRes.items];
      console.log(mergedRes);
    }

    dispatch({
      type: actions.GET_RENTERS_LIST,
      payload: mergedRes,
    });
  } catch (error) {
    dispatch({
      type: actions.GET_RENTERS_LIST_ERROR,
      payload: error,
    });
  }
};

export const getV3RentersListAction = (cognitoToken, page, size, search, controller) => async (dispatch) => {
  try {
    let response = { items: [] };
    response = await getRentersV3(cognitoToken, page, size, search, controller);

    dispatch({
      type: actions.GET_RENTERS_LIST,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: actions.GET_RENTERS_LIST_ERROR,
      payload: error,
    });
  }
};

export const getPropertiesListAction = (cognitoToken, page, size, search, controller) => async (dispatch) => {
  try {
    let response = { items: [] };
    response = await getProperties(cognitoToken, page, size, search, controller);

    dispatch({
      type: actions.GET_PROPERTIES_LIST,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: actions.GET_PROPERTIES_LIST_ERROR,
      payload: error,
    });
  }
};

export const getSummaryDataAction = (cognitoToken) => async (dispatch) => {
  try {
    const res = await getSummary(cognitoToken);

    // let action = '';

    // if (info === 'payments') action = actions.GET_PAYMENTS_SUMMARY_DATA;
    // if (info === 'properties') action = actions.GET_PROPERTIES_SUMMARY_DATA;
    // if (info === 'people') action = actions.GET_PEOPLE_SUMMARY_DATA;

    dispatch({
      type: actions.GET_ALL_SUMMARY_DATA,
      payload: res,
    });
  } catch (error) {
    console.log(error);
    const { response } = error;
    throw response;
  }
};

// deprecated
export const getOnboardingStatusAction = (cognitoToken) => async (dispatch) => {
  try {
    const onBoardStatus = await getOnboardingStatus(cognitoToken);
    const renters = await getRentersV3(cognitoToken, 1, 10, '', '');
    if (renters.items.length) {
      onBoardStatus.step2.status = 'finished';
    }
    dispatch({
      type: 'SET_ONBOARDING_STATUS',
      payload: onBoardStatus,
    });
  } catch (error) {
    console.log(error);
    const { response = '' } = error;
    throw response;
  }
};

export const setSummaryPaymentType = (type) => async (dispatch) => {
  dispatch({
    type: 'SET_SUMMARY_CARD_PAYMENT_TYPE',
    payload: type,
  });
};

export const unsetTransactionRenter = () => async (dispatch) => {
  dispatch({
    type: 'RENTER_TRANSACTION',
    payload: {
      userName: null,
    },
  });
};

export const updateQuickStartStatusAction = (id, quickStart, cognitoToken, controller) => async (dispatch) => {
  try {
    await updateLandlordProfile(null, null, id, quickStart);
    const currentStep = quickStart.lastCompletedStep + 1;
    dispatch(getUserDataV3(cognitoToken, controller));
    dispatch({ type: 'SET_CURRENT_ONBOARDING_STEP', payload: { currentStep } });
  } catch (error) {
    console.log(error);
    const { response = '' } = error;
    throw response;
  }
};
