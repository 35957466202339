import React from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ReactComponent as RentCredLogo } from 'assets/images/rentcred/rentcred-logo.svg';
import QuadpayLogo from 'assets/images/quadpay-logo.png';
import { ReactComponent as ZipLogo } from 'assets/images/zip_badge.svg';

import Text from 'components/Text';
import Space from 'components/Space';

const QuadpayIneligiblePanel = (props) => {
  const { setShowQuadpayIneligible, setShowPayment } = props;
  return (
    <Container>
      <Header>
        <ArrowLeftOutlined
          onClick={() => {
            setShowPayment(true);
            setShowQuadpayIneligible(false);
          }}
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowTest"
        />
        RentCred Loan
      </Header>
      <ContentContainer>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
          <RentCredLogo width={120} height={52} />{' '}
          <Text size={16} color="black" strong>
            Loans
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text size={16} color="black">
            powered by
          </Text>
          <ZipLogo style={{ fontSize: '20px' }} />
        </div>
        <Space vertical={10} />
        <Text size={16} color="black" centered>
          RentCred loans is a fast and easy way to get emergency cash when you need it.
        </Text>
        <Space vertical={10} />
        <Text size={16} color="black" centered>
          To qualify for a RentCred loan you must have RentCred Gold status with a payment history of at least 6
          on-time payments made through the PayRent portal.
        </Text>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

export default QuadpayIneligiblePanel;
