import React, { useState, useEffect, useRef } from 'react';
import { Card as AntCard } from 'antd';
import Text from 'components/Text';
import Space from 'components/Space/Space';

import dayjs from 'dayjs';

const TotalPaymentAmount = (props) => {
  // console.log('[TotalPayments.js]', props);
  const {
    totalAmount,
    totalAmountValidation,
    absorbFees,
    absorbFeesPercent,
    paymentMethod,
    chosenPm,
    firstPayment,
    fee,
    formData,
    balanceDue,
  } = props;

  const { frequency, firstPaymentDate, endPaymentDate, deliverBy } = formData || {};
  const [nextPaymentDate, setNextPaymentDate] = useState();
  const mountedRef = useRef(true);

  useEffect(() => {
    setNextPaymentDate();
    let npd;

    if (firstPaymentDate && typeof deliverBy === 'number') {
      console.log('ƒ useEffect on formData');
      const firstPaymentDay = dayjs(firstPaymentDate).date();
      if (deliverBy > firstPaymentDay) {
        npd = dayjs([dayjs(firstPaymentDate).year(), dayjs(firstPaymentDate).month(), deliverBy]);
        console.log('payment is same month on', npd.format('MMM D, YYYY'));
      } else {
        npd = dayjs(firstPaymentDate)
          .add(1, 'months')
          .add(deliverBy - firstPaymentDay, 'days');
        console.log('payment is next month on', npd.format('MMM D, YYYY'));
      }
      return setNextPaymentDate(npd);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [firstPaymentDate, deliverBy, frequency]);

  return (
    <AntCard
      style={{
        borderColor: '#C4C4C4',
        borderRadius: '4px',
        marginTop: '15px',
        marginBottom: '15px',
      }}
      bordered
      styles={{
        body: {
          borderRadius: '4px',
          textAlign: 'center',
          backgroundColor: '#F7F7F4',
          padding: '10px',
          color: 'black',
        },
      }}
    >
      {firstPaymentDate && (
        <div>
          <Text size={16} color="black">
            Your {frequency === 'ONE-TIME' ? 'one-time' : 'initial'} payment will be scheduled for{' '}
            {dayjs(firstPaymentDate).format('MMM D, YYYY')}
            {(frequency === 'MONTHLY' || frequency === 'WEEKLY') &&
              deliverBy !== 'Send subsequent payments on' && (
                <span>
                  {' '}
                  with {frequency.toLowerCase()} recurring payments starting{' '}
                  {frequency === 'WEEKLY'
                    ? `on the following ${deliverBy}`
                    : `on ${nextPaymentDate?.format('MMM D, YYYY')}`}
                </span>
              )}
            {endPaymentDate && <span> and ending on {endPaymentDate.format('MMM D, YYYY')}</span>}
            <span>.</span>
          </Text>
          <Space vertical={20} />
        </div>
      )}

      <Text size={16} color="black" centered strong>
        Total Payment Amount
      </Text>
      <Text size={28} color="black" centered strong>
        ${balanceDue ? ' varies' : !totalAmount ? '0.00' : Number(totalAmount).toFixed(2)}
      </Text>
      {totalAmountValidation && (
        <Text size={13} color="red" centered>
          Amount can´t be zero
        </Text>
      )}

      <Text size={16} color="black" centered>
        {absorbFeesPercent === '100' && absorbFees && paymentMethod[chosenPm]?.paymentMethodType === 'ACH'
          ? 'Landlord is absorbing the fee'
          : balanceDue
          ? 'Payment may include a processing fee'
          : `Includes a $${Number(fee).toFixed(2)} processing fee`}
      </Text>

      {paymentMethod[chosenPm]?.paymentMethodType === 'ACH' && firstPayment && (
        <Text size={16} color="black" centered>
          Your first payment is on us!
        </Text>
      )}
      <Text size={11} color="grey3">
        Disclaimer: We use best efforts to execute your scheduled payments. Please confirm that they have been
        processed on the designated day.
      </Text>
    </AntCard>
  );
};

export default TotalPaymentAmount;
