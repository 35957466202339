import React, { useEffect, useState } from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

import { CheckOutlined } from '@ant-design/icons';

import PricingImage from 'assets/images/pricing-landing-image.png';

import BannerLeft from 'assets/images/corner1-merged.png';
import BannerRight from 'assets/images/corner2-merged.png';
import MbannerLeft from 'assets/images/mobile-corner-1.png';
import MbannerRight from 'assets/images/mobile-corner-2.png';
// import DealImg from 'assets/images/deal-img.png';
import MostPopular from 'assets/images/most-popular.png';
import CardWithTitle from 'components/CardWithTitle';
import Space from 'components/Space';
import Button from 'components/Button';
import Text from 'components/Text';

import NavBar from '../NavBar';
import Calculator from '../Calculator';

//import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const Pricing = () => {
  const history = useHistory();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hideColumnPayg, setHideColumnPayg] = useState(false);
  const [hideColumnDiys, setHideColumnDiys] = useState(false);
  const [hideColumnGlap, setHideColumnGlap] = useState(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 576);
    setScreenWidth(window.innerWidth);
    if (window.innerWidth < 768) {
      setHideColumnPayg(true);
      setHideColumnDiys(true);
      setHideColumnGlap(true);
    }
    if (window.innerWidth >= 768) {
      setHideColumnPayg(false);
      setHideColumnDiys(false);
      setHideColumnGlap(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    updateMedia();
    const params = new URLSearchParams(history.location.search);

    if (params.get('apid')) {
      localStorage.setItem('apid', params.get('apid'));
    }

    window.fcSettings = {
      token: 'e94c84e8-a9ca-4a99-87d3-023bdf8d3278',
      host: 'https://wchat.freshchat.com',
      config: {
        cssNames: {
          widget: 'custom_fc_frame',
          // open: 'custom_fc_open',
          //expanded: 'custom_fc_expanded'
        },
        headerProperty: {
          hideChatButton: false,
        },
      },
    };
  }, []);

  return (
    <Layout className="pricing-landing-page">
      <StyledHeader>
        <NavBar />
        <Title style={{ marginTop: '18px' }}>Pricing and Plans</Title>
        <img
          src={PricingImage}
          alt="Payrent pricing"
          width="749"
          className="header-main-img"
          alt="Pricing image"
        />
        <BannerContainer>
          {isDesktop ? (
            <img
              src={BannerLeft}
              width="54"
              style={{ position: 'absolute', top: '-13px', left: '-36px', zIndex: 2 }}
              alt="Banner left"
            />
          ) : (
            <img
              src={MbannerLeft}
              width="17"
              style={{ position: 'absolute', top: '-16px', left: '-10px', zIndex: 2 }}
              alt="Mobile banner left"
            />
          )}

          <StyledBanner id="servicePlans">
            <h3 className="h3-header">
              {isDesktop ? (
                <span>Try Before You Buy - No Payment Required at Sign-Up</span>
              ) : (
                <span>
                  Try Before You Buy <br /> No Payment Required at Sign-Up
                </span>
              )}
            </h3>
          </StyledBanner>
          {isDesktop ? (
            <img
              src={BannerRight}
              width="54"
              style={{ position: 'absolute', top: '-13px', right: '-38px', zIndex: 2 }}
              alt="Banner right"
            />
          ) : (
            <img
              src={MbannerRight}
              width="17"
              style={{ position: 'absolute', top: '-16px', right: '-10px', zIndex: 2 }}
              alt="Mobile banner right"
            />
          )}
        </BannerContainer>

        <Space vertical={50} />
      </StyledHeader>
      <StyledContent>
        <StyledRow justify="center" gutter={[0, 10]}>
          <Col
            xs={23}
            sm={18}
            md={8}
            lg={7}
            xl={6}
            xxl={5}
            className={hideColumnPayg ? 'payg-hidden' : 'payg-expanded'}
          >
            <CardWithTitle
              title="Pay-As-You-Go"
              headFontSize={28}
              headAlign="center"
              bodyMinHeight={920}
              style={{ zIndex: 2, top: '-20px', borderRadius: '4px' }}
              type="pricing"
              className="pricing-columns"
            >
              <Space vertical={15} />
              <Text color="grey3" centered strong size={14}>
                FREE FOREVER
              </Text>
              <Space vertical={15} />
              <Text color="black" size={46} centered>
                $0
              </Text>
              <Space vertical={15} />
              <Text color="grey3" centered strong size={14}>
                per month
              </Text>
              <Space vertical={25} />
              <Text color="black" size={14} centered>
                Ideal for part-time landlords with less than 10 properties
              </Text>
              <Space vertical={25} />
              <Text size={20} strong color="grey3" centered>
                Features
              </Text>
              <Text
                size={14}
                color="grey3"
                strong
                style={{ display: 'flex', marginBottom: '20px', marginTop: '30px' }}
              >
                <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                Unlimited units
              </Text>
              <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
                <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                3-Day funding hold
              </Text>
              <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
                <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                $5 per Bank Transfer
              </Text>
              {(screenWidth >= 768 || !hideColumnPayg) && (
                <>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    3.5% + $0.30 per credit card transaction
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Rent reminders
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Payment controls
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Scheduled payments
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Monitor Renters Insurance
                  </Text>
                </>
              )}

              {screenWidth < 768 && hideColumnPayg && (
                <Text color="violet" underline size={14} centered onClick={() => setHideColumnPayg(false)}>
                  More Features...
                </Text>
              )}

              <Button
                style={{
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  bottom: '15px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                color="yellow"
                onClick={() => history.push('/sign-up/landlord?plan=8')}
                id="PAYG"
                data-testid="signUpPAYG"
              >
                SIGN UP
              </Button>
            </CardWithTitle>
          </Col>

          <Col
            xs={23}
            sm={18}
            md={8}
            lg={7}
            xl={6}
            xxl={5}
            className={hideColumnDiys ? 'diy-hidden' : 'diy-expanded'}
          >
            <StyledCard
              title="Do-It-Yourself"
              headFontSize={28}
              headAlign="center"
              bodyMinHeight={920}
              type="pricing"
              className="pricing-columns"
            >
              <Space vertical={15} />
              <Text color="grey3" centered strong size={14}>
                TRY FREE FOR 7-DAYS
              </Text>
              <Space vertical={15} />
              <Row justify="center">
                <Col span={2}></Col>
                <Col span={9}>
                  <Text color="black" size={46} centered>
                    $19
                  </Text>
                </Col>
                <Col span={2}>
                  <img src={MostPopular} width={87} className="most-popular-img" alt="Most popular" />
                </Col>
              </Row>
              <Space vertical={15} />
              <Text color="grey3" centered strong size={14}>
                per month
              </Text>
              <Space vertical={25} />
              <Text color="black" size={14} centered>
                Just right for full time rental managers with 10 or more rental units
              </Text>
              <Space vertical={25} />
              <Text size={20} strong color="grey3" centered>
                Features
              </Text>
              <Row>
                <Text
                  size={14}
                  color="grey3"
                  strong
                  style={{ display: 'inline', marginBottom: '20px', marginTop: '30px' }}
                >
                  <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                  25 units included
                  <span style={{ fontSize: '12px', marginLeft: '5px' }}>($3/additional unit)</span>
                </Text>
              </Row>

              <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
                <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                3-Day funding hold
              </Text>
              <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
                <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                $2 per Bank Transfer
              </Text>
              {(screenWidth >= 768 || !hideColumnDiys) && (
                <>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    3.5% + $0.30 per credit card transaction
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Rent reminders
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Payment controls
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Scheduled payments
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Automatic Late Fees
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Rent Collection Enforcement
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Monitor Renters Insurance
                  </Text>
                </>
              )}

              {screenWidth < 768 && hideColumnDiys && (
                <Text color="violet" underline size={14} centered onClick={() => setHideColumnDiys(false)}>
                  More Features...
                </Text>
              )}

              <Button
                style={{
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  bottom: '15px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                color="yellow"
                onClick={() => history.push('/sign-up/landlord?plan=2')}
                id="DIY"
                data-testid="signUpDOTY"
              >
                SIGN UP
              </Button>
            </StyledCard>
          </Col>

          <Col
            xs={23}
            sm={18}
            md={8}
            lg={7}
            xl={6}
            xxl={5}
            className={hideColumnGlap ? 'glap-hidden' : 'glap-expanded'}
          >
            <CardWithTitle
              title="Go-Like-A-Pro"
              headFontSize={28}
              headAlign="center"
              bodyMinHeight={920}
              style={{ zIndex: 2, top: '-20px', borderRadius: '4px' }}
              type="pricing"
              className="pricing-columns pricing-column-3"
            >
              <Space vertical={15} />
              <Text color="grey3" centered strong size={14}>
                TRY FREE FOR 14-DAYS
              </Text>
              <Space vertical={15} />
              <Text color="black" size={46} centered>
                $49
              </Text>
              <Space vertical={15} />
              <Text color="grey3" centered strong size={14}>
                per month
              </Text>
              <Space vertical={25} />
              <Text color="black" size={14} centered>
                Perfect for professional property managers with 25 or more rental units.
              </Text>
              <Space vertical={25} />
              <Text size={20} strong color="grey3" centered>
                Features
              </Text>
              <Row>
                <Text
                  size={14}
                  color="grey3"
                  strong
                  style={{ display: 'inline', marginBottom: '20px', marginTop: '30px' }}
                >
                  <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                  10 units included
                  <span style={{ fontSize: '12px', marginLeft: '5px' }}>($3/additional unit)</span>
                </Text>
              </Row>

              <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
                <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                1-Day funding hold
              </Text>
              <Text size={16} color="grey3" strong style={{ display: 'flex', marginBottom: '20px' }}>
                <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                FREE Bank Transfers
              </Text>
              {(screenWidth >= 768 || !hideColumnGlap) && (
                <>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    3.5% + $0.30 per credit card transaction
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Rent reminders
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Payment controls
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Scheduled payments
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Automatic Late Fees
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Rent Collection Enforcement
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Custom Management Reports
                  </Text>
                  <Text size={16} color="grey3" style={{ display: 'flex', marginBottom: '20px' }}>
                    <CheckOutlined style={{ color: '#541388', marginRight: '10px' }} />
                    Monitor Renters Insurance
                  </Text>
                </>
              )}

              {screenWidth < 768 && hideColumnGlap && (
                <Text color="violet" underline size={14} centered onClick={() => setHideColumnGlap(false)}>
                  More Features...
                </Text>
              )}

              <Button
                style={{
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  bottom: '15px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                color="yellow"
                onClick={() => history.push('/sign-up/landlord?plan=3')}
                id="GLAP"
                data-testid="signUpGLAP"
              >
                SIGN UP
              </Button>
            </CardWithTitle>
          </Col>
        </StyledRow>

        <Calculator />
      </StyledContent>
    </Layout>
  );
};

const StyledHeader = styled(Header)`
  background: rgb(203, 71, 184);
  background: radial-gradient(57.37% 717.26% at 0% 4.16%, #cb47b8 0%, #541388 100%);

  height: 40%;
  text-align: center;
  align-items: center;

  h1 {
    color: white;
  }
  .header-main-img {
    width: 749px;
  }
  @media screen and (max-width: 1050px) {
    .header-main-img {
      width: 700px;
    }
  }
  @media screen and (max-width: 768px) {
    .header-main-img {
      width: 600px;
    }
  }
  @media screen and (max-width: 650px) {
    .header-main-img {
      width: 500px;
    }
  }
  @media screen and (max-width: 576px) {
    padding-top: 22px;
    .header-main-img {
      width: 320px;
    }
    h1 {
      font-size: 34px;
    }
  }
`;

const BannerContainer = styled.div`
  position: relative;
  width: 70%;
  height: 54px;
  display: flex;
  margin: 0 auto;
  @media screen and (max-width: 576px) {
    width: 80%;
  }
  @media screen and (max-width: 575px) {
    width: 90%;
  }
`;

const StyledContent = styled(Content)`
  height: 100%;
  background-color: #f4f4f0;
`;

const StyledBanner = styled.div`
  width: 100%;
  height: 54px;
  background: #ffcc00;
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: center;
  z-index: 10;
  .h3-header {
    font-weight: 400;
    font-size: 29px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 1200px) {
    .h3-header {
      font-size: 24px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1050px) {
    .h3-header {
      font-size: 21px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 900px) {
    .h3-header {
      font-size: 18px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 768px) {
    .h3-header {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
    }
  }
  @media screen and (max-width: 578px) {
    .h3-header {
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
    }
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledCard = styled(CardWithTitle)`
  box-shadow: 0px 0px 20px 9px rgba(0, 0, 0, 0.2);
  z-index: 10;
  top: -35px;
  border-radius: 4px;
  @media screen and (max-width: 767px) {
    box-shadow: unset;
    background-color: #feffe6;
    top: unset;
  }
`;

export default Pricing;
