import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import styled from 'styled-components';

import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import moment from 'moment';

import PanelFooter from 'components/Panel/PanelFooter';

const CreditCardDetail = (props) => {
  const servicePlanData = useSelector((store) => store.session.userData.servicePlan);

  const { onFinish, loading, checkZipCode, validateCardNo, editPaymentMethod } = props;
  const { Option } = Select;
  const monthFormat = 'MM/YY';
  // const [form] = Form.useForm();

  useEffect(() => {
    if (editPaymentMethod.length) {
      props.form.setFieldsValue({
        paymentMethodName: editPaymentMethod[0].label,
        userName: editPaymentMethod[0].paymentGatewayMeta?.user_name,
        cardNumber: `xxxx xxxx xxxx ${editPaymentMethod[0].paymentGatewayMeta?.last_four}`,
        expirationDate: moment(
          `${editPaymentMethod[0].paymentGatewayMeta?.expiration_month}/${editPaymentMethod[0].paymentGatewayMeta?.expiration_year}`,
          'MM/YY'
        ),
      });
    }
  }, [editPaymentMethod, props.form]);

  return (
    <>
      <Text size={12} color={'black'} strong centered>
        {`Fees: ${(Number(servicePlanData?.creditCardRateFee) * 100).toFixed(2)}% + $${Number(
          servicePlanData?.creditCardTrxFee
        ).toFixed(2)} per transaction`}
      </Text>
      <Space vertical={10} />
      <Form
        form={props.form}
        onFinish={onFinish}
        initialValues={{
          billingCity: 'Select City',
          state: 'Select State',
          // cardNumber: editPaymentMethod.length ? editPaymentMethod[0].label.split(' ')[1] : '',
        }}
        data-testid="formTest"
        style={{ height: 'calc(100% - 29px)', display: 'flex', flexDirection: 'column' }}
      >
        <ContentContainer className="content-container">
          <span className="inputTitle">Payment Method Name</span>
          <Form.Item name="paymentMethodName" whitespace="true">
            <Input disabled data-testid="paymentMethodNameTest" />
          </Form.Item>

          <span className="inputTitle">Full Name</span>
          <Form.Item
            name="userName"
            whitespace="true"
            rules={[{ required: true, message: 'Please enter your full name' }]}
            hasFeedback={!editPaymentMethod.length}
          >
            <Input data-testid="userNameTest" disabled={editPaymentMethod.length} />
          </Form.Item>

          <span className="inputTitle">Credit Card Number</span>
          <Form.Item
            name="cardNumber"
            whitespace="true"
            rules={[
              {
                validator: validateCardNo,
              },
            ]}
            hasFeedback={!editPaymentMethod.length}
          >
            <Input id="success" data-testid="creditCardTest" disabled={editPaymentMethod.length} />
          </Form.Item>

          <Row gutter={15}>
            <Col span={12}>
              <span className="inputTitle">Expiration date</span>
              <Form.Item
                name="expirationDate"
                whitespace="true"
                rules={[{ required: true, message: 'Please enter the expiration date' }]}
                hasFeedback={!editPaymentMethod.length}
              >
                <DatePicker
                  placeholder="MM/YY"
                  format={monthFormat}
                  disabledDate={(current) => current && current < moment().startOf('month')}
                  picker="month"
                  style={{ width: '100%' }}
                  data-testid="expDateTest"
                  disabled={editPaymentMethod.length}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="inputTitle">CVV</span>
              <Form.Item
                name="cvv"
                whitespace="true"
                rules={[{ required: true, message: 'Please enter the cvv number' }]}
                hasFeedback
              >
                <Input data-testid="cvvTest" disabled={editPaymentMethod.length} />
              </Form.Item>
            </Col>
          </Row>
          {!editPaymentMethod.length && (
            <>
              <span className="inputTitle">Billing address</span>
              <Form.Item name="billingAddress" whitespace="true">
                <Input disabled />
              </Form.Item>

              <Row gutter={15}>
                <Col span={10}>
                  <span className="inputTitle">Billing City</span>
                  <Form.Item name="billingCity" whitespace="true">
                    <Select onChange={() => {}} disabled data-testid="billingCityTest">
                      <Option value="1">One</Option>
                      <Option value="2">Two</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <span className="inputTitle">State</span>
                  <Form.Item name="state" whitespace="true">
                    <Select onChange={() => {}} disabled data-testid="stateSelectTest">
                      <Option value="1">One</Option>
                      <Option value="2">Two</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <span className="inputTitle">Zip Code</span>
                  <Form.Item
                    name="postalCode"
                    whitespace="true"
                    rules={[
                      {
                        validator: checkZipCode,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input data-testid="zipCodeTest" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </ContentContainer>
        <PanelFooter>
          <Button
            htmlType="submit"
            type="primary"
            alignment="center"
            size="large"
            loading={loading}
            data-testid="saveTest"
            disabled={editPaymentMethod.length}
            bottom={0}
          >
            Save
          </Button>
        </PanelFooter>
      </Form>
    </>
  );
};

const ContentContainer = styled.div`
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
`;

export default CreditCardDetail;
