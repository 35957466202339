import axios from 'axios';
import Jsona from 'jsona';
import { refreshToken } from 'services/aws/cognito';
import store from '../../../store';

const dataFormatter = new Jsona();

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const deleteUnconfirmedCognitoUser = async (email, phoneNumber) => {
  try {
    const data = {
      data: {
        attributes: {
          phone: phoneNumber,
          email,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/cognito/unconfirmed`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const deletePaymentMethodV3 = async (id, cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  const url = `${process.env.REACT_APP_API_URL_V3}/payment-methods/${id}`;

  const options = {
    method: 'DELETE',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
    },
    url,
  };

  await axios(options);
};
