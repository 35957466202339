import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { documentsPresignedUrl } from 'services/api/common/create';

import { ReactComponent as SureLogo } from 'assets/images/sure-logo.svg';
import { Row, Col, Upload, Divider } from 'antd';
import { DollarOutlined, CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';

import Button from 'components/Button';
import AlertFade from 'components/AlertFade';

const { Dragger } = Upload;

const GetUploadInsurance = (props) => {
  const { setGetUploadVisible, setInsuranceStepsVisible, handleUploadFile, userPolicy } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const uploadedInsuranceFile = useSelector((store) => store.tenant.uploadedInsuranceFile);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    message: '',
    description: '',
    type: 'warning',
    isVisible: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const ba = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (ba.length) {
        const res = await documentsPresignedUrl(
          session.cognitoTokens.idToken.jwtToken,
          ba[0].id,
          'download',
          '',
          'insurance'
        );
        if (res.presignedUrl) {
          dispatch({ type: 'SET_INSURANCE_POLICY_FILE', payload: true }); // to disable buttons
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayAlert = (message, description, type) => {
    setAlert({
      message,
      description,
      type,
      isVisible: true,
    });

    setTimeout(() => {
      setAlert({
        message: '',
        description: '',
        type,
        isVisible: false,
      });
    }, 5000);
  };

  const customRequest = async (info) => {
    try {
      setLoading(true);
      await handleUploadFile(info);
      displayAlert('Successful Upload', 'Your policy policy details will be available shortly.', 'success');
      setLoading(false);
    } catch (error) {
      displayAlert(error.message || 'There was a problem with your request', '', 'warning');
      setLoading(false);
    }
  };

  const draggerOptions = {
    accept: '.pdf,.jpg,.jpeg,.png',
    multiple: false,
    showUploadList: false,
    customRequest: (info) => customRequest(info),
  };

  return (
    <Row justify="center" align="middle" style={{ height: 'calc(100% - 65px)', flexDirection: 'column' }}>
      <Col
        span={20}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          color="violet"
          alignment="center"
          bottom={24}
          onClick={() => {
            setGetUploadVisible(false);
            setInsuranceStepsVisible(true);
          }}
          data-testid="getInsuredTest"
          disabled={userPolicy || uploadedInsuranceFile}
        >
          <DollarOutlined style={{ fontSize: '12px' }} />
          GET INSURED
        </Button>
        <SureLogo />
        <Divider>or</Divider>
        <AlertFade
          message={alert.message}
          description={alert.description}
          type={alert.type}
          isVisible={alert.isVisible}
          position="relative"
        />
        <Dragger {...draggerOptions} disabled={userPolicy || uploadedInsuranceFile}>
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined style={{ color: '#000000' }} />
          </p>
          <p style={{ padding: '0px 15px 15px 15px' }}>Drag policy document to this area or click Upload Policy</p>
          <Button
            color="violet"
            alignment="center"
            loading={loading}
            disabled={userPolicy || uploadedInsuranceFile}
          >
            <UploadOutlined style={{ fontSize: '12px' }} />
            UPLOAD POLICY
          </Button>
        </Dragger>
      </Col>
    </Row>
  );
};

export default GetUploadInsurance;
