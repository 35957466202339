import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Alert, Button } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { alertVariants } from 'animations/variants';

function SystemMessage(props) {
  // console.log('SystemMessage props', props);
  const { displayAlert, displayConditions, action, ...rest } = props;
  const history = useHistory();
  const location = useLocation();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const getValue = (t, path) => path.split('.').reduce((r, k) => r?.[k], t);

  const showAlert =
    displayAlert && displayConditions.every((condition) => getValue(state, condition.path) === condition.value);

  const handleAction = new Function('history', 'location', 'session', 'dispatch', action?.fn);

  return (
    <StyledMessageWrapper showAlert={showAlert} data-testid="systemMsgTest">
      <motion.div variants={alertVariants} initial="hide" animate="show">
        <StyledAlert
          {...rest}
          action={
            action?.showAction ? (
              <Button
                type="default"
                onClick={() => handleAction(history, location, state, dispatch)}
                data-testid="systemMessageButtonTest"
              >
                {action.label || 'Submit'}
              </Button>
            ) : null
          }
        />
      </motion.div>
    </StyledMessageWrapper>
  );
}

SystemMessage.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string.isRequired,
  type: PropTypes.string,
  showIcon: PropTypes.bool,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  banner: PropTypes.bool,
};

SystemMessage.defaultProps = {
  description: 'This is a system message.',
  type: 'info',
  showIcon: true,
  closable: false,
  banner: false,
  onClose: () => {},
};

const StyledMessageWrapper = styled.div`
  margin: 18px;
  min-height: 68px;
  display: ${(props) => (props.showAlert ? 'block' : 'none')};
`;
const StyledAlert = styled(Alert)`
  gap: 8px;
`;

export default React.memo(SystemMessage);
