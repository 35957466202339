// @flow
import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Form, Radio, Card as AntCard, Spin, Tooltip, Row, Col, Input } from 'antd';
import ReactJoyride from 'react-joyride';
import {
  BankFilled,
  CreditCardFilled,
  PlusCircleFilled,
  ScheduleOutlined,
  ArrowLeftOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import { debounce } from 'lodash';
import { ReactComponent as RentCredLogo } from 'assets/images/rentcred/rentcred-logo.svg';
import { addACHMethod } from 'services/wepay';
import styled from 'styled-components';
import moment from 'moment';
import { usePlaidLink } from 'react-plaid-link';
import { usStates } from 'resources/FormData';
import { getUserDataV3 } from 'store/actions/sessionActions';
import * as tenantApi from 'services/api/tenantApi';
import { getPlaidOauthLinkToken } from 'services/api/landlordApi/read';
import { getServicePlanData, getTourStatus } from 'services/api/common/read';
import { updateTourStatus } from 'services/api/common/update';
import { getPaymentMethods, getBillingItems, getPaymentMethodsHistory } from 'store/actions/tenantActions';

// Components
import Title from 'components/Title';
import Link from 'components/Link';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import PanelFooter from 'components/Panel/PanelFooter';
import Overlay from './components/Overlay/Overlay';
import { calculateFee, getBalanceDueWithPending } from 'resources/helpers';
import { makePaymentSteps } from '../../../../resources/tourSteps';
import VerifyPaymentMethod from '../../components/VerifyPaymentMethod/VerifyPaymentMethod';
import createFinixFraudSessionId from 'services/finix/finixFraudSession';

//import './style.scss';
const StyledRadio = styled(Radio)`
  .ant-radio {
    align-self: start;
  }
`;

const MakePayment = (props) => {
  const {
    balanceDue,
    setBalanceDue,
    otherAmount,
    setOtherAmount,
    additionalFees,
    setShowQuadpayIneligible,
    quadpayResult,
    setShowQuadpayEligible,
    setQuadpayRedirect,
    silaOnboardingStatus,
    checkPaymentMethodStatus,
  } = props;
  const rentCredStatus = useSelector((store) => store.tenant.rentCred);
  const userData = useSelector((store) => store.session.userData);
  const { absorbFeesPercent, absorbFees, acceptCreditCard, preventPayments } = userData.property.paymentSettings;
  const servicePlanData = useSelector((store) => store.session.userData.servicePlan);
  const billingItems = useSelector((store) => store.tenant.billingItems);
  const paymentSettings = useSelector((store) => store.tenant.paymentSettings);
  const paymentMethod = useSelector((store) => store.tenant.paymentMethods);
  const paymentMethodsHistory = useSelector((store) => store.tenant.paymentMethodsHistory);
  const firstPayment = useSelector((store) => store.tenant.firstPayment);
  const session = useSelector((store) => store.session);
  const userPropertyState = usStates.filter((ab) => ab.name === session.userData.property.state.toUpperCase())[0];

  const [servicePlan, setServicePlan] = useState(null);
  const [form] = Form.useForm();
  const [due, setDue] = useState((0).toFixed(2));
  const billingAccountRef = React.useRef({});
  const [totalAmount, setTotalAmount] = useState(0);
  const totalAmountRef = useRef(0);
  const pMethodUpdateRef = useRef(false);
  const sendAmountRef = useRef(0);
  const [sendAmount, setSendAmount] = useState(0);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentAmountType, setPaymentAmountType] = useState('balanceDue');

  const [disableOtherAmount, setDisableOtherAmount] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [fee, setFee] = useState(0);
  const [radio, setRadio] = useState(-1);
  // const [payMethodsVisible, showPayMethods] = useState(false);
  // const [absorbFeesPercent, setAbsorbFeesPercent] = useState(0);
  const [totalAmountValidation, setTotalAmountValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    visible: false,
    message: '',
    type: 'warning',
    description: '',
  });
  const pButtonClicked = React.useRef(false);
  const [paymentButton, setPaymentButton] = useState({
    text: 'PAY NOW',
    clicked: 0,
    outlined: false,
    color: 'violet',
  });

  const [achFee, setAchfee] = useState(0);
  const [tourStatus, setTourStatus] = useState({ tourViewed: true, paymentScreenTour: true });
  const [userBillingAccount, setUserBillingAccount] = useState({ upcoming: 0 });
  const [showQuadpay, setShowQuadpay] = useState(false);
  const [paymentRadio, setPaymentRadio] = useState(false);
  const [quadpayLoading, setQuadpayLoading] = useState(false);

  const [plaidToken, setPlaidToken] = useState(null);
  const otherAmountInputRef = useRef(null);

  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  const dispatch = useDispatch();

  const [finixAuth, setFinixAuth] = useState(null);

  const onSuccess = useCallback((token, metadata) => {
    const plaid = {
      token,
      metadata,
    };
    AddBankAccount(plaid);
  }, []);

  const onExit = () => {
    displayAlert('Payment Method Error', 'warning', 'Something went wrong while adding your payment method!');
  };

  // customizacion del css https://support.plaid.com/hc/en-us/articles/360008420353-Plaid-Link-customization

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
    onExit,
  };
  const { open } = usePlaidLink(config);

  useEffect(() => {
    const controller = new AbortController();
    if (quadpayResult?.cancelled) {
      displayPanelAlert(true, 'Your payment was cancelled.', 'warning', 4000);
    }
    dispatch(getBillingItems());
    dispatch(getPaymentMethodsHistory(userData.paymentGateway, true, session.cognitoTokens.idToken.jwtToken));
    fetchAndSetData(controller);
    dispatch({
      type: 'HEADER_BUTTON_VISIBLE',
      visible: true,
      screenTitle: 'Payment',
      leftButtonPath: '/tenant',
      leftButtonType: 'back',
    });
    return () => {
      controller.abort();
      if (props.timerId) clearInterval(props.timerId);
      dispatch({
        type: 'HEADER_BUTTON_VISIBLE',
        visible: false,
        screenTitle: '',
        leftButtonPath: '',
        leftButtonType: '',
      });
    };
  }, []);

  const fetchAndSetData = async (controller) => {
    try {
      setDisableButtons(true);
      setBalanceDue(true);
      dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));

      if (userData.paymentGateway === 'Finix') {
        if (!userData.property.propertyGroup.finixAccount) throw new Error('No merchant account found');
        const auth = await createFinixFraudSessionId(userData.property.propertyGroup.finixAccount.merchantId);
        setFinixAuth(auth);
      }

      const servicePlanRes = await getServicePlanData(session.cognitoTokens.idToken.jwtToken);
      setServicePlan(servicePlanRes);

      const activeBillingAccount = userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (activeBillingAccount.length) {
        const billingAccountRes = await tenantApi.getBillingAccount(
          session.cognitoTokens.idToken.jwtToken,
          activeBillingAccount[0].id
        );
        setUserBillingAccount(billingAccountRes);
      }

      if (userData.paymentGateway === 'Wepay') props.checkPaymentMethodStatus();
      const defaultPaymentMethodIndex = paymentMethod.length
        ? paymentMethod.findIndex((p) => p.defaultMethod === true)
        : -1;

      // pm.paymentMethodType === 'Card' && !acceptCreditCard
      if (defaultPaymentMethodIndex > -1) {
        if (
          ((paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'Card' ||
            paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'CREDIT' ||
            paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'DEBIT') &&
            acceptCreditCard &&
            paymentMethod[defaultPaymentMethodIndex].state === 'ACTIVE') ||
          (paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'ACH' &&
            paymentMethod[defaultPaymentMethodIndex].state === 'ACTIVE')
        ) {
          props.setPaymentMethodSelection(defaultPaymentMethodIndex);
          form.setFieldsValue({ paymentMethod: defaultPaymentMethodIndex });
          setRadio(defaultPaymentMethodIndex);
        }
      }
      if (userData.paymentGateway === 'SilaStripe') {
        if (isOAuthRedirect) {
          setPlaidToken(localStorage.getItem('link_token'));
        } else {
          if (session.userData.paymentGateway !== 'Wepay') {
            const plaidOauthToken = await getPlaidOauthLinkToken(session.cognitoTokens.idToken.jwtToken);
            setPlaidToken(plaidOauthToken.linkToken);
            localStorage.setItem('link_token', plaidOauthToken.linkToken);
          }
        }
      }

      setDisableButtons(false);
    } catch (err) {
      console.error(err);
      displayPanelAlert(true, err.message || `An error occured loading the payment panel.`, 'warning', 4000);
    }
  };

  const fetchTourStatus = async () => {
    const tourRes = await getTourStatus(session.cognitoTokens.idToken.jwtToken, session.userData.id);
    // if (!tourRes.hasOwnProperty('paymentScreenTour')) {
    //   tourRes.paymentScreenTour = false;
    // }
    setTourStatus(tourRes);
  };

  const checkSameDayPayment = async () => {
    const today = moment(Date.now()).format('MM-DD-YYYY');
    const items = await tenantApi.getBillingItems(
      'state:PROCESSED,state:PROCESSING',
      null,
      'orderDate',
      'payment,billingCharge',
      10,
      1
    );

    const todayPayments = items.filter(
      (i) => i.refType === 'Payment' && moment(i.createdAt).format('MM-DD-YYYY') === today
    );
    if (todayPayments.length) {
      return true;
    }
    return false;
  };

  const displayAlert = (message, type, description, persist) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    if (!persist) {
      setTimeout(() => {
        dispatch({
          type: 'UNSET_ALERT',
        });
      }, 5000);
    }
  };

  const displayPanelAlert = (visible, message, type, time) => {
    setAlert({
      visible,
      message,
      type,
    });
    setTimeout(() => {
      setAlert({
        visible: false,
        message,
        type,
      });
    }, time);
  };

  const calculateTotalAmount = async (value, propertySettings, selectedPm, quadpay) => {
    if (quadpay) setQuadpayLoading(true);
    const totalFee = await calculateFee(
      value,
      propertySettings,
      selectedPm,
      servicePlan,
      paymentMethod,
      firstPayment,
      absorbFees,
      absorbFeesPercent,
      false,
      quadpay,
      session.cognitoTokens.idToken.jwtToken,
      userPropertyState.abbreviation
    );

    const achFeeAmount = await calculateFee(
      value,
      propertySettings,
      selectedPm,
      servicePlan,
      [],
      firstPayment,
      absorbFees,
      absorbFeesPercent,
      'ACH'
    );
    setAchfee(achFeeAmount);

    const calculatedAmount = quadpay && value >= 500 ? 500 : value;
    const total = calculatedAmount + parseFloat(totalFee);
    // setOtherAmount(0);
    setFee(totalFee.toFixed(2));

    setSendAmount(Number(calculatedAmount));
    setTotalAmount(Number(total).toFixed(2));
    sendAmountRef.current = Number(calculatedAmount);
    totalAmountRef.current = Number(total).toFixed(2);
    if (quadpay) setQuadpayLoading(false);
  };

  const handleBalanceDue = (e) => {
    // if (userData?.property?.paymentSettings?.requireFullPayment && due > 0) {
    //   return displayPanelAlert(true, 'Full payment required', 'error', 5000);
    // }
    if (e.target.checked) {
      setPaymentAmountType('balanceDue');
      if (+due > 0) {
        calculateTotalAmount(+due, userData.property, form.getFieldValue('paymentMethod'), showQuadpay);
        setOtherAmount(0);

        form.setFieldsValue({
          otherAmount: '0.00',
        });
      } else {
        form.setFieldsValue({
          otherAmount: '0.00',
        });
        totalAmountRef.current = 0;
        sendAmountRef.current = 0;
        setTotalAmount(0);
        setFee(0);
        setOtherAmount(0);
      }
      setDisableOtherAmount(false);
      setBalanceDue(true);
    } else {
      totalAmountRef.current = 0;
      sendAmountRef.current = 0;
      setTotalAmount(0);
      setFee(0);
      setBalanceDue(false);
      setDisableOtherAmount(true);
    }
  };

  const handleClickOtherAmount = (e) => {
    // if (userData?.property?.paymentSettings?.requireFullPayment && due > 0) {
    //   return displayPanelAlert(true, 'Full payment required', 'error', 5000);
    // }
    if (e.target.checked) {
      totalAmountRef.current = 0;
      sendAmountRef.current = 0;
      setTotalAmount(0);
      setFee(0);
      setAchfee(0);
      setBalanceDue(false);
      setPaymentAmountType('otherAmount');
      setDisableOtherAmount(true);
    }
  };

  const AddBankAccount = async (plaid) => {
    const pm = paymentMethod[form.getFieldValue('paymentMethod')];
    setAlert({
      visible: false,
    });
    try {
      if (userData.paymentGateway === 'Wepay') {
        const microDepositPm = paymentMethodsHistory.filter(
          (p) =>
            (p.microDepositsFlow === true && p.paymentGatewayMeta?.state === 'pending') ||
            (p.microDepositsFlow === true && p.state === 'PENDING') ||
            (p.microDepositsFlow === true && p.state === 'SCHEDULED')
        );

        const disableMicrodeposits = microDepositPm.length > 0;
        const PaymentMethodRes = await addACHMethod(userData.email, disableMicrodeposits);

        if (PaymentMethodRes === null) {
          displayAlert(
            'Payment Method Error',
            'warning',
            'Something went wrong while adding your payment method.'
          );
          return;
        }

        setPaymentLoader(true);

        let alertTitle = 'Payment Method Added';
        let alertType = 'success';
        let description = '';
        let microdeposits = false;
        let persist = false;

        if (PaymentMethodRes.flow === 'microdeposits') {
          microdeposits = true;
          alertTitle = 'Payment Method Requires Verification';
          alertType = 'warning';
          persist = true;
          description =
            'We have sent 2 microdeposits to your account to verify ownership. Please check your email for details and follow the instructions to confirm this payment method.';
        }
        delete PaymentMethodRes.flow;

        await tenantApi.createPaymentMethod(PaymentMethodRes);
        dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
        dispatch(getPaymentMethodsHistory(userData.paymentGateway, true, session.cognitoTokens.idToken.jwtToken));
        setPaymentLoader(false);
        if (!microdeposits) props.checkPaymentMethodStatus();

        displayAlert(alertTitle, alertType, description, persist);
      }
      if (userData.paymentGateway === 'Nuvei' || userData.paymentGateway === 'SilaStripe') {
        setPaymentLoader(true);
        await tenantApi.createACHPaymentMethod(
          session.cognitoTokens.idToken.jwtToken,
          plaid,
          userData.paymentGateway
        );
        setPaymentLoader(false);
        dispatch(getPaymentMethods(userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
      }
    } catch (err) {
      setLoading(false);
      pMethodUpdateRef.current = false;
      displayAlert('Payment Method Error', 'warning', 'Something went wrong while adding your payment method.');
    }
  };

  const handleOtherAmountChange = debounce(async (e) => {
    if (e.target) {
      const { value } = e.target;
      if (value.indexOf(',') > -1) {
        const newValue = value.replace(',', '.');
        form.setFieldsValue({ otherAmount: newValue });
        return;
      }

      const val = +value;
      if (isNaN(val)) {
        form.setFieldsValue({ otherAmount: '' });
      }
      if (val === 0) {
        totalAmountRef.current = 0;
        sendAmountRef.current = 0;
        setOtherAmount(val);
        setTotalAmount(0);
        setFee(0);
        setAchfee(0);
      }
      if (typeof +val === 'number' && +val > 0) {
        if (showQuadpay) setQuadpayLoading(true);
        const totalFee = await calculateFee(
          val,
          userData.property,
          radio,
          servicePlan,
          paymentMethod,
          firstPayment,
          absorbFees,
          absorbFeesPercent,
          false,
          showQuadpay,
          session.cognitoTokens.idToken.jwtToken,
          userPropertyState?.abbreviation
        );
        const achFeeAmount = await calculateFee(
          val,
          userData.property,
          radio,
          servicePlan,
          [],
          firstPayment,
          absorbFees,
          absorbFeesPercent,
          'ACH'
        );
        setAchfee(achFeeAmount);
        const otherAmountValue = showQuadpay && val >= 500 ? 500 : val;
        const total = otherAmountValue + Number(totalFee.toFixed(2));
        setFee(totalFee.toFixed(2));
        sendAmountRef.current = Number(val.toFixed(2));
        totalAmountRef.current = Number(total.toFixed(2));
        setSendAmount(Number(val.toFixed(2)));
        setTotalAmount(Number(total.toFixed(2)));
        setTotalAmountValidation(false);
        setOtherAmount(otherAmountValue);
        if (showQuadpay) {
          form.setFieldsValue({ otherAmount: otherAmountValue });
          setQuadpayLoading(false);
        }
      }
    }
  }, 500);

  const handleBlurOtherAmount = async (e) => {
    const pm = form.getFieldValue('paymentMethod');

    let validAmount = 0;
    if (pm != undefined && typeof pm === 'number') {
      if (paymentMethod[pm].paymentMethodType === 'ACH') {
        validAmount = 10;
      }
    }

    if (+otherAmount > validAmount) {
      const totalFee = await calculateFee(
        otherAmount,
        userData.property,
        radio,
        servicePlan,
        paymentMethod,
        firstPayment,
        absorbFees,
        absorbFeesPercent,
        false,
        showQuadpay,
        session.cognitoTokens.idToken.jwtToken,
        userPropertyState.abbreviation
      );
      const achFeeAmount = await calculateFee(
        otherAmount,
        userData.property,
        radio,
        servicePlan,
        [],
        firstPayment,
        absorbFees,
        absorbFeesPercent,
        'ACH'
      );
      setAchfee(achFeeAmount);
      const total =
        showQuadpay && otherAmount >= 500
          ? 500 + Number(totalFee.toFixed(2))
          : otherAmount + Number(totalFee.toFixed(2));
      setFee(totalFee.toFixed(2));
      sendAmountRef.current = Number(otherAmount.toFixed(2));
      totalAmountRef.current = Number(total.toFixed(2));
      setOtherAmount(Number(otherAmount.toFixed(2)));
      setSendAmount(Number(otherAmount.toFixed(2)));
      setTotalAmount(Number(total.toFixed(2)));
    } else {
      totalAmountRef.current = 0;
      sendAmountRef.current = 0;
      setTotalAmount(0);
      setFee(0);
      setAchfee(0);
    }
  };

  const handleRadioChange = (e) => {
    if (e.target.value === 'quadpay') {
      setShowQuadpay(true);
      const payAmount = balanceDue ? due : otherAmount;
      if (payAmount > 0) calculateTotalAmount(payAmount, userData.property, e.target.value, true);
      if (!balanceDue) {
        form.setFieldsValue({ otherAmount: otherAmount >= 500 ? 500 : otherAmount });
      }
      return;
    }
    setShowQuadpay(false);
    setPaymentRadio(e.target.value);
    props.setPaymentMethodSelection(e.target.value);
    setRadio(e.target.value);

    const payAmount = balanceDue ? +due : otherAmount;
    if (payAmount > 0) calculateTotalAmount(payAmount, userData.property, e.target.value, false);
    if (!balanceDue) {
      form.setFieldsValue({ otherAmount });
    }
  };

  const handlePayment = async () => {
    setLoading(true);

    if (paymentAmountType !== 'balanceDue' || (showQuadpay && paymentAmountType === 'balanceDue')) {
      if (userData?.property?.paymentSettings?.requireFullPayment) {
        if (due > 0) {
          if (+otherAmount < due) {
            displayPanelAlert(true, 'Full payment required', 'error', 5000);

            return setLoading(false);
          }
        }
      }
    }

    if (totalAmountRef.current === 0) {
      setTotalAmountValidation(true);
      return setLoading(false);
    }

    const pm = form.getFieldValue('paymentMethod');
    if ((await checkSameDayPayment()) && paymentButton.clicked === 0 && !pMethodUpdateRef.current) {
      setPaymentButton({ text: 'CONFIRM PAYMENT', clicked: 1, outlined: true, color: '' });
      pButtonClicked.current = true;
      setLoading(false);
      return setAlert({
        visible: true,
        message: 'You have already made a payment today. Click to confirm additional payment.',
        type: 'warning',
      });
    }
    const result = getActiveScheduledPayments(props.scheduledPayments.items);

    if (result && paymentButton.clicked === 0 && !pMethodUpdateRef.current) {
      setPaymentButton({ text: 'CONFIRM PAYMENT', clicked: 1, outlined: true, color: '' });
      pButtonClicked.current = true;
      setLoading(false);
      return setAlert({
        visible: true,
        message: 'You have a scheduled payment. Click to confirm additional payment.',
        type: 'warning',
      });
    }
    pMethodUpdateRef.current = false;

    try {
      if (showQuadpay) {
        // Check user status
        if (rentCredStatus.renterTierId < 3 || rentCredStatus.points < 6) {
          props.setShowPayment(false);
          setShowQuadpayIneligible(true);
          return;
        }
        const sendValue = paymentAmountType === 'balanceDue' ? (+due >= 500 ? 500 : +due) : +otherAmount;
        const qRes = await tenantApi.makeQuadpayPayment(session.cognitoTokens.idToken.jwtToken, sendValue);
        setQuadpayRedirect(qRes.redirect);
        props.setShowPayment(false);
        setShowQuadpayEligible(true);
        return;
      }
      const paySettings = await tenantApi.getPaymentSettings(userData.property.id);

      if (
        (userData.paymentGateway === 'Wepay' && paySettings.customer.state === 'active') ||
        (userData.paymentGateway === 'Nuvei' && paySettings.customer.state === 'active') ||
        (userData.paymentGateway === 'SilaStripe' && paySettings.customer.state === 'active') ||
        (userData.paymentGateway === 'Finix' && paySettings.customer.state === 'active')
      ) {
        let sessionKey = null;
        if (userData.paymentGateway === 'Finix') {
          sessionKey = finixAuth.getSessionKey();
          console.log('[MakePayment.js] ƒ handlePayment fraud key', sessionKey);
        }

        const paymentResult = await tenantApi.makePayment(
          userData.paymentGateway,
          sendAmountRef.current,
          paymentMethod[pm],
          billingAccountRef.current,
          paymentSettings,
          session.cognitoTokens.idToken.jwtToken,
          userData,
          sessionKey
        );

        if (userData.paymentGateway === 'Nuvei' || userData.paymentGateway === 'SilaStripe') {
          paymentResult.due = due;
          if (!paymentResult.hasOwnProperty('fee')) paymentResult.fee = {};
          paymentResult.fee.firstPayment = firstPayment;
        }
        props.setPaymentData(paymentResult);

        dispatch(getBillingItems());
        dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken));

        setLoading(false);
        props.setShowPayment(false);
        props.updateRecentTransactions();
        props.setShowPaymentStatus(true);
      }
      if (
        paySettings.customer.state === 'pending' ||
        paySettings.customer.state === 'action-required' ||
        paySettings.customer.state === 'error' ||
        paySettings.customer.state === 'new' ||
        paySettings.customer.state === 'new2-step1' ||
        paySettings.customer.state === 'new2-step2' ||
        paySettings.customer.state === 'pending-step' ||
        paySettings.customer.state === 'stripe-payment-method-required'
      ) {
        // Make scheudled payment
        const scheduledPayment = {
          balanceDue: false,
          paymentAmount: sendAmountRef.current,
          frequency: 'ONE-TIME',
          endPaymentDate: null,
          firstPaymentDate: null,
          deliverBy: 'Send on',
          payFrom: paymentMethod[pm].id,
        };

        await tenantApi.schedulePayment(
          session.cognitoTokens.idToken.jwtToken,
          scheduledPayment,
          session.userData.id
        );
        props.setPaymentData({
          due,
          amount: balanceDue ? Number(due) : otherAmount,
          fee: {
            amount: fee,
            landlordAbsorbPercent: userData.property.paymentSettings.absorbFeesPercent,
          },
          landlordDisabled: true,
        });
        props.setShowPayment(false);
        props.setShowPaymentStatus(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      const res = err;

      if (res === undefined) {
        props.setDrawerVisible(false);
        displayAlert('Network error', 'warning', '');
        return;
      }
      if (res.status >= 400) {
        props.setPaymentData({
          due,
          totalAmount,
          fee: {
            amount: fee,
            landlordAbsorbPercent: userData?.property?.paymentSettings?.absorbFeesPercent,
          },
        });
        props.setPaymentError(res.data?.errors);
        props.setShowPayment(false);
        props.setShowPaymentStatus(true);
      }
    }
  };

  const checkPrice = (rule, value) => {
    const pm = form.getFieldValue('paymentMethod');

    let validAmount = 0;

    if (pm != undefined && typeof pm === 'number') {
      if (paymentMethod[pm].paymentMethodType === 'ACH') {
        validAmount = 10;
      }
    }
    if (value > validAmount && !balanceDue) {
      return Promise.resolve();
    }
    if (balanceDue === true) {
      return Promise.resolve();
    }
    return Promise.reject('Amount must be greater than $10.');
  };

  const handleInputLeave = async (e) => {
    const number = Number(e.target.value).toFixed(2);
    otherAmountInputRef.current.blur();
    form.setFieldsValue({
      otherAmount: number,
    });
  };

  const items = [
    {
      key: '1',
      label: (
        <>
          <BankFilled
            style={{
              marginRight: '10px',
              fontSize: '20px',
              color: 'black',
            }}
          />
          Add a bank account
          <Tooltip
            placement="right"
            title={`${(Number(servicePlanData?.achRateFee) * 100).toFixed(2)}%`}
            trigger="click"
          >
            <InfoCircleFilled
              style={{
                marginLeft: '10px',
                fontSize: '13px',
                color: 'black',
              }}
              data-testid="achTooltipTest"
            />
          </Tooltip>
        </>
      ),
      onClick: () => {
        if (userData.paymentGateway === 'Wepay') {
          AddBankAccount(null);
        }
        if (userData.paymentGateway === 'Finix') {
          props.setShowPayment(false);
          props.setShowPaymentMethodDetail(true);
          props.setNewPaymentMethodType('bank');
        }
        if (userData.paymentGateway === 'Nuvei' || userData.paymentGateway === 'SilaStripe') {
          if (plaidToken) open();
        }
      },
    },
    {
      key: '2',
      label: (
        <>
          <CreditCardFilled
            style={{
              marginRight: '10px',
              fontSize: '20px',
              color: 'black',
            }}
          />
          Add a credit card
          <Tooltip
            placement="right"
            title={`${(Number(servicePlanData?.creditCardRateFee) * 100).toFixed(2)}% + $${Number(
              servicePlanData?.creditCardTrxFee
            ).toFixed(2)}`}
            trigger="click"
          >
            <InfoCircleFilled
              style={{
                marginLeft: '10px',
                fontSize: '13px',
                color: 'black',
              }}
              data-testid="creditCardTooltipTest"
            />
          </Tooltip>
        </>
      ),
      onClick: () => {
        props.setShowPayment(false);
        props.setShowPaymentMethodDetail(true);
        props.setNewPaymentMethodType('card');
      },
      disabled: !userData?.property?.paymentSettings?.acceptCreditCard,
    },
  ];

  const getActiveScheduledPayments = (items) => {
    const now = moment();
    for (let i = 0; i < items.length; i++) {
      if (items[i].frequency === 'ONE-TIME' && now.isBefore(moment(items[i].firstPaymentDate))) {
        return true;
      }
      if (items[i].frequency === 'WEEKLY' && items[i].endDate === null) {
        return true;
      }
      if (items[i].frequency === 'MONTHLY' && items[i].endDate === null) {
        return true;
      }
    }
  };

  const handleFocusOtherAmount = () => {
    form.setFieldsValue({
      otherAmount: '',
    });
    setOtherAmount(0);
  };

  const handleJoyrideCallback = async (data) => {
    const { status, type, action, lifecycle } = data;

    if ((status === 'finished' && lifecycle === 'complete') || action === 'close') {
      try {
        await updateTourStatus(session.cognitoTokens.idToken.jwtToken, session.userData.id, {
          ...tourStatus,
          paymentScreenTour: true,
        });
        await fetchTourStatus();
      } catch (err) {
        displayAlert('Update Error', 'warning', err?.data?.errors[0].detail || 'Your request failed.');
      }
    }
  };

  // function paymentMethodType() {
  //   if (paymentAmountType === 'balanceDue') {
  //     return 'balanceDue';
  //   }
  //   if (paymentAmountType === 'otherAmount') {
  //     return 'otherAmount';
  //   }
  // }

  useEffect(() => {
    if (servicePlan) {
      fetchTourStatus();
      if (userData.paymentGateway === 'Wepay') checkPaymentMethodStatus();
      const defaultPaymentMethodIndex = paymentMethod.length
        ? paymentMethod.findIndex((p) => p.defaultMethod === true)
        : -1;

      if (userData?.billingAccounts?.length) {
        const activeBillingAccount = userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
        if (activeBillingAccount.length) {
          const dueAmount = activeBillingAccount[0].balance;

          if (+dueAmount <= 0) {
            setBalanceDue(false);
            setDisableOtherAmount(true);
          } else {
            setDisableOtherAmount(false);
          }

          billingAccountRef.current = {
            id: activeBillingAccount[0].id,
            type: activeBillingAccount[0].type,
          };
          const amountNumber = Number(dueAmount).toFixed(2);

          const newBalanceDue = getBalanceDueWithPending(amountNumber, billingItems.processing);

          setDue(newBalanceDue);

          if (balanceDue && +newBalanceDue > 0) {
            if (defaultPaymentMethodIndex > -1) {
              if (
                ((paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'Card' ||
                  paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'CREDIT' ||
                  paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'DEBIT') &&
                  acceptCreditCard) ||
                paymentMethod[defaultPaymentMethodIndex].paymentMethodType === 'ACH'
              ) {
                calculateTotalAmount(
                  Number(newBalanceDue),
                  userData.property,
                  defaultPaymentMethodIndex,
                  showQuadpay
                );
              } else {
                setTotalAmount(Number(newBalanceDue).toFixed(2));
                sendAmountRef.current = Number(newBalanceDue);
                totalAmountRef.current = Number(newBalanceDue).toFixed(2);
              }
            } else {
              setTotalAmount(Number(newBalanceDue).toFixed(2));
              sendAmountRef.current = Number(newBalanceDue);
              totalAmountRef.current = Number(newBalanceDue).toFixed(2);
            }
          }
        }
      }
    }
  }, [servicePlan]);

  return (
    <>
      {(preventPayments ||
        (silaOnboardingStatus.state !== 'PASSED' && session.userData.paymentGateway === 'SilaStripe')) && (
        <Overlay silaOnboardingStatus={silaOnboardingStatus} preventPayments={preventPayments} />
      )}
      <Container className="make-payment-container">
        <ReactJoyride
          steps={makePaymentSteps}
          callback={handleJoyrideCallback}
          continuous
          run={!tourStatus.paymentScreenTour}
          scrollToFirstStep
          showProgress
          showSkipButton
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
        <Header>
          <ArrowLeftOutlined
            onClick={() => {
              props.setDrawerVisible(false);
              props.setShowPayment(false);
              props.setPayClicked(false);
            }}
            style={{
              fontSize: '22px',
              color: 'white',
              marginRight: '10px',
              display: 'flex',
            }}
            data-testid="arrowTest"
          />
          Payment
        </Header>
        {/*showQuadpay && <Quadpay setShowQuadpay={setShowQuadpay} />*/}
        <FormContainer>
          <StyledForm
            form={form}
            onFinish={handlePayment}
            initialValues={{
              otherAmount: '0.00',
            }}
          >
            <ContentContainer className="content-container">
              <Title level={3} color="violet2">
                Account Balance
              </Title>

              <Text size={32} color={due < 0 ? 'green' : 'red'} centered strong>
                {userData && `$${due}`}
                &nbsp;
                <small style={{ fontSize: '16px' }}>{userData && due >= 0 ? 'DUE' : 'CREDIT'}</small>
              </Text>

              <Text size={16} color="red" centered strong>
                ${userBillingAccount?.upcoming?.toFixed(2)} upcoming
              </Text>

              <PaymentContainer className="makePaymentStep">
                <Text color="black" size={16} strong>
                  Make a Payment
                </Text>
                <Space vertical={10} />
                <Row justify="space-around">
                  <Col span={12}>
                    {/**
                   <Checkbox
                    onChange={handleBalanceDue}
                    checked={balanceDue}
                    disabled={disableButtons}
                    data-testid="balanceDueTest"
                  >
                    Balance due
                  </Checkbox>
                   */}

                    <StyledRadio
                      defaultChecked={false}
                      disabled={disableButtons || rentCredStatus.renterTierId === 0}
                      data-testid="balanceDueTest"
                      onClick={handleBalanceDue}
                      checked={paymentAmountType === 'balanceDue'}
                      style={{ alignSelf: 'start' }}
                    >
                      <div>Balance Due</div>
                      <Space vertical={6} />
                      <div>${due || (0.0).toFixed(2)}</div>
                    </StyledRadio>
                  </Col>
                  {/* <Col span={2}>
                    <Text color="black" size={14} strong>
                      OR
                    </Text>
                  </Col> */}
                  <Col span={12}>
                    <div className="payment-amount">
                      <Radio
                        disabled={disableButtons || rentCredStatus.renterTierId === 0}
                        onClick={handleClickOtherAmount}
                        checked={paymentAmountType === 'otherAmount'}
                      >
                        Other Amount
                      </Radio>
                      <Form.Item
                        name="otherAmount"
                        rules={[
                          {
                            validator: checkPrice,
                          },
                        ]}
                      >
                        <Input
                          prefix="$"
                          //type="number"
                          disabled={
                            !disableOtherAmount ||
                            disableButtons ||
                            paymentAmountType === 'balanceDue' ||
                            rentCredStatus.renterTierId === 0
                          }
                          onChange={(e) => {
                            e.persist();
                            handleOtherAmountChange(e);
                          }}
                          onFocus={handleFocusOtherAmount}
                          onBlur={handleBlurOtherAmount}
                          onMouseLeave={handleInputLeave}
                          data-testid="otherAmountTest"
                          ref={(input) => (otherAmountInputRef.current = input)}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </PaymentContainer>

              <PaymentMethodsContainer className="payment-methods-container">
                <Text color="black" size={16} strong>
                  Payment Methods
                </Text>
                <Space vertical={10} />
                <Form.Item
                  name="paymentMethod"
                  //valuePropName="value"
                  rules={[
                    {
                      required: true,
                      message: 'Please Choose your payment method.',
                    },
                  ]}
                >
                  <Radio.Group onChange={handleRadioChange}>
                    {paymentMethod.length > 0 &&
                      paymentMethod.map((pm, i) => (
                        <Fragment key={i}>
                          {pm.state === 'ACTIVE' && (
                            <Radio
                              value={i}
                              disabled={
                                (pm.paymentMethodType === 'Card' ||
                                  pm.paymentMethodType === 'CREDIT' ||
                                  pm.paymentMethodType === 'DEBIT') &&
                                !acceptCreditCard
                              }
                              data-testid={`radio${i}`}
                            >
                              {pm.label}
                            </Radio>
                          )}
                          {pm.state === 'SCHEDULED' && (
                            <>
                              <Radio value={i} disabled checked={false}>
                                {pm.label} Validating ...
                              </Radio>
                              {pm?.paymentGatewayMeta?.state === 'pending verification' &&
                                pm?.paymentMethodType === 'ACH' && (
                                  <VerifyPaymentMethod
                                    item={pm}
                                    displayAlert={displayPanelAlert}
                                    makePaymentPanel
                                    setLoading={setLoading}
                                  />
                                )}
                            </>
                          )}
                          {pm.state === 'PENDING' && (
                            <>
                              <Radio value={i} disabled>
                                {pm.label} Validating ...
                              </Radio>
                            </>
                          )}
                          <br />
                        </Fragment>
                      ))}
                    <br />
                    <Radio value="quadpay" key="q1" id="quadpay">
                      Pay with
                      <RentCredLogo className="radio-rentcred-logo" />
                      <span className="rentcred-max">($500 max.)</span>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {paymentLoader && (
                  <PaymentLoaderContainer>
                    <Spin />
                  </PaymentLoaderContainer>
                )}
              </PaymentMethodsContainer>

              {/* removed from ui 08/03/2024
                {paymentMethod.length < 5 && (
                <Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
                  <Link to="#" centered data-testid="addPaymentMethodTest">
                    <PlusCircleFilled
                      style={{
                        color: '#85BB65',
                        fontSize: '20px',
                        marginRight: '10px',
                      }}
                    />
                    Add a payment method
                  </Link>
                </Dropdown>
              )} */}

              <AntCard
                style={{
                  borderColor: '#C4C4C4',
                  borderRadius: '4px',
                  marginTop: '15px',
                  marginBottom: '15px',
                }}
                bordered
                styles={{
                  body: {
                    borderRadius: '4px',
                    textAlign: 'center',
                    backgroundColor: '#F7F7F0',
                    padding: '10px',
                    color: 'black',
                  },
                }}
                className="totalPaymentStep"
              >
                <Spin spinning={quadpayLoading}>
                  {additionalFees?.discount && (
                    <AlertFade
                      isVisible
                      message={`Paying early saves you $${additionalFees.discountAmount}`}
                      type="success"
                    />
                  )}
                  <Text size={16} color="black" centered strong data-testid="makePaymentTitleTest">
                    Total Payment Amount
                  </Text>
                  <Text size={11} color="grey4" centered>
                    Paid to: {userData?.privacyFullNameForRenter}
                  </Text>
                  <Text size={28} color="black" centered strong>
                    ${Number(totalAmount).toFixed(2)}
                  </Text>

                  {totalAmountValidation && (
                    <Text size={13} color="red" centered>
                      Amount can't be zero
                    </Text>
                  )}

                  <Text size={16} color="black" centered>
                    {+absorbFeesPercent === 100 &&
                    absorbFees &&
                    paymentMethod[form.getFieldValue('paymentMethod')]?.paymentMethodType === 'ACH'
                      ? 'Landlord is absorbing the fee'
                      : `Includes a $${Number(fee).toFixed(2)} ${
                          form.getFieldValue('paymentMethod') === 'quadpay' ? 'merchant ' : ''
                        }convenience fee`}
                  </Text>

                  {paymentMethod[form.getFieldValue('paymentMethod')]?.paymentMethodType === 'ACH' &&
                    firstPayment && (
                      <Text size={16} color="black" centered>
                        Your first payment is on us!
                      </Text>
                    )}
                  {(paymentMethod[form.getFieldValue('paymentMethod')]?.paymentMethodType === 'Card' ||
                    paymentMethod[form.getFieldValue('paymentMethod')]?.paymentMethodType === 'CREDIT' ||
                    paymentMethod[form.getFieldValue('paymentMethod')]?.paymentMethodType === 'DEBIT') && (
                    <AlertFade isVisible message={`Paying with a bank account costs $${achFee.toFixed(2)}`} />
                  )}
                </Spin>
              </AntCard>
            </ContentContainer>
            <div style={{ margin: '12px 24px' }}>
              <Text size={11} color="grey4" centered>
                By clicking Pay Now, you authorize PayRent Inc. to initiate a one-time debit in your name to the
                payment method selected. The Total Payment Amount above will be presented to your financial
                institution by the next business day. You further agree that once you click submit you may not
                revoke this authorization or cancel this payment.
              </Text>
            </div>
            <PanelFooter>
              <AlertFade
                isVisible={alert.visible}
                message={alert.message}
                description={alert.description}
                type={alert.type}
                clickFunction={alert.clickFunction}
                alertPosition="absolute"
                position="absolute"
                minHeight="100"
                width={80}
                bottom={120}
              />
              <Space vertical={10} />
              <Button
                alignment="center"
                outlined={paymentButton.outlined}
                color={paymentButton.color}
                size="large"
                // onClick={handlePayment}
                loading={loading}
                disabled={
                  disableButtons ||
                  rentCredStatus.renterTierId === 0 ||
                  (userData?.property?.paymentSettings?.requireFullPayment && +due <= 0)
                }
                data-testid="paymentButtonTest"
                htmlType="submit"
                className="payButtonStep"
              >
                {paymentButton.text}
              </Button>
              <Button
                onClick={() => {
                  props.setShowPayment(false);
                  props.setShowScheduledPayment(true);
                  //props.setCreateScheduledPayment(true);
                }}
                type="link"
                alignment="center"
                bottom={1}
                className="scheduleButtonStep"
                data-testid="scheduledTest"
              >
                Schedule payment
                <ScheduleOutlined
                  style={{
                    color: 'black',
                    marginLeft: '10px',
                    fontSize: '20px',
                  }}
                />
              </Button>
            </PanelFooter>
          </StyledForm>
        </FormContainer>
      </Container>
    </>
  );
};

const PaymentLoaderContainer = styled.div`
  margin-top: -25px;
  justify-content: center;
  display: flex;
`;

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const ContentContainer = styled.div`
  overflow: hidden !important;
  overflow-y: auto !important;
  overflow-x: hidden;
  flex-grow: 1;
  height: 50px;
  padding: 0px 25px 0px 25px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const FormContainer = styled.div`
  height: calc(100% - 65px);
`;

// width: 100vw;
const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PaymentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 0px;
`;

const PaymentMethodsContainer = styled.div`
  margin-bottom: 20px;
`;

export default MakePayment;
