import React from 'react';
import { Divider } from 'antd';
import MorePropertyActions from './MorePropertyActions';
import AddUnit from './AddUnit';
import EditProperty from './EditProperty';

const PropertyActions = (props) => {
  return (
    <div className="property-actions" style={{ display: 'inline-flex', alignItems: 'center' }}>
      <EditProperty {...props} />
      <Divider type="vertical" />
      <AddUnit {...props} />
      <Divider type="vertical" />
      <MorePropertyActions {...props} />
    </div>
  );
};

export default PropertyActions;
