import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'antd';
import { useFlagsmith } from 'flagsmith-react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { getServicePlanAction } from 'store/actions/sessionActions';
import { upgradeDowngradeServicePlan } from 'services/api/landlordApi/create';
import { displayAlert } from 'resources/helpers';

import Text from 'components/Text';
import SpaceResponsive from 'components/SpaceResponsive';
import Space from 'components/Space';
import Button from 'components/Button';
import AlertFade from 'components/AlertFade';

import DoItYourSelf from './components/DoItYourSelf';
import GoLikeAPro from './components/GoLikeAPro';
import { boardingElement, boardingText } from '../../animation/variants';

const Upgrade = (props) => {
  const {
    setSubscriptionPayment,
    setUpgradeServicePlan,
    upsellSp,
    setUserOldSp,
    setSilaAccountSetup,
    setFinishedSetup,
  } = props;
  const { isLoading, isError, hasFeature, getValue } = useFlagsmith();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    message: '',
    type: 'warning',
  });

  const session = useSelector((store) => store.session);

  const onFinish = async () => {
    try {
      const cognitoToken = session.cognitoTokens.idToken.jwtToken;
      const newSp = upsellSp.spId;
      const oldSp = +session.userData.id;
      setUserOldSp(oldSp);

      await upgradeDowngradeServicePlan(
        cognitoToken,
        {
          processorId: session.userData.servicePlan.processor.id,
          servicePlanId: newSp,
        },
        +session.userData.id
      );
      dispatch(getServicePlanAction(session.cognitoTokens.idToken.jwtToken));
      setLoading(false);
      const flowId = getValue('onboarding-flow');

      if (flowId === 1 || flowId === 2) {
        setUpgradeServicePlan(false);
        setSubscriptionPayment(true);
      }
    } catch (error) {
      setLoading(false);
      displayAlert(setAlert, 'There was an error with your request', 'warning', 3000);
    }
  };

  const handleNoThanks = () => {
    const flowId = getValue('onboarding-flow');

    if (flowId === 1) {
      if (+session.userData.servicePlan.id !== 8) {
        setUpgradeServicePlan(false);
        setSubscriptionPayment(true);
      } else {
        setUpgradeServicePlan(false);
        setSilaAccountSetup(true);
      }
    }
    if (flowId === 2) {
      if (+session.userData.servicePlan.id !== 8) {
        setUpgradeServicePlan(false);
        setSubscriptionPayment(true);
      } else {
        setUpgradeServicePlan(false);
        setFinishedSetup(true);
      }
    }
  };

  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong>
          We Recommend and Upgrade
        </Text>
      </motion.div>
      <SpaceResponsive lg={15} xmd={10} md={10} sm={10} />
      <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave">
        <StyledForm name="upgrade" onFinish={onFinish}>
          <ContentContainer className="content-container">
            <Text color="black" size={14} centered>
              Based on your profile, you should consider upgrading your service plan
            </Text>
            <Space vertical={10} />
            <Text color="black" strong size={18} centered>
              Switch and Save
            </Text>
            <Text color="black" strong size={18} centered>
              ${upsellSp.costDiff} Annually
            </Text>
            <Space vertical={10} />
            {upsellSp.spId === 2 ? <DoItYourSelf /> : <GoLikeAPro />}
          </ContentContainer>
          <StyledFooter>
            <AlertFade
              isVisible={alert.isVisible}
              message={alert.message}
              type={alert.type}
              minHeight="80"
              alertPosition="absolute"
              bottom={250}
            />
            <Text color="black" size={18} strong centered>
              Try Free for 30-Days
            </Text>
            <Button color="violet" bottom={0} type="primary" htmlType="submit" loading={loading}>
              UPGRADE
            </Button>
            <Text color="violet" size={12} underline centered onClick={handleNoThanks} pointer>
              No Thanks
            </Text>
          </StyledFooter>
        </StyledForm>
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  background-color: #ffffff;
  min-height: 70%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 20px 22px 20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
  @media screen and (max-width: 400px) {
    padding: 22px 10px 15px 10px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;import { motion, AnimatePresence } from 'framer-motion';

  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled(Form)`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const StyledFooter = styled.div`
  min-height: 110px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
  gap: 20px;
`;

export default Upgrade;
