import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputNumber } from 'antd';

export default function CurrencyInput(props) {
  // console.log('[CurrencyInput.js]', props);
  const formatter = (value) => {
    // console.log('formatter', typeof value, value);
    if (!value) return;
    const roundedNumber = value.match(/\.\d{3,}/) ? Number.parseFloat(value).toFixed(2) : value;

    // console.log(typeof roundedNumber, roundedNumber);
    return roundedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <StyledInputNumber
      prefix="$"
      formatter={formatter}
      parser={(value) => value.replace(/(,*)|/g, '')}
      {...props}
    />
  );
}

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  ${
    '' /* .ant-input-number-input {
    font-size: 16px !important;
  } */
  }
`;

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
};

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
};
