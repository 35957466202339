import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Redirect } from 'react-router-dom';
import { signOutCognito } from 'services/aws/cognito';
import * as Iot from 'services/aws/iot/aws-iot';
import { LOG_OUT } from 'store/actions';

const SignOut = (props) => {
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);

  useEffect(() => {
    const topic = `${process.env.REACT_APP_ENV}/${session.userData.id}`;
    Iot.unsubscribeFromTopics([topic]);
    Iot.disconnect();
    signOutCognito();
    localStorage.clear();
    sessionStorage.clear();
    if (window.fcWidget) {
      window.fcWidget.close();
      window.fcWidget.user.clear();
    }
    dispatch({
      type: LOG_OUT,
    });
  }, []);

  return <Redirect to="/sign-in" />;
};

export default SignOut;
