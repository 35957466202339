import axios from 'axios';
import Jsona from 'jsona';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import store from '../../../store';
import { refreshToken } from '../../aws/cognito';
dayjs.extend(utc);

const dataFormatter = new Jsona();

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const inviteRenter = async (values) => {
  try {
    const data = {
      data: {
        type: 'invites',
        attributes: {
          name: values.name,
          email: values.email,
          phone: values.mobilePhone,
          leaseStartDate: values.leaseStartDate.format('YYYY-MM-DD'),
          leaseEndDate: values.leaseEndDate.format('YYYY-MM-DD'),
          extendMonthToMonthFlag: values.extendMonth,
          requireInsurance: values.requireInsurance,
        },
        relationships: {
          property: {
            data: {
              type: 'properties',
              id: values.propertyId,
              method: 'update',
            },
          },
        },
      },
      included: [
        {
          type: 'properties',
          id: values.propertyId,
          attributes: {
            id: values.propertyId,
          },
        },
      ],
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/invites`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createTransaction = async (values) => {
  const renter = JSON.parse(values.renterId);
  try {
    const data = {
      data: {
        type: 'billing-items',
        attributes: {
          transactionType: values.transactionType,
          memo: values.memo,
          transactionDate: `${values.transactionDate.format('YYYY-MM-DD')}  08:00:00`,
          amount: values.transactionAmount,
          requestType: 'default',
        },
        relationships: {
          billingAccount: {
            data: {
              type: 'billing-accounts',
              id: renter.billingAccount,
              method: 'update',
            },
          },
          customer: {
            data: {
              type: 'customers',
              id: renter.id,
              method: 'update',
            },
          },
        },
        included: [
          {
            type: 'billing-accounts',
            id: renter.billingAccount,
          },
          {
            type: 'customers',
            id: renter.id,
          },
        ],
      },
    };
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/billing-items`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createPaymentGateway = async (body) => {
  try {
    const data = {
      data: {
        type: 'payment-gateways',
        attributes: body,
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        // Authorization: `Bearer ${oldApiToken}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/payment-gateways`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const submitAppForOnboarding = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/boarding/${id}/submit`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const nuveiWebhookTrigger = async (id) => {
  try {
    const data = {
      MessageType: 'Boarding.Merchant.G1P',
      UId: id,
      CreatedDate: '2016-04-20T22:35:08.3195501Z',
      Status: 'Completed',
      ResponseCode: '0',
      ResponsePhrase: 'Success',
      Message: {
        DbaName: 'Test DBA Name',
        MerchantId: '912280012617',
        GatewayMerchantId: '1064',
        GatewayTerminalNumbers: ['1064322'],
        SharedSecret: '123456789G1',
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        AuthenticationKey: process.env.REACT_APP_NV_API_KEY,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/boarding/notification`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createOnboarding = async (cognitoToken, values) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    let dateOfBirth = '';
    if (values.dateOfBirth) {
      if (typeof values.dateOfBirth === 'function') {
        dateOfBirth = values.dateOfBirth.format('YYYY-MM-DD');
      } else {
        dateOfBirth = values.dateOfBirth;
      }
    }

    const data = {
      data: {
        attributes: {
          processorId: values.processorId,
          compliance: true,
          signDate: dayjs.utc().format(),
          agent: process.env.REACT_APP_ENV === 'production' ? 'Payrent - FREE' : 'Payrent testing',
          office: process.env.REACT_APP_ENV === 'production' ? 'Payrent - PF' : 'Payrent testing',
          servicePlanId: values.servicePlanId,
          propertyGroupId: values.propertyGroupId,
          merchant: {
            legalName: values.legalBusinessName ? values.legalBusinessName : null,
            dbaName: values.dbaName ? values.dbaName : null,
            businessEin: values.businessEin ? values.businessEin : null,
            businessStartDate: values.businessStartDate ? values.businessStartDate.format() : null,
            address: {
              num: values.businessAddress ? values.bStreetNum : null,
              street: values.businessAddress ? values.bAddress : null,
              city: values.bCity ? values.bCity : null,
              state: values.bState ? values.bState : null,
              zip: values.bZipcode ? values.bZipcode : null,
            },
            website: values.website ? values.website : null,
            businessPhone: values.businessPhone ? values.businessPhone : null,
            businessEmail: values.businessEmail ? values.businessEmail : null,
            bankRouting: values.routingNumber ? values.routingNumber : null,
            bankAccount: values.bankAccountNumber ? values.bankAccountNumber : null,
            ownershipType: values.ownership ? values.ownership : null,
          },
          owner: {
            firstName: values.firstName ? values.firstName : null,
            lastName: values.lastName ? values.lastName : null,
            percentOwnership: values.owner ? values.owner : null,
            dateOfBirth: values.dateOfBirth ? dateOfBirth.format('YYYY-MM-DD') : '',
            address: {
              num: values.streetNumber ? values.streetNumber : null,
              street: values.streetName ? values.streetName : null,
              city: values.city ? values.city : null,
              state: values.state ? values.state : null,
              zip: values.zipCode ? values.zipCode : null,
            },
            phone: values.mobilePhone ? values.mobilePhone : null,
            ssn: values.ssn ? values.ssn : null,
            businessRole: values.businessRole ? values.businessRole : null,
          },
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/boarding`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    console.log(error);
    const { response } = error;
    throw response;
  }
};

export const rentReminder = async (id) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL}/payable-accounts/${id}/rent-reminder`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const resendInvite = async (id) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL}/invites/${id}/resend`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const resendInviteV3 = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/renter/${id}/resend`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createProperty = async (locationData, values, complianceLevel) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/properties`;
    const data = {
      data: {
        attributes: {
          address: !locationData.streetNumber
            ? locationData.address
            : `${locationData.streetNumber} ${locationData.address}`,
          address2: values.unit || '',
          city: locationData.city || '',
          googlePlaceId: locationData.google_place_id || '',
          lat: locationData.lat || 0.0,
          long: locationData.long || 0.0,
          rentAmount: Number(values.rentAmount),
          rentDueDay: values.rentDay,
          state: locationData.state || '',
          commercial: values.propertyCategory,
          zip: locationData.zip || '',
          bedrooms: +values.bedrooms || 0,
          bathrooms: +values.bathrooms || 0,
          propertyGroup: values.propertyGroup,
          parentPropertyId: values.parentPropertyId,
        },
        type: 'properties',
        relationships: {
          paymentSettings: {
            data: {
              id: 'temp-id99',
              type: 'payment-settings',
            },
          },
        },
      },
      included: [
        {
          attributes: {
            acceptCreditCard: values.acceptCreditCard,
            preventPayments: values.preventPayment,
            requireFullPayment: values.fullPayment,
            absorbFees: values.absorbFees,
            absorbFeesPercent: values.percent ? values.percent : 100,
          },
          id: 'temp-id99',
          type: 'payment-settings',
        },
      ],
    };

    if (complianceLevel) {
      data.included[0].attributes.complianceLevel = values.complianceLevel;
    }

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      url,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const resetPropertiesPreferences = async (cognitoToken, propertyGroupId = null) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/properties/reset`,
    };

    if (propertyGroupId) {
      options.url = `${process.env.REACT_APP_API_URL_V3}/properties/reset?property-group=${propertyGroupId}`;
    }

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const uploadDownloadDocuments = async (cognitoToken, billingAccountId, action, filename) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/billing-accounts/${billingAccountId}/document?action=${action}&filename=${filename}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const uploadDownloadDocsExtended = async (cognitoToken, id, action, filename, type) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/documents/file?action=${action}&filename=${filename}&type=${type}&id=${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const upgradeDowngradeServicePlan = async (cognitoToken, values, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'boarding',
        id,
        attributes: {
          ...values,
        },
      },
    };
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/service-plans/upgrade`,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createUpdateBillingDetails = async (cognitoToken, id, values, stripeId) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          billingDetails: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email || '',
            businessName: null,
            streetAddress: null,
            zipCode: null,
            city: null,
            state: null,
          },
          paymentMethod: {
            type: 'card',
            payment_method_id: stripeId,
          },
        },
      },
    };
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}/billing-details`,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateCreatePropertyFees = async (cognitoToken, values, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'property-fee',
        attributes: {
          securityDeposit: values.securityDepositSwitch,
          securityDepositAmount: values.securityDepositAmount || null,
          discount: values.daysEarlySwitch,
          discountDays: values.daysEarly || null,
          discountAmount: values.discountAmountEarly || null,
          lateFee: values.daysLateSwitch,
          lateFeeFrequency: 'ONE-TIME',
          lateFeeDays: values.daysLateFee || null,
          lateFeeAmount: values.lateFeeAmount || null,
          parkingFee: values.parkingFeeSwitch,
          parkingFeeFrequency: values.periodParkingFee || null,
          parkingFeeAmount: values.parkingFeeAmount || null,
          moveInFee: values.moveInFeeSwitch,
          moveInFeeAmount: values.moveInFee || null,
          moveOutFee: values.moveOutFeeSwitch,
          moveOutFeeAmount: values.moveOutFee || null,
        },
      },
    };
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/properties/${id}/fees`,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createRenter = async (cognitoToken, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          cellPhone: values.mobilePhone,
          billingAccount: {
            leaseStartDate: values.leaseStartDate.format('YYYY-MM-DD'),
            leaseEndDate: values.leaseEndDate.format('YYYY-MM-DD'),
            monthToMonthEndFlag: values.extendMonth,
            requireInsurance: values.requireInsurance,
          },
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          landlordId: +values.landlordId,
          propertyId: +values.propertyId,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/renter`,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createPropertyGroup = async (cognitoToken, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'property-groups',
        attributes: {
          name: values.name,
          settings: {
            ...values.settings,
            express_settlement: values?.expressSettlement || false,
          },
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/property-groups`,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createParentProperty = async (cognitoToken, propertyGroupId, locationData, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'parent-properties',
        attributes: {
          propertyGroupId,
          image: '',
          address: !locationData.streetNumber
            ? locationData.address
            : `${locationData.streetNumber} ${locationData.address}`,
          city: locationData.city || '',
          googlePlaceId: locationData.google_place_id || '',
          lat: locationData.lat,
          long: locationData.long,
          state: locationData.state || '',
          zip: locationData.zip || '',
          acquisitionDate: values.acquisitionDate ? values.acquisitionDate.format('YYYY-MM-DD') : null,
          acquisitionPrice: values.acquisitionPrice,
          currentValue: values.currentValue,
          annualDebtService: values.annualDebtService,
          annualTaxes: values.annualTaxes,
          annualInsurance: values.annualInsurance,
          annualMaintCosts: values.annualMaintCosts,
          annualMgmtFees: values.annualMgmtFees,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/parent-properties`,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateSettlementBank = async (cognitoToken, id, customerId, propertyGroupId) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: {
          paymentGateway: 'SilaStripe',
          paymentMethodType: 'ACH',
          paymentMethodId: id,
          customerId,
          propertyGroupId,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods`,
    };

    const res = await axios(options);

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createPaymentMethodV3 = async (cognitoToken, plaid, gateway, userType, propertyGroupId) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  const data = {
    data: {
      attributes: {
        paymentGateway: gateway,
        paymentMethodType: 'ACH',
        publicToken: plaid.token,
        mask: plaid.metadata.account.mask,
        accountId: plaid.metadata.account.id,
        authFlow: 'instant_auth',
      },
    },
  };

  if (userType === 'landlord') {
    data.data.attributes.propertyGroupId = propertyGroupId;
  }
  if (
    plaid.metadata.account.verification_status === 'pending_manual_verification' ||
    plaid.metadata.account.verification_status === 'pending_automatic_verification'
  ) {
    data.data.attributes.authFlow = 'microdeposit';
    data.data.attributes.meta = {
      subtype: plaid.metadata.account.subtype,
      type: plaid.metadata.account.type,
      accountId: plaid.metadata.account.id,
      name: plaid.metadata.account.name,
    };
  }

  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
    },
    data,
    url: `${process.env.REACT_APP_API_URL_V3}/payment-methods`,
  };

  try {
    const res = await axios(options);

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const createRefund = async (cognitoToken, id, landlordId, renterId) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'property-groups',
        attributes: {
          landlordId,
          renterId,
          reason: 'Landlord initiated refund',
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/payments/${id}/refund`,
      data,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const uploadSilaDoc = async (cognitoToken, fileList, propertyGroupId, silaHandle) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      const detailsObj = {
        documentType: fileList[i].documentType,
        name: fileList[i].info.file.name,
        description: 'kyc required document',
      };

      if (propertyGroupId) detailsObj.propertyGroupId = propertyGroupId;
      formData.append(`file${i + 1}`, fileList[i].info.file);
      formData.append(`details${i + 1}`, JSON.stringify(detailsObj));
    }
    formData.append('silaHandle', silaHandle);

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data: formData,
      url: `${process.env.REACT_APP_API_URL_V3}/documents`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const stripeOptIn = async (cognitoToken, propertyGroupId, optIn) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/stripe-connect/${propertyGroupId}/opt-in/${optIn}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createFinixMerchantIdentity = async (cognitoToken, data) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/identities`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const res = await axios(options);
    // console.log('[api.js] ƒ createFinixMerchantIdentity result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const createFinixMerchantAssociate = async (cognitoToken, identityId, data) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/identities/${identityId}/associates`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const res = await axios(options);
    // console.log('[api.js] ƒ createFinixMerchantIdentity result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const createFinixMerchant = async (cognitoToken, identityId, data) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants?identity_id=${identityId}`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    const res = await axios(options);
    // console.log('[api.js] ƒ createFinixMerchant result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error('[api.js] ƒ createFinixMerchant', err);
    throw err.response || err;
  }
};

export const createFinixComplianceFormLink = async (id, data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL_V3}/finix/merchants/compliance-forms/files/${id}/external-links`;

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
      url,
      data,
    };
    // console.log('[api.js] ƒ createFinixComplianceFormLink options', options);
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const createOrUpdateDocumentReference = async (cognitoToken, docData) => {
  // console.log('[create.js] ƒ createOrUpdateDocumentReference docData', docData);
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'documents',
        attributes: docData,
      },
    };
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/users/documents`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const cloneDocument = async (cognitoToken, docData, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'documents',
        attributes: docData,
      },
    };

    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/users/documents/${id}/clone`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const sendEsignDocument = async (cognitoToken, docData) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: docData,
      },
    };
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/esign/send`,
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vdn.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const createSenderIdentity = async (cognitoToken, senderData, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        attributes: senderData,
      },
    };

    const options = {
      signal: controller?.signal,
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/esign/senders`,
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const resendEsignVerification = async (cognitoToken, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      signal: controller?.signal,
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/esign/senders/invite`,
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios(options);
    return res;
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const createFinixFileResource = async (data) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/onboarding/files`,
      data,
    };
    // console.log('[api.js] ƒ createFinixFileResource options', options);
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const verifyFinixMerchant = async (id) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/onboarding/verify/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};
