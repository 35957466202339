import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Card as AntCard, Skeleton } from 'antd';
import { getBillingAccount } from 'services/api/common/read';
import { documentsPresignedUrl } from 'services/api/common/create';
import { DownloadOutlined } from '@ant-design/icons';
import AlertFade from 'components/AlertFade';
import SharedDocuments from './SharedDocuments';
import IconButton from 'components/uielements/IconButton/IconButton';
import Text from 'components/Text/Text';
import Space from 'components/Space/Space';

const Documents = (props) => {
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens?.idToken?.jwtToken;
  const [leaseDocument, setLeaseDocument] = useState(false);
  const [leaseDocUrl, setLeaseDocUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'error', visible: false });

  useEffect(() => {
    checkLeaseDocument();
  }, []);

  const checkLeaseDocument = async () => {
    try {
      setLoading(true);
      const activeBa = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      if (activeBa.length) {
        const baRes = await getBillingAccount(cognitoToken, activeBa[0].id);

        if (baRes.leaseDocument) {
          const res = await documentsPresignedUrl(cognitoToken, activeBa[0].id, 'download', '', 'lease');
          setLeaseDocUrl(res.presignedUrl);
          setLeaseDocument(true);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleNoFile = () => {
    if (!leaseDocument) {
      setAlert({
        visible: true,
        message: 'No lease document available',
        type: 'error',
      });
      setTimeout(() => {
        setAlert({
          visible: false,
          message: 'No lease document available',
          type: 'error',
        });
      }, 5000);
    }
  };

  const handleDownloadLease = () => {
    if (leaseDocument) {
      window.open(leaseDocUrl, '_blank');
    } else {
      handleNoFile();
    }
  };

  return (
    <AntCard
      title="Documents"
      styles={{
        header: {
          fontSize: '16px',
          color: '#541388',
          fontWeight: 600,
        },
      }}
      data-testid="cardTest"
    >
      <AlertFade isVisible={alert.visible} message={alert.message} type="error" />
      <Text color="black" size={16} strong>
        My Documents
      </Text>
      <Space vertical={12} />
      <Skeleton loading={loading} active paragraph={{ rows: 0 }}>
        <ItemContainer>
          Lease Agreement
          <IconButton
            toolTitle="Download Lease"
            disabled={!leaseDocument}
            icon={<DownloadOutlined />}
            handleClick={handleDownloadLease}
            data-testid="leaseDownloadTest"
          />
        </ItemContainer>
      </Skeleton>
      <SharedDocuments />
    </AntCard>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 12px;
`;

export default Documents;
