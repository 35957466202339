import React from 'react';

import { ReactComponent as RentPrepLogo } from 'assets/images/applications/rentprep.svg';

const RentPrepTitle = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        maxHeight: '42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <RentPrepLogo />
    </div>
  );
};

export default RentPrepTitle;
