// @flow

// node_modules
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs, Form, Spin, Segmented } from 'antd';
import { addCardMethod } from 'services/wepay/paymentMethod';
import * as tenantApi from 'services/api/tenantApi';
import { getPaymentMethods } from 'store/actions/tenantActions';
import * as actions from 'store/actions';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getUserDataV3 } from 'store/actions/sessionActions';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { BankFilled, CreditCardFilled } from '@ant-design/icons';
import Text from 'components/Text/Text';
// Local components
import CreditCardDetail from './components/CreditCardDetail';
import NuveiCreditCardDetail from './components/NuveiCreditCardDetail';
import BankAccountDetail from './components/BankAccountDetail';
import NuveiBankAccountDetail from './components/NuveiBankAccountDetail';
import StripeCreditCard from './components/StripeCreditCard';
import FinixPaymentMethodForm from 'screens/Landlord/components/FinixPaymentMethodForm/FinixPaymentMethodForm';
import AlertFade from 'components/AlertFade';
import Space from 'components/Space';

//import './style.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentMethodDetail = (props) => {
  // console.log('[PaymentMethodDetail.js] props', props);
  const { handleAddBankAccount, newPaymentMethodType } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const session = useSelector((store) => store.session);
  const userData = useSelector((state) => state.session.userData);
  const userEmail = useSelector((state) => state.session.userData.email);
  const paymentMethods = useSelector((state) => state.tenant.paymentMethods);
  const editPaymentMethod = paymentMethods.filter((p) => p.id === props.paymentMethodId);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [activeTab, setActiveTab] = useState('1');

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState({
    clientSecret: null,
    appearance: {
      theme: 'none',
      rules: {
        '.Input::placeholder': {
          color: '#595959',
        },
        '.Input': {
          border: '1px solid #D9D9D9',
          color: '#595959',
          fontFamily: `'Montserrat', 'Poppins', sans-serif`,
          fontSize: '14px',
          padding: '8px',
        },
        '.Input:focus-visible': {
          outline: '0',
        },
        '.Input:focus': {
          borderColor: '#FFCC00',
          borderRightWidth: '1px !important',
        },
        '.Tab:hover': {
          color: '#595959',
        },
        '.Tab--selected': {
          borderColor: '#D9D9D9',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px yellow',
        },
        '.Input--invalid': {
          boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 1px var(--colorDanger)',
          marginBottom: '7px',
        },
      },
    },
  });

  /*
  const onSuccess = useCallback((token, metadata) => {
    const plaid = {
      token,
      metadata,
    };
    handleAddBankAccount(plaid);
  }, []);

  const onExit = () => {
    displayAlert('Payment Method Error', 'warning', 'Something went wrong while adding your payment method!');
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
    onExit,
  };

  const { open, ready, error } = usePlaidLink(config);
  */

  // useEffect(() => {
  //   console.log('ƒ useEffect on paymentMethodId', props.paymentMethodId);
  //   const controller = new AbortController();
  //   dispatch(getUserDataV3(session.cognitoTokens.idToken.jwtToken, controller));
  //   if (userData.paymentGateway === 'SilaStripe') {
  //     fetchData();
  //   }
  //   return () => {
  //     controller.abort();
  //   };
  // }, []);

  useEffect(() => {
    // console.log('ƒ useEffect on newPaymentMethodType', newPaymentMethodType);
    if (newPaymentMethodType) {
      if (newPaymentMethodType === 'bank') {
        setActiveTab('1');
      } else {
        setActiveTab('2');
      }
    }
  }, [newPaymentMethodType]);

  // const fetchData = async () => {
  //   try {
  //     const result = await tenantApi.getStripeIntent(cognitoToken);
  //     console.log(result);
  //     setOptions({ ...result, clientSecret: result.client_secret });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const displayAlert = (message, type, description) => {
    dispatch({
      type: 'SET_ALERT',
      payload: {
        isVisible: true,
        message,
        type,
        description,
        closable: true,
      },
    });

    setTimeout(() => {
      dispatch({
        type: 'UNSET_ALERT',
      });
    }, 5000);
  };

  const onFinish = async (values, gateway) => {
    let paymentMethod = gateway === 'nuvei' ? values : '';
    if (gateway !== 'nuvei') {
      try {
        setLoading(true);
        paymentMethod = await addCardMethod(userEmail, values);
      } catch (err) {
        setLoading(false);
        const description = err || 'Something went wrong while adding your payment method!';

        displayAlert('Payment Method', 'warning', description);
        props.setDrawerVisible(false);
        return;
      }
    }

    // TODO: revisar logica porque si falla puede traer problemas al ingresar el primer metodo
    let paymentMethodRes = '';
    try {
      paymentMethodRes = await tenantApi.createPaymentMethod(paymentMethod);
    } catch (e) {
      setLoading(false);
      const description = e?.data?.errors[0].detail || 'Something went wrong while adding your payment method!';
      displayAlert('Payment Method', 'warning', description);
      props.setDrawerVisible(false);
      return;
    }

    try {
      if (gateway === 'nuvei') {
        await tenantApi.activateNuveiCreditCard(session.cognitoTokens.idToken.jwtToken, paymentMethodRes.id);
      }

      if (props.defPaymentMethod) {
        await tenantApi.setDefaultPaymentMethodV3(paymentMethodRes.id, session.cognitoTokens.idToken.jwtToken);
      }
    } catch (err) {
      await tenantApi.deletePaymentMethod(paymentMethodRes.id);
      setLoading(false);
      const description = err?.data?.errors[0].detail || 'Something went wrong while adding your payment method!';
      displayAlert('Payment Method', 'warning', description);
      props.setDrawerVisible(false);
      return;
    }
    setLoading(false);
    displayAlert('Payment Method', 'success', 'Your payment method has been submitted');
    console.log('about to get payment methods with auth');
    // dispatch(getPaymentMethods(userData.paymentGateway, null, session.cognitoTokens.idToken.jwtToken));
    if (gateway !== 'nuvei') props.checkPaymentMethodStatus();
    if (props.payClicked) {
      props.setShowPaymentMethodDetail(false);
      props.setShowPayment(true);
    } else {
      if (props.setShowPaymentMethodDetail) props.setShowPaymentMethodDetail(false);
      props.setDrawerVisible(false);
    }
  };

  const onFinishFinix = () => {
    setLoading(false);
    setShowError(false);
    displayAlert('Payment Method', 'success', 'Your payment method has been submitted');
    // dispatch(getPaymentMethods(userData.paymentGateway, null, session.cognitoTokens.idToken.jwtToken));
    props.checkPaymentMethodStatus();
    if (props.payClicked) {
      props.setShowPaymentMethodDetail(false);
      props.setShowPayment(true);
    } else {
      if (props.setShowPaymentMethodDetail) props.setShowPaymentMethodDetail(false);
      props.setDrawerVisible(false);
    }
  };

  const checkZipCode = (rule, value) => {
    const isValidPostalZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if (isValidPostalZipCode) {
      return Promise.resolve();
    }
    return Promise.reject('Please input valid zip code.');
  };

  const onError = (error) => {
    if (error) {
      setErrorMessage(error);
    }
    setShowError(true);
  };

  const checkLuhn = (cardNo) => {
    let s = 0;
    let doubleDigit = false;
    for (let i = cardNo.length - 1; i >= 0; i--) {
      let digit = +cardNo[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    return s % 10 === 0;
  };

  const validateCardNo = (rule, value) => {
    const no = Number(value);

    if (
      (no &&
        checkLuhn(no) &&
        value.length === 16 &&
        (value[0] === '4' ||
          (value[0] === '5' && value[1] >= 1 && value[1] <= 5) || // mastercard
          (value.indexOf('222') === 0 && value[3] >= 1 && value[3] <= 9) || // mastercard
          (value.indexOf('22') === 0 && value[2] >= 3 && value[2] <= 9) || // mastercard
          (value.indexOf('2') === 0 && value[1] >= 3 && value[1] <= 6) || // mastercard
          (value.indexOf('27') === 0 && value.indexOf('01') === 2) || // mastercard
          value.indexOf('2720') === 0 || // mastercard
          value.indexOf('6011') === 0 ||
          value.indexOf('65') === 0 ||
          value.indexOf('35') === 0)) ||
      (value.length === 15 &&
        (value.indexOf('34') === 0 ||
          value.indexOf('37') === 0 ||
          value.indexOf('5') === 0 ||
          value.indexOf('2131') === 0 || // JCB
          value.indexOf('1800') === 0)) || // JCB
      (value.length === 14 &&
        (value.indexOf('36') === 0 ||
          value.indexOf('38') === 0 ||
          (value[0] === '3' && value[1] === '0' && value[2] >= 0 && value[1] <= 5))) ||
      (value.length === 13 && value[0] === 4)
    ) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid credit card number.');
  };

  const handleChangeTab = (key) => {
    setShowError(false);
    if (Number(key) === 1) {
      if (!props.paymentMethodId && userData.paymentGateway === 'Wepay') {
        handleAddBankAccount(null);
        if (props.setDrawerVisible) props.setDrawerVisible(false);
      } else if (
        (!props.paymentMethodId && userData.paymentGateway === 'Nuvei') ||
        (!props.paymentMethodId && userData.paymentGateway === 'SilaStripe') ||
        (!props.paymentMethodId && userData.paymentGateway === 'Finix')
      ) {
        props.plaidOpen();
        if (props.setDrawerVisible) props.setDrawerVisible(false);
      } else {
        setActiveTab('1');
      }
    }
    if (Number(key) === 2) {
      setActiveTab('2');
    }
  };

  return (
    <Container>
      <Header data-testid="headerTest">
        <ArrowLeftOutlined
          onClick={() => {
            if (props.payClicked) {
              props.setShowPaymentMethodDetail(false);
              props.setShowPayment(true);
            } else {
              if (form.isFieldsTouched() && !editPaymentMethod.length) {
                if (window.confirm('Are you sure you want to leave?.')) {
                  if (props.setShowPaymentMethodDetail) props.setShowPaymentMethodDetail(false);
                  props.setDrawerVisible(false);
                }
              } else {
                if (props.setShowPaymentMethodDetail) props.setShowPaymentMethodDetail(false);
                props.setDrawerVisible(false);
              }
            }
          }}
          style={{
            fontSize: '22px',
            color: 'white',
            marginRight: '10px',
            display: 'flex',
          }}
          data-testid="arrowImgTest"
        />
        Payment Method Details
      </Header>

      <FormContainer>
        <TabsContainer data-name="tabs-container" data-testid="tabContainterTest" className="payment-method-tab">
          <div style={{ textAlign: 'center' }}>
            <Segmented
              value={activeTab}
              size="large"
              options={[
                {
                  label: <span data-testid="bankAccountLabel">Bank Account</span>,
                  value: '1',
                  icon: <BankFilled />,
                },
                {
                  label: <span data-testid="creditCardLabel">Credit Card</span>,
                  value: '2',
                  icon: <CreditCardFilled />,
                  disabled: !session.userData?.property?.paymentSettings?.acceptCreditCard,
                },
              ]}
              onChange={(value) => handleChangeTab(value)}
            />
          </div>
          {activeTab === '1' && (
            <div className="bank-account-wrapper" style={{ textAlign: 'center' }}>
              <Space vertical={10} />
              <AlertFade
                message={errorMessage || 'Error: Something went wrong'}
                display
                isVisible={showError}
                type="error"
              />
              {!showError && userData.paymentGateway === 'Finix' && (
                <div data-testid="bankFees">
                  <Text color="black" size={14} weight={600}>
                    Fees: {session.userData.servicePlan.achRateFee}% + ${session.userData.servicePlan.achTrxFee}
                  </Text>
                  <div>
                    <Text color="black" size={14} weight={600}>
                      Your landlord is absorbing {session.userData.property.paymentSettings.absorbFeesPercent}% of
                      this fee
                    </Text>
                  </div>
                </div>
              )}

              {userData.paymentGateway === 'Wepay' && (
                <BankAccountDetail onFinish={() => {}} editPaymentMethod={editPaymentMethod} />
              )}
              {userData.paymentGateway === 'Nuvei' && (
                <NuveiBankAccountDetail editPaymentMethod={editPaymentMethod} />
              )}
              {userData.paymentGateway === 'Finix' && (
                <BankFormContainer className="content-container">
                  <FinixPaymentMethodForm next={onFinishFinix} replace={false} onError={onError} type={'bank'} />
                </BankFormContainer>
              )}
              <div className="mandate-wrapper" style={{ padding: '25px' }}>
                <Text size={11} color="grey4">
                  By clicking Save, you authorize your landlord and their affiliates to debit the specified bank
                  account for any charges related to your use of their services, until you revoke this
                  authorization.
                </Text>
              </div>
            </div>
          )}
          {activeTab === '2' && (
            <div style={{ textAlign: 'center' }}>
              <Space vertical={10} />
              <AlertFade
                message={errorMessage || 'Error: Something went wrong'}
                display
                isVisible={showError}
                type="error"
              />
              {!showError && userData.paymentGateway === 'Finix' && (
                <div data-testid="cardFees">
                  <Text color="black" size={14} weight={600}>
                    Credit Card Fees: {(session.userData.servicePlan.creditCardRateFee * 100).toFixed(2) || 0}% + $
                    {session.userData.servicePlan.creditCardTrxFee.toFixed(2) || 0}
                  </Text>
                  <div>
                    <Text color="black" size={14} weight={600}>
                      Debit Card Fees: {(session.userData.servicePlan.debitRateFee * 100).toFixed(2) || 0}% + $
                      {session.userData.servicePlan.debitTrxFee?.toFixed(2) || 0}
                    </Text>
                  </div>
                </div>
              )}

              {userData.paymentGateway === 'SilaStripe' && (
                <Spin spinning={userData.paymentGateway === 'SilaStripe' && !options.clientSecret}>
                  {userData.paymentGateway === 'SilaStripe' && !!options.clientSecret && (
                    <Elements stripe={stripePromise} options={options}>
                      <StripeCreditCard setDrawerVisible={props.setDrawerVisible} options={options} />
                    </Elements>
                  )}
                </Spin>
              )}

              {userData.paymentGateway === 'Wepay' && (
                <CreditCardDetail
                  form={form}
                  onFinish={onFinish}
                  loading={loading}
                  checkZipCode={checkZipCode}
                  validateCardNo={validateCardNo}
                  data-testid="creditCardComponentTest"
                  editPaymentMethod={editPaymentMethod}
                />
              )}
              {userData.paymentGateway === 'Nuvei' && (
                <NuveiCreditCardDetail
                  form={form}
                  checkZipCode={checkZipCode}
                  onFinish={onFinish}
                  loading={loading}
                  setLoading={setLoading}
                  editPaymentMethod={editPaymentMethod}
                />
              )}
              {userData.paymentGateway === 'Finix' && (
                <BankFormContainer className="content-container">
                  <FinixPaymentMethodForm next={onFinishFinix} replace={false} type={'card'} />
                </BankFormContainer>
              )}
            </div>
          )}
        </TabsContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const FormContainer = styled.div`
  height: calc(100% - 112px);
  padding-top: 25px;
  display: flex;
`;

//  width: 100vw;
const Header = styled.div`
  background-color: #541388;
  color: white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-weight: 600;
`;

const BankFormContainer = styled.div`
  padding: 25px;
`;

const TabsContainer = styled.div`
  height: 100%;
  width: 100%;
  .ant-tabs {
    height: 100%;
  }

  div.ant-tabs-tab {
    margin-left: -1px !important;
  }

  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    content: none !important;
    border-bottom: 0 !important;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid #bfbfbf;
    border-radius: 5px 5px 5px 5px;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: white;
  }

  .ant-tabs-nav {
    height: 32px;
    border-bottom: none !important;
    .ant-tabs-nav-wrap {
      align-self: unset;
    }
  }

  .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  .ant-tabs-tab-btn {
    color: grey !important;
    font-size: 14px;
  }

  .ant-tabs-tab-active {
    background-color: #cb47b8 !important;
    .ant-tabs-tab-btn {
      color: white !important;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

export default PaymentMethodDetail;
