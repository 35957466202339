import React from 'react';
import styled from 'styled-components';

import { motion, AnimatePresence } from 'framer-motion';

import DocsReceived from 'components/DocsReceived';
import Text from 'components/Text';
import Button from 'components/Button';
import SpaceResponsive from 'components/SpaceResponsive';
import { boardingElement, boardingText } from '../../animation/variants';

const DocsReview = (props) => {
  const { setShowDocsReview, setShowOnboardingFullscreen } = props;
  return (
    <>
      <motion.div variants={boardingText} initial="hidden" animate="enter" exit="leave">
        <Text color="black" size={24} sizeXs={20} strong>
          Upload Your Documents
        </Text>
      </motion.div>
      <SpaceResponsive lg={15} xmd={10} md={10} sm={10} />
      <Container variants={boardingElement} initial="hidden" animate="enter" exit="leave">
        <>
          <ContentContainer className="content-container">
            <DocsReceived />
          </ContentContainer>

          <StyledFooter>
            <Button
              color="violet"
              bottom={0}
              onClick={() => {
                setShowDocsReview(false);
                setShowOnboardingFullscreen(false);
              }}
            >
              CLOSE
            </Button>
          </StyledFooter>
        </>
      </Container>
    </>
  );
};

const Container = styled(motion.div)`
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  min-height: 76%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 22px 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 488px) {
    width: 95%;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden !important;
  overflow-y: scroll !important;
  overflow-x: hidden;import { AnimatePresence } from 'framer-motion';

  padding: 0px 0px;
  margin: 0 auto;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledFooter = styled.div`
  min-height: 80px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  width: 100%;
  gap: 20px;
`;

export default DocsReview;
