import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Drawer, Empty } from 'antd';
import { getNewsFeed } from 'services/api/landlordApi/read';
import { CloseSquareFilled } from '@ant-design/icons';
import styled from 'styled-components';
import NewsFeed from '../NewsFeed';

const bodyStyle = {
  backgroundColor: '#f7f7f4',
  overflowY: 'auto',
  padding: '10px',
};
const headerStyle = {
  backgroundColor: '#541388',
  // height: '80px',
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #cb47b8;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
  }
  .ant-drawer-title {
    color: #fff;
    font-size: 22px;
  }
  .anticon {
    color: #fff;
  }
  @media screen and (max-width: 470px) {
    .ant-drawer-content-wrapper {
      width: 380px !important;
    }
  }
`;

const NewsPanel = (props) => {
  const { title, placement, closeable, onClose, visible, isOpen } = props;
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const res = await getNewsFeed(source);
      let postsStatus2 = [];

      if (res?.message) {
        postsStatus2 = res.message.filter((p) => p.status === 2);
      }
      setPosts(postsStatus2);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledDrawer
      className="news-panel"
      title={title}
      placement={placement}
      closable={closeable}
      closeIcon={<CloseSquareFilled data-testid="closeNewsTest" />}
      onClose={onClose}
      open={visible}
      styles={{ body: bodyStyle, header: headerStyle }}
      width="482px"
      destroyOnClose
    >
      {posts.length > 0 ? (
        <NewsFeed posts={posts} isOpen={isOpen} />
      ) : (
        <Empty
          description={
            <span>
              There's no news... <br />
              But that's usually good news, right?
            </span>
          }
        />
      )}
    </StyledDrawer>
  );
};
export default NewsPanel;
