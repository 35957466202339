import React from 'react';

import Text from 'components/Text';
import SpaceResponsive from 'components/SpaceResponsive';
import StatusMessage from './components/StatusMessage';

const OnboardingStatus = (props) => {
  const { paymentGateway, servicePlan } = props;
  // console.log('[OnboardingStatus.js]', props);

  const getStatus = (processor, boardingStatus) => {
    if (processor === 'Wepay') {
      return boardingStatus.wepayState;
    }
    if (processor === 'SilaStripe') {
      if (boardingStatus.ach.state === 'REGISTERED' || boardingStatus.ach.state === 'PASSED') {
        return 'ACTIVE';
      }
      if (boardingStatus.ach.state === 'REGISTER_ERROR' || boardingStatus.ach.state === 'FAILED') {
        return 'FAILED';
      }
    }
    if (processor === 'Finix') {
      return boardingStatus.finixOnboardingState;
    }
  };

  return (
    <>
      <StatusMessage status={getStatus(paymentGateway, props.boardingStatus)} />
      <SpaceResponsive lg={10} md={10} sm={10} />
      <Text size={24} sizeXs={20} centered color="black">
        Service Plan
      </Text>
      <SpaceResponsive lg={10} md={10} sm={10} />

      <Text size={16} sizeXs={12} color="black" strong centered>
        {servicePlan.name}
      </Text>
      <Text size={16} sizeXs={12} color="black" centered>
        ${servicePlan.subscription_fee || servicePlan.subscriptionFee || '0.00'} per month
      </Text>
      <Text size={16} sizeXs={12} color="black" centered>
        {(+servicePlan.ach_rate_fee * 100 || +servicePlan.achRateFee * 100 || 0).toFixed(2)}% +{' $'}
        {(servicePlan.ach_trx_fee || servicePlan.achTrxFee || 0).toFixed(2)} ACH
      </Text>
      <Text size={16} sizeXs={12} color="black" centered>
        {(servicePlan.credit_card_rate_fee * 100 || servicePlan.creditCardRateFee * 100 || 0).toFixed(2)}
        %+$
        {(servicePlan.credit_card_trx_fee || servicePlan.creditCardTrxFee || 0).toFixed(2)} Credit Card
      </Text>
    </>
  );
};

export default OnboardingStatus;
