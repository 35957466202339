// AWS Sdk
import * as AWS from 'aws-sdk/global';
import { pickBy, identity } from 'lodash';

import 'cross-fetch/polyfill';
// Aws Cognito Identity
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';

// Aws Config
AWS.config.region = process.env.REACT_APP_REGION;

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'us-east-1_4565412',
  ClientId: process.env.REACT_APP_APP_CLIENT_ID || 'test',
};

/**
 *
 * @param {object } userData
 * @param callback
 * @param errorCallback
 */
export const registerCognitoUser = (userData, callback, errorCallback) => {
  const userPool = new CognitoUserPool(poolData);
  const {
    email,
    phoneNumber,
    firstName,
    lastName,
    isLandLord,
    businessName = '',
    servicePlan = '',
    apid = '',
    ipQualityScore,
    birthdate = '',
  } = userData;

  const atributes = [
    { Name: 'email', Value: email },
    { Name: 'phone_number', Value: `+${phoneNumber}` },
    { Name: 'given_name', Value: firstName },
    { Name: 'family_name', Value: lastName },
    { Name: 'custom:landlord', Value: String(!!isLandLord) },
    { Name: 'custom:business_name', Value: businessName },
    { Name: 'custom:servicePlan', Value: servicePlan },
    { Name: 'custom:apid', Value: apid },
    { Name: 'custom:IqsRequestId', Value: ipQualityScore },
    { Name: 'custom:birthdate', Value: birthdate },
  ];

  const attributeList = [];
  atributes.forEach((attribute) => attributeList.push(attribute));

  userPool.signUp(userData.email, userData.password, attributeList, null, (err, result) => {
    if (err) {
      console.log('Error - Sign Up: ', err);
      errorCallback(err.message || JSON.stringify(err));
      return;
    }

    callback();
    // const cognitoUser = result.user;
    // console.log('user name is ' + cognitoUser.getUsername());
  });
};

/**
 *
 * @param username
 * @param password
 * @returns {Promise<unknown>}
 */
export const authenticate = (username = '', password = '') => {
  // User Name could be a mail
  const authenticationData = {
    Username: username,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: authenticationData.Username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        AWS.config.region = process.env.REACT_APP_REGION;

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, // your identity pool id here
          Logins: {
            // Change the key below according to the specific region your user pool is in.
            [`cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]:
              result.getIdToken().getJwtToken(),
          },
        });
        AWS.config.credentials.clearCachedId();
        //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        AWS.config.credentials.refresh(async (error) => {
          if (error) {
            console.error(error);
          } else {
            // Instantiate aws sdk service objects now that the credentials have been updated.
            // example: var s3 = new AWS.S3();
            console.log('Successfully logged!');
            const principal = AWS.config.credentials.identityId;

            // Now we have cognito identity and credentials to make AWS IoT calls.
            // Attach pre-created IoT policy to this principal.
            // IMPORTANT: Make sure you have granted AttachPrincipalPolicy API permission in IAM to Cognito Identity Pool's Role.
            // It is done here for the demo purpose only while cognito user should NOT be allowed to call AttachPrincipalPolicy in production, this step must be done by Administrator only
            // attachPrincipalPolicy('demo-policy', principal);

            // Now we can use this principal for IoT actions
            // We'll need aws-iot-device-sdk-js for mqtt over websocket calls using these cognito credentials.

            result.identityCredentials = AWS.config.credentials.data.Credentials;
            result.identityCredentials.identityId = principal;

            resolve(result);
          }
        });
      },

      onFailure(err) {
        reject(err);
      },
    });
  });
};

/**
 *
 * @param cognitoTokens
 * @returns {Promise<unknown>}
 */

/**
 *
 * @param tokens - Could be one or more tokens from cognito or social logins like Google or Fb
 * @param {string} providerName
 * @returns {Promise<unknown>}
 */
export const getFederatedCredentials = (tokens, providerName) => {
  let dataLogin = {};

  switch (providerName) {
    case 'cognito':
      // const idToken = result.idToken.jwtToken;
      dataLogin = {
        // Format: 'cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>'
        [`cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: tokens
          .getIdToken()
          .getJwtToken(),
      };
      break;
    case 'google':
      dataLogin = {
        'accounts.google.com': tokens,
      };
      break;
    case 'facebook':
      dataLogin = {
        'graph.facebook.com': tokens,
      };
      break;
    default:
      break;
  }

  const params = {
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, // your identity pool id here
    Logins: dataLogin, // <IdentityProviderName>
  };

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

  return new Promise((resolve, reject) => {
    // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
    AWS.config.credentials.refresh((error) => {
      if (error) {
        console.error(error);
        reject('fail');
      } else {
        // Instantiate aws sdk service objects now that the credentials have been updated.
        // example: var s3 = new AWS.S3();
        resolve('Successfully logged!');
      }
    });
  });
};

export const getCognitoUser = () => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser != null) {
      // NOTE: getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(null);
          // alert(err.message || JSON.stringify(err));
          return;
        }
        resolve({
          cognitoUser,
          session,
        });
      });
    } else {
      reject(null);
    }
  });
};

/**
 *
 * @param user
 */
export const getUserAttributes = (user = null) => {
  if (user != null) {
    user.getUserAttributes((err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      for (let i = 0; i < result.length; i++) {
        console.log(`attribute ${result[i].getName()} has value ${result[i].getValue()}`);
      }
    });
  }
};

export const updateUserAttributes = (formValues) => {
  return new Promise((resolve, reject) => {
    const filledValues = pickBy(formValues, identity);
    let attributeList = [];

    Object.keys(filledValues).forEach((key) => {
      attributeList.push(
        new CognitoUserAttribute({
          Name: key,
          Value: key === 'phone_number' ? `+${filledValues[key]}` : filledValues[key],
        })
      );
    });

    getCognitoUser()
      .then((res) => {
        res.cognitoUser.updateAttributes(attributeList, function (err, result) {
          if (err) {
            reject(err.message || JSON.stringify(err));
            return;
          }
          resolve(result);
        });
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const changePassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    getCognitoUser()
      .then((res) => {
        res.cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
          if (err) {
            reject(err.message || JSON.stringify(err));
            return;
          }
          resolve(result);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const refreshToken = () => {
  // AWS.config.credentials.needsRefresh()
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          // alert(err.message || JSON.stringify(err));
          console.log(JSON.stringify(err));
          reject(false);
        }
        // console.log('session validity: ' + session.isValid());

        const refresh_token = session.getRefreshToken();
        // console.log('CREDENTIALS ', AWS.config.credentials);
        if (!AWS.config.credentials) {
          const params = {
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, // your identity pool id here
            Logins: {
              [`cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: session
                .getIdToken()
                .getJwtToken(),
            }, // <IdentityProviderName>
          };
          AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
        }

        //console.log('Needs refresh ', AWS.config.credentials.needsRefresh());
        if (AWS.config.credentials.needsRefresh()) {
          cognitoUser.refreshSession(refresh_token, (err, newSession) => {
            if (err) {
              console.log('Error 1', err);
              reject(false);
            } else {
              AWS.config.credentials.params.Logins[
                `cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`
              ] = newSession.getIdToken().getJwtToken();

              // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
              AWS.config.credentials.refresh((err) => {
                if (err) {
                  console.log(err);
                  reject(false);
                } else {
                  // Instantiate aws sdk service objects now that the credentials have been updated.
                  // example: var s3 = new AWS.S3();
                  // console.log('%cTOKEN SUCCESSFULLY UPDATED', 'background: purple; color: #fff; font-size: 18px');
                  resolve(newSession);
                }
              });
            }
          });
        } else {
          resolve(false);
        }
      });
    }
  });
};

/**
 *
 * @param email
 * @param callback
 * @param callbackError
 */
export const sendRecoveryPasswordEmail = (email, callback, callbackError) => {
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.forgotPassword({
    onSuccess(data) {
      // successfully initiated reset password request
      //console.log(`CodeDeliveryData from forgotPassword: ${data}`);
    },
    onFailure(err) {
      callbackError(err);
    },
    inputVerificationCode(data) {
      // First step
      callback(data);
    },
  });
};

/**
 *
 * @param email
 * @param verificationCode
 * @param newPassword
 * @returns {Promise<unknown>}
 */
export const confirmNewPassword = (email, verificationCode, newPassword) => {
  const userPool = new CognitoUserPool(poolData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        resolve('Password confirmed!');
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};

/**
 * @param {string} userName
 * @param {string} confirmationCode - Email code sent by Cognito
 * @param {function} callback
 * @param {function} callbackError
 */
export const confirmRegistration = (userName, confirmationCode, callback, callbackError) => {
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: userName,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
    if (err) {
      callbackError(err);
      return;
    }
    callback(result);
  });
};

/**
 *
 * @param userName
 * @returns {Promise<unknown>}
 */
export const resendConfirmationCode = (userName) => {
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: userName,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
};

export const deleteCognitoUser = () => {
  return new Promise((resolve, reject) => {
    getCognitoUser()
      .then((res) => {
        res.cognitoUser.deleteUser((err, result) => {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
          console.log('call result: ' + result);
          resolve(true);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const signOutCognito = () => {
  getCognitoUser().then((data) => {
    data.cognitoUser.signOut();
  });
};
