import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import { getServicePlanAction } from 'store/actions/sessionActions';
import { getBillingDetails } from 'services/api/landlordApi/read';
import SpaceResponsive from 'components/SpaceResponsive';
import Text from 'components/Text';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import { deleteStripePaymentMethod } from 'services/api/landlordApi/delete';
import { displayAlert } from 'resources/helpers';

const Title = styled.h1`
  font-family: Poppins;
  font-size: 42px;
  text-align: center;
  color: #122c34;
  margin-bottom: 10px !important;
`;

const Subtitle = styled.div`
  width: 320px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  color: #122c34;
`;

const PlansBillingsPanel = (props) => {
  const { resetPanel, setVisiblePanel } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const history = useHistory();
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const [plansBilling, setPlansBilling] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteText, setDeleteText] = useState('Delete');

  useEffect(() => {
    dispatch(getServicePlanAction(cognitoToken));
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);
      setPlansBilling(plansBillingRes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      displayAlert(`Plans and billing couldn't be fetched`, 'error');
    }
  };

  const deleteStripePm = async () => {
    try {
      if (deleteText === 'Delete') {
        setDeleteText('Really Delete?');
        return;
      }

      setLoading(true);
      await deleteStripePaymentMethod(cognitoToken, session.userData.id);
      const plansBillingRes = await getBillingDetails(cognitoToken, session.userData.id);

      setPlansBilling(plansBillingRes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      displayAlert('The payment method was not deleted, please try again', 'error');
    }
  };

  return (
    <FormContentContainer className="content-container" data-testid="plansBillingPanelTest">
      <Title data-testid="titleTest">Account Settings</Title>
      <Subtitle data-testid="subtitleTest">Plans and Billing</Subtitle>
      <SpaceResponsive lg={30} md={30} sm={30} />
      <StyledDiv>
        <Text size={20} color="black" weight={500}>
          Service Plan
        </Text>
        <SpaceResponsive lg={10} md={10} sm={10} />
        <Row>
          <Col span={18}>
            <Text size={16} color="black" weight={500}>
              {session.userData.servicePlan.name}
            </Text>

            <Text size={16} color="black" style={{ display: 'block' }}>
              {`$${session.userData.servicePlan.subscriptionFee} per month`}
            </Text>

            <Text size={16} color="black" style={{ display: 'block' }}>
              {`$${session.userData.servicePlan.achTrxFee} ACH/ ${(
                session.userData.servicePlan.creditCardRateFee * 100
              ).toFixed(2)}%+$${session.userData.servicePlan.creditCardTrxFee} Credit Card`}
            </Text>
          </Col>
          <Col span={6}>
            {+session.userData.servicePlan?.id !== 9 &&
              +session.userData.servicePlan?.id !== 10 &&
              session.userData.servicePlan?.id && (
                <Text
                  size={14}
                  color={session.userData.servicePlan?.id === 5 ? 'grey' : 'violet'}
                  underline
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (session.userData.servicePlan.id !== 5) {
                      dispatch({ type: 'SET_SELECTED_MENU', payload: '' });
                      history.push('/landlord/service-plans');
                      resetPanel();
                    }
                  }}
                >
                  Change
                </Text>
              )}
          </Col>
        </Row>
      </StyledDiv>
      {loading ? (
        <Row justify="center" align="middle">
          <Col>
            <Spin style={{ marginTop: '90px' }} />
          </Col>
        </Row>
      ) : (
        <>
          <StyledDiv>
            <Space vertical={40} />
            <Row>
              <Col span={18}>
                <Text size={20} color="black" weight={500}>
                  Billing Details
                </Text>
                <Text size={16} color="black" weight={500} style={{ display: 'block' }}>
                  {plansBilling &&
                    `${plansBilling.billingDetails.firstName} ${plansBilling.billingDetails.lastName}`}
                </Text>
                <Text size={16} color="black" weight={500} style={{ display: 'block' }}>
                  {plansBilling && `${plansBilling.billingDetails.email || ''}`}
                </Text>
              </Col>
              <Col span={6}></Col>
            </Row>
          </StyledDiv>
          <StyledDiv>
            <Space vertical={10} />
            <Row>
              <Col span={18}>
                <Text size={20} color="black" weight={500}>
                  Payment Method
                </Text>
                <SpaceResponsive lg={10} md={10} sm={10} />

                <Text size={16} color="black" weight={500} style={{ display: 'block' }}>
                  {plansBilling &&
                    plansBilling?.paymentMethod?.paymentMethod &&
                    `${plansBilling?.paymentMethod?.paymentMethod}`}
                </Text>
                <Text size={16} color="black" weight={500} style={{ display: 'block' }}>
                  {plansBilling &&
                    plansBilling?.paymentMethod?.number &&
                    `**** **** **** ${plansBilling.paymentMethod?.number}`}
                </Text>
                <Text size={16} color="black" weight={500} style={{ display: 'block' }}>
                  {plansBilling &&
                    plansBilling?.paymentMethod?.expDate &&
                    `${plansBilling?.paymentMethod?.expDate} cvv ${plansBilling?.paymentMethod?.cvv || ''}`}
                </Text>
              </Col>
              <Col span={6}>
                {!plansBilling?.paymentMethod && (
                  <Text
                    size={14}
                    color="violet"
                    underline
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setVisiblePanel('billing');
                    }}
                    data-testid="addTest"
                  >
                    {!plansBilling?.paymentMethod && 'Add'}
                  </Text>
                )}
                {plansBilling?.paymentMethod?.paymentMethod && (
                  <Text
                    size={14}
                    color="violet"
                    underline
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      deleteStripePm();
                    }}
                  >
                    {deleteText}
                  </Text>
                )}
              </Col>
            </Row>
          </StyledDiv>
        </>
      )}
    </FormContentContainer>
  );
};

const StyledDiv = styled.div`
  position: relative;
  min-height: 110px;
`;

const FormContentContainer = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 0px 24px 0px 24px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

export default PlansBillingsPanel;
