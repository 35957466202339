import styled from 'styled-components';
import { Drawer, Button } from 'antd';

export const Headline = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 2.75rem;
  line-height: 1.25;
  color: #fff;
  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const Subheading = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.38;
  color: #fff;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Benefits = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.29;
  color: #fff;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  ul {
    padding-left: 0;
    list-style: none;
    text-indent: -16px;
    li {
      margin-bottom: 10px;
      margin-left: 10px;
      :before {
        content: '✓  ';
      }
    }
  }
`;

export const StyledCTA = styled.div`
  display: inline-flex;
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  font-family: 'Poppins';
  width: 100%;
  justify-content: center;
  gap: 12px;
`;

export const StyledSalesDrawer = styled(Drawer)`
  background-color: #541388 !important;
  .ant-drawer-body {
    padding: 3rem 2rem;
  }
`;
export const StyledButton = styled.div`
  border: 2px solid #fff;
  background: #cb47b8;
  color: #fff;
  border-radius: 25px;
`;
