import dayjs from 'dayjs';
import parser from 'parse-address';
import valid from 'card-validator';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const checkZipCode = (rule, value) => {
  const isValidPostalZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  if (isValidPostalZipCode) {
    return Promise.resolve();
  }
  return Promise.reject('Please input a valid zip code.');
};

export const boardingStep4CheckZipCode = (rule, value, form) => {
  if (form.getFieldValue('ownership') !== 'Sole Proprietorship') {
    const isValidPostalZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if (isValidPostalZipCode) {
      return Promise.resolve();
    }
    return Promise.reject('Please input a valid zip code.');
  }
  return Promise.resolve();
};

export const checkSsn = (rule, value) => {
  const isValidSsn = /^((?!666|000)[0-8][0-9_]{2}-(?!00)[0-9_]{2}-(?!0000)[0-9_]{4})*$/.test(value);
  if (isValidSsn) {
    return Promise.resolve();
  }
  return Promise.reject('Please input a valid SSN.');
};

export const checkCity = (rule, value) => {
  if (!isNaN(value) || value === undefined) {
    return Promise.reject('Please input a valid city.');
  }
  return Promise.resolve();
};

export const boardingStep4checkCity = (rule, value, form) => {
  if (form.getFieldValue('ownership') !== 'Sole Proprietorship') {
    if (!isNaN(value) || value === undefined) {
      return Promise.reject('Please input a valid city.');
    }
  }

  return Promise.resolve();
};

export const checkPhoneNumber = (rule, value) => {
  let regex = /^[+][1]\s?(\d\s?){10,20}$/;
  if (!regex.test(value) || value.length > 16) {
    return Promise.reject('Please input a valid phone number.');
  }
  return Promise.resolve();
};

export const checkAddressValidator = (rule, value) => {
  if (value) {
    if (value.length) {
      if (!parser.parseLocation(value)) {
        return Promise.reject('Please input a valid address.');
      }
    }
  }

  return Promise.resolve();
};

export const disableLeaseStartDate = (current, form) => {
  const leaseStart = form.getFieldValue('leaseStartDate');
  const leaseEnd = form.getFieldValue('leaseEndDate');

  if (leaseEnd && !leaseStart) {
    // console.log('has leaseEnd', leaseEnd);
    return current && current > dayjs(form.getFieldValue('leaseEndDate')).subtract(3, 'd');
  }
  return false;
};

export const disableLeaseEndDate = (current, form) => {
  if (form.getFieldValue('leaseStartDate')) {
    return current && current < dayjs(form.getFieldValue('leaseStartDate')).add(3, 'd');
  }
  return current && current < dayjs().startOf('d');
};

export const disableScheduledPaymentEndDate = (current, form) => {
  if (form.getFieldValue('firstPaymentDate')) {
    let disabledDays = 8;
    let unit = 'd';
    if (form.getFieldValue('frequency') === 'MONTHLY') {
      disabledDays = 1;
      unit = 'months';
    }

    return current && current < dayjs(form.getFieldValue('firstPaymentDate')).local().add(disabledDays, unit);
  }
  return current && current < dayjs().local().add(8, 'd');
};

const checkLuhn = (cardNo) => {
  let s = 0;
  let doubleDigit = false;
  for (let i = cardNo.length - 1; i >= 0; i--) {
    let digit = +cardNo[i];
    if (doubleDigit) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }
    s += digit;
    doubleDigit = !doubleDigit;
  }
  return s % 10 === 0;
};

export const validateCardNo = (rule, value) => {
  const no = Number(value);
  if (value) {
    if (
      (no &&
        checkLuhn(no) &&
        value.length === 16 &&
        (value[0] === '4' ||
          (value[0] === '5' && value[1] >= 1 && value[1] <= 5) || // mastercard
          (value.indexOf('222') === 0 && value[3] >= 1 && value[3] <= 9) || // mastercard
          (value.indexOf('22') === 0 && value[2] >= 3 && value[2] <= 9) || // mastercard
          (value.indexOf('2') === 0 && value[1] >= 3 && value[1] <= 6) || // mastercard
          (value.indexOf('27') === 0 && value.indexOf('01') === 2) || // mastercard
          value.indexOf('2720') === 0 || // mastercard
          value.indexOf('6011') === 0 ||
          value.indexOf('65') === 0 ||
          value.indexOf('35') === 0)) ||
      (value.length === 15 &&
        (value.indexOf('34') === 0 ||
          value.indexOf('37') === 0 ||
          value.indexOf('5') === 0 ||
          value.indexOf('2131') === 0 || // JCB
          value.indexOf('1800') === 0)) || // JCB
      (value.length === 14 &&
        (value.indexOf('36') === 0 ||
          value.indexOf('38') === 0 ||
          (value[0] === '3' && value[1] === '0' && value[2] >= 0 && value[1] <= 5))) ||
      (value.length === 13 && value[0] === 4)
    ) {
      return Promise.resolve();
    }
  }

  return Promise.reject('Please enter a valid credit card number.');
};

export const isValidNumber = (rule, value) => {
  if (value) {
    if (isNaN(value)) {
      return Promise.reject('Value is not a valid number');
    }
  }
  return Promise.resolve();
};

export const validateCreditCard = (_, cardNumber) => {
  // console.log('ƒ validateCreditCard', rule, cardNumber);
  const card = valid.number(cardNumber);
  // console.log(card);
  if (card.isPotentiallyValid) {
    return Promise.resolve(card);
  }
  return Promise.reject(new Error('Please enter a valid credit card number.'));
};

export const validateEmailList = (rule, value) => {
  // console.log('ƒ validateEmailList', rule, value);
  if (value) {
    const cleanList = value.replace(/\s/g, '');
    const emailList = cleanList.split(',');
    const invalidEmails = emailList.filter((email) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));

    if (invalidEmails.length) {
      return Promise.reject('One or more email addresses are invalid.');
    }
  }

  return Promise.resolve();
};
