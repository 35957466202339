import styled from 'styled-components';
import { Typography, Collapse, Segmented } from 'antd';
import CardWithTitle from '../../../../components/CardWithTitle/CardWithTitle';

const { Text } = Typography;

export const StyledSegmented = styled(Segmented)`
  .ant-segmented-item-selected {
    background-color: #cb47b8;
    color: #fff;
  }
`;
export const StyledKpiTitle = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* pr-slate-grey */

  color: #122c34;
`;

export const StyledKpiValue = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => (props.value < 0 ? '#f43535' : '#122c34')};
  display: ${(props) => (props.display ? 'inline-block' : 'none')};
  animation: ${(props) => (props.display ? 'fadeIn ease 0.5s' : 'fadeOut ease 0.5s;')};
  @media screen and (max-width: 992px) {
    &&&&&&&&&&& {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    &&&&&&&&&&&&&&& {
      font-size: calc(20px + 0.5vw) !important;
    }
  }
  @media screen and (max-width: 575px) {
    &&&&&&&&&&& {
      font-size: 22px;
    }
  }
`;

export const StyledWarning = styled.div`
  span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #f43535;
  }
  min-height: 19px;
`;

export const StyledCardWithTitle = styled(CardWithTitle)`
  min-height: 142px;
  .ant-card-head {
    border-bottom: none;
    padding: 8px 24px !important;
    min-height: 56px;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-body {
    padding: 0 24px 16px 24px;
  }

  .ant-card-extra {
    padding: 8px 0 8px 0;
    min-height: 40px;
  }
`;

export const StyledCollapse = styled(Collapse)`
  background-color: transparent;
  padding: 0;
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-header {
    span {
      font-size: 20px;
      font-weight: 600;
      color: rgb(84, 19, 136);
    }
  }
  .ant-collapse-item {
    border-bottom: 0;
  }
`;
