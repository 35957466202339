import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Menu, Spin } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import { achMicrodepositVerification } from 'services/api/landlordApi/update';
import { displayAlert } from 'resources/helpers';

import { usePlaidLink } from 'react-plaid-link';

const PaymentMethodActions = (props) => {
  const {
    paymentMethods,
    pendingMicrodeposit,
    plaidToken,
    onboardingState,
    loading,
    setAlert,
    setPmSelect,
    propertyGroup,
    setProcessing,
    fetchPropertyGroups,
    fetchPropertyGroupStatus,
  } = props;
  const session = useSelector((store) => store.session);

  const onSuccess = async (token, metadata) => {
    try {
      console.log('pm id', onboardingState.ach.silaSettlement.id);
      setProcessing(true);
      await achMicrodepositVerification(
        session.cognitoTokens.idToken.jwtToken,
        onboardingState.ach.silaSettlement.id,
        propertyGroup[0].id
      );
      fetchPropertyGroupStatus(propertyGroup);
      setProcessing(false);
      displayAlert(setAlert, 'Payment method verified', 'success', 5000);
    } catch (error) {
      console.log(error);
      setProcessing(false);
      displayAlert(setAlert, error.data?.errors[0].detail || 'Your request failed', 'warning', 5000);
    }
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
  };
  const { open } = usePlaidLink(config);

  const menu = (
    <Menu>
      {paymentMethods.length > 1 && <Menu.Item onClick={() => setPmSelect(true)}>Change</Menu.Item>}
      {pendingMicrodeposit && (
        <Menu.Item
          onClick={() => {
            if (plaidToken) {
              open();
            }
          }}
          disabled={!plaidToken}
        >
          <Spin spinning={loading}>Verify</Spin>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <EllipsisOutlined style={{ fontSize: '20px', color: '#CB47B8' }} onClick={(e) => e.preventDefault()} />
    </Dropdown>
  );
};

export default PaymentMethodActions;
