import React, { useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const MoreMenu = (props) => {
  // console.log('[MoreMenu.js]', props);
  const { items, confirmKeys, resetDropdown, loading } = props;
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const handleMenuClick = (e) => {
    // console.log('[MoreMenu.js] ƒ handleMenuClick', e.key);

    if (confirmKeys && confirmKeys.includes(e.key) && !confirmed) {
      setOpen(true);
      setConfirmed(true);
    } else {
      setOpen(false);
      setConfirmed(false);
      resetDropdown();
    }
  };

  const handleOpenChange = (nextOpen, info) => {
    // console.log('handleOpenChange', nextOpen, info);
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
      resetDropdown();
      setConfirmed(false);
    }
  };

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      trigger={['click']}
      open={open}
      onOpenChange={handleOpenChange}
      data-testid="moreMenuTest"
    >
      <Tooltip title="More Actions">
        <Button
          type="link"
          icon={<MoreOutlined style={{ fontSize: 22 }} />}
          loading={loading}
          data-testid="moreMenuButtonTest"
        />
      </Tooltip>
    </Dropdown>
  );
};

MoreMenu.propTypes = {
  items: PropTypes.array.isRequired,
  confirmKeys: PropTypes.array,
  resetDropdown: PropTypes.func,
};

export default MoreMenu;
