import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import 'react-image-crop/dist/ReactCrop.css';
import { Upload } from 'antd';

// import 'antd/es/modal/style';

// import 'antd/es/slider/style';

import { LoadingOutlined, UserOutlined, DeleteOutlined } from '@ant-design/icons';

import { ReactComponent as PhotoIcon } from 'assets/images/photo-icon.svg';

import { documentsPresignedUrl } from 'services/api/common/create';
import { updateTenantMetaV3 } from 'services/api/tenantApi';

const Avatar = (props) => {
  const { validateAndCrop, imageUrl, uploading, displayAlert, setImageUrl } = props;
  const session = useSelector((store) => store.session);
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;

  const [deleteFile, setDeleteFile] = useState(false);

  const uploadButton = (
    <div>
      {uploading ? (
        <LoadingOutlined data-testid="loadingTest" />
      ) : (
        <UserOutlined style={{ fontSize: '40px', color: 'white' }} data-testid="userIconTest" />
      )}
    </div>
  );

  const customRequest = (info) => {
    validateAndCrop(info);
  };

  const handleDeleteFile = async () => {
    try {
      const ba = session.userData.billingAccounts.filter((b) => b.state === 'ACTIVE');
      const fileUrl = session.userData.tenantMeta.renterProfile.photo.split('/');
      const fileName = fileUrl[fileUrl.length - 1];

      if (ba.length) {
        await documentsPresignedUrl(
          session.cognitoTokens.idToken.jwtToken,
          ba[0].id,
          'delete',
          fileName,
          'profile'
        );

        setImageUrl(null);
        const renterProfile = {
          ...session.userData.tenantMeta.renterProfile,
          photo: '',
        };

        await updateTenantMetaV3(
          session.userData.id,
          session.userData.tenantMeta.creditBoost,
          renterProfile,
          session.userData.tenantMeta.financialGoals,
          cognitoToken
        );
        setDeleteFile(false);
      }
    } catch (err) {
      setDeleteFile(false);
      const { response } = err;
      displayAlert(response?.data?.errors[0].detail || err.message, 'warning');
    }
  };

  return (
    <>
      <StyledUpload
        style={{ width: 'unset' }}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        accept=".pdf,.jpg,.jpeg,.png"
        showUploadList={false}
        customRequest={(info) => customRequest(info)}
        // beforeUpload={beforeUpload}
        // onChange={this.handleChange}
        imageUrl={imageUrl}
        openFileDialogOnClick={!imageUrl}
      >
        {imageUrl ? (
          <>
            <img
              src={imageUrl}
              alt="avatar"
              style={{ width: '100%', borderRadius: '50px', cursor: 'pointer' }}
              onMouseEnter={() => setDeleteFile(true)}
              data-testid="imgTest"
            />
            {deleteFile && (
              <DeleteFileContainer
                onClick={handleDeleteFile}
                onMouseLeave={() => setDeleteFile(false)}
                data-testid="deleteContainerTest"
              >
                <DeleteOutlined
                  style={{ fontSize: '20px', position: 'absolute', color: 'white' }}
                  data-testid="deleteTest"
                />
              </DeleteFileContainer>
            )}
          </>
        ) : (
          uploadButton
        )}

        <PhotoIcon
          style={{ transform: 'scale(1.43)', position: 'absolute', bottom: '-8px', right: '-5px' }}
          onMouseEnter={() => {
            if (imageUrl) setDeleteFile(true);
          }}
          data-testid="photoIconTest"
        />
      </StyledUpload>
    </>
  );
};

const StyledUpload = styled(Upload)`
  width: unset !important;
  .ant-upload {
    position: relative;
    border-radius: 50px;
    background-color: #d7d7d7;
  }
  ${(props) => {
    if (props.imageUrl) {
      return css`
        .ant-upload {
          border: unset;
        }
      `;
    }
  }};
`;

const DeleteFileContainer = styled.div`
  width: 100%;
  background-color: rgb(19 19 19 / 50%);
  position: absolute;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Avatar;
