import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Form, Input, Select } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import Space from 'components/Space';

import PanelFooter from 'components/Panel/PanelFooter';

const BankAccountDetail = (props) => {
  const { onFinish, editPaymentMethod } = props;
  const userData = useSelector((store) => store.session.userData);
  const servicePlanData = useSelector((store) => store.session.userData.servicePlan);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editPaymentMethod.length) {
      form.setFieldsValue({
        paymentMethodName: editPaymentMethod[0].label,
        accountNumber: `Account ending in ${editPaymentMethod[0].paymentGatewayMeta?.account_last_four}`,
      });
    }
  }, []);

  return (
    <>
      <Text size={12} color={'black'} strong centered>
        {userData.property.paymentSettings.absorbFeesPercent === 100 &&
        userData.property.paymentSettings.absorbFees
          ? 'Fees: $0 Your landlord is paying this fee'
          : `Fees: ${(Number(servicePlanData?.achRateFee) * 100).toFixed(2)}% per transaction (max. $${
              servicePlanData?.cap
            })`}
      </Text>
      {userData.property.paymentSettings.absorbFees &&
        userData.property.paymentSettings.absorbFeesPercent > 0 &&
        userData.property.paymentSettings.absorbFeesPercent < 100 && (
          <Text size={12} color={'black'} strong centered>
            Your landlord is absorbing {userData.property.paymentSettings.absorbFeesPercent}% of this fee
          </Text>
        )}
      <Space vertical={10} />

      <Form
        form={form}
        onFinish={onFinish}
        style={{ height: 'calc(100% - 29px)', display: 'flex', flexDirection: 'column' }}
      >
        <ContentContainer className="content-container">
          <span className="inputTitle">Payment Method Name</span>
          <Form.Item name="paymentMethodName" whitespace="true">
            <Input disabled data-testid="achPaymentMethodName" />
          </Form.Item>

          <span className="inputTitle">Bank Account</span>
          <Form.Item
            name="accountNumber"
            whitespace="true"
            rules={[{ required: true, message: 'Please enter your account number' }]}
          >
            <Input disabled={editPaymentMethod.length} data-testid="achAccountNumberTest" />
          </Form.Item>
        </ContentContainer>
        <PanelFooter>
          <Button htmlType="submit" color="violet" alignment="center" size="medium" disabled bottom={0}>
            Save
          </Button>
        </PanelFooter>
      </Form>
      {/**
       <Button type="link" alignment="center" onClick={() => {}}>
        Delete payment method
      </Button>
       */}
    </>
  );
};

const ContentContainer = styled.div`
  padding: 25px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export default BankAccountDetail;
