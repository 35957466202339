import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePlaidLink } from 'react-plaid-link';
import Text from 'components/Text';

import { getPlaidLinkToken, achMicrodepositVerification } from 'services/api/tenantApi';
import { getPaymentMethods } from 'store/actions/tenantActions';

const VerifyPaymentMethod = (props) => {
  const { item, displayAlert, paymentMethods, setLoading } = props;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.session);
  const [plaidToken, setPlaidToken] = useState(null);

  const onSuccess = async (token, metadata) => {
    try {
      dispatch({
        type: 'SET_LOADER',
        payload: true,
      });
      await achMicrodepositVerification(session.cognitoTokens.idToken.jwtToken, item.id);
      dispatch(getPaymentMethods(session.userData.paymentGateway, false, session.cognitoTokens.idToken.jwtToken));
      displayAlert(
        paymentMethods ? 'Payment Method Verified' : true,
        paymentMethods ? 'success' : 'Payment Method Verified',
        paymentMethods ? '' : 'success',
        paymentMethods ? '' : 3000
      );
      dispatch({
        type: 'SET_LOADER',
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: 'SET_LOADER',
        payload: false,
      });
      displayAlert(
        paymentMethods ? 'Payment Method Error' : true,
        paymentMethods ? 'warning' : 'Payment Method Error',
        paymentMethods ? 'Something went wrong while verifying your payment method' : 'warning',
        paymentMethods ? '' : 3000
      );
    }
  };

  const config = {
    clientName: 'Payrent',
    env: process.env.REACT_APP_PLAID_ENV,
    product: ['auth'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    token: plaidToken,
    onSuccess,
  };
  const { open } = usePlaidLink(config);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (
        item.paymentMethodType === 'ACH' &&
        session.userData.paymentGateway === 'SilaStripe' &&
        item.state === 'SCHEDULED' &&
        item?.paymentGatewayMeta?.state === 'pending verification'
      ) {
        setLoading(true);
        const tokenRes = await getPlaidLinkToken(session.cognitoTokens.idToken.jwtToken, item.id);

        setPlaidToken(tokenRes?.linkToken);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Text
      size={14}
      role="button"
      underline
      style={{ cursor: 'pointer' }}
      color={!plaidToken ? 'grey' : 'violet'}
      onClick={() => {
        if (plaidToken) {
          open();
        }
      }}
    >
      Verify
    </Text>
  );
};

export default VerifyPaymentMethod;
