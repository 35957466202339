import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, Row, Col, Select, DatePicker, Checkbox, Form, Tooltip, Space, theme } from 'antd';
import { disableLeaseStartDate, disableLeaseEndDate } from 'resources/FormValidators';
import { createRenter } from 'services/api/landlordApi/create';
import { getProperties } from 'services/api/landlordApi/read';
import { InfoCircleFilled } from '@ant-design/icons';
import Text from 'components/Text';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { updateQuickStartStatusAction } from 'store/actions/landlordActions';

const { Option } = Select;

const InvitationForm = (props) => {
  const { inviteProperty, currentStep, setCurrentStep, setIsLoading } = props;
  // console.log('[InvitationForm.js] props', props);
  const session = useSelector((store) => store.session);
  const quickStart = session.userData.landlordMeta.landlordProfile?.landlordProfile?.quickStart;
  const dispatch = useDispatch();
  const cognitoToken = session.cognitoTokens.idToken.jwtToken;
  const [message, setMessage] = useState({ type: 'success', message: '' });
  const [form] = Form.useForm();
  const [properties, setProperties] = useState([]);
  const [selectedProp, setSelectedProp] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoadingProperties(true);
      const propertiesRes = await getProperties(false, 1, 10000, false, 'VACANT');
      setProperties(propertiesRes);
      const propertyIdDetail = form.getFieldValue('propertyId');
      setLoadingProperties(false);

      if (inviteProperty || propertyIdDetail) {
        const property = propertiesRes.filter((p) => p.id === inviteProperty?.id || p.id === propertyIdDetail);

        setSelectedProp(property);
        if (inviteProperty?.id) {
          form.setFieldsValue({
            propertyId: inviteProperty?.id,
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoadingProperties(false);
      displayAlert('Request failed.', 'warning');
    }
  };

  const displayAlert = (text, type) => {
    setMessage({
      message: text,
      type,
    });
    setTimeout(() => {
      setMessage({
        message: '',
      });
    }, 3000);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      let normalizePhone = '';
      if (values.mobilePhone) {
        normalizePhone = `+1${values?.mobilePhone.replace(/\s/g, '')}`;
        values.mobilePhone = normalizePhone;
      }
      values.extendMonth = !values.extendMonth;
      values.landlordId = session.userData.id;

      await createRenter(cognitoToken, values);
      dispatch(
        updateQuickStartStatusAction(
          session.userData.id,
          { ...quickStart, lastCompletedStep: currentStep, finished: true },
          cognitoToken
        )
      );
      setCurrentStep(currentStep + 1);
    } catch (error) {
      // SQS Queue throws an error on local...keep going
      if (error?.data?.errors[0]?.detail === 'The specified queue does not exist.') {
        dispatch(
          updateQuickStartStatusAction(
            session.userData.id,
            {
              ...quickStart,
              lastCompletedStep: currentStep,
              finished: true,
            },
            cognitoToken
          )
        );
        setCurrentStep(currentStep + 1);
      }
      const detail = error?.data?.errors[0]?.detail;
      displayAlert(detail || 'Request failed.', 'warning');
    }

    setIsLoading(false);
  };

  const handleOnValuesChange = (changedValues, allValues) => {
    if (changedValues.mobilePhone) {
      form.validateFields(['email']);
    }
    if (changedValues.email) {
      form.validateFields(['mobilePhone']);
    }
    if (changedValues.propertyId) {
      const property = properties.filter((p) => p.id === changedValues.propertyId);
      setSelectedProp(property);
    }
  };

  return (
    <StyledForm
      form={form}
      layout="vertical"
      requiredMark={false}
      name="invite-renter-onboarding-form"
      data-testid="inviteRenterFormTest"
      initialValues={{
        remember: true,
        extendMonth: false,
        mobilePhone: '',
        leaseStartDate: '',
        leaseEndDate: '',
        requireInsurance: true,
      }}
      onFinish={onFinish}
      onFinishFailed={() => {}}
      onValuesChange={handleOnValuesChange}
    >
      <Row justify="space-between" gutter={20}>
        <Col span={12}>
          <Form.Item
            label="First Name"
            name="firstName"
            data-testid="firstNameTest"
            rules={[
              {
                required: true,
                message: "Please enter the tenant's first name.",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Last Name"
            name="lastName"
            data-testid="lastNameTest"
            rules={[
              {
                required: true,
                message: "Please enter the tenant's last name.",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        shouldUpdate={(prevValues, curValues) => prevValues.mobilePhone !== curValues.mobilePhone}
        noStyle
      >
        {() => {
          return (
            <Form.Item
              label="Email Address (optional)"
              name="email"
              data-testid="emailTest"
              rules={[
                {
                  required: !form.getFieldValue('mobilePhone'),
                  type: 'email',
                  message:
                    !form.getFieldValue('email') && !form.getFieldValue('mobilePhone')
                      ? 'Requires either email address or mobile phone number.'
                      : 'Please enter an email address',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.email !== curValues.email} noStyle>
        {() => {
          return (
            <Form.Item
              label="Renter Mobile Phone (optional)"
              name="mobilePhone"
              rules={[
                {
                  required: !form.getFieldValue('email'),
                  message:
                    !form.getFieldValue('email') && !form.getFieldValue('mobilePhone')
                      ? 'Requires either email address or mobile phone number.'
                      : 'Please enter a phone number',
                },
                {
                  // pattern: new RegExp('^[0-9]{6,}$'),
                  // supports ten characters without + sign
                  // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                  pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                  message: 'Please enter only numbers - min length 10',
                },
                {
                  message: 'Please enter a valid phone number.',
                  max: 16,
                },
              ]}
            >
              <PhoneInput />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        label="Property"
        name="propertyId"
        data-testid="propertyTest"
        rules={[{ required: true, message: 'Please select a property' }]}
      >
        <Select
          placeholder="Select property"
          onChange={() => {}}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          data-testid="propSelectTest"
          loading={loadingProperties}
        >
          {properties.length &&
            properties.map((property, i) => (
              <Option value={property.id} key={i}>
                {property.address2 ? `${property.address} - #${property.address2}` : `${property.address}`}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Row gutter={16} justify="start">
        <Col span={12}>
          <Form.Item
            label="Lease Start"
            name="leaseStartDate"
            data-testid="leaseStartDateTest"
            rules={[
              {
                required: true,
                message: 'Please select a date.',
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => disableLeaseStartDate(current, form)}
              format="MM/DD/YYYY"
              placeholder="mm/dd/yyyy"
              data-testid="leaseDateTest"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Lease End"
            name="leaseEndDate"
            data-testid="leaseEndDateTest"
            rules={[
              {
                required: true,
                message: 'Please select a date.',
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => disableLeaseEndDate(current, form)}
              format="MM/DD/YYYY"
              placeholder="mm/dd/yyyy"
              // disabled={!form.getFieldValue('leaseStartDate')}
            />
          </Form.Item>
        </Col>
      </Row>
      <ExtendItem name="extendMonth" valuePropName="checked">
        <Checkbox>
          <Text size={16} color="#595959">
            Remove renter at end of lease.
          </Text>
          <Tooltip
            placement="top"
            title="Closes tenants billing account and removes them from the property"
            trigger="click"
          >
            <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ccTooltip" />
          </Tooltip>
        </Checkbox>
      </ExtendItem>
      <ExtendItem name="requireInsurance" valuePropName="checked">
        <Checkbox>
          <Text size={16} color="#595959">
            Require Renters Insurance
          </Text>
        </Checkbox>
      </ExtendItem>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const ExtendItem = styled(Form.Item)`
  margin-bottom: 12px;
`;

export default InvitationForm;
