import React from 'react';
import styled from 'styled-components';
import { Card as AntCard, Progress, Row, Col, Radio } from 'antd';

// Components

import Text from 'components/Text';
import Space from 'components/Space';
import RentCredTitle from '../../../components/RentCredTitle';
import RentCredBadge from 'screens/Tenant/components/RentCredBadge/RentCredBadge';
import { useSelector } from 'react-redux';

const RentCredStatus = (props) => {
  const rentCredStatus = useSelector((store) => store.tenant.rentCred);

  return (
    <StyledRentCredCard
      title={<RentCredTitle subtitle="Rewards" />}
      extra={<RentCredBadge />}
      data-testid="rentcred-status"
    >
      <Row justify="center">
        <Col span={12} style={{ textAlign: 'center' }}>
          <StyledProgress
            type="circle"
            percent={+`${rentCredStatus.points === 9 ? rentCredStatus.points + 1 : rentCredStatus.points}0`}
            format={() => `${rentCredStatus.points}`}
            strokeColor="#541388"
            strokeWidth={10}
            size={100}
          />
          <Space vertical={10} />
          <Text color="black" size={12} centered>
            On-time Payments
          </Text>
        </Col>
      </Row>
    </StyledRentCredCard>
  );
};

const StyledProgress = styled(Progress)`
  .ant-progress-text {
    font-size: 56px;
    font-weight: 600;
  }
  .ant-progress-text {
    color: #000 !important;
  }
`;

const StyledRentCredCard = styled(AntCard)`
  .ant-card-head-title {
    padding-top: 5px;
    padding-bottom: 0px;
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    border-color: #541388;
  }
`;

export default RentCredStatus;
