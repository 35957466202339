import React from 'react';
import Text from 'components/Text/Text';
import { Space } from 'antd';

function TenantFaqs() {
  const faqs = [
    {
      q: 'Do I need to provide my renter’s email AND phone number?',
      a: 'In order to add and invite your tenant we need either their email address or mobile number.',
    },
    {
      q: 'How do I enter a business as a tenant?',
      a: 'If your tenant is a business, you may enter the contact name or the business name. In the First and Last name fields',
    },
    {
      q: 'Is my personal information shared with the tenant?',
      a: 'No, we don’t share your personal email address, phone number or name. If you have a business name, your tenants will see that in the invitation.',
    },
    {
      q: 'Can a unit have more than one tenant? ',
      a: 'Each unit in PayRent can have only one person associated with the account for security reasons. However, you can clone the unit (eg. 1-a, 1-b) to represent a single tenancy.',
    },
  ];
  return faqs.map((faq, index) => (
    <Space direction="vertical" key={index}>
      <Text color="black" strong>
        {faq.q}
      </Text>
      <Text color="black">{faq.a}</Text>
    </Space>
  ));
}

export default TenantFaqs;
