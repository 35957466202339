export const layout = {
  oneCol: {
    xs: { span: 24 },
  },
  twoCol: {
    xs: { span: 24 },
    md: { span: 12 },
  },
  threeCol: {
    xs: { span: 24 },
    md: { span: 8 },
  },
  fourCol: {
    xs: { span: 24 },
    md: { span: 6 },
  },
  sixCol: {
    xs: { span: 12 },
    md: { span: 4 },
  },
  rowGutter: [12, 12],
};

export const rowStyle = {
  textAlign: 'left',
};
