import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Card as AntCard, Tabs } from 'antd';

import Tag from 'components/Tag';
import Text from 'components/Text';

const { TabPane } = Tabs;

const Benefits = (props) => {
  const [tierId, setTierId] = useState(1);
  const { rentCredStatus } = props;

  useEffect(() => {
    if (rentCredStatus.renterTierId > 0) {
      setTierId(rentCredStatus.renterTierId === 1 ? 5 : rentCredStatus.renterTierId);
    }
  }, [rentCredStatus]);

  const onTabClick = (key, event) => {
    setTierId(key);
  };

  return (
    <AntCard
      title="Benefits"
      styles={{
        header: {
          fontSize: '16px',
          color: '#541388',
          fontWeight: 600,
        },
        body: {
          padding: '24px 17px',
        },
      }}
    >
      <StyledTabs defaultActiveKey={`${tierId}`} activeKey={`${tierId}`} onTabClick={onTabClick} centered>
        <TabPane
          tab={
            <>
              <Tag
                color="orange"
                style={{ width: '70px', textAlign: 'center', marginBottom: '8px', marginRight: '0px' }}
              >
                Bronze
              </Tag>
              <Text color="black" size={11} centered>
                1 payment
              </Text>
            </>
          }
          key="5"
        >
          <ul style={{ paddingInlineStart: '21px' }}>
            <li>Flexible payments with RentCred Float</li>
          </ul>
        </TabPane>
        <TabPane
          tab={
            <>
              <Tag style={{ width: '70px', textAlign: 'center', marginBottom: '8px', marginRight: '0px' }}>
                Silver
              </Tag>
              <Text color="black" size={11} centered>
                3 payments
              </Text>
            </>
          }
          key="2"
        >
          <ul style={{ paddingInlineStart: '21px' }}>
            <li>Flexible payments with RentCred Float</li>
            <li>Payments reported to 1 credit bureau (equifax)</li>
          </ul>
        </TabPane>
        <TabPane
          tab={
            <>
              <Tag
                color="gold"
                style={{ width: '70px', textAlign: 'center', marginBottom: '8px', marginRight: '0px' }}
                data-testid="goldTabTest"
              >
                Gold
              </Tag>
              <Text color="black" size={11} centered>
                6 payments
              </Text>
            </>
          }
          key="3"
        >
          <ul style={{ paddingInlineStart: '21px' }}>
            <li>Flexible payments with RentCred Float</li>
            <li>Payments reported to 1 credit bureau (equifax)</li>
            <li>Borrow up to $500 to help with rent</li>
          </ul>
        </TabPane>
        <TabPane
          tab={
            <>
              <Tag
                color="#8C8C8C"
                style={{ width: '70px', textAlign: 'center', marginBottom: '8px', marginRight: '0px' }}
              >
                Platinum
              </Tag>
              <Text color="black" size={11} centered>
                9 payments
              </Text>
            </>
          }
          key="4"
        >
          <ul style={{ paddingInlineStart: '21px' }}>
            <li>Flexible payments with RentCred Float</li>
            <li>Payments reported to 1 credit bureau (equifax)</li>
            <li>Borrow up to $500 to help with rent</li>
            <li>Late fee forgiveness</li>
          </ul>
        </TabPane>
      </StyledTabs>
    </AntCard>
  );
};

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin: 0px !important;
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-content {
    min-height: 80px;
  }
`;

export default Benefits;
