import React from 'react';
import { Table as AntTable } from 'antd';
import styled from 'styled-components';

function Table(props) {
  return <StyledTable {...props} />;
}

const StyledTable = styled((props) => <AntTable {...props} />)`
  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }
  @media screen and (max-width: 1050px) {
    td.ant-table-cell {
      font-size: calc(8px + 0.5vw);
    }
  }
`;

export default Table;
