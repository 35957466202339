// @flow
import * as React from 'react';
import { Card as AntCard } from 'antd';

const CardBlank = (props) => {
  const { children, title, ...rest } = props;

  return (
    <AntCard
      title={title}
      bordered={false}
      styles={{
        header: {
          border: 'none',
          fontSize: '16px',
          color: '#541388',
          fontWeight: 500,
          padding: '8px 24px 8px 24px',
        },
        body: {
          padding: '0 24px 15px 24px',
          fontSize: '16px',
        },
      }}
      {...rest}
    >
      {children}
    </AntCard>
  );
};

export default CardBlank;
